import { useApi } from '@pidz/api';

const api = useApi();

export const assetService = {
  getAssets: (): Promise<AssetModel[]> => api.jsonrpc({
    url: 'asset:getAssetsForResume'
  }),

  getAsset: (assetId: number): Promise<Base64File> => api.jsonrpc({
    url: 'asset:downloadAsset', 
    params: { assetId }
  }),
  
  addAsset: (assetModel: Asset): Promise<Asset> => api.jsonrpc({
    url: 'asset:addAsset', 
    params: { assetModel }
  }),
  
  removeAsset: (assetId: number): Promise<{result: boolean}> => api.jsonrpc({
    url: 'asset:removeAsset', 
    params: { assetId }
  }),
  
  getAssetTypes: (): Promise<AssetType[]> => api.jsonrpc({
    url: 'asset_type:getAssetTypes'
  }),

  getInvoiceTemplate: (): Promise<Base64File> => api.jsonrpc({
    url: 'freelancer_invoice:getInvoiceTemplatePreview'
  }),

  updateInvoiceTemplate: (template: FileModel | null): Promise<{result: boolean}> => api.jsonrpc({
    url: 'freelancer:updateInvoiceTemplate', 
    params: { template }
  }),
}
