<template>
  <div class="relative w-full flex flex-col flex-grow">
    <reload-page :reload="() => reload(true)" />
    <p-banner-list :banners="dashboardBanners"/>
    <upcoming-shifts />
    <layout-desktop-wrapper no-padding-bottom>
      <inbox />
    </layout-desktop-wrapper>
  </div>
</template>

<script setup lang="ts">
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import resetAllOverviewPages from '@app/utils/reset-all-overview-pages';
import { MollieMandateModal } from '@pidz/shared/components';
import { Inbox } from '@pidz/shared/components'
import { mollieMandate } from '@pidz/shared/utils';
import { useUserStore, useDashboardStore, useFeatureflagsStore, useBannersStore, useMollieStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed, onMounted } from 'vue';
import PeriodicalFeedbackModal from './components/PeriodicalFeedbackModal.vue';
import UpcomingShifts from './components/UpcomingShifts.vue';

const modal = useModalController();

const mollie = useMollieStore();
const dashboardStore = useDashboardStore();
const user = useUserStore();
const flagStore = useFeatureflagsStore();
const bannersStore = useBannersStore();

const dashboardBanners = computed(() => bannersStore.getBannerPerRoute('dashboard'));

const showFeedbackModal = computed(() => flagStore.getStatus('ffe-feedback'));

const getUserFeedback = async () => {
  if (!dashboardStore.preventFeedbackModal && showFeedbackModal.value) {
    const review = await dashboardStore.getPeriodicalReview();
    if (review) {
      modal.open(PeriodicalFeedbackModal, { periodicalReview: review });
    }
  }
};

const reload = async (force = false) => {
  await Promise.all([
    user.fetchFreelancerData(force),
    getUserFeedback(),
    bannersStore.fetchBanners(),
    bannersStore.fetchSystemNotifications(),
    dashboardStore.fetch(),
  ]);

  if (await mollie.isMollieMandateRequired()) modal.open(MollieMandateModal, { 
    mandateCB: async () => await mollieMandate(import.meta.env.VITE_APP_URL ?? 'https://app.mijnpidz.nl') });

  dashboardStore.fetchUnpaidInvoices(); // unpaid invoices is dependent on freelancer info, so it needs to wait
};

onMounted(async () => {
  reload();
  resetAllOverviewPages();
});
</script>
