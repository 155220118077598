import { DateTime, DateTimeUnit } from 'luxon';
import { createDurationObject } from '../date-objects';

const yesterday = DateTime.now().startOf('day').minus(createDurationObject({ day: 1 }));
const today = DateTime.now().startOf('day');

export const isSame = (dt1: DateTime, dt2: DateTime, unit: DateTimeUnit): boolean => dt1.hasSame(dt2, unit);

export const isToday = (dt: DateTime) => isSame(today, dt.startOf('day'), 'day');

export const isYesterday = (dt: DateTime) => isSame(yesterday, dt.startOf('day'), 'day');

export const isCurrentYear = (dt: DateTime) => {
  if (dt.year === DateTime.now().year) return true;

  return false;
}
