import { FormFieldType } from '@pidz/enums'
import { ref } from 'vue'
import * as zod from 'zod'
import { contactDropdownOptions, radioGroupOptions } from './form-options';

export const anonymous = ref<FormField>({
  active: true,
  name: 'anonymous',
  formType: FormFieldType.CHECKBOX,
  width: 100,
  validation: zod.boolean(),
  data: {
    id: 'current',
    label: 'Ik blijf anoniem',
    inputType: 'checkbox',
  },
});

export const message = ref<FormField>({
  active: true,
  name: 'message',
  formType: FormFieldType.TEXTAREA,
  validation: zod
    .string()
    .min(1, { message: 'Omschrijving is verplicht' })
    .max(512, { message: 'Omschrijving mag maximaal 512 karakters zijn' })
    .optional(),
  width: 100,
  data: {
    id: 'message',
    label: 'Omschrijving:',
    inputType: 'textarea',
    rows: 4,
    placeholder: 'Beschrijf de situatie zo nauwkeurig mogelijk',
  },
});

export const about = ref<FormField>({
  active: true,
  name: 'about',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Selecteer een optie' }),
  data: {
    id: 'subject',
    label: 'Mijn melding gaat over:',
    options: contactDropdownOptions(),
    showDropdownPrepend: false,
  },
});

export const contactRadioGroupOptions = ref<FormField>({
  active: true,
  name: 'contactType',
  formType: FormFieldType.RADIOGROUP,
  validation: zod.string({ required_error: 'Selecteer een optie' }),
  width: 100,
  data: {
    id: 'contactType',
    label: '',
    inputType: 'radiogroup',
    options: radioGroupOptions(),
  },
});