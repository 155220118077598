<template>
  <div ref="viewport" class="feedback-page">
    <top-bar>
      <template #default>Wat vind jij van PIDZ?</template>
    </top-bar>

    <section class="content">
      <p>
        Als ervaringsdeskundige weet jij als geen ander wat fijn werken is, maar
        ook wat er beter kan. Laat dus van je horen, want samen met jou maken we
        PIDZ steeds beter.
      </p>

      <labeled-slot label="Mijn feedback gaat over:">
        <input v-model="review.subject" placeholder="Onderwerp" />
      </labeled-slot>

      <labeled-slot label="Omschrijving:">
        <textarea v-model="review.body"
                  placeholder="Beschrijf de situatie zo nauwkeurig mogelijk"
                  rows="7"/>
      </labeled-slot>

      <upload-files @upload="addFeedbackFile" />

      <div class="bottom">
        <checkbox-with-text v-model="review.contact_consent ">
          PIDZ mag contact met mij opnemen om aanvullende informatie op te
          vragen
        </checkbox-with-text>
        <async-button class="send-button"
                      :disabled="!sendable"
                      @click="sendFeedback">Verstuur</async-button>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import AsyncButton from '@app/components/AsyncButton.vue';
import CheckboxWithText from '@app/components/CheckboxWithText.vue';
import ExceptionModal from '@app/components/ExceptionModal.vue';
import LabeledSlot from '@app/components/LabeledSlot.vue';
import TopBar from '@app/components/TopBar.vue';
import UploadFiles from '@app/components/UploadFiles.vue';
import { lazyMakeFeedback } from '@pidz/factories';
import { useFeedbackStore } from '@pidz/stores';
import {
  useInputScrollFix,
  base64FromFile,
  useModalController,
} from '@pidz/utils';
import { reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { Feedback } from '../dashboard/types';
import FeedbackSentModal from './components/FeedbackSentModal.vue';

const feedbackStore = useFeedbackStore();

const viewport = ref();
useInputScrollFix(viewport);

const router = useRouter();

const review = reactive<Feedback>({
  ...lazyMakeFeedback(),
  subject: '',
});

const feedbackFiles = ref<{ name: string; data: string }[]>([]);

const sendable = computed(() => !!(review.subject && review.body));
const modal = useModalController();

const addFeedbackFile = async (files: File[]) => {
  feedbackFiles.value = await Promise.all(
    Array.from(files).map(async (file) => ({
      name: file.name,
      data: await base64FromFile(file),
    })),
  );
};

const sendFeedback = async () => {
  try {
    const result = await feedbackStore.addReview({ ...review, subject: `BETA - ${review.subject}` });
    if (result?.UUID) {
      await Promise.all(
        feedbackFiles.value.map(async (file) => feedbackStore.addAsset({
          fileName: file.name,
          data: file.data,
          UUID: result.UUID!,
        })),
      );
    }
    modal.open(FeedbackSentModal);
  } catch {
    modal.open(ExceptionModal, {
      title: 'Feedback versturen mislukt!',
      message:
        'Het is helaas niet gelukt om je feedback te versturen. Probeer het later nog een keer.',
    });
  } finally {
    router.push('dashboard');
  }
};
</script>

<style lang="scss">
.feedback-page {
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .content {
    padding: 0px 16px;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex: 1;

    overflow-y: auto;

    & > p {
      color: var(--pidz-black);
      font-weight: bold;
    }

    > * {
      margin-top: 12px;
    }

    input,
    textarea {
      font: inherit;
      width: 100%;
      padding: 14px 12px;
      background-color: var(--pidz-white);
      color: var(--pidz-black);
      border: 1px solid var(--pidz-black);
      resize: none;
      border-radius: 5px;
    }
  }

  .bottom {
    padding-bottom: calc(24px + env(safe-area-inset-bottom));

    .send-button {
      border: 0;
      font-family: inherit;
      color: var(--pidz-white);
      display: block;
      width: 100%;
      padding: 12px;
      font-size: 16px;
      background-color: var(--pidz-blue);
      text-align: center;

      margin-top: 16px;

      &:disabled {
        opacity: 0.25;
        pointer-events: none !important;
        touch-action: none;
      }
    }
  }
}
</style>
