import { DateTime, formatYmd, isAfter } from '@pidz/date';
import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';
import {
  functionOptions,
  appointmentOptions,
  sectorOptions,
} from './form-options';

export const currentField = ref<FormField>({
  active: true,
  name: 'is_current',
  formType: FormFieldType.TOGGLE,
  width: 100,
  validation: zod.boolean(),
  data: {
    id: 'current',
    label: 'Dit is mijn huidige werkgever',
    inputType: 'checkbox',
  },
});

export const functionField = ref<FormField>({
  active: true,
  name: 'function_name',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een functie in' }),
  data: {
    id: 'function',
    label: 'Functie',
    options: functionOptions([]),
    showDropdownPrepend: false,
  },
});

export const sectorField = ref<FormField>({
  active: true,
  name: 'sector_id',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een sector in' }),
  data: {
    id: 'sector',
    label: 'Sector',
    options: sectorOptions([]),
    showDropdownPrepend: false,
  },
});

export const appointmentField = ref<FormField>({
  active: true,
  name: 'appointment_type',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een aanstelling in' }),
  data: {
    id: 'appointment',
    label: 'Aanstelling',
    options: appointmentOptions(),
    showDropdownPrepend: false,
  },
});

export const institutionNameField = ref<FormField>({
  active: true,
  name: 'institution_name',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een naam in' })
    .min(1, { message: 'Vul een naam in' })
    .max(128, { message: 'Naam mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'institution_name',
    label: 'Naam zorginstelling',
    inputType: 'text',
    placeholder: 'Naam zorginstelling',
  },
});

export const institutionPlaceField = ref<FormField>({
  active: true,
  name: 'institution_place',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een plaats in' })
    .min(1, { message: 'Vul een plaats in' })
    .max(128, { message: 'Plaats mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'institution_place',
    label: 'Plaats zorginstelling',
    inputType: 'text',
    placeholder: 'Plaats zorginstelling',
  },
});

export const descriptionField = ref<FormField>({
  active: true,
  name: 'description',
  formType: FormFieldType.TEXTAREA,
  validation: zod
    .string()
    .max(512, { message: 'Omschrijving mag maximaal 512 karakters zijn' })
    .optional(),
  width: 100,
  data: {
    id: 'description',
    label: 'Toelichting (optioneel)',
    inputType: 'textarea',
    rows: 4,
    placeholder: 'Leg uit wat je hier geleerd hebt',
  },
});

export const dateField = ref<FormField>({
  width: 100,
  active: true,
  formType: FormFieldType.DOUBLE,
  name: 'date',
  validation: zod
    .object({
      start_date: zod.string(),
      end_date: zod.string(),
    })
    .refine(
      (data): boolean =>
        !isAfter(
          DateTime.fromISO(data.start_date).startOf('day'),
          DateTime.now().startOf('day'),
        ),
      {
        message:
          'De datum waarop je werkervaring start kan niet in de toekomst liggen',
      },
    )
    .refine(
      (data): boolean =>
        !isAfter(
          DateTime.fromISO(data.start_date),
          DateTime.fromISO(data.end_date),
        ),
      {
        message: 'De startdatum kan niet na de einddatum liggen',
      },
    ),
  data: {
    id: 'date',
    label: 'Datum van/tot',
    icon: 'time',
    items: [
      {
        name: 'start_date',
        formType: FormFieldType.INPUT,
        data: {
          id: 'start',
          inputType: 'date',
          max: formatYmd(DateTime.now()),
        },
      },
      {
        name: 'end_date',
        formType: FormFieldType.INPUT,
        data: {
          id: 'end',
          inputType: 'date',
          min: formatYmd(DateTime.now()),
        },
      },
    ],
  },
});

export const singleDateField = ref<FormField>({
  active: false,
  name: 'start_date',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .refine(
      (data): boolean =>
        !isAfter(
          DateTime.fromISO(data).startOf('day'),
          DateTime.now().startOf('day'),
        ),
      {
        message:
          'De datum waarop je werkervaring start kan niet in de toekomst liggen',
      },
    ),
  width: 100,
  data: {
    id: 'start_date',
    label: 'Datum van',
    inputType: 'date',
    preIcon: 'agenda',
    max: formatYmd(DateTime.now()),
  },
});
