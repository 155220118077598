import useInterface from '@app/stores/interface';
import useNotificationsStore from '@app/stores/notifications';
import { setUserId } from '@app/utils/firebase-analytics';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { Auth } from '@pidz/api';
import { UserLoginState } from '@pidz/enums';
import { useInvoice, useUserStore } from '@pidz/stores';
import { reactive, watch } from 'vue';
import useImpersonation from './use-impersonation';
import useLogout from './use-logout';
import useRouting from './use-routing';

export interface UserState {
  loginState:UserLoginState;
  assumeUser:string|null;
  initialized:Promise<void>;
}

let triggerInitialized:Function;

const state:UserState = reactive({
  loginState: UserLoginState.LoggedOut,
  assumeUser: null,
  initialized: new Promise((ok) => {
    triggerInitialized = ok;
  }),
});

export const updateFreelancerUnpaidInvoices = async () => {
  const user = await useUserStore().getCurrentUser();

  if (!user) {
    return;
  }

  if (user.user_type === 'agency_worker') {
    state.loginState = UserLoginState.LoggedInFreelancer;
    return;
  }

  const invoiceStore = useInvoice();
  await invoiceStore.updateUnpaidInvoices();
  if (invoiceStore.unpaidInvoiceCount === 0) {
    if (state.loginState !== UserLoginState.LoggedInFreelancer) {
      state.loginState = UserLoginState.LoggedInFreelancer;
    }
  } else {
    state.loginState = UserLoginState.LoggedInFreelancerUnpaid;
  }
};

const updateUser = async () => {
  const user = await useUserStore().getCurrentUser();

  if (!user) {
    return;
  }

  setUserId(user.uuid);

  if (['admin', 'franchisee'].includes(user.user_type)) {
    const assumeUser = state.assumeUser ?? sessionStorage.getItem('assumeUser');
    if (assumeUser) {
      state.assumeUser = assumeUser;
      await updateFreelancerUnpaidInvoices();
    } else {
      state.loginState = UserLoginState.LoggedInAdmin;
    }
  } else {
    await updateFreelancerUnpaidInvoices();
  }
};

export const updateLoginState = async () => {
  const currentSession = await Auth.getCurrentSession();
  if (currentSession) {
    await updateUser();
  } else {
    state.loginState = UserLoginState.LoggedOut;
  }
};

export const login = async (email:string, password:string) => {
  await Auth.signIn(email, password);
  await updateLoginState();
  await useNotificationsStore().registerDevice();
};

export const { logout } = useLogout(state, updateUser);
export const { setImpersonation, impersonationConfigurator } = useImpersonation(state);

const { enforceAllowedRoute } = useRouting(state);
watch(() => state.loginState, () => enforceAllowedRoute());
watch(() => state.assumeUser, (newValue) => {
  if (newValue) {
    updateFreelancerUnpaidInvoices();
  }
});

export default async () => {
  await useNotificationsStore().registerFirebase();
  await updateLoginState();
  enforceAllowedRoute();
  triggerInitialized();
  useInterface().setInitialized();

  await App.addListener('appStateChange', async ({ isActive }) => {
    if (isActive) {
      const interfaceStore = useInterface();
      const status = await Network.getStatus();
      await interfaceStore.setNetworkStatus(status.connected);
      updateLoginState();
    }
  });
};
