<template>
  <div class="shift-detail-row-icon-expandable">
    <div class="top-row" @click="isOpen = !isOpen">
      <component :is="icon" class="icon" />
      <div class="title">
        <slot name="title"></slot>
      </div>
      <span :class="['chevron', {'down': !isOpen}]"><general-chevron/></span>
    </div>

    <div :class="['bottom-row', {'open': isOpen}]">
      <div class="expanded-content">
        <slot name="bottom-row"></slot>
      </div>
    </div>
  </div>

</template>

<script lang="ts" setup>
import GeneralChevron from '@app/icons/GeneralChevronWhite.vue';
import { ref } from 'vue';

defineProps<{
  icon: any;
}>();

const isOpen = ref(false);

</script>
<style lang="scss">
.shift-detail-row-icon-expandable {
  width: 100%;
  background-color: var(--pidz-white);

  display: flex;
  flex-direction: column;

  border-bottom: solid 1px var(--pidz-grey-light);

  .top-row {
    display: flex;
    align-items: center;
    min-height: 60px;

    .icon {
      margin: 0 18px;
      color: var(--pidz-blue)
    }

    .title {
      color: var(--pidz-black);
      flex-grow: 2;
    }

    .chevron {
      display: flex;
      margin: 0 18px;
      transition: all 200ms ease-in;
      color: var(--pidz-blue);

      &.down {
        color: var(--pidz-black);
        transform: rotate(-180deg);
      }
    }
  }

  .bottom-row {
    height: 0px;
    overflow: hidden;

    transition: 200ms ease-in;

    &.open {
      height: 80px;
      overflow: auto;
      transition: 200ms ease-in;
    }

    .expanded-content {
      margin-left: 64px;

      display: flex;
      flex-direction: column;

      a {
        color: var(--pidz-blue);
        margin-bottom: 12px;
      }
    }
  }
}
</style>
