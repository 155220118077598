<template>
  <drawer class="invoice-drawer" vertical>
    <template #buttons>
      <template v-if="showSetPaidStatusButton">
        <async-button class="light" @click="props.setPaidStatus">
          {{ paidStatusButtonText }}
        </async-button>
      </template>
      <template v-if="canSendReminder">
        <async-button class="send-reminder-button light"
                      @click="props.sendInvoiceReminder">
          Stuur herinnering
        </async-button>
      </template>
      <template v-if="showSendInvoiceButton">
        <form-section v-if="!invoiceData.automatic_invoice_number">
          <label for="input-invoice-number">Factuurnummer</label>

          <input v-if="isInvoiceNumberEdittable"
                 id="input-invoice-number"
                 type="input"
                 placeholder="Factuurnummer"
                 enterkeyhint="done"
                 :value="modelValue"
                 @input="
                   $emit(
                     'update:modelValue',
                     ($event.target as HTMLInputElement).value,
                   )
                 "/>
          <span v-else>
            {{ creditInvoiceNumber }}
          </span>
        </form-section>

        <div v-if="isInvoiceNumberWarningVisible">
          Dit is geen geldig factuurnummer. Een factuurnummer is maximaal 32
          karakters en mag alleen cijfers, letters en de karakters '-' en '_'
          bevatten.
        </div>

        <p v-if="isFactoringCompanyUnavailable" class="factoring-company-error">
          Ophalen betaalstatus bij The Factoring Company mislukt. Probeer het
          later nog eens.
        </p>
        <div class="links">
          <async-button class="light"
                        :disabled="!canSendInvoice"
                        @click="props.sendInvoice">
            Factuur versturen
          </async-button>
          <async-button v-if="
                          isCreditInvoice &&
                            !isCreditInvoicePaid &&
                            isPrefinancedByFactoringCompany
                        "
                        class="light"
                        :disabled="
                          isPayCreditButtonDisabled || isFactoringCompanyUnavailable
                        "
                        @click="payCreditInvoice">
            Creditfactuur betalen
          </async-button>
        </div>
      </template>
    </template>
  </drawer>
</template>

<script lang="ts" setup>
/* eslint-disable max-lines */
import AsyncButton from '@app/components/AsyncButton.vue';
import Drawer from '@app/components/Drawer.vue';
import FormSection from '@app/components/FormSection.vue';
import resetAllOverviewPages from '@app/utils/reset-all-overview-pages';
import { InvoiceStatus } from '@pidz/enums';
import { useInvoice } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed, ref } from 'vue';
import { Invoice } from '../types';
import InvoicePayCreditModal from './InvoicePayCreditModal.vue';

const props = defineProps<{
  setPaidStatus: Function;
  sendInvoiceReminder: Function;
  sendInvoice: Function;
  isCreditInvoice: boolean;
  isCreditInvoicePaid: boolean;
  isFactoringCompanyUnavailable: boolean;
  invoiceData: Invoice;
  modelValue: string;
  creditInvoiceNumber?: string;
}>();

const invoiceStore = useInvoice();

const isPayCreditButtonDisabled = ref(false);
defineEmits(['setPaidStatus', 'update:modelValue', 'payCreditInvoice']);
const modal = useModalController();

const canSendReminder = computed(() => props.invoiceData.can_send_reminder);
const isPrefinancedByFactoringCompany = computed(
  () => props.invoiceData.prefinance_company === 'factoring_company',
);

const showSetPaidStatusButton = computed(
  () => ![
    InvoiceStatus.FINISHED,
    InvoiceStatus.CREDITED,
    InvoiceStatus.FORCED_CREDITED,
  ].includes(props.invoiceData.status),
);

const paidStatusButtonText = computed(() => (props.invoiceData.status !== InvoiceStatus.PAID
  ? 'Zet op betaald'
  : 'Zet op niet betaald'));

const showSendInvoiceButton = computed(() => [
  InvoiceStatus.FINISHED,
  InvoiceStatus.CREDITED,
  InvoiceStatus.FORCED_CREDITED,
].includes(props.invoiceData.status));

const isInvoiceNumberValid = computed(
  () => /^[a-z0-9-_]+$/i.test(props.modelValue) && props.modelValue.length <= 32,
);

const isInvoiceNumberWarningVisible = computed(
  () => props.modelValue && !isInvoiceNumberValid.value,
);

const isInvoiceNumberEdittable = computed(
  () => !props.isCreditInvoice
    || (props.isCreditInvoicePaid && props.invoiceData.sheet_approved),
);

const canSendInvoice = computed(() => {
  if (!props.isCreditInvoicePaid) {
    return false;
  }
  if (!props.invoiceData.sheet_approved) {
    return false;
  }
  if (
    !props.invoiceData.automatic_invoice_number
    && !isInvoiceNumberValid.value
  ) {
    return false;
  }
  return true;
});

const payCreditInvoice = async () => {
  try {
    isPayCreditButtonDisabled.value = true;
    const creditDetails = await invoiceStore.factoringCompanyCreditPaymentLink(
      props.invoiceData.invoice_id,
      `pidz-app://freelancer.creditInvoice.payment/${props.invoiceData.sheet_id}`
    )
    isPayCreditButtonDisabled.value = false;
    modal.open(InvoicePayCreditModal, {
      creditDetails,
      invoiceNumber: props.modelValue,
    });
    resetAllOverviewPages();
  } catch (e) {
    modal.close();
    isPayCreditButtonDisabled.value = true;
  }
};
</script>

<style lang="scss">
.invoice-drawer {
  grid-auto-flow: row;
  input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  input:disabled {
    opacity: 0.66;
  }

  .factoring-company-error {
    font-size: 13px;
    margin: 0px;
  }

  .drawer-loader {
    display: flex;
    justify-content: space-around;
    animation: rotate 1s ease-in-out infinite;
  }
}
</style>
