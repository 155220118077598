import { configCognito } from '@app/utils/configure-cognito';
import { Preferences } from '@capacitor/preferences';
import { useFeatureflagsStore} from '@pidz/stores';
import { defineStore } from 'pinia';

let environmentSetTrigger:Function|null = null;
export const environmentSet = new Promise((ok) => {
  environmentSetTrigger = ok;
});

export default defineStore('environment', {
  state: (): { selected: Environment | null, all: Environment[] | null } => ({
    selected: null,
    all: null,
  }),
  actions: {
    async setActiveEnvironment(env: Environment) {
      configCognito(env);
      this.selected = env;
      (environmentSetTrigger as Function)();
      await Preferences.set({ key: 'environment', value: JSON.stringify(env) });
      const featureFlagsStore = useFeatureflagsStore();
      featureFlagsStore.initRemoteFlags();
    },
  },
});
