<template>
  <form class="form" :class="[className]">
    <template v-for="(row, i) in formData.rows">
      <div v-if="row.type === RowType.ROW" :key="i"
           class="form__row">
        <template v-for="(item, row_i) in row.items" >
          <div v-if="item.formType === FormFieldType.DOUBLE && item.data.items && item.active" :key="row_i"
               class="form__item" :style="{ 'width': item.width ? `${item.width}%` : '100%' }">
            <p-double-form-field :id="{
                                   first: item.data.items[0].data.id,
                                   second: item.data.items[1].data.id
                                 }"
                                 :input-type="{
                                   first: item.data.items[0].data.inputType,
                                   second: item.data.items[1].data.inputType
                                 }"
                                 :name="{
                                   first: `${item.name}.${item.data.items[0].name}`,
                                   second: `${item.name}.${item.data.items[1].name}`
                                 }"
                                 :max="{
                                   first: item.data.items[0].data.max,
                                   second: item.data.items[1].data.max
                                 }"
                                 :min="{
                                   first: item.data.items[0].data.min,
                                   second: item.data.items[1].data.min
                                 }"
                                 :label="item.data.label"
                                 :icon-name="item.data.icon"
                                 :error-message="doubleFieldErrors(item.name, item.data.items)"
                                 :disabled="item.data.disabled || disableForm"/>
          </div>
          <div  v-if="isSingleField(item.formType) && item.active" :key="row_i"
                class="form__item" :style="{ 'width': item.width ? `${item.width}%` : '100%' }">
            <p-form-field :id="item?.data?.id"
                          :name="item.name"
                          :label="item?.data?.label"
                          :icon="item?.data?.icon"
                          :pre-icon="item?.data?.preIcon"
                          :input-type="item.data.inputType"
                          :form-type="item.formType"
                          :options="item.data.options"
                          :placeholder="item.data.placeholder"
                          :show-dropdown-prepend="item.data.showDropdownPrepend"
                          :rows="item.data.rows"
                          :min="item.data.min"
                          :max="item.data.max"
                          :disabled="item.data.disabled || disableForm"
                          :class="[
                            {'input__checkbox':
                              item.formType === FormFieldType.CHECKBOX || item.formType === FormFieldType.TOGGLE
                            },
                            {'form-field__icon': item.data.preIcon}
                          ]"
                          :error-message="errors?.[item.name]"/>
          </div>
        </template>
      </div>
      <div v-if="row.type === RowType.BREAK" :key="i"
           class="form__break">
        <p-ruler margin-bottom="16px"/>
      </div>
    </template>
    <div v-if="!disableButtons" class="form__button-row">
      <p-button class="form__button form__button--cancel" outlined
                @click="$emit('cancel')">{{ cancelButtonText }}</p-button>
      <p-button class="form__button form__button--save" :disabled="isDisabled"
                @click.prevent="onSubmit">{{ submitButtonText }}</p-button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { RowType, FormFieldType } from '@pidz/enums';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { Ref, computed } from 'vue';
import * as zod from 'zod';
import { PRuler } from '../../atoms';
import { PButton } from '../../molecules';
import { PDoubleFormField, PFormField } from '../../organisms'

const props = withDefaults(defineProps<{
  formData: PidzFormData<any> // TODO: dynamic interface?
  submitButtonText?: string
  cancelButtonText?: string
  className?: string
  disableButtons?: boolean
  disableForm?: boolean
}>(), {
  submitButtonText: 'Opslaan',
  cancelButtonText: 'Annuleren',
  disableButtons: false,
  disabledForm: false
});

const schema = computed(() => {
  let zodSchema: {[key: string]: zod.ZodTypeAny} = {};
  // Add field validations
  props.formData.rows.forEach((row: Row) => {
    if (row.type === RowType.ROW) {
      row.items.forEach((item: FormField) => {
        if (item.active && item.name) {
          zodSchema = { ...zodSchema, [item.name]: item.validation };
        }
      });
    }
  });
  // Add global validations
  let zodS:zod.ZodTypeAny = zod.object(zodSchema);

  if (props.formData.globalValidations.length === 0) return zodS;

  props.formData.globalValidations.forEach((validation: GlobalValidation<any>) => {
    zodS = zodS.refine(validation.fn, validation.msg);
  });
  return zodS;
});

const validationSchema = computed(() => toTypedSchema(
  schema.value
));

const initialValues = computed(() => props.formData.values);

const {
  meta,
  isValidating,
  values,
  errors,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  submitCount,
  validate,
  setFieldError,
} = useForm({
  validationSchema,
  initialValues
});

defineExpose({
  meta,
  isValidating,
  validate,
  values,
  errors,
  setFieldValue,
  setFieldError,
  handleSubmit,
  isSubmitting,
  submitCount
});

const emit = defineEmits(['cancel', 'submit']);

const onSubmit = async () => {
  const submit = handleSubmit(async (formValues: any) => {
    if (isValidating.value || !meta.value.valid) {
      return;
    }
    emit('submit', formValues);
  });
  if (submit) {
    await submit();
  }
};

const isSingleField = (type: FormFieldType): boolean => type !== FormFieldType.DOUBLE;

const isDisabled: Ref<boolean> = computed(() => isValidating.value || isSubmitting.value);

const doubleFieldErrors = (name: string, items: DoubleFormFieldItems[]) => errors.value?.[name]
  || errors.value?.[`${name}.${items[0].name}`]
  || errors.value?.[`${name}.${items[1].name}`];

</script>
<style lang="scss" scoped>
.form{
  &__row{
    display: flex;

    &:not(:empty){
      margin-bottom: 16px;
    }
  }

  &__item{
    display: flex;
    margin-right: 16px;
    width: 100%;

    &:last-of-type{
      margin-right: 0;
    }

    :deep(.p-form-dropdown){
      width: 100%;
    }
  }

  &__button-row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 0 32px;

    &--web{
      position: absolute;
      bottom: -20px;
      margin: 0px -32px;
    }

    > * {
      flex-basis: 100%;
    }
  }
}
</style>
