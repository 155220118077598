import { ShiftType } from '@pidz/enums';

export default [
  {
    name: 'shifts',
    path: '/shifts',
    redirect: { name: 'shifts overview' },
    meta: { title: 'Opdrachten' },
    children: [
      {
        name: 'shifts overview',
        path: '/shifts',
        redirect: { name: 'incidental shifts' },
        meta: { title: 'Opdrachten' },
        children: [
          {
            name: 'incidental shifts',
            path: '/shifts/incidental',
            component: () => import('@app/pages/shifts/ShiftOverviewPage.vue'),
            meta: {
              title: 'Opdrachten',
              type: ShiftType.TYPE_INCIDENTAL
            },
          },
          {
            name: 'periodical shifts',
            path: '/shifts/periodical',
            component: () => import('@app/pages/shifts/ShiftOverviewPage.vue'),
            meta: {
              title: 'Opdrachten',
              type: ShiftType.TYPE_PERIODICAL
            },
          },
          {
            name: 'shift',
            path: '/shifts/:id',
            component: () => import('@app/pages/shifts/ShiftDetailPage.vue'),
            meta: {
              title: 'Opdracht',
              backButton: true,
            },
          },
        ],
      },
      
    ]
  }
]