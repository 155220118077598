<template>
  <layout-desktop-wrapper>
    <language-form ref="form" 
                   :form-data="formDataLanguage" 
                   :language-item="languageItem"
                   is-edit/>
    <form-delete-row name="Taalvaardigheid" :on-delete="form?.onDelete"
                     class="border-t border-neutral-200"/>
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import FormDeleteRow from '@app/components/FormDeleteRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { LanguageForm } from '@pidz/shared/components';
import { 
    globalValidations, 
    rows,
  } from '@pidz/shared/forms/cv/language';
import { useCVStore } from '@pidz/stores';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const form = ref<typeof LanguageForm>();

const route = useRoute()
const cvStore = useCVStore();

const languageItem = computed(() => cvStore.getLanguageProficiencyById(Number(route.params.id)));

const formatLanguage = (language: LanguageProficiency): LanguageForm => ({
  language_uuid: language.language_uuid,
  skill_level: language.skill_level
})

const formDataLanguage: PidzFormData<NewLanguagesForm> = {
  globalValidations,
  values: formatLanguage(languageItem.value as LanguageProficiency),
  rows
}

const loadStores = async () => {
  await cvStore.loadResume();
};

onMounted(async () => {
  await loadStores();
});
</script>
<style lang="scss" scoped>
.language{
  &__delete-btn {
    background-color: var(--pidz-white);
    padding: 32px 16px;
  }
  &__delete-text {
    color: var(--pidz-black);
  }
}
</style>
  