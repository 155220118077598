<template>
  <p-banner-list :banners="deniedReasonBanners"/>
  <layout-desktop-wrapper>
    <personal-form ref="form" :form-data="formDataPersonal"
                   is-edit/>
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { AssetStatus } from '@pidz/enums';
import { PersonalForm } from '@pidz/shared/components'
import {
  globalValidations,
  rows,
} from '@pidz/shared/forms/profile/personal';
import { useAssetStore, useUserStore } from '@pidz/stores';
import { computed, ref } from 'vue';

const form = ref<typeof PersonalForm>();
const userStore = useUserStore();

const formDataPersonal: PidzFormData<ProfilePersonal> = {
  globalValidations,
  values: userStore.getPersonalDetails(),
  rows,
};

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)

const deniedReasonBanners = computed(() => {
  const assets = useAssetStore().getAssetsForProfile

  return [
    ...userStore.isUserFreelancer ? 
      assets?.personal?.insuranceDocument as Asset[] : [],
    ...userStore.isUserFreelancer ? 
      assets?.personal?.insuranceDocumentPaymentProof as Asset[] : [],
    ...assets?.personal?.vogDocument as Asset[],
  ]
  .filter((asset) => asset.status === AssetStatus.DISAPPROVED)
  .map((asset) => {
    return {
      type: 'warning',
      title: `Reden afkeuring (${asset.type?.name})`,
      text: asset.deny_reason
    }
  })
})

const goBack = () => form.value?.onCancel()
defineExpose({ goBack })
</script>
