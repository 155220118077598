export default [
  {
    name: 'sheets',
    path: '/sheets',
    redirect: { name: 'sheets overview' },
    meta: { title: 'Urenregistraties' },
    children: [
      {
        name: 'sheets overview',
        path: '/sheets',
        component: () => import('@app/pages/sheets/SheetOverviewPage.vue'),
        meta: { title: 'Urenregistraties' },
      },
      {
        name: 'sheet',
        path: '/sheets/:id(\\d+)',
        component: () => import('@app/pages/sheets/SheetDetailPage.vue'),
        meta: {
          title: 'Urenregistratie',
          backButton: true,
        },
      },
      {
        name: 'add sheetrow',
        path: '/sheets/:id(\\d+)/new',
        component: () => import('@app/pages/sheets/SheetEditPage.vue'),
        meta: {
          title: 'Nieuw gewerkte uren',
          backButton: true,
        },
      },
      {
        name: 'edit sheet',
        path: '/sheets/:id(\\d+)/edit/:sheetRowIndex',
        component: () => import('@app/pages/sheets/SheetEditPage.vue'),
        meta: {
          title: 'Gewerkte uren',
          backButton: true,
        },
      },    
      {
        name: 'add microsheetrow',
        path: '/sheets/:id(\\d+)/row/new',
        component: () => import('@app/pages/sheets/MicroSheetRowEditPage.vue'),
        meta: {
          title: 'Nieuw gewerkte uren',
          backButton: true,
        },
      },
      {
        name: 'edit microsheetrow',
        path: '/sheets/:id(\\d+)/row/:rowUuid',
        component: () => import('@app/pages/sheets/MicroSheetRowEditPage.vue'),
        meta: {
          title: 'Gewerkte uren',
          backButton: true,
        },
      },
    ]
  },




 
]