export default [
  {
    name: 'rates',
    path: '/settings/rates',
    redirect: { name: 'rates overview' },
    children: [
      {
        name: 'rates overview',
        path: '/settings/rates',
        component: () => import('@app/pages/settings/rates/RatesOverviewPage.vue'),
        meta: { title: 'Tarieven' },
      },
      {
        name: 'rates standard-rates edit',
        path: '/settings/rates/standard/edit',
        component: () => import('@app/pages/settings/rates/EditStandardRates.vue'),
        meta: {
          title: 'Standaard tarieven aanpassen',
          backButton: true,
        },
      },
      {
        name: 'rates different-rates add',
        path: '/settings/rates/different/add',
        component: () => import('@app/pages/settings/rates/AddDifferentRates.vue'),
        meta: { 
          title: 'Afwijkend tarief toevoegen' ,
          backButton: true,
        },
      },
      {
        name: 'rates different-rates edit',
        path: '/settings/rates/different/edit/:organisation(\\d+)/:department(\\d+)?',
        component: () => import('@app/pages/settings/rates/EditDifferentRates.vue'),
        meta: { 
          title: 'Afwijkend tarief bewerken' ,
          backButton: true,
        },
      },
    ]
  }
];
