export default [
  {
    name: 'notifications',
    path: '/settings/notifications',
    redirect: { name: 'notifications overview' },
    children: [
      {
        name: 'notifications overview',
        path: '/settings/notifications',
        component: () => import('@app/pages/settings/notifications/NotificationsOverviewPage.vue'),
        meta: { title: 'Notificaties' },
      }
    ]
  },
];
