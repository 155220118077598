import { CvSection, CvSectionStatus } from '@pidz/enums';

// Add competency properties to the competency object
const getGroupExperience = (competencies: Competency[]) => {
  const experiencedCompetencies = competencies.filter(
    (competency) => competency.is_experienced,
  );
  if (experiencedCompetencies.length === 0) return 'Niet bekwaam';
  if (experiencedCompetencies.length === competencies.length) return 'Bekwaam';
  return 'Deels bekwaam';
};

// Add competency properties to the competency object
const getGroupCompetencies = (id: number, competencies: Competency[]) => {
  return competencies.filter((comp) => comp.type.category.id === id).map((comp) => {
    return {
      ...comp,
      is_experienced_initial: comp.is_experienced,
    }
  })
}

export const getGroupedCompetencies = (competencies: Competency[]): CompetencyGroup[] => {
  const uniqueGroupIds = [...new Set(competencies.map((comp) => comp.type.category.id))];
  // group competencies by category
  const groups = uniqueGroupIds.map((id) => {
    return {
      id,
      name: competencies.find((comp) => comp.type.category.id === id)!.type.category.name,
      competencies: getGroupCompetencies(id, competencies),
    }
  })
  // add experience to each group and return
  return groups.map((group) => {
    return {
      ...group,
      experience: getGroupExperience(group.competencies),
    }
  })
};

export const getBannerInfo = (entity: string, status: number = 0, deny_reason: string = 'Geen reden opgegeven') => {
  switch (status) {
    case 1:
      return { text: `Deze ${entity} is goedgekeurd en mag niet meer bewerkt worden`, type: 'success' };
    case 2:
      return { title: 'Reden afgekeurd:', text: deny_reason || 'Geen reden opgegeven', type: 'warning' };
    case 3:
      return { 
        text: `Deze ${entity} is verlopen en kan niet meer worden bewerkt.
        Verwijder deze ${entity} en voeg een nieuwe toe.`,
        type: 'warning' };
    default:
      return undefined;
  }
}

export const isBadgeVisible = (cvSection: CVSection[], type: CvSection): boolean => {
  if(!cvSection.length && type === CvSection.TRAINING) return false
  if(!cvSection.length) return true
  if(isSectionWithStatus(cvSection)) return cvSection.some((item) => 
    item.status === CvSectionStatus.DECLINED || 
    item.status === CvSectionStatus.EXPIRED || 
    item.nearing_expiration_date
  );
  return false
};

const isSectionWithStatus = (cvSection: CVSection[]): cvSection is CVSectionWithStatus[] => 
  (cvSection[0] as CVSectionWithStatus).status !== undefined;

