import { sortFormOptionsAlphabetical } from '../../utils';
import { OTHER_EDUCATION_ID } from './const';

export const educationOptions = (subjects: EducationSubject[]): FormDropDownOption[] => 
  subjects.map(
    (education, index): FormDropDownOption => ({
      id: index,
      value: education.id,
      label:
        education.name || `Geen label beschikbaar voor id: ${education.id}`,
    }),
  )
  .sort(sortFormOptionsAlphabetical)
  .sort((a, b) => {
    if (a.value === OTHER_EDUCATION_ID) return 1;
    if (b.value === OTHER_EDUCATION_ID) return -1;
    return 0;
  })

