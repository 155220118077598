<template>
  <div ref="calendarEvent" class="calendar-event"
       :class="[
         CalendarEventType[props.type].toLowerCase(),
         variant, isNightEvent ? 'has-night-event' : '',
         isSquashed ? 'squashed' : ''
       ]"
       @click="handleClick">
    <div class="details">
      <div class="event-details">
        <p class="event-title">{{ title }}</p>
        <p v-if="location" class="event-location">{{ location }}</p>
      </div>
      <div v-if="isAllDay" class="all-day">
        <p>Hele dag</p>
      </div>
      <div v-else class="time-details">
        <p>{{ startTime }}</p>
        <span>&nbsp;-&nbsp;</span>
        <p>{{ endTime }}</p>
      </div>
    </div>
    <div v-if="isNightEvent">
      <p-icon icon-name="nightshift" :size="iconSize"
              color="white" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { CalendarEventType } from '@pidz/enums';
import { computed, onMounted, ref } from 'vue';

const emits = defineEmits<{(e: 'handleClick', event: any): void
}>();
const props = withDefaults(defineProps<{
  uuid?: number
  variant: 'small' | 'medium' | 'large' | 'extra-large' | 'empty';
  type: CalendarEventType
  title?: string
  startTime?: string
  endTime?: string
  isAllDay?: boolean
  isNightEvent?: boolean
  location?: string
  topBorderRadius?: boolean
  bottomBorderRadius?: boolean
}>(), {
  variant: 'medium',
  type: 2,
  isAllDay: false,
  isNightEvent: false,
  topBorderRadius: true,
  bottomBorderRadius: true
});

const isSquashed = ref(false);
const calendarEvent = ref<HTMLElement | null>(null);
const borderRadiusTop = computed(() => (props.topBorderRadius ? '5px 5px' : '0px 0px'));
const borderRadiusBottom = computed(() => (props.bottomBorderRadius ? '5px 5px' : '0px 0px'));

const iconSize = computed(() => {
  if (props.variant === 'extra-large') {
    return 'large';
  }
  return 'medium';
});

const handleClick = () => {
  emits('handleClick', { uuid: props.uuid });
};

const checkSquashed = () => {
  if (calendarEvent.value) {
    const calendarEventHeight = calendarEvent.value.clientHeight;
    const calendarEventWidth = calendarEvent.value.clientWidth;

    isSquashed.value = calendarEventHeight < 30 || calendarEventWidth < 120;
  }
};

onMounted(() => {
  checkSquashed();
});

</script>
<style scoped lang="scss">
.calendar-event {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: min-content;
  gap: 8px;
  border-radius: v-bind(borderRadiusTop) v-bind(borderRadiusBottom);
  color: white;
  overflow: hidden;

  .details {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 4px;

    .event-details {
      display: flex;
      flex-direction: column;
      gap: 2px;

      & p {
        margin: 0px;
        padding: 0px;
      }

      .event-title {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .event-location,
    .all-day,
    .time-details {
      font-size: 12px;
    }

    .time-details {
      display: flex;

      & p {
        margin: 0px;
        padding: 0px;
      }
    }

  }

  &.instantly_bookable {
    background: var(--pidz-calendar-green);
  }

  &.shift {
    background: var(--pidz-calendar-green);
  }

  &.freelancer_request {
    background: var(--pidz-calendar-blue);
  }

  &.unavailable {
    background: var(--pidz-calendar-grey);
  }

  &.squashed {
    overflow: hidden;

    .details {
      .event-details {
        .event-title,
        .event-location {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-all;
        }
      }

      .time-details {
        display: grid;
        grid-template-columns: auto 1fr;
        flex-direction: column;
      }
    }
  }

  &.large {
    grid-template-columns: auto min-content;
    justify-content: space-between;
    min-height: 62px;

    .details {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 16px;
      padding: 12px 16px;

      .time-details {
        flex-direction: column;

        span {
          display: none;
        }
      }

      .time-details,
      .all-day {
        text-align: center;
      }
    }
  }

  &.extra-large {
    .details {
      gap: 4px;
      padding: 24px;

      .event-details {
        gap: 4px;
        .event-title {
          font-size: 20px;
        }
      }

      .time-details,
      .all-day {
        font-size: 16px;
      }
    }
  }

  &.large,
  &.extra-large {
    .event-location {
      font-size: 16px;
    }
  }

  &.has-night-event {
    grid-template-columns: auto min-content;

    .details {
      padding-right: 0px;
    }

    > :last-child {
      padding: 2px 2px 0px 0px ;
    }

    &.medium,
    &.large {
      > :last-child {
        padding: 4px 8px 0px 0px ;
      }
    }

    &.extra-large {
      > :last-child {
        padding: 16px 16px 0px 0px ;
      }
    }
  }
  &.empty {
    padding: 6px;
    > * {
      display: none;
    }
  }
}
</style>
