<template>
  <hr class="hr"/>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

const props = withDefaults(defineProps<{
  height?: string,
  width?: string,
  marginTop?: string,
  marginBottom?: string
}>(), {
  height: '1px',
  width: '100%',
  marginTop: '0px',
  marginBottom: '0px'
});

const height = ref(props.height);
</script>
<style lang="scss">
.hr {
    height: v-bind(height);
    width: v-bind(width);
    margin-top: v-bind(marginTop);
    margin-bottom: v-bind(marginBottom);
    background-color: var(--pidz-grey-light);
    border: none;
}
</style>
