<template>
  <p-content-section :has-add-button="true" @add="emit('add-language')">
    <template #header>
      <div class="languages-header">
        <p-badge v-if="isBadgeVisible"
                 class="languages-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading icon="language" class="font-bold">Taalvaardigheid</p-heading>
      </div>
    </template>
    <template v-if="language_skills?.length" #content>
      <div class="languages">
        <p-table class="languages__table"
                    :columns="columns"
                    :rows="rows"
                    :borders="['row']"
                    omit-header
                    responsive>
          <template #row_lang="{ value }">
            <strong>{{ value.lang }}</strong>
          </template>
          <template #row_actions="{ value }">
            <div class="flex-end">
              <router-link :to="{ name: 'cv languages edit', params: { id: value.id } }">
                <p-icon icon-name="pencil" color="blue" />
              </router-link>
            </div>
          </template>
        </p-table>
      </div>
    </template>
    <template #empty>
      <p>
        <strong>Je hebt nog geen taalvaardigheid toegevoegd</strong>
        <br /><br />
        Klik hierboven op het plusje om je eerste taal toe te voegen aan je CV.
        De eerste taal die je moet invullen is Nederlands, daarna kan je ook
        andere talen opgeven.
      </p>
    </template>
  </p-content-section>
</template>
<script lang="ts" setup>
import { useCVStore } from '@pidz/stores';
import { computed } from 'vue';
const cvStore = useCVStore();

const props = defineProps<{
  language_skills?: LanguageProficiency[];
  isBadgeVisible: boolean;
}>();

const emit = defineEmits(['add-language']);

const columns = computed(() => {
  return [
    {
      field: 'lang',
      label: 'Taal',
    },
    {
      field: 'level',
      label: 'Niveau',
    },
    {
      field: 'actions',
      label: '',
    },
  ];
});

const rows = computed(() => {
  return props.language_skills
    ?.sort(
      (a: LanguageProficiency, b: LanguageProficiency) =>
        a.skill_level - b.skill_level,
    )
    .map((skill, index) => {
      const lang = cvStore.getLanguageByUuid(skill.language_uuid) || null;
      return {
        id: skill.id,
        lang: lang?.display_name || 'Onbekend',
        level: skills.get(skill.skill_level) || 'Onbekend',
      };
    });
});

const skills = new Map([
  [1, 'Moedertaal of tweetalig'],
  [2, 'Professioneel niveau'],
  [3, 'Basisniveau'],
]);
</script>
<style lang="scss" scoped>
.languages-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.languages {
  color: var(--pidz-black);
  @media screen and (min-width: 768px) {
    margin-right: 2rem;
  }
}
</style>
