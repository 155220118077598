<template>
  <p-card title="Adresgegevens" 
          class="address" 
          has-button
          icon-name="pencil"
          icon-size="large" 
          :rounded="false" 
          title-size="lg"
          :padding="padding" 
          @button-click="emit('edit-address')">
    <template #header>
      <p-heading variant="secondary" class="font-bold"></p-heading>
    </template>

    <template #content>
      <p-heading level="h4" font-size="md"
                 variant="black" class="sub-title font-bold">Woonadres</p-heading>
      <p-table class="address__table"
               :colgroup="[{ span: 1, width: '180px' }]"
               :columns="columns"
               :rows="addressRows"
               :omit-header="true"
               vertical-align="top"
               compact
               disable-horizontal-spacing>
        <template #row_type="row">
          <span class="address__type--bold">{{ row.value.label }}</span>
        </template>
        <template #row_value="row">
          <span>{{ row.value.value }}</span>
        </template>
      </p-table>
      <template v-if="userStore.isUserFreelancer">
        <p-ruler margin-top="16px"/>

        <p-heading level="h4" font-size="md"
                   variant="black" class="sub-title font-bold">Bedrijfadres</p-heading>
        <p v-if="!userStore.isBusinessAddressDifferent">Gelijk aan woonadres</p>
        <p-table v-else
                 class="address__table"
                 :colgroup="[{ span: 1, width: '180px' }]"
                 :columns="columns"
                 :rows="businessRows"
                 :omit-header="true"
                 vertical-align="top"
                 compact
                 disable-horizontal-spacing>
          <template #row_type="row">
            <span class="address__type--bold">{{ row.value.label }}</span>
          </template>
          <template #row_value="row">
            <span>{{ row.value.value }}</span>
          </template>
        </p-table>
      </template>
    </template>
  </p-card>
</template>
<script lang="ts" setup>
import { useUserStore } from '@pidz/stores';
import { countries } from '@pidz/utils';
import { computed } from 'vue';

const emit = defineEmits(['edit-address']);
const userStore = useUserStore();

const props = defineProps<{
  address: ProfileAddress;
  business: ProfileAddress;
  padding: string;
}>();

const columns = [
  { field: 'type', label: 'type' },
  { field: 'value', label: 'waarde' },
];

const addressRows = computed(() => {
  return [
  {
    label: 'Straatnaam + nr.',
    value: `${props.address.street} ${props.address.houseNumber}`,
  },
  {
    label: 'Postcode',
    value: props.address.zipCode,
  },
  {
    label: 'Woonplaats',
    value: props.address.city,
  },
  {
    label: 'Land',
    value: (countries.get(props.address.country) as string) || '',
  },
  {
    label: 'Postbus (optioneel)',
    value: props.address.poBox ? props.address.poBox : '-'
  }
]
});

const businessRows = computed(() => [
  {
    label: 'Straatnaam + nr.',
    value: `${props.business.street} ${props.business.houseNumber}`,
  },
  {
    label: 'Postcode',
    value: props.business.zipCode,
  },
  {
    label: 'Woonplaats',
    value: props.business.city,
  },
  {
    label: 'Land',
    value: props.business.country,
  },
  {
    label: 'Postbus (optioneel)',
    value: props.business.poBox,
  },
]);
</script>
<style scoped lang="scss">
.address{
  &__table{
    font-size: 16px
  }
  &__type {
    &--bold {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.sub-title {
  margin: 1rem 0 0.5rem 0;
  padding-top: 24px;
  padding-bottom: 16px;
}
</style>
