import { DateTime, formatYmd, isAfter } from '@pidz/date';
import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';
import { registrationTypesOptions } from './form-options';
import { defaultRegistrationValidation } from './form-validation';

export const registrationTypeField = ref<FormField>({
  active: true,
  name: 'type',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een registratie in' }),
  data: {
    id: 'type',
    label: 'Registratie',
    options: registrationTypesOptions([]),
    showDropdownPrepend: false,
  },
});

export const registrationNumberField = ref<FormField>({
  active: true,
  name: 'registration_number',
  formType: FormFieldType.INPUT,
  validation: defaultRegistrationValidation,
  width: 100,
  data: {
    id: 'registration_number',
    label: 'Registratienummer',
    inputType: 'text',
    placeholder: 'Bvb 123456789',
  },
});

export const acquiredAtField = ref<FormField>({
  active: true,
  name: 'acquired_at',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .refine(
      (data): boolean =>
        !isAfter(
          DateTime.fromISO(data).startOf('day'),
          DateTime.now().startOf('day'),
        ),
      {
        message:
          'De datum waarop je bent geregistreerd mag niet in de toekomst liggen',
      },
    ),
  width: 100,
  data: {
    id: 'acquired_at',
    label: 'Datum geregistreerd',
    inputType: 'date',
    preIcon: 'agenda',
    max: formatYmd(DateTime.now()),
  },
});
