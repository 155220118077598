import type { App, Component as ComponentType } from 'vue';
// import './assets/scss/fonts.scss';
// import './assets/scss/app.scss';
import components from './components/index';

const installComponents = (componentsToInstall: { [key: string]: ComponentType }, app: App) => {
  Object.entries(componentsToInstall).forEach(([componentName, component]) => {
    app.component(componentName, component);
  });
};

export default {
  install: (app: App) => {
    installComponents(components, app);
  }
};
