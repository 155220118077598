<template>
  <a class="in-app-browser-link" @click="openLink">
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import { Browser } from '@capacitor/browser';

const props = defineProps<{
  href: string | undefined;
}>();

const openLink = async (e:Event) => {
  if (props.href) {
    e.preventDefault();
    await Browser.open({ url: props.href });
  }
};
</script>
