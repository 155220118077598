<template>
  <header class="top-bar">
    <section class="controls">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="center">
        <slot v-if="$slots.center" name="center"></slot>
        <div v-else class="error">center slot is required</div>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </section>
  </header>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.top-bar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: var(--pidz-blue);
  font-size: 14px;
  color: var(--pidz-white);
  height: 52px;

  .controls {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto 1fr;
    padding: 7px 14px;
    line-height: 1;
  }

  .left,
  .center,
  .right {
    display: flex;
    align-items: center;
    .error {
      color: var(--pidz-red);
    }
  }

  .left {
    justify-content: flex-start;
    font-family: inherit;
  }

  .center {
    justify-content: space-around;
    align-items: center;

  }

  .right {
    justify-content: flex-end;
    position: relative;
  }
}
</style>
