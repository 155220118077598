<template>
  <button class="async-button" :disabled=disabled
          @click="$attrs.onClick && asyncFunction($event,$attrs.onClick as Function)">
    <slot v-if="!interfaceStore.asyncButtonsDisabled"></slot>
    <infinity-spinner v-else class="spinner"></infinity-spinner>
  </button>
</template>
<script lang="ts" setup>

import InfinitySpinner from '@app/components/InfinitySpinner.vue';
import useInterface from '@app/stores/interface';
import { computed } from 'vue';

const interfaceStore = useInterface();

const props = defineProps<{disabled?: boolean}>();

const disabled = computed(() => (props.disabled ? props.disabled : interfaceStore.asyncButtonsDisabled));

const asyncFunction = async (e: Event, fn: Function) => {
  e.stopImmediatePropagation();
  try {
    interfaceStore.asyncButtonsDisabled = true;
    await fn();
  } finally {
    interfaceStore.asyncButtonsDisabled = false;
  }
};

</script>
<style lang="scss">
  .async-button {
    border: none;
    font: inherit;
    padding: 12px;
    background-color: transparent;
    text-align: center;
    line-height: 1;

    .spinner {
      font-size: 1rem;
      height: 1rem;
      margin: auto;
      rotate: 0deg;
      animation: rotate-spinner 2000ms ease-in-out infinite;
    }

    &:disabled {
      opacity: 0.4
    }

    .light {
      background-color: var(--pidz-white);
    }

    &.button-outline, &.light {
      svg {
          stroke: var(--pidz-blue);
      }
    }
  }

  @keyframes rotate-spinner {

    100% {
      rotate: 360deg;
    }
  }
</style>
