<template>
  <div class="md:w-[240px] md:min-w-[240px] absolute md:sticky z-40 right-4
  md:right-auto md:left-0 bottom-24 md:bottom-0 mb-safe md:mb-0 md:top-0 md:max-h-dvh md:h-dvh 
  rounded-lg md:rounded-none md:opacity-100 md:translate-y-0
  transition-all md:transition-none duration-300
  bg-white md:bg-blue md:text-white overflow-y-auto flex flex-row md:pointer-events-auto"
       :class="[
         { 'translate-y-0 opacity-100': menuOpen },
         { 'translate-y-4 opacity-0 pointer-events-none': !menuOpen },
       ]">
    <div class="flex flex-col w-full">
      <div class="hidden md:flex items-center min-h-14 p-4">
        <div class="relative">
          <pidz-logo />
          <environment-indicator class="absolute -right-4 -top-1" />
        </div>
      </div>

      <nav class="flex-grow p-2 md:p-0">
        <component :is="item.component"
                   v-for="(item, i) in menuItems"
                   :key="i"
                   v-bind="isExternalLink(item) && { href: item.to, target: '_blank' }"
                   :to="item.to"
                   class="flex items-center w-full gap-4 px-4 py-2 md:py-3 text-neutral md:text-white
                   md:hover:bg-blue-900 transition relative"
                   :class="[{ 'hidden md:flex': item.hideInMoreMenu }]"
                   active-class="font-bold !text-blue-900 md:!text-white md:bg-blue-900
                   [&_.activeIcon]:flex [&_.normalIcon]:hidden"
                   @click="$emit('closeMenu')">
          <p-badge v-if="item.openCount"
                   :content="item.openCount"
                   :variant="item.badgeVariant ? item.badgeVariant : 'info'"
                   class="absolute top-2 left-10 -translate-x-1/2"/>
          <template v-if="item.icon">
            <span class="hidden md:flex">
              <p-icon :icon-name="item.icon" color="white" />
            </span>
            <span class="normalIcon flex md:hidden">
              <p-icon :icon-name="item.icon" />
            </span>
            <span class="activeIcon hidden md:!hidden">
              <p-icon :icon-name="item.icon" color="blue" />
            </span>
          </template>
          <span class="text-xs md:text-sm md:font-bold">{{ item.name }}</span>
        </component>
      </nav>
      <div class="hidden md:flex flex-col items-center justify-center text-center text-xs py-4 px-2">
        <span>© {{ currentYear }} - Smiling Gents</span>
        <span>All rights reserved</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import EnvironmentIndicator from '@app/components/EnvironmentIndicator.vue';
import PidzLogo from '@app/components/PidzLogo.vue';
import { useDashboardStore, useUserStore } from '@pidz/stores';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const dashboardStore = useDashboardStore();
const userStore = useUserStore();

defineProps<{ menuOpen: boolean }>();
defineEmits(['closeMenu']);

const currentYear = DateTime.now().toFormat('y');


const isExternalLink = (item: MenuItem): boolean => item.component === 'a';

const menuItems = computed<MenuItem[]>(() => [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    to: { name: 'dashboard' },
    hideInMoreMenu: true,
    component: 'router-link',
  },
  {
    name: 'Agenda',
    icon: 'agenda',
    to: { name: 'calendar' },
    hideInMoreMenu: false,
    component: 'router-link',
  },
  {
    name: 'Uitnodigingen',
    icon: 'mail',
    to: { name: 'invitations' },
    openCount: dashboardStore.invitationCount,
    hideInMoreMenu: true,
    component: 'router-link',
  },
  {
    name: 'Opdrachten',
    icon: 'jobs',
    to: { name: 'shifts' },
    hideInMoreMenu: true,
    component: 'router-link',
  },
  {
    name: 'Urenregistraties',
    icon: 'time',
    to: userStore.isUserAgencyWorker ? 'https://helloflexpeople.helloflex.com/user/login' : { name: 'sheets' },
    hideInMoreMenu: true,
    component: userStore.isUserAgencyWorker ? 'a' : 'router-link'
  },
  {
    name: userStore.isUserAgencyWorker ? 'Loonstroken' : 'Facturen',
    icon: 'invoice',
    to: userStore.isUserAgencyWorker ? 'https://helloflexpeople.helloflex.com/user/login' : { name: 'invoices' },
    openCount: dashboardStore.openInvoiceCount,
    hideInMoreMenu: false,
    component: userStore.isUserAgencyWorker ? 'a' : 'router-link'
  },
  {
    name: 'Contact',
    icon: 'call',
    to: { name: 'contact' },
    hideInMoreMenu: false,
    component: 'router-link',
  },
  {
    name: 'FAQ',
    icon: 'faq',
    to: { name: 'faq' },
    hideInMoreMenu: false,
    component: 'router-link',
  },
]);
</script>
