import { useApi } from '@pidz/api';

const api = useApi();

export const mollieService = {

  isMollieMandateRequired: async (): Promise<boolean> => api.jsonrpc({
    url: 'organisation:isMollieMandateRequired',
  }),

  isMollieMandateValid: async (): Promise<{ bank_account_is_same_as_mandate: boolean, mandate_bank_account: string }> => api.jsonrpc({
    url: 'organisation:mollieMandateIsValid',
  }),

  revokeAndRequestNewMollieMandate: async (redirectUrl: string): Promise<string> => api.jsonrpc({
    url: 'organisation:revokeAndRequestNewMollieMandate',
    params: { redirectUrl }
  }),

  mandatePaymentLink: async (redirect: string | null): Promise<string> => api.jsonrpc({
    url: 'organisation:createMollieMandate',
    params: { redirectUrl: redirect }
  }),
};
