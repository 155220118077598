import { useApi } from '@pidz/api';

const api = useApi();

export const dashboardService = {
  // not used?
  // getOpenSheets: async (maxResults: number) => api.jsonrpc({
  //   url: 'dashboard:getOpenSheets',
  //   params: { maxResults }
  // }),

  getNotificationCount: async (type: string): Promise<number> => api.jsonrpc({
    url: 'notification:getNotificationCount',
    params: { type }
  }),

  getCalendarShifts: async (): Promise<CalendarEvent[]> => api.jsonrpc({
    url: 'calendar:getCalendarShifts'
  }),
  getInactiveTaskList: async (): Promise<string[]> => api.jsonrpc({
    url: 'freelancer:getInactiveTaskList'
  }),
};
