<template>
  <div class="impersonation-overview-item" @click="setUser(data.uuid)">
    <span class="freelancer-name">{{data.name}}</span>
    <span class="freelancer-email">{{data.email}}</span>
  </div>
</template>
<script lang="ts" setup>
import { setImpersonation } from '@app/user-state';
import { useUserStore } from '@pidz/stores';

const props = defineProps<{
  data: impersonationOverviewItem
}>();

const setUser = (uuid: string) => {
  setImpersonation(uuid);
  useUserStore().setUserPermissions(props.data.type)
  window.impersonationId = uuid;
}
</script>
<style lang="scss">
.impersonation-overview-item {
  color: var(--pidz-body-text);
  background-color: var(--pidz-white);
  border-bottom: 1px solid var(--pidz-grey-light);
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 12px;

  .freelancer-name {
    font-weight: bold;
  }
}
</style>
