export const errorCodes = {
  600: {
    type: 'error',
    title: 'Oeps',
    message: 'Er is iets misgegaan',
  },
  601: {
    type: 'error',
    title: 'Oeps',
    message: 'Er is iets misgegaan',
  },
  602: {
    type: 'error',
    title: 'Oeps',
    message: 'Er is iets misgegaan',
  },
  606: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze opdracht is al geweigerd',
  },
  609: {
    type: 'error',
    title: 'Oeps',
    message: 'Alleen bestandstypes PDF, PNG, JPEG en HEIC zijn toegestaan'
  },
  801: {
    type: 'error',
    title: 'Oeps',
    message: 'Het ingevulde KVK nummer is niet geldig',
  },
  1001: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze opdracht is al ingevuld',
  },
  1002: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze opdracht overlapt met een andere opdracht',
  },
  1003: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze opdracht is al geaccepteerd',
  },
  1202: {
    type: 'error',
    title: 'Oeps',
    message: 'De opdracht die je wil toevoegen valt niet binnen de urenregistratie',
  },
  1203: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze (incidentele) opdracht valt niet binnen de urenregistratie',
  },
  1204: {
    type: 'error',
    title: 'Oeps',
    message: 'De pauze die je toevoegt valt binnen een andere pauze',
  },
  1205: {
    type: 'error',
    title: 'Oeps',
    message: 'De pauze die je wil toevoegen valt buiten je werktijden',
  },
  1206: {
    type: 'error',
    title: 'Oeps',
    message: 'De ingevulde minuren mogen niet binnen de gewerkte uren vallen',
  },
  1207: {
    type: 'error',
    title: 'Oeps',
    message: 'De ingevulde overuren moeten buiten de gewerkte uren vallen',
  },
  1302: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze urenregistratie is al een keer verstuurd',
  },
  1304: {
    type: 'error',
    title: 'Oeps',
    message: 'Dit factuurnummer is al in gebruik',
  },
  1305: {
    type: 'error',
    title: 'Oeps',
    message: 'De factuur kon niet worden verstuurd',
  },
  1310: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze urenregistratie heeft nog openstaande wijzigingen',
  },
  1312: {
    type: 'error',
    title: 'Oeps',
    message: 'Deze urenregistratie overlapt met een andere urenregistratie',
  },
  1402: { // situation seemingly impossible, overridden by 1403
    type: 'error',
    title: 'Oeps',
    message: 'Het ingevulde BIC-nummer bestaat niet',
  },
  1403: { // situation returns 600
    type: 'error',
    title: 'Oeps',
    message: 'De combinatie van je BIC- en IBAN-nummer is niet correct',
  },
  1605: {
    type: 'error',
    title: 'Oeps',
    message: 'Voor deze afdeling is al een afwijkend tarief ingevuld',
  },
  1607: {
    type: 'error',
    title: 'Oeps',
    message: 'Helaas, de postcode die je hebt ingevuld is niet geldig.',
  },
  1608: {
    type: 'error',
    title: 'Oeps',
    message: 'BTW nummer is niet correct',
  },
  1609: {
    type: 'error',
    title: 'Oeps',
    message: 'BTW nummer is niet gevonden',
  },
  1610: {
    type: 'error',
    title: 'Oeps',
    message: 'Telefoonnummer is niet correct',
  },
  1702: {
    type: 'error',
    title: 'Oeps',
    message: 'Er bestaat al een agenda-item op dit tijdstip',
  },
  1703: {
    type: 'error',
    title: 'Oeps',
    message: 'Geen geldige tijd ingevuld',
  },
  'Working hours should be within less than 24 hours.': {
    type: 'error',
    title: 'Oeps',
    message: 'De gewerkte tijd mag niet meer dan 24 uur bedragen',
  },

  'Working hours should be more than 0.': {
    type: 'error',
    title: 'Oeps',
    message: 'De gewerkte tijd moet minimaal 1 minuut zijn',
  },

  'Working hours should be equal to assignment hours.': {
    type: 'error',
    title: 'Oeps',
    message: 'De gewerkte tijd moet overeenkomen met de geplande tijd',
  },

  'Working hours should be within assignment hours.': {
    type: 'error',
    title: 'Oeps',
    message: 'De opdracht die je wil toevoegen valt niet binnen de urenregistratie',
  },

  'Working hours should be within sheet hours.': {
    type: 'error',
    title: 'Oeps',
    message: 'Deze (incidentele) opdracht valt niet binnen de urenregistratie',
  },

  'Breaks should be within working hours.': {
    type: 'error',
    title: 'Oeps',
    message: 'De pauze die je wil toevoegen valt buiten je werktijden',
  },

  'Breaks should not overlap.': {
    type: 'error',
    title: 'Oeps',
    message: 'De pauze die je toevoegt valt binnen een andere pauze',
  },

  'Worked during sleep hours are not allowed during this assignment.': {
    type: 'error',
    title: 'Oeps',
    message: `Deze opdracht ondersteund geen 'Gewerkt tijdens slaapuren'`,
  },

  'Hours worked during sleep should not be more than 4.': {
    type: 'error',
    title: 'Oeps',
    message: `'Gewerkt tijdens slaapuren' mag maximaal 4 uur zijn`,
  },

  'Working hours can not overlap with other working hours.': {
    type: 'error',
    title: 'Oeps',
    message: 'Deze urenregistratie overlapt met een andere urenregistratie',
  },
};
