import { RowType } from '@pidz/enums';
import {
  streetField,
  houseNumberField,
  zipCodeField,
  cityField,
  poBoxField,
  countryField,
  sameAddressField,
  businessAddress_StreetField,
  businessAddress_HouseNumberField,
  businessAddress_ZipCodeField,
  businessAddress_CityField,
  businessAddress_PoBoxField,
  businessAddress_CountryField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<AddressForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [streetField.value, houseNumberField.value],
  },
  {
    type: RowType.ROW,
    items: [zipCodeField.value, cityField.value],
  },
  {
    type: RowType.ROW,
    items: [poBoxField.value, countryField.value],
  },
  {
    type: RowType.ROW,
    items: [sameAddressField.value],
  },
  {
    type: RowType.ROW,
    items: [
      businessAddress_StreetField.value,
      businessAddress_HouseNumberField.value,
    ],
  },
  {
    type: RowType.ROW,
    items: [
      businessAddress_ZipCodeField.value,
      businessAddress_CityField.value,
    ],
  },
  {
    type: RowType.ROW,
    items: [
      businessAddress_PoBoxField.value,
      businessAddress_CountryField.value,
    ],
  },
];
