import { Registrations } from '@pidz/enums';
import * as zod from 'zod';

export const defaultRegistrationValidation = zod.string({ required_error: `Vul een registratienummer in`})
.min(
  1, { message: `Vul een registratienummer in` }
).max(
  128, { message: `Registratienummer mag maximaal 128 tekens zijn` }
)

export const validationMap = new Map<Registrations, Zod.ZodString>([
  [Registrations.BIG_REGISTRATION, 
    zod.string({ required_error: 'Vul een BIG registratie in'})
      .min(1, { message: 'Vul een BIG registratie in'})
      .length(11, { message: 'Een BIG registratie is precies 11 nummers'})
      .regex(/^\d{11}$/, { message: 'Een BIG registratie is alleen maar nummers '})
  ],
  [Registrations.HEALTHCARE_PORTAL_REGISTRATION,     
    zod.string({ required_error: 'Vul een vestigingsnummer in'})
      .min(1, { message: 'Vul een vestigingsnummer in'})
      .length(12, { message: 'Een vestigingsnummer is precies 12 nummers'})
      .regex(/^\d{12}$/, { message: 'Een vestigingsnummer is alleen maar nummers '})
  ],
  [Registrations.HKZ_REGISTRATION, 
    zod.string({ required_error: 'Vul een HKZ registratienummer in'})
      .min(10, { message: 'Een HKZ registratienummer is minimaal 10 tekens'})
      .max(20, { message: 'Een HKZ registratienummer is maximaal 20 tekens'})
  ],
  [Registrations.KIWA_REGISTRATION,
    zod.string({ required_error: 'Vul een Kiwa keurmerk registratienummer in'})
      .min(8, { message: 'Een Kiwa keurmerk registratienummer is minimaal 8 tekens'})
      .max(20, { message: 'Een Kiwa keurmerk registratienummer is maximaal 20 tekens'})
  ],
  [Registrations.QUALITY_V_AND_V_REGISTRATION,
    zod.string({ required_error: 'Vul een kwaliteitsregister V&V in'})
      .min(6, { message: 'Een registratienummer voor het kwaliteitsregister V&V is minimaal 6 tekens'})
      .max(12, { message: 'Een registratienummer voor het kwaliteitsregister V&V is maximaal 12 tekens'})
  ],
  [Registrations.SKJ_REGISTRATION,
    zod.string({ required_error: 'Vul een SKJ registratienummer in'})
      .min(8, { message: 'Een SKJ registratienummer is minimaal 8 tekens'})
      .max(15, { message: 'Een SKJ registratienummer is maximaal 15 tekens'})
  ],
  [Registrations.VSR_REGISTRATION, 
    zod.string({ required_error: 'Vul een VSR registratienummer in'})
      .min(1, { message: 'Vul een VSR registratienummer in'})
      .length(11, { message: 'Een VSR registratienummer is precies 11 tekens'})
  ],
]);