import { useApi } from '../../utils/use-api';

const api = useApi()

export const calendarService = {
  getEvents: (dates: { start: string, end: string}): Promise<CalendarEvent[]> => api.jsonrpc({
    url: 'calendar:getAvailability', 
    params: dates
  }),

  addCalendarItem: (addCalendarItem:{ 
    calendarItemModel: CalendarEventRequest 
  }): Promise<PersonalCalendarEvent> => api.jsonrpc({
    url: 'calendar:addCalendarItem', 
    params: addCalendarItem
  }),

  updateCalendarItem: (updateCalendarItem: { 
    calendarItemModel: PersonalCalendarEvent 
  }): Promise<{result: boolean}> => api.jsonrpc({
    url: 'calendar:updateCalendarItem',
    params: updateCalendarItem
  }),

  updateCalendarItemRepeat: (updateCalendarItemRepeat: { 
    calendarItemModel: PersonalCalendarEvent 
  }): Promise<{result: boolean}> => api.jsonrpc({
    url: 'calendar:updateCalendarItemRepeat',
    params: updateCalendarItemRepeat
  }),

  removeAvailability: (calendarId: { 
    calendarId: number 
  }): Promise<{result: boolean}> => api.jsonrpc({
    url: 'calendar:removeAvailability',
    params: calendarId
  }),

  removeCalendarItemsByRepeat: (calendarRepeatId: { 
    id: number 
  }): Promise<{result: boolean}> => api.jsonrpc({
    url: 'calendar:removeCalendarItemsByRepeat',
    params: calendarRepeatId
  }),

  getCalendarLink: (): Promise<string> => api.jsonrpc({
    url: 'calendar:getWebCalURL',
  })
}