<template>
  <layout-desktop-wrapper>
    <reload-page :reload="reload" />
    <div class="flex flex-col gap-6 w-full">
      <standard-rates-section class="standard-rates-section"
                              :rates="standardRates"
                              :padding="'16px'"
                              @edit-standard-rates="handleRoute('standard-rates', 'edit')"/>

      <different-rates-section class="different-rates-section"
                               :rates="differentRates"
                               padding="16px"
                               @add-different-rates="handleRoute('different-rates', 'add')"/>
    </div>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import {
  DifferentRatesSection,
  StandardRatesSection,
} from '@pidz/shared/components';
import { useRatesStore } from '@pidz/stores';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const ratesStore = useRatesStore();
const router = useRouter();

const handleRoute = (section: string, type: string) => {
  router.push({name: `rates ${section} ${type}`});
};

const reload = async () => await ratesStore.fetchFreelancerFunctionRates();

onMounted(async () => await reload());

const standardRates = computed(() =>
  ratesStore.getFreelancerStandardFunctionRates(),
);
const differentRates = computed(() =>
  ratesStore.getFreelancerDifferentFunctionRates(),
);
</script>

<style lang="scss" scoped>
  .rates-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
</style>
