<template>
  <section
    class="calendar-remove-event">
    <p-icon
      icon-name="bin"
      size="large"
      color="blue"/>
    <div
      class="text">{{removalText}}</div>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const removalText = computed(() => (route.meta.editRecurrence
  ? 'Agenda-reeks verwijderen'
  : 'Agenda-item verwijderen'));

</script>

<style lang="scss">
.calendar-remove-event {
  display: flex;
  align-items: center;

  .text {
    color: var(--pidz-black);
    margin-left: 18px;
  }
}
</style>
