/* eslint-disable max-lines */
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('reload', () => {
  const isPulling = ref<boolean>(false);
  const setIsPulling = (value: boolean) => isPulling.value = value;

  const $reset = () => {    
  };

  return {
    isPulling,

    setIsPulling,

    $reset
  }
});
