import { DateTime } from '@pidz/date';
import * as zod from 'zod';

const errors = {
  occurences: {
    invalidNumberType: 'Vul een getal in',
    outOfRange: 'Vul hier een getal in van 1 t/m 99',
  },
  end: {
    wrongEndDate: 'Einddatum van de herhaling kan niet voor de startdatum van het agenda-item liggen.',
  },
};

export function getZodRepeatSchema(event: CalendarFormItemModel) {
  return zod.object({
    occurences:
      zod.number(
        { invalid_type_error: errors.occurences.invalidNumberType },
      ).min(
        1,
        { message: errors.occurences.outOfRange },
      ).max(
        99,
        { message: errors.occurences.outOfRange },
      ).optional(),
    end:
      zod.coerce.string().refine(
        (data) =>
          DateTime.fromISO(data) >= DateTime.fromISO(event.start.date),
        { message: errors.end.wrongEndDate },
      ).optional(),
  });
}
