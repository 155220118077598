<template>
  <div>
    <p-banner :text="sheetStatusInfo.get(sheetStatus)?.text ?? 'onbekend'"
                 :type="sheetStatusInfo.get(sheetStatus)?.className ?? 'error'">
      <span v-if="remarkCustomer">
        <a class="link-to-modal" @click="openRemarksModal">Bekijk de reden van afkeuring</a>.
      </span>
    </p-banner>
  </div>
</template>
<script lang="ts" setup>
import { addDuration, createDurationObject, localeString, isSame } from '@pidz/date';
import { SheetStatus } from '@pidz/enums';
import { useModalController } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import SheetRemarkCustomerModal from './SheetRemarkCustomerModal.vue';

const props = defineProps<{
  sheetStatus: number;
  month: string;
  remarkCustomer?: string;
}>();

const modal = useModalController();

const sendableDate = computed(() => {
  const lCurrentMonth = DateTime.fromISO(props.month);
  const lnextMonth = addDuration(lCurrentMonth, createDurationObject({ month: 1 }));
  const isSameYear = isSame(lCurrentMonth, lnextMonth, 'year');

  const formats = isSameYear
    ? ({ day: 'numeric', month: 'long' } as const)
    : ({ day: 'numeric', month: 'long', year: 'numeric' } as const);

  return localeString(lnextMonth, formats, { locale: 'nl' });
});

const sheetStatusInfo = new Map<
  SheetStatus,
  { className: 'info' | 'success' | 'error' | 'warning'; text: string }
>([
  [
    SheetStatus.NEW_SHEET,
    {
      className: 'info',
      text: `Je kunt deze urenregistratie vanaf ${sendableDate.value} versturen.`,
    },
  ],
  [
    SheetStatus.REQUEST_BY_FREELANCER,
    { className: 'info', text: `Je urenregistratie is verstuurd.` },
  ],
  [
    SheetStatus.APPROVED_BY_CUSTOMER,
    {
      className: 'success',
      text: `Je urenregistratie is goedgekeurd. Je kunt deze nu factureren.`,
    },
  ],
  [
    SheetStatus.DECLINED_BY_CUSTOMER,
    {
      className: 'warning',
      text: `Je urenregistratie is afgekeurd. Wijzig je gegevens en verstuur deze opnieuw.`,
    },
  ],
  [
    SheetStatus.INVOICED,
    {
      className: 'info',
      text: `Je urenregistratie is gefactureerd. Bekijk de factuur voor meer informatie.`,
    },
  ],
  [SheetStatus.CLOSED, { className: 'info', text: `Je urenregistratie is gesloten.` }],
  [
    SheetStatus.CLOSED_WITHOUT_SHEETROW,
    { className: 'info', text: `Je urenregistratie is gesloten zonder uren.` },
  ],
]);

const openRemarksModal = () => {
  modal.open(SheetRemarkCustomerModal, {
    remarkCustomer: props.remarkCustomer,
  });
};
</script>
<style scoped lang="scss">
.link-to-modal {
  color: var(--pidz-body-text);
  cursor: pointer;
  text-decoration: underline;
}
</style>
