import { useApi } from '@pidz/api';

const api = useApi();

export const adminService = {
  getFreelancers: (getFreelancersRequest): Promise<getFreelancersResponse> => api.jsonrpc({
    url: 'admin:getFreelancers', 
    params: getFreelancersRequest
  }),
}

