<template>
  <modal>
    <h2 class="font-bold">Weet je het zeker?</h2>
    <p>
      Weet je zeker dat je uit wilt loggen?
      <span v-if="isApp">Je ontvangt dan geen notificaties meer.</span> 
    </p>
    <template #footer>
      <button class="button-outline" @click="modalController.close">Annuleer</button>
      <async-button @click="logoutClick">Uitloggen</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { logout } from '@app/user-state';
import { Capacitor } from '@capacitor/core';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();

const logoutClick = async () => {
  await logout();
  modalController.close();
};

const isApp = Capacitor.getPlatform() !== 'web';
</script>
