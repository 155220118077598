<template>
  <sheet-block-list-section v-model="value"
                            title="Pauze"
                            add-title="Pauze toevoegen"
                            edit-title="Pauze bewerken"
                            :block-factory="blockFactory"
                            :validate="validate"
                            :disabled="!workingHoursBlock.start"/>
</template>
<script lang="ts" setup>
import { addHourToIsoDate } from '@pidz/date';
import { SheetBlockType } from '@pidz/enums';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import {
  validateStartBeforeEnd,
  validateInsideWorkHours,
  validateNoOverlap,
} from '../utils/block-validations';
import SheetBlockListSection from './SheetBlockListSection.vue';

const props = defineProps<{
  modelValue: any[];
  workingHoursBlock: any;
  shiftId: number;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

const validate = (block: any, otherBlocks: any[]) => {
    validateStartBeforeEnd(block);

  const newWorkingHoursBlock: DateTimeRange = {
    start: typeof props.workingHoursBlock.start === 'string' 
    ? props.workingHoursBlock.start
    : DateTime.fromJSDate(props.workingHoursBlock.start).toISO()!,
    end: props.workingHoursBlock.end,
  }

  validateNoOverlap(
    block,
    otherBlocks,
    'De pauze kan niet met andere pauzes overlappen.',
  );

  validateInsideWorkHours(
    block,
    newWorkingHoursBlock,
    'De pauze kan niet buiten de werktijden vallen.',
  );
};

const blockFactory = () => ({
  start: props.workingHoursBlock.start,
  end: addHourToIsoDate(props.workingHoursBlock.start),
  type: SheetBlockType.TYPE_BREAK,
  shift_id: props.shiftId,
});
</script>
