<template>
  <section class="sheet-block-function">
    <label>Functie</label>
    <dropdown placeholder="Selecteer je functie" v-model="value"
              :options="functionOptions" name="functions" />
  </section>
</template>

<script setup lang="ts">
import Dropdown from '@app/components/Dropdown.vue';
import { computed } from 'vue';

const props = defineProps<{
  modelValue: number | string | null,
  functionOptions: {value: string | number, name: string}[],
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});
</script>

<style lang="scss">
.sheet-block-function {
  label {
    display: block;
    color: var(--pidz-black);
    margin-bottom: 4px;
    font-weight: bold;
  }

}
</style>
