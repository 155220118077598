<template>
  <p-form ref="contactForm" v-model:form-data="formData"
          submit-button-text="Verstuur"
          :disable-buttons="true"
          @cancel="onCancel"/>
</template>

<script lang="ts" setup>
import { ContactFormReason } from '@pidz/enums';
import { useFormFunctions } from '@pidz/shared/forms';
import { globalValidations, rows } from '@pidz/shared/forms/contact';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useContactStore } from '@pidz/stores';
import { ref } from 'vue';
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  Router,
  useRouter,
} from 'vue-router';

const contactStore = useContactStore();
const router: Router = useRouter();
const formData: PidzFormData<ContactForm> = {
  globalValidations,
  values: {
    contactType: 'message', 
    about: ContactFormReason.PERSON, 
    message: '', 
    anonymous: true
  },
  rows
}
const contactForm = ref<any>(null);

const formSubmitted = ref(false);
onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, false, 'send_contact_cancel'),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, false, 'send_contact_cancel'),
);

const onSubmit = async () => {
  contactForm?.value?.validate();
  if (contactForm?.value?.errors?.value?.length > 0) return;
  await contactStore.sendHotlineMail(contactForm?.value?.values);
  formSubmitted.value = true;
  succesToaster();
  rerouteToOverview(router, 'contact');
}

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  warnBeforeCancel,
} = useFormFunctions(
  contactForm,
  [],
  () => rerouteToOverview(router, 'contact'),
  () => Promise.resolve(),
  false,
  'melding versturen',
);

defineExpose({isSubmitDisabled, isSubmitting, onCancel, onSubmit})

</script>