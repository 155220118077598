<!-- eslint-disable vue/no-mutating-props -->
<template>
  <filter-overview title="Urenregistraties filteren op">
    <section>
      <h3 class="my-4 font-bold">Status:</h3>
      <option-grid v-model="modelValue.statuses" multi
                   :options="sheetStatusOptions" />
    </section>
    <section>
      <h3 class="my-4 font-bold">Maand:</h3>
      <inline-option-grid ref="inlineOptionsGrid">
        <div v-for="(option, i) in monthOptions" :key="i"
             class="options-group">
          <h4>{{ option.year }}</h4>
          <option-grid v-model="modelValue.searchMonths"
                       multi
                       :columns="3"
                       :options="option.months"/>
        </div>
      </inline-option-grid>
    </section>
  </filter-overview>
</template>
<script lang="ts" setup>
import FilterOverview from '@app/components/FilterOverview.vue';
import OptionGrid from '@app/components/OptionGrid.vue';
import InlineOptionGrid from '@app/components/filter/InlineOptionGrid.vue';
import { generateMonthOptions } from '@pidz/date';
import { SheetStatus } from '@pidz/enums';
import { DateTime } from 'luxon';
import { onMounted, ref } from 'vue';
import { ISheetFilterParams } from './types';

const foundingYear = 2012;
const endYear = DateTime.now().year + 3;
const monthOptions = ref(generateMonthOptions(foundingYear, endYear));
const inlineOptionsGrid = ref<InstanceType<typeof InlineOptionGrid> | null>(null);

defineProps<{ modelValue: ISheetFilterParams }>();

const sheetStatusOptions = [
  { name: 'Openstaand', value: SheetStatus.NEW_SHEET },
  { name: 'Wachten op goedkeuring', value: SheetStatus.REQUEST_BY_FREELANCER },
  { name: 'Goedgekeurd', value: SheetStatus.APPROVED_BY_CUSTOMER },
  { name: 'Afgekeurd', value: SheetStatus.DECLINED_BY_CUSTOMER },
  { name: 'Gefactureerd', value: SheetStatus.INVOICED },
  { name: 'Gesloten', value: SheetStatus.CLOSED },
];

onMounted(() => {
  if (!inlineOptionsGrid.value) {
    return;
  }
  const { offsetLeft } = inlineOptionsGrid.value.$el.children[DateTime.now().year - foundingYear];
  inlineOptionsGrid.value.$el.scrollLeft = offsetLeft;
});
</script>
