<template>  
  <div class="flex flex-col flex-grow w-full">
    <reload-page class="shift-detail-page"
                 :reload="reload"
                 :loading="loading"/>

    <div class="flex flex-col w-full bg-blue text-white px-4 pb-2 md:py-4">
      <h1 class="font-bold text-base break-words">{{ sheetData?.organisation_name }}</h1>
      <h2 class="text-sm break-words">{{ sheetData?.department_name }}</h2>
    </div>

    <sheet-banner v-if="!initialLoad && sheetStatus && sheetData"
                  :sheet-status="sheetStatus"
                  :month="sheetData.month"
                  :remark-customer="sheetData.remark_customer" class="w-full"/>

    <layout-desktop-wrapper no-padding-bottom>
      <div class="flex flex-col flex-grow w-full">
        <template v-if="!initialLoad && sheetData && rowsReady">
          <div v-if="hasSheetRows" class="sheetrows w-full">
            <h2 class="font-bold text-base p-4 md:p-0 pb-0">{{ formatMonth(DateTime.fromISO(sheetData.month)) }}</h2>
            <div class="w-full grid md:grid-cols-2 gap-x-4 p-4 md:px-0">
              <micro-sheet-row-card v-for="(sheetRow, i) in microSheetRows"
                                    :key="i"
                                    :sheet-id="sheetData.sheet_id"
                                    :sheet-row="sheetRow"
                                    :config="microSheetRowConfig(sheetRow)"/>
            </div>
          </div>
          <sheet-detail-empty v-else />
          <sheet-remark v-if="isAbleToAddRemark"
                        ref="sheetRemarksComponent"
                        v-model="sheetRemarks"/>
        </template>
      </div>
      <template v-if="!initialLoad && !sheetRemarksComponent?.open">
        <div class="sticky bottom-0">
          <floating-action-button v-if="sheetData?.has_periodical && sheetData.editable"
                                  :to="{ name: 'add microsheetrow' }"/>
          <sheet-drawer v-if="!initialLoad && sheetData"
                        :sheet-id="sheetData.sheet_id"
                        :is-sendable="sheetData.sendable"
                        :send-sheet="sendSheet"
                        :sheet-status="sheetData.status"
                        :earnings="sheetData.total_earnings"
                        :total-work-minutes="sheetData.total_work_minutes"
                        @close-sheet="closeSheet"/>
        </div>
      </template>
    </layout-desktop-wrapper>
  </div>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import FloatingActionButton from '@app/components/FloatingActionButton.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { SheetStatus } from '@pidz/enums';
import { useShiftStore, useSheetStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ShiftDiffModal from '../shifts/components/ShiftDiffModal.vue';
import { ShiftDiff, ShiftWorker } from '../shifts/types';
import CloseSheetModal from './components/CloseSheetModal.vue';
import MicroSheetRowCard from './components/MicroSheetRowCard.vue';
import SheetBanner from './components/SheetBanner.vue';
import SheetDetailEmpty from './components/SheetDetailEmpty.vue';
import SheetDrawer from './components/SheetDrawer.vue';
import SheetRemark from './components/SheetRemark.vue';
import { MicroSheetRow, MicroSheetRowConfig, SheetSummary } from './types';

const initialLoad = ref(true);
const loading = ref(false);

const route = useRoute();
const modal = useModalController();
const router = useRouter();
const shiftStore = useShiftStore();
const sheetStore = useSheetStore();

const sheetId = computed(() => Number(route.params.id));
const sheetData = computed(() => sheetStore.sheets[sheetStore.sheets.findIndex(
    element => element.sheet_id === sheetId.value,
)]);

const sheetSummary = ref<SheetSummary | null>(null);
const microSheetRows = ref<any[] | null>(null);

const hasSheetRows = computed(() => {
  if (!sheetData.value) {
    return undefined;
  }
  const rows = microSheetRows.value;
  return rows && rows.length > 0;
});

const microSheetRowConfig = (row: MicroSheetRow): MicroSheetRowConfig => {
  const assignment = sheetSummary.value?.assignments.find(
    (a) => a.uuid === row.assignmentUuid,
  );
  const sheet = sheetData.value;
  return {
    allowSleep: assignment?.is_sleep ?? false,
    allowOverUnder: !assignment?.is_periodical,
    allowTravel: sheet?.allow_travel_costs ?? false,
    allowEdit: sheet?.editable ?? false,
  };
};

const sheetRemarks = ref<string>('');
const sheetRemarksComponent = ref();

const formatMonth = (month: DateTime) => {
  return month.setLocale('nl-nl').toLocaleString({ month: 'long' });
};

const isAbleToAddRemark = computed(
  () => sheetData.value?.sendable && hasSheetRows.value,
);

const sheetStatus = computed(() => {
  if (
    sheetData.value?.status === SheetStatus.NEW_SHEET &&
    sheetData.value.sendable
  ) {
    return undefined;
  }
  if (
    sheetData.value?.status === SheetStatus.NEW_SHEET &&
    !sheetData.value.sendable
  ) {
    return 1;
  }
  if (
    sheetData.value?.status === SheetStatus.CLOSED &&
    sheetData.value.sheet_row_models.length === 0
  ) {
    return 7;
  }
  return sheetData.value?.status;
});

const checkForShiftDiffs = async ({
  shifts_pending_changes: changedShifts,
}) => {
  if (changedShifts.length === 0) {
    return [];
  }
  const [shiftId] = changedShifts;
  return Promise.all([
    shiftStore.getShift(shiftId),
    shiftStore.getShiftWorker(shiftId),
  ]);
};

const showShiftDiffModal = (
  shiftDiff: ShiftDiff,
  shiftWorker: ShiftWorker,
  reloadFn: Function,
) => {
  modal.open(ShiftDiffModal, {
    shiftDiff,
    shiftWorker,
    onAcceptHandler: async () => {
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      reloadFn();
    },
    onDeclineHandler: async () => {
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      reloadFn();
    },
  });
};

const rowsReady = computed(() => {
  return microSheetRows.value !== null;
});

const reload = async () => {
  loading.value = true;
  await sheetStore.getSheetById(sheetId.value);
  if (!sheetData.value) {
    return;
  }

  const [shift, shiftWorker] = await checkForShiftDiffs(sheetData.value);
  if (shift?.shift_diff && shiftWorker) {
    showShiftDiffModal(shift.shift_diff, shiftWorker, reload);
  }

  const { sheet_id, uuid: sheetUuid } = sheetData.value;
  sheetSummary.value = await sheetStore.getSheetSummary(sheet_id);
  microSheetRows.value = await sheetStore.getRows(sheetUuid);

  loading.value = false;
  initialLoad.value = false;
};

const closeSheet = async () => {
  let updatedSheet;
  modal.open(CloseSheetModal, {
    closeSheet: async () => {
      try {
        updatedSheet = await sheetStore.closeSheetById(sheetId.value);
        modal.close();
        logCustomEvent('sheet_closed_without_hours');
        await router.push({ name: 'sheets' });
      } catch (e: any) {
        modal.close();
        // handled by global error handler
      } finally {
        sheetStore.updateSheet(updatedSheet);
      }
    },
    hasSheetRows: hasSheetRows.value,
  });
};

const sendSheet = async () => {
  try {
    await sheetStore.sendSheetForApprovalById(
      sheetId.value,
      sheetRemarks.value,
    );
    logCustomEvent('sheet_sent_for_approval');
    await reload();
  } catch (e: any) {
    // handled by global error handler
  } finally {
    sheetStore.updateSheet(sheetData.value!); // is this one always up to date?
  }
};

onMounted(() => {
  reload();
});
</script>

<style lang="scss">
.sheet-detail-page {
  h2 {
    text-transform: capitalize;
    padding: 12px;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: var(--pidz-body-text);
  }

  h1.department {
    background: var(--pidz-blue);
    margin: 0;
    color: var(--pidz-white);
    padding: 0 24px 14px;
    word-break: break-word;
  }
}
</style>
