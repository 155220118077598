<template>
  <modal class="invoice-prefinance-choice-modal">
    <h2 class="font-bold">Voorfinancieren?</h2>
    <p class="mb-3">
      Aan het voorfinancieren van je factuur is 5% excl. btw van het
      factuurbedrag aan kosten verbonden.
    </p>
    <p>
      Wanneer je jouw factuur met voorfinanciering verstuurt ga je automatisch
      akkoord met de algemene voorwaarden van {{ termsTitle }}.
    </p>
    <span class="pre-finance-asset">
      <download-icon />
      <in-app-browser-link :href="factoringCompanyLink">
        Algemene voorwaarden {{ termsTitle }}
      </in-app-browser-link>
    </span>

    <checkbox-with-text v-model="prefinanceTermsAccepted">
      Ik ga akkoord met de algemene voorwaarden van {{ termsTitle }}, en ik geef
      akkoord op de bijbehorende kosten.
    </checkbox-with-text>

    <template #footer>
      <async-button :class="[{ inactive: !prefinanceTermsAccepted }, 'button-outline']"
                    @click="acceptWithPrefinance">
        Voorfinancieren
      </async-button>
      <async-button @click="acceptWithoutPrefinance">Nee, bedankt</async-button>
    </template>
  </modal>
</template>

<script setup lang="ts">
import AsyncButton from '@app/components/AsyncButton.vue';
import CheckboxWithText from '@app/components/CheckboxWithText.vue';
import InAppBrowserLink from '@app/components/InAppBrowserLink.vue';
import Modal from '@app/components/Modal.vue';
import { DownloadIcon } from '@pidz/icons';
import { computed, ref } from 'vue';

const props = defineProps<{
  sendInvoiceFinal: Function,
  prefinanceCompany: string,
}>();

const prefinanceTermsAccepted = ref(false);

const termsTitle = computed(() => (props.prefinanceCompany === 'freelance_factoring'
  ? 'Freelance Factoring'
  : 'The Factoring Company'));

const factoringCompanyLink = computed(() => (props.prefinanceCompany === 'freelance_factoring'
  ? 'https://mijnpidz.nl/build/files/algemene_voorwaarden_freelancefactoring.pdf'
  : 'https://mijnpidz.nl/build/files/algemene_voorwaarden_opdrachtgevers.pdf'));

const acceptWithPrefinance = async () => {
  if (prefinanceTermsAccepted.value) {
    await props.sendInvoiceFinal(true);
  }
};

const acceptWithoutPrefinance = () => props.sendInvoiceFinal(false);
</script>

<style lang="scss">
.black {
  color: var(--pidz-black);
  a {
    text-decoration: underline;
  }
}

.prefinance-approval-label {
  display: flex;
  align-items: center;
}

.pre-finance-asset {
  display: flex;
  align-items: center;

  margin: 12px 0px 18px 0;

  a {
    margin-left: 18px;
    text-decoration: underline;
  }
}
</style>
