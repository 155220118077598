<template>
  <input type="range" class="range-slider"
         min="0" :max="max"
         steps="1" :style="sliderWidth"
         :value="modelValue"
         @input="$emit('update:modelValue', Number(($event.target as HTMLInputElement).value))"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: number,
  max: number,
}>();

defineEmits(['update:modelValue']);

const sliderWidth = computed(() => `--slider-width: ${(props.modelValue / props.max) * 100}%`);
</script>

  <style scoped lang="scss">
  .range-slider {
    width: calc(100% - 10px);
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 1) var(--slider-width),
              rgba(0, 0, 0, .3) var(--slider-width));
    border-radius: 5px;
    height: 5px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: var(--pidz-white)
    }
  }
  </style>
