<template>
  <p-card title="Afwijkende tarieven"
          has-button
          icon-name="add"
          icon-size="large"
          :rounded="false"
          title-size="lg"
          :padding="padding"
          @button-click="emit('add-different-rates')">
    <template #content>
      <div v-if="organisations" class="different-rates">
        <template v-if="organisations.length">
          <div v-for="(organisation, index) in organisations"
               :key="index">
            <organisation-list-item :organisation="organisation"/>
            <p-ruler v-if="index !== organisations.length - 1"/>
          </div>
        </template>
        <template v-else>
          <p class="no-results">
            <strong>Je hebt nog geen afwijkende tarieven toegevoegd</strong>
            <br />Klik hierboven op het plusje om je eerste afwijkend tarief toe te voegen.
          </p>
        </template>
      </div>
      <div v-else class="spinner">
        <p-icon class="spinner__icon"
                :icon-name="'spinner'"
                size="large"
                color="blue"/>
      </div>
    </template>
  </p-card>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import OrganisationListItem from './OrganisationListItem.vue';

const props = defineProps<{
  padding: string;
  rates?: Rate[];
}>();

const emit = defineEmits(['add-different-rates']);

const organisations = computed(() => {
  if (!props.rates) return undefined;

  return Array.from(
    props.rates.reduce((map, rate) => {
      if (!map.has(rate.organisation_name!)) {
        map.set(rate.organisation_name!, {
          name: rate.organisation_name!,
          id: rate.organisation_id!,
          rates: [],
        });
      }
      map.get(rate.organisation_name!)!.rates.push(rate);
      return map;
    }, new Map<string, OrganisationDifferentRates>()).values()
  );
});
</script>

<style lang="scss" scoped>
.different-rates {
  margin-top: 16px;
}

.no-results {
  padding: 16px;
  text-align: center;
  font-size: 14px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  &__icon {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
