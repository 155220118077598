<template>
  <div class="option-grid">
    <label v-for="(option, i) in options" :key="i"
           :class="['option', isOptionSelected(option.value) && 'active']"
           :disabled="isOptionDisabled(option.value)"
    >
      <input v-if="multi" type="checkbox"
             :disabled="isOptionDisabled(option.value)"
             v-model="multiValue"
             :value="option.value"/>
      <input v-else type="radio"
             @click="checkForReset(option.value)"
             :value="option.value" v-model="singleValue"
      />
      {{option.name}}
    </label>
  </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';

const props = withDefaults(defineProps<{
  disabled?: number | string | Array<number | string>,
  modelValue: number | string | Array<string | number>,
  options: Array<{ value: number | string, name: string }>
  columns?: number,
  multi?: boolean,
}>(), {
  columns: 2,
  multi: false,
});

const isOptionDisabled = (value: number|string) => {
  if (props.disabled === undefined) { return false; }
  if (Array.isArray(props.disabled)) {
    return props.disabled.includes(value);
  }
  return props.disabled === value;
};

const emit = defineEmits(['update:modelValue']);

const singleValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

const multiValue = computed({
  get(): Array<string | number> {
    return props.modelValue as Array<string | number>;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

const isOptionSelected = (option: number | string) => {
  if (props.multi) return (props.modelValue as Array<number | string>).includes(option);
  return option === props.modelValue;
};

const checkForReset = (option: number | string) => {
  if (option === props.modelValue) {
    emit('update:modelValue', 0);
  }
};

</script>

<style lang="scss">
.option-grid {
  display: grid;
  grid-template-columns: repeat(v-bind(columns), 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  user-select: none;

  .option {
    height: 50px;
    border: 1px solid var(--pidz-white);
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    input {
      display: none;
    }

    &.active {
      background-color: var(--pidz-white);
      color: var(--pidz-blue);
    }
  }
}
</style>
