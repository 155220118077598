<template>
  <infinity-top-spinner v-if="loading" rotate
                        :animation-normal="100"/>
  <div class="flex flex-col w-full bg-blue text-white px-4 md:pb-2 md:pt-6">
    <h1 class="font-bold text-base break-words">{{ shiftData?.department.organisation_name }}</h1>
    <h2 class="text-sm break-words">{{ shiftData?.department.name }}</h2>
  </div>
  <tab-bar v-if="pages !== undefined && pages !== null"
           class="tab-bar"
           :pages="pages"
           :tab-titles="tabTitles" />
  
  <shift-banner-wrapper v-if="!initialLoad && shiftData && shiftWorkerData" :current-shift="shiftData"
                        :is-freelancer-inactive="isFreelancerInactive"
                        :current-shift-worker="shiftWorkerData"
                        class="w-full"/>
  
  <div v-if="!initialLoad && shiftData && shiftWorkerData" ref="pages"
       class="tabs">
    <shift-tab :current-shift="shiftData"
               :is-freelancer-inactive="isFreelancerInactive"
               :current-shift-worker="shiftWorkerData"/>
    <department-tab :department="shiftData.department"
                    @department-tab-viewed="logDepartmentTabViewed"/>
  </div>
  <layout-desktop-wrapper no-padding-top no-padding-bottom
                          class="sticky bottom-0">
    <div class="flex-grow"></div>
    <shift-drawer v-if="!initialLoad && shiftData && shiftWorkerData"
                  :is-freelancer-inactive="isFreelancerInactive"
                  :sheet-id="shiftData.sheet_id"
                  :shift-worker="shiftWorkerData"
                  :break="shiftData.break"
                  @accept-shift="reload"/>
  </layout-desktop-wrapper>
</template>

<script setup lang="ts">
/* eslint-disable max-lines */
import InfinityTopSpinner from '@app/components/InfinityTopSpinner.vue';
import TabBar from '@app/components/TabBar.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import router from '@app/router';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { ShiftStatus } from '@pidz/enums';
import { rerouteToOverview } from '@pidz/shared';
import { useUserStore, useShiftStore, useShiftsStore, useToaster } from '@pidz/stores';
import { useModalController, shiftApprovalStatusMap } from '@pidz/utils';
import { Component, computed, markRaw, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import DepartmentTab from './components/DepartmentTab.vue';
import FixedRateModalContent from './components/FixedRateModalContent.vue';
import KoraalOvoModalContent from './components/KoraalOvoModalContent.vue';
import MondriaanOvoModalContent from './components/MondriaanOvoModalContent.vue';
import NonStandardConditionsModal from './components/NonStandardConditionsModal.vue';
import ShiftBannerWrapper from './components/ShiftBannerWrapper.vue';
import ShiftDiffModal from './components/ShiftDiffModal.vue';
import ShiftDrawer from './components/ShiftDrawer.vue';
import ShiftTab from './components/ShiftTab.vue';

const initialLoad = ref(true);
const loading = ref(false);

const store = useShiftStore();
const shiftsStore = useShiftsStore();

const route = useRoute();
const shiftId = computed(() => Number(route.params.id));
const modal = useModalController();
const user = useUserStore();
const shiftData = computed(() => store.shifts[shiftId.value]!);
const shiftWorkerData = computed(() => store.shiftWorkers[shiftId.value]!);
const shiftDiff = computed(() => shiftData.value?.shift_diff);


const pages = ref();
const tabTitles = ref(['De opdracht', 'De afdeling']);
const isFreelancerInactive = computed(() => user.inactiveTaskList?.length > 0);

const openShiftDiffModal = () => {
  modal.open(ShiftDiffModal, {
    shiftWorker: shiftWorkerData,
    shiftDiff,
    onAcceptHandler: async () => router.push({ name: 'shifts' }),
    onDeclineHandler: async () => router.push({ name: 'shifts' }),
  });
};

const openChangedConditionsModal = () => {
  const {
    is_koraal_ovo: isKoraalOvo,
    is_mondriaan_ovo: isMondriaanOvo,
    fixed_rate: fixedRate,
  } = { ...shiftData.value };
  const modalContent = ref<Array<{ component: Component; props?: { [key: string]: any } }>>([]);

  if (isKoraalOvo) {
    modalContent.value.push({ component: markRaw(KoraalOvoModalContent) });
  }
  if (isMondriaanOvo) {
    modalContent.value.push({ component: markRaw(MondriaanOvoModalContent) });
  }
  if (fixedRate) {
    modalContent.value.push({
      component: markRaw(FixedRateModalContent),
      props: { rate: shiftData.value?.rate },
    });
  }

  if (modalContent.value.length === 0) {
    return;
  }

  const showNext = () => {
    if (modalContent.value.length > 1) {
      return modalContent.value.shift();
    }
    return modal.close();
  };
  modal.open(NonStandardConditionsModal, { content: modalContent, showNext });
};

const reload = async () => {
  loading.value = true;

  try {
    await store.getShift(shiftId.value);
    await store.getShiftWorker(shiftId.value);
    await user.fetchFreelancerData();
    
    initialLoad.value = false;
    loading.value = false;

  } 
  catch(err) {
    loading.value = false;
    useToaster().addToaster({ 
      title: 'Oeps', 
      message: 'Er is iets misgegaan.', 
      type: 'error' 
    });
    rerouteToOverview(router, 'shifts overview')
  }
  finally {

    if (shiftData.value?.status !== ShiftStatus.FILLED && !shiftWorkerData.value?.is_read_by_freelancer) { 
      await shiftsStore.setInvitationRead(shiftWorkerData.value?.id)
    }
    if (shiftDiff.value && shiftWorkerData.value?.shift_status === ShiftStatus.FILLED) {
      openShiftDiffModal();
    }
    if (shiftData.value?.status !== ShiftStatus.FILLED) {
      openChangedConditionsModal();
    }
  }

  return {
    isEmpty: false,
    hasNext: false,
  };
};

const logDepartmentTabViewed = () => {
  logCustomEvent('invitation_department_details_watched', {
    status: shiftApprovalStatusMap.get(shiftWorkerData.value?.status!)?.text ?? '',
  });
};

onMounted(async () => {
  await reload();
});
</script>

<style lang="scss">
.tabs {
  height: auto;
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  width: calc(100vw);

    @media (min-width: 768px) {
      width: calc(100vw - 240px);
    }

  &::-webkit-scrollbar {
    display: none;
  }

  & > div,
  & > section {
    scroll-snap-align: start;
    width: calc(100vw);
    max-width: calc(100vw);

    @media (min-width: 768px) {
      width: calc(100vw - 240px);
      max-width: calc(100vw - 240px);
    }
  }
}
</style>
