<template>
  <div class="container">
    <div class="status-row">
      <div v-if="dateText || date" class="status-row__date status-row__item date">
        <span v-if="dateText && date">{{`${dateText} ${formatDate(date)}`}}</span>
        <span v-else-if="dateText && !date">{{dateText}}</span>
      </div>
      <div class="status-row__status status-row__item status">
        <p-status :variant="status"/>
      </div>
      <div class="status-row__item file">
        <p-button class="file__button"
                  variant="ghost"
                  @click="(e: Event) => emit('open-files', e)">
          <p-icon icon-name="file"
                  size="medium"
                  color="blue"/>
          <div class="file__button-text">
            {{ fileText }}
          </div>
        </p-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { DateTime } from 'luxon';
import { computed } from 'vue';

const emit = defineEmits(['open-files']);

const props = withDefaults(defineProps<{
  status: string | number,
  date?: DateTime | string,
  dateText?: string,
  fileText: string
  reverse?: boolean
  customDateFormat?: {}
  alignment: 'horizontal' | 'vertical'
}>(), {
  reverse: false,
  alignment: 'horizontal'
});

const formatDate = (date: DateTime | string): string => date.toLocaleString(props.customDateFormat
  || { year: 'numeric', month: '2-digit', day: '2-digit' });

const flexDirection = computed(() => {
  if (props.alignment === 'horizontal') return props.reverse ? 'row-reverse' : 'row';
  if (props.alignment === 'vertical') return props.reverse ? 'column-reverse' : 'column';
  return 'row';
});

</script>

<style lang="scss" scoped>
.container {
  container-type:inline-size;
  .status-row {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 8px;
    > div {
      width: 100%;
    }
    @container(min-width: 650px) {
      flex-direction: v-bind(flexDirection);
        > div {
          &.date {
            width: 50%;
          }
          &.status {
            width: 25%;
          }
          &.file {
            width: 25%;
          }
      }
    }
    &__item {
      text-wrap: nowrap;
    }
    .file {
      &__button-text {
        font-size: 14px;
        margin-left: 8px;
        color: var(--p-blue);
      }
    }
    &__date {
      color: var(--p-grey-dark);
      font-size: 14px;
      display: flex;
      align-items: center;
      text-wrap: nowrap;
    }
    gap: 8px;
  }
}
</style>
