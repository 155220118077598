export enum CalendarEventType {
    SHIFT = 2,
    UNAVAILABLE = 3,
    FREELANCER_REQUEST = 4,
}  

export enum DirectionEnum {
    NEXT = 1,
    PREV = 2,
}

export enum RecurrenceRepeatType {
    Never = 0,
    Daily = 1,
    Weekly = 2,
}

export enum RecurrencePeriodType {
    OneYear = 0,
    SetAmount = 1,
    OnDate = 2,
}

export enum CalenderViewTypeEnum {
    WEEK = 1,
    MONTH = 2,
  }
  