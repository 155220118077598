import { FormFieldType } from '@pidz/enums';
import bic from 'bic';
import iban from 'iban';
import { ref } from 'vue';
import * as zod from 'zod';

export const ibanField = ref<FormField>({
  active: true,
  name: 'iban',
  formType: FormFieldType.INPUT,
  validation: zod.string().refine((value) => iban.isValid(value), {
    message: 'Vul een geldige IBAN in',
  }),
  width: 100,
  data: {
    id: 'iban',
    label: 'IBAN',
    inputType: 'text',
    placeholder: 'NL11AAAA01234567',
  },
});

export const bicField = ref<FormField>({
  active: true,
  name: 'bic',
  formType: FormFieldType.INPUT,
  validation: zod.string().refine((value) => bic.isValid(value), {
    message: 'Vul een geldig BIC nummer in',
  }),
  width: 100,
  data: {
    id: 'bic',
    label: 'BIC',
    inputType: 'text',
    placeholder: 'ABNANL2A',
  },
});

export const nameField = ref<FormField>({
  active: true,
  name: 'name',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, { message: 'Vul een naam in' })
    .max(255, { message: 'Maximaal 255 karakters' }),
  width: 100,
  data: {
    id: 'name',
    label: 'Op naam van',
    inputType: 'text',
    placeholder: 'A. Achternaam',
  },
});

export const automaticIncassoField = ref<FormField>({
  active: true,
  name: 'automaticIncasso',
  formType: FormFieldType.TOGGLE,
  validation: zod.boolean(),
  width: 100,
  data: {
    id: 'automaticIncasso',
    label: 'Betaal facturen van MijnPIDZ via automatische incasso',
  },
});
