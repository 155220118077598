<template>
  <div class="downloadable-invoices">
    <button ref="downloadButtonRef"
            :class="['download-invoice-button', filteredBundledInvoices.length === 0 && 'disabled']"
            @click.stop="openList">
      <download-icon />
    </button>
    <download-invoice-list v-if="listOpen" :bundled-invoices="filteredBundledInvoices"
                           :invoice-id="invoice.invoice_id" />
  </div>
</template>
<script lang="ts" setup>

import { DownloadIcon } from '@pidz/icons';
import {
  computed, onMounted, onUnmounted, ref,
} from 'vue';
import { BundleInvoice, Invoice, SoftwareInvoice } from '../types';
import DownloadInvoiceList from './DownloadInvoiceList.vue';

const props = defineProps<{
  invoice: Invoice | SoftwareInvoice,
}>();

const listOpen = ref(false);
const downloadButtonRef = ref();

interface FilteredBundleInvoice extends Omit<BundleInvoice, 'id'> {
  id: string
}

const filteredBundledInvoices = computed<FilteredBundleInvoice[]>(() => {
  const { invoice } = props;

  const bundledInvoices = invoice.bundle_invoices?.filter((bundledInvoice) => bundledInvoice.id) ?? [];

  return bundledInvoices as FilteredBundleInvoice[];
});

const openList = () => {
  if (filteredBundledInvoices.value.length === 0) { return; }
  listOpen.value = true;
};

const closeList = (e: MouseEvent) => {
  if (e.target !== downloadButtonRef.value?.$el && listOpen.value === true) {
    listOpen.value = false;
  }
};

onMounted(() => document.addEventListener('click', (e) => closeList(e)));

onUnmounted(() => document.removeEventListener('click', (e) => closeList(e)));

</script>
<style lang="scss">
.downloadable-invoices {
  position: relative;
  .download-invoice-button {
      &.disabled {
        color: var(--pidz-white);
        opacity: 0.5;
      }
    }
}

</style>
