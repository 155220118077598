export const enum RowType {
    ROW = 'row',
    BREAK = 'break',
}

export const enum FormFieldType {
    INPUT = 'input',
    CHECKBOX = 'checkbox',
    DROPDOWN = 'dropdown',
    DOUBLE = 'double',
    TOGGLE = 'toggle',
    TEXTAREA = 'textarea',
    RADIOGROUP = 'radiogroup'
}
