<template>
  <div class="w-full flex flex-col flex-grow">
    <reload-page :reload="reload"
                 :loading="loading"/>
    <template v-if="invoiceData">
      <div class="flex w-full justify-between items-center bg-blue text-white px-4 pb-2 md:py-4">
        <div class="flex flex-col">
          <h1 class="font-bold text-base break-words">{{ invoiceData?.creditor_organisation_name }}</h1>
        </div>
        <download-invoice-menu v-if="invoiceData" :invoice="invoiceData" />
      </div>
      <div class="software-invoice-detail-page flex flex-col flex-grow w-full items-center">
        <p-banner v-if="showSent"
                  type="info"
                  :text="`Deze factuur is ${daysAgo(DateTime.fromISO(invoiceData.sent_date))} verstuurd.`"/>
        <layout-desktop-wrapper no-padding-bottom>
          <div class="month">{{ month }}</div>
          <text-row left="Gebruik mijnPIDZ" :right="formatPriceToEuroString(invoiceData.total_amount)" />
          <text-row class="bold" left="Factuurtotaal"
                    :right="formatPriceToEuroString(invoiceData.total_amount)" />
          <div class="flex-grow"></div>

          <software-invoice-drawer :invoice-data="invoiceData!" />
        </layout-desktop-wrapper>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import ReloadPage from '@app/components/ReloadPage.vue';
import TextRow from '@app/components/TextRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { daysAgo } from '@pidz/date';
import { InvoiceStatus } from '@pidz/enums';
import { useInvoice } from '@pidz/stores';
import { formatPriceToEuroString, useModalController } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import DownloadInvoiceMenu from './components/DownloadInvoiceMenu.vue';
import InvoicePaymentModal from './components/InvoicePaymentModal.vue';
import SoftwareInvoiceDrawer from './components/SoftwareInvoiceDrawer.vue';

const initialLoad = ref(true);
const loading = ref(false);

const route = useRoute();
const modal = useModalController();
const invoiceStore = useInvoice();
const invoiceId = computed(() => Number(route.params.id));
const invoiceData = computed(() => invoiceStore.softwareInvoices[invoiceId.value]);
const month = computed(() =>
  invoiceData.value
    ? DateTime.fromISO(invoiceData.value.month).setLocale('nl-nl').toLocaleString({
        month: 'long',
      })
    : undefined,
);

const showSent = computed(() =>
  invoiceData.value
    ? [
        InvoiceStatus.INVOICED,
        InvoiceStatus.REMINDER_1,
        InvoiceStatus.REMINDER_2,
        InvoiceStatus.REMINDER_3,
      ].includes(invoiceData.value.status)
    : false,
);

const reload = async () => {
  loading.value = true;

  await invoiceStore.getSoftwareInvoice(invoiceId.value);
  
  loading.value = false;
  initialLoad.value = false;
};

const paymentStatus = computed(() => route.params.payment ? InvoiceStatus.PAID : undefined);
watch(() => paymentStatus.value, async () => {
  if (!route.params.payment || !invoiceData.value) return;
  await reload();
  const success = invoiceData.value.status === InvoiceStatus.PAID;
  invoiceStore.setSoftwareInvoiceToStatusPending();
  modal.open(InvoicePaymentModal, { success });
});

onMounted(() => {
  reload();
});
</script>

<style lang="scss">
.software-invoice-detail-page {
  .month {
    padding: 16px;
    padding-bottom: 6px;
    color: var(--pidz-body-text);
  }

  .text-row {
    margin-bottom: 24px;
    box-shadow: var(--shadow-light);
  }

  .bold {
    font-weight: bold;
  }
}
</style>
