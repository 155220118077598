<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section class="worked-at-filter">
    <h3 class="my-4 font-bold">Eerder gewerkt bij</h3>
    <option-grid v-model="value" :options="alreadyWorkedAtOptions"/>
  </section>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import OptionGrid from '../OptionGrid.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: any,
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

const alreadyWorkedAtOptions = [
  { name: 'Afdeling', value: 1 },
  { name: 'Organisatie', value: 2 }];

</script>
