import { DateTime, LocaleOptions } from 'luxon';

export const localeString = (dt: DateTime, formatOpts?: Intl.DateTimeFormatOptions, opts?: LocaleOptions):string => 
  dt.toLocaleString(formatOpts, opts);

export const format = (dt: DateTime, fmt:string, opts?: LocaleOptions): string => dt.toFormat(fmt, opts);

export const formatYmd = (dt: DateTime): string => {
  return dt.toFormat('yyyy-MM-dd')
};

export const formatHour = (dt: DateTime): string => 
  dt.toLocaleString({ hour: '2-digit', minute: '2-digit' }, { locale: 'nl' });

export const formatDayMonth = (dt: DateTime): string => 
`${dt.get('day')} ${String(dt.get('monthShort')).charAt(0).toUpperCase() + String(dt.get('monthShort')).slice(1)}`;

export const formatFullDate = (dt: DateTime): string => dt.toLocaleString(
  {
    weekday: 'long', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit',
  },
  { locale: 'nl' },
);

export const capitalizeString = <T extends string>(s: T) => (s[0].toUpperCase() + s.slice(1)) as Capitalize<typeof s>;