<!-- eslint-disable vue/no-mutating-props -->
<template>
  <filter-overview title="Opdrachten filteren op">
    <section>
      <h3 class="my-4 font-bold">Status</h3>
      <option-grid v-model="modelValue.shiftStatuses" multi
                   :options="statusOptions"/>
    </section>
    <day-part-filter v-model="modelValue.dayParts" />
    <worked-at-filter v-model="modelValue.alreadyWorkedAt" />
    <functions-filter v-model="modelValue.functions" />
    <travel-time-filter v-model="modelValue.travelTime" :max-work-range="maxWorkRange" />
    <date-range-filter v-model="modelValue.date" />
  </filter-overview>
</template>

<script setup lang="ts">
import FilterOverview from '@app/components/FilterOverview.vue';
import DateRangeFilter from '@app/components/filter/DateRangeFilter.vue';
import DayPartFilter from '@app/components/filter/DayPartFilter.vue';
import FunctionsFilter from '@app/components/filter/FunctionsFilter.vue';
import TravelTimeFilter from '@app/components/filter/TravelTimeFilter.vue';
import WorkedAtFilter from '@app/components/filter/WorkedAtFilter.vue';
import OptionGrid from '../../components/OptionGrid.vue';
import { IShiftFilterParams } from './types';

const statusOptions = [
  { name: 'Geaccepteerd', value: 2 },
  { name: 'Goedgekeurd', value: 3 },
  { name: 'Geannuleerd', value: 4 },
  { name: 'Afgekeurd', value: 5 }];

defineProps<{
  modelValue: IShiftFilterParams,
  maxWorkRange:number,
}>();

</script>
