<template>
  <reload-page :reload="() => reload(true)" :loading="!freelancerData || !freelancerFiles" />
  <template v-if="freelancerData && freelancerFiles">
    <p-banner-list :banners="profileBanners" />
      
    <layout-desktop-wrapper>
      <div class="w-full flex flex-col gap-6">
        <personal-details :data="freelancerData.personal"
                          :files="freelancerFiles.personal"
                          padding="24px 16px"
                          file-row="vertical"
                          @edit-personal="() => handleRoute('personal', RouteType.EDIT)"/>
            
        <contact-details :data="freelancerData.contact"
                         padding="24px 16px"
                         @edit-contact="() => handleRoute('contact', RouteType.EDIT)"/>
            
        <address-details :address="freelancerData.address"
                         :business="freelancerData.businessAddress"
                         padding="24px 16px"
                         @edit-address="() => handleRoute('address', RouteType.EDIT)"/>
            
        <company-details v-if="userStore.isUserFreelancer"
                         :data="freelancerData.company"
                         :files="freelancerFiles.company"
                         padding="24px 16px"
                         file-row="vertical"
                         @edit-company="() => handleRoute('company', RouteType.EDIT)"/>
            
        <bank-details :data="freelancerData.bank"
                      padding="24px 16px"
                      @edit-bank="() => handleRoute('bank', RouteType.EDIT)"/>
      </div>
    </layout-desktop-wrapper>
  </template>
</template>

<script setup lang="ts">
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { RouteType } from '@pidz/enums';
import {
  AddressDetails,
  BankDetails,
  CompanyDetails,
  ContactDetails,
  PersonalDetails,
} from '@pidz/shared/components';
import { useAssetStore, useUserStore } from '@pidz/stores';
import { useBannersStore } from '@pidz/stores';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const userStore = useUserStore();
const assetStore = useAssetStore();
const bannersStore = useBannersStore();

const freelancerData = computed(() => userStore.getFreelancerInfoForProfile);
const freelancerFiles = computed(() => assetStore.getAssetsForProfile);
const profileBanners = computed(() =>
  bannersStore.getBannerPerRoute('profile'),
);

const handleRoute = (section: string, routeType: RouteType) => {
  router.push({
    name: `profile ${section} ${routeType}`,
  });
};

const reload = async (force = false) => {
  await Promise.all([
    userStore.fetchFreelancerData(force),
    assetStore.fetchAllAssetData(force),
    bannersStore.fetchBanners(),
  ]);
};

onMounted(async () => {
  await reload();
});
</script>
