import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { FileType, MimeType } from '@pidz/enums';
import { base64ToBlob } from '@pidz/utils';

export const openFile = async (document: Base64File, mimeType: MimeType = MimeType.PDF) => {
  const deviceInfo = await Device.getInfo();

  if (deviceInfo.platform === 'web') {
    const blobData = base64ToBlob(document.data);
    const blob = new Blob([blobData], {type: mimeType})
    const url = window.URL.createObjectURL(blob);
    await Browser.open({ url });
  } else {
    const writeFileResult = await Filesystem.writeFile({
      path: document.name,
      data: document.data,
      directory: Directory.Documents,
    });
    await FileOpener.open({ filePath: writeFileResult.uri });
  }
}

export const MimeTypeMap = new Map<string, MimeType>([
  [
      FileType.PDF, 
      MimeType.PDF
  ],
  [
      FileType.JPEG,
      MimeType.JPEG
  ],
  [
      FileType.JPG,
      MimeType.JPG
  ],
  [
      FileType.PNG,
      MimeType.PNG
  ],
  [
      FileType.WEBP,
      MimeType.WEBP
  ],
  [
      FileType.DOC,
      MimeType.DOC
  ],
  [
      FileType.DOCX,
      MimeType.DOCX
  ],[
      FileType.ZIP,
      MimeType.ZIP
  ]
]);
