<template>
  <div class="card" :class="{'card__rounded' : rounded}">
    <div v-if="title" class="card__header">
      <p-heading variant="secondary" :font-size="titleSize">{{ title }}</p-heading>
      <p-button v-if="hasButton"
                class=""
                variant="ghost"
                @click="$emit('button-click')">
        <p-icon :icon-name="iconName"
                :size="iconSize"
                color="blue" />
      </p-button>
    </div>
    <!-- class: card__edit-button -->
    <div v-if="slots.content" class="card__content">
      <slot v-if="slots.content" name="content" />
    </div>
    <div v-if="slots.footer" class="card__footer">
      <slot name="footer" />
    </div>
  </div>

</template>
<script lang="ts" setup>
import { useSlots } from 'vue';

defineEmits(['button-click']);
const slots = useSlots();
withDefaults(defineProps<{
  hasButton?: boolean
  iconName?: string
  iconSize?: 'large' | 'medium' | 'small'
  title?: string
  titleSize?: 'xsm' | 'sm' | 'md' | 'lg' | 'xl'
  padding?: string
  rounded?: boolean
}>(), {
  hasButton: false,
  iconSize: 'medium',
  iconName: 'pencil',
  titleSize: 'md',
  padding: '16px',
  rounded: true
});
</script>

<style lang="scss" scoped>
.card {
  box-shadow: var(--shadow-light);
  background-color: white;
  padding: v-bind(padding);

  &__rounded {
    border: 0.5px solid var(--pidz-grey-light);
    border-radius: 4px;
  }

  &__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

</style>
