<template>
  <div class="flex flex-col px-4 py-2 border-b border-neutral-200 last:border-b-0">
    <div class="flex flex-row justify-between">
      <p-icon class="add-icon" :icon-name="iconName"
              size="medium"
              color="blue"/>
      <div class="flex flex-1 justify-end items-center text-xs color text-neutral-600 pr-3">
        <p>{{ daysAgo(DateTime.fromISO(item.created_at))}}</p>
      </div>
      <p-button variant="ghost" 
                @click="emit('dismiss', item.read_dismiss_parameters)">
        <p-icon icon-name="close"
                size="small"
                color="blue"/>
      </p-button>
    </div>
    <div :class="['flex flex-row justify-between text-sm pt-2 text-blue', 
                  {'cursor-pointer': notificationRoute}]" @click="onClickNotification">
      <p>
        {{ sanitizedBody }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { DateTime, daysAgo } from '@pidz/date';
import { getNotificationRoute } from '@pidz/shared/utils';
import DOMPurify from 'dompurify';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { getInboxItemIcon } from '../inboxItemIcon';
const router = useRouter();

const props = defineProps<{
  item: NotificationItem
}>();

const iconName = computed(() => getInboxItemIcon(props.item.type))
const sanitizedBody = computed(() => DOMPurify.sanitize(props.item.body, {USE_PROFILES: {html: false}}))

const emit = defineEmits(['dismiss'])

const notificationRoute = computed(() => getNotificationRoute(props.item))

const onClickNotification = () => {
  if (notificationRoute.value) router.push(notificationRoute.value);
}
</script>
<style scoped lang="scss">
</style>
