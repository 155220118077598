import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

let initializeFirebase: any = null;
const isFirebaseInitialized = new Promise((res) => { initializeFirebase = res; });

export const initializeAnalytics = async () => {
  // Other platforms use GoogleService-Info.plist / google-services.json
  if (Capacitor.getPlatform() === 'web') {
    await FirebaseAnalytics.initializeFirebase({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_FIREBASE_APP_ID,
      measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    });
  }

  await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  initializeFirebase();
};

export const setUserId = async (userId: string) => {
  await isFirebaseInitialized;
  await FirebaseAnalytics.setUserId({ userId });
};

export const logCustomEvent = async (
  name: string,
  params: object = {},
) => {
  await isFirebaseInitialized;
  await FirebaseAnalytics.logEvent({
    name,
    params,
  });
};
