import { RowType } from '@pidz/enums';
import {
  acquiredAtField,
  registrationNumberField,
  registrationTypeField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<NewRegistrationForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [registrationTypeField.value],
  },
  {
    type: RowType.ROW,
    items: [registrationNumberField.value],
  },
  {
    type: RowType.ROW,
    items: [acquiredAtField.value],
  },
];
