import { DateTime } from 'luxon';

const calculateOffset = (start: string, end: string) =>
  (DateTime.fromISO(start).toMillis() - DateTime.fromISO(end).toMillis()) / 1000;

export const microRangeToLegacyBlockTimes = (startTime: string, duration: number) => {
  // TODO: Find out why startTime is sometimes a string
  const newStartTime = typeof startTime === 'object' 
  ? DateTime.fromJSDate(startTime) 
  : DateTime.fromISO(startTime);

  return {
    start: startTime,
    end: newStartTime.plus({second: duration}).toISO()!,
  };
};

export const legacyBlockTimesToMicroRange = (start: string, end: string) => ({
  startTime: start,
  duration: calculateOffset(end, start),
});
