enum InvoiceStatus {
  NEW = 0,
  INVOICED = 1,
  REMINDER_1 = 2,
  REMINDER_2 = 3,
  PAID = 4,
  FINISHED = 5,
  CHECKED_IN = 6,
  REMINDER_3 = 7,
  // missing status 8
  CREDITED = 9,
  FORCED_CREDITED = 10,
  DOUBTFUL_DEBTOR = 11,
}

export const invoiceStatusInfo = new Map<
InvoiceStatus,
{ className: 'info' | 'success' | 'error' | 'warning'; text: string }
>([
  [InvoiceStatus.NEW, { className: 'info', text: 'Nieuwe factuur' }],
  [InvoiceStatus.INVOICED, { className: 'info', text: 'Factuur verstuurd' }],
  [
    InvoiceStatus.REMINDER_1,
    { className: 'info', text: '1e herinnering verstuurd' },
  ],
  [
    InvoiceStatus.REMINDER_2,
    { className: 'info', text: '2e herinnering verstuurd' },
  ],
  [
    InvoiceStatus.REMINDER_3,
    { className: 'info', text: '3e herinnering verstuurd' },
  ],
  [
    InvoiceStatus.DOUBTFUL_DEBTOR,
    { className: 'info', text: '3e herinnering verstuurd' },
  ],
  [InvoiceStatus.FINISHED, { className: 'info', text: 'Onbekende status' }],
  [InvoiceStatus.CHECKED_IN, { className: 'info', text: 'Onbekende status' }],
  [InvoiceStatus.PAID, { className: 'success', text: 'Factuur betaald' }],
  [
    InvoiceStatus.CREDITED,
    { className: 'error', text: 'Factuur gecrediteerd' },
  ],
  [
    InvoiceStatus.FORCED_CREDITED,
    { className: 'info', text: 'Factuur gecrediteerd' },
  ],
]);
