<template>
  <div class="login-page">
    <div class="logo relative" @click="environmentSwitchDetect">
      <environment-indicator class="absolute left-[50px] -top-1" />
      <pidz-logo />
    </div>
    <h1 class="font-bold"><profile-icon /> Inloggen</h1>
    <p-banner v-if="authError.hasError"
              type="error"
              title="Inloggen mislukt"
              :text="authError.message"></p-banner>
    <form class="login-form" @submit.prevent>
      <form-section>
        <label for="input-email">Email</label>
        <input id="input-email"
               v-model.trim="loginForm.email"
               type="email"
               enterkeyhint="next"
               placeholder="jouw@emailadres.nl"
               tabindex="1"
               @keydown.enter.prevent="
                 ($refs.passwordField as HTMLInputElement).focus
               "/>
        <div class="grouped-label">
          <label for="input-wachtwoord">Wachtwoord</label>
          <router-link :to="{ name: 'forgot password' }" class="label-link underline">Wachtwoord vergeten?</router-link>
        </div>
        <input id="input-password"
               ref="passwordField"
               v-model.trim="loginForm.password"
               type="password"
               enterkeyhint="go"
               placeholder="Wachtwoord"
               tabindex="2"
               @keydown.enter="loginClick"/>
      </form-section>
      <form-section class="submit-form">
        <async-button class="light" type="submit"
                      @click="loginClick">Inloggen
        </async-button>
      </form-section>
    </form>
  </div>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import FormSection from '@app/components/FormSection.vue';
import PidzLogo from '@app/components/PidzLogo.vue';
import { login } from '@app/user-state';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { ProfileIcon } from '@pidz/icons';
import { useModalController } from '@pidz/utils';
import { onMounted, reactive, ref } from 'vue';
import EnvironmentIndicator from '../../components/EnvironmentIndicator.vue';
import EnvironmentSwitchModal from './components/EnvironmentSwitchModal.vue';

const errors: { [key: string]: string } = {
  PasswordResetRequiredException: `PIDZ werkt aan de veiligheid van jouw account. Je wachtwoord is daarmee komen te vervallen.`,
  NotAuthorizedException: `Incorrect email adres of wachtwoord. Dubbelcheck je gegevens en probeer het opnieuw.`,
};

const loginForm = reactive({
  email: '',
  password: '',
});
const authError = reactive({
  hasError: false,
  message: '',
});

const passwordField = ref(null);
const platform = Capacitor.getPlatform();
const modalController = useModalController();
const tapCount = ref(0);

onMounted(async () => {
  if (platform === 'ios') {
    await Keyboard.setAccessoryBarVisible({ isVisible: false });
  }
});

const loginClick = async () => {
  authError.hasError = false;
  (document.activeElement as any)?.blur();
  try {
    await login(loginForm.email, loginForm.password);
  } catch (error: any) {
    authError.hasError = true;
    authError.message = errors[error.name] || errors.NotAuthorizedException;
  }
};

const environmentSwitchDetect = () => {
  if (import.meta.env.PROD && !(loginForm.email === 'env')) {
    return;
  }
  tapCount.value += 1;
  if (tapCount.value === 5) {
    modalController.open(EnvironmentSwitchModal);
    tapCount.value = 0;
  }
};
</script>
<style lang="scss">
.login-page {
  color: var(--pidz-white);
  background-color: var(--pidz-blue);
  padding: 48px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
  overflow: auto;

  .logo {
    height: 27px;
    align-self: center;
  }

  h1 {
    display: flex;
    align-items: center;
    margin: 48px 0 0 0;
    font-size: 28px;
    line-height: 1;

    i {
      margin-right: 16px;
    }
  }

  .banner {
    margin-top: 32px;
  }

  .login-form {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    height: 100%;
  }

  .submit-form {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
  }
}
</style>
