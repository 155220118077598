<template>
  <header class="top-bar">
    <section class="controls">
      <div class="left">
        <slot name="left">
          <button class="back-button" :onclick="goBack">
            <p-icon icon-name="arrow"
                    size="large"
                    color="white"/>
          </button>
        </slot>
      </div>
      <div class="center">
        <slot>
          <h1 class="topbar-title">{{route.meta.title}}</h1>
        </slot>
      </div>
      <div class="right">
        <slot name="right"> </slot>
      </div>
    </section>
  </header>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

const goBack = () => {
  if (!window.history.state.back || isAddOrEdit.value) {
    return router.replace({ name: 'dashboard' });
  }
  return router.go(-1);
};

const isAddOrEdit = computed(() => {
  const back = window.history.state.back;
  return back.includes('add') || back.includes('edit');
})
</script>

<style lang="scss">
.top-bar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--pidz-blue);
  font-size: 14px;
  color: var(--pidz-white);
  height: 52px;
  z-index: 20;

  .controls {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto 1fr;
    padding: 7px 16px;
    line-height: 1;
  }

  .left, .center, .right {
    display: flex;
    align-items: center;
  }

  button {
    height: 100%;
    border: none;
    background: none;
    padding: 0;
    color: inherit;
    line-height: 1;
  }

  .left {
    justify-content: flex-start;
    font-family: inherit;
  }

  .center {
    justify-content: space-around;
    align-items: center;

    .topbar-title {
      display: -webkit-box;
      text-align: center;
      padding: 0 16px;
      overflow: hidden;
      line-height: 1.2;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 600;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .right {
    justify-content: flex-end;
    position: relative;
  }

  .back-button {
    font-family: inherit;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--pidz-white);
    text-decoration: none;

    .icon {
      display: block;
      transform: rotate(-90deg);
      height: 20px;
      width: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

</style>
