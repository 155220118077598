export const profilePermissions: PagePermission[] = [
    // Profile Personal
    {
      name: 'profile overview',
      view: true,
      sections: [
        {
          name: 'profile personal insuranceDocument',
          view: false
        },
        {
          name: 'profile personal insuranceDocumentPaymentProof',
          view: false
        },
      ]
    },
    {
      name: 'profile personal edit',
      view: true,
      sections: [
        {
          name: 'profile personal insuranceDocument',
          view: false
        },
        {
          name: 'profile personal insuranceDocumentPaymentProof',
          view: false
        },
      ]
    },
]
