import { useApi } from '@pidz/api';

const api = useApi()

export const featureflagService = {
  getFeatureFlag: (flagName: string) : Promise<FeatureFlag> => api.rest({
    method: 'get',
    url: `/api/feature-flag/${flagName}`,
  })
};
