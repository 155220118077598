// We have a lot of different messages which correspond to only a few different banners
// The key in this function is the name of the banner which will be shown, with the value being
// all the different messages.
// Commented messages are not shown, as discussed with UX. Might be shown in the future.

type MessageMapIndex<K extends string, T extends string> = { [key in K]: T[] }

export const mapMessageToBanner = (message: MessageName): BannerName | undefined => {
  const messageMap: MessageMapIndex<BannerName, MessageName> = {
    timezone: ['timezone'],
    unsubscribed: ['unsubscribed'],
    manual_inactive: ['manual_inactive'],
    invoice: ['invoice'],

    activate_profile: [
      'missing_assets',
      'renew_assets',
      'wait_approve_assets',
      // 'set_travel_time',
      // 'no_functions',
      // 'no_sectors',
      'invalid_cv',
      // 'set_rates',
      // 'set_automatic_payment_choice',
      'profile',
      'dashboard_expired'
    ],
    nearly_inactive: ['dashboard_nearly_inactive'],

    resume_incomplete: [
      'upload_diploma',
      'add_workexperience',
      'big_registration',
      'add_languageskill_dutch',
      'competency_experience',
      'resume_items_expired',
      'no_healthcare_provider_portal',
    ],
    resume_items_nearly_expired: ['resume_items_nearly_expired'],

    profile_incomplete: [
      'vog_document',
      'coc_document',
      'insurance_document',
      'complaints_document',
      'profile_files_expired'
    ],
    profile_files_nearly_expired: ['profile_files_nearly_expired']
  };

  for (const [banner, messages] of Object.entries(messageMap)) {
    if (messages.includes(message)) return banner as BannerName;
  }

  return undefined;
};
