import {
  travelTime,
  rates,
  notifications,
  profile
} from './index'

export default [{
  name: 'settings',
  path: '/settings',
  redirect: { name: 'profile' },
  component: () => import('@app/pages/settings/SettingsOverviewPage.vue'),
  meta: { title: 'Instellingen' },
  children:[
    ...profile,
    ...notifications,
    ...rates,
    ...travelTime
  ]
}]