import { SheetBlockType } from '@pidz/enums';
import { useSheetStore } from '@pidz/stores';
import {
  SheetBlockModel, UnsavedSheetBlock, SheetRowModel,
} from '../types';
import { groupBlocksByEditStatus } from './sheet-block-utils';

const sheetStore = useSheetStore();

export const groupBlocks = <T>(blocks: T[], sheetBlocks: T[], key: keyof T) => {
  const groupedBlocks = groupBlocksByEditStatus<T>(blocks, sheetBlocks, key);
  return groupedBlocks;
};

export const hasFunctionBeenEditted = (updatedBlock: SheetBlockModel | undefined, sheetRow: SheetRowModel | null) => {
  if (updatedBlock?.sheet_block_id === null) { return false; }
  const sheetBlocks = [...sheetRow?.sheet_block_models ?? []];
  const workingHours = sheetBlocks?.find((b:any) => b.type === SheetBlockType.TYPE_WORKING_HOURS);
  return workingHours?.shift_id !== updatedBlock?.shift_id;
};

export const addSheetBlocks = async (sheetBlocks: UnsavedSheetBlock[], sheetId: number) => Promise.all(sheetBlocks.map(
  (sheetBlock) => {
    const { temp_id, sheet_block_id, ...block } = sheetBlock;
    return sheetStore.addSheetBlock({...block, sheet_id: sheetId });
  },
));

export const editSheetBlocks = async (sheetBlocks: SheetBlockModel[]) => {
  if (sheetBlocks.length === 0) return Promise.resolve();
  return Promise.all(sheetBlocks.map(
    (sheetBlock: SheetBlockModel) => sheetStore.updateSheetBlock(sheetBlock),
  ));
};

export const removeSheetBlocks = async (sheetBlocks: SheetBlockModel[]) => {
  const removedSheetBlockIds = sheetBlocks.map((sheetBlock) => sheetBlock.sheet_block_id);
  if (removedSheetBlockIds.length === 0) { return Promise.resolve(); }
  return sheetStore.removeSheetBlock(removedSheetBlockIds);
};


export const addWorkingHours = async (workingHours: UnsavedSheetBlock | undefined, sheetId: number) => {
  if (!workingHours) { return Promise.resolve(); }
  const { temp_id, sheet_block_id, ...newWorkingHours } = workingHours;
  return sheetStore.addSheetBlock({ ...newWorkingHours, sheet_id: sheetId });
};

export const editWorkingHours = async (workingHours: SheetBlockModel | undefined) => {
  if (!workingHours) { return Promise.resolve(); }
  return sheetStore.updateSheetBlock(workingHours);
};
