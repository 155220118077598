<template>
  <input :id="inputId"
         v-model="value"
         class="input"
         :class="[
           !props.hasBorderRadiusLeft ? 'no-left-border-radius' : '',
           !props.hasBorderRadiusRight ? 'no-right-border-radius' : '',
           hasNoLeftBorder ? 'no-border-left' : '',
           disabled ? 'input--disabled' : '',
         ]"
         :type="inputType"
         :min="minValue"
         :max="maxValue"
         :disabled="disabled"/>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: DateTime | string | number | null | undefined;
    inputType: 'date' | 'time' | 'text' | 'number' | 'textarea';
    inputId: string;
    hasBorderRadiusLeft?: boolean;
    hasBorderRadiusRight?: boolean;
    hasNoLeftBorder?: boolean;
    min?: DateTime | number | string;
    max?: DateTime | number | string;
    disabled?: boolean;
  }>(),
  {
    hasBorderRadiusLeft: true,
    hasBorderRadiusRight: true,
    hasNoLeftBorder: false,
    disabled: false
  }
);

const emit = defineEmits(['update:modelValue']);

// computed value
const value = computed({
  get() {
    if (props.modelValue && DateTime.isDateTime(props.modelValue)) {
      if (props.inputType === 'date') {
        const date = props.modelValue as DateTime;
        return date.toISODate();
      }
      if (props.inputType === 'time') {
        const time = props.modelValue as DateTime;
        return time.toISOTime()!.substring(0, 5);
      }
    }
    return props.modelValue;
  },
  set(newValue) {
    if (props.modelValue && DateTime.isDateTime(props.modelValue)) {
      if (props.inputType === 'date') {
        const dateString = newValue as string;
        emit('update:modelValue', DateTime.fromISO(dateString));
      }
      if (props.inputType === 'time') {
        const timeString = newValue as string;
        const newDateTime = DateTime.fromISO(timeString);
        const originalDateTime = props.modelValue as DateTime;
        emit(
          'update:modelValue',
          originalDateTime
            .set({ hour: newDateTime.hour })
            .set({ minute: newDateTime.minute })
        );
      }
    } else {
      emit('update:modelValue', newValue);
    }
  }
});

const minValue = computed((): string | number => {
  if (props.min && DateTime.isDateTime(props.min)) {
    const date = props.min as DateTime;
    return date.toISODate() as string;
  }
  const minVal = props.min;
  return minVal as number;
});

const maxValue = computed((): string | number => {
  if (props.max && DateTime.isDateTime(props.max)) {
    const date = props.max as DateTime;
    return date.toISODate() as string;
  }
  const maxVal = props.max;
  return maxVal as number;
});
</script>
<style scoped lang="scss">
.input {
  flex-grow: 1; // flex-grow fixes the width on double form fields on ios
  width: 100%;
  min-height: 40px;
  border: 1px solid var(--pidz-black);
  border-radius: 5px;
  padding: 0px 12px;
  font-family: Lato;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--pidz-body-text);
  background: var(--pidz-white);

  &.no-left-border-radius {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  &.no-right-border-radius {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  &.no-border-left {
    border-left: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  &--disabled{
    background-color: var(--pidz-grey-light);
    color: var(--pidz-grey-dark);
  }
}
</style>
