import { RowType } from '@pidz/enums';
import {
  appointmentField,
  currentField,
  dateField,
  descriptionField,
  functionField,
  institutionNameField,
  institutionPlaceField,
  sectorField,
  singleDateField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<
  Omit<NewWorkExperienceForm, 'id'>
>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [functionField.value],
  },
  {
    type: RowType.ROW,
    items: [sectorField.value],
  },
  {
    type: RowType.ROW,
    items: [appointmentField.value],
  },
  {
    type: RowType.ROW,
    items: [institutionNameField.value],
  },
  {
    type: RowType.ROW,
    items: [institutionPlaceField.value],
  },
  {
    type: RowType.ROW,
    items: [descriptionField.value],
  },
  {
    type: RowType.ROW,
    items: [dateField.value],
  },
  {
    type: RowType.ROW,
    items: [singleDateField.value],
  },
  {
    type: RowType.ROW,
    items: [currentField.value],
  },
];
