<template>
  <div class="w-full flex flex-col flex-grow" :data-dynamic-prevent-pull="preventPullToRefresh">
    <reload-page :reload="reload" :loading="forceReload" />
    <overview-tab-bar>
      <router-link :to="{ name: 'freelancer invoices' }">Zzp facturen</router-link>
      <router-link :to="{ name: 'software invoices' }">Software facturen</router-link>
    </overview-tab-bar>
    <search-bar  v-model="request.searchString"
                 storage-key="invitations"
                 filter-button
                 :has-filter="filterStore.isFilterApplied"
                 @open-filter="filterOpen = true"/>          
    <layout-desktop-wrapper v-if="empty[activeInvoiceType]">
      <p class="text-center">Er zijn geen resultaten gevonden</p>
    </layout-desktop-wrapper>
    <div v-else class="flex-grow contain-strict">
      <virtual-scroller v-if="activeInvoiceType === 'freelancer'"
                        :items="InvoiceOverviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="(value) => setScrollPosition(value, 'freelancer')"/>

      <virtual-scroller v-if="activeInvoiceType === 'software'"
                        :items="InvoiceOverviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="(value) => setScrollPosition(value, 'software')"/>
    </div>
    <invoice-filter v-if="filterOpen"
                    v-model="filterStore.filters"
                    @close="filterOpen = false"
                    @reset="onFilterReset"
                    @update="onFilterUpdate"/>
  </div>
</template>

<script setup lang="ts">
import OverviewTabBar from '@app/components/OverviewTabBar.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import SearchBar from '@app/components/SearchBar.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import VirtualScroller from '@app/components/virtualscroller/VirtualScroller.vue';
import { InvoiceType, InvoiceStatus } from '@pidz/enums';
import { useInvoiceFilterStore, useInvoicesStore } from '@pidz/stores';
import {
  computed, onMounted, reactive, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import InvoiceFilter from './components/InvoiceFilter.vue';

const invoiceStore = useInvoicesStore();
const route = useRoute();
const filterStore = useInvoiceFilterStore();
const activeInvoiceType = computed(() => route.meta.type as InvoiceType);

const scrollPosition = computed(() => invoiceStore.invoicesScrollPosition[activeInvoiceType.value] ?? 0);
const endOfList = ref(false);
const forceReload = ref(false);
const loading = ref(false);
const preventPullToRefresh = ref(false);
const empty = ref({'freelancer': false, 'software': false});

const InvoiceOverviewItems = computed(() => invoiceStore.invoicesOverviewItems[activeInvoiceType.value])

const filterOpen = ref(false);

const request = reactive({
  isLoading: false,
  limit: 10,
  offset: 0,
  searchString: '',
});

const filledArrayOrUndefined = (arr: any[]) => (arr.length > 0 ? arr : undefined);

const applyLinkedInvoiceStatuses = (
  arr: number[],
  ...statuses: { [key: string]: number }[]
) => {
  const statusesArr = [...arr];
  statuses.forEach(
    (status) => statusesArr.includes(status.checkFor) && statusesArr.push(status.toAdd),
  );

  return statusesArr;
};

const getInvoicesParams = computed(() => {
  const { offset, limit, searchString } = request;

  return {
    count: false,
    offset,
    limit,
    searchString: searchString.length > 0 ? searchString : undefined,
    searchMonths: filledArrayOrUndefined(filterStore.filters.searchMonths),
    invoiceStatuses: filledArrayOrUndefined(
      applyLinkedInvoiceStatuses(
        filterStore.filters.invoiceStatuses,
        {
          checkFor: InvoiceStatus.CREDITED,
          toAdd: InvoiceStatus.FORCED_CREDITED,
        },
        {
          checkFor: InvoiceStatus.REMINDER_3,
          toAdd: InvoiceStatus.DOUBTFUL_DEBTOR,
        },
      ),
    ),
  };
});

const loadNext = async () => {
  if (loading.value) return;

  loading.value = true;
  
  const options: {} = {}; // TODO: remove from here and the api
  const params = getInvoicesParams.value;
  const type = activeInvoiceType.value;
  const newInvoices = await invoiceStore.getInvoices(type, { params, options }, route);

  request.offset+= request.limit;

  endOfList.value = newInvoices.length !== request.offset;
  loading.value = false;

  empty.value[activeInvoiceType.value] = newInvoices.length === 0;
};

const reload = async (type: string = 'pull') => {
 
  await invoiceStore.clearInvoices();

  forceReload.value = type === 'pull';

  request.offset = 0;
  preventPullToRefresh.value = false;
  empty.value = {'freelancer': false, 'software': false};

  await loadNext();

  forceReload.value = false;
};

const onFilterUpdate = () => {
  filterOpen.value = false;
  reload();
};

const onFilterReset = () => {
  filterStore.reset();
  onFilterUpdate();
};

const setScrollPosition = (emittedScrollPosition: number, type: string) => {
  invoiceStore.invoicesScrollPosition[type] = emittedScrollPosition;
};

watch(
  () => activeInvoiceType.value,
  () => setOffsetAndEndoflist(),
);

watch(
  () => request.searchString,
  () => reload(),
);

const setOffsetAndEndoflist = () => {
  let invoiceItemLength = 0;
  
  if (invoiceStore.invoicesOverviewItems[activeInvoiceType.value]) {
    invoiceItemLength = invoiceStore.invoicesOverviewItems[activeInvoiceType.value].length ?? 0;
  }

  endOfList.value = false;
  request.offset = invoiceItemLength;
}

onMounted(async () => setOffsetAndEndoflist());
</script>
