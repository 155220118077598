import {
  differenceBetweenDates,
  DateTime,
  eventDateTimeToLuxon,
  formatISODate,
  addDuration,
  createDurationObject, 
} from '@pidz/date';
import { RecurrencePeriodType } from '@pidz/enums';
import { EventDate } from './types';

// use this in luxon toISO to get rid of milliseconds
const IsoOptions = { suppressMilliseconds: true };

export const validateDatesOfEvent = (start: EventDate, end: EventDate, allDay: boolean) => {
  if (allDay){
    return true
  }
  const startDate = eventDateTimeToLuxon(start);
  const endDate = eventDateTimeToLuxon(end);
  const { hours } = differenceBetweenDates(startDate, endDate, 'hours');
  return (hours || 0) <= 0;
};

export const validateTimeOfEvent = (start: EventDate, end: EventDate, allDay: boolean) => {
  if (allDay){
    return true
  }
  const startTime = eventDateTimeToLuxon(start);
  const endTime = eventDateTimeToLuxon(end);
  const durationInMinutes = endTime.diff(startTime, 'minutes').minutes;
  return durationInMinutes >= 15;
};

export const formatRecurrenceEndDate = (
  endDate: string,
  endTime: string,
  start: string,
  periodType?: RecurrencePeriodType,
) => {
  const [hour, minute] = endTime.split(':').map((val) => Number(val));
  switch (periodType) {
    // add one year to start date and return as ISO
    case RecurrencePeriodType.OneYear:
      return DateTime.fromISO(start).plus({ years: 1 }).toISO(IsoOptions);

    // create dateTime from endDate and return as ISO
    case RecurrencePeriodType.OnDate:
      return DateTime.fromISO(endDate).set({ hour, minute }).toISO(IsoOptions);
    default:
      return undefined;
  }
};

// Unfortunately, we have to account for the API returning all_day items with a 00:00 time and an additional day
// This function sets the proper end time and date if this is an allDay event
export const formatEventDate = (dateTime: DateTime, type: 'start' | 'end', allDay = false) => {
  const isEndDateAndAllDay = type === 'end' && allDay === true;
  const yesterday = addDuration(dateTime, createDurationObject({ days: -1 }));

  const date = isEndDateAndAllDay ? yesterday.endOf('day') : dateTime;

  return {
    date: formatISODate(date) || '',
    time: date.toFormat('HH:mm'),
  };
};
