/* eslint-disable max-len */
export const mapBannerToText = new Map<BannerName, Banner>([
  ['timezone', {
    type: 'warning',
    text: `Let op: alle weergegeven tijden in de app zijn ingesteld op de lokale tijd van de opdracht in 
          Nederland. De weergegeven tijden in de app zijn niet gebaseerd op de tijdsinstellingen van 
          jouw telefoon en hierdoor kun je op dit moment geen urenregistraties aanpassen.`
  }],

  // freelancer inactive banners
  ['unsubscribed', {
    type: 'error',
    title: 'Je bent uitgeschreven',
    text: `Je bent door een PIDZ medewerker uitgeschreven. Neem voor meer informatie contact op met het PIDZ kantoor.`
  }],
  ['manual_inactive', {
    type: 'error',
    title: 'Je bent inactief',
    text: `Je bent door een PIDZ medewerker op inactief gesteld. Neem voor meer informatie contact op met het PIDZ kantoor.`
  }],
  ['invoice', {
    type: 'error',
    title: 'Activeer je profiel',
    text: `Helaas staan er nog een of meerdere facturen voor het gebruik van mijnPIDZ open. 
          Je kunt geen gebruik maken van mijnPIDZ tot dat deze voldaan zijn via iDeal. 
          Betaal je software facturen om weer van alle functies gebruik te kunnen maken.`
  }],

  ['activate_profile', {
    type: 'warning',
    title: 'Activeer je profiel',
    text: `Om je profiel gereed te maken voor opdrachten hebben we je gegevens nodig. Is je profiel compleet en zijn alle bestanden goedgekeurd? Dan kun je direct aan de slag!`
  }],
  ['nearly_inactive', {
    type: 'warning',
    title: 'Je profiel wordt binnenkort inactief',
    text: `Je hebt een of meerdere bestanden die binnenkort gaan verlopen. Vernieuw deze bestanden op tijd om te voorkomen dat je profiel inactief wordt.`
  }],

  ['profile_incomplete', {
    type: 'warning',
    title: 'Activeer je profiel',
    text: `Bekijk de secties met een oranje uitroepteken om te zien wat nog moet gebeuren op je profiel. 
           Is je profiel compleet en zijn alle bestanden goedgekeurd? Dan kun je direct aan de slag!`
  }],
  ['profile_files_nearly_expired', {
    type: 'warning',
    title: 'Je profiel wordt binnenkort inactief',
    text: `Bekijk de secties met een oranje uitroepteken om te zien wat nog moet gebeuren op je profiel. 
           Is je profiel compleet en zijn alle bestanden goedgekeurd? Dan kun je direct aan de slag!`
  }],


  ['resume_incomplete', {
    type: 'warning',
    title: 'Activeer je profiel',
    text: `Bekijk de secties met een oranje uitroepteken om te zien wat nog moet gebeuren op je CV. 
           Is je CV compleet en zijn alle bestanden goedgekeurd? Dan kun je direct aan de slag!`
  }],
  ['resume_items_nearly_expired', {
    type: 'warning',
    title: 'Je profiel wordt binnenkort inactief',
    text: `Bekijk de secties met een oranje uitroepteken om te zien wat nog moet gebeuren op je CV. 
           Is je CV compleet en zijn alle bestanden goedgekeurd? Dan kun je direct aan de slag!`
  }],
]); 
