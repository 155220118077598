<template>
  <stepper-root v-model="step"
                :default-value="1"
                class="flex flex-col"
                :linear="linear"
                orientation="vertical">

    <stepper-item v-for="item in stepperOptions"
                  :key="item.step"
                  :step="item.step"
                  :disabled="item.disabled"
                  class="flex items-center cursor-pointer group">

      <stepper-trigger class="flex items-stretch w-full p-0">

        <section class="flex relative">
          <stepper-indicator class="z-10 inline-flex w-6 h-6 items-center justify-center rounded-full
                                    group-data-[state=completed]:bg-blue
                                    group-data-[state=active]:bg-blue
                                    group-data-[state=inactive]:bg-neutral-300
                                    text-white ">
            <div>{{ item.step }}</div>
          </stepper-indicator>

          <stepper-separator v-if="item.step !== stepperOptions[stepperOptions.length - 1].step" 
                             class="absolute top-1 left-[calc(50%-2px)] w-1 h-full
                                  group-data-[state=completed]:bg-blue
                                  group-data-[state=active]:bg-neutral-300
                                  group-data-[state=inactive]:bg-neutral-300"/>

        </section>

        <section class="flex w-full items-center justify-between pl-2 pb-4 
                      text-neutral text-left group-data-[state=inactive]:text-neutral-300">
          <div>
            <stepper-title class=" text-sm font-bold">{{ item.title }}</stepper-title>
            <stepper-description class="text-base">{{ item.description }}</stepper-description>
          </div>

          <p-icon v-if="showEdit && !item.disabled" color="blue"
                  icon-name="pencil"/>
        </section>


      </stepper-trigger>

    </stepper-item>
  </stepper-root>
</template>
<script lang="ts" setup>
import {
  StepperDescription, StepperIndicator, StepperItem, StepperRoot, StepperSeparator, StepperTitle, StepperTrigger
} from 'radix-vue';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  modelValue: number
  stepperOptions: StepperOption[],
  showEdit?: boolean,
  linear?: boolean
}>(), {
  showEdit: false,
  linear: true
});

const emit = defineEmits(['update:modelValue']);

const step = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit('update:modelValue', newVal);
  }
});
</script>
