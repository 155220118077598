<template>
  <div class="w-full flex flex-col flex-grow" :data-dynamic-prevent-pull="preventPullToRefresh">
    <reload-page :reload="reload" :loading="forceReload" />
    <overview-tab-bar>
      <router-link :to="{ name: 'incidental invitations' }">Incidenteel</router-link>
      <router-link :to="{ name: 'periodical invitations' }">Periodiek</router-link>
    </overview-tab-bar>
    <search-bar  v-model="request.searchString"
                 storage-key="invitations"
                 filter-button
                 :has-filter="filterStore.isFilterApplied"
                 @open-filter="filterOpen = true"/>
    <layout-desktop-wrapper v-if="empty[activeShiftType]">
      <p class="text-center">Er zijn geen resultaten gevonden</p>
    </layout-desktop-wrapper>
    <div v-else class="flex-grow contain-strict">
      <virtual-scroller v-if="activeShiftType === 1"
                        :items="overviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="(value) => setScrollPosition(value, 1)"/>
      
      <virtual-scroller v-if="activeShiftType === 2"
                        :items="overviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="(value) => setScrollPosition(value, 2)"/>
      
    </div>
    <invitation-filter v-if="filterOpen"
                       v-model="filterStore.filters"
                       :max-work-range="maxWorkRange"
                       @close="filterOpen = false"
                       @reset="onFilterReset"
                       @update="onFilterUpdate"/>
  </div>
</template>

<script setup lang="ts">
import OverviewTabBar from '@app/components/OverviewTabBar.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import SearchBar from '@app/components/SearchBar.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import VirtualScroller from '@app/components/virtualscroller/VirtualScroller.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useInvitationFilterStore, useInvitationsStore, useUserStore } from '@pidz/stores';
import { determineChangedInvitationFilters } from '@pidz/utils';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import InvitationFilter from './InvitationFilter.vue';

const invitationStore = useInvitationsStore();
const filterStore = useInvitationFilterStore();
const userStore = useUserStore();

const route = useRoute();
const activeShiftType = computed(() => route.meta.type as number);
const filterOpen = ref(false);
const maxWorkRange = ref(0);

const scrollPosition = computed(() => invitationStore.invitationOverviewScrollPosition[activeShiftType.value] ?? 0);
const overviewItems = computed(() => invitationStore.invitationOverviewItems[activeShiftType.value]);

const endOfList = ref(false);
const forceReload = ref(false);
const loading = ref(false);
const preventPullToRefresh = ref(false);
const empty = ref({1: false, 2: false});


const request = reactive({
  isLoading: false,
  limit: 10,
  offset: 0,
  searchString: '',
});

const getShiftParams = computed<ShiftGetShiftsParams>(() => {
  const { date, functions, dayParts, travelTime, alreadyWorkedAt } = filterStore.filters;
  const { start, end } = date;
  const { offset, limit, searchString } = request;

  return {
    offset: offset,
    limit,
    type: 2,
    start,
    shiftType: activeShiftType.value,
    end: end !== '' ? end : undefined,
    searchString: searchString.length > 0 ? searchString : undefined,
    shiftStatuses: [1],
    functions: functions.length > 0 ? functions : undefined,
    dayParts: dayParts.length > 0 ? dayParts : undefined,
    travelTime: travelTime > 0 && travelTime !== maxWorkRange.value ? travelTime : undefined,
    alreadyWorkedAt: alreadyWorkedAt !== 0 ? alreadyWorkedAt : undefined,
  };
});

const loadNext = async () => {
  if (loading.value) return;
  
  loading.value = true;

  const options: {} = {}; // TODO: remove from here and in the API
  const params = getShiftParams.value;
  const type = activeShiftType.value;
  const newInvitations = await invitationStore.getInvitations(type, { params, options });

  request.offset += request.limit;
  
  endOfList.value = newInvitations.length !== request.offset;
  loading.value = false;

  empty.value[activeShiftType.value] = newInvitations.length === 0;
};

const reload = async (type: string = 'pull') => {
  await invitationStore.clearInvitations();

  forceReload.value = type === 'pull';

  request.offset = 0;
  preventPullToRefresh.value = false;
  empty.value = {1: false, 2: false};

  await loadNext();

  forceReload.value = false;
};

watch(
  () => activeShiftType.value,
  () => setOffsetAndEndoflist(),
);

watch(
  () => request.searchString,
  () => {
    logCustomEvent('invitation_search', { search: request.searchString });
    reload('normal');
  },
);

const onFilterUpdate = () => {
  logCustomEvent('invitation_filter_apply', determineChangedInvitationFilters(filterStore.filters, maxWorkRange));
  filterOpen.value = false;
  reload('normal');
};

const onFilterReset = () => {
  logCustomEvent('invitation_filter_reset');

  filterStore.reset();
  filterOpen.value = false;
  reload('normal');
};

const setScrollPosition = (emittedScrollPosition: number, type: number) => {
  invitationStore.invitationOverviewScrollPosition[type] = emittedScrollPosition;
};

const setOffsetAndEndoflist = () => {
  let length = 0;
  
  if (invitationStore.invitationOverviewItems[activeShiftType.value]) {
    length = invitationStore.invitationOverviewItems[activeShiftType.value].length ?? 0;
  }

  endOfList.value = false;
  request.offset = length;
}

onMounted(async () => {
  setOffsetAndEndoflist();

  maxWorkRange.value =  await userStore.getMaxWorkRange() ?? 0
  
  if (!filterStore.filters.travelTime) {
    filterStore.applyInitialFilters({ travelTime: maxWorkRange.value });
  }
});
</script>
