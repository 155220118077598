<template>
  <div class="w-full flex flex-col flex-grow items-center">
    <section v-if="preferencesFeatureFlag" class="settings-wrapper">
      <router-link v-for="(route, index) in routes"
                   :key="index"
                   class="router-link items-center"
                   :to="route.routeObject">
        {{ route.name }}
      </router-link>
    </section>
    <router-view v-slot="{ Component }">
      <component :is="Component" ref="view" />
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { checkPermissionPage } from '@pidz/shared/utils';
import { useFeatureflagsStore } from '@pidz/stores'
import { computed, ref } from 'vue';

const flagStore = useFeatureflagsStore();
const view = ref();

const routes = [
  { name: 'Profiel', routeObject: { name: 'profile'}},
  { name: 'Notificaties', routeObject: { name: 'notifications'}},
  ...((checkPermissionPage('rates') ? [{ name: 'Tarieven', routeObject: { name: 'rates'}}] : [])),
  { name: 'Reistijd & koppelingen', routeObject: { name: 'travel-time-clients'}},
]

const preferencesFeatureFlag = computed(() => flagStore.getStatus('ffe-preferences'));
</script>

<style lang="scss" scoped>
.settings-wrapper {
  list-style-type: none;
  display: flex;
  width: 100%;
  position: sticky;
  top: 56px;
  z-index: 20;
  background-color: white;
  border-bottom: 1px solid var(--pidz-grey-light);

  .router-link {
    color: var(--pidz-grey-dark);
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    align-content: center;
    position: relative;
    font-size: 12px;
    padding: 20px 10px;
    line-height: 1;
    flex-grow: 1;

    &::after {
      content: '';
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: var(--pidz-blue);
      transition: height 0.2s ease;
    }

    &:hover {
      background-color: var(--pidz-blue-light);
      color: black;
    }

    &.router-link-active {
      color: black;

      &::after {
        animation: active 0.2s alternate forwards ease;
      }

      @keyframes active {
        from {
          height: 0;
        }
        to {
          height: 5px;
        }
      }
    }
  }
}
</style>

