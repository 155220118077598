<template>
  <upcoming-shifts-loader v-if="!shifts" />
  <upcoming-shifts-empty v-else-if="shifts.length === 0" />
  <section v-else class="upcoming-shifts">
    <h2 class="title my-4">Volgende opdracht:</h2>
    <div class="list">
      <upcoming-shift v-for="(shift, index) in shifts"
                      :key="index"
                      :start="shift.start"
                      :end="shift.end"
                      :address="
                        formatAddress(
                          shift.department_street,
                          shift.department_house_number,
                          shift.department_city,
                        )
                      "
                      :organisation="shift.organisation_name"
                      @click="goToShift(shift.shift_id)"/>
    </div>
  </section>
</template>
<script setup lang="ts">
import { useDashboardStore } from '@pidz/stores';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import UpcomingShift from './UpcomingShift.vue';
import UpcomingShiftsEmpty from './UpcomingShiftsEmpty.vue';
import UpcomingShiftsLoader from './UpcomingShiftsLoader.vue';

const dashboardStore = useDashboardStore();

const shifts = computed(() => dashboardStore.upcomingShifts);
const router = useRouter();

const formatAddress = (street: string, houseNumber: string, city?: string) => 
  `${street} ${houseNumber}${city ? `, ${city}` : ''}`;

const goToShift = (id: number) => {
  router.push({ name: 'shift', params: { id } });
};
</script>
<style lang="scss">
.upcoming-shifts {
  .title {
    color: var(--pidz-black);
    font-weight: 400;
    margin-left: 16px;
    font-size: 0.75rem;
  }

  .list {
    padding-bottom: 24px;
    display: flex;
    width: 100%;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 16px;

    div:first-of-type {
      margin-left: 16px;
    }

    div:last-of-type {
      margin-right: 16px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
