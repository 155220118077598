<template>
  <div :class="['time-range-picker', errorMessage && errorMessage.trim().length ? '' : 'has-padding']">
    <div class="input-container">
      <p-label :input-id="inputId" :label="label" />
      <div class="inputs">
        <p-input-prepend>
          <p-icon icon-name="time" color="white"
                  :border-radius="false"/>
        </p-input-prepend>
        <p-input v-model="startTime" input-type="time"
                 :input-id="inputId"
                 :has-border-radius-left="false"
                 :has-border-radius-right="false"
                 :has-no-left-border="true" />
        <p-input v-model="endTime" input-type="time"
                 :input-id="inputId"
                 :has-border-radius-left="false"
                 :has-no-left-border="true" />
      </div>
    </div>
    <p-label v-if="errorMessage && errorMessage.trim().length" variant="error"
             :input-id="inputId"
             :label="errorMessage" />
  </div>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  timeFrom: DateTime,
  timeTo: DateTime,
  label?: string,
  inputId?: string,
  errorMessage: string
}>(), {
  inputId: 'time-range',
  label: 'Tijd'
});

const emit = defineEmits(['update:timeFrom', 'update:timeTo']);

const startTime = computed({
  get() {
    return props.timeFrom;
  },
  set(val: DateTime) {
    emit('update:timeFrom', val);
  }
});

const endTime = computed({
  get() {
    return props.timeTo;
  },
  set(val: DateTime) {
    emit('update:timeTo', val);
  }
});

</script>

<style scoped lang="scss">
.time-range-picker {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .inputs {
      display: flex;
    }
  }
  &.has-padding {
    .input-container {
      padding-bottom: 24px;
    }
  }
}
</style>
