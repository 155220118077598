<template>
  <div class="inline-options-grid">
    <slot />
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss">
.inline-options-grid {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  padding-bottom: 20px;

  /* width */
  &::-webkit-scrollbar {
     width: 10px;
     height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #00356b;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  .options-group {
    width: 60%;
    flex-shrink: 0;
    scroll-snap-align: start;

    h4 {
      margin: 0 0 12px;
      font-size: 24px;
    }

    &:not(:first-child) {
      margin-left: 24px;
    }

    .option-grid {
      padding-right: 24px;
      border-right: 1px solid var(--pidz-white);
    }

    &:last-child {
      margin-right: 40%;

      .option-grid {
        padding: 0;
        border: none;
      }
    }
  }
}</style>
