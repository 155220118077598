<template>
  <div :class="['checkbox', text ? 'has-text' : '', disabled ? 'is-disabled' : '']">
    <input :id="inputId"
           v-model="checked"
           type="checkbox"
           :disabled="disabled"
           :checked="checked"
           :value="value"/>
    <span class="checkbox-content">{{ text }}</span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  modelValue: boolean
  inputId: string
  text?: string
  disabled?: boolean
  value?: string | number
}>(), {
  modelValue: false,
  disabled: false
});

const emit = defineEmits(['update:modelValue']);

const checked = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  }
});
</script>

<style lang="scss">
$checkbox-size-medium: 20px;
$checkbox-size-large: 40px;
$checkmark-rotate: 45deg;

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  font-family: Lato;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  input {
    position: absolute;
    z-index: 1;
    height: $checkbox-size-medium;
    width: $checkbox-size-medium;
    opacity: 0;
    border-radius: 12px;
    cursor: pointer;

    &:checked+span {
      background-color: var(--pidz-blue);
      border: 0px;
    }
  }

  .checkbox-content {
    min-height: 24px;
    min-width: 24px;
    border-radius: 5px;
    background-color: var(--pidz-white);
    border: 1px solid var(--pidz-black);
  }

  &:hover input~.checkbox-content {
    background-color: var(--pidz-blue-light);
  }

  .checkbox-content:after {
    content: "";
    position: absolute;
    display: none;
    box-sizing: content-box;
  }

  input:checked~.checkbox-content:after {
    display: block;
  }

  .checkbox-content:after {
    position: relative;
    left: 9px;
    top: 5px;
    width: 4px;
    height: 8px;
    border: solid var(--pidz-white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate($checkmark-rotate);
    -ms-transform: rotate($checkmark-rotate);
    transform: rotate($checkmark-rotate);
  }

  &.has-text {
    color: var(--pidz-body-text);

    input {
      height: $checkbox-size-large;
      min-width: $checkbox-size-large;

      width: 100%;
      margin: 12px 8px;

      &:checked+span {
        border: 1px solid var(--pidz-black);
        color: var(--pidz-white);
      }

      &:checked~.checkbox-content:after {
        display: none;
      }
    }

    .checkbox-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: $checkbox-size-large;
      height: $checkbox-size-large;
      padding: 12px 8px;
    }
  }
  &.is-disabled {
    pointer-events: none;

    .checkbox-content{
      background-color: var(--pidz-grey-dark);
    }
  }
}
</style>
