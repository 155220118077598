<template>
  <label class="checkbox-with-text">
    <slot />
    <input v-model="value"
           type="checkbox" />
    <span class="checkmark"></span>
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean | undefined
}>();
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});
</script>

<style lang="scss">

.checkbox-with-text {
  cursor: pointer;
  user-select: none;
  color: var(--pidz-black);
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    order: -1;
    top: 0;
    left: 0;
    min-height: 20px;
    min-width: 20px;
    border-radius: 5px;
    background-color: var(--pidz-white);
    border: 1px solid var(--pidz-black);
    margin-right: 10px;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: var(--pidz-blue);
    border: 0;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    position: relative;
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid var(--pidz-white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

</style>
