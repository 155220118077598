<template>
  <div class="p-form-dropdown">
    <p-label v-if="props.label" class="label"
             :label="label"
             input-id="dropdown"/>
    <div class="dropdown-container">
      <p-input-prepend v-if="showDropdownPrepend" :disabled="disabled">
        <p-icon :icon-name="icon"
                color="white"
                :border-radius="false"/>

      </p-input-prepend>
      <div id="dropdown" class="dropdown">
        <p-dropdown v-model="selectedOption"
                    :options="options"
                    has-border
                    :has-left-border="!showDropdownPrepend"
                    :shadowed="false"
                    :disabled="disabled"/>
      </div>
    </div>
  </div>

</template>
<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = withDefaults(defineProps<{
  label?: string
  icon?: string
  options?: DropdownOption[]
  modelValue: string | number | undefined
  showDropdownPrepend?: boolean
  disabled?: boolean
}>(), {
  icon: 'refresh',
  showDropdownPrepend: true,
  options: () => [],
  disabled: false
});

const selectedOption = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit('update:modelValue', newVal);
  }
});

</script>
<style lang="scss" scoped>
.p-form-dropdown {
  display:flex;
  flex-direction:column;
  gap: 8px;
  .dropdown-container {
    display: flex;
    .dropdown {
      width: 100%;
    }
  }
}
</style>
