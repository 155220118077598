import { useAssetStore, useToaster } from '@pidz/stores';
import { openFile, useModalController } from '@pidz/utils';
import { ComputedRef, computed, ref } from 'vue';
import { CancelModal, DeleteModal } from '../../components';
import { getMimeType, logGoogleAnalyticsEvent } from '../../utils';

export function useFormFunctions(
  form: any,
  assets: Asset[] = [],
  cancelFunction: () => void,
  deleteFunction: () => Promise<void>,
  isEdit: boolean,
  modalTitle: string,
) {
  const newFiles = ref<File[]>([]);
  const existingFiles = ref<Asset[]>(assets);
  const deletedFiles = ref<Asset[]>([]);
  const toaster = useToaster();
  const assetStore = useAssetStore();

  const existingAssets = computed({
    get() {
      return existingFiles.value;
    },
    set(newValue) {
      existingFiles.value = newValue;
    },
  });

  const modalController = useModalController();

  //Submit Statusses
  const isSubmitting: ComputedRef<boolean> = computed(
    () => form?.value?.isSubmitting,
  );
  const isSubmitDisabled: ComputedRef<boolean> = computed(
    () =>
      form?.value?.isValidating ||
      !form?.value?.meta?.dirty ||
      (!form?.value?.meta?.valid && form?.value?.meta?.dirty),
  );

  //File Handlers
  const onAddFile = (files: File[]) => {
    newFiles.value = [...newFiles.value, ...files];
  };

  const onDeleteFile = (file: File | Asset) => {
    if ('id' in file && file.id && isEdit) {
      existingAssets.value = existingAssets.value.filter(
        (f: Asset) => f.id !== file.id,
      );
      deletedFiles.value = [...deletedFiles.value, file];
    } else {
      newFiles.value = newFiles.value.filter((f: File) => f !== file);
    }
  };

  const onDownloadFile = async (file: File | Asset) => {
    if ('id' in file && file.id) {
      const response = await assetStore.fetchAsset(file.id);
      const mimeType = getMimeType(file.filename)
      if(mimeType) openFile(response, mimeType);
    }
  };

  //Form Handlers
  const onCancel = () => cancelFunction();

  const onDelete = () => {
    modalController.open(DeleteModal, {
      type: modalTitle,
      delete: () => deleteFunction(),
      cancel: () => cancelFunction(),
    });
  };

  const succesToaster = (message: string = 'Succesvol toegevoegd') =>
    toaster.addToaster(
      {
        type: 'success',
        title: 'Gelukt!',
        message,
      },
      5000,
    );

  const warnBeforeCancel = (
    next: Function, 
    formSubmitted: boolean,
    isWeb: boolean = false,
    eventName: string,
    ) => {
    if (
      !formSubmitted &&
      (form.value.meta.dirty || newFiles.value.length > 0)
    ) {
      modalController.open(CancelModal, {
        cancelEdit: () => {
          logGoogleAnalyticsEvent(isWeb, eventName)
          next();
        },
        continueEdit: () => {
          logGoogleAnalyticsEvent(isWeb, eventName)
          modalController.close();
        }
      });
    } else {
      next();
    }
  };

  return {
    isSubmitting,
    isSubmitDisabled,
    succesToaster,
    onCancel,
    onDelete,
    deletedFiles,
    newFiles,
    existingAssets,
    onAddFile,
    onDeleteFile,
    onDownloadFile,
    warnBeforeCancel,
  };
}
