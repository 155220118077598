<template>
  <div :class="[className, 'input-row']" >
    <span v-if="textBefore">{{ textBefore }}</span>
    <input :type="type"
           :id="id"
           :inputmode="inputmode"
           v-model="value"
           :name="name"
           :min="min"
           :max="max"
           @keydown.enter="($event.target as HTMLInputElement).blur()"
    />
    <span v-if="textAfter">{{ textAfter }}</span>
    <p class="error" v-if="errorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script lang="ts" setup>
import { useField } from 'vee-validate';
import { InputTypeHTMLAttribute } from 'vue';

const props = defineProps<{
  modelValue: string | number | Date | null
  name: string
  id: string
  type: InputTypeHTMLAttribute
  inputmode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined
  textBefore?: string
  textAfter?: string
  className?: string
  min?: number
  max?: number
}>();

const { value, errorMessage } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true,
  },
);

</script>
<style lang="scss">
.input-row {
  margin-top: 24px;
  color: var(--pidz-body-text);
}

input {
  font: inherit;
}

.error {
  font-size: 13px;
  color: var(--pidz-red);
  margin-bottom: 0;
}
</style>
