<template>
  <layout-desktop-wrapper no-padding-bottom>

    <section class="w-full bg-white pt-4">
      <h1 class="text-2xl font-bold px-4 text-blue">{{ contactDetails?.name }}</h1>

      <div class="flex items-center gap-4 p-4 border-b border-neutral-200 cursor-pointer
    transition hover:bg-neutral-100" 
           @click="openMaps">
        <p-icon icon-name="location" color="blue" />
        <span class="text-neutral">
          {{ contactDetails?.street }} {{ contactDetails?.house_number }}
          <br/>{{ contactDetails?.zip_code }}, {{ contactDetails?.city }}
        </span>
      </div>

      <a :href="`mailto:${contactDetails?.email}`" class="flex items-center gap-4 p-4 border-b border-neutral-200
    transition hover:bg-neutral-100">
        <p-icon icon-name="mail" color="blue" />
        <span class="text-neutral">Stuur een email</span>
      </a>

      <a :href="`tel:${contactDetails?.phone_number}`" class="flex items-center gap-4 p-4 border-b border-neutral-200
    transition hover:bg-neutral-100">
        <p-icon icon-name="call" color="blue" />
        <span class="text-neutral">Bel {{ contactDetails?.phone_number }}</span>
      </a>

      <router-link :to="{ name: 'report' }" class="flex items-center gap-4 p-4 border-b border-neutral-200
    transition hover:bg-neutral-100">
        <p-icon icon-name="activity" color="blue" />
        <span class="text-neutral">Meld een misstand</span>
      </router-link>
    </section>
  </layout-desktop-wrapper>
</template>

<script setup lang="ts">
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { useOrganisationStore } from '@pidz/stores';
import { computed, onMounted, ref } from 'vue';

const organisationStore = useOrganisationStore();
const contactDetails = ref<ContactDetails | null>(null);

const platform = Capacitor.getPlatform();

const address = computed(() =>
`${contactDetails.value?.street} ${contactDetails.value?.house_number},
${contactDetails.value?.zip_code} ${contactDetails.value?.city}`);

const openMaps = async () => {
  let url = `https://maps.google.com/?q=${encodeURI(address.value)}`;
  if (platform === 'ios') {
    url = `comgooglemaps://?q=${encodeURI(address.value)}`;
    if (!(await AppLauncher.canOpenUrl({ url })).value) {
      url = `maps://?q=${encodeURI(address.value)}`;
    }
  }
  await AppLauncher.openUrl({ url });
};

onMounted(async () => {
  contactDetails.value = await organisationStore.getContactDetails();
});
</script>
