import { useApi } from '@pidz/api';
export interface InvoiceGetInvoicesParams {
  count: boolean;
  limit?: number;
  offset?: number;
  invoiceStatuses?: Array<number>;
  searchString?: string;
  searchMonths?: Array<string>;
}

const api = useApi();

export const invoiceService = {
  getInvoices: async (
    params: InvoiceGetInvoicesParams,
  ): Promise<{ data: Invoice[] }> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:getInvoices',
      params,
    }),
  getSoftwareInvoices: async (
    params: InvoiceGetInvoicesParams,
  ): Promise<{ data: SoftwareInvoice[]; total_count: number }> =>
    await api.jsonrpc({
      url: 'software_invoice:getInvoices',
      params,
    }),
  getSoftwareInvoice: async (invoiceId: number): Promise<SoftwareInvoice> =>
    await api.jsonrpc({
      url: 'software_invoice:getInvoice',
      params: { invoiceId },
    }),
  getFreelancerInvoiceBySheet: async (sheetId: number): Promise<Invoice> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:getFreelancerInvoiceBySheet',
      params: { sheetId },
    }),
  changeFreelancerInvoicePaidStatus: async (
    invoiceId: number,
    paid: boolean,
  ): Promise<boolean> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:changeFreelancerInvoicePaidStatus',
      params: { invoiceId, paid },
    }),
  sendInvoiceById: async (
    sheetId: number,
    preFinance: boolean,
    invoiceNumber: string | null,
  ): Promise<boolean> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:sendInvoice',
      params: { sheetId, preFinance, invoiceNumber },
    }),
  factoringCompanyCreditIsPaid: async (
    freelancerInvoiceId: number,
  ): Promise<boolean> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:factoringCompanyCreditIsPaid',
      params: { freelancerInvoiceId },
    }),
  factoringCompanyCreditPaymentLink: async (
    freelancerInvoiceId: number,
    redirectURL: string,
  ): Promise<CreditDetails> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:factoringCompanyCreditPaymentLink',
      params: { freelancerInvoiceId, redirectURL },
    }),
  softwareInvoicePaymentLink: async (
    invoiceId: number,
    redirectUrl: string,
  ): Promise<string> =>
    await api.jsonrpc({
      url: 'software_invoice:getSoftwareInvoicePaymentLink',
      params: { invoiceId, redirectUrl },
    }),
  sendInvoiceReminder: async (invoiceId: number): Promise<Invoice> =>
    await api.jsonrpc({
      url: 'freelancer_invoice:sendInvoiceReminder',
      params: { invoiceId },
    }),
  setSoftwareInvoiceToStatusPending: async (
    paymentId: string,
  ): Promise<string> =>
    await api.jsonrpc({
      url: 'software_invoice:setPaymentStatusPendingByInvoice',
      params: { paymentId },
    }),
};
