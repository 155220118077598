<template>
  <p-content-section :key="registrations?.length"
                     :has-add-button="showAddButton"
                     class="registrations"
                     @add="emit('add-registration')">
    <template #header>
      <div class="registrations-header">
        <p-badge v-if="isBadgeVisible"
                 class="registrations-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading edit icon="marking"
                   class="font-bold">Registraties & Keurmerken</p-heading>
      </div>
    </template>
    <template v-if="registrations && registrations.length" #content>
      <section v-if="isFreelancer && !registrations?.find((item) => item.type.id === ZORGAANBIEDERS_PORTAAL_ID)"  
               class="healthcare_portal">
        <strong>Registratie zorgaanbiedersportaal is verplicht</strong>
        <div>Klik hierboven op het plusje om je registratie toe te voegen aan je CV</div>
      </section>
      <ul class="registrations__list">
        <li v-for="(registration, index) in registrations" :key="index">
          <p-card :title="registration.type.name"
                  has-button
                  icon-name="pencil"
                  @button-click="emit('edit-registration', registration.id)">
            <template #content>
              <registration-card :registration="registration" />
            </template>
          </p-card>
        </li>
      </ul>
    </template>
    <template #empty>
      <strong v-if="isFreelancer">Verplicht: Registratie in het zorgaanbiedersportaal</strong>
      <strong v-else>Je hebt nog geen registraties of keurmerken toegevoegd</strong>
      <p>
        Klik hierboven op het plusje om je eerste registratie of keurmerk toe te
        voegen aan je CV
      </p>
    </template>
  </p-content-section>
</template>
<script setup lang="ts">
import { ZORGAANBIEDERS_PORTAAL_ID } from '@pidz/shared/forms/cv/registration';
import { useCVStore, useUserStore } from '@pidz/stores';
import { computed } from 'vue';
import RegistrationCard from './RegistrationCard.vue';

const props = defineProps<{
  registrations?: Registration[];
  isBadgeVisible: boolean;
}>();

const cvStore = useCVStore();
const userStore = useUserStore();

const showAddButton = computed(
  () => props?.registrations?.length !== cvStore?.registrationTypes?.length,
);

const isFreelancer = computed(() => userStore.isUserFreelancer);

const emit = defineEmits(['add-registration', 'edit-registration']);
</script>

<style lang="scss" scoped>
.registrations-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}

.registrations {
  &__list {
    margin-left: 0;
    list-style: none;
    padding-left: 0;

    @media screen and (min-width: 768px) {
      margin-right: 2rem;
    }

    li {
      padding-bottom: 8px;
    }
  }

  .healthcare_portal {
    text-align: center;
    color: var(--pidz-grey-dark)
  }
}
</style>
