<template>
  <layout-desktop-wrapper>
    <work-experience-form ref="form"
                          :form-data="formDataWorkExperience"
                          is-edit
                          :work-experience-item="workExperienceItem"/>
    <form-delete-row name="Werkervaring" :on-delete="form?.onDelete"
                     class="border-t border-neutral-200"/>   
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import FormDeleteRow from '@app/components/FormDeleteRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date';
import { WorkExperienceForm } from '@pidz/shared/components';
import { globalValidations, rows } from '@pidz/shared/forms/cv/work';
import { useCVStore } from '@pidz/stores';
import { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const form = ref<typeof WorkExperienceForm>();

const route = useRoute();
const cvStore = useCVStore();

const workExperienceItem = computed(() =>
  cvStore.getWorkExperienceById(Number(route.params.id)),
);

const formatWorkExperience = (
  workExperience: WorkExperience,
): WorkExperienceForm => {
  const { start_date, end_date, function_name, ...item } = workExperience;
  const functionId = cvStore.getFunctionIdByName(function_name);

  let formattedWorkExperience: WorkExperienceForm = {
    ...item,
    function_name: functionId,
  };
  if (workExperience.is_current) {
    formattedWorkExperience = {
      ...formattedWorkExperience,
      start_date: formatYmd(DateTime.fromISO(start_date)),
    };
  } else {
    formattedWorkExperience = {
      ...formattedWorkExperience,
      date: {
        start_date: formatYmd(DateTime.fromISO(start_date)),
        end_date: end_date ? formatYmd(DateTime.fromISO(end_date)) : null,
      },
    };
  }
  return formattedWorkExperience;
};

const formDataWorkExperience: PidzFormData<WorkExperienceForm> = {
  globalValidations,
  values: formatWorkExperience(workExperienceItem.value as WorkExperience),
  rows,
};

const loadStores = async () => {
  Promise.all([
    await cvStore.loadResume(),
    await cvStore.loadFreelancerResumeFunctions(),
  ]);
};

onMounted(async () => {
  await loadStores();
});
</script>
<style scoped lang="scss">
.work-experience {
  &__delete-btn {
    padding: 32px 16px;
    background-color: var(--pidz-white);
  }
  &__delete-text {
    color: var(--pidz-black);
  }
}
</style>