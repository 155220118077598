import { deltaHoursMinutes } from '@pidz/date';
import { DateTime } from 'luxon';

interface DateTimeRange {
  start: string;
  end: string;
}

const dateStringToInt = (str: string | Date) => {

  const newDate: DateTime = (str instanceof Date) 
    ? DateTime.fromJSDate(str)
    : DateTime.fromISO(str);

  return newDate.toMillis();
}

export const validateStartBeforeEnd = (range: DateTimeRange) => {
  if (DateTime.fromISO(range.start) > DateTime.fromISO(range.end)) {
    throw Error('De eindtijd kan niet voor de starttijd zijn.');
  }
};

export const validateWorkHoursMax24 = (range: DateTimeRange) => {
  const hours = (dateStringToInt(range.end) - dateStringToInt(range.start)) / 3600000;
  if (hours > 24) {
    throw Error('Een opdracht kan niet langer dan 24 uur zijn.');
  }
};

export const validateOverOutsideWorkHours = (range: DateTimeRange, workRange: DateTimeRange) => {
  const outside =
    dateStringToInt(range.end) <= dateStringToInt(workRange.start) ||
    dateStringToInt(range.start) >= dateStringToInt(workRange.end);
  if (!outside) {
    throw Error('Overuren moeten buiten de werktijd vallen.');
  }
};

export const validateOverNoGap = (range: DateTimeRange, workAndOverworkBlocks: DateTimeRange[]) => {
  const startEqualsOthersEnd = (a, b) => dateStringToInt(a.start) === dateStringToInt(b.end);
  const exactlyAfter = workAndOverworkBlocks.find((b) => startEqualsOthersEnd(b, range));
  if (exactlyAfter) {
    return;
  }

  const exactlyBefore = workAndOverworkBlocks.find((b) => startEqualsOthersEnd(range, b));
  if (exactlyBefore) {
    return;
  }

  throw Error('Overuren moeten altijd aansluiten op de gewerkte uren.');
};

export const validateInsideWorkHours = (
  range: DateTimeRange,
  workRange: DateTimeRange,
  error: string,
) => {
    const inside =
    dateStringToInt(range.start) >= dateStringToInt(workRange.start) &&
    dateStringToInt(range.end) <= dateStringToInt(workRange.end);
  if (!inside) {
    throw Error(error);
  }
};

export const validateNoOverlap = (range: DateTimeRange, others: DateTimeRange[], error: string) => {
  const [rangeStartDate, rangeEndDate] = [range.start, range.end].map((s) => new Date(s));
  const conflict = others.find((other) => {
    const [otherStartDate, otherEndDate] = [other.start, other.end].map((s) => new Date(s));
    const noConflict = rangeEndDate <= otherStartDate || rangeStartDate >= otherEndDate;
    return noConflict === false;
  });
  if (conflict) {
    throw Error(error);
  }
};

export const validationStartsAtMidNight = (range: DateTimeRange) => {
  const rangeStartDate = DateTime.fromISO(range.start);
  return rangeStartDate.hour === 0 && rangeStartDate.minute === 0 && rangeStartDate.second === 0;
};

export const validateSleepMinOneHour = (range: DateTimeRange) => {
  const { hours } = deltaHoursMinutes(range.start, range.end);
  if (hours < 1) {
    throw Error(
      'De gewerkte tijd in de slaapuren onder een uur worden niet uitbetaald volgens het contract.',
    );
  }
};
export const validateSleepMax4Hours = (range: DateTimeRange) => {
  const { hours, minutes } = deltaHoursMinutes(range.start, range.end);
  if (hours + minutes / 60 > 4) {
    throw Error('De gewerkte uren mogen niet langer dan 4 uur zijn.');
  }
};
export const validateSleepRoundInQuarters = (range: DateTimeRange) => {
  const { minutes } = deltaHoursMinutes(range.start, range.end);
  if (minutes % 15 !== 0) {
    throw Error(
      'De gewerkte tijd in de slaapuren moet naar beneden worden afgerond tot kwartieren.',
    );
  }
};
