<template>
  <section class="sheet-block-work-hours">
    <label>Datum van/tot</label>
    <input-border multi>
      <agenda-icon />
      <input v-model="values.start.date" type="date"
             class="date-start" />
      <input v-model="values.end.date" type="date"
             class="date-end" />
    </input-border>
    <label>
      <span>Tijd van/tot</span>
      <span v-if="timeLabelTrailingText" class="trail">{{timeLabelTrailingText}}</span>
    </label>
    <input-border multi>
      <time-icon />
      <input v-model="values.start.time" type="time"
             class="time-start" />
      <input v-model="values.end.time" type="time"
             class="time-end" />
    </input-border>
    <template v-if="showOvertimeRemarkForm">
      <input-border class="overtime-remark-form">
        <label>Toelichting overuren</label>
        <input v-model="overtimeRemark" type="text">
      </input-border>
    </template>
    <div v-if="error" class="error">{{error}}</div>
  </section>
</template>
<script lang="ts" setup>

import { AgendaIcon, TimeIcon } from '@pidz/icons';
import {
  computed, onMounted, reactive, ref, watch,
} from 'vue';
import InputBorder from '../../../components/InputBorder.vue';

const props = defineProps<{
  modelValue: any,
  incidentalShiftTimes: any,
  otherWorkHourBlocks:any[],
  validate: Function;
}>();

const emit = defineEmits(['update:modelValue']);

const values = reactive({
  start: { date: '', time: '' },
  end: { date: '', time: '' },
});
const error = ref<string|null>(null);

const overtimeRemark = ref(props.modelValue.overtimeRemark);

const dateTimeToLocalYMD = (date:Date) => date.toLocaleString(
  'nl',
  {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
).split('-').reverse().join('-');

const dateTimeToLocalTime = (date:Date) => date.toLocaleString('nl', { hour: '2-digit', minute: '2-digit' });

const splitDateTime = (date:Date) => ({ date: dateTimeToLocalYMD(date), time: dateTimeToLocalTime(date) });

const isValidDate = (d:Date) => !Number.isNaN(d.getTime());

const joinDateTime = ({ date, time }) => {
  const d = new Date(`${date}T${time}`);
  return isValidDate(d) ? d : null;
};

const subtract = (a:Date, b:Date) => (a.getTime() - b.getTime()) / 1000;

const getModel = () => {
  const startDateTime = joinDateTime(values.start);
  const endDateTime = joinDateTime(values.end);
  let overtimeRemarkText = overtimeRemark.value
  if (startDateTime === null || endDateTime === null) {
    throw new Error('incomplete');
  }

  if (endDateTime < startDateTime) {
    throw new Error('De eindtijd kan niet voor de starttijd zijn.');
  }
  if (subtract(endDateTime, startDateTime) > 24 * 60 * 60) {
    throw new Error('Een opdracht kan niet langer dan 24 uur zijn.');
  }

  const { incidentalShiftTimes } = props;
  if (!incidentalShiftTimes) {
    return {
      startTime: startDateTime,
      duration: subtract(endDateTime, startDateTime),
      startOffset: 0,
      endOffset: 0,
    };
  }
  const shiftStart = new Date(incidentalShiftTimes.start);
  const shiftEnd = new Date(incidentalShiftTimes.end);
  const startOffset = subtract(startDateTime, shiftStart)
  const endOffset = subtract(endDateTime, shiftEnd)
  if (!hasOffset(startOffset, endOffset)) {
    overtimeRemarkText = null
  }

  return {
    startTime: shiftStart,
    duration: subtract(shiftEnd, shiftStart),
    startOffset,
    endOffset,
    overtimeRemark: overtimeRemarkText
  };
};

const updateModel = () => {
  error.value = null;
  try {
    const model = getModel();
    const newModel = {
      ...props.modelValue,
      ...model,
    }
    emit('update:modelValue', newModel);
    props.validate(newModel)
  } catch (e:any) {
    if (e.message !== 'incomplete') {
      error.value = e.message;
    }
  }
};

onMounted(() => {
  const {
    startTime, duration, startOffset, endOffset,
  } = props.modelValue;
  const startDate = new Date(startTime);
  if (!isValidDate(startDate)) { return; }

  const endDate = new Date(startDate.getTime() + (duration + endOffset) * 1000);
  startDate.setUTCSeconds(startDate.getUTCSeconds() + startOffset);

  Object.assign(values, {
    start: splitDateTime(startDate),
    end: splitDateTime(endDate),
  });
  updateModel();
});
watch(values, updateModel);
watch(overtimeRemark, updateModel);

const time = (d) => new Date(d).toLocaleString('nl', {
  hour: '2-digit',
  minute: '2-digit',
});

const hasOffset = (start, end) => { return start < 0 || end > 0 };

const timeLabelTrailingText = computed(() => {
  const { incidentalShiftTimes } = props;
  if (!incidentalShiftTimes) { return ''; }

  const { startOffset, endOffset } = props.modelValue;
  if (startOffset !== 0 || endOffset !== 0) {
    const { start, end } = incidentalShiftTimes;
    return `Was ${time(start)} tot ${time(end)}`;
  }
  return '';
});

const showOvertimeRemarkForm = computed(() => {
  const { startOffset, endOffset } = props.modelValue;
  return hasOffset(startOffset, endOffset)
});

</script>
<style lang="scss">
.sheet-block-work-hours {
  label {
    display: flex;
    justify-content: space-between;
    color: var(--pidz-black);
    margin-bottom: 4px;
  }
  .icon-field-field {
    margin-bottom: 12px;
  }
  .error {
    color: var(--pidz-red);
  }
  input {
    padding: 0 12px;

    &[type="date"]::-webkit-calendar-picker-indicator,
    &[type="time"]::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
  .input-border {
    margin-bottom: 12px;
  }
  .overtime-remark-form {
    display: grid;
    grid-template-columns: 1fr;
    height: fit-content;
  }
  .overtime-remark-form input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #333;
  }
}
</style>
