<template>
  <p-button :class="['to-top-button', {'to-top-button--visible': buttonVisible}]" @click="emit('to-top')">
    <p-icon class="icon"
              icon-name="arrow"
              color="blue"
              shadowed/>
    <span>Terug naar boven</span>
  </p-button>
</template>
<script setup lang="ts">
import { computed } from 'vue';


const props = defineProps<{ isVisible: boolean }>()
const emit = defineEmits(['to-top'])
const buttonVisible = computed(() => props.isVisible)
</script>
  
<style scoped lang="scss">
// this is styled here and not in CompLib
// because its probably only used here
.to-top-button {
    display:flex;
    z-index: 1;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom .2s cubic-bezier(0.175, 0.885, 0.6, 1.275);
    border-radius: 20px;
    font-size: 14px;
    color: var(--pidz-blue) !important;
    background-color: var(--pidz-blue-light) !important;
    box-shadow: var(--shadow-light);
    .icon {
      transform: rotate(90deg)
    }
    span {
      padding-right: 12px;
    }
    bottom: -100px;
    &--visible {
      bottom: 12px;
      @supports (-webkit-touch-callout: none) {
        bottom: 20px;
      }
    }
}
</style>
  