<template>
  <section class="remove-sheetrow">
    <a @click="removeSheetRow"> Item verwijderen </a>
  </section>
</template>
<script lang="ts" setup>
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useSheetStore } from '@pidz/stores';
import { useRouter } from 'vue-router';
import { SheetBlockModel } from '../types';

const sheetStore = useSheetStore();

const router = useRouter();

const props = defineProps<{
  workingHours: SheetBlockModel;
}>();

const removeSheetRow = async () => {
  try {
    await sheetStore.removeSheetBlock(props.workingHours.sheet_block_id)
    logCustomEvent('sheet_row_remove');
  } finally {
    router.back();
  }
};
</script>
<style lang="scss">
.remove-sheetrow {
  a {
    color: var(--pidz-red);
    text-decoration: underline;
  }
}
</style>
