import { rerouteToOverview } from '@pidz/shared/utils';
import { useCVStore } from '@pidz/stores';
import { RouteLocationNormalized, useRouter } from 'vue-router';

export async function checkIfWorkExperienceItemExists(
  to: RouteLocationNormalized,
) {
  const cvStore = useCVStore();
  const workExperience = cvStore.getWorkExperienceById(Number(to.params.id));
  await cvStore.loadFreelancerResumeFunctions();
  if (!workExperience) {
    const router = useRouter();
    rerouteToOverview(router, 'cv');
  }
}

export async function checkIfRegistrationItemExists(
  to: RouteLocationNormalized,
) {
  const cvStore = useCVStore();
  const registration = cvStore.getRegistrationById(Number(to.params.id));
  await cvStore.loadRegistrationTypes();
  if (!registration) {
    const router = useRouter();
    rerouteToOverview(router, 'cv');
  }
}

export async function checkIfEducationItemExists(
  to: RouteLocationNormalized,
) {
  const cvStore = useCVStore();
  const education = cvStore.getEducationById(Number(to.params.id));
  if (!education) {
    const router = useRouter();
    rerouteToOverview(router, 'cv');
  }
}

export async function checkIfTrainingItemExists(
  to: RouteLocationNormalized,
) {
  const cvStore = useCVStore();
  const training = cvStore.getTrainingById(Number(to.params.id));
  await cvStore.loadTrainingSubjects();
  if (!training) {
    const router = useRouter();
    rerouteToOverview(router, 'cv');
  }
}

export async function checkIfLanguageItemExists(
  to: RouteLocationNormalized,
) {
  const cvStore = useCVStore();
  const language = cvStore.getLanguageProficiencyById(Number(to.params.id));
  await cvStore.loadLanguages();
  if (!language) {
    const router = useRouter();
    rerouteToOverview(router, 'cv');
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function preLoadResumeData(
  _to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) {
  if (!from.name) {
    const cvStore = useCVStore();
    await cvStore.loadResume();
  }
}
