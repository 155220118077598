<template>
  <div class="itemLink">
    <strong>{{ organisation.name }}</strong>
    <div class="itemLink__departments">
      <div v-for="(department, index) in departments"
           :key="index"
           class="itemLink__department">
        <div class="itemLink__department--column--left">
          <span>{{ department.name ?? 'Alle afdelingen' }}</span>
          <i>{{ department.rates.length }} functie{{
            department.rates.length > 1 ? 's' : ''
          }}</i>
        </div>
        <p-icon class="cursor-pointer"
                icon-name="pencil"
                color="blue" 
                @click="routeToEditPage(department.rates[0].organisation_id, department.rates[0].department_id)"/>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  organisation: OrganisationDifferentRates;
}>();

const departments = computed(() =>
  Array.from(
    props.organisation.rates.reduce((map, rate) => {
      if (!map.has(rate.department_name!)) {
        map.set(rate.department_name!, {
          id: rate.department_id,
          name: rate.department_name,
          rates: [],
        });
      }
      map.get(rate.department_name!)!.rates.push(rate);
      return map;
    }, new Map<string, DepartmentDifferentRates>()).values()
  )
);

const routeToEditPage = (organisation?: number, department?: number) => {
  router.push({
    name: `rates different-rates edit`,
    params: { organisation, department },
  });
};
</script>

<style lang="scss" scoped>
.itemLink {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: 8px;

  &__departments {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__department {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--column--left {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
</style>
