<!-- eslint-disable vue/no-mutating-props -->
<template>
  <filter-overview title="Facturen filteren op" class="invoices-filter">
    <section>
      <h3 class="my-4 font-bold">Status:</h3>
      <option-grid v-model="modelValue.invoiceStatuses" multi
                   :options="invoiceStatusOptions" />
    </section>
    <section>
      <h3 class="my-4 font-bold">Maand:</h3>
      <inline-option-grid ref="inlineOptionsGrid">
        <div v-for="(option, i) in monthOptions" :key="i"
             class="options-group">
          <h4>{{ option.year }}</h4>
          <option-grid v-model="modelValue.searchMonths"
                       multi
                       :columns="3"
                       :options="option.months"/>
        </div>
      </inline-option-grid>
    </section>
  </filter-overview>
</template>
<script lang="ts" setup>
import FilterOverview from '@app/components/FilterOverview.vue';
import OptionGrid from '@app/components/OptionGrid.vue';
import InlineOptionGrid from '@app/components/filter/InlineOptionGrid.vue';
import { generateMonthOptions } from '@pidz/date';
import { InvoiceStatus } from '@pidz/enums';
import { DateTime } from 'luxon';
import { onMounted, ref } from 'vue';
import { IInvoiceFilterParams } from '../types';

defineProps<{ modelValue: IInvoiceFilterParams }>();

const foundingYear = 2012;
const endYear = DateTime.now().year + 3;
const monthOptions = ref(generateMonthOptions(foundingYear, endYear));
const inlineOptionsGrid = ref<InstanceType<typeof InlineOptionGrid> | null>(null);

const invoiceStatusOptions = [
  { name: 'Gefactureerd', value: InvoiceStatus.INVOICED },
  { name: '1e herinnering', value: InvoiceStatus.REMINDER_1 },
  { name: '2e herinnering', value: InvoiceStatus.REMINDER_2 },
  { name: '3e herinnering', value: InvoiceStatus.REMINDER_3 },
  { name: 'Betaald', value: InvoiceStatus.PAID },
  { name: 'Gecrediteerd', value: InvoiceStatus.CREDITED },
];

onMounted(() => {
  if (!inlineOptionsGrid.value) {
    return;
  }
  const { offsetLeft } = inlineOptionsGrid.value.$el.children[DateTime.now().year - foundingYear];
  inlineOptionsGrid.value.$el.scrollLeft = offsetLeft;
});
</script>
<style lang="scss"></style>
