import router from '@app/router';
import { PushNotificationSchema } from '@capacitor/push-notifications';

const routeToShiftDetail = ({ parameter_data }) => ({ name:'shift', params: { id:parameter_data } });
const routeToSheetDetail = ({ parameter_data }) => ({ name:'sheet', params: { id:parameter_data } });

const routeToInvitationDetail = ({ parameter_data }) => {
  try {
    const obj = JSON.parse(parameter_data);
    return { name:'shift', params: { id:obj.shift } };
  } catch(e) {
    return { name:'invitations' };
  }
}

const handlerMap = {
  notify_freelancer_freelancer_sheet: routeToSheetDetail,
  notify_worker_filled_shift_changed: routeToShiftDetail,
  notify_worker_shift_worker_removed: routeToShiftDetail,
  notify_freelancer_freelancer_shift: routeToShiftDetail,
  notify_worker_invite_worker_by_app_only: routeToInvitationDetail,
}

export default (notification: PushNotificationSchema) => {
  const { data } = notification;
  const handler = handlerMap[data.parameter_type];
  const route = handler ? handler(data) : { name:'dashboard' };
  router.push(route);
};
