import { RowType } from '@pidz/enums';
import {
  travelTimeField
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<TravelTimeForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [travelTimeField.value],
  },
];
