export const enum FileType {
    PDF = 'pdf',
    JPG = 'jpg',
    JPEG = 'jpeg',
    PNG = 'png',
    WEBP = 'webp',
    DOC = 'doc',
    DOCX = 'docx',
    ZIP = 'zip'
}

export const enum MimeType {
    PDF = 'application/pdf',
    JPG = 'application/jpg',
    JPEG = 'application/jpg',
    PNG = 'application/png',
    WEBP = 'application/webp',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ZIP = 'application/zip'
}

export enum AssetType {
    COC = 'cocDocument',
    COMPLAINTS_DOCUMENT = 'complaintsDocument',
    INSURANCE_DOCUMENT = 'insuranceDocument',
    INSURANCE_DOCUMENT_PAYMENT_PROOF = 'insuranceDocumentPaymentProof',
    VOG = 'vogDocument'
}
