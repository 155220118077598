<template>
  <h2>Vast tarief van kracht</h2>
  <p>Voor deze opdracht heeft de zorginstelling een vast tarief bepaald van €{{formattedRate}}.
    Dit tarief kan afwijken van jouw ingestelde tarieven.</p>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{rate: number}>();

const formattedRate = computed(() => props.rate.toFixed(2).replace('.', ','));

</script>
<style lang="scss">
</style>
