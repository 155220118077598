<template>
  <div v-click-out="() => { if (!disabled) isDropdownOpen = false }" :class="[`custom-dropdown ${props.textVariant}`, {bordered: hasBorder}, {'disabled': disabled}]">
    <div :class="['selected-item', {noleftborder : !hasLeftBorder}]"  @click="toggleDropdown">
      <span class="title">{{ selectedOption !== undefined ? selectedOption.label : 'Maak een keuze' }}</span>
      <p-icon class="chevron" :color="disabled ? 'grey' : 'black'"
              icon-name="chevron" size="small"/>
    </div>
    <div v-if="isDropdownOpen" class="dropdown-list-container">
      <ul ref="itemList"
          :class="['dropdown-list', {shadowed: shadowed}]">
        <li v-for="(option, index) in options" :key="index"
            @click="handleOptionClick(option.value)">{{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useClickOutDirective } from '@pidz/utils';
import { computed, nextTick, ref } from 'vue';

const { vClickOut } = useClickOutDirective();
const isDropdownOpen = ref(false);
const emit = defineEmits(['update:modelValue']);
const props = withDefaults(defineProps<{
  modelValue: string|number|undefined
  options: DropdownOption[]
  hasBorder?: boolean
  textVariant?: TextVariant
  iconInline?: boolean
  hasLeftBorder?: boolean
  shadowed?: boolean
  disabled?: boolean
}>(), {
  textVariant: 'medium',
  hasLeftBorder: true,
  shadowed: true,
  disabled: false
});

const itemList = ref<HTMLElement>();
const selectedOption = computed(() => props.options.find((o: DropdownOption) => o.value === props.modelValue));

const handleScrollPosition = async () => {
  await nextTick();
  const index = props.options.findIndex((option: DropdownOption) => option.value === props.modelValue);
  if (index > -1) {
    const listItem = itemList.value?.children[index] as HTMLElement;
    itemList.value!.scrollTop = listItem.offsetTop;
  }
};

const toggleDropdown = () => {
  if (props.disabled) return;
  isDropdownOpen.value = !isDropdownOpen.value;
  if (isDropdownOpen.value) {
    handleScrollPosition();
  }
};

const handleOptionClick = (value: string|number) => {
  emit('update:modelValue', value);
  toggleDropdown();
};

// styling vars
const rotation = computed(() => (isDropdownOpen.value ? '180deg' : '0deg'));
const iconPosition = computed(() => (props.iconInline ? 'start' : 'space-between'));
const borderBottomRight = computed(() => (isDropdownOpen.value ? '0px' : '4px'));
const borderBottomLeft = computed(() => (isDropdownOpen.value ? '0px' : '4px'));
</script>

<style scoped lang="scss">
.custom-dropdown {
  position: relative;
  display: flex;
  gap: 12px;

  &.large .selected-item .title {
    font-size: 20px;
  }

  &.largest .selected-item .title {
    font-size: 28px
  }
  .title {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.disabled {
    background-color: var(--pidz-grey-light);
    color: var(--pidz-grey-dark);

    .selected-item {
      cursor: default;
    }
  }

  .selected-item {
    cursor: pointer;
    margin: 2px 12px;
    border-radius: 4px;
    border-bottom-right-radius: v-bind(borderBottomRight);
    border-bottom-left-radius: v-bind(borderBottomLeft);
    display: flex;
    gap: 8px;
    justify-content: v-bind(iconPosition);
    align-items: center;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    &.noleftborder {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: 0px;
    }
  }
  .dropdown-list-container{
    width: 100%;
    position: absolute;
    top: 100%;
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 9999;
    display: block;

    .dropdown-list {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 150px;
      overflow-y: auto;
      background: white;

      &.shadowed {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      }
      li {
        padding: 12px 12px;
        cursor: pointer;
        font-weight: 400;
          &:hover {
            background-color: var(--pidz-blue);
            color: white;
          }
        }
      }
    }
  }

.bordered {
  .selected-item {
    padding: 8px;
    margin: 0px;
    border: 1px solid black;
  }
  .dropdown-list-container {
    border: 1px solid black;
    border-top: 0px;
  }
}

.chevron{
  transform: rotate(v-bind(rotation));
  transition-duration: 0.4s;
}
</style>
