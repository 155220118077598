<template>
  <h2 class="px-4 py-2 text-sm font-bold">Inbox</h2>
  <p-tabs :tabs :active-tab="'actions'">
    <template #actions>
      <actions-tab :actions @dismiss="(params) => dismissInboxItem(params, NotificationItemType.ACTIONS)"/>
    </template>
    <template #messages>
      <messages-tab :messages @dismiss="(params) => dismissInboxItem(params, NotificationItemType.NOTIFICATIONS)"/>
    </template>
  </p-tabs>
</template>
<script lang="ts" setup>
import { NotificationItemType } from '@pidz/enums';
import { useNotificationInboxStore, useToaster } from '@pidz/stores'
import { computed, onMounted } from 'vue';
import ActionsTab from './components/ActionsTab.vue';
import MessagesTab from './components/MessagesTab.vue'

const notificationStore = useNotificationInboxStore()
const toaster = useToaster()

const messages = computed<NotificationItem[] | undefined>(() => notificationStore.notifications?.data)
const actions = computed<NotificationItem[] | undefined>(() => notificationStore.actions?.data)

const tabs = computed<Tab[]>((): Tab[] => [
  { title: `Acties (${actions.value?.length || 0})`, key: 'actions' },
  { title: `Recente berichten (${messages.value?.length || 0})`, key: 'messages' },
])

const dismissInboxItem = async (params: DismissNotificationsParams, type: NotificationItemType) => {
  await notificationStore.dismissNotification(params, type)
  toaster.addToaster(
    {
      type: 'success',
      title: 'Gelukt!',
      message: 'Succesvol verwijderd',
    },
    5000,
  );
}

onMounted(() => {
  notificationStore.loadActions(true)
  notificationStore.loadNotifications(true)
})

</script>
