/* eslint-disable max-lines */
export const countries = new Map([
    ['AF', 'Afghanistan'],
    ['AX', 'Åland'],
    ['AL', 'Albanië'],
    ['DZ', 'Algerije'],
    ['VI', 'Amerikaanse Maagdeneilanden'],
    ['AS', 'Amerikaans-Samoa'],
    ['AD', 'Andorra'],
    ['AO', 'Angola'],
    ['AI', 'Anguilla'],
    ['AQ', 'Antarctica'],
    ['AG', 'Antigua en Barbuda'],
    ['AR', 'Argentinië'],
    ['AM', 'Armenië'],
    ['AW', 'Aruba'],
    ['AU', 'Australië'],
    ['AZ', 'Azerbeidzjan'],
    ['BS', 'Bahama\'s'],
    ['BH', 'Bahrein'],
    ['BD', 'Bangladesh'],
    ['BB', 'Barbados'],
    ['BE', 'België'],
    ['BZ', 'Belize'],
    ['BJ', 'Benin'],
    ['BM', 'Bermuda'],
    ['BT', 'Bhutan'],
    ['BO', 'Bolivia'],
    ['BA', 'Bosnië en Herzegovina'],
    ['BW', 'Botswana'],
    ['BV', 'Bouvet'],
    ['BR', 'Brazilië'],
    ['VG', 'Britse Maagdeneilanden'],
    ['IO', 'Brits Indische Oceaanterritorium'],
    ['BN', 'Brunei'],
    ['BG', 'Bulgarije'],
    ['BF', 'Burkina Faso'],
    ['BI', 'Burundi'],
    ['KH', 'Cambodja'],
    ['CA', 'Canada'],
    ['BQ', 'Caribisch Nederland'],
    ['CF', 'Centraal-Afrikaanse Republiek'],
    ['CL', 'Chili'],
    ['CN', 'China'],
    ['CX', 'Christmaseiland'],
    ['CC', 'Cocoseilanden'],
    ['CO', 'Colombia'],
    ['KM', 'Comoren'],
    ['CG', 'Congo-Brazzaville'],
    ['CD', 'Congo-Kinshasa'],
    ['CK', 'Cookeilanden'],
    ['CR', 'Costa Rica'],
    ['CU', 'Cuba'],
    ['CW', 'Curaçao'],
    ['CY', 'Cyprus'],
    ['DK', 'Denemarken'],
    ['DJ', 'Djibouti'],
    ['DM', 'Dominica'],
    ['DO', 'Dominicaanse Republiek'],
    ['DE', 'Duitsland'],
    ['EC', 'Ecuador'],
    ['EG', 'Egypte'],
    ['SV', 'El Salvador'],
    ['GQ', 'Equatoriaal-Guinea'],
    ['ER', 'Eritrea'],
    ['EE', 'Estland'],
    ['ET', 'Ethiopië'],
    ['FO', 'Faeröer'],
    ['FK', 'Falklandeilanden'],
    ['FJ', 'Fiji'],
    ['PH', 'Filipijnen'],
    ['FI', 'Finland'],
    ['FR', 'Frankrijk'],
    ['TF', 'Franse Zuidelijke Gebieden'],
    ['GF', 'Frans-Guyana'],
    ['PF', 'Frans-Polynesië'],
    ['GA', 'Gabon'],
    ['GM', 'Gambia'],
    ['GE', 'Georgië'],
    ['GH', 'Ghana'],
    ['GI', 'Gibraltar'],
    ['GD', 'Grenada'],
    ['GR', 'Griekenland'],
    ['GL', 'Groenland'],
    ['GP', 'Guadeloupe'],
    ['GU', 'Guam'],
    ['GT', 'Guatemala'],
    ['GG', 'Guernsey'],
    ['GN', 'Guinee'],
    ['GW', 'Guinee-Bissau'],
    ['GY', 'Guyana'],
    ['HT', 'Haïti'],
    ['HM', 'Heard en McDonaldeilanden'],
    ['HN', 'Honduras'],
    ['HU', 'Hongarije'],
    ['HK', 'Hongkong'],
    ['IE', 'Ierland'],
    ['IS', 'IJsland'],
    ['IN', ' India'],
    ['ID', 'Indonesië'],
    ['IQ', 'Irak'],
    ['IR', 'Iran'],
    ['IL', 'Israël'],
    ['IT', ' Italië'],
    ['CI', 'voorkust'],
    ['JM', 'Jamaica'],
    ['JP', ' Japan'],
    ['YE', 'Jemen'],
    ['JE', 'Jersey'],
    ['JO', 'Jordanië'],
    ['KY', 'Kaaimaneilanden'],
    ['CV', ' Kaapverdië'],
    ['CM', 'Kameroen'],
    ['KZ', 'Kazachstan'],
    ['KE', 'Kenia'],
    ['KG', 'Kirgizië'],
    ['KI', 'Kiribati'],
    ['UM', 'Kleine afgelegen eilanden van de Verenigde Staten'],
    ['KW', 'Koeweit'],
    ['HR', 'Kroatië'],
    ['LA', 'Laos'],
    ['LS', 'Lesotho'],
    ['LV', 'Letland'],
    ['LB', 'Libanon'],
    ['LR', 'Liberia'],
    ['LY', 'Libië'],
    ['LI', 'Liechtenstein'],
    ['LT', 'Litouwen'],
    ['LU', 'Luxemburg'],
    ['MO', 'Macau'],
    ['MG', 'Madagaskar'],
    ['MW', 'Malawi'],
    ['MV', 'Malediven'],
    ['MY', 'Maleisië'],
    ['ML', 'Mali'],
    ['MT', 'Malta'],
    ['IM', 'Man'],
    ['MA', 'Marokko'],
    ['MH', 'Marshalleilanden'],
    ['MQ', 'Martinique'],
    ['MR', 'Mauritanië'],
    ['MU', 'Mauritius'],
    ['YT', 'Mayotte'],
    ['MX', 'Mexico'],
    ['FM', 'Micronesië'],
    ['MD', 'Moldavië'],
    ['MC', 'Monaco'],
    ['MN', 'Mongolië'],
    ['ME', 'Montenegro'],
    ['MS', 'Montserrat'],
    ['MZ', 'Mozambique'],
    ['MM', 'Myanmar'],
    ['NA', 'Namibië'],
    ['NR', 'Nauru'],
    ['NL', 'Nederland'],
    ['NP', 'Nepal'],
    ['NI', 'Nicaragua'],
    ['NC', 'Nieuw-Caledonië'],
    ['NZ', 'Nieuw-Zeeland'],
    ['NE', 'Niger'],
    ['NG', 'Nigeria'],
    ['NU', 'Niue'],
    ['MP', 'Noordelijke Marianen'],
    ['KP', 'Noord-Korea'],
    ['MK', 'Noord-Macedonië'],
    ['NO', 'Noorwegen'],
    ['NF', 'Norfolk'],
    ['UG', 'Oeganda'],
    ['UA', 'Oekraïne'],
    ['UZ', 'Oezbekistan'],
    ['OM', 'Oman'],
    ['AT', 'Oostenrijk'],
    ['TL', 'Oost-Timor'],
    ['PK', 'Pakistan'],
    ['PW', 'Palau'],
    ['PS', 'Palestina'],
    ['PA', 'Panama'],
    ['PG', 'Papoea-Nieuw-Guinea'],
    ['PY', 'Paraguay'],
    ['PE', 'Peru'],
    ['PN', 'Pitcairneilanden'],
    ['PL', 'Polen'],
    ['PT', 'Portugal'],
    ['PR', 'Puerto Rico'],
    ['QA', 'Qatar'],
    ['RE', 'Réunion'],
    ['RO', 'Roemenië'],
    ['RU', 'Rusland'],
    ['RW', 'Rwanda'],
    ['BL', 'Saint-Barthélemy'],
    ['KN', 'Saint Kitts en Nevis'],
    ['LC', 'Saint Lucia'],
    ['PM', 'Saint-Pierre en Miquelon'],
    ['VC', 'Saint Vincent en de Grenadines'],
    ['SB', 'Salomonseilanden'],
    ['WS', 'Samoa'],
    ['SM', 'San Marino'],
    ['SA', 'Saoedi-Arabië'],
    ['ST', 'Sao Tomé en Principe'],
    ['SN', 'Senegal'],
    ['RS', 'Servië'],
    ['SC', 'Seychellen'],
    ['SL', 'Sierra Leone'],
    ['SG', 'Singapore'],
    ['SH', 'Sint-Helena, Ascension en Tristan da Cunha'],
    ['MF', 'Saint-Martin'],
    ['SX', 'Sint Maarten'],
    ['SI', 'Slovenië'],
    ['SK', 'Slowakije'],
    ['SD', 'Soedan'],
    ['SO', 'Somalië'],
    ['ES', 'Spanje'],
    ['SJ', 'Spitsbergen en Jan Mayen'],
    ['LK', 'Sri Lanka'],
    ['SR', 'Suriname'],
    ['SZ', 'Swaziland'],
    ['SY', 'Syrië'],
    ['TJ', 'Tadzjikistan'],
    ['TW', 'Taiwan'],
    ['TZ', 'Tanzania'],
    ['TH', 'Thailand'],
    ['TG', 'Togo'],
    ['TK', 'Tokelau'],
    ['TO', 'Tonga'],
    ['TT', 'Trinidad en Tobago'],
    ['TD', 'Tsjaad'],
    ['CZ', 'Tsjechië'],
    ['TN', 'Tunesië'],
    ['TR', 'Turkije'],
    ['TM', 'Turkmenistan'],
    ['TC', 'Turks- en Caicoseilanden'],
    ['TV', 'Tuvalu'],
    ['UY', 'Uruguay'],
    ['VU', 'Vanuatu'],
    ['VA', 'Vaticaanstad'],
    ['VE', 'Venezuela'],
    ['AE', 'Verenigde Arabische Emiraten'],
    ['US', 'Verenigde Staten'],
    ['GB', 'Verenigd Koninkrijk'],
    ['VN', 'Vietnam'],
    ['WF', 'Wallis en Futuna'],
    ['EH', 'Westelijke Sahara/SADR'],
    ['BY', 'Wit-Rusland'],
    ['ZM', 'Zambia'],
    ['ZW', 'Zimbabwe'],
    ['ZA', 'Zuid-Afrika'],
    ['GS', 'Zuid-Georgia en de Zuidelijke Sandwicheilanden'],
    ['KR', 'Zuid-Korea'],
    ['SS', 'Zuid-Soedan'],
    ['SE', 'Zweden'],
    ['CH', 'Zwitserland'],
  ]);
  
  export const suggestedCountries = () => ['NL', 'BE', 'DE'].map((isoCode) => [isoCode, countries.get(isoCode)]);