<template>
  <p-dropdown-list :open="model" :position="position"
                   @close="closeDropDown">
    <template #items>
      <p-file-list-item file-name="Download CV"
                        show-download-button
                        @download="downloadFile('cv')"/>
      <p-file-list-item file-name="Download overzicht uren voor herregistratie"
                        show-download-button
                        @download="downloadFile('reregistration')"/>
    </template>
  </p-dropdown-list>
</template>

<script lang="ts" setup>
import { cvService } from '@pidz/api';
import { MimeType } from '@pidz/enums';
import { useToaster } from '@pidz/stores';
import { openFile } from '@pidz/utils';
import { ref } from 'vue';

const emit = defineEmits(['start', 'end']);
const model = ref(false);
defineProps<{
  position?: PidzDropdownListPosition
}>();

const openDropdown = (event: Event) => {
  event.stopPropagation();
  model.value = true;
};

defineExpose({ openDropdown });

const closeDropDown = () => (model.value = false);

const downloadFile = async (type: string) => {
  emit('start');
  model.value = false;

  useToaster().addToaster({
    type: 'success',
    title: 'Download wordt gestart',
    message: '',
  });

  let document: Base64File | undefined;

  if (type === 'cv') document = await cvService.getResumeAsPDF();
  if (type === 'reregistration') document = await cvService.geBIGRegistrationAsPDF();
  if (document) await openFile(document, MimeType.PDF);
  emit('end')
}
</script>
