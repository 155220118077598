<template>
  <section ref="toasterElement" :class="['toaster', props.type]">
    <p-icon class="toaster-icon"
            size="small"
            :icon-name="iconName"
            :background="iconColor"
            boxed/>
    <div class="content-wrapper">
      <div class="title">{{ props.title }}</div>
      <div class="message">{{ props.message }}</div>
    </div>

    <div class="close-toaster" @click="closeToaster">
      <p-icon class="close-icon" icon-name="close"
              role="close-toaster"/>
    </div>
    <div class="progress-bar" ></div>
  </section>
</template>

<script lang="ts" setup>
import { useHorizontalSwipe } from '@pidz/utils';
import { computed, onMounted, ref } from 'vue';

const toasterElement = ref<HTMLElement | undefined>();
const emit = defineEmits(['close']);

const props = withDefaults(defineProps<{
  type: 'error' | 'success' | 'warning' | 'info';
  title: string;
  message: string;
  timestamp: string;
  duration: number;
}>(), {
  type: 'info'
});

const iconName = computed(() => {
  switch (props.type) {
    case 'error':
    case 'warning':
      return 'exclamation';
    case 'success':
      return 'check';
    case 'info':
      return 'info';
    default:
      return 'info';
  }
});

const iconColor = computed(() => {
  switch (props.type) {
    case 'error':
      return 'red';
    case 'success':
      return 'green';
    case 'warning':
      return 'orange';
    case 'info':
      return 'blue';
    default:
      return 'white';
  }
});
// demo comment
const closeToaster = () => {
  emit('close', props.timestamp);
};

// start timeout to close when toaster is shown
setTimeout(closeToaster, props.duration);

// hwn mounted, init swipe function
onMounted(() => {
  useHorizontalSwipe((direction) => {
    if (direction === -1) {
      closeToaster();
    }
  }, toasterElement.value ?? undefined);
});

const progressBarTimer = computed(
  () => `${props.duration}ms linear forwards`
);
</script>

<style lang="scss" scoped>
.toaster {
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: var(--shadow-light);

  &.error {
    background: var(--pidz-red-light);

    .progress-bar {
      background: var(--pidz-red);
    }
  }

  &.success {
    background: var(--pidz-green-light);

    .progress-bar {
      background: var(--pidz-green);
    }
  }

  &.warning {
    background: var(--pidz-orange-light);

    .progress-bar {
      background: var(--pidz-orange);
    }
  }

  &.info {
    background: var(--pidz-blue-light);

    .progress-bar {
      background: var(--pidz-blue);
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: black;

    .title {
      font-size: 14px;
      font-weight: bold;
    }

    .message {
      font-size: 12px;
    }
  }

  .progress-bar {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    right: 0;
    animation: progress v-bind(progressBarTimer)
  }

  @keyframes progress {
    100% {
      right: 100%;
    }
  }

  .toaster-icon {
    max-width: 24px;
    flex-shrink: 0;
  }

  .close-toaster {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;

    .close-icon {
      color: black;
      height: 14px;
      width: 14px;
      cursor: pointer;
    }
  }
}
</style>
