<template>
  <p-card :padding="padding"
             title="Zorginstellingen"
             :rounded="false" title-size="lg"
             icon-size="large">
    <template #content>
      <div v-if="contentLoaded" class="institutions">
        <div v-for="(organisation, index) in organisations" :key="index">
          <link-list-item :item=organisation @edit-organisation="(id: number) => emit('edit', id)" />
          <p-ruler v-if="organisations && index !== (organisations.length - 1)"/>
        </div>
      </div>
      <div v-else class="spinner">
        <p-icon class="spinner__icon"
                   :icon-name="'spinner'"
                   size="large"
                   color="blue"/>
      </div>
    </template>
  </p-card>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import LinkListItem  from './LinkListItem.vue'

const props = defineProps<{
  padding: string;
  organisations?: Organisation[]
}>();

const contentLoaded = computed(() => props.organisations)
const emit = defineEmits(['edit'])

</script>
<style scoped lang="scss">
.institutions {
  margin-top: 24px;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  &__icon {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
