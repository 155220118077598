<template>
  <div class="drawer-wrapper">
    <transition name="slide">
      <div v-if="calendarStore.isDrawerOpen"
           class="calendar-drawer"
           data-prevent-pull>
        <div class="closer"
             @click="calendarStore.isDrawerOpen = false">
          <general-chevron />
        </div>
        <div class="overflow-container">
          <p v-if="dailyEvents.length === 0">Er zijn geen geplande opdrachten voor vandaag</p>
          <calendar-event v-for="event in dailyEvents"
                          v-else
                          :key="event.start"
                          :calendar-event="event"/>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
/* eslint-disable */
import GeneralChevron from '@app/icons/GeneralChevronWhite.vue';
import { collidesWithDay } from '@pidz/utils';
import { useCalendar } from '@pidz/stores';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import CalendarEvent from './CalendarEvent.vue';

const router = useRouter();
const calendarStore = useCalendar();

const dailyEvents = computed<CalendarEvent[] | []>(() => {
  const filteredEvents = calendarStore.events.filter((event: CalendarEvent) =>
    collidesWithDay(calendarStore.date, event),
  );
  return filteredEvents;
});

router.afterEach((to) => {
  if (
    typeof to.name === 'string' &&
    !['shift', 'calendar edit event', 'calendar add event'].includes(to.name)
  ) {
    calendarStore.isDrawerOpen = false;
  }
});
</script>
<style lang="scss">
.drawer-wrapper {
  height: 100%;
  overflow-y: hidden;

  .calendar-drawer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--pidz-blue);
    bottom: 0;
    padding: 0 12px;
    padding-bottom: env(safe-area-inset-bottom);
    border-radius: 5px 5px 0 0;
    color: var(--pidz-white);
    position: relative;

    p {
      text-align: center;
    }

    .overflow-container {
      position: relative;
      width: 100%;
      flex: 1;
      overflow-y: scroll;
      padding-bottom: 16px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .closer {
      padding: 12px;
      color: var(--pidz-white);
      display: flex;
      justify-content: center;

      .pidz-icon {
        transform: rotateX(180deg);
      }
    }

    &::after {
      content: '';
      width: calc(100% - 24px);
      height: 32px;
      bottom: env(safe-area-inset-bottom);
      position: absolute;
      background: linear-gradient(to top, var(--pidz-blue), transparent);
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  .slide-enter-from,
  .slide-leave-to {
    transform: translateY(100%);
  }
}
</style>
