import { UserType } from '@pidz/enums'
import { profilePermissions } from './permissions/uzk/profilePermissions'
const permissionsAgencyWorker: PagePermission[] = [ 
  ...profilePermissions,
  // Rates
  {
    name: 'rates',
    view: false
  },
  {
    name: 'rates overview',
    view: false
  },
  {
    name: 'rates standard-rates edit',
    view: false
  },
  // Sheets
  {
    name: 'sheets',
    view: false
  },
  {
    name: 'sheets overview',
    view: false
  },
  {
    name: 'sheet',
    view: false
  },
  {
    name: 'add sheetrow',
    view: false
  },
  {
    name: 'edit sheet',
    view: false
  },
  {
    name: 'add microsheetrow',
    view: false
  },
  {
    name: 'edit microsheetrow',
    view: false
  },
  // Invoices
  {
    name: 'invoices',
    view: false
  },
  {
    name: 'freelancer invoices',
    view: false
  },
  {
    name: 'freelancer invoices overview',
    view: false
  },
  {
    name: 'freelancer invoice',
    view: false
  },
  {
    name: 'software invoices',
    view: false
  },
  {
    name: 'software invoices overview',
    view: false
  },
  {
    name: 'software invoice',
    view: false
  }
]

const permissionsFreelancer: PagePermission[] = [ 
  {
    name: 'profile overview',
    view: true,
    sections: [
      {
        name: 'profile personal insuranceDocument',
        view: true
      },
      {
        name: 'profile personal insuranceDocumentPaymentProof',
        view: true
      },
    ]
  },
]

export const permissionMap = new Map<UserType, PagePermission[]>(
  [
    [UserType.AGENCY_WORKER, permissionsAgencyWorker],
    [UserType.FREELANCER, permissionsFreelancer]
  ]
)
