<template>
  <layout-desktop-wrapper ref="viewport"  class="calendar-event-page">
    <form class="bg-white" @submit.prevent>
      <section class="title">Niet beschikbaar</section>
      <calendar-add-event-date-section v-model:start="event.start"
                                       v-model:end="event.end"
                                       v-model:allDay="event.allDay"/>
      <calendar-add-event-recurring-section v-model:days="repeat.days"
                                            v-model:periodType="repeat.period_type"
                                            v-model:repeatType="repeat.repeat_type"
                                            v-model:occurrences="repeat.occurrences"
                                            v-model:end="repeat.end"
                                            :date="event.start.date"/>
    </form>
    <form-buttons-row :on-cancel="backToCalendarOverview"
                      :on-submit="saveCalendarEvent"
                      :is-submit-disabled="invalidEvent"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import router from '@app/router';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { Preferences } from '@capacitor/preferences';
import { roundToNextHour, formatHour, eventDateTimeToLuxon } from '@pidz/date';
import { CalendarEventType, RecurrencePeriodType, RecurrenceRepeatType } from '@pidz/enums';
import { useCalendar } from '@pidz/stores';
import { useInputScrollFix } from '@pidz/utils';
import { toTypedSchema } from '@vee-validate/zod';
import { DateTime } from 'luxon'
import { useForm } from 'vee-validate';
import { computed, nextTick, reactive, ref, watch } from 'vue';
import * as zod from 'zod';
import CalendarAddEventDateSection from './components/CalendarAddEventDateSection.vue';
import CalendarAddEventRecurringSection from './components/CalendarAddEventRecurringSection.vue';
import { getZodRepeatSchema } from './forms/zodSchemas';
import {
  formatRecurrenceEndDate,
  validateDatesOfEvent,
  validateTimeOfEvent,
} from './utils';

const calendarStore = useCalendar();

const backToCalendarOverview = () => {
  logCustomEvent('agendaitem_create_cancel');
  router.go(-1);
};
const viewport = ref();
useInputScrollFix(viewport);

const event = reactive({
  start: {
    date: calendarStore.date.toFormat('yyyy-MM-dd'),
    time: formatHour(roundToNextHour()),
  },
  end: {
    date: calendarStore.date.toFormat('yyyy-MM-dd'),
    time: formatHour(roundToNextHour().plus({ hours: 1 })),

  },
  type: CalendarEventType.UNAVAILABLE,
  allDay: false,
});

// for when 'all day' is selected
const IsoOptions = { suppressMilliseconds: true };
const calendarItemModel = computed(():CalendarFormItemModel => ({
  all_day: event.allDay,
  type: event.type,
  start:
    event.allDay
      ? eventDateTimeToLuxon(event.start).startOf('day').toISO(IsoOptions)!
      : eventDateTimeToLuxon(event.start).toISO(IsoOptions)!,
  end:
    event.allDay
      ? eventDateTimeToLuxon(event.end).endOf('day').toISO(IsoOptions)!
      : eventDateTimeToLuxon(event.end).toISO(IsoOptions)!,
}));

const repeat = reactive({
  period_type: 0,
  end: DateTime.now(),
  days: [DateTime.fromISO(event.start.date!).weekday - 1],
  repeat_type: 0,
  occurrences: 1,
});

const repeatModel = computed(() => ({
  type: calendarItemModel.value.type,
  period_type: repeat.period_type,
  start: calendarItemModel.value.start,
  end: repeat.period_type === RecurrencePeriodType.SetAmount ? undefined : formatRecurrenceEndDate(
    repeat.end.toISO()!,
    event.start.time,
    calendarItemModel.value.start,
    repeat.period_type,
  ),
  days:
    repeat.repeat_type === RecurrenceRepeatType.Weekly
      ? repeat.days
      : undefined,
  repeat_type: repeat.repeat_type,
  occurrences:
    repeat.period_type === RecurrencePeriodType.SetAmount
      ? repeat.occurrences
      : 1,
}));

const handleCustomEvents = async (data) => {
  const currentCalendarView = (await Preferences.get({ key: 'calendarView' })).value ?? '';
  const allDay = calendarItemModel.value.all_day;

  if (data.repeat) {
    logCustomEvent('agendaitem_create_recurring_item', {
      type: data.type,
      view: currentCalendarView,
      all_day: allDay,
      frequency: repeatModel.value.repeat_type,
      duration: repeatModel.value.period_type,
    });
  } else if (calendarItemModel.value.type === 3) {
    logCustomEvent('agendaitem_create_not_available', {
      view: currentCalendarView,
      allDay,
    });
  }
};

watch(() => event.start.date,
  async () => {
    // this is here because otherwise the rec. enddate update is based on the old date
    await nextTick();
    repeat.days = [DateTime.now().weekday - 1];
  },
);


const validationSchema = toTypedSchema(
  zod.object({
    repeat: getZodRepeatSchema(event),
  }),
);

const { meta, isValidating, resetForm } = useForm({
  validationSchema,
});

watch(() => repeat.period_type, () => resetForm());

const isEventDateValid = computed(() => validateDatesOfEvent(event.start, event.end, event.allDay));
const isEventTimeValid = computed(() => validateTimeOfEvent(event.start, event.end, event.allDay));

const invalidEvent = computed(
  () => isValidating.value || !(meta.value.valid && isEventDateValid.value && isEventTimeValid.value)
);

const saveCalendarEvent = async () => {
  if (invalidEvent.value) {
    return;
  }

  try {
    const calendarData = {
      calendarItemModel: {
        ...calendarItemModel.value,
        repeat:
          repeat.repeat_type === RecurrenceRepeatType.Never
            ? undefined
            : { ...repeatModel.value },
      },
    };
    await calendarStore.addCalendarItem(calendarData);
    handleCustomEvents(calendarData.calendarItemModel);
    calendarStore.setDate(DateTime.fromISO(event.start.date))
    backToCalendarOverview();
  } catch (e) {
    // handled by global error handler
  }
};

</script>

<style lang="scss">
.calendar-event-page {
  section {
    padding: 24px 16px;
    border-bottom: 1px solid #d8d8d8;

    &.title {
      color: var(--pidz-body-text);
      border-bottom: 0px;
      padding-bottom: 0px;
      font-weight: bold;
      font-size: 14px;
    }
    &.event-date-section {
      padding-top: 16px;
    }
    .labeled-slot > label {
      font-size: 12px;
      margin-bottom: 8px;
      display: inline-block;
    }

    span {
      font-size: 12px;
    }

    .dropdown {
      color: var(--pidz-body-text);
    }
  }
}
</style>
