<template>
  <div class="upcoming-shifts-loader">
    <div class="skeleton-title"> </div>
    <div class="skeleton-body"> </div>
  </div>
</template>
<script setup lang="ts">

</script>
<style lang="scss">
.upcoming-shifts-loader {
  padding: 0 12px;

  div {
    border-radius: 5px;
    background-color: rgb(191, 210, 230, 1);
    animation: pulse 1s ease-in-out alternate-reverse infinite;
  }

  .skeleton-title {
    width: 40%;
    height: 1rem;
  }

  .skeleton-body {
    width: 80%;
    height: 96px;
    margin-top: 12px;
  }

  @keyframes pulse {
    100% {
      background-color: rgb(221, 240, 260, 1);
    }
  }
}

</style>
