<template>
  <text-row class="invoice-detail-hour-row"
            :left-label="row.function.name"
            :left="`${formatPriceToEuroString(row.rate)} ${
              row.vat_free ? 'B.T.W vrij' : 'ex B.T.W.'
            }`"
            :right-label="time"
            :right="total"/>
</template>
<script lang="ts" setup>
import TextRow from '@app/components/TextRow.vue';
import { formatPriceToEuroString } from '@pidz/utils';
import { computed } from 'vue';

const props = defineProps<{
  row: any;
}>();

const time = computed(() => {
  const { minutes } = props.row;
  const sign = minutes < 0 ? '-' : '';

  const rest = Math.abs(minutes);
  const hours = Math.floor(rest / 60);
  const mins = rest - hours * 60;
  return `${sign}${hours} uur ${mins} min`;
});

const total = computed(() => {
  const { row } = props;
  return formatPriceToEuroString(row.rate * (row.minutes / 60));
});
</script>
<style lang="scss">
.invoice-detail-hour-row {
}
</style>
