
import { contactService } from '@pidz/api';
import { defineStore } from 'pinia';
export default defineStore('contact', () => {
  const sendHotlineMail = async (formData: ContactForm) => {
    const request = {
      ...formData,
      contactType: formData.contactType === 'message' ? 1 : 2
    }
    await contactService.sendHotlineMail(request)
  }
  return {
    sendHotlineMail
  }
})