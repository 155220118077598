<template>
  <section v-if="!disabled" class="sheet-block-list-section">
    <label>{{ title }}</label>
    <div v-for="block in value"
         :key="getId(block)"
         class="block"
         @click="edit(block)">
      <slot name="item" :block="block">
        <div class="total">{{ blockTimeTotal(block) }}</div>
        <div class="description">{{ timeDescription(block) }}</div>
      </slot>
      <div class="remove" @click.stop="remove(block)">
        <bin-icon />
      </div>
    </div>
    <div class="link" @click="isFull ? edit(value[0]) : add()">
      {{ isFull ? editTitle : addTitle }}
    </div>
    <Teleport to="body">
      <sheet-block-list-editor v-if="editing"
                               v-model="editing"
                               :title="getId(editing) ? editTitle : addTitle"
                               :error="error"
                               @cancel="editing = null"
                               @save="save">
        <slot name="editor">
          <component :is="editor ?? DateTimeRange" v-model="editing" 
                     inverse />
        </slot>
      </sheet-block-list-editor>
    </Teleport>
  </section>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { BinIcon } from '@pidz/icons';
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { UnsavedBlock } from '../types';
import { getId, sameId } from '../utils/block-id';
import blockTimeTotal from '../utils/block-time-total';
import hasStartAndEnd from '../utils/has-start-and-end';
import DateTimeRange from './DateTimeRange.vue';
import SheetBlockListEditor from './SheetBlockListEditor.vue';

const props = defineProps<{
  title: string;
  addTitle: string;
  editTitle: string;
  modelValue: any[];
  blockFactory: Function;
  validate?: Function;
  editor?: any;
  allowSave?: Function;
  allowOnlyOne?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits(['update:modelValue']);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

const editing = ref<UnsavedBlock | null>(null);
const error = ref<string | null>(null);
watch(editing, (nv: any) => {
  error.value = null;
  try {
    if (props.validate && hasStartAndEnd(nv)) {
      const otherBlocks = value.value.filter((b) => !sameId(b, nv));
      props.validate(nv, otherBlocks);
    }
  } catch (e: any) {
    error.value = e.message;
  }
});

const timeDescription = (block: UnsavedBlock) => {
  const hoursMinutes = (isoDate: string) => DateTime.fromISO(isoDate).toFormat('HH:mm');
  return `Van ${hoursMinutes(block.start)} tot ${hoursMinutes(block.end)}`;
};

const isFull = computed(() => props.allowOnlyOne && value.value.length > 0);
const add = () => {
  editing.value = {
    ...props.blockFactory(),
    sheet_block_id: null,
  };
};
const edit = (block: UnsavedBlock) => {
  editing.value = { ...block };
};

const remove = (block: UnsavedBlock) => {
  value.value = value.value.filter((b) => !sameId(block, b));
};
const save = () => {
  logCustomEvent('sheet_block_save', { type: props.title });

  if (!editing.value) {
    return;
  }
  if (props.allowSave && !props.allowSave(editing.value)) {
    return;
  }
  if (!getId(editing.value)) {
    value.value = [
      ...value.value,
      { ...editing.value, temp_id: Math.random() },
    ];
  } else {
    value.value = value.value.map((b) => (sameId(editing.value!, b) ? editing.value : b));
  }
  editing.value = null;
};
</script>
<style lang="scss">
.sheet-block-list-section {
  position: relative;
  z-index: 10;
  
  & > label {
    display: block;
    color: var(--pidz-black);
    margin-bottom: 4px;
    font-weight: bold;
  }

  .link {
    color: var(--pidz-blue);
    text-decoration: underline;
    margin-top: 4px;
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--pidz-grey-dark);
    border-radius: 4px;
    padding: 0;
    margin-bottom: 2px;
    & > div {
      padding: 24px 12px;
    }

    .total {
      font-weight: bold;
      color: var(--pidz-black);
      min-width: 120px;
    }
    .description {
      border-left: 1px solid var(--pidz-grey-dark);
      flex: 1;
      border-left: 1px solid var(--pidz-grey-dark);
      color: var(--pidz-black);
      overflow: hidden;
      overflow-wrap: break-word;
    }

    .remove {
      margin-right: 12px;
      --size: 40px;
      width: var(--size);
      height: var(--size);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
