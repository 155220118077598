<template>
  <modal>
    <h2 class="font-bold">Selecteer omgeving</h2>
    <infinity-spinner v-if="!fetchedEnvironments" class="spinner-center" />
    <select v-else v-model="environment"
            class="environment-select">
      <option v-for="(environment, i) in environmentStore.all"
              :key="i"
              :value="environment.name">
        {{ environment.name }}
      </option>
    </select>
  </modal>
</template>

<script lang="ts" setup>
import InfinitySpinner from '@app/components/InfinitySpinner.vue';
import Modal from '@app/components/Modal.vue';
import { Environment } from '@app/pages/types';
import useEnvironment from '@app/stores/environment';
import config from '@app/utils/configure-firebase-app';
import { getEnvironments } from '@app/utils/environment';
import { computed, onMounted, ref } from 'vue';

const environmentStore = useEnvironment();
const fetchedEnvironments = ref(false);

onMounted(async () => {
  try {
    const environments = await getEnvironments();
    environmentStore.all = JSON.parse(environments as string);
  } catch (e) {
    const parsedConfig = JSON.parse(config.defaultConfig);
    environmentStore.all = parsedConfig.environments;
  } finally {
    fetchedEnvironments.value = true;
  }
});

const environment = computed({
  get() {
    return environmentStore.selected?.name;
  },
  set(env) {
    const updatedEnv = environmentStore.all?.find(
      (e: Environment) => e.name === env,
    );
    if (updatedEnv) {
      environmentStore.setActiveEnvironment(updatedEnv);
    }
  },
});
</script>

<style lang="scss">
.spinner-center {
  margin: auto;
  svg {
    stroke: var(--pidz-blue);
  }
}

.environment-select {
  width: 100%;
  font: inherit;
  color: var(--pidz-body-text);
  border: 1px solid var(--pidz-body-text);
  background-color: transparent;
  padding: 12px;
  border-radius: 4px;
}
</style>
