import { useApi } from '@pidz/api';

const api = useApi();

export const profileService = {
  getFreelancerFunctions: (): Promise<FreelancerFunction[]> => api.jsonrpc({
    url: 'profile:getFreelancerFunctions'
  }),

  getProfile: (): Promise<Freelancer> => api.jsonrpc({
    url: 'freelancer:getCurrentFreelancer'
  }),

  setProfile: (freelancerModel: Freelancer): Promise<Freelancer> => api.jsonrpc({
    url: 'freelancer:updateFreelancer',
    params: { freelancerModel }
  }),

  setAgencyWorkerProfile: (agencyWorkerModel: Freelancer): Promise<Freelancer> => api.jsonrpc({
    url: 'freelancer:updateAgencyWorker',
    params: { agencyWorkerModel }
  }),

  getCoCOrganisation: (cocNumber: string): Promise<any> => api.jsonrpc({
    url: 'coc:getCoCOrganisationByNumber',
    params: { cocNumber }
  }),

  updateEmailPreferences: (value: boolean): Promise<boolean> => api.jsonrpc({
    url: 'freelancer_preferences:updatePreferences',
    params: { preferences: {invite_by_email: value} }
  }),

  updateAutomaticPaymentChoice: (choice: 'ideal' | 'incasso' | 'none'): Promise<void> => api.jsonrpc({
    url: 'freelancer:updateAutomaticPaymentChoice',
    params: { choice }
  })
}

