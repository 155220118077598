<template>
  <div class="department-item">
    <div class="department-item__titlerow">
      <p-heading variant="secondary" font-size="md">{{ department.department_name }}</p-heading>
      <div class="department-item__controls">
        <p-icon v-if="department.department_description" icon-name="chevron"
                :class="['department-item__chevron', { expanded: showDetails }]" 
                @click="showDetails = !showDetails" />
        <p-toggle v-model="isLinked" colored
                  :input-id="department.department_name ?? 'department-toggle'"/>
      </div>
    </div>
    <span>Reistijd: {{ Math.round(department.travel_time / 60) }} min</span>
    <div v-if="showDetails" class="department-item__description">
      <p v-html="departmentInfo"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DOMPurify from 'dompurify';
import { computed, ref } from 'vue';

const props = defineProps<{
  department: Department;
}>();

const emit = defineEmits(['update:department']);

const isLinked = computed({
  get: () => props.department.status === 'linked',
  set: (value: boolean) => {
    emit('update:department', {
      ...props.department,
      status: value ? 'linked' : 'blocked',
    });
    return value;
  },
});

const showDetails = ref(false);
const departmentInfo = computed(() => DOMPurify.sanitize(props.department.department_description));
</script>

<style lang="scss" scoped>
.department-item {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  &__titlerow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  };
  &__controls {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  &__chevron {
    transition: transform 0.3s;
    &.expanded {
      transform: rotate(180deg);
    }
  }
  &__description {
    padding-top: 16px;
  }
}
</style>
