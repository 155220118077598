import {MicroSheetRow} from '@app/pages/sheets/types';

export const validateOvertimeRemark = (row: MicroSheetRow) => {
  if (row.startOffset < 0 || row.endOffset > 0) {
    if(!row.overtimeRemark) {
      throw Error('Geef de reden voor de overuren')
    }
    if(row.overtimeRemark.length > 280) {
      throw Error('Overuren toelichting mag niet langer dan 280 karakters zijn.')
    }
  }
}
