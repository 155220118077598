import { DateTime } from 'luxon';

export const convertToFileRowData = (assets: Asset[], fileName: string) => {
  if (assets.length === 0) return;

  let asset: Asset;

  if (assets.length > 1) {
    asset = findNewestDocument(assets);
  } else asset = assets[0];

  return {
    status: asset.status,
    date: asset.valid_until ? DateTime.fromISO(asset.valid_until!).toFormat('dd-MM-yyyy') : '',
    dateText: asset.valid_until ? 'Geldig tot' : '',
    fileText: fileName ?? (asset.type?.name || ''),
  }
}

const findNewestDocument = (assets: Asset[]) => assets.reduce((a, b) => 
  DateTime.fromISO(a.valid_until!) > DateTime.fromISO(b.valid_until!) ? a : b);
