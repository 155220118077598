import { sortFormOptionsAlphabetical } from '@pidz/shared/forms';
import { OTHER_SUBJECT_ID } from './const';

export const trainingOptions = (trainingSubjects: TrainingSubject[]): FormDropDownOption[] => {
  const options = trainingSubjects.map(
    (training, index): FormDropDownOption => ({
      id: index,
      value: training.id,
      label:
        training.name || `Geen label beschikbaar voor id: ${training.id}`,
    }),
  )

  const otherSubject = options.find(
    (option: FormDropDownOption) => option.value === OTHER_SUBJECT_ID,
  );

  const filteredOptions = options
    .filter((option: FormDropDownOption) => option.value !== OTHER_SUBJECT_ID)
    .sort(sortFormOptionsAlphabetical);

  if (otherSubject) filteredOptions.push(otherSubject);

  return filteredOptions;
};

export const proofTypeOptions = (): FormDropDownOption[] => {
  return [
    {
      id: 0,
      label: 'Certificaat',
      value: 1,
    },
    {
      id: 1,
      label: 'Bewijs van deelname',
      value: 2,
    },
    {
      id: 2,
      label: 'Diploma',
      value: 3,
    },
    {
      id: 3,
      label: 'Getuigschrift',
      value: 4,
    },
  ].sort(sortFormOptionsAlphabetical);
};

export const methodOptions = (): FormDropDownOption[] => {
  return [
    {
      id: 0,
      label: 'Praktijkscholing',
      value: 0,
    },
    {
      id: 1,
      label: 'E-learning',
      value: 1,
    },
    {
      id: 2,
      label: 'Theorie',
      value: 2,
    },
  ].sort(sortFormOptionsAlphabetical);
};

export const newMethodOptions = (): FormDropDownOption[] => {
  return [
    {
      id: 0,
      label: 'Certificaat theorie',
      value: 7,
    },
    {
      id: 1,
      label: 'Certificaat praktijk',
      value: 8,
    },
    {
      id: 2,
      label: 'Bewijs van deelname theorie ',
      value: 9,
    },
    {
      id: 3,
      label: 'Bewijs van deelname praktijk ',
      value: 10,
    },
  ].sort(sortFormOptionsAlphabetical);
};
