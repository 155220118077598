/* eslint-disable no-use-before-define */
import { onBeforeUnmount } from 'vue';

type DownMoveCallback = (e:Event, pos:DOMPoint) => void;
type UpCallback = (e:Event) => void;

interface UseDownMoveUpConfig {
  down:DownMoveCallback;
  move:DownMoveCallback;
  up:UpCallback;
}

const getPosition = (e:any) => {
  const src = e.touches ? e.touches[0] : e;
  return new DOMPoint(src.pageX, src.pageY);
};

export const useDownMoveUp = (config:UseDownMoveUpConfig, el?: HTMLElement) => {
  const element = el ?? window;

  const downEvents = ['mousedown', 'touchstart'];
  const moveEvents = ['mousemove', 'touchmove'];
  const upEvents = ['mouseup', 'touchend'];

  const onDown = (e:any) => {
    config.down(e, getPosition(e));
    moveEvents.forEach((type) => { element.addEventListener(type, onMove, true); });
    upEvents.forEach((type) => { element.addEventListener(type, onUp, true); });
  };

  const onMove = (e:any) => {
    config.move(e, getPosition(e));
  };

  const onUp = (e:any) => {
    config.up(e);
    moveEvents.forEach((type) => { element.removeEventListener(type, onMove, true); });
    upEvents.forEach((type) => { element.removeEventListener(type, onUp, true); });
  };

  downEvents.forEach((type) => { element.addEventListener(type, onDown, true); });

  onBeforeUnmount(() => {
    downEvents.forEach((type) => { element.removeEventListener(type, onDown, true); });
  });
};
