<template >
  <div class="form-section">
    <slot />
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss">

.form-section {
  display: grid;
  grid-template-columns: 1fr;

  label, a {
    display: block;
    font-size: 12px;
    }

  .grouped-label {
    display: flex;
    justify-content: space-between;

    a {
      cursor: pointer;
      color: var(--pidz-white);
    }
  }

  input {
    margin-top: 4px;
    font: inherit;
    border: 1px solid var(--pidz-white);
    background-color: transparent;
    border-radius: 4px;
    padding: 12px 12px;
    color: var(--pidz-white);
    width: 100%;
    transition: all 100ms ease-in-out;

    &:focus {
      border-left: 4px solid var(--pidz-white);
      border-radius: 0px 4px 4px 0;
      outline: none;
    }

    &::placeholder {
      color: var(--pidz-white);
      opacity: 1;
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }
  }

  button {
    width: 100%;
    font: inherit;
    line-height: 1;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: var(--pidz-white);
    color: var(--pidz-blue);
  }
}

</style>
