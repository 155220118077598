<template>
  <layout-desktop-wrapper>
    <reload-page :reload="reload"
                 :loading="loading"/>
    <p-banner v-if="!isCorrectTimezone()"
              type="warning"
              title="Bewerken niet mogelijk door andere tijdzone"
              :text="incorrectTimezoneText"
              class="w-full"/>
  
    <div class="flex flex-col w-full bg-blue md:bg-white text-white md:text-neutral
                px-4 pb-2 md:py-4 md:border-b border-neutral-500">
      <h1 class="font-bold text-base break-words">{{ sheet.organisation_name }}</h1>
      <h2 class="text-sm break-words">{{ sheet.department_name }}</h2>
    </div>    
    
    <div class="micro-sheet-row-edit-page">
      <div v-if="row" class="blocks">
        <micro-sheet-working-hours v-model="row"
                                   :incidental-shift-times="incidentalShiftTimes"
                                   :other-work-hour-blocks="otherWorkHours"
                                   :validate="validate"/>
        <micro-sheet-pick-assignment v-if="shouldPickAssignment"
                                     v-model="row.assignmentUuid"
                                     :options="assignmentOptions"/>
        <sheet-block-break v-model="legacyBreaks"
                           :working-hours-block="legacyWorkHours"
                           :shift-id="0"/>
        <micro-sheet-work-during-sleep v-if="allowSleep"
                                       v-model="row.timeWorkedDuringSleep"/>
        <sheet-block-commute v-if="sheetSummary?.allow_travel_costs"
                             v-model="row.commutes"
                             :context="{} as any"/>
        <section v-if="row.uuid && isRowPeriodical" class="remove">
          <a @click="removeSheetRow"> Item verwijderen </a>
        </section>
      </div>
    </div>
    <form-buttons-row :on-cancel="backToSheet"
                      :on-submit="save"
                      :is-submit-disabled="!isCorrectTimezone()"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */

import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { validateOvertimeRemark } from '@app/pages/sheets/utils/row-validation';
import router from '@app/router';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useSheetStore, useToaster } from '@pidz/stores';
import { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import MicroSheetPickAssignment from './components/MicroSheetPickAssignment.vue';
import MicroSheetWorkDuringSleep from './components/MicroSheetWorkDuringSleep.vue';
import MicroSheetWorkingHours from './components/MicroSheetWorkingHours.vue';
import SheetBlockBreak from './components/SheetBlockBreak.vue';
import SheetBlockCommute from './components/SheetBlockCommute.vue';
import { MicroSheetRow, SheetSummary } from './types';
import {
  microRangeToLegacyBlockTimes,
  legacyBlockTimesToMicroRange,
} from './utils/micro-range-to-legacy-block-times';

const initialLoad = ref(true);
const loading = ref(false);

const sheetStore = useSheetStore();
const row = ref<MicroSheetRow | null>(null);
const sheetSummary = ref<SheetSummary | null>(null);

const sheetId = computed(() => Number(route.params.id));
const sheet = computed(() => sheetStore.sheets[sheetStore.sheets.findIndex(
  element => element.sheet_id === sheetId.value,
)]);

const assignments = computed(() => sheetSummary.value?.assignments ?? []);
const assignmentOptions = computed(() =>
  assignments.value.map((a) => ({ value: a.uuid, name: a.function })),
);
const periodicalAssignments = computed(() =>
  assignments.value.filter((a) => a.is_periodical),
);

const rowAssignment = computed(() =>
  assignments.value.find((a) => a.uuid === row.value!.assignmentUuid),
);

const isRowPeriodical = computed(() => {
  const uuid = row.value?.assignmentUuid;
  return assignments.value.find((a) => a.uuid === uuid)?.is_periodical ?? true;
});

const incidentalShiftTimes = computed(() => {
  const { start, end, is_periodical } = rowAssignment.value ?? {};
  if (!is_periodical && start && end) {
    return { start, end };
  }
  return undefined;
});

const legacyBreaks = computed({
  get: () =>
    row.value!.breaks.map((b, i) => ({
      ...microRangeToLegacyBlockTimes(b.startTime, b.duration),
      temp_id: i + 1,
    })),
  set: (to) => {
    row.value!.breaks = to.map((b) => ({
      ...legacyBlockTimesToMicroRange(b.start, b.end),
    }));
  },
});

const legacyWorkHours = computed(() => {
  const { startTime, duration } = row.value!;
  if (startTime !== '') {
    return microRangeToLegacyBlockTimes(startTime, duration);
  }
  return { start: null, end: null };
});

const otherWorkHours = ref([]);
const shouldPickAssignment = computed(
  () => periodicalAssignments.value.length > 1 && isRowPeriodical.value,
);

const route = useRoute();
const allowSleep = computed(() => rowAssignment.value?.is_sleep);

const isCorrectTimezone = () =>
  DateTime.now().setZone('local').zoneName === DateTime.now().zoneName;
// eslint-disable-next-line max-len, vue/max-len
const incorrectTimezoneText =
  'Je kunt niet bewerken omdat je tijdzone niet op de Nederlandse tijd staat. Stel je tijdzone tijdelijk in naar de Nederlandse tijd of bewerk deze urenregistratie als je weer in Nederland bent.';

const backToSheet = () => router.back();

const reload = async () => {
  loading.value = true;
  const { id, rowUuid } = route.params as Record<string, any>;
  sheetSummary.value = await sheetStore.getSheetSummary(id);

  if (!sheetSummary.value) {
    return;
  }

  const { sheetUuid } = sheetSummary.value;
  if (rowUuid === undefined) {
    const periodicals = periodicalAssignments.value;
    row.value = {
      sheetUuid: sheetSummary.value?.sheetUuid,
      assignmentUuid:
        periodicals.length === 1 ? periodicals[0].uuid : undefined,
      startOffset: 0,
      endOffset: 0,
      timeWorkedDuringSleep: 0,
      breaks: [],
      commutes: [],
      startTime: '',
      duration: 0,
    };
  } else {
    row.value = await sheetStore.getRow(sheetUuid, rowUuid);
  }
  loading.value = false;
  initialLoad.value = false;
};

const validate = (rowValue: MicroSheetRow) => {
  validateOvertimeRemark(rowValue);
};

const save = async () => {
  if (!row.value) {
    return;
  }
  if (!row.value.assignmentUuid) {
    return;
  }
  try {
    validate(row.value);
  } catch (e: Error) {
    await useToaster().addToaster({
      type: 'error',
      title: 'Oeps',
      message: e.message,
    });
    return;
  }
  if (row.value.uuid === undefined) {
    await sheetStore.createRow(row.value);
  } else {
    await sheetStore.updateRow(row.value);
  }
  logCustomEvent('sheet_row_save');
  backToSheet();
};

const removeSheetRow = async () => {
  await sheetStore.removeRow(row.value!);
  backToSheet();
};

onMounted(() => {
  reload();
});
</script>
<style lang="scss">
.micro-sheet-row-edit-page {
  width: 100%;
  .blocks {
    box-shadow: var(--shadow-light);
    & > section {
      background: var(--pidz-white);
      padding: 24px 16px;
      border-bottom: 1px solid gray;
      &:last-child {
        border: 0;
      }
      & > h2 {
        margin: 0;
        display: block;
        font-size: 16px;
        color: var(--pidz-black);
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
  }
  .remove a {
    color: var(--pidz-red);
    text-decoration: underline;
  }
}
</style>
