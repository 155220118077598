import { updateLoginState } from '@app/user-state';
import { defineStore } from 'pinia';

interface InterfaceState {
  initialized: boolean,
  asyncButtonsDisabled: boolean,
  networkStatus: boolean
}

export default defineStore('interface', {
  state: (): InterfaceState => ({
    initialized: false,
    asyncButtonsDisabled: false,
    networkStatus: true,
  }),
  actions: {
    async setNetworkStatus(status: boolean, reAuthUser = false) {
      try {
        if ((status && !this.networkStatus) && reAuthUser) {
          await updateLoginState();
        }
      } finally {
        this.networkStatus = status;
      }
    },
    setInitialized() {
      this.initialized = true;
    },
  },
});
