<template>
  <layout-desktop-wrapper>
    <language-form ref="form" :form-data="formDataLanguage"/>    
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled"/>
  </layout-desktop-wrapper>  
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { LanguageForm } from '@pidz/shared/components'
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/language';
import { ref } from 'vue';

const form = ref<typeof LanguageForm>();

const values: NewLanguagesForm = {
  language_uuid: undefined,
  skill_level: undefined
}

const formDataLanguage: PidzFormData<NewLanguagesForm> = {
  globalValidations,
  values,
  rows
}

</script>
<style lang="scss" scoped>
.language{
  width: 100%;
}
</style>
  