<template>
  <modal>
    <h2 class="font-bold">Weet je het zeker?</h2>
    <p>
      Door deze opdracht te accepteren, bevestig je dat je beschikbaar bent.
    </p>
    <template #footer>
      <button class="button-outline" @click="modalController.close">Annuleer</button>
      <button @click="accept">Accepteer opdracht</button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal from '@app/components/Modal.vue';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();

const props = defineProps<{
  accept: Function;
}>();

const accept = () => {
  modalController.close();
  props.accept();
};
</script>
