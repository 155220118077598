<template>
  <modal class="shift-diff-modal" prevent-close>
    <h2 class="font-bold">Gewijzigde opdracht</h2>
    <p class="mb-4">
      <strong>{{ shiftWorker.organisation_name }}</strong> heeft de details van
      jouw opdracht aangepast en heeft hierover met jou contact opgenomen. Zijn
      de wijzigingen zoals afgesproken? Keur de opdracht dan goed om de
      wijzigingen definitief te maken.
    </p>
    <div class="details-wrapper">
      <shift-diff-details :department-name="shiftWorker?.department_name"
                          :organisation-name="shiftWorker?.organisation_name"
                          :shift-start="shiftWorker?.shift_start"
                          :shift-end="shiftWorker?.shift_end"/>
      <shift-diff-card title="Oorspronkelijke opdracht"
                       :changes="changeSetsByStatus?.old"/>
      <shift-diff-card class="changed"
                       title="Gewijzigde opdracht"
                       :changes="changeSetsByStatus?.new"/>
    </div>
    <template #footer>
      <async-button class="button-outline" @click="declineShift">Afwijzen</async-button>
      <async-button @click="acceptShift">Accepteren</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { NotificationItemType } from '@pidz/enums';
import { useNotificationInboxStore, useShiftsStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed } from 'vue';
import {
  ShiftWorker,
  ChangeSetOptions,
  ChangeSets,
  ChangeSetValues,
} from '../types';
import ShiftDiffCard from './ShiftDiffCard.vue';
import ShiftDiffDetails from './ShiftDiffDetails.vue';

const shiftsStore = useShiftsStore();
const modalController = useModalController();

const props = defineProps<{
  shiftWorker: ShiftWorker;
  shiftDiff: {
    shift_id: number;
    change_set: ChangeSets;
  };
  onAcceptHandler: Function;
  onDeclineHandler: Function;
}>();

const groupChangesetByStatus = (
  prev: {
    new: { [key: string]: ChangeSetValues };
    old: { [key: string]: ChangeSetValues };
  },
  changeSet: [string, { old: ChangeSetValues; new: ChangeSetValues }],
) => {
  const current = { ...prev };
  current.old = { ...prev.old, [changeSet[0]]: changeSet[1].old ?? null };
  current.new = { ...prev.new, [changeSet[0]]: changeSet[1].new ?? null };
  return current;
};

const removeIdProperty = (option: ChangeSetOptions) => {
  const { id, ...rest } = option;
  return rest;
};

const changeSetsByStatus = computed(() => {
  const { option, ...rest } = props.shiftDiff.change_set;
  // For some reason the API sometimes returns an ID, which is not something that should be displayed,
  // thus I have to filter the options and remove the ID from the object.
  const changes = Object.entries(rest).reduce(groupChangesetByStatus, {
    old: {},
    new: {},
  });
  return {
    old: { ...changes.old, ...(option?.old && removeIdProperty(option.old)) },
    new: { ...changes.new, ...(option?.new && removeIdProperty(option.new)) },
  };
});

const dismissInboxItems = (newShiftWorker: ShiftWorker) => {  
  const inboxStore = useNotificationInboxStore();

  // remove actions for old shift
  const relevantActions = inboxStore.actions?.data.filter((msg: NotificationItem) => 
    msg.parameters?.shift === newShiftWorker.shift_id);

    if (relevantActions) {
    relevantActions.forEach((msg: NotificationItem) => {
      inboxStore.dismissNotification(
        msg.read_dismiss_parameters, NotificationItemType.ACTIONS);
    });
  }

  // remove notifications for old shift
  const relevantNotifications = inboxStore.notifications?.data.filter((msg: NotificationItem) => 
    msg.parameters?.shift === props.shiftDiff.shift_id);

  if (relevantNotifications) {
    relevantNotifications.forEach((msg: NotificationItem) => {
      inboxStore.dismissNotification(
        msg.read_dismiss_parameters, NotificationItemType.NOTIFICATIONS);
    });
  }
}


const acceptShift = async () => {
  try {
    const newShiftWorker = await shiftsStore.acceptShiftChanges(props.shiftWorker.id);

    // when there are actions that are related to this shift, mark them as read
     if (newShiftWorker) dismissInboxItems(newShiftWorker);
    modalController.close();
    await props.onAcceptHandler();

  } catch (e: any) {
    // handled by global error handler
  }
};

const declineShift = async () => {
  try {
    const newShiftWorker = await shiftsStore.rejectShiftChanges(props.shiftWorker.id,);

    // when there are actions that are related to this shift, mark them as read
    if (newShiftWorker) dismissInboxItems(newShiftWorker);
    
    modalController.close();
    await props.onDeclineHandler();
  } catch (e: any) {
    // handled by global error handler
  }
};
</script>
<style lang="scss">
.shift-diff-modal {
  display: flex;
  flex-direction: column;

  .modal-body {
    flex: 1 1 auto;
    overflow-y: scroll;
  }

  p,
  span {
    color: var(--pidz-body-text);
  }

  .shift-diff-details {
    margin-bottom: 24px;
  }

  .shift-diff-card {
    margin: 12px 0 0 0;
  }
}
</style>
