<template>
  <p-content-section :key="sortedTrainings?.length"
                     is-expandable
                     :has-add-button="true"
                     @add="emit('add-training')">
    <template #header>
      <div class="trainings-header">
        <p-badge v-if="isBadgeVisible"
                 class="trainings-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading icon="certificate" class="font-bold">Aanvullende scholing</p-heading>
      </div>
    </template>
    <template v-if="sortedTrainings && sortedTrainings.length" #content>
      <div class="training">
        <p-time-line class="training__timeline">
          <template #timeline-items>
            <li v-for="training in sortedTrainings" :key="training.id">
              <trainings-time-line-item :data="training"
                                        @edit="(e: Event) => edit(e, training.id)"/>
            </li>
          </template>
        </p-time-line>
      </div>
    </template>
    <template #empty>
      <p>
        <strong>Je hebt nog geen scholingen toegevoegd</strong><br />
        Klik hierboven op het plusje om je scholing toe te voegen aan je CV
      </p>
    </template>
  </p-content-section>
</template>

<script lang="ts" setup>
import { OTHER_SUBJECT_ID } from '@pidz/shared/forms/cv/training';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import TrainingsTimeLineItem from './TrainingsTimeLineItem.vue';

const props = defineProps<{
  trainings: Training[] | undefined;
  isBadgeVisible: boolean;
}>();

const emit = defineEmits(['add-training', 'edit-training']);

const edit = (e: Event, id: number) => {
  e.stopPropagation();
  emit('edit-training', id);
};

const sortedTrainings = computed(() => {
  if (!props.trainings) return;

  const timelineEducationItems = props.trainings.map((item) =>
    convertToEducationTimeLine(item),
  );

  return timelineEducationItems
    .sort((a, b) => {
      return a.startDate.toMillis() - b.startDate.toMillis();
    })
    .reverse();
});

const convertToEducationTimeLine = (
  item: Training,
): TimelineItem & TimelineItemEducation => {
  return {
    type: 'education',
    title: `${item?.subject?.id === OTHER_SUBJECT_ID ? 'Overige - ' + item.other_subject : item.subject?.name} - ${trainingMethods.get(item.method)}`,
    subtitle: `${item.institution_name} ${item.institution_place ? '- ' + item.institution_place : ''}`,
    status: item.status || 0,
    startDate: DateTime.fromISO(item.acquired_at),
    endDate: item.expires_at ? DateTime.fromISO(item.expires_at) : undefined,
    fileText: `${item.assets.length === 1 ? 'Diploma bestand' : 'Diploma bestanden'}`,
    assets: item.assets,
    id: item.id,
    nearing_expiration_date: item.nearing_expiration_date,
  }
}

// TODO: check if back-end will migrate all old types, else, do add these
const trainingProofTypes = new Map([
  [0, ''],
  [1, 'Certificaat'],
  [2, 'Bewijs van deelname'],
  [2, 'Diploma'],
  [2, 'Getuigschrift'],
]);

const trainingMethods = new Map([
  [0, 'Praktijkscholing'], // old
  [1, 'E-learning'], // old
  [2, 'Theorie'], // old
  [7, 'Certificaat theorie'],
  [8, 'Certificaat praktijk'],
  [9, 'Bewijs van deelname theorie'],
  [10, 'Bewijs van deelname praktijk'],
]);
</script>

<style lang="scss" scoped>
.trainings-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}

.training {
  &__timeline {
    margin-left: 0.6rem;
    @media screen and (min-width: 768px) {
      margin-left: -2.4rem;
    }
  }
}
</style>
./form/const
