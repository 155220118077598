<template>
  <layout-desktop-wrapper>
    <contact-details-form ref="form" :form-data="formDataContact"
                          is-edit/>
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { ContactDetailsForm } from '@pidz/shared/components'
import {
  globalValidations,
  rows,
} from '@pidz/shared/forms/profile/contact';
import { useUserStore } from '@pidz/stores';
import { ref, computed } from 'vue';

const form = ref<typeof ContactDetailsForm>();
const userStore = useUserStore();

const formDataContact: PidzFormData<ProfileContact> = {
  globalValidations,
  values: userStore.getContactDetails(),
  rows,
};

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)

const goBack = () => form.value?.onCancel()
defineExpose({ goBack })
</script>
