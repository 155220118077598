<template>
  <layout-desktop-wrapper>
    <registration-form ref="form" 
                       :form-data="formDataRegistration"/>
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled || form?.showFileError"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { RegistrationForm } from '@pidz/shared/components'
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/registration';
import { DateTime } from 'luxon'
import { ref } from 'vue';

const form = ref<typeof RegistrationForm>();

const values: NewRegistrationForm = {
    type: undefined,
    registration_number: undefined,
    acquired_at: formatYmd(DateTime.now()),
    assets: [],
}

const formDataRegistration: PidzFormData<NewRegistrationForm> = {
  globalValidations,
  values,
  rows
}
</script>
