<template>
  <div class="radio-group">
    <radio-group-root :id="inputId"
                      v-model="model"
                      :name
                      :disabled
                      class="flex flex-col gap-2"
                      aria-label="View density">
      <div  v-for="(item, index) in options" :key="item.value"
            class="flex items-center">
        <radio-group-item :id="'r' + index"
                          :value="item.value"
                          class="bg-white border w-[26px] h-[26px] border-neutral-400 rounded-full cursor-default">
          <radio-group-indicator class="flex items-center justify-center">
            <p-icon class="h-6 w-6 relative" icon-name="check"
                    boxed background="blue"
                    size="small"
                    color="blue"/>
          </radio-group-indicator>
        </radio-group-item>
        <label class="text-black leading-none pl-3" :for="'r' + index">
          {{ item.label }}
        </label>
      </div>
    </radio-group-root>
  </div>

</template>

<script setup lang="ts">
import { RadioGroupIndicator, RadioGroupItem, RadioGroupRoot } from 'radix-vue'

withDefaults(defineProps<{
  name: string
  inputId: string
  inputType: string
  disabled?: boolean
  options: { label: string, value: string }[]
  data?: {
    defaultValue?: string,
    dir?: 'rtl' | 'ltr',
    loop?: boolean,
    required?: boolean,
    orientation?: 'horizontal' | 'vertical',
  }
}>(), {
  disabled: false,
  data: undefined
})

const model = defineModel<string>({required: true})

</script>

