<template>
  <div class="pidz-pagination">
    <p-icon v-if="!isPreviousDisabled" icon-name="chevron"
            class="icon previous"
            data-testid="previous"
            @click="!isPreviousDisabled && emit('previous')"/>
    <p class="today"
       data-testid="today"
       @click="emit('today')">Vandaag</p>
    <p-icon v-if="!isNextDisabled" icon-name="chevron"
            class="icon next"
            data-testid="next"
            @click="!isNextDisabled && emit('next')"/>
  </div>
</template>
<script setup lang="ts">

defineProps<{
  isPreviousDisabled?: boolean
  isNextDisabled?: boolean
}>();

const emit = defineEmits(['previous', 'today', 'next']);

</script>
<style lang="scss" scoped>
    .pidz-pagination {
        display: flex;
        gap: 32px;

        .today {
          display: flex;
          align-self: center;
          margin: 0;
          color: var(--pidz-body-text);
          font-size: 16px;
        }
        .icon, .today {
          cursor: pointer;
        }
        .previous {
            transform: rotate(90deg);
        }
        .next {
            transform: rotate(-90deg)
        }
    }

</style>
