import { useApi } from '@pidz/api';

const api = useApi();

export const userService = {
  getProfilePicture: (): Promise<ProfilePicture> => api.jsonrpc({
    url: 'user:getProfilePicture'
  }),
    
  getCroppedProfilePicture: (): Promise<ProfilePicture> => api.jsonrpc({
    url: 'user:getCroppedProfilePicture'
  }),

  getCurrentUser: (): Promise<User> => api.jsonrpc({
    url: 'user:getCurrentUser'
  }),

  getMaxWorkRange: (): Promise<number> => api.jsonrpc({
    url: 'user:getMaxWorkRange'
  }),

  updateMaxWorkRange: (maxWorkRange: number): Promise<boolean> => api.jsonrpc({
    url: 'user:updateMaxWorkRange',
    params: { maxWorkRange }
  }),

  getDepartmentCountWithinRange: (maxTravelTime: number): Promise<number> => api.jsonrpc({
    url: 'user:countDepartmentsWithinRange',
    params: { maxTravelTime }
  }),

  getOrganisationCountWithinRange: (maxTravelTime: number): Promise<number> => api.jsonrpc({
    url: 'user:countOrganisationsWithinRange',
    params: { maxTravelTime }
  }),
  
  resetPassword: (data: {email: string, redirectUri: string}): Promise<void> => api.rest({
    method: 'post', 
    url: '/auth/reset-password-request', 
    params: data,
    isMicro: true
  }),
}
