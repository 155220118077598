import { InvoiceGetInvoicesParams, invoiceService } from '@pidz/api';
import { InvoiceType } from '@pidz/enums';
import { invoiceStatusInfo } from '@pidz/utils'
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { formatAllOverviewItems } from '../utils/overview-conversion';

interface GetInvoicesParams {
  params: InvoiceGetInvoicesParams,
  options: {}
}

type CombinedInvoice = Invoice | SoftwareInvoice;

const invoiceStatusComputed = (invoice: CombinedInvoice, type?: InvoiceType) : 
{className: 'info' | 'success' | 'error' | 'warning' | 'Onbekende status', text: string} => {
  
  if ('payable' in invoice) {
    if (type === InvoiceType.SOFTWARE_INVOICE && invoice.status === 1 && invoice.payable) {
      return { className: 'error', text: 'Factuur te betalen' };
    }
  }

  const invoiceInfo = invoiceStatusInfo.get(invoice.status);

  if (!invoiceInfo) return { text: 'info', className: 'Onbekende status' };

  return invoiceInfo;
};

const invoiceSubTitle = (invoice: CombinedInvoice) => {
  if ('department_name' in invoice) {
    return invoice.department_name;
  }
  return undefined;
};

const invoiceId = (invoice: CombinedInvoice): number => {
  if ('sheet_id' in invoice) {
    return invoice.sheet_id;
  }
  return invoice.invoice_id;
};

const invoiceTitle = (invoice: CombinedInvoice): string => {
  if ('organisation_name' in invoice) {
    return invoice.organisation_name;
  }
  return invoice.creditor_organisation_name;
};

const formatInvoiceEarnings = (earnings: number | undefined) => {
  if (!earnings) {
    return undefined;
  }
  return `€ ${earnings.toFixed(2)}`;
};

const transformInvoiceToInvoiceOverviewItem = (invoice: CombinedInvoice, route, type): newOverviewItem => ({
    firstOfType: false,
    lastOfType: false,
    id: invoice.invoice_id,
    to: { name: `${route.meta.type} invoice`, params: { id: invoiceId(invoice) }},
    groupTitle: DateTime.fromISO(invoice.month).setLocale('nl-NL').toFormat('LLLL, yyyy'),
    status: invoiceStatusComputed(invoice, type).text,
    title: invoiceTitle(invoice),
    subtitle: invoiceSubTitle(invoice) || '',
    class: invoiceStatusComputed(invoice, type).className,
    description: (invoice) ? `${invoice.invoice_number}` : '',
    trailing: formatInvoiceEarnings(invoice.total_amount),
}); 

export default defineStore('invoices', () => {
  const invoices = ref<Record<InvoiceType, Invoice[]>>({
    [InvoiceType.FREELANCER_INVOICE]: [],
    [InvoiceType.SOFTWARE_INVOICE]: [],
  });
  const invoicesOverviewItems = ref({});
  const invoicesScrollPosition = ref({});
  const invoicesSearchString = ref('');

  const clearInvoices = () => {
    invoices.value = {
      [InvoiceType.FREELANCER_INVOICE]: [],
      [InvoiceType.SOFTWARE_INVOICE]: [],
    };
    invoicesOverviewItems.value = {};
    invoicesScrollPosition.value = {};
    invoicesSearchString.value = '';
  }

  const getInvoices = async (type: InvoiceType, { params } : GetInvoicesParams, route) => {
    const result = type === InvoiceType.FREELANCER_INVOICE
      ? await invoiceService.getInvoices(params)
      : await invoiceService.getSoftwareInvoices(params);

    const newInvoices = result.data;
    const previousInvoices = invoices.value[type];

    if (newInvoices.length !== 0) {
      if (!invoicesOverviewItems.value[type]) invoicesOverviewItems.value[type] = [];

      newInvoices.forEach((invoice) => {        

        invoicesOverviewItems.value[type] = [...invoicesOverviewItems.value[type], 
        transformInvoiceToInvoiceOverviewItem(invoice, route, type)]
      });

      invoicesOverviewItems.value[type] = formatAllOverviewItems(invoicesOverviewItems.value[type]);

    }

    if (!params.offset || !previousInvoices?.length) {
      invoices.value[type] = [...newInvoices] as Invoice[];
    } else {
      invoices.value[type] = [...previousInvoices, ...newInvoices] as Invoice[];
    }

    return invoices.value[type];
  }

  const $reset = () => {
    clearInvoices();
  }
  return {
    invoices,
    invoicesOverviewItems,
    invoicesScrollPosition,
    invoicesSearchString,
    clearInvoices,
    getInvoices,
    $reset
  }
})
