import { useApi } from '@pidz/api';

const api = useApi()

export const feedbackService = {
  getPeriodicalReview: (): Promise<Feedback | boolean> => api.jsonrpc({
    url: 'feedback:getPeriodicalReview',
  }),

  updateReview: (feedbackModel: Feedback): Promise<{}> => api.jsonrpc({
    url: 'feedback:updateReview',
    params: { feedbackModel }
  }),

  addReview: (feedbackModel: Feedback): Promise<Feedback> => api.jsonrpc({
    url: 'feedback:addReview',
    params: { feedbackModel }
  }),

  addAsset: (asset: FeedbackAddAssetParams): Promise<boolean> => api.jsonrpc({
    url: 'feedback:addAsset',
    params: { asset }
  }),
}
