<template>
  <p-content-section :key="sortedEducations?.length"
                     is-expandable
                     :has-add-button="true"
                     @add="emit('add-education')">
    <template #header>
      <div class="educations-header">
        <p-badge v-if="isBadgeVisible"
                 class="educations-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading icon="jobs" class="font-bold">Opleiding</p-heading>
      </div>
    </template>
    <template v-if="sortedEducations && sortedEducations.length" #content>
      <div class="education">
        <p-time-line class="education__timeline">
          <template #timeline-items>
            <li v-for="(education, index) in sortedEducations" :key="index">
              <education-time-line-item :data="education"
                                        @edit="(e: Event) => edit(e, education.id)"/>
            </li>
          </template>
        </p-time-line>
      </div>
    </template>

    <template #empty>
      <p>
        <strong>Verplicht: Minimaal 1 goedgekeurde zorgopleiding</strong><br />
        Klik hierboven op het plusje om je diploma toe te voegen aan je CV
      </p>
    </template>
  </p-content-section>
</template>

<script lang="ts" setup>
import { OTHER_EDUCATION_ID } from '@pidz/shared/forms/cv/education';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import EducationTimeLineItem from './EducationTimeLineItem.vue';

const props = defineProps<{
  educations: Education[] | undefined;
  isBadgeVisible: boolean;
}>();

const emit = defineEmits(['add-education', 'edit-education']);

const edit = (e: Event, id: number) => {
  e.stopPropagation();
  emit('edit-education', id);
};

const sortedEducations = computed(() => {
  if (!props.educations) return;

  const timelineEducationItems = props.educations.map((item) =>
    convertToEducationTimeLine(item),
  );

  return timelineEducationItems
    .sort((a, b) => {
      return a.startDate.toMillis() - b.startDate.toMillis();
    })
    .reverse();
});

const convertToEducationTimeLine = (
  item: Education,
): TimelineItem & TimelineItemEducation => {
  return {
    id: item.id,
    type: 'education',
    title: getEducationTitle(item),
    subtitle: `${item.institution_name}${item.institution_place ? ' - ' + item.institution_place : ''}`,
    status: item.status || 0,
    startDate: DateTime.fromISO(item.start_date),
    endDate: DateTime.fromISO(item.end_date),
    fileText: `${item.assets.length === 1 ? 'Diploma bestand' : 'Diploma bestanden'}`,
    assets: item.assets,
  };
};

const getEducationTitle = (item: Education) => {
  const education =
    item.subject.id === OTHER_EDUCATION_ID
      ? 'Overige - ' + item.other_subject
      : item.subject.name;
  const specialization = item.specialization ? ' - ' + item.specialization : '';
  return `${education}${specialization}`;
};
</script>

<style lang="scss" scoped>
.educations-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}

.education {
  &__timeline {
    margin-left: 0.6rem;
    @media screen and (min-width: 768px) {
      margin-left: -2.4rem;
    }
  }
}
</style>
./form/const
