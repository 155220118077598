<template>
  <sheet-block-list-section v-model="value"
                            title="Minuren"
                            add-title="Minuren toevoegen"
                            edit-title="Minuren bewerken"
                            :block-factory="blockFactory"
                            :validate="validate"/>
</template>
<script lang="ts" setup>
import { addHourToIsoDate } from '@pidz/date';
import { SheetBlockType } from '@pidz/enums';
import { computed } from 'vue';
import {
  validateStartBeforeEnd,
  validateInsideWorkHours,
  validateNoOverlap,
} from '../utils/block-validations';
import SheetBlockListSection from './SheetBlockListSection.vue';

const props = defineProps<{
  modelValue: any[];
  workingHoursBlock: any;
  shiftId: number;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

const validate = (block: any, otherBlocks: any[]) => {
  validateStartBeforeEnd(block);
  validateInsideWorkHours(
    block,
    props.workingHoursBlock,
    'Minuren moeten binnen de werktijd vallen.',
  );
  validateNoOverlap(block, otherBlocks, 'Minuren mogen niet overlappen.');
};

const blockFactory = () => ({
  start: props.workingHoursBlock.start,
  end: addHourToIsoDate(props.workingHoursBlock.start),
  type: SheetBlockType.TYPE_UNDERTIME,
  shift_id: props.shiftId,
});
</script>
