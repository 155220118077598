import { 
  checkIfEducationItemExists, 
  checkIfLanguageItemExists, 
  checkIfRegistrationItemExists, 
  checkIfTrainingItemExists, 
  checkIfWorkExperienceItemExists, 
  preLoadResumeData 
} from './navigation-guards';

export default [
  {
    name: 'cv',
    path: '/cv',
    redirect: '/cv',
    children: [
      {
        name: 'cv overview',
        path: '/cv',
        component: () => import('@app/pages/cv/CVOverviewPage.vue'),
        meta: { title: 'CV' },
      },
      {
        name: 'cv work-experience add',
        path: '/cv/work-experience/add',
        component: () =>
          import('@app/pages/cv/sections/work/AddWorkExperiencePage.vue'),
        meta: { 
          title: 'Werkervaring toevoegen', 
          backButton: true,
        },       
      },
      {
        name: 'cv work-experience edit',
        path: '/cv/work-experience/edit/:id',
        component: () =>
          import('@app/pages/cv/sections/work/EditWorkExperiencePage.vue'),
        meta: { 
          title: 'Werkervaring wijzigen',
          backButton: true,  
        },
        beforeEnter: [preLoadResumeData, checkIfWorkExperienceItemExists],
      },
      {
        name: 'cv education add',
        path: '/cv/education/add',
        component: () =>
          import('@app/pages/cv/sections/education/AddEducationPage.vue'),
        meta: { 
          title: 'Opleiding toevoegen',
          backButton: true,
        },
      },
      {
        name: 'cv education edit',
        path: '/cv/education/edit/:id',
        component: () =>
          import('@app/pages/cv/sections/education/EditEducationPage.vue'),
        meta: { 
          title: 'Opleiding wijzigen', 
          backButton: true,
        },
        beforeEnter: [preLoadResumeData, checkIfEducationItemExists],
      },
      {
        name: 'cv training add',
        path: '/cv/training/add',
        component: () => import('@app/pages/cv/sections/training/AddTrainingPage.vue'),
        meta: { 
          title: 'Aanvullende scholing toevoegen', 
          backButton: true,
        },
      },
      {
        name: 'cv training edit',
        path: '/cv/training/edit/:id',
        component: () =>
          import('@app/pages/cv/sections/training/EditTrainingPage.vue'),
        meta: { 
          title: 'Aanvullende scholing bewerken', 
          backButton: true,
        },
        beforeEnter: [preLoadResumeData, checkIfTrainingItemExists],
      },
    
      {
        name: 'cv languages add',
        path: '/cv/languages/add',
        component: () =>
          import('@app/pages/cv/sections/languages/AddLanguagePage.vue'),
        meta: { 
          title: 'Taalvaardigheid toevoegen', 
          backButton: true,
        },
      },
      {
        name: 'cv languages edit',
        path: '/cv/languages/edit/:id',
        component: () =>
          import('@app/pages/cv/sections/languages/EditLanguagePage.vue'),
        meta: { 
          title: 'Taalvaardigheid wijzigen', 
          backButton: true,
        },
        beforeEnter: [preLoadResumeData, checkIfLanguageItemExists],
      },
      {
        name: 'cv registration add',
        path: '/cv/registration/add',
        component: () =>
          import('@app/pages/cv/sections/registrations/AddRegistrationPage.vue'),
        meta: { 
          title: 'Registratie toevoegen', 
          backButton: true,
        },
        beforeEnter: [preLoadResumeData],
      },
      {
        name: 'cv registration edit',
        path: '/cv/registration/edit/:id',
        component: () =>
          import('@app/pages/cv/sections/registrations/EditRegistrationPage.vue'),
        meta: { 
          title: 'Registratie wijzigen', 
          backButton: true,
        },
        beforeEnter: [preLoadResumeData, checkIfRegistrationItemExists],
      },
      {
        name: 'cv competencies edit',
        path: '/cv/competencies/edit/:id',
        component: () =>
          import('@app/pages/cv/sections/competencies/EditCompetenciesPage.vue'),
        meta: { 
          title: 'Handelingen bewerken',
          backButton: true,
        },
      },
    ]
  }
]