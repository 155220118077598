/* eslint-disable max-lines */
import { FormFieldType } from '@pidz/enums';
import { suggestedCountries, countries } from '@pidz/utils';
import { ref } from 'vue';
import * as zod from 'zod';

const countryList = (): FormDropDownOption[] =>
  [...suggestedCountries(), ...countries].map<FormDropDownOption>(
    ([key, value], index) => ({ id: index, value: key, label: value || '' }),
  );

export const streetField = ref<FormField>({
  active: true,
  name: 'street',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Straat mag niet leeg zijn.',
    })
    .max(255, { message: 'Straat mag maximaal 255 tekens bevatten.' }),
  width: 200,
  data: {
    id: 'street',
    label: 'Straat',
    inputType: 'text',
    placeholder: '',
  },
});

export const houseNumberField = ref<FormField>({
  active: true,
  name: 'houseNumber',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Huis nr. mag niet leeg zijn.',
    })
    .max(15, { message: 'Huis nr. mag maximaal 15 tekens bevatten.' }),
  width: 75,
  data: {
    id: 'houseNumber',
    label: 'Huis nr.',
    inputType: 'text',
    placeholder: '',
  },
});

export const zipCodeField = ref<FormField>({
  active: true,
  name: 'zipCode',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Postcode mag niet leeg zijn.',
    })
    .max(7, { message: 'Postcode mag maximaal 7 tekens bevatten.' }),
  width: 75,
  data: {
    id: 'zipCode',
    label: 'Postcode',
    inputType: 'text',
    placeholder: '',
  },
});

export const cityField = ref<FormField>({
  active: true,
  name: 'city',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Woonplaats mag niet leeg zijn.',
    })
    .max(255, { message: 'Woonplaats mag maximaal 255 tekens bevatten.' }),
  width: 200,
  data: {
    id: 'city',
    label: 'Woonplaats',
    inputType: 'text',
    placeholder: '',
  },
});

export const poBoxField = ref<FormField>({
  active: true,
  name: 'poBox',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(255, { message: 'Postbus mag maximaal 255 tekens bevatten.' })
    .optional(),
  width: 140,
  data: {
    id: 'poBox',
    label: 'Postbus (optioneel)',
    inputType: 'text',
    placeholder: '',
  },
});

export const countryField = ref<FormField>({
  active: true,
  name: 'country',
  formType: FormFieldType.DROPDOWN,
  width: 200,
  validation: zod.string({ required_error: 'Selecteer een land' }),
  data: {
    id: 'country',
    label: 'Land',
    options: countryList(),
    showDropdownPrepend: false,
  },
});

export const sameAddressField = ref<FormField>({
  active: true,
  name: 'isSameAddress',
  formType: FormFieldType.TOGGLE,
  width: 100,
  validation: zod.boolean(),
  data: {
    id: 'isSameAddress',
    label: 'Mijn bedrijfsadres is hetzelfde als mijn woonadres',
    inputType: 'checkbox',
  },
});

// businessAddress_ fields

export const businessAddress_StreetField = ref<FormField>({
  active: true,
  name: 'businessAddress_street',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Straat mag niet leeg zijn.',
    })
    .max(255, { message: 'Straat mag maximaal 255 tekens bevatten.' }),
  width: 200,
  data: {
    id: 'businessAddress_street',
    label: 'Straat',
    inputType: 'text',
    placeholder: '',
  },
});

export const businessAddress_HouseNumberField = ref<FormField>({
  active: true,
  name: 'businessAddress_houseNumber',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Huis nr. mag niet leeg zijn.',
    })
    .max(15, { message: 'Huis nr. mag maximaal 15 tekens bevatten.' }),
  width: 75,
  data: {
    id: 'businessAddress_houseNumber',
    label: 'Huis nr.',
    inputType: 'text',
    placeholder: '',
  },
});

export const businessAddress_ZipCodeField = ref<FormField>({
  active: true,
  name: 'businessAddress_zipCode',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Postcode mag niet leeg zijn.',
    })
    .max(7, { message: 'Postcode mag maximaal 7 tekens bevatten.' }),
  width: 75,
  data: {
    id: 'businessAddress_zipCode',
    label: 'Postcode',
    inputType: 'text',
    placeholder: '',
  },
});

export const businessAddress_CityField = ref<FormField>({
  active: true,
  name: 'businessAddress_city',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, {
      message: 'Woonplaats mag niet leeg zijn.',
    })
    .max(255, { message: 'Woonplaats mag maximaal 255 tekens bevatten.' }),
  width: 200,
  data: {
    id: 'businessAddress_city',
    label: 'Woonplaats',
    inputType: 'text',
    placeholder: '',
  },
});

export const businessAddress_PoBoxField = ref<FormField>({
  active: true,
  name: 'businessAddress_poBox',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(255, { message: 'Postbus mag maximaal 255 tekens bevatten.' })
    .optional(),
  width: 140,
  data: {
    id: 'businessAddress_poBox',
    label: 'Postbus (optioneel)',
    inputType: 'text',
    placeholder: '',
  },
});

export const businessAddress_CountryField = ref<FormField>({
  active: true,
  name: 'businessAddress_country',
  formType: FormFieldType.DROPDOWN,
  width: 200,
  validation: zod.string({ required_error: 'Selecteer een land' }),
  data: {
    id: 'businessAddress_country',
    label: 'Land',
    options: countryList(),
    showDropdownPrepend: false,
  },
});
