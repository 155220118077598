<template>
  <div :class="['form-field', className]">
    <p-label v-if="label && !isDropDown"
             :input-id="id"
             :label="label" />
    <div class="form-field__input">
      <p-input-prepend v-if="preIcon && !isDropDown" :disabled="disabled">
        <p-icon :icon-name="preIcon"
                color="white"
                :border-radius="false" />
      </p-input-prepend>
      <component :is="singleFormFieldMap.get(formType)"
                 v-model="value"
                 :input-id="id"
                 :input-type="inputType"
                 :label="label"
                 :icon="icon"
                 :options="options"
                 :name="name"
                 :min="min"
                 :max="max"
                 :rows="rows"
                 :disabled="disabled"
                 :placeholder="placeholder"
                 :show-dropdown-prepend="showDropdownPrepend"/>
    </div>
    <p v-if="errorMessage && !disableErrors" class="error">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script lang="ts" setup>
import { FormFieldType } from '@pidz/enums';
import { useField } from 'vee-validate';
import { computed } from 'vue';
import type { Component } from 'vue';
import { PCheckbox, PIcon, PInput, PInputPrepend, PLabel, PTextarea, PToggle } from '../../atoms';
import { PFormDropdown, PRadioGroup } from '../../molecules';

declare interface FormFieldProps {
    name: string
    id: string
    formType: FormType
    inputType?: InputTypeHTMLAttribute
    preIcon?: string
    label?: string
    className?: string
    min?: number | string | undefined
    max?: number | string | undefined
    disableErrors?: boolean
    options?: {}[]
    icon?: string
    placeholder?: string
    items?: DoubleFormFieldItems[]
    showDropdownPrepend?: boolean
    rows?: number
    disabled?: boolean
    nested?: boolean
}

const props = defineProps<FormFieldProps>();

const isDropDown = computed(() => (props.formType === FormFieldType.DROPDOWN));

const singleFormFieldMap = new Map<FormFieldType, Component>([
  [FormFieldType.DROPDOWN, PFormDropdown],
  [FormFieldType.CHECKBOX, PCheckbox],
  [FormFieldType.TOGGLE, PToggle],
  [FormFieldType.INPUT, PInput],
  [FormFieldType.TEXTAREA, PTextarea],
  [FormFieldType.RADIOGROUP, PRadioGroup]
]);

const { value, errorMessage } = useField(
  () => props.name,
  undefined,
  {
    syncVModel: true
  }
);

</script>
<style lang="scss">
.form-field{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  span{
    width: 100%;
  }

  &__text{
    display: block;
  }

  &__number{
    input {
      width: 65px;
      margin: 0 10px;
    }
  }
  &__input{
    display: flex;
    min-height: 40px;
  }

  &__icon{
    span{
      display: flex;
    }

    .input{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
}

.input{
  &__checkbox{
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    justify-content: flex-end;

    .error{
      flex-basis: 100%;
      bottom: 0;
    }

    input{
      position: absolute;
      z-index: 1;
      height: 20px;
      width: 20px;
      opacity: 0;
      border-radius: 12px;
      cursor: pointer;
    }
  }

  &--left{
    .input{
      border-left: 0px;
      border-radius: 0;
    }
  }
  &--right{
    .input{
      border-left: 0px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  &--left, &--right{
    display: flex;
    flex-grow: 1;
    min-width: 1%;
  }
}

.error {
  font-size: 13px;
  color: var(--pidz-red);
  margin-bottom: 0;
  margin-top: 0;
}
</style>
