<template>
  <div class="registration">
    <div class="registration__fields"
         :class="{ 'registration__fields--web': isWeb }">
      <p-form ref="registrationForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-form="isDisabled"
              :disable-buttons="true"/>
    </div>
    <div v-if="showFiles"
         class="registration__files"
         :class="[
           { 'registration__files--web': isWeb },
           { 'registration__files--disabled': isDisabled },
         ]">
      <p-expanded-picker :new-files="newFiles"
                         :existing-files="existingAssets"
                         :disabled="isDisabled"
                         :show-status="false"
                         @upload="onAddFile"
                         @delete="onDeleteFile"
                         @download="onDownloadFile"/>
      <p v-if="showFileError" class="file__error">
        Je moet voor deze registratie een registratie bewijs toevoegen
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { formatYmd } from '@pidz/date';
import { CvSectionStatus, RegistrationProofType } from '@pidz/enums';
import { useFormFunctions } from '@pidz/shared/forms';
import {
  REGISTRATION_ASSET_TYPE_ID,
  changeAquiredtAtFieldLabelLogic,
  registrationTypesOptions,
  toggleAquiredtAtFieldLogic,
  updateRegistrationTypeOptions,
  changeRegistrationNumberLabelLogic,
  changeRegistrationNumberValidationLogic,
} from '@pidz/shared/forms/cv/registration';
import { rerouteToOverview, fileRemove, fileUpload } from '@pidz/shared/utils';
import { useCVStore, useAssetStore } from '@pidz/stores';
import { DateTime } from 'luxon';
import { computed, onMounted, ref, watch } from 'vue';
import {
  useRouter,
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';

interface Props {
  registrationItem?: Registration;
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<RegistrationForm> | PidzFormData<NewRegistrationForm>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  disabled: false,
});

const registrationForm = ref<any>();

const router: Router = useRouter();
const cvStore = useCVStore();
const assetStore = useAssetStore();
const showFiles = ref<boolean>(true);

const showFileError = computed(() => {
  return (
    (newFiles.value.length === 0 &&
      existingAssets.value.length < 1 &&
      registrationForm?.value?.submitCount > 0 &&
      showFiles.value) ||
    (deletedFiles.value.length ===
      (props?.formData?.values?.assets || []).length &&
      showFiles.value &&
      !(newFiles.value.length > 0))
  );
});

const getRegistrationById = (id: number): RegistrationType =>
  cvStore.getRegistrationTypeById(id) as RegistrationType;

const itemId = props.registrationItem?.id ?? 0;

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  onDelete,
  newFiles,
  deletedFiles,
  existingAssets,
  onAddFile,
  onDownloadFile,
  onDeleteFile,
  warnBeforeCancel,
} = useFormFunctions(
  registrationForm,
  props.registrationItem?.assets,
  () => rerouteToOverview(router, 'cv'),
  async () => {
    props.registrationItem?.assets.forEach(
      async (asset: Asset) => await assetStore.removeAsset(asset.id!),
    );
    await cvStore.removeRegistration(itemId);
    succesToaster('Succesvol verwijderd');
  },
  props.isEdit,
  'registratie',
);

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'cv_modal_edit_cancel' : 'cv_modal_add_cancel';

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const onSubmit = async () => {
  const submit = registrationForm?.value?.handleSubmit(
    async (formValues: RegistrationForm) => {
      if (
        registrationForm?.value?.isValidating ||
        !registrationForm?.value?.meta.valid ||
        showFileError.value
      ) {
        return;
      }
      const files = await fileUpload(
        newFiles.value,
        REGISTRATION_ASSET_TYPE_ID,
      );
      if (deletedFiles.value.length > 0 && props.isEdit)
        fileRemove(deletedFiles.value);

      const { type, ...registration } = formValues;

      registration.acquired_at =
        registration.acquired_at ?? formatYmd(DateTime.now());

      let item: Omit<Registration, 'id'> = {
        ...registration,
        type: getRegistrationById(type),
        assets: props.isEdit ? [...existingAssets.value, ...files] : files,
        deny_reason: props.registrationItem?.deny_reason || null,
        checked: props.registrationItem?.checked || null,
        expires_at: props.registrationItem?.expires_at || null,
        expires: props.registrationItem?.expires || null,
        status: props.registrationItem?.status || CvSectionStatus.NEW,
      };

      let editItem: Registration = {
        ...item,
        id: itemId,
      };

      props.isEdit
        ? await cvStore.updateRegistration(editItem)
        : await cvStore.addRegistration(item);

      formSubmitted.value = true;
      rerouteToOverview(router, 'cv');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

watch(
  () => registrationForm?.value?.values?.acquired_at,
  (value: string) =>
    changeAquiredtAtFieldLabelLogic(
      getRegistrationById(registrationForm?.value?.values.type),
      value,
    ),
);

watch(
  () => registrationForm?.value?.values?.type,
  async (newValue: number, oldValue: number) => {
    showFiles.value = getRegistrationById(newValue).proof_type === RegistrationProofType.BOTH
    changeRegistrationNumberValidationLogic(newValue)
    changeRegistrationNumberLabelLogic(newValue)
    toggleAquiredtAtFieldLogic(
      registrationForm,
      cvStore.getRegistrationTypeById(newValue) as RegistrationType,
    )
    if(oldValue !== newValue && oldValue !== undefined) {
      await registrationForm.value.setFieldValue('registration_number', '')
      await registrationForm.value.setFieldError('registration_number', '')
    }
  }
);

onMounted(async () => {
  await cvStore.loadRegistrationTypes();

  // Freelancers are not allowed to add two of the same registrations, so existing registrations are filtered
  const existingRegistrations =
    cvStore?.resume?.registrations.map(
      (registration) => registration.type.id,
    ) || [];
  // Filter out existing option
  const filteredRegistrations = existingRegistrations.filter(
    (registration) => registration !== props?.registrationItem?.type?.id || 0,
  );
  const options = registrationTypesOptions(cvStore.registrationTypes || []);
  updateRegistrationTypeOptions(options, filteredRegistrations);
});

defineExpose({
  isSubmitDisabled,
  isSubmitting,
  onCancel,
  onSubmit,
  onDelete,
  onDownloadFile,
  showFileError,
});
</script>
<style scoped lang="scss">
.registration {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
  &__files {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-top: 0;

    &--web {
      padding: 32px;
      padding-top: 0;
      box-shadow: none;
    }
    &--disabled {
      padding-bottom: 0;
    }
  }
}
.file {
  &__error {
    font-size: 14px;
    color: red;
  }
}
</style>
