import { Preferences } from '@capacitor/preferences';
import {
  CognitoIdToken,
  CognitoUserSession,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';

let environmentAuth: EnvironmentAuth | undefined;
let currentSession: CognitoUserSession | undefined;

const configureEnvironmentAuth = (value: EnvironmentAuth) => {
  environmentAuth = value;
};

const createCognitoUserSession = (storedSessionValue: {
  accessToken: { jwtToken: string },
  refreshToken: { token: string },
  idToken: { jwtToken: string }
}) => {
  const AccessToken = new CognitoAccessToken({ AccessToken: storedSessionValue.accessToken.jwtToken });
  const IdToken = new CognitoIdToken({ IdToken: storedSessionValue.idToken.jwtToken });
  const RefreshToken = new CognitoRefreshToken({ RefreshToken: storedSessionValue.refreshToken.token });

  return new CognitoUserSession({
    AccessToken,
    IdToken,
    RefreshToken,
  });
};

const getCurrentSession = async () => {
  if (currentSession) {
    return currentSession;
  }

  const { value } = await Preferences.get({ key: 'currentSession' });

  if (value) {
    const newSession = createCognitoUserSession(JSON.parse(value));
    currentSession = newSession;
  }

  return currentSession;
};

const signIn = async (username: string, password: string) => new Promise<CognitoUserSession>((resolve, reject) => {
  if (!environmentAuth) {
    return;
  }

  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const cognitoUser = new CognitoUser({
    Username: authenticationData.Username,
    Pool: new CognitoUserPool({
      UserPoolId: environmentAuth.userPoolId,
      ClientId: environmentAuth.userPoolWebClientId,
    }),
  });

  cognitoUser.authenticateUser(authenticationDetails, {
    onFailure: reject,
    onSuccess: async (result) => {
      currentSession = result;

      await Preferences.set({
        key: 'currentSession',
        value: JSON.stringify(currentSession),
      });

      resolve(result);
    },
  });
});

const refresh = async () => {
  if (!environmentAuth) {
    return undefined;
  }

  const poolData = {
    UserPoolId: environmentAuth.userPoolId,
    ClientId: environmentAuth.userPoolWebClientId,
  };

  const cognitoUser = new CognitoUserPool(poolData).getCurrentUser();
  const refreshToken = currentSession?.getRefreshToken().getToken();

  if (!cognitoUser) {
    // eslint-disable-next-line no-console
    console.error('No user available');
    return undefined;
  }

  if (!refreshToken) {
    // eslint-disable-next-line no-console
    console.error('No refreshToken available');
    return undefined;
  }

  const refreshTokenInstance = new CognitoRefreshToken({ RefreshToken: refreshToken });

  return new Promise<CognitoUserSession | undefined>((resolve) => {
    cognitoUser.refreshSession(refreshTokenInstance, async (err, updatedSession: CognitoUserSession) => {
      if (err) {
        throw new Error(err);
      }

      currentSession = updatedSession;
      await Preferences.set({
        key: 'currentSession',
        value: JSON.stringify(currentSession),
      });

      resolve(updatedSession);
    });
  });
};

const signOut = async () => {
  currentSession = undefined;
};

export const Auth = {
  configureEnvironmentAuth,
  getCurrentSession,
  signIn,
  signOut,
  refresh,
  createCognitoUserSession,
};
