<template>
  <div class="shift-diff-details">
    <h3>{{ departmentName }}</h3>
    <h4>{{ organisationName }}</h4>
    <span>Van {{ formatDate(shiftStart) }}</span>
    <span>Tot {{ formatDate(shiftEnd) }}</span>
  </div>
</template>
<script lang="ts" setup>
import { formatFullDate } from '@pidz/date';
import { DateTime } from 'luxon';

defineProps<{
  departmentName: string;
  organisationName: string;
  shiftStart: string;
  shiftEnd: string;
}>();

const formatDate = (isoString: string) => formatFullDate(DateTime.fromISO(isoString));
</script>

<style lang="scss">
.shift-diff-details {
  border: 1px solid var(--pidz-grey-light);
  padding: 24px;
  border-radius: 5px;
  color: var(--pidz-body-text);

  h3,
  h4 {
    padding: 0;
    margin: 0;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }
}
</style>
