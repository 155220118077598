import { useApi } from '../../utils/use-api';

const api = useApi();

export const healthService = {
  getHealth: (): Promise<HealthStatus> => api.rest({
    method: 'get',
    url: '/health'
  }),
}

