import { useSheetStore, useImpersonationStore, useShiftsStore, useInvitationsStore, useInvoicesStore } from '@pidz/stores';

const sheetStore = useSheetStore();
const impersonationStore = useImpersonationStore();
const invitationStore = useInvitationsStore();
const shiftsStore = useShiftsStore();
const invoiceStore = useInvoicesStore();

const resetAllOverviewPages = () => {
  sheetStore.clearSheets();
  invitationStore.clearInvitations();
  impersonationStore.clearImpersonation();
  shiftsStore.clearShifts();
  invoiceStore.clearInvoices();
};

export default resetAllOverviewPages;
