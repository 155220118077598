<template>
  <loader v-if="initialLoad" />
  <section v-else ref="scrollWrapper"
           class="virtual-scroller">
    <layout-desktop-wrapper>
      <div class="full-height-element" :style="{ height: `${totalSize}px` }">
        <div class="scroll-container"
             :style="{ transform: `translateY(${virtualRows[0]?.start ?? 0}px)` }">
          <div v-for="virtualRow in virtualRows"
               :key="virtualRow.key"
               :ref="measureElement"
               :data-index="virtualRow.key"
               :class="virtualRow.index % 2">
            <section v-if="virtualRow.index > items.length - 1"
                     class="scroller-bottom">
              <template v-if="!forceReload">
                <div v-if="endOfList && !loading && items.length > 4"
                     class="scroller-end">
                  Einde van de lijst
                </div>
                <loader v-else-if="loading"
                        class="loader"
                        text="Meer items aan het laden..."/>
              </template>
            </section>
        
            <component :is="elementSwitcher[props.scrollItemComponent]"
                       v-else
                       :data="items[virtualRow.index]"/>
          </div>
        </div>
      </div>
    </layout-desktop-wrapper>
  </section>
  <to-top-button :is-visible="isButtonVisible" @to-top="scrollToTop" />
</template>

<script setup lang="ts">
import ImpersonationOverviewItem from '@app/components/ImpersonationOverviewitem.vue';
import Loader from '@app/components/Loader.vue';
import NewOverviewItem from '@app/components/NewOverviewItem.vue';
import ToTopButton from '@app/components/virtualscroller/ToTopButton.vue';
import { useVirtualizer } from '@tanstack/vue-virtual';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect,
  watch,
  Ref,
} from 'vue';
import LayoutDesktopWrapper from '../layout/LayoutDesktopWrapper.vue';

const props = withDefaults(
  defineProps<{
    endOfList?: boolean;
    loadNext: Function;
    items: newOverviewItem[] | impersonationOverviewItem[];
    loading: boolean;
    scrollPosition: number;
    preventPullToRefresh: boolean;
    forceReload: boolean;
    scrollItemComponent?: string;
    smooth?: boolean
  }>(),
  {
    items: () => [],
    scrollItemComponent: () => 'overview',
    smooth: false,
  },
);

const elementSwitcher: Record<string, any> = {
  overview: NewOverviewItem,
  impersonation: ImpersonationOverviewItem,
};

const emit = defineEmits<{
  storeScrollPosition: [scrollPosition: number];
  updatePreventPullToRefresh: [value: boolean];
}>();

const scrollWrapper = ref<HTMLElement | null>(null);
const initialLoad = ref(true);

const rowVirtualizerOptions = computed(() => ({
  count: props.items.length + 1,
  getScrollElement: () => scrollWrapper.value,
  estimateSize: () => 143,
  overscan: 20,
}));

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => rowVirtualizer.value.getTotalSize());

const restoreScrollPosition = async (pos: number) => {
  rowVirtualizer.value.scrollToIndex(pos, { align: 'start' });
};

onMounted(async () => {
  if (props.scrollPosition === 0 && props.items.length === 0) {
    props.loadNext();
    initialLoad.value = false;
  } else if (props.scrollPosition !== 0) {
    initialLoad.value = false;
    await nextTick();
    await restoreScrollPosition(props.scrollPosition);
  } else {
    initialLoad.value = false;
  }
});

onBeforeUnmount(() =>
  emit('storeScrollPosition', rowVirtualizer.value.range!.startIndex),
);

watchEffect(() => {
  emit('updatePreventPullToRefresh', rowVirtualizer.value.scrollOffset > 5);
  if (rowVirtualizer.value.range === null || 
      props.endOfList || 
      props.items.length === 0 ||
      props.loading ||
      props.forceReload ||
      initialLoad.value) return;

  if (rowVirtualizer.value.range?.endIndex >= props.items.length - 3) props.loadNext();
});

const measureElement = (el) => {
  nextTick(() => {
    if (!el) return;
    rowVirtualizer.value.measureElement(el);
  });
};

const lastScrollDirection: Ref<'forward' | 'backward'> = ref('forward');
// watch a little later because the virualizer needs time to render after fn restoreScrollPosition
setTimeout(() => {
  watch(
    () => rowVirtualizer.value.scrollDirection,
    (newDir) => {
      if (newDir) lastScrollDirection.value = newDir;
    },
  );
}, 500);

const scrolledDownToIndex = ref(0);
watch(
  () => rowVirtualizer.value.range?.endIndex,
  (index) => {
    if (index && rowVirtualizer.value.scrollDirection === 'forward')
      scrolledDownToIndex.value = index;
  },
);

const buttonLogic = computed(() => {
  const endIndex = rowVirtualizer.value.range?.endIndex || 0;
  return {
    isScrollingUp: lastScrollDirection.value === 'backward',
    isPastScrollDownTrigger: endIndex >= 32,
    isPastScrollUpTrigger: endIndex <= scrolledDownToIndex.value - 2,
    isScrolledToEnd: endIndex >= props.items.length - 2,
  };
});

const isButtonVisible = computed(() => {
  if (props.loading) return false;
  const logic = buttonLogic.value;
  return (
    (logic.isScrolledToEnd && logic.isPastScrollDownTrigger) ||
    (logic.isPastScrollDownTrigger &&
      logic.isScrollingUp &&
      logic.isPastScrollUpTrigger)
  );
});

const scrollToTop = () => {
  rowVirtualizer.value.scrollToIndex(0, { behavior: props.smooth ? 'smooth' : 'auto' });
};
</script>

<style lang="scss">
.virtual-scroller {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  contain: strict;
  overscroll-behavior: none;

  .full-height-element {
    width: 100%;
    position: relative;

    .scroll-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .scroller-bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        .loader {
          margin-bottom: 36px;
        }

        svg {
          stroke: var(--pidz-blue);
        }

        .scroller-end {
          margin-top: 16px;
          margin-bottom: 90px;
          font-size: 14px;
          color: var(--pidz-body-text);
        }
      }
    }
  }
}
</style>
