<template>
  <router-link to="/calendar/add" class="add-event-button">
    <p-button variant="ghost"
                 shadowed>
      <p-icon class="add-icon" icon-name="add"
                 boxed :background="iconBackground"
                 size="large"
                 color="blue"/>
    </p-button>
  </router-link>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  isDrawerOpen?: boolean
}>();

const iconBackground = computed(() => (props.isDrawerOpen ? 'white' : 'blue'));
</script>
<style lang="scss" scoped>
.add-event-button {
  .add-icon {
    transition: all 0.3s ease-in-out;
    min-width: 44px;
    min-height: 44px;
    max-width: 44px;
    max-height: 44px;
  }
}
</style>
