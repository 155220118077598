<template>
  <div class="search-wrapper w-full flex">
    <div class="search-container">
      <label for="search-field">
        <search-icon />
        <input id="search-field" type="text"
               :value="modelValue"
               placeholder="Zoeken..."
               @focus="openSearchHistory"
               @keydown.enter="updateSearchTerm(($event.target as HTMLInputElement).value.trim())"
               @keyup.enter="($event.target as HTMLInputElement).blur()" />
      </label>
      <button> <close-icon @click="$emit('update:modelValue', '')" /> </button>
    </div>
    <div v-if="filterButton" class="bg-white flex items-center justify-center w-14 relative"
         @click="emit('openFilter')">
      <p-icon icon-name="filter"
              size="large"
              color="blue"/>
      <div v-if="hasFilter" class="w-4 h-4 rounded-full bg-red border-white border absolute right-2 top-3"></div>
    </div>
    <search-history v-if="displaySearchHistory" :search-term="modelValue"
                    :storage-key="storageKey"
                    @set-search-term="updateSearchTerm"
                    @close-search-history="closeSearchHistory"/>
  </div>
</template>
<script lang="ts" setup>
import SearchHistory from '@app/components/SearchHistory.vue';
import { CloseIcon, SearchIcon } from '@pidz/icons';
import { ref } from 'vue';

const emit = defineEmits(['update:modelValue', 'openFilter']);
const displaySearchHistory = ref(false);

const props = defineProps<{
  modelValue: string;
  storageKey: string;
  hasFilter?: boolean;
  filterButton?: boolean;
}>();

const openSearchHistory = () => {
  displaySearchHistory.value = true;
};

const closeSearchHistory = () => {
  displaySearchHistory.value = false;
};

const updateSearchTerm = (searchTerm: string) => {
  if (props.modelValue === searchTerm) { closeSearchHistory(); }
  emit('update:modelValue', searchTerm);
};

</script>
<style lang='scss'>
.search-wrapper {
  box-shadow: var(--shadow-light);
  position: relative;
}

.search-container {
  width: 100%;
  display: flex;
  max-height: 56px;
  padding: 0 16px;
  -webkit-tap-highlight-color: transparent;

  &, button {
    background-color: var(--pidz-white);
  }

  label {
    display: flex;
    width: 100%;
  }

  .pidz-icon {
    width: 20px;

  }

  input {
    margin-left: 16px;
    padding: 16px 0;
    width: 100%;
    font: inherit;
    color: var(--pidz-body-text);

    ::placeholder {
      color: var(--pidz-grey-dark);
    }

    &:focus {
      outline: none;
    }
  }

  input, button {
    border: none;
    background-color: none;
  }

  button {
    background-color: var(--pidz-grey-dark);
    border-radius: 50%;
    padding: 4px;
    display: flex;
    align-items: center;
    align-self: center;

    .pidz-icon {
      height: 12px;
      width: 12px;
      color: var(--pidz-white);
    }
  }
}
</style>
