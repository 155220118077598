<template>
  <layout-desktop-wrapper>
    <bank-form ref="form" :form-data="formDataBank"
               is-edit 
               :mandate-bank-account
               :web-url />
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { BankForm } from '@pidz/shared/components'
import {
  globalValidations,
  rows,
} from '@pidz/shared/forms/profile/bank';
import { useUserStore, useMollieStore } from '@pidz/stores';
import { ref, computed, onMounted } from 'vue';

const form = ref<typeof BankForm>();
const userStore = useUserStore();

const formDataBank: PidzFormData<ProfileBank> = {
  globalValidations,
  values: userStore.getBankDetails(),
  rows,
};

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)

const goBack = () => form.value?.onCancel()
defineExpose({ goBack })

const mollie = useMollieStore();

const mandateBankAccount = ref<undefined | string>();
const webUrl = import.meta.env.VITE_APP_URL;

onMounted(async () => {
  const { bank_account_is_same_as_mandate, mandate_bank_account } = await mollie.isMollieMandateValid();
  if (!bank_account_is_same_as_mandate && mandate_bank_account) {
    mandateBankAccount.value = mandate_bank_account;
  }
})
</script>
