<template>
  <modal>
    <h2 class="font-bold">Algemene voorwaarden</h2>
    <p>
      Met het accepteren van de opdracht ga je automatisch akkoord met de
      volgende voorwaarden:
    </p>
    <accept-shift-modal-terms pdf-url="https://mijnpidz.nl/build/files/algemene_voorwaarden_opdrachtgevers.pdf"
                              label="Algemene voorwaarden Smiling Gents Software B.V."/>
    <accept-shift-modal-terms pdf-url="https://mijnpidz.nl/build/files/algemene_voorwaarden_freelancefactoring.pdf"
                              label="Algemene voorwaarden The Factoring Company"/>
    <template #footer>
      <button class="button-outline" @click="modalController.close">Annuleer</button>
      <button @click="accept">Akkoord</button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal from '@app/components/Modal.vue';
import { useModalController } from '@pidz/utils';
import AcceptShiftModalTerms from './AcceptShiftModalTerms.vue';

const modalController = useModalController();

const props = defineProps<{
  accept: Function;
}>();

const accept = () => {
  modalController.close();
  props.accept();
};
</script>
