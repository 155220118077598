import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';

export const rateField = ref<FormField>({
  active: true,
  name: 'rate',
  formType: FormFieldType.INPUT,
  validation: zod.string()
    .refine((val: string) => /^\d+([,.]\d{2})?$/.test(val), {
      message: 'Vul een geldig bedrag in',
    }),
  width: 50,
  data: {
    id: 'rate',
    preIcon: 'euro',
    label: 'Incidenteel',
    inputType: 'text',
  },
});

export const periodicalRateField = ref<FormField>({
  active: true,
  name: 'periodical_rate',
  formType: FormFieldType.INPUT,
  validation: zod.string()
    .refine((val: string) => /^\d+([,.]\d{2})?$/.test(val), {
      message: 'Vul een geldig bedrag in',
    }),
  width: 50,
  data: {
    id: 'periodical_rate',
    preIcon: 'euro',
    label: 'Periodiek',
    inputType: 'text',
  },
});
