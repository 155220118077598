<template>
  <section class="micro-sheet-row-card-view">
    <header>
      <h3>{{date}}</h3>
      <pencil-icon v-if="config.allowEdit" class="edit-sheet-row" />
    </header>
    <main>
      <span :class="{ label:1, wasTime }">Start- en eindtijd</span>
      <span>{{actualTime}}</span>
      <template v-if="wasTime">
        <span />
        <span>{{wasTime}}</span>
      </template>
      <template v-if="overtimeRemark">
        <span class="label">Toelichting overuren</span>
        <span class="truncate-text">{{overtimeRemark}}</span>
      </template>
      <span class="label">Niet declarabele tijd</span>
      <span>{{formatSecondsToHours(breakTime)}}</span>
      <span class="label">Gewerkt tijdens slaapuren</span>
      <span class="work-during-sleep">{{formatSecondsToHours(workDuringSleepTime, config.allowSleep)}}</span>
      <span class="label">Reiskosten</span>
      <span class="travel-distance">{{config.allowTravel ? travelDistance : 'n.v.t.'}}</span>
    </main>
    <footer>
      <span class="label">Totaal</span><span>{{formatSecondsToHours(totalTime)}}
        <span v-if="hasDaylightSavingsSplit" class="dst"> (zomer/wintertijd al verrekend)</span>
      </span>
    </footer>
  </section>
</template>
<script lang="ts" setup>

import { PencilIcon } from '@pidz/icons';
import { MicroSheetRowConfig } from '../types';

defineProps<{
  date: string,
  actualTime: string,
  wasTime?:string,
  breakTime: number,
  workDuringSleepTime: number,
  totalTime: number,
  travelDistance?: number,
  overtimeRemark?: string,

  config: MicroSheetRowConfig,
  hasDaylightSavingsSplit: boolean,
}>();

const formatSecondsToHours = (totalSeconds: number, allowed:boolean = true) => {
  if (!allowed) { return 'n.v.t.'; }
  const totalMinutes = Math.round(totalSeconds / 60);
  const sign = totalMinutes < 0 ? '-' : '';
  let minutes = Math.abs(totalMinutes);
  if (minutes === 0) { return '-'; }

  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  const result = sign;
  const parts:string[] = [];
  if (hours > 0) { parts.push(`${hours} uur`); }
  if (minutes > 0) { parts.push(`${minutes} ${minutes === 1 ? 'minuut' : 'minuten'}`); }
  return result + parts.join(', ');
};

</script>
<style lang="scss">

.micro-sheet-row-card-view {
  border-radius: 12px;
  color: var(--pidz-body-text);
  background-color: var(--pidz-white);
  border: 1px solid var(--pidz-grey-light);
  box-shadow: var(--shadow-light);
  margin-bottom: 16px;

  header {
    border-bottom: 1px solid var(--pidz-grey-light);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }

    .edit-sheet-row {
      height: 24px;
      color: var(--pidz-blue);

      .pidz-icon {
        height: 100%;
      }
    }
  }

  main, footer {
    padding: 12px;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 8px;

    .label {
      font-size: 14px;
      font-weight: 600;
      align-self: center;
    }

  }

  footer {
    border-top: 1px solid var(--pidz-grey-light);
  }
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
