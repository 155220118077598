<template>
  <div class="unpaid-invoice-page">
    <pidz-logo />
    <main>
      <h1>Openstaande facturen</h1>
      <p>
        Helaas zijn er nog openstaande facturen van het gebruik van onze
        software.
      </p>
      <p>
        Om gebruik te kunnen maken van het PIDZ systeem dienen deze facturen
        voldaan te worden.
      </p>
      <ul>
        <li v-for="invoice in invoiceStore.unpaidInvoices"
            :key="invoice.invoice_id"
            @click="invoiceClick(invoice)">
          <invoice-icon />Betaal factuur van maand: {{ invoiceMonth(invoice) }}
        </li>
      </ul>
    </main>
  </div>
</template>
<script lang="ts" setup>
import { Browser } from '@capacitor/browser';
import { InvoiceIcon } from '@pidz/icons';
import { useInvoice } from '@pidz/stores';
import { DateTime } from 'luxon';
import PidzLogo from '../../components/PidzLogo.vue';
import { SoftwareInvoice } from '../invoices/types';

const invoiceStore = useInvoice();

const invoiceMonth = (invoice: SoftwareInvoice) => DateTime.fromISO(invoice.month).setLocale('nl-nl').toLocaleString({
  year: 'numeric',
  month: 'long',
});

const invoiceClick = async (invoice: SoftwareInvoice) => {
  const invoiceId = invoice.invoice_id;
  const url = await invoiceStore.softwareInvoicePaymentLink(
    invoiceId, 
    `pidz-app://freelancer.software.unpaid.payment/${invoiceId}`
  )
  Browser.open({ url });
};
</script>
<style lang="scss">
.unpaid-invoice-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--pidz-blue);
  color: var(--pidz-white);

  .pidz-logo {
    height: 40px;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  main {
    width: 100%;
    padding: 12px;

    ul {
      margin-top: 24px;
      list-style-type: none;
      padding: 0;
    }

    li {
      padding: 12px;
      background: var(--pidz-white);
      color: var(--pidz-blue);
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid var(--pidz-grey-dark);
      }
    }

    .pidz-icon {
      margin-right: 6px;
    }

    button {
      margin-top: 60px;
      padding: 12px 24px;
      background: var(--pidz-white);
      color: var(--pidz-blue);
      border: 0;
      font-size: 16px;
    }
  }
}
</style>
