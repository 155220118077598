import { RowType } from '@pidz/enums';
import {
  cocField,
  companyNameField,
  websiteField,
  vatExemption,
  vatField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<ProfileCompany>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [cocField.value],
  },
  {
    type: RowType.ROW,
    items: [companyNameField.value],
  },
  {
    type: RowType.ROW,
    items: [websiteField.value],
  },
  {
    type: RowType.ROW,
    items: [vatField.value],
  },
  {
    type: RowType.ROW,
    items: [vatExemption.value],
  },
];
