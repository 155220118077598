<template>
  <p-card class="notifications"
          :padding="padding"
          title="Uitnodigingen"
          :rounded="false"
          title-size="lg">
    <template #content>
      <div>
        <p class="text-sm pt-4">
          Stel in hoe je uitnodigingen voor open opdrachten wenst te ontvangen
        </p>
      </div>
      <div class="flex flex-col justify-between pt-8 text-sm">
        <div class="flex flex-row justify-between items-center pb-4">
          <p class="font-bold">Emails</p>
          <p-toggle v-model="emailNotifications"
                    input-id="notification-toggle"
                    colored
                    :disabled="loading"/>
        </div>
        <p-ruler />
        <div v-if="isWeb"
             class="flex flex-row justify-between items-center pt-4">
          <p class="font-bold">Dashboard</p>
          <p class="text-neutral-500">
            Je ontvangt altijd meldingen via het dashboard
          </p>
        </div>
        <div v-else class="flex flex-row justify-between items-center pt-4">
          <div class="flex items-center gap-2">
            <p class="font-bold">
              {{ approved ? 'Meldingen staan aan' : 'Meldingen staan uit' }}
            </p>
            <p-icon :icon-name="approved ? 'check' : 'close'"
                    size="large"
                    :color="approved ? 'green' : 'red'"/>
          </div>
          <div>
            <p-button rounded @click="openAppSettings"> Instellingen </p-button>
          </div>
        </div>
      </div>
    </template>
  </p-card>
</template>

<script lang="ts" setup>
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  PushNotifications,
  PermissionStatus,
} from '@capacitor/push-notifications';
import { useToaster, useUserStore } from '@pidz/stores';
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';
import { ref, computed, onMounted } from 'vue';

const userStore = useUserStore();
const toaster = useToaster();

const loading = ref<boolean>(false);

const isWeb = Capacitor.getPlatform() == 'web';

defineProps<{
  padding: string;
}>();

const pushNotification = ref<PermissionStatus>();
const emailNotifications = computed({
  get() {
    return !!userStore.freelancer?.invite_by_email;
  },
  set(value) {
    handleUpdateEmailNotification(value);
  },
});

const approved = computed(() => pushNotification.value?.receive === 'granted');

const handleUpdateEmailNotification = async (value: boolean) => {
  loading.value = true;
  await userStore.updateEmailPreferences(value);
  toaster.addToaster(
    {
      type: 'success',
      title: 'Gelukt!',
      message: 'Succesvol aangepast',
    },
    5000,
  );
  loading.value = false;
};

const openAppSettings = () => {
  NativeSettings.open({
    optionAndroid: AndroidSettings.AppNotification,
    optionIOS: IOSSettings.App,
  });
};

onMounted(async () => {
  if (!isWeb) {
    App.addListener('resume', async () => {
      pushNotification.value = await PushNotifications.checkPermissions();
    });
    pushNotification.value = await PushNotifications.checkPermissions();
  }
});
</script>
