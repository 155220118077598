import { InvoiceType } from '@pidz/enums';

export default [
  {
    name: 'invoices',
    path: '/invoices',
    redirect: { name: 'freelancer invoices' },
    children: [
      {
        name: 'freelancer invoices',
        path: '/invoices/freelancer',
        redirect: { name: 'freelancer invoices overview' },
        children: [
          {
            name: 'freelancer invoices overview',
            path: '/invoices/freelancer',
            component: () => import('@app/pages/invoices/InvoiceOverviewPage.vue'),
            meta: { 
              title: 'Facturen', 
              type: InvoiceType.FREELANCER_INVOICE
            },
          },
          {
            name: 'freelancer invoice',
            path: '/invoices/freelancer/:id/:payment?',
            component: () => import('@app/pages/invoices/FreelancerInvoiceDetailPage.vue'),
            meta: {
              title: 'Factuur', 
              backButton: true,
            },
          },
        ]
      },
      {
        name: 'software invoices',
        path: '/invoices/software',
        redirect: { name: 'software invoices overview' },
        children: [
          {
            name: 'software invoices overview',
            path: '/invoices/software',
            component: () => import('@app/pages/invoices/InvoiceOverviewPage.vue'),
            meta: { 
              title: 'Facturen', 
              type: InvoiceType.SOFTWARE_INVOICE 
            },
          },
          {
            name: 'software invoice',
            path: '/invoices/software/:id/:payment?',
            component: () => import('@app/pages/invoices/SoftwareInvoiceDetailPage.vue'),
            meta: { 
              title: 'Factuur', 
              backButton: true,
            },
          },
        ]
      },
    ],
  },
]