import { RowType } from '@pidz/enums';
import {
  birthCountryField,
  birthDateField,
  birthPlaceField,
  firstNameField,
  genderField,
  initialsField,
  insertionField,
  lastNameField,
  maritalStatusField,
  nationalityField,
  nationalServiceNumberField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<any>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [firstNameField.value, initialsField.value],
  },
  {
    type: RowType.ROW,
    items: [insertionField.value, lastNameField.value],
  },
  {
    type: RowType.ROW,
    items: [birthDateField.value],
  },
  {
    type: RowType.ROW,
    items: [birthPlaceField.value],
  },
  {
    type: RowType.ROW,
    items: [birthCountryField.value],
  },
  {
    type: RowType.ROW,
    items: [nationalityField.value],
  },
  {
    type: RowType.ROW,
    items: [maritalStatusField.value],
  },
  {
    type: RowType.ROW,
    items: [nationalServiceNumberField.value],
  },
  {
    type: RowType.ROW,
    items: [genderField.value],
  },
];
