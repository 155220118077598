<template>
  <month-view :is-loading="calendarStore.eventsLoading"
              :selected-date-text="selectedDateText"
              :is-drawer-open="calendarStore.isDrawerOpen"
              :days="days"
              :has-add-event-button="false"
              @handle-day-click="selectDay"/>
  <calendar-drawer />
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import MonthView from '@app/components/calendar/MonthView.vue';
import { getSelectedDateText } from '@pidz/date';
import { useCalendar } from '@pidz/stores';
import { computed, ref } from 'vue';
import CalendarDrawer from './CalendarDrawer.vue';

const calendarStore = useCalendar();
const dailyEvents = ref<[] | CalendarEvent[]>([]);

const selectedDateText = computed(() => {
  const { date, isDateThisYear } = calendarStore;
  return getSelectedDateText(date, isDateThisYear);
});

const days = computed(() => calendarStore.getMonthDays)

const selectDay = (day: CalendarDay) => {
  if (!calendarStore.isDrawerOpen) calendarStore.isDrawerOpen = true;
  dailyEvents.value = day.events;
  calendarStore.setDate(day.date);
};
</script>
<style lang="scss">
@keyframes fadeMonthOpacity {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0.2;
  }
}

.calendar-month-content {
  &.loading .grid,
  &.loading .selected-date {
    animation: fadeMonthOpacity 1s infinite alternate-reverse;
    pointer-events: none;
    cursor: none;
  }

  .selected-date {
    padding: 12px;
    color: var(--pidz-body-text);
  }

  .grid {
    padding: 12px 2px;
    background: var(--pidz-white);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 62px);
    transition: all 0.3s ease-in-out;

    .more-1 {
      display: none;
    }
    &.condensed {
      .more-1 {
        display: inherit;
      }
      .more-3 {
        display: none;
      }
      grid-template-rows: repeat(6, 39px);

      .more-1 ~ .bar,
      .bar ~ .bar {
        display: none;
      }
    }
  }
  .day {
    color: var(--pidz-body-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    padding: 2px;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 13px;

    &.disabled {
      opacity: 0.5;
    }

    .nr {
      height: 16px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.today .nr {
      font-size: 11px;
      background: var(--pidz-blue);
      color: var(--pidz-white);
      font-weight: bold;
      border-radius: 50%;
    }

    &.selected {
      border: 1px solid var(--pidz-grey-light);
      border-radius: 4px;
    }

    .bar {
      width: 100%;
      height: 5px;
      border-radius: 2.5px;
      margin-top: 2px;
    }
  }
}
</style>
