<template>
  <div class="information-circle">
    <div class="information-circle__outer" :class="`information-circle__outer--${number ? type : 'gradient'}`">
      <div class="information-circle__inner" :class="`information-circle__inner--${number ? type : 'gradient'}`">
        <p-icon v-if="isEmpty"
                icon-name="hourglass"
                class="information-circle__icon"
                size="large"
                color="light-grey"/>
        <h3 v-else class="information-circle__number">{{ type === 'gradient' && number ? number/10 : number }}</h3>
      </div>
    </div>
    <h2 v-if="subText" class="information-circle__subtext">{{ subText }}</h2>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  number: number | null
  subText: string
  type: 'filled' | 'gradient'
}>(), {
  type: 'filled'
});

const isEmpty = computed(() => props.number === null);
const gradientPercentage = computed(() => `${100 - Number(props.number)}%`);
</script>

<style scoped lang="scss">
.information-circle {
    margin-bottom: 15px;
    &__outer {
        width:100%;
        border-radius: 500px;
        aspect-ratio: 1/1;
        position: relative;
        box-shadow: var(--shadow-light);

        &--filled{
            background-color: var(--pidz-blue-light);
        }
        &--gradient{
            background: rgb(215,215,214);
            background: linear-gradient(180deg, rgba(215,215,214,1) 0%, rgba(215,215,214,1) v-bind(gradientPercentage), rgba(0,77,157,1) v-bind(gradientPercentage), rgba(158,187,217,1) 100%);
        }
    }
    &__inner {
        width: calc(100% - 10px);
        border-radius: 500px;
        aspect-ratio: 1/1;
        position: absolute;
        left: 5px;
        top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &--filled{
            background-color: 'transparent';
        }
        &--gradient{
            background-color: white;
        }
    }
    &__subtext {
        text-align: center;
        color: var(--pidz-body-text);
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;

        @media screen and (min-width: 1024px) {
          font-size: 16px;
        }
    }
    &__number{
        text-align: center;
        display: flex;
        justify-content: center;
        height: 100%;
        margin: 0;
        flex-direction: column;
        font-family: Lato;
        font-size: 24px;
        font-weight: 700;
        color: var(--pidz-blue);
    }
    &__icon {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
}
</style>
