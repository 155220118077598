<template>
  <drawer class="software-invoice-drawer" vertical>
    <div class="software-invoice-number">
      <span>Factuurnummer</span>
      <span class="invoice-number">
        {{invoiceData.invoice_number}}
      </span>
    </div>
    <template v-if="invoicePayable" #buttons>
      <async-button class="light" @click="payInvoice">Factuur betalen</async-button>
    </template>
  </drawer>
</template>

<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Drawer from '@app/components/Drawer.vue';
import resetAllOverviewPages from '@app/utils/reset-all-overview-pages';
import { Browser } from '@capacitor/browser';
import { useInvoice } from '@pidz/stores'
import { computed } from 'vue';
import { SoftwareInvoice } from '../types';

const props = defineProps<{
  invoiceData: SoftwareInvoice,
}>();

const invoiceStore = useInvoice();
const invoicePayable = computed(() => props.invoiceData.payable);

const payInvoice = async () => {
  const invoiceId = props.invoiceData.invoice_id;
  const url = await invoiceStore.softwareInvoicePaymentLink(
    invoiceId, 
    `pidz-app://freelancer.software.payment/${invoiceId}`
  )

  resetAllOverviewPages();

  Browser.open({ url });
};

</script>

<style lang="scss">
.software-invoice-drawer {
  grid-auto-flow: row;

  .software-invoice-number {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px
    }

    .invoice-number {
      font-weight: 700;
      font-size: 28px;
    }
  }

}

</style>
