<template>
  <shift-detail-row-text name="Uren per opdracht"
                         :value="formattedHoursString(shift.hours_per_shift)"
  />
  <shift-detail-row-text name="Uren per week"
                         :value="formattedHoursString(shift.hours_per_week)"
  />
  <shift-detail-row-text name="Dagen" :values="mappedShiftDays" />
  <shift-detail-row-text name="Dagdelen" :values="mappedDayParts" />
</template>
<script lang="ts" setup>
import { dayPartsMap, daysMap } from '@pidz/date';
import { computed } from 'vue';
import { Shift } from '../types';
import ShiftDetailRowText from './ShiftDetailRowText.vue';

const props = defineProps<{
  shift: Shift;
}>();

const mappedShiftDays = computed(
  () => [...props.shift.days]
    .sort((a, b) => a - b)
    .map((dayIndex) => daysMap.get(dayIndex)!) ?? '-',
);

const mappedDayParts = computed(
  () => [...props.shift.days_part]
    .sort((a, b) => a - b)
    .map((dayPart) => dayPartsMap.get(dayPart)!) ?? '-',
);

const formattedHoursString = (hours?: number | undefined) => (hours ? `${hours} uur` : '-');
</script>
<style lang="scss"></style>
