import { Component, ref, shallowRef } from 'vue';

const current = shallowRef<Component|null>(null);
const currentData = ref<any|null>(null);
export const useModalController = () => {
  const open = (instance:Component, data:any = {}) => {
    current.value = instance;
    currentData.value = data;
  };
  const close = () => {
    current.value = null;
    currentData.value = null;
  };
  return {
    current,
    currentData,
    open,
    close,
  };
};
