import { useDownMoveUp } from '@pidz/utils';
import { easeOutCubic } from 'js-easing-functions';
import { ref } from 'vue';

const downPosition = ref<DOMPoint | null>(null);
const pullHeight = ref(0);
const isPulling = ref(false);

const cancelPull = () => {
  downPosition.value = null;
  pullHeight.value = 0;
  isPulling.value = false;
};

export default (main, reload) => {
  useDownMoveUp({
    down(event, position) {
      const target = (event.target as HTMLElement);
      const preventPullTarget = target.closest('[data-prevent-pull]');
      const dynamicPreventPullTarget = target.closest('[data-dynamic-prevent-pull=true]');
      const preventPullTargetOnOffset = target.closest('[data-prevent-pull-on-scrolloffset]');
      
      const safeAreaTop = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--sat').replace('px', '')
      );

      if (main.value && (main.value.getBoundingClientRect().top - safeAreaTop - 56) < 0) return;
      if (preventPullTarget || dynamicPreventPullTarget) return;
      if (preventPullTargetOnOffset && preventPullTargetOnOffset.scrollTop > 0) return;

      downPosition.value = position;
    },
    move(_, position) {
      if (downPosition.value === null || main.value.scrollTop > 0) return;

      const delta = new DOMPoint(
        position.x - downPosition.value.x,
        position.y - downPosition.value.y,
      );

      if (Math.abs(delta.x) > 5 && Math.abs(delta.x) > Math.abs(delta.y)) {
        cancelPull();
        return;
      }

      if (delta.y > 10) {
        isPulling.value = true;
      }

      const dampForce = easeOutCubic(Math.min(delta.y, 400), 1, -0.5, 400);
      pullHeight.value = dampForce * delta.y;
    },
    up() {
      if (pullHeight.value >= 100) {
        reload();
      }

      cancelPull();
    },
  });

  return { pullHeight, isPulling };
};
