<template>
  <div class="registration-card">
    <div class="registration-card__number">
      {{ registration.registration_number }}
    </div>
    <div v-if="registration.expires_at && registration.acquired_at"
         class="registration-card__expiration">
      Geregistreerd op {{ formatDate(registration.acquired_at) }}
    </div>
    <div class="registration-card__file-row">
      <p-status-row :status="registration.nearing_expiration_date ? 
                         CvSectionStatus.NEARING_EXPIRATION_DATE : registration.status"
                       :date="formatDate(dateFileRow())"
                       :date-text="dateTextFileRow()"
                       :file-text="registration.assets.length > 1 ? 'Registratie bestanden' : 'Registratie bestand'"
                       :alignment="'vertical'"
                       :reverse="false"
                       @open-files="(e: Event) => handleFileToggle(e)"/>
      <p-dropdown-list v-if="registration.assets.length == 1"
                          :open="showDropDown"
                          :position="position"
                          class="-translate-y-full" @close="() => (showDropDown = false)">
        <template #items>
          <div v-for="(asset, index) in registration.assets" :key="index">
            <p-file-list-item :file-name="asset.filename"
                                 :show-download-button="true"
                                 @download="downloadFile(asset)"/>
          </div>
        </template>
      </p-dropdown-list>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { CvSectionStatus } from '@pidz/enums';
import { useAssetStore } from '@pidz/stores';
import { openFile } from '@pidz/utils';
import { DateTime } from 'luxon';
import { ref } from 'vue';
import { getMimeType } from '../../../../utils';

const props = defineProps<{
  registration: Registration;
}>();

const assetStore = useAssetStore();
const showDropDown = ref<boolean>(false);
const position = ref<PidzDropdownListPosition>({ height: 0, left: 0 });

const downloadFile = async (file: Asset) => {
  if('id' in file && file.id){
    const response = await assetStore.fetchAsset(file.id);
    const mimeType = getMimeType(file.filename)
    if(mimeType) openFile(response, mimeType);
  }
}

const handleFileToggle = (e: Event) => {
  e.stopPropagation();
  if (props.registration.assets.length == 1) {
    const target = e.currentTarget as HTMLInputElement;
    const leftOffset = document.body.clientWidth > 425 ? target.offsetLeft : 0;
    const left =
      target.offsetLeft + 400 > (target?.offsetParent?.clientWidth || 0)
        ? undefined
        : leftOffset + 25;
    const topOffset = target.offsetTop;
    const buttonHeight = target.clientHeight;
    const height = topOffset + buttonHeight;

    position.value = { height, left };
    showDropDown.value = !showDropDown.value;
  } else {
    downloadFile(props.registration.assets[0]);
  }
};

const dateFileRow = (): string => {
  if (props.registration.acquired_at && props.registration.expires_at) {
    return props.registration.expires_at;
  }
  if (!props.registration.acquired_at && props.registration.expires_at) {
    return props.registration.acquired_at;
  }
  return props.registration.acquired_at;
};

const dateTextFileRow = (): string => {
  if (props.registration.acquired_at && props.registration.expires_at) {
    return 'Geldig tot';
  }
  if (props.registration.acquired_at && !props.registration.expires_at) {
    return 'Geregistreerd op';
  }
  return 'Geldig tot';
};

const formatDate = (date: string): string =>
  DateTime.fromISO(date).toLocaleString({
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
</script>

<style lang="scss" scoped>
.registration-card {
  padding-left: 16px;
  padding-top: 12px;

  &__number {
    color: var(--pidz-blue);
    font-size: 16px;
    padding-bottom: 8px;
  }

  &__expiration {
    color: var(--pidz-grey-dark);
    font-size: 14px;
    padding-bottom: 8px;
  }
}
</style>
