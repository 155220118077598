<template>
  <div class="expanded-picker">
    <p>{{ title }}</p>
    <p v-if="!disabled && showHelpText">Voeg een of meerdere afbeeldingen/documenten van je certificaat toe.
      Zorg er voor dat alle benodigde informatie van je certificaat te zien is.</p>

    <div v-if="combinedFiles.length" class="expanded-picker__list">
      <p-file-list-item v-for="item in combinedFiles"
                        :key="getName(item)"
                        :class="['expanded-picker__item' , `expanded-picker__item--${'id' in item ? 'existing' : 'new'}`]"
                        :file-name="getName(item)"
                        :date="'valid_until' in item ? DateTime.fromISO(item.valid_until || '') : undefined"
                        :status="'status' in item && showStatus ? item.status : undefined"
                        show-file-icon
                        :show-download-button="'id' in item"
                        :show-delete-button="!disabled"
                        @delete="emit('delete', item)"
                        @download="emit('download', item)"/>
    </div>
    <div v-else class="expanded-picker__empty">
      <p>Er zijn nog geen bestanden toegevoegd</p>
    </div>

    <div v-if="hasError" class="expanded-picker__error">
      <span>{{ dirtyFiles!.length === 1 ? 'Het volgende bestand is' : 'De volgende bestanden zijn'}} te groot,
        de maximale grootte is 8MB per bestand:</span>
      <ul>
        <li v-for="file in dirtyFiles" :key="file.name">{{ file.name }}</li>
      </ul>
    </div>

    <p-file-picker v-if="!disabled" class="expanded-picker__picker"
                   :multiple="multi"
                   :allowed-file-types="allowedFileTypes"
                   @upload="handleFiles">
      <p-icon icon-name="add"
              class="pointer"
              color="blue"/>
      Bestand(en) toevoegen
    </p-file-picker>

  </div>
</template>
<script lang="ts" setup>
import { DateTime } from 'luxon';
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    existingFiles?: Asset[],
    newFiles: File[],
    allowedFileTypes?: Array<'pdf' | 'image'>,
    maxFileSizeInMB?: number,
    multi?: boolean,
    disabled?: boolean,
    title?: string,
    showHelpText?: boolean
    showStatus?: boolean
  }>(),
  {
    title: 'Bestanden',
    existingFiles: () => [],
    newFiles: () => [],
    allowedFileTypes: () => ['pdf', 'image'],
    maxFileSizeInMB: 8,
    multi: true,
    disabled: false,
    showHelpText: true,
    showStatus: true
  }
);

const emit = defineEmits(['upload', 'delete', 'download']);
const combinedFiles = computed<(Asset | File)[]>(() => [...props.existingFiles, ...props.newFiles]);
const dirtyFiles = ref<File[]>();

const maxSize = computed(() => props.maxFileSizeInMB * 1024 * 1024);
const hasError = ref(false);

const handleFiles = (files: File[]) => {
  dirtyFiles.value = [...files].filter((file) => file.size > maxSize.value);
  hasError.value = dirtyFiles.value.length > 0;
  const filesAdded = (Object.values(props.newFiles) as File[]).map((file: File) => file.name);
  const newFiles = [...files].filter((file) => file.size < maxSize.value && !filesAdded.includes(file.name));
  emit('upload', newFiles);
};

const getName = (item: File | Asset): string => {
  if ('name' in item) {
    return item.name;
  }
  if ('filename' in item) {
    return item.filename;
  }
  return '';
};
</script>
<style lang="scss" scoped>
.expanded-picker {
  font-size: 16px;
  @media (min-width: 480px) {
    font-size: 14px;
  }
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 24px;
  }
  > p {
    margin-top: 0;
  }
  &__file, &__file-info, &__empty {
    display: flex;
    align-items: center;
  }
  &__file {
    justify-content: space-between;
    align-items: center;
  }
  &__empty {
    color: var(--pidz-grey-light);
    justify-content: center;
  }
  &__error {
    color: var(--pidz-red);
  }
  &__item{
    &--existing{
      margin-bottom: 24px;
    }
    &--new{
      margin-bottom: 8px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__picker{
    margin-bottom: 0px;
  }
}
.pointer {
  cursor: pointer;
}
</style>
