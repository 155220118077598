import { adminService } from '@pidz/api';
import { defineStore } from 'pinia';

interface ImpersonationStore {
  freelancers: Freelancer[],
  impersonationOverviewItems: impersonationOverviewItem[],
  impersonationSearchString: string,
}

const transformFreelancersToImpersonationItems = (freelancer: Freelancer): impersonationOverviewItem => ({
  name: `${freelancer.user.first_name} ${freelancer.user.insertion ?? ''} ${freelancer.user.last_name}`,
  email: freelancer.email_freelancer ?? 'Geen email',
  uuid: freelancer.user.uuid,
  type: freelancer.user.user_type
})

export default defineStore('impersonationStore', {
  state: (): ImpersonationStore => ({
    freelancers: [],
    impersonationOverviewItems: [],
    impersonationSearchString: '',
  }),
  actions: {
    async clearImpersonation() {
      this.freelancers = [];
      this.impersonationOverviewItems= [];
      this.impersonationSearchString = '';
    },
    async getFreelancers(params: getFreelancersRequest) {
      const result = await adminService.getFreelancers(params);

      const newFreelancers = result.data;
      this.freelancers = [...this.freelancers, ...result.data]

      newFreelancers.forEach((freelancer) => {
        this.impersonationOverviewItems = [...this.impersonationOverviewItems, 
          transformFreelancersToImpersonationItems(freelancer)
        ]
      });

      return this.freelancers;
    },

  },
});
