export default [
  { name: 'home', 
    path: '/',
    redirect: '/dashboard',
    meta: { title: 'Dashboard' }
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import('@app/pages/dashboard/DashboardPage.vue'),
    meta: { title: 'Dashboard' },
  },
  {
    name: 'feedback',
    path: '/feedback',
    component: () => import('@app/pages/feedback/FeedbackPage.vue'),
    meta: { title: 'Feedback' },
  },
]