import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';

export const travelTimeField = ref<FormField>({
  active: true,
  name: 'travelTime',
  formType: FormFieldType.INPUT,
  validation: zod
    .number({
      invalid_type_error: 'Reistijd is een getal in minuten'
    })
    .min(1, {
      message: 'Reistijd mag niet 0 of negatief zijn.',
    }),
  width: 100,
  data: {
    id: 'travelTime',
    label: 'Max reistijd (minuten)',
    inputType: 'number',
    placeholder: '',
  },
});
