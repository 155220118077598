<template>
  <div class="sheet-travel-costs-editor">
    <label>Van locatie:</label>
    <input name="from" v-model="from"
           placeholder="Vanaf" />
    <label>Naar locatie:</label>
    <input name="to" v-model="to"
           placeholder="Naar" />
    <label>Afstand (km):</label>
    <input name="distance" v-model="distance"
           type="number"
           min="0" max="500"
           step="1" placeholder="Afstand"/>
    <label>Bijzonderheden:</label>
    <textarea name="description" v-model="description"
              rows="5"
              placeholder="Bijzonderheden" />
  </div>
</template>
<script lang="ts" setup>

import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: any,
  inverse?:boolean,
}>();

const createValue = (prop:string) => computed({
  get() {
    return props.modelValue[prop];
  },
  set(to:any) {
    emit('update:modelValue', {
      ...props.modelValue,
      [prop]: to,
    });
  },
});

const from = createValue('fromAddress');
const to = createValue('toAddress');
const distance = createValue('distance');
const description = createValue('description');

</script>
<style lang="scss">

.sheet-travel-costs-editor {
  display: grid;
  label {
    display: block;
    color: var(--pidz-white);
    font-weight: normal;
    margin-bottom: 4px;
  }
  input {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    margin-bottom: 4px;

  }
  textarea {
    margin-bottom: 12px;
  }
  input, textarea {
    font-family: inherit;
    font-size: 14px;
    padding: 8px 12px;
    border: 0;
    background: none;
    border: 1px solid var(--pidz-white);
    color: var(--pidz-white);
    border-radius: 4px;

    &::placeholder {
      color: rgba(white,0.5);
      opacity: 1;
    }

  }
}
</style>
