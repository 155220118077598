export const base64FromFile = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const match = /data:.+,(.+)/.exec(reader.result as string);
    if (match) {
      resolve(match[1]);
    } else {
      reject(new Error('read data url invalid'));
    }
  };
  reader.onerror = reject;
});
