/* eslint-disable */
import '@pidz/icons/public/style.css';;
import initUserState from '@app/user-state';
import initEnvironment from '@app/utils/environment';
import { initializeAnalytics } from '@app/utils/firebase-analytics';
import { initNetworkStatus } from '@app/utils/network-connection-status-checker';
import overrideAndroid from '@app/utils/override-android';
import { initMSW } from '@pidz/mocking';
import PidzComponents from '@pidz/new-components';
import '../../../tailwind-index.css';
import { useFeatureflagsStore, useHealthStore, useToaster } from '@pidz/stores';
import { errorHandler } from '@pidz/utils';
import { Settings } from 'luxon';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'
import initAppLinkListener from './utils/app-link-listener';
import { useUpdateChecker } from './utils/use-update-checker';

const init = async () => {
  initMSW();
  await initEnvironment();
  await overrideAndroid();
  await initNetworkStatus();
  await initAppLinkListener();
  await initializeAnalytics();
  await initUserState();
  useUpdateChecker(router);
  initFeatureFlags();

  // Set the default zone in luxon
  Settings.defaultZone = 'Europe/Amsterdam';
  // Settings.throwOnInvalid = true;
};

const initFeatureFlags = () => {
  const featureFlagsStore = useFeatureflagsStore();
  featureFlagsStore.initRemoteFlags();
}

(async () => {
  const app = createApp(App)
    .use(createPinia())
    .use(PidzComponents)
  if (router) {
    app.use(router);
  }
  app.mount('#app');
  await init();
})();

window.onunhandledrejection = (event: PromiseRejectionEvent | any) => {
  let response;

  if (event.reason.response) response = event.reason.response; // JSONRPC error
  if (event.response) response = event.response; // Rest error

  if (!response) return;

  if (response.data.status === 'MAINTENANCE') {
    useHealthStore().setBackendStatus('MAINTENANCE');
  } else { 
    if (response.data.error) {
      useToaster().addToaster(errorHandler(response.data.error.code), 7000);
    }
  }
}
