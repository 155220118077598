import { useApi } from '../../utils/use-api';

const api = useApi();

export const notificationService = {
  setInvitationRead: (shiftWorkerId: number): Promise<boolean> => api.jsonrpc({
    url: 'notification:setInvitationRead',
    params: { shiftWorkerId }
  }),

  registerDevice: (data: DeviceRegistration): Promise<boolean> => api.jsonrpc({
    url: 'notification:registerDevice',
    params: data
  }),

  unRegisterDevice: (firebaseRegistrationId: string): Promise<boolean> => api.jsonrpc({
    url: 'notification:unRegisterDevice',
    params: { firebaseRegistrationId }
  }),

  getNotifications: (params: NotificationParams): Promise<NotificationResponse> => api.jsonrpc({
    url: 'notification:getNotifications',
    params
  }),

  dismissNotification: (params: DismissNotificationsParams): Promise<void> => api.jsonrpc({
    url: 'notification:dismissNotification',
    params
  })
}

