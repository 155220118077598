export const languageOptions = (languages: Language[]): FormDropDownOption[] => 
  languages.map(
    (language, index): FormDropDownOption => ({
      id: index,
      value: language.uuid,
      label:
        language.display_name ||
        `Geen label beschikbaar voor id: ${language.uuid}`,
    }),
  )
  
const skillType = new Map([
  [1, 'Moedertaal'],
  [2, 'Professioneel'],
  [3, 'Basisniveau'],
]);

export const skillOptions = (): FormDropDownOption[] => {
  const opties: FormDropDownOption[] = [];
  skillType.forEach((skill, key) => {
    opties.push({
      id: key,
      value: key,
      label: skill,
    });
  });
  return opties;
};
