<template>
  <teleport to="body">
    <section class="toaster-wrapper">
      <TransitionGroup name="slide-right">
        <p-toaster v-for="(toaster, key) in props.activeToasters"
                   :key="key"
                   :type="toaster.type"
                   :title="toaster.title"
                   :message="toaster.message"
                   :duration="toaster.duration"
                   :timestamp="key.toString()"
                   @close="(toaster) => emit('removeToaster', toaster)"/>
      </TransitionGroup>
    </section>
  </teleport>
</template>

<script lang="ts" setup>
import PToaster from '../../molecules/toaster/Toaster.vue';

const props = defineProps<{
  activeToasters: { [key: string]: Toaster }
}>();

const emit = defineEmits(['removeToaster']);
</script>

<style lang="scss">
.toaster-wrapper {
  z-index: 101;
  width: 100%;
  position: fixed;
  top: 52px;
  padding: 0 16px 0 8px;
  overflow: hidden;
  @media screen and (min-width: 480px) {
    top: 24px;
    width: 521px;
    right: 0px;
  }
  & > * {
      margin-top: 8px;
    }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: all 0.4s cubic-bezier(.55,1.5,.7,1);
  }

  .slide-right-enter-from,
  .slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
