<template>
  <div class="calendar-controls">
    <div class="left">
      <p-dropdown v-if="selectedCalendarView === CalenderViewTypeEnum.WEEK && !!selectedWeek" v-model="selectedWeek"
                  variant="transparent"
                  text-variant="largest"
                  icon-inline
                  :options="weeks" />
      <p-dropdown v-else-if="selectedMonth" v-model="selectedMonth"
                  variant="transparent"
                  text-variant="largest"
                  icon-inline
                  :options="months" />
      <p-dropdown v-model="selectedYear"
                  variant="transparent"
                  text-variant="largest"
                  icon-inline
                  :options="yearOptions" />
    </div>
    <div class="center">
      <p-dropdown v-model="selectedCalendarViewOption"
                  variant="transparent"
                  icon-inline
                  :options="viewOptions" />
    </div>
    <div class="right">
      <p-pagination :is-previous-disabled="isPreviousDisabled" :is-next-disabled="isNextDisabled"
                    @previous="$emit('previous')" @today="$emit('today')"
                    @next="$emit('next')"></p-pagination>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { getWeeknumbers } from '@pidz/date';
import { CalenderViewTypeEnum } from '@pidz/enums'
import { capitalizeString} from '@pidz/utils';
import { DateTime, Info } from 'luxon';
import { computed } from 'vue';

const emit = defineEmits([
  'update:selectedYear',
  'update:selectedMonth',
  'update:selectedWeek',
  'update:selectedCalendarView',
  'previous', 'today', 'next'
]);

const props = defineProps<{
  yearOptions: DropdownOption[]
  isPreviousDisabled: boolean
  isNextDisabled: boolean
  selectedMonth?: number
  selectedYear: number
  selectedWeek?: number
  selectedCalendarView: CalenderViewTypeEnum
}>();

const selectedYear = computed({
  get() {
    return props.selectedYear;
  },
  set(newValue) {
    emit('update:selectedYear', newValue);
  }
});
const selectedMonth = computed({
  get() {
    return props.selectedMonth;
  },
  set(newValue) {
    emit('update:selectedMonth', newValue);
  }
});
const selectedWeek = computed({
  get() {
    return props.selectedWeek;
  },
  set(newValue) {
    emit('update:selectedWeek', newValue);
  }
});
const selectedCalendarView = computed({
  get() {
    return Number(props.selectedCalendarView);
  },
  set(newValue) {
    emit('update:selectedCalendarView', newValue);
  }
});

const selectedCalendarViewOption = computed({
  get() {
    return props.selectedCalendarView;
  },
  set(newValue) {
    emit('update:selectedCalendarView', newValue);
  }
});

const currentDate = DateTime.local();
const currentYear = currentDate.year;

// provides the weeks shown in the dropdown
const weeks = getWeeknumbers(currentYear).map((_, i) => ({
  id: i + 1,
  value: i + 1,
  label: `Week ${i + 1}`
}));

// provides the month in the dropdown
const months = Info.months('long', { locale: 'nl' }).map((month, index) => ({
  id: index + 1,
  value: index + 1,
  label: capitalizeString(month)
}));

// list of dropdown options
const viewOptions = [{
  id: CalenderViewTypeEnum.WEEK,
  value: CalenderViewTypeEnum.WEEK,
  label: 'Week'
},
{
  id: CalenderViewTypeEnum.MONTH,
  value: CalenderViewTypeEnum.MONTH,
  label: 'Maand'
}] as DropdownOption[];
</script>

<style lang="scss" scoped>
.calendar-controls {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 24px 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato;
  background-color: var(--pidz-white);
  color: var(--pidz-black);

  .left {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .center {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    height: 24px;
  }
}
</style>
