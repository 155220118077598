
// main function
export const getNotificationRoute = (msg: NotificationItem): Route | null => {
  if (PROFILE_CV_TYPES.includes(msg.type)) return { name: 'cv'};
  const route = notificationRouteMap[msg.type];
  return route ? route(msg) : null;
}

// Route resolver object
const notificationRouteMap: Record<string, (msg: NotificationItem) => Route | null> = {
  // profile
  notify_freelancer_bank_account_changed: () => ({ name: 'profile' }),
  notify_freelancer_asset_expire_reminder: () => ({ name: 'profile' }),
  notify_freelancer_asset_disapproved: () => ({ name: 'profile' }),

  // sheets
  notify_open_sheets_freelancer_reminder: () => ({ name: 'sheets' }),
  notify_freelancer_freelancer_sheet: (msg) => ({ name: 'sheet', params: { id: String(msg.parameter) }}),
  
  // invoices
  freelancer_invoice: () => ({ name: 'freelancer invoices' }),
  software_invoice: () => ({ name: 'software invoices' }),
  software_invoice_reminder: () => ({ name: 'software invoices' }),
  software_incasso_failed: () => ({ name: 'software invoices'}),

  // shifts
  notify_worker_invite_worker_by_project: (msg) => ({ name: 'shift', params: { id: String(msg.parameters.shift) }}),
  notify_freelancer_freelancer_shift: (msg) =>  ({ name: 'shift', params: { id: String(msg.parameters.shift) }}),
  notify_worker_covid_changed: (msg) => ({ name: 'shift', params: { id: String(msg.parameters.shift) }}),
  notify_worker_filled_shift_changed: (msg) =>({ name: 'shift', params: { id: String(msg.parameters.parentShift) }}),
  notify_worker_shift_worker_removed: () => ({ name: 'shifts' }),

  // other
  notify_freelancer_pre_finance_preference_changed: () => ({ name: 'support' }),
  notify_freelancer_nudge_rate: () => ({name: 'rates'}),
};

// Types that redirect to profile > cv
const PROFILE_CV_TYPES: string[] = [
  'notify_worker_resume_item_approved',
  'notify_worker_resume_item_disapproved',
  'notify_worker_resume_item_expired',
  'notify_freelancer_competency_expire_reminder',
  'notify_freelancer_registration_expire_reminder',
  'notify_freelancer_registration_expired',
  'notify_freelancer_training_expire_reminder',
  'notify_freelancer_training_expired',
  'notify_freelancer_competency_expired',
  'worker_education_approved',
  'worker_education_disapproved',
  'worker_training_approved',
  'worker_training_disapproved',
  'worker_registration_approved',
  'worker_registration_disapproved',
];
