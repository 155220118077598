<template>
  <layout-desktop-wrapper ref="viewport" class="calendar-event-page">
    <form class="bg-white" @submit.prevent>
      <section class="title">Niet beschikbaar</section>
      <calendar-add-event-date-section v-model:start="event.start"
                                       v-model:end="event.end"
                                       v-model:allDay="event.allDay"/>
      <calendar-add-event-recurring-section v-if="route.meta.editRecurrence && eventFromStore?.repeat"
                                            v-model:days="repeat.days"
                                            v-model:periodType="repeat.period_type"
                                            v-model:repeatType="repeat.repeat_type"
                                            v-model:occurrences="repeat.occurrences"
                                            v-model:end="repeat.end"
                                            :date="event.start.date"/>
      <calendar-remove-event @click="remove" />
    </form>
    <form-buttons-row :on-cancel="backToCalendarOverview"
                      :on-submit="update"
                      :is-submit-disabled="invalidEvent"/>
  </layout-desktop-wrapper>
</template>
<!-- eslint-disable max-lines -->
<script lang="ts" setup>
import ExceptionModal from '@app/components/ExceptionModal.vue';
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import {
  eventDateTimeToLuxon,
  DateTime
} from '@pidz/date';
import { RecurrenceRepeatType, RecurrencePeriodType } from '@pidz/enums';
import { useCalendar } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import * as zod from 'zod';
import CalendarAddEventDateSection from './components/CalendarAddEventDateSection.vue';
import CalendarAddEventRecurringSection from './components/CalendarAddEventRecurringSection.vue';
import CalendarEditRecurrenceModal from './components/CalendarEditRecurrenceModal.vue';
import CalendarRemoveEvent from './components/CalendarRemoveEvent.vue';
import CalendarRemoveModal from './components/CalendarRemoveModal.vue';
import CalendarRemoveRecurrenceModal from './components/CalendarRemoveRecurrenceModal.vue';
import { getZodRepeatSchema } from './forms/zodSchemas';
import {
  formatRecurrenceEndDate,
  validateDatesOfEvent,
  validateTimeOfEvent,
  formatEventDate
} from './utils';

const route = useRoute();
const router = useRouter();
const calendarStore = useCalendar();
const backToCalendarOverview = () => router.go(-1);
const modal = useModalController();
const calendarId = Number(route.params.id as string);
const headerText = computed(() =>
  route.meta.editRecurrence ? 'Agenda-reeks bewerken' : 'Agenda-item bewerken',
);

const eventFromStore = calendarStore.events.find(
  (e: PersonalCalendarEvent) => e.id === calendarId,
);
const isRecurrenceItem = computed(
  () => !!(eventFromStore && eventFromStore.repeat),
);

const transformEventFromStore = ({
  start,
  end,
  type,
  all_day,
  id,
  repeat,
}: PersonalCalendarEvent) => {
  const { first_item: firstEvent } = repeat ?? {};
  const startDate = route.meta.editRecurrence && firstEvent ? firstEvent.start : start;
  const endDate = route.meta.editRecurrence && firstEvent ? firstEvent.end : end;
  return {
    id: route.meta.editRecurrence && firstEvent ? firstEvent.id : id,
    start: formatEventDate(startDate, 'start'),
    end: formatEventDate(endDate, 'end', all_day),
    type,
    allDay: all_day,
  };
};

const createUniqueDaysArray = (...args: number[]) => [...new Set([...args])];

const transformRepeatFromEvent = (event) => {
  if (!event.repeat) return {};

  return {
    ...event.repeat,
    days: createUniqueDaysArray(
      ...(event.repeat?.days ?? []),
      (event.repeat?.first_item?.start?.weekday ?? 1) - 1,
    )
  }
};

const event = reactive({ ...transformEventFromStore(eventFromStore!) });
const repeat = reactive(transformRepeatFromEvent(eventFromStore!));

const IsoOptions = { suppressMilliseconds: true };
const calendarItemModel = computed(() => ({
  id: event.id,
  all_day: event.allDay,
  type: event.type,
  start:
    event.allDay
      ? eventDateTimeToLuxon(event.start).startOf('day').toISO(IsoOptions)!
      : eventDateTimeToLuxon(event.start).toISO(IsoOptions)!,
  end:
    event.allDay
      ? eventDateTimeToLuxon(event.end).endOf('day').toISO(IsoOptions)
      : eventDateTimeToLuxon(event.end).toISO(IsoOptions),
}));

const repeatModel = computed(() => ({
  id: repeat.id,
  type: calendarItemModel.value.type,
  period_type: repeat.period_type,
  start: calendarItemModel.value.start,
  end: formatRecurrenceEndDate(
    repeat.end,
    event.start.time,
    calendarItemModel.value.start,
    repeat.period_type,
  ),
  days: repeat.repeat_type === RecurrenceRepeatType.Weekly ? repeat.days : undefined,
  repeat_type: repeat.repeat_type,
  occurrences: repeat.period_type === RecurrencePeriodType.SetAmount ? repeat.occurrences : 1,
}));

const removeCalendarEvent = () => calendarStore.removeCalendarEvent( calendarId );

const removeRecurrence = () => calendarStore.removeCalendarEventRecurrence(repeat.id);

const updateCalendarEvent = async () => {
  try {
    await calendarStore.updateCalendarItem({
      calendarItemModel: { ...calendarItemModel.value },
    });
    calendarStore.date = DateTime.fromISO(event.start.date);
    backToCalendarOverview();
  } catch (e) {
    // handled by global error handler
  }
};

const updateRecurrence = async () => {
  modal.open(CalendarEditRecurrenceModal, {
    update: async () => {
      try {
        await calendarStore.updateCalendarRecurrence({
          calendarItemModel: {
            ...calendarItemModel.value,
            repeat: { ...repeatModel.value },
          },
        });
        calendarStore.date = DateTime.fromISO(event.start.date);
        await calendarStore.loadEvents(calendarStore.dateRange(true));
        backToCalendarOverview();
      } catch (e) {
        // handled by global error handler
      }
    },
  });
};

const remove = async () => {
  const removeModalComponent = route.meta.editRecurrence
    ? CalendarRemoveRecurrenceModal
    : CalendarRemoveModal;
  const removeFn = route.meta.editRecurrence ? removeRecurrence : removeCalendarEvent;

  modal.open(removeModalComponent, {
    remove: async () => {
      try {
        await removeFn();
        calendarStore.date = DateTime.fromISO(event.start.date);
        await calendarStore.loadEvents(calendarStore.dateRange(true));
        logCustomEvent('agendaitem_remove', {
          series: isRecurrenceItem.value,
          type: route.meta.editRecurrence ? 'reeks' : 'single',
        });
        backToCalendarOverview();
      } catch (e) {
        modal.open(ExceptionModal);
      }
    },
  });
};

const validationSchema = toTypedSchema(
  zod.object({
    repeat: getZodRepeatSchema(event),
  }),
);

const { meta, isValidating, resetForm } = useForm({
  validationSchema,
});

watch(() => repeat.period_type, () => resetForm());

const isEventDateValid = computed(() => validateDatesOfEvent(event.start, event.end, event.allDay));
const isEventTimeValid = computed(() => validateTimeOfEvent(event.start, event.end, event.allDay));

const invalidEvent = computed(() => isValidating.value || !(meta.value.valid && isEventDateValid.value && isEventTimeValid.value));

const update = async () => {
  if (invalidEvent.value) {
    return;
  }
  await ((route.meta.editRecurrence) ? updateRecurrence : updateCalendarEvent)();
};

watch(
  () => event.start,
  (newStart, oldStart) => {
    const newSt = DateTime.fromISO(newStart.date);
    const oldSt = DateTime.fromISO(oldStart.date);
    
    if (repeat.days) {
      repeat.days = [
        ...repeat.days.filter(
          (day: number) => day
            !== oldSt.weekday - 1,
        ),
        newSt.weekday - 1,
      ];
    }
  },
);
</script>

<style lang="scss">
.calendar-event-page {
  section {
    padding: 24px 16px;
    border-bottom: 1px solid #d8d8d8;
    &.title {
      border-bottom: 0px;
      padding-bottom: 0px;
      font-weight: bold;
      font-size: 14px;
    }
    &.event-date-section {
      padding-top: 16px;
    }
    .labeled-slot > label {
      font-size: 12px;
      margin-bottom: 8px;
      display: inline-block;
    }
  }
}
</style>
