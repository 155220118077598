<template>
  <!-- TODO: change styling to new style -->
  <!-- TODO: add disabled state -->
  <!-- z-20 added specifically for the different rates page because the buttons overlapped the component when open -->
  <combobox-root v-model="selectedOption"
                 :display-value="(v) => v.label"
                 :filter-function="filterFunction"
                 :class="[{'z-20' : comboboxOpen}, 'flex relative']"
                 :disabled
                 @update:open="(value) => setComboboxOpenValue(value)">

    <div v-if="prependIcon"
         :class="['flex justify-center items-center w-10 h-10 bg-blue rounded-l border border-neutral border-solid',
                  {'rounded-bl-none': comboboxOpen, 'bg-neutral-700': disabled }]">
      <p-icon :icon-name="prependIcon"
              color="white"
              :border-radius="false" />
    </div>

    <combobox-anchor :class="['flex flex-1 justify-between pl-4 bg-white rounded border border-neutral border-solid',
                              {'rounded-l-none border-l-0': prependIcon,
                               'rounded-b-none': comboboxOpen}]">

      <combobox-input class="w-full focus:outline-none" :placeholder="placeholderText"/>

      <combobox-trigger class="flex justify-center items-center w-10">
        <p-icon :class="['transition-all', {'rotate-180': comboboxOpen}]" color="black"
                icon-name="chevron" size="small"/>
      </combobox-trigger>
    </combobox-anchor>

    <combobox-content class="absolute overflow-hidden w-full mt-10 max-h-64
                           bg-white border border-t-0 border-neutral border-solid rounded-b"
                      :class="{'!hidden': (!comboboxOpen && forceMountComponent)}"
                      :force-mount="forceMountComponent">
      <combobox-viewport class="text-center">
        <combobox-empty class="combobox-empty">Geen resultaten gevonden</combobox-empty>

        <combobox-group>
          <combobox-item v-for="(option, index) in options"
                         :key="index"
                         class="flex items-center pl-3 h-10 
                              data-[highlighted]:bg-blue data-[highlighted]:text-white
                              data-[disabled]:text-neutral-700 data-[disabled]:pointer-events-none"
                         :value="option">
            <span class="text-left">{{ option.label }}</span>
          </combobox-item>
        </combobox-group>
      </combobox-viewport>
    </combobox-content>

  </combobox-root>
</template>

<script lang="ts" setup>
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxGroup,
  ComboboxInput,
  ComboboxItem,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport
} from 'radix-vue';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<{
  modelValue: ComboBoxOption
  options: ComboBoxOption[]
  prependIcon?: string
  placeholderText?: string
  disabled?: boolean
}>(), {
  prependIcon: '',
  placeholderText: 'Selecteer of zoek een optie',
  disabled: false
});

const emit = defineEmits(['update:modelValue', 'value:selected']);

const selectedOption = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit('update:modelValue', newVal);
    emit('value:selected');
  }
});

// When a lot of items are entered as options, opening the combobox becomes quite slow because 
// it renders all dropdown items fresh. By force mounting, 
// all items are present in the DOM and are hidden/shown based on the open state, increasing performance
const forceMountComponent = computed(() => {
  if (props.options?.length > 30) return true
  
  return false;
})

const filterFunction = (list: ComboBoxOption[], searchTerm: string) => {
  return list.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));
}

const comboboxOpen = ref(false);
const setComboboxOpenValue = (value: boolean) => { comboboxOpen.value = value; };
</script>
