<template>
  <div v-if="organisation" class="organisation__details">
    <div class="organisation__titlerow">
      <p-heading variant="secondary" font-size="lg">
        {{ organisation?.organisation_name }}
      </p-heading>
      <p-toggle v-model="isOrganisationLinked" colored
                :input-id="organisation?.organisation_name ?? 'organisation-toggle'"/>
    </div>
  </div>
  <div class="organisation__departments">
    <p-heading class="organisation-departments__heading"
               variant="secondary"
               font-size="md">Afdeling</p-heading>
    <div v-if="departments.length" class="department-list">
      <div v-for="(department, i) in departments"
           :key="department.department_id">
        <organisation-department-item v-model:department="departments[i]" />
        <p-ruler v-if="i < departments.length - 1" />
      </div>
    </div>
    <div v-else class="organisation__spinner">
      <p-icon class="spinner__icon"
              icon-name="spinner"
              size="large"
              color="blue"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { OrganisationStatus } from '@pidz/enums';
import { CancelModal } from '@pidz/shared/components'
import { rerouteToOverview } from '@pidz/shared/utils';
import { useClientsStore, useToaster } from '@pidz/stores';
import { useModalController } from '@pidz/utils'
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { OrganisationDepartmentItem } from '..';

const props = defineProps<{
  isLoading: boolean;
  isDirty: boolean;
}>();

const router = useRouter();
const route = useRoute();
const clientsStore = useClientsStore();
const departments = ref<Department[]>([]);
const orgId = Number(route.params.id);
const organisation = ref<Organisation>();
const isOrganisationLinked = computed({
  get: () => organisation?.value?.status === OrganisationStatus.LINKED || false,
  set: (value: boolean) => {
    if (!organisation.value) return false;
    organisation.value.status = value ? OrganisationStatus.LINKED : OrganisationStatus.BLOCKED;
  },
});

const allDepartmentsAreEqual = computed(() =>
  departments.value.every((dep) => dep.status === departments.value[0].status),
);
const someDepartmentsAreLinked = computed(() =>
  departments.value.some((dep) => dep.status === OrganisationStatus.LINKED),
);
const someDepartmentsAreBlocked = computed(() =>
  departments.value.some((dep) => dep.status === OrganisationStatus.BLOCKED),
);

const emit = defineEmits([
  'update:is-dirty',
  'update:is-loading',
]);

onMounted(async () => {
  const organisationFromStore = clientsStore.organisations?.find((org) => org.organisation_id === orgId)
  if (!organisationFromStore) return rerouteToOverview(router, 'travel-time-clients');
  organisation.value = {...organisationFromStore};
  departments.value = [...await clientsStore.getDepartmentsByOrganisationId(orgId)];
});

watch(isOrganisationLinked, (isLinked: boolean) => {
  if (!departments.value.length) return;
  if (isLinked && !allDepartmentsAreEqual.value && someDepartmentsAreBlocked.value) return;
  departments.value = departments.value.map((dep) => 
  ({...dep, status: isLinked ? OrganisationStatus.LINKED : OrganisationStatus.BLOCKED}));
});

watch(departments,() => {
    if (allDepartmentsAreEqual.value) {
      isOrganisationLinked.value = departments.value[0].status === OrganisationStatus.LINKED;
    }
    if (someDepartmentsAreLinked.value) {
      isOrganisationLinked.value = true;
    }

    const departmentsHaveChanged = departments.value.some((dep) => {
      const deptFromStore = clientsStore.departmentsByOrganisationId[orgId]?.find((d) => 
        d.department_id === dep.department_id);
      return deptFromStore?.status !== dep.status;
    });
    emit('update:is-dirty', departmentsHaveChanged);
  },
  { deep: true },
);

const onSubmit = async () => {
  emit('update:is-loading', true);
  if (allDepartmentsAreEqual.value) {
    await clientsStore.updateOrganisationBlocked(orgId, departments.value[0].status === OrganisationStatus.BLOCKED);
  } else await clientsStore.updateLinkedDepartments(departments.value, orgId);
  useToaster().addToaster(
    {
      type: 'success',
      title: 'Gelukt!',
      message: 'Succesvol opgeslagen',
    },
    5000,
  );
  emit('update:is-loading', false);
  emit('update:is-dirty', false);
  rerouteToOverview(router, 'travel-time-clients');
};

const onCancel = () => {
  const modal = useModalController();
  if (props.isDirty) {
    modal.open(CancelModal, {
      cancelEdit: () => rerouteToOverview(router, 'travel-time-clients'),
      continueEdit: () => modal.close()
    });
  } else {
    rerouteToOverview(router, 'travel-time-clients');
  }
};

defineExpose({
  onSubmit,
  onCancel
});
</script>

<style lang="scss" scoped>
.organisation {
  &__details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: var(--pidz-white);
  }
  &__titlerow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__departments {
    padding: 0 16px;
    background-color: var(--pidz-white);
  &__heading {
      margin-bottom: 8px;
    }
  }
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    animation: spin 1s linear infinite;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
