import { checkFreelancerData } from './navigation-guards';

export default [
  {
    name: 'profile',
    path: '/settings/profile',
    redirect: { name: 'profile overview' },
    children: [
      {
        name: 'profile overview',
        path: '/settings/profile',
        component: () => import('@app/pages/settings/profile/ProfileOverviewPage.vue'),
        meta: { title: 'Profiel' },
        beforeEnter: [checkFreelancerData],
      },
      {
        name: 'profile personal edit',
        path: '/settings/profile/personal/edit',
        component: () => import('@app/pages/settings/profile/sections/personal/EditPersonalPage.vue'),
        meta: { 
          title: 'Persoonsgegevens bewerken',
          backButton: true,
        },
        beforeEnter: [checkFreelancerData],
      },
      {
        name: 'profile address edit',
        path: '/settings/profile/address/edit',
        component: () => import('@app/pages/settings/profile/sections/address/EditAddressPage.vue'),
        meta: { 
          title: 'adresgegevens bewerken',
          backButton: true,
        },
        beforeEnter: [checkFreelancerData],
      },
      {
        name: 'profile contact edit',
        path: '/settings/profile/contact/edit',
        component: () =>
          import('@app/pages/settings/profile/sections/contact/EditContactPage.vue'),
        meta: { 
          title: 'Contactgegevens bewerken',
          backButton: true,
        },
        beforeEnter: [checkFreelancerData],
      },
      {
        name: 'profile bank edit',
        path: '/settings/profile/bank/edit',
        component: () => import('@app/pages/settings/profile/sections/bank/EditBankPage.vue'),
        meta: { 
          title: 'Bankgegevens bewerken',
          backButton: true,
        },
        beforeEnter: [checkFreelancerData],
      },
      {
        name: 'profile company edit',
        path: '/settings/profile/company/edit',
        component: () => import('@app/pages/settings/profile/sections/company/EditCompanyPage.vue'),
        meta: { 
          title: 'Bedrijfsgegevens bewerken',
          backButton: true,
        },
        beforeEnter: [checkFreelancerData],
      },
    ]
  }
]
