<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section class="date-range-filter">
    <h3 class="my-4 font-bold">Datum van/tot</h3>
    <date-range v-model="value"></date-range>
  </section>
</template>
<script lang="ts" setup>

import { computed } from 'vue';
import DateRange from '../DateRange.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue:any,
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

</script>
