import { initializeApp as realInitializeApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import {
  getRemoteConfig as realGetRemoteConfig,
  fetchAndActivate as realFetchAndActivate,
  RemoteConfig,
  getString as realGetString,
} from 'firebase/remote-config';

const { fitPlaywrightEnabled: isPlaywrightEnabled } = window as any;

export const initializeApp = (options: FirebaseOptions): FirebaseApp => {
  if (!isPlaywrightEnabled) { return realInitializeApp(options); }
  return {
    name: '[DEFAULT]',
    automaticDataCollectionEnabled: false,
    options: {},
  };
};

export const getRemoteConfig = (app?: FirebaseApp): any => {
  if (!isPlaywrightEnabled) { return realGetRemoteConfig(app); }
  return {
    defaultConfig: {},
    settings: {},
  };
};

export const fetchAndActivate = async (config: RemoteConfig) => {
  if (!isPlaywrightEnabled) { return realFetchAndActivate(config); }
  return new Promise((resolve) => { resolve(true); });
};

export const getString = async (config: RemoteConfig, key: string) => {
  if (!isPlaywrightEnabled) {
    return realGetString(config, key);
  }
  return JSON.stringify(JSON.parse(config.defaultConfig as any)[key]) || '';
};
