<template>
  <svg width="48px" height="48px"
       viewBox="0 0 48 48" version="1.1"
       xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <g id="Group" transform="translate(1 1)">
      <path d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z" id="Oval"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2" />
      <path d="M19.4615 18.5769C19.4615 16.1341 17.4813 14.1538 15.0385 14.1538C12.5957 14.1538 10.6154 16.1341 10.6154 18.5769" id="Path"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
      <path d="M35.3846 18.5769C35.3846 16.1341 33.4043 14.1538 30.9615 14.1538C28.5187 14.1538 26.5385 16.1341 26.5385 18.5769" id="Path-Copy"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
      <path d="M31.8462 28.3077C31.8462 33.1933 27.8856 37.1538 23 37.1538C18.1144 37.1538 14.1538 33.1933 14.1538 28.3077L31.8462 28.3077Z" id="Path-Copy-2"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
</template>
