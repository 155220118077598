/* eslint-disable max-lines */
import {
  dashboardService,
  profileService,
  userService
} from '@pidz/api';
import { formatYmd } from '@pidz/date';
import { UserType } from '@pidz/enums';
import { useClientsStore } from '@pidz/stores';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { permissionMap } from '../utils/userPermissions';

export default defineStore('user', () => {
  const profilePicture= ref<ProfilePicture | null> (null);
  const user = ref<CurrentUser | null>(null);
  const permissions = ref<PagePermission[]>()
  const freelancer = ref<Freelancer>();
  const inactiveTaskList= ref<string[]>();
  const impersonationId = ref<string | null>(null);
  const clientsStore = useClientsStore()
  const userType = ref<UserType | null>(null);

  // getters
  // only works on native app
  const isUserAdmin = computed(() => user.value?.user_type === UserType.ADMIN)
  const isUserFreelancer = computed(() => freelancer.value?.user.user_type === UserType.FREELANCER);
  const isUserAgencyWorker = computed(() => freelancer.value?.user.user_type === UserType.AGENCY_WORKER);

  // only works on zzp2 web app
  const isImpersonated = computed(() => Cookies.get('pidz_rpc_assume_user') !== undefined);

  // actions
  const fetchFreelancerData = async (force = false) => {
    if(force || !inactiveTaskList.value) inactiveTaskList.value = await dashboardService.getInactiveTaskList();
    if (force || !profilePicture.value) {
      const croppedProfilePicture = await userService.getCroppedProfilePicture();
      profilePicture.value = croppedProfilePicture.path ? 
      croppedProfilePicture : 
      await userService.getProfilePicture();
    }    
    if (force || !freelancer.value) {
      freelancer.value = await profileService.getProfile();
      await fetchMaxWorkRange()
    }
  };

  const updateFreelancerData = async (model: Freelancer) => {
    let updatedFreelancer;

    if (isUserAgencyWorker.value) {
      updatedFreelancer = await profileService.setAgencyWorkerProfile(model);
    } else {
      updatedFreelancer = await profileService.setProfile(model);
    }

    freelancer.value = updatedFreelancer;
  };

  const updateAutomaticPaymentChoice = async (choice: 'ideal' | 'incasso' | 'none') => {
    await profileService.updateAutomaticPaymentChoice(choice);
  }

  const setUserPermissions = (type: UserType) => {
    if(!user.value) return
    permissions.value = permissionMap.get(type) || []
  }

  const getCurrentUser = async () => {
    if (!user.value) {
      try {
        user.value = await userService.getCurrentUser();
        userType.value = user.value!.user_type;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    if(user.value?.user_type){
      setUserPermissions(user.value!.user_type)
    }
    return user.value;
  };

  const isFreelancer = async () => {
    user.value = await getCurrentUser();

    if (!user.value) {
      return false;
    }

    return user.value.user_type === 'freelancer';
  };
  
  // TODO: contemplate moving maxworkrange and department/organisation count to clients store
  const maxWorkRange: Ref<number | null> = ref(null);
  const fetchMaxWorkRange = async (): Promise<number | null> => 
    maxWorkRange.value = await userService.getMaxWorkRange();
  
  //TODO: Old version use fetch if not already loaded and computed on ref
  const getMaxWorkRange = async (): Promise<number | null> => {
    if (!maxWorkRange.value) {
      maxWorkRange.value = await userService.getMaxWorkRange();
    }
    return maxWorkRange.value;
  };

  const updateMaxWorkRange = async (value: number) => {
    await userService.updateMaxWorkRange(value);
    maxWorkRange.value = value;
    departmentCountWithinRange.value = undefined
    organisationCountWithinRange.value = undefined
    fetchOrganisationCountWithinRange(value)
    fetchDepartmentCountWithinRange(value)
    clientsStore.fetchOrganisations()
  };
  
  const departmentCountWithinRange = ref<number | undefined>(undefined);
  const fetchDepartmentCountWithinRange = 
  async (maxTravelTime: number, preview: boolean = false): Promise<number | void> => {
    if(!preview && (maxTravelTime !== maxWorkRange.value || !departmentCountWithinRange.value)){
      departmentCountWithinRange.value = await userService.getDepartmentCountWithinRange(maxTravelTime);
    }
    if(preview) return await userService.getDepartmentCountWithinRange(maxTravelTime);
  }

  const organisationCountWithinRange = ref<number | undefined>(undefined);
  const fetchOrganisationCountWithinRange = async (maxTravelTime: number): Promise<void> => {
    if(maxTravelTime !== maxWorkRange.value || !organisationCountWithinRange.value){
      organisationCountWithinRange.value = await userService.getOrganisationCountWithinRange(maxTravelTime);
    } 
  }

  const resetPassword = async (request) => {
    return await userService.resetPassword(request);
  };

  const getFreelancerFunctions = async () => {
    return await profileService.getFreelancerFunctions();
  };

  const updateEmailPreferences = async (value: boolean) => {
    if(freelancer.value?.invite_by_email !== undefined) freelancer.value.invite_by_email = value;
    return await profileService.updateEmailPreferences(value);
  }

  const getPagePermission = (routeName: string): boolean => {
    if(!permissions.value) return true
    const userPermission = permissions.value.find(permission => permission.name === routeName)
    if(!userPermission) return true
    return userPermission.view
  }

  const getFullName = (): string => {
    if (freelancer.value) {
      const { first_name, insertion, last_name } = freelancer.value!.user;
      return `${first_name} ${insertion || ''} ${last_name}`;
    }
    return '-';
  };

  const getFreelancerBSN = () => {
    if (freelancer.value?.has_national_identification_number) {
        return freelancer.value.national_identification_number ?? '*'.repeat(9);
    }
    return '';
  };

  // TODO: move functions to separate file
  const getBankDetails = (): ProfileBank => ({
    iban: ( isUserFreelancer.value ? freelancer.value?.iban_organisation : freelancer.value?.iban_personal) || '-',
    bic: ( isUserFreelancer.value ? freelancer.value?.bic_organisation : freelancer.value?.bic_personal) || '-',
    name: ( isUserFreelancer.value ? freelancer.value?.name_of_organisation : freelancer.value?.name_bank_account) || '-',
    automaticIncasso: freelancer.value?.incasso_organisation || false 
  });

  const getContactDetails = (): ProfileContact => ({
    phone: freelancer.value?.telephone_number || '-',
    mobile: freelancer.value?.mobile_phone_number || '-',
    email: (isUserFreelancer.value ? freelancer.value?.email_freelancer : freelancer.value?.email) || '-',
    // email: freelancer.value?.email_freelancer || '-',
    ...isUserAgencyWorker.value && { 
      emergencyContact: freelancer.value?.emergency_contact || '-',
      emergencyPhone: freelancer.value?.emergency_phone || '-'
    }
  });

  const getPersonalDetails = (): ProfilePersonal => ({
    firstName: freelancer.value?.user.first_name ?? '-',
    lastName: freelancer.value?.user.last_name ?? '-',
    insertion: freelancer.value?.user.insertion ?? '',
    initials: freelancer.value?.initials ?? '',
    fullName: getFullName(),
    birthdate: formatYmd(DateTime.fromISO(freelancer.value?.birth_date || '')),
    nationality: freelancer.value?.nationality ?? '-',
    nationalIdentificationNumber: getFreelancerBSN(),
    gender: freelancer.value?.sex ?? '-',
    ...isUserAgencyWorker.value && { 
      maritalStatus: freelancer.value?.marital_status || '-',
      birthCountry: freelancer.value?.birth_country || '-',
      birthPlace: freelancer.value?.birth_place || '-'
    }
  });

  const getAddress = (): ProfileAddress => ({
    street: (isUserFreelancer.value ? freelancer.value?.street_freelancer : freelancer.value?.street) ?? '',
    houseNumber: (isUserFreelancer.value ? freelancer.value?.house_number_freelancer : freelancer.value?.house_number) ?? '',
    zipCode: (isUserFreelancer.value ? freelancer.value?.zip_code_freelancer : freelancer.value?.zip_code) ?? '',
    city: (isUserFreelancer.value ? freelancer.value?.city_freelancer : freelancer.value?.city)?? '',
    country: (isUserFreelancer.value ? freelancer.value?.country_freelancer : freelancer.value?.country) ?? '',
    poBox: freelancer.value?.po_box_freelancer ??'',
  });

  const getBusinessAddress = (): ProfileAddress => ({
    street: freelancer.value?.organisation.street ?? '',
    houseNumber: freelancer.value?.organisation.house_number ?? '',
    zipCode: freelancer.value?.organisation.zip_code ?? '',
    city: freelancer.value?.organisation.city ?? '',
    country: freelancer.value?.organisation.country ?? '',
    poBox: freelancer.value?.organisation.po_box ?? '',
  });

  const getCompanyDetails = (): ProfileCompany => ({
    cocNumber: freelancer.value?.coc_number || '-',
    companyName: freelancer.value?.organisation.name || '-',
    website: freelancer.value?.website || '',
    vatNumber: freelancer.value?.vat_number || '-',
    vatFree: freelancer.value?.vat_free || false,
    invoiceTemplate: freelancer.value?.invoice_template || undefined,
  });

  const getFreelancerInfoForProfile = computed<FreelancerProfile | undefined>(
    () => {
      if (!freelancer.value) return undefined;

      return {
        personal: getPersonalDetails(),
        address: getAddress(),
        businessAddress: getBusinessAddress(),
        contact: getContactDetails(),
        bank: getBankDetails(),
        company: getCompanyDetails(),
      };
    },
  );
    
  const isBusinessAddressDifferent = computed(() => {
    if (!freelancer.value) return undefined;
    for (const [key, value] of Object.entries(getFreelancerInfoForProfile.value?.address || [])) {
      if (getFreelancerInfoForProfile.value?.businessAddress[key] !== value) return true;
    }
  
    return false;
  });

  const getCoCOrganisation = async (cocNumber: string) => {
    return await profileService.getCoCOrganisation(cocNumber);
  };

  const $reset = () => {
    profilePicture.value = null;
    user.value = null;
    maxWorkRange.value = null;
    freelancer.value = undefined;
    inactiveTaskList.value = undefined;
  };

  return {
    // state
    profilePicture,
    user,
    userType,
    maxWorkRange,
    freelancer,
    permissions,
    inactiveTaskList,
    isBusinessAddressDifferent,
    impersonationId,
    departmentCountWithinRange,
    organisationCountWithinRange,

    // getters
    isUserAdmin,
    isUserFreelancer,
    isUserAgencyWorker,
    isImpersonated,
    // getDepartmentCountWithinRange,
    // getOrganisationCountWithinRange,
    getFullName,
    getFreelancerFunctions,
    getFreelancerInfoForProfile,
    getBankDetails,
    getContactDetails,
    getAddress,
    getBusinessAddress,
    getPersonalDetails,
    getCompanyDetails,
    getCoCOrganisation,
    getMaxWorkRange,
    getPagePermission,

    // actions
    fetch,
    getCurrentUser,
    updateAutomaticPaymentChoice,
    isFreelancer,
    resetPassword,
    updateMaxWorkRange,
    fetchDepartmentCountWithinRange,
    fetchOrganisationCountWithinRange,
    fetchMaxWorkRange,
    fetchFreelancerData,
    updateFreelancerData,
    updateEmailPreferences,
    setUserPermissions,

    $reset,
  };
});
