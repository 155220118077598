<template>
  <div v-for="(type, index) in Object.keys(assets)" :key="index"
       class="asset">
    <p-ruler margin-bottom="24px" />
    <p-expanded-picker class="asset__picker"
                       :new-files="newFiles[type]"
                       :existing-files="existingFiles[type]"
                       :title="titleMap.get(type)"
                       :disabled="isDisabled"
                       :show-help-text="false"
                       @upload="(files: File[]) => onAddFile(files, type)"
                       @delete="(file: File | Asset) => onDeleteFile(file, type)"
                       @download="onDownloadFile"/>
    <p v-if="showError(type)" class="asset__error">
      {{ `Het is verplicht een ${titleMap.get(type)} toe te voegen` }}
    </p>

    <span v-if="newFiles[type].length > 0"
          class="asset__valid-until date__container">
      <p-label :input-id="`valid-until__${type}`" :label="'Geldig tot'" />
      <span class="date__input">
        <p-input-prepend>
          <p-icon :icon-name="'agenda'"
                  color="white"
                  :border-radius="false"/>
        </p-input-prepend>
        <p-input v-model="validUntil[type]"
                 input-type="date"
                 :input-id="`valid-until__${type}`"
                 :has-border-radius-left="true"
                 :has-no-left-border="true"
                 :min="formatYmd(DateTime.now())"/>
      </span>
      <span v-if="validUntil[type] === ''" class="asset__error">Vul een geldige verloopdatum in</span>
    </span>
  </div>
</template>
<script lang="ts" setup>
import { addDuration, createDurationObject, formatYmd } from '@pidz/date';
import { getMimeType } from '@pidz/shared/utils';
import { useAssetStore, useUserStore } from '@pidz/stores';
import { openFile } from '@pidz/utils';
import { DateTime } from 'luxon';
import { ref } from 'vue';

const props = defineProps<{
  assets: ProfileAssetsPersonal;
  isWeb?: boolean;
  isEdit?: boolean;
  isDisabled?: boolean;
}>();

const assetStore = useAssetStore();
const userStore = useUserStore();

const existingFiles = ref<ProfileAssetsPersonal>({
  vogDocument: props.assets.vogDocument,
  ...userStore.isUserFreelancer && 
    { insuranceDocumentPaymentProof: props.assets.insuranceDocumentPaymentProof },
  ...userStore.isUserFreelancer && 
    { insuranceDocument: props.assets.insuranceDocument },
});

const newFiles = ref<ProfileAssetsPersonal>({
  vogDocument: [],
  ...userStore.isUserFreelancer && 
    { insuranceDocumentPaymentProof: [] },
  ...userStore.isUserFreelancer && 
    { insuranceDocument: [] },
});

const deletedFiles = ref<Asset[]>([]);

const validUntil = ref<ValidDatePersonalAssets>({
  vogDocument:
    formatYmd(addDuration(DateTime.now(), createDurationObject({ year: 1 }))) ||
    '',
  insuranceDocument:
    formatYmd(addDuration(DateTime.now(), createDurationObject({ year: 1 }))) ||
    '',
  insuranceDocumentPaymentProof:
    formatYmd(addDuration(DateTime.now(), createDurationObject({ year: 1 }))) ||
    '',
});

const titleMap = new Map<string, string>([
  ['vogDocument', 'Verklaring omtrent gedrag'],
  ['insuranceDocument', 'Polisblad aansprakelijkheidsverzekering'],
  [
    'insuranceDocumentPaymentProof',
    'Betaalbewijs aansprakelijkheidsverzekering',
  ]
]);

const showError = (type: string): boolean => 
  newFiles.value[type]?.length === 0 && 
  existingFiles.value[type]?.length < 1 && 
  type !== 'insuranceDocumentPaymentProof'

const onAddFile = (files: File[], type: string) => newFiles.value[type] = [...newFiles.value[type], ...files];

const onDeleteFile = (file: File | Asset, type: string) => {
  if ('id' in file && file.id && props.isEdit) {
    existingFiles.value[type] = existingFiles.value[type].filter(
      (f: Asset) => f.id !== file.id,
    );
    deletedFiles.value = [...deletedFiles.value, file];
  } else {
    newFiles.value[type] = newFiles.value[type].filter((f: File) => f !== file);
  }
};

const onDownloadFile = async (file: File | Asset) => {
  if ('id' in file && file.id) {
    const response = await assetStore.fetchAsset(file.id);
    const mimeType = getMimeType(file.filename)
    if(mimeType) openFile(response, mimeType);
  }
};

defineExpose({ deletedFiles, newFiles, existingFiles, validUntil });
</script>
<style scoped lang="scss">
.asset {
  font-size: 14px;
  &__picker,
  &__invoice-item,
  &__title,
  &__valid-until,
  &__error{
    margin-bottom: 24px;
  }
  &__error {
    color: red;
  }
}
.date {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__input {
    display: flex;
  }
}
</style>
