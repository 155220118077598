<template>
  <div class="flex flex-col w-full bg-blue md:bg-white text-white md:text-neutral
              px-4 pb-2 md:py-4 md:border-b border-neutral-500">
    <h1 class="font-bold text-base break-words">{{ sheet.organisation_name }}</h1>
    <h2 class="text-sm break-words">{{ sheet.department_name }}</h2>
  </div>    
  <layout-desktop-wrapper  class="sheet-edit-page">
    <reload-page :reload="reload"
                 :loading="loading"/>


    <div v-if="blocks.workingHours" class="blocks">
      <sheet-block-working-hours v-model="blocks.workingHours"
                                 :disabled="!isPeriodical"
                                 :other-work-hour-blocks="blocks.otherWorkHours"/>
      <sheet-block-function v-if="sheet && sheet.periodical_shifts?.length > 1 && functionOptions"
                            v-model="blocks.workingHours.shift_id"
                            :function-options="functionOptions"/>
      <template v-if="!isPeriodical">
        <sheet-block-over-time v-model="blocks.overTimes"
                               :working-hours-block="blocks.workingHours"
                               :shift-id="shiftId"/>
        <sheet-block-under-time v-model="blocks.underTimes"
                                :working-hours-block="blocks.workingHours"
                                :shift-id="shiftId"/>
      </template>
      <sheet-block-break v-model="blocks.breaks" :working-hours-block="blocks.workingHours"
                         :shift-id="shiftId" />
      <sheet-block-sleep v-if="allowSleep" v-model="blocks.sleeps"
                         :working-hours-block="blocks.workingHours" :shift-id="shiftId" />
      <sheet-block-commute v-if="allowTravel && commuteContext" v-model="commutes"
                           :context="commuteContext" />
      <remove-sheet-row v-if="blocks.workingHours && sheet?.has_periodical"
                        :working-hours="blocks.workingHours"/>
    </div>
    <form-buttons-row :on-cancel="backToSheet"
                      :on-submit="save"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import router from '@app/router';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { SheetBlockType } from '@pidz/enums'
import { useSheetStore } from '@pidz/stores';
import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import RemoveSheetRow from './components/RemoveSheetRow.vue';
import SheetBlockBreak from './components/SheetBlockBreak.vue';
import SheetBlockCommute from './components/SheetBlockCommute.vue';
import SheetBlockFunction from './components/SheetBlockFunction.vue';
import SheetBlockOverTime from './components/SheetBlockOverTime.vue';
import SheetBlockSleep from './components/SheetBlockSleep.vue';
import SheetBlockUnderTime from './components/SheetBlockUnderTime.vue';
import SheetBlockWorkingHours from './components/SheetBlockWorkingHours.vue';
import {
  addSheetBlocks,
  addWorkingHours,
  editSheetBlocks,
  editWorkingHours,
  groupBlocks,
  hasFunctionBeenEditted,
  removeSheetBlocks,
} from './utils/sheet-save-utils';

const initialLoad = ref(true)
const loading = ref(false);

const route = useRoute();
const sheetStore = useSheetStore();
const sheetRow = ref<SheetRowModel | null>(null);
const sheetId = computed(() => Number(route.params.id));
const sheet = computed(() => sheetStore.sheets[sheetStore.sheets.findIndex(
  element => element.sheet_id === sheetId.value,
)]);
const isPeriodical = computed(() => sheet.value?.has_periodical);
const shiftId = computed(() => (isPeriodical.value
  ? sheet.value!.periodical_shifts[0].id!
  : sheetRow.value!.shift_id!));
const allowTravel = computed(() => sheet.value?.allow_travel_costs);
const allowSleep = computed(() => (isPeriodical.value
  ? sheet.value?.has_sleep_shift
  : sheetRow.value?.is_sleep_shift));

const functionOptions = computed(() => sheet.value?.periodical_shifts.map(({ function_name, id }) => ({
  name: function_name,
  value: id,
})));

const backToSheet = () => router.back();

const commuteContext = computed(() => {
  if (!(sheet.value && sheetRow.value)) { return null; }
  const { uuid: sheetUuid } = sheet.value;
  const { shift_uuid, work_uuid } = sheetRow.value;
  return {
    sheetUuid,
    assignmentUuid: shift_uuid,
    workUuid: work_uuid,
    sheetRowUuid: work_uuid,
  };
});

const commutes = ref<Commute[]>([]);
const originalCommutes = ref<Commute[] | null >([]);

const blocks = reactive<{[key:string]: any|null}>({
  workingHours: null,
  otherWorkHours: [],
  overTimes: [],
  underTimes: [],
  breaks: [],
  sleeps: [],
});

const reload = async () => {
loading.value = true;  
await sheetStore.getSheetById(sheetId.value);

  if (!sheet.value) {
    return;
  }
  
  const sheetUuid = sheet.value.uuid;
  originalCommutes.value = await sheetStore.getCommutes(sheetUuid);
  if (originalCommutes.value !== null) {
    commutes.value = originalCommutes.value.slice();
  }
  

  if (route.params.sheetRowIndex) {
    const rowIndex = Number(route.params.sheetRowIndex);
    sheetRow.value = { ...sheet.value.sheet_row_models[rowIndex] };
    
    const sheetBlocks = sheetRow.value.sheet_block_models;
    Object.assign(blocks, {
      workingHours:
        sheetBlocks.find((b) => b.type === SheetBlockType.TYPE_WORKING_HOURS)
        ?? null,
      overTimes: sheetBlocks.filter(
        (b) => b.type === SheetBlockType.TYPE_OVERTIME,
      ),
      underTimes: sheetBlocks.filter(
        (b) => b.type === SheetBlockType.TYPE_UNDERTIME,
      ),
      breaks: sheetBlocks.filter((b) => b.type === SheetBlockType.TYPE_BREAK),
      sleeps: sheetBlocks.filter((b) => b.type === SheetBlockType.TYPE_WORK_DURING_SLEEP),
      otherWorkHours: sheet.value.sheet_row_models
        .filter((_, i) => i !== rowIndex)
        .flatMap((row) => row.sheet_block_models)
        .filter((block) => block.type === SheetBlockType.TYPE_WORKING_HOURS),
    });
      } else {
    blocks.workingHours = {
      shift_id: sheet.value.periodical_shifts[0].id,
      type: SheetBlockType.TYPE_WORKING_HOURS,
    };
  }

  initialLoad.value = false;
  loading.value = false;
};

const cleanCommute = (c:any) => {
  const { temp_id, sheet_block_id, ...rest } = c;
  return rest;
};

const commutesToCreate = () => commutes.value.filter((o) => o.uuid === undefined)
  .map(cleanCommute);

const commutesToUpdate = () => commutes.value.filter((c) => {
  const other = originalCommutes.value?.find((o) => o.uuid === c.uuid);
  return other !== undefined && JSON.stringify(other) !== JSON.stringify(c);
}).map(cleanCommute);

const commutesToRemove = () => {
  if (originalCommutes.value !== null) {
    return originalCommutes.value.filter((c) => !commutes.value.find((o) => o.uuid === c.uuid))
      .map(cleanCommute);
  } else {
    return [];
  }
};

const save = async () => {
  const { otherWorkHours, ...sheetBlocks } = blocks;

  const groupedSheetBlocks = groupBlocks<SheetBlockModel>(
    Object.values(sheetBlocks).flat() as unknown as SheetBlockModel[],
    sheetRow.value?.sheet_block_models ?? [],
    'sheet_block_id',
  );

  const addedWorkingHours = groupedSheetBlocks.added.find(
    (block) => block.type === SheetBlockType.TYPE_WORKING_HOURS,
  );
  const addedSheetBlocks = groupedSheetBlocks.added.filter(
    (block) => block.type !== SheetBlockType.TYPE_WORKING_HOURS,
  );
  const edittedWorkingHours = groupedSheetBlocks.editted.find(
    (block) => block.type === SheetBlockType.TYPE_WORKING_HOURS,
  );
  const edittedBlocks = groupedSheetBlocks.editted.filter(
    (block) => block.type !== SheetBlockType.TYPE_WORKING_HOURS,
  );
  const freelancerFunctionHasBeenUpdated = edittedWorkingHours
    && hasFunctionBeenEditted(edittedWorkingHours, sheetRow.value);

    if (freelancerFunctionHasBeenUpdated) {
    await sheetStore.updateSheetBlock(edittedWorkingHours);
  }

  await Promise.all([
    Promise.all(commutesToCreate().map(async (c) => sheetStore.addCommute(c))),
    Promise.all(commutesToUpdate().map(async (c) => sheetStore.updateCommute(c))),
    Promise.all(commutesToRemove().map(async (c) => sheetStore.removeCommute(c))),
  ]);

  await removeSheetBlocks(groupedSheetBlocks.removed);
  await editWorkingHours(edittedWorkingHours);
  await editSheetBlocks(edittedBlocks);
  await addWorkingHours(addedWorkingHours, sheetId.value);
  await addSheetBlocks(addedSheetBlocks, sheetId.value);
  logCustomEvent('sheet_row_save');
  backToSheet();
};

onMounted(() => {
  reload();
});
</script>
<style lang="scss">
.sheet-edit-page {
  width: 100%;
  .blocks {
    box-shadow: var(--shadow-light);
    & > section {
      background: var(--pidz-white);
      padding: 24px 12px;
      border-bottom: 1px solid gray;
      &:last-child {
        border: 0;
      }
    }
  }
}
</style>
