import { calendarService } from '@pidz/api';
import { getMonthRange, isSame } from '@pidz/date';
import { collidesWithDay, mapCalendarEventsToLuxon } from '@pidz/utils';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';

export default defineStore('calendar', () => {

  interface CachedEventRanges {
    month: number,
    year: number,
    events: CalendarEventReponse
  }

  // state
  const date: Ref<DateTime> = ref(DateTime.now().startOf('day'));
  const events: Ref<CalendarEvent[]> = ref([]);
  const cachedEventsRanges= ref<CachedEventRanges[]>([]);
  const eventsLoading: Ref<boolean> = ref(false);
  const isDrawerOpen: Ref<boolean> = ref(false);

  // getters
  const isDateThisYear = computed(() => date.value.year === DateTime.now().year);

  const dateRange = (includingFullLastDay: boolean) =>
    getMonthRange(date.value, includingFullLastDay);

  const getEventsByDay = (d: DateTime) =>
    events.value.filter((event: CalendarEvent) => collidesWithDay(d, event));

  const getEventById = (id: number) => events.value.find((e) => e.id === id);

  const getMonthDays = computed<CalendarDay[]>(() => {
    const { start } = getMonthRange(date.value, false);
    const output = Array.from({ length: 7 * 6 }).map((_, daynr) => {
      // const d = new Date(start.toJSDate());
      const d = start.plus({ days: daynr });
      const ymd = d.toFormat('EEE MMM dd y');
      return {
        date: d,
        name: d.toFormat('d'),
        classes: {
          day: true,
          selected: isSame(d, date.value, 'day'),
          disabled: d.month !== date.value.month,
          today: isSame(d, DateTime.now(), 'day'),
        },
        key: ymd,
        events: events.value.filter((e) => collidesWithDay(d, e)),
      };
    });
    return output;
  });

  // actions
  const loadEvents = async ({ start, end }) => {
    if (!start || !end) throw new Error('start and end must be provided');
    if (!DateTime.isDateTime(start) || !DateTime.isDateTime(end)) {
      throw new Error('start and end must be luxon DateTime objects');
    }

    const savedRange = cachedEventsRanges.value.find(
      (range) => range.month === date.value.month && range.year === date.value.year
    )

    if(savedRange){
      events.value = savedRange.events
      return;
    }

    try {
      events.value = [];
      eventsLoading.value = true;
      const e = await calendarService.getEvents(
        {
          start: start.toISO()!,
          end: end.toISO()!,
        },
      );

      const eventRange = {
        month: date.value.month,
        year: date.value.year,
        events: mapCalendarEventsToLuxon(e)
      }
      events.value = eventRange.events
      cachedEventsRanges.value = [...cachedEventsRanges.value, eventRange]

    } finally {
      eventsLoading.value = false;
    }
  };

  const setToday = () => {
    date.value = DateTime.now().startOf('day');
  };

  const setDate = (to: DateTime) => {
    date.value = to;
  };

  const clearCachedEventRange = () => cachedEventsRanges.value = []

  const addCalendarItem = async (calendarItemModel: {
    calendarItemModel: CalendarEventRequest;
  }): Promise<void> => {
    const response = await calendarService.addCalendarItem(calendarItemModel);
    const { calendarItemModel: calendarEvent } = calendarItemModel;

    if (calendarEvent.repeat) {
      cachedEventsRanges.value = []
      loadEvents(dateRange(true));
    }
    if (response) {
      response.all_day = Boolean(calendarEvent.all_day);
      response.start = DateTime.fromISO(response.start);
      response.end = DateTime.fromISO(response.end);
      events.value = [...events.value, response];

      cachedEventsRanges.value = cachedEventsRanges.value.map((range) => {
        if (range.month === date.value.month && range.year === date.value.year) {
          range.events = events.value
        }
        return range
      })
    }
  };

  const updateCalendarItem = async (calendarItemModel: {
    calendarItemModel: PersonalCalendarEvent;
  }): Promise<void> => {
    const response = await calendarService.updateCalendarItem(calendarItemModel);
    if (response) {
      const { calendarItemModel: calendarEvent } = calendarItemModel;
      const index = events.value.findIndex((e: CalendarEvent) => e.id === calendarEvent.id);
      calendarEvent.start = DateTime.fromISO(calendarEvent.start);
      calendarEvent.end = DateTime.fromISO(calendarEvent.end);
      events.value[index] = calendarEvent;

      cachedEventsRanges.value = cachedEventsRanges.value.map((range) => {
        if (range.month === date.value.month && range.year === date.value.year) {
          range.events = events.value
        }
        return range
      })
    }
  };

  const removeCalendarEvent = async (calendarId: number): Promise<void> => {
    await calendarService.removeAvailability({ calendarId });
    events.value = events.value.filter((e: CalendarEvent) => e.id !== calendarId);
  };

  const updateCalendarRecurrence = async (calendarItemModel: {
    calendarItemModel: PersonalCalendarEvent;
  }): Promise<void> => {
    await calendarService.updateCalendarItemRepeat(calendarItemModel);
    cachedEventsRanges.value = []
    loadEvents(dateRange(true));
  };

  const removeCalendarEventRecurrence = async (id: number): Promise<void> => {
    await calendarService.removeCalendarItemsByRepeat({ id });
    cachedEventsRanges.value = []
    events.value = events.value.filter((e: CalendarEvent) => e.repeat?.id !== id);
    loadEvents(dateRange(true));
  };

  const getCalendarLink = (): Promise<string> => calendarService.getCalendarLink();

  const $reset = () => {
    date.value = DateTime.now().startOf('day');
    events.value = [];
    cachedEventsRanges.value = [];
    eventsLoading.value = false;
    isDrawerOpen.value = false;
  };

  return {
    // state
    date,
    cachedEventsRanges,
    events,
    eventsLoading,
    isDrawerOpen,
    // getters
    isDateThisYear,
    dateRange,
    getEventsByDay,
    getEventById,
    getMonthDays,
    // actions
    loadEvents,
    setToday,
    setDate,
    addCalendarItem,
    updateCalendarItem,
    removeCalendarEvent,
    updateCalendarRecurrence,
    removeCalendarEventRecurrence,
    getCalendarLink,
    clearCachedEventRange,
    $reset,
  };
});
