<template>
  <layout-desktop-wrapper>
    <div class="p-4 bg-blue-100">
      <h1 class="text-2xl font-bold py-3 text-blue">Meldformulier</h1>
      <div class="form bg-white p-4">
        
        <p>Geef een (vermoeden van) een misstand door via ons meldformulier.</p>
        <p class="my-3">Waarmee kunnen we helpen?</p>
        <contact-form ref="form" :form-data/>
        <form-buttons-row :is-loading
                          :on-cancel :on-submit
                          :is-submit-disabled/>
      </div>
    </div>
    <div class="description mt-2 p-4">
      <p-heading variant="secondary" font-size="md"
                 class="font-bold pb-3">Wanneer trek ik aan de bel?</p-heading>
      <p class="mb-3">Een vermoeden van onprofessioneel handelen, mogelijke belangenverstrengeling tussen partijen, 
        een (bijna)-incident, geschil, agressie of andere ernstige misdragingen 
        waardoor de zorg (in)direct negatief wordt beïnvloed?</p>
      <p>
        Heb je geprobeerd om dit met de betrokken persoon op te lossen,
        maar geen uitkomst kunnen vinden? Dan kun je bij het meldpunt van PIDZ terecht. 
        Een specialist neemt jouw melding oprecht serieus, bespreekt jouw klacht en eventuele vervolgstappen. 
        Het doel van het meldpunt is om de zorg te verbeteren en
        eventuele misstanden gemakkelijker aan de kaak te kunnen stellen. 
        Dit kan ook anoniem.
      </p>
    </div>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { ContactForm } from '@pidz/shared/components';
import { globalValidations, rows } from '@pidz/shared/forms/contact';
import { computed, ref } from 'vue';

const form = ref<typeof ContactForm>();
const formData: PidzFormData<ContactForm> = {
  globalValidations,
  values: {
    contactType: 'message', 
    about: 1, 
    message: '', 
    anonymous: true,
  },
  rows
}

const onSubmit = () => form.value?.onSubmit()
const onCancel = () => form.value?.onCancel()
const isLoading = computed(() => form.value?.isSubmitting)
const isSubmitDisabled = computed(() => form.value?.isSubmitDisabled)

</script>
