import { Auth as Real } from './Auth';

let currentEmail = sessionStorage.getItem('AuthProxyEmail') ?? '';
const testLogins = [
  'zzp@pidz.nl',
  'wrongpass@pidz.nl',
  'expiredpass@pidz.nl',
];

const NamedError = (name:string) => Object.assign(Error(name), { name });

const getFakeUserSession = () => Real.createCognitoUserSession({
  accessToken: { jwtToken: currentEmail },
  idToken: { jwtToken: currentEmail },
  refreshToken: { token: currentEmail },
});

const getCurrentSession = async () => (testLogins.includes(currentEmail)
  ? getFakeUserSession()
  : Real.getCurrentSession());

const signIn = async (email: string, password: string) => {
  if (testLogins.includes(email)) {
    if (email === 'wrongpass@pidz.nl') { throw NamedError('NotAuthorizedException'); }
    if (email === 'expiredpass@pidz.nl') { throw NamedError('PasswordResetRequiredException'); }

    currentEmail = email;
    sessionStorage.setItem('AuthProxyEmail', email);

    return getFakeUserSession();
  }

  return Real.signIn(email, password);
};

const signOut = async () => {
  if (testLogins.includes(currentEmail)) {
    currentEmail = '';
    sessionStorage.clear();

    return;
  }

  await Real.signOut();
};

const refresh = async () => {
  if (testLogins.includes(currentEmail)) {
    return getFakeUserSession();
  }

  return Real.refresh();
};

const { configureEnvironmentAuth } = Real;

export default {
  configureEnvironmentAuth,
  getCurrentSession,
  signIn,
  signOut,
  refresh,
};
