<template>
  <p-badge v-if="showEnvironmentIndicator"
           variant="error"
           :content="activeEnvironment"/>    
</template>

<script lang="ts" setup>
import useEnvironment from '@app/stores/environment';
import { computed } from 'vue';

const environment = useEnvironment();

const activeEnvironment = computed(() => environment.selected?.name.slice(0, 3));

const showEnvironmentIndicator = computed(
  () => environment.selected?.name !== 'production',
);
</script>
