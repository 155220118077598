<template>
  <layout-desktop-wrapper>
    <section class="w-full bg-white p-4 mb-8">
      <h1 class="text-2xl font-bold mb-4 text-blue">Veelgestelde vragen</h1>
      <template v-if="faqItemsFiltered">
        <search-bar v-model="request.searchString"
                    :modal-value="request.searchString"
                    storage-key="faq"
                    class="mb-4 border border-neutral-400 !shadow-none"/>
        <p-accordion v-model="faqItemsFiltered" />
        <p v-if="faqItemsFiltered.length === 0" class="w-full text-center p-4">Geen resultaten gevonden</p>
      </template>
      <infinity-spinner v-else class="spinner-center" />
    </section>
    <section v-if="contactDetails" class="w-full bg-white px-4 py-6">
      <p class="font-bold">Staat je vraag er niet tussen?</p>
      <p class="">Ga naar de <router-link :to="{name: 'contact'}">contact pagina</router-link>
        en neem contact op met {{ contactDetails.name }}.</p>
    </section>
  </layout-desktop-wrapper>
</template>

<script setup lang="ts">
import InfinitySpinner from '@app/components/InfinitySpinner.vue';
import SearchBar from '@app/components/SearchBar.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { useFaqStore, useOrganisationStore } from '@pidz/stores';
import { computed, onMounted, reactive, ref } from 'vue';

const faqStore = useFaqStore();
const organisationStore = useOrganisationStore();

const faqItems = ref<accordionItem[] | undefined>();
const contactDetails = ref<ContactDetails | undefined>();

const request = reactive({
  searchString: '',
});

const faqItemsFiltered = computed(() => {
  if(!faqItems.value) return undefined;
  const { searchString } = request;
  const items = [...(faqItems.value ?? [])];

  return items.filter(
      (faqItem) =>
      searchString === '' ||
      faqItem.title.toLowerCase().includes(searchString.toLowerCase()) ||
      faqItem.body.toLowerCase().includes(searchString.toLowerCase()),
    );
  }
);

const setFaqItems = () => {
  faqItems.value = faqStore.faq?.map((faqItem) => ({
    title: faqItem.title,
    body: faqItem.body,
    open: false,
  }));
}

const loadStores = async () => {
  await faqStore.loadFaq();
};

onMounted(async () => {
  await loadStores();
  setFaqItems();
  contactDetails.value = await organisationStore.getContactDetails();
});
</script>

<style lang="scss">
.spinner-center {
  margin: auto;
  svg {
    stroke: var(--pidz-blue);
  }
}
</style>
