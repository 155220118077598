import { Preferences } from '@capacitor/preferences';
import { tokenConfigurator } from '@pidz/api';
import axios from 'axios'

// Because the environment / back-end baseurl is stored at different places on web and app we need to make a switcher
// Preferably both web and app are made equal and all Capacitor imports are removed from shared utils
const getBaseUrl = async (isMicro = false) => {
  let baseUrl;

  const { value } = await Preferences.get({ key: 'environment' });

  if (value) {
      isMicro ? baseUrl = JSON.parse(value).microUrl : baseUrl = JSON.parse(value).baseUrl;
  } else {
      // @ts-ignore
      isMicro ? baseUrl = import.meta.env.VITE_MICRO_URL : baseUrl = import.meta.env.VITE_BACKEND_HOST;
  }

  return baseUrl;
}

const getImpersonationId = async () => {
  const value = window.impersonationId

  if (value) return value;

  return undefined;
}

export const useApi = (defaults: {} = {}) => {
  return {
    jsonrpc: async ({url, params = {}}) => {
        const axiosInstance = axios.create({
            // @ts-ignore
            baseURL: await getBaseUrl(),
            withCredentials: true,
            ...defaults
        });

        axiosInstance.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
            return Promise.reject(error);
          }
      );

        const bearerToken = await tokenConfigurator();
        const headers = {
            Authorization: bearerToken.headers?.Authorization,
            'x-pidz-client-name': 'app',
            // @ts-ignore
            'x-pidz-version': import.meta.env.VITE_APP_VERSION_NUMBER,
            'is_axios': 'yes'
        };

        const impersonationId = await getImpersonationId()
        if (impersonationId) headers['x-pidz-rpc-assume-user'] = impersonationId;

        const body = {
            'jsonrpc': '2.0',
            'method': `pidz.services.api.${url}`,
            'params': params
        };

        const result = await axiosInstance.post('/api-rpc-cognito/', body, {
          headers: headers
        })

        return result.data.result;
    },
    rest: async ({method, url, params = {}, isMicro = false}) => {

      const axiosInstance = axios.create({
          // @ts-ignore
          baseURL: await getBaseUrl(isMicro),
          withCredentials: true,
          ...defaults
      });

      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          return Promise.reject(error);
        }
    );

      const bearerToken = await tokenConfigurator();
      const headers = {
          Authorization: bearerToken.headers?.Authorization,
          'x-pidz-client-name': 'app',
          // @ts-ignore
          'x-pidz-version': await getBaseUrl(isMicro),
      };

      const impersonationId = await getImpersonationId()
      if (impersonationId) headers['x-pidz-rpc-assume-user'] = impersonationId;

      let result;

      // get and delete calls need a different axios setup
      if (method === 'get'|| method === 'delete') {
        result = await axiosInstance[method](url, { headers: headers, params: params })
        return result.data;
      };

      result = await axiosInstance[method](url, params, { headers: headers });

      return result.data;
    }
  }
}
