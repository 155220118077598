<template>
  <span class="timeline-item__default">
    <h3 class="font-bold">{{ data.title }}</h3>
    <p class="subtitle">{{ data.subtitle }}</p>
    <p>{{ data.description }}</p>
    <time>
      {{ formatDate(data.startDate) }} -
      {{ data.endDate ? formatDate(data.endDate) : 'heden' }}
    </time>
    <p-button variant="ghost"
              class="edit"
              @click="(e: Event) => emit('edit', e)">
      <p-icon icon-name="pencil" color="blue" />
      Bewerken
    </p-button>
  </span>
</template>

<script lang="ts" setup>
import { capitalizeString } from '@pidz/date';
import { DateTime } from 'luxon';

defineProps<{
  data: TimelineItem & TimelineItemWork;
}>();

const emit = defineEmits(['edit']);

const formatDate = (date: DateTime) => {
  return capitalizeString(
    date.setLocale('nl').toLocaleString({ month: 'long', year: 'numeric' }),
  );
};
</script>

<style lang="scss">
.timeline-item__default {
  h3,
  p,
  time {
    margin: 0 0 0.5rem 0;
    font-size: 14px;

    @media screen and (min-width: 1024px) {
      font-size: 1rem;
    }
  }
  h3 {
    font-size: 16px;
    color: var(--pidz-blue);
  }
  p {
    &.subtitle {
      color: var(--pidz-grey-dark);

      @media screen and (min-width: 1024px) {
        font-size: 1rem;
      }
    }
  }

  time {
    display: block;
    font-size: 0.875rem;
  }
  .edit {
    font-size: 14px;
    color: var(--pidz-blue);
  }
}
</style>
