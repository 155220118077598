export default [
  {
    name: 'travel-time-clients',
    path: '/settings/travel-time-clients',
    redirect: { name: 'travel-time-clients overview' },
    children: [
      {
        name: 'travel-time-clients overview',
        path: '/settings/travel-time-clients',
        component: () => import('@app/pages/settings/travel-time-clients/TravelTimeClientsOverviewPage.vue'),
        meta: { title: 'Reistijd & koppelingen' },
      },
      {
        name: 'travel-time-clients travel-time edit',
        path: '/settings/travel-time/edit',
        component: () => import('@app/pages/settings/travel-time-clients/EditTravelTimePage.vue'),
        meta: {
          title: 'Reistijd aanpassen',
          backButton: true,
        },
      },
      {
        name: 'travel-time-clients organisation edit',
        path: '/settings/organisation/edit/:id',
        component: () => import('@app/pages/settings/travel-time-clients/OrganisationEditPage.vue'),
        meta: { 
          title: 'Zorginstelling aanpassen',
          backButton: true,
        },
      },
    ]
  },
];