<template>
  <layout-desktop-wrapper>
    <reload-page :reload="reload" />
    <div class="w-full flex flex-col gap-6">
      <travel-time :padding="'16px'" :travel-data="travelData"
                   @edit-travel-time="handleRoute('travel-time')"/>
      <organisation-overview :padding="'16px'" :organisations="organisations"
                             @edit="(id: number) => handleRoute('organisation', id)"/>
    </div>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import {TravelTime, OrganisationOverview} from '@pidz/shared/components';
import { useUserStore, useClientsStore, } from '@pidz/stores';
import { onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const clientsStore = useClientsStore();
const router = useRouter();

const travelData = computed<TravelTimeData>(() => ({
  time: userStore.maxWorkRange,
  linkedOrganisations: userStore.organisationCountWithinRange,
  linkedDepartments: userStore.departmentCountWithinRange
}))
const organisations = computed<Organisation[] | undefined>(() => clientsStore.organisations)

const handleRoute = (section: string, id?: number) => {
  router.push({
    name: `travel-time-clients ${section} edit`,
    params: { id },
  });
};

const reload = async () => {
  if(userStore.maxWorkRange){
    await Promise.all([
      clientsStore.fetchOrganisations(),
      userStore.fetchDepartmentCountWithinRange(userStore.maxWorkRange),
      userStore.fetchOrganisationCountWithinRange(userStore.maxWorkRange)
    ]);
  }
};

onMounted(async () => {
  await reload();
});

</script>
<style lang="scss" scoped>
.travel-time-clients-overview-page {
  width: 100%;
}

.traveltime-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  gap: 24px;
}
</style>
