import { useApi } from '@pidz/api';

const api = useApi();

export const sheetService = {
  getSheets: (params: GetSheetsParams): Promise<{data: Sheet[]}> => api.jsonrpc({
    url: 'sheet:getSheets', 
    params: params
  }),

  getSheet: (sheetId: number): Promise<Sheet> => api.jsonrpc({
    url: 'sheet:getSheet', 
    params: { sheetId }
  }),

  sendSheetForApprovalById: (sheetId: number, comment: string): Promise<boolean> => api.jsonrpc({
    url: 'sheet:sendSheetForApproval', 
    params: { sheetId, comment }
  }),

  closeSheetById: (sheetId: number): Promise<Object> => api.jsonrpc({
    url: 'sheet:closeSheet', 
    params: { sheetId }
  }),

  getSheetSummary: (sheetId: number): Promise<SheetSummary> => api.jsonrpc({
    url: 'sheet:getSheetSummary', 
    params: { sheetId }
  }),

  // Sheetblocks
  addSheetBlock: (sheetBlockModel: UnsavedSheetBlock): Promise<{result: Sheet}> => api.jsonrpc({
    url: 'sheetblock:addSheetBlock', 
    params: { sheetBlockModel },
  }),

  updateSheetBlock: (sheetBlockModel: SheetBlockModel): Promise<{result: Sheet}> => api.jsonrpc({
    url: 'sheetblock:updateSheetBlock', 
    params: { sheetBlockModel },
  }),

  removeSheetBlock: (sheetBlockIds: number[]): Promise<{result: Sheet}> => api.jsonrpc({
    url: 'sheetblock:removeSheetBlock', 
    params: { sheetBlockIds },
  }),

  // Sheet rows (microservice)
  getRows: (sheetUuid: string): Promise<MicroSheetRow[]> => api.rest({ 
    url: `/sheet/${sheetUuid}/row`, 
    method: 'get',
    isMicro: true
  }),

  getRow: (sheetUuid: string, rowUuid: string): Promise<MicroSheetRow> => api.rest({
    url: `/sheet/${sheetUuid}/row/${rowUuid}`, 
    method: 'get',
    isMicro: true
  }),

  createRow: (row: MicroSheetRow): Promise<void> => api.rest({
    url: `/sheet/${row.sheetUuid}/row`, 
    params: row,
    method: 'post',
    isMicro: true
  }),

  updateRow: (row: MicroSheetRow): Promise<void> => api.rest({
    url: `/sheet/${row.sheetUuid}/row/${row.uuid}`, 
    params: row,
    method: 'put',
    isMicro: true
  }),

  removeRow: (row: MicroSheetRow): Promise<void> => api.rest({
    url: `/sheet/${row.sheetUuid}/row/${row.uuid}`, 
    method: 'delete',
    isMicro: true
  }),

  // Sheet commutes (microservice)
  getCommutes: (sheetUuid: string): Promise<Commute[]> => api.rest({
    url: `/sheet/${sheetUuid}/commute`, 
    method: 'get',
    isMicro: true
  }),

  addCommute: (commute: Commute): Promise<Commute> => api.rest({
    url: `/sheet/${commute.sheetUuid}/commute`, 
    params: commute,
    method: 'post',
    isMicro: true
  }),

  updateCommute: (commute: Commute): Promise<void> => api.rest({
    url: `/sheet/${commute.sheetUuid}/commute/${commute.uuid}`, 
    params: commute,
    method: 'put',
    isMicro: true
  }),

  removeCommute: (commute: Commute): Promise<void> => api.rest({
    url: `/sheet/${commute.sheetUuid}/commute/${commute.uuid}`, 
    method: 'delete',
    isMicro: true
  }),
}
