export const getInboxItemIcon = (type: string): string => {
  switch (type) {
    case 'notify_freelancer_freelancer_sheet':
    case 'freelancer_invoice':
    case 'notify_freelancer_pre_finance_preference_changed':
    case 'notify_open_sheets_freelancer_reminder':
      return 'stopwatch';
    case 'notify_freelancer_review_invitation_first_reminder':
    case 'notify_freelancer_new_review':
      return 'review';
    case 'notify_worker_invite_worker_by_project':
    case 'notify_freelancer_freelancer_shift':
    case 'notify_worker_shift_worker_removed':
    case 'notify_worker_filled_shift_changed':
      return 'jobs';
    case 'notify_worker_worker_activated':
    case 'notify_freelancer_bank_account_changed':
    case 'notify_worker_worker_inactivated':
    case 'notify_freelancer_inactivation_prevented':
    case 'notify_freelancer_competency_expire_reminder':
    case 'notify_freelancer_registration_expire_reminder':
    case 'notify_freelancer_registration_expired':
    case 'notify_freelancer_training_expired':
    case 'notify_freelancer_training_expire_reminder':
      return 'activity';
    case 'notify_worker_covid_changed':
      return 'profession';
    case 'notify_freelancer_nudge_rate':
      return 'marking';
    case 'software_invoice':
    case 'software_invoice_reminder':
    case 'software_incasso_failed':
      return 'invoice';
    case 'notify_worker_resume_item_approved':
    case 'notify_worker_resume_item_disapproved':
    case 'notify_worker_resume_item_expired':
    case 'notify_freelancer_asset_expire_reminder':
    case 'notify_freelancer_asset_disapproved':
    case 'notify_freelancer_competency_expired':
    case 'worker_education_approved':
    case 'worker_education_disapproved':
    case 'worker_training_approved':
    case 'worker_training_disapproved':
    case 'worker_registration_approved':
    case 'worker_registration_disapproved':
      return 'files';
    default:
      return 'copy'
  }
}