import { http, passthrough } from 'msw'
// import * as conversions from './conversions/index';

/******* @TODO
- Error handling
- Playwright integratie
*/
type RpcResponseBody = {
    method: string,
    params: object
}
export default [
    http.post(
        'https://acc.mijnpidz.nl/api-rpc-cognito/',
        async ({ request}) => {
          return passthrough(); // TODO: temporary measurement until MSW is properly implemented
            // @ts-ignore
            const data: RpcResponseBody = await request.json();
            if (!data) return passthrough();
            // if (data.method && data.method.startsWith('pidz.services.api')) {
                // const parts = data.method.split('.')
                // const methods = parts[parts.length-1].split(':');

                // if (!conversions[methods[1]]) {
                    return passthrough();
                // }

                // const response = {
                //     'jsonrpc': '2.0',
                //     'result':conversions[methods[1]](data.params)
                // };

                // return HttpResponse.json(response);
            // }

            return passthrough();
        },
    )
]
