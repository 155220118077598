export enum NotificationType {
    SHEET_APPROVAL_CHANGED = 'notify_freelancer_freelancer_sheet',
  
    CHANGED_SHIFT = 'notify_worker_filled_shift_changed',
    SHIFT_CANCELLED = 'notify_worker_shift_worker_removed',
    SHIFT_APPROVAL_CHANGED = 'notify_freelancer_freelancer_shift',
  
    INVITED = 'notify_worker_invite_worker_by_app_only',
  
    ASSET_APPROVED = 'notify_freelancer_asset_approved',
    ASSET_DISAPPROVED = 'notify_freelancer_asset_disapproved',
    ASSET_EXPIRED = 'notify_freelancer_asset_expire_reminder',
  
    WORKER_DEACTIVATED = 'notify_worker_worker_inactivated',
    WORKER_ACTIVATED = 'notify_worker_worker_activated',
  }

  export enum NotificationItemType {
    NOTIFICATIONS = 'notifications',
    ACTIONS = 'actions'
  }