<template>
  <sheet-block-list-section v-model="value"
                            class="sheet-block-sleep"
                            title="Gewerkt tijdens slaapuren"
                            add-title="Gewerkte uren tijdens slaapuren toevoegen"
                            edit-title="Gewerkte uren tijdens slaapuren wijzigen"
                            allow-only-one
                            :block-factory="blockFactory"
                            :editor="SheetSleepHourEditor"
                            :validate="validate">
    <template #item="{ block }">
      <div class="total">{{ blockTimeTotal(block) }}</div>
    </template>
  </sheet-block-list-section>
</template>
<script lang="ts" setup>
import {
  addHourToIsoDate,
} from '@pidz/date';
import { SheetBlockType } from '@pidz/enums';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import blockTimeTotal from '../utils/block-time-total';
import {
  validateSleepMinOneHour,
  validateSleepMax4Hours,
  validateSleepRoundInQuarters,
  validationStartsAtMidNight,
  validateInsideWorkHours,
} from '../utils/block-validations';
import SheetBlockListSection from './SheetBlockListSection.vue';
import SheetSleepHourEditor from './SheetSleepHourEditor.vue';

const props = defineProps<{
  modelValue: any[];
  workingHoursBlock: any;
  shiftId: number;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

const validate = (block: any) => {
  validateSleepRoundInQuarters(block);
  validateSleepMinOneHour(block);
  validateSleepMax4Hours(block);
  validationStartsAtMidNight(block);
  validateInsideWorkHours(
    block,
    props.workingHoursBlock,
    'Gewerkt tijdens slaapuren mag niet buiten de werktijd vallen.',
  );
};

const setEndDateTimeToStartOfDay = (dateString: string) => {
  return DateTime.fromISO(dateString).startOf('day').toISO({suppressMilliseconds: true})!;
};

const blockFactory = () => ({
  start: setEndDateTimeToStartOfDay(props.workingHoursBlock.end),
  end: addHourToIsoDate(setEndDateTimeToStartOfDay(props.workingHoursBlock.end)),
  type: SheetBlockType.TYPE_WORK_DURING_SLEEP,
  shift_id: props.shiftId,
});
</script>
<style lang="scss">
.sheet-block-sleep {
  .total {
    flex: 1;
  }
}
</style>
