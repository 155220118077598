import { useToaster } from '@pidz/stores';
import { MimeTypeMap } from '@pidz/utils';

export const getMimeType = (fileName: string) => {
  const extension = fileName.split('.').pop() || '';
  const mimeType = MimeTypeMap.get(extension)
  
  if(!mimeType){
      useToaster().addToaster({
          type: 'error',
          title: 'Oeps',
          message: 'We kunnen het bestand niet downloaden. Neem contact op met de vestiging',
      });
      return;
  }
  return mimeType
}