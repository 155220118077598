<template>
  <layout-desktop-wrapper>
    <section class="px-4 py-6 bg-white">
      <h1 class="text-xl font-bold">Afwijkende tarieven</h1>
      <p class="text-xs">{{taxText}}</p>
      <p class="text-sm pt-4">Stel voor elke functie je incidentele en/of periodieke tarief in</p>
      
      <p-stepper v-model="rateStage" 
                 :stepper-options="stepperOptions"
                 show-edit
                 :linear="false"
                 class="py-4"/>
      
      <section v-if="mappedRates && standardRates">
        <different-rates-form-edit ref="form" 
                                   :standard-rates="standardRates"
                                   :different-rates="mappedRates"
                                   :organisation="selectedOrganisation!"
                                   :department="selectedDepartment!" />
      </section>
      
      <loader v-else />

      <form-delete-row name="Tarieven" :on-delete="onDelete"/>  
      
    </section>

    <form-buttons-row :on-cancel="() => rerouteToOverview(router, 'rates')" 
                      :on-submit="form?.onSubmit"
                      :is-loading="loading"
                      :is-submit-disabled="disabled"/>
      
  </layout-desktop-wrapper>

</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import FormDeleteRow from '@app/components/FormDeleteRow.vue';
import Loader from '@app/components/Loader.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { DifferentRatesFormEdit } from '@pidz/shared/components'
import { CancelModal, DeleteModal } from '@pidz/shared/components';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useRatesStore, useToaster, useUserStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, Router, useRoute, useRouter } from 'vue-router';

const userStore = useUserStore();
const ratesStore = useRatesStore();
const modalController = useModalController();
const router: Router = useRouter();
const route = useRoute();

const form = ref<typeof DifferentRatesFormEdit>();

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)

const rateStage = ref<3>(3);

const taxText = computed(() => userStore.freelancer?.vat_free ? '(BTW vrijgesteld)' : '(Excl. BTW)')

const selectedOrganisation = ref<ComboBoxOption | null>(null);
const selectedDepartment = ref<ComboBoxOption | null>(null);

const stepperOptions = computed(() => [{
  step: 1,
  title: 'Kies zorginstelling',
  description: selectedOrganisation.value?.label,
  disabled: true
}, {
  step: 2,
  title: 'Kies afdeling',
  description: selectedDepartment.value?.label,
  disabled: true
}, {
  step: 3,
  title: 'Stel afwijkende tarieven in',
  disabled: true
}]);

const allDepartments = ref(false);
const standardRates = ref<Rate[] | undefined>();
const differentRates = ref<Rate[] | undefined>();
const mappedRates = ref<editRate[] | undefined>();

onMounted(async () => {
  const {organisation, department} = route.params;
  
  if (!department) allDepartments.value = true;
  
  differentRates.value = ratesStore.getDifferentRatesById(
    parseInt(organisation as string), parseInt(department as string));

  if (!differentRates.value) return;

  const { organisation_id, organisation_name, department_id, department_name} = differentRates.value[0]

  selectedOrganisation.value = {
    value: organisation_id!,
    label: organisation_name!
  }
  
  selectedDepartment.value = {
    value: allDepartments.value ? -1 : department_id!,
    label: allDepartments.value ? 'Alle afdelingen' : department_name!
  }

  standardRates.value = await ratesStore.getFreelancerStandardFunctionRates();

  mappedRates.value = standardRates.value?.map((entry) => {
    const matchingRate = differentRates.value!.find(
      (rate) => rate.function_id === entry.function_id
    );

    return matchingRate
      ? { ...entry, ...matchingRate, enabled: true, existing: true }
      : { ...entry, enabled: false, existing: false };
  });
})

const cancel = (callbackRoute: Function) => {
  if (form.value?.isChanged && !form.value?.formSubmitted) {
    modalController.open(CancelModal, {
      cancelEdit: () => {
        callbackRoute()
      },
      continueEdit: () => {
        modalController.close();
      }
    });
  } else {
    callbackRoute();
  }
}

const deleteDifferentRates = async () => {
  differentRates.value?.forEach(async (rate) => {
    await ratesStore.removeFreelancerFunctionRate(rate.freelancer_function_rate_id);
  });

  rerouteToOverview(router, 'rates');

  useToaster().addToaster({
            type: 'success',
            title: 'Gelukt!',
            message: 'Sucessvol verwijderd'
          }, 5000);
}

const onDelete = () => {
  modalController.open(DeleteModal, {
    type: 'Tarief',
    customText: 'Weet je zeker dat je het tarief wilt verwijderen?',
    delete: () => deleteDifferentRates(),
    cancel: () => () => {},
  });
}

onBeforeRouteLeave((_to, _from, next) => cancel(next));
</script>
