<template>
  <div class="sheet-remark" :class="{ open }"
       @click="testClickOut">
    <section>
      <label for="remark-textarea">Opmerkingen voor de zorginstelling:</label>
      <textarea id="remark-textarea" v-model="value"></textarea>
    </section>
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  computed, onBeforeUnmount, onMounted, ref,
} from 'vue';

const props = defineProps<{
  modelValue: string,
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get: () => props.modelValue,
  set: (to) => emit('update:modelValue', to),
});

const open = ref(false);
defineExpose({ open });

let keyboardListeners:any[] = [];
onMounted(async () => {
  if (Capacitor.isPluginAvailable('Keyboard')) {
    keyboardListeners = [
      await Keyboard.addListener('keyboardWillShow', () => { open.value = true; }),
      await Keyboard.addListener('keyboardWillHide', () => { open.value = false; }),
    ];
  }
});
onBeforeUnmount(() => {
  keyboardListeners.forEach((listener) => listener.remove());
});

const testClickOut = (e:any) => {
  if (!/textarea/i.exec(e.target?.nodeName)) {
    open.value = false;
  }
};

const disableScroll = computed(() => (open.value ? 'none' : 'inherit'));

</script>

<style lang="scss">

* {
  touch-action: v-bind(disableScroll);
}

.sheet-remark {
  width: 100%;
  color: var(--pidz-body-text);
  section {
    padding: 12px;
  }

  label {
    font-size: 12px;
  }

  textarea {
    font: inherit;
    width: 100%;
    padding: 14px 12px;
    background-color: transparent;
    color: inherit;
    border: 1px solid var(--pidz-black);
    resize: none;
    border-radius: 5px  ;
  }

  &.open {
    z-index: 1;
    position: fixed;
    inset: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    section {
      background: var(--pidz-blue);
    }
    label {
      color: var(--pidz-white);
    }
    textarea {
      background: var(--pidz-white);
      min-height: 160px;
    }
  }
}
</style>
