<template>
  <div ref="calendarDayContent" class="calendar-day-content"
       data-prevent-pull-on-scrolloffset>
    <div class="week-indicator">{{ selectedDayText }}</div>

    <section class="day-timesheet">
      <div v-for="(n, i) in 25" :key="n"
           class="hour-row">
        <div class="time-indicator">{{ formatHour(i) }}</div>
        <div class="line"></div>
      </div>
      <div class="calendar-day-events">
        <calendar-event v-for="event in dayEvents"
                        :key="event.id"
                        :class="[
                          'day-event',
                          {
                            'from-prev-day': otherDay(event.start),
                            'to-next-day': otherDay(event.end),
                            'all-day': event.all_day,
                            condensed: eventShouldBeCondensed(event.end, event.start),
                          },
                        ]"
                        :calendar-event="event"
                        :style="{
                          top: calculateTop(event.start),
                          height: calculateHeight(event),
                        }"/>
      </div>
    </section>
    <div class="floating-buttons">
      <add-event-button />
      <link-calendar-button />
    </div>
  </div>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import { isSame, differenceBetweenDates, DateTime } from '@pidz/date';
import { useCalendar } from '@pidz/stores';
import { collidesWithDay } from '@pidz/utils';
import { computed, ref, watch } from 'vue';
import AddEventButton from './AddEventButton.vue';
import CalendarEvent from './CalendarEvent.vue';
import LinkCalendarButton from './LinkCalendarButton.vue';

const calendarStore = useCalendar();
const calendarDayContent = ref<HTMLDivElement | null>();

const formatHour = (hour: number) =>
  hour < 24 ? `${hour.toString().padStart(2, '0')}:00` : '00:00';
const calcHoursWithMinutes = (event: DateTime) => event.hour + event.minute / 60;

const selectedDayText = computed((): string => `Week ${calendarStore.date.weekNumber}`);

const dayEvents = computed((): CalendarEvent[] => {
  const selectedDate = calendarStore.date;
  return calendarStore.events.filter((e) => collidesWithDay(selectedDate, e));
});

watch(
  () => calendarStore.date,
  () => {
    calendarDayContent.value?.scrollTo({ top: 0 });
  },
);

const otherDay = (date: DateTime) => !isSame(date, calendarStore.date, 'day');

const calculateTop = (dateTime: DateTime) => {
  const top = !isSame(dateTime, calendarStore.date, 'day')
    ? 0
    : calcHoursWithMinutes(dateTime) * 24 + 24;
  return `${top}px`;
};

const calculateHeight = ({
  start,
  end,
  all_day,
}: {
  start: DateTime;
  end: DateTime;
  all_day: boolean;
}) => {
  if (all_day) return '576px';

  const isStartDateToday = isSame(start, calendarStore.date, 'day');
  const isEndDateToday = isSame(end, calendarStore.date, 'day');

  if (!isStartDateToday && !isEndDateToday) return `${26 * 24}px`;
  if (!isStartDateToday) return `${calcHoursWithMinutes(end) * 24 + 24}px`;
  if (!isEndDateToday) return `${(24 - calcHoursWithMinutes(start)) * 24 + 24}px`;

  const { minutes } = differenceBetweenDates(end, start, 'minute');
  return `${((minutes || 0) / 60) * 24}px`;
};
const eventShouldBeCondensed = (end: DateTime, start: DateTime): boolean => {
  const shouldBeCondensed: boolean = end.toUnixInteger() - start.toUnixInteger() <= 5400 * 1000;
  return shouldBeCondensed;
};
</script>
<style lang="scss">
.calendar-day-content {
  overflow-y: scroll;
  scrollbar-width: none;
  padding-bottom: 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  .week-indicator {
    color: var(--pidz-black);
    margin: 12px 12px 6px 12px;
  }

  .day-timesheet {
    width: 100%;
    padding-bottom: 24px;
    position: relative;
    background: var(--pidz-white);

    .hour-row {
      width: 100%;
      height: 24px;
      color: var(--pidz-grey-dark);

      display: flex;
      flex-direction: row;

      .time-indicator {
        width: 72px;
        line-height: 0.8em;

        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      .line {
        flex: 1;
        border-bottom: solid 1px var(--pidz-grey-light);
      }
    }
  }

  .calendar-day-events {
    position: absolute;
    left: 80px;
    right: 12px;
    top: 0;

    .day-event {
      position: absolute;
      padding: 8px;

      &.from-prev-day {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &.to-next-day {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.all-day {
        border-radius: 5px;
      }

      &.condensed {
        padding: 0 8px;
        overflow: hidden;
      }
    }
  }

  .floating-buttons {
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    bottom: 16px;
    right: 16px;
  }
}
</style>
