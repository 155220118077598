<template>
  <modal>
    <h1 class="font-bold">Agenda-reeks verwijderen</h1>
    <p>Weet je zeker dat je deze agenda-reeks wil verwijderen?</p>
    <p>
      Eventuele uitzonderingen die je eerder gemaakt hebt op deze reeks komen
      ook te vervallen.
    </p>
    <template #footer>
      <button class="button-outline"
              @click="modalController.close()">
        Annuleren
      </button>
      <async-button class="error"
                    @click="removeRecurrence">Verwijderen</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();
const props = defineProps<{ remove: Function }>();

const removeRecurrence = async () => {
  await props.remove();
  modalController.close();
};
</script>
<style lang="scss"></style>
