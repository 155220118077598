import router from '@app/router';
import { UserLoginState } from '@pidz/enums';
import { UserState } from '.';

const LOGIN_PAGE = '/login';
const UPDATE_REQUIRED_PAGE = '/update-required';
const FORGOT_PASSWORD_PAGE = '/forgot';
const IMPERSONATION_PAGE = '/impersonation';
const UNPAID_PAGE = '/unpaid';
const DASHBOARD_PAGE = '/dashboard';

const forbiddenWhenLoggedIn = [LOGIN_PAGE, IMPERSONATION_PAGE, UNPAID_PAGE, FORGOT_PASSWORD_PAGE];

export default (state:UserState) => {
  const getAllowedRoute = () => {
    switch (state.loginState) {
      case UserLoginState.LoggedOut: return [LOGIN_PAGE, UPDATE_REQUIRED_PAGE, FORGOT_PASSWORD_PAGE];
      case UserLoginState.LoggedInAdmin: return [IMPERSONATION_PAGE, UPDATE_REQUIRED_PAGE];
      case UserLoginState.LoggedInFreelancerUnpaid: return [UNPAID_PAGE, UPDATE_REQUIRED_PAGE];
      default:
        return undefined;
    }
  };

  const enforceAllowedRoute = () => {
    const allowed = getAllowedRoute();
    if (allowed) {
      if (!allowed.includes(router.currentRoute.value.path)) {
        router.replace(allowed[0]);
      }
    } else if (forbiddenWhenLoggedIn.includes(router.currentRoute.value.path)) {
      router.replace(DASHBOARD_PAGE);
    }
  };

  const isAllowed = (route:any) => {
    const allowed = getAllowedRoute();
    if (allowed !== undefined && !allowed.includes(route.path)) { return false; }
    if (allowed === undefined && forbiddenWhenLoggedIn.includes(route.path)) { return false; }
    return true;
  };

  router.beforeEach(async (to) => {
    await state.initialized;
    const allowed = isAllowed(to);
    return allowed;
  });

  return {
    enforceAllowedRoute,
  };
};
