<template>

  <section class="sheet-block-work-hours">
    <date-time-range v-model="value"
                     :disabled="disabled" />
    <div v-if="error"
         class="error">{{error}}</div>
  </section>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  validateStartBeforeEnd,
  validateWorkHoursMax24,
  validateNoOverlap,
} from '../utils/block-validations';
import hasStartAndEnd from '../utils/has-start-and-end';
import DateTimeRange from './DateTimeRange.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: SheetBlockModel,
  disabled?:boolean,
  otherWorkHourBlocks:any[],
}>();

const error = ref<string|null>(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    try {
      error.value = null;
            if (hasStartAndEnd(to)) {
        validateStartBeforeEnd(to);
        validateWorkHoursMax24(to);
        validateNoOverlap(to, props.otherWorkHourBlocks, 'De tijden overlappen met een andere opdracht.');
      }
    } catch (e:any) {
      error.value = e.message;
    } finally {
            emit('update:modelValue', to);
    }
  },
});

</script>
<style lang="scss">
.sheet-block-work-hours {
  label {
    display: block;
    color: var(--pidz-black);
    margin-bottom: 4px;
  }
  .icon-field-field {
    margin-bottom: 12px;
  }
  .error {
    color: var(--pidz-red);
  }
}
</style>
