<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       width="95" height="119"
       viewBox="0 0 95 119">
    <g fill="none" fill-rule="evenodd">
      <ellipse cx="47.5" cy="95.5"
               fill="#E1F4FD" rx="47.5"
               ry="23.5"/>
      <g fill-rule="nonzero" transform="translate(4)">
        <path fill="#89D1F4" d="M34.8 48.6892V22h18.9818v26.6892s-18.9818.0564-18.9818 0Z"/>
        <path fill="#47B283" d="M42.709 22v26.7143c4.7455-.007 9.491-.0212 9.491-.0212V22h-9.491Z"/>
        <path fill="#004D9D" d="M36.3766 20.1532c0 .7334-.3242 1.3925-.84 1.8468v-1.3513c0-9.1094-9.8574-15.0772-18.1366-10.8447.0269-.0829 1.6831-4.298 1.715-4.3792a3.1906 3.1906 0 0 1 .0471-.1123C20.6185 2.0847 23.8957 0 27.5141 0c4.0566 0 7.6614 2.6777 8.7667 6.5101.1348.464.0958-.5268.0958 13.643Z"/>
        <path fill="#89D1F4" d="M23.0755 7.8571c-5.5241 0-10.5058 3.4829-12.4422 8.586C.4357 43.2978 1.123 41.477.9903 41.862.3333 43.7684 0 45.766 0 47.7988 0 57.8348 8.1605 66 18.191 66c10.0303 0 18.1908-8.1651 18.1908-18.2012V21.171c0-7.3413-5.9691-13.3139-13.3063-13.3139Z"/>
        <path fill="#004D9D" d="M18.191 66C8.1604 66 0 57.8931 0 47.9286 0 38.005 8.0763 29.857 18.191 29.857c10.0303 0 18.1908 8.107 18.1908 18.0715C36.3818 57.893 28.2213 66 18.191 66Z"/>
        <ellipse cx="18.1909" cy="47.9286"
                 fill="#E1F4FD" rx="15.0273"
                 ry="14.9286"/>
        <path fill="#004D9D" d="M50.6234 20.1532c0 .7334.3242 1.3925.84 1.8468v-1.3513c0-9.1094 9.8574-15.0772 18.1366-10.8447-.0269-.0829-1.6831-4.298-1.715-4.3792a3.1906 3.1906 0 0 0-.0471-.1123C66.3815 2.0847 63.1043 0 59.4859 0c-4.0566 0-7.6614 2.6777-8.7667 6.5101-.1348.464-.0958-.5268-.0958 13.643Z"/>
        <path fill="#47B283" d="M63.9245 7.8571c5.5241 0 10.5058 3.4829 12.4422 8.586 10.1976 26.8547 9.5103 25.0338 9.643 25.4189.6571 1.9065.9903 3.904.9903 5.9368C87 57.8348 78.8395 66 68.809 66c-10.0303 0-18.1908-8.1651-18.1908-18.2012V21.171c0-7.3413 5.9691-13.3139 13.3063-13.3139Z"/>
        <path fill="#004D9D" d="M53.7818 48.7143H34.8L34.8081 44h18.9656z"/>
        <path fill="#004D9D" d="M52.1919 44h-9.4828v4.7143H52.2z"/>
        <path fill="#004D9D" d="M50.6182 47.9286c0-9.9646 8.1605-18.0715 18.1909-18.0715C78.8264 29.8571 87 37.9163 87 47.9286 87 57.893 78.8395 66 68.809 66c-10.0303 0-18.1908-8.1069-18.1908-18.0714Z"/>
        <ellipse cx="68.8091" cy="47.9286"
                 fill="#E1F4FD" rx="15.0273"
                 ry="14.9286"/>
      </g>
      <path fill="#D7D2C2" d="M26.9264 50.1564c.3846.1634.8093-.0323.9484-.437.1392-.4049-.0598-.8656-.4443-1.029L15.4187 43.581c-.3845-.1634-.8092.0323-.9483.437-.1392.4048.0598.8656.444 1.0278l12.012 5.1106Zm39.6168-4.5459 12.013 5.1104c.3846.1634.8093-.0323.9485-.437.1391-.4049-.0598-.8656-.4444-1.029l-12.0115-5.1095c-.3846-.1634-.8094.0323-.9485.4371-.1392.4048.0595.8644.443 1.028m2.6159 9.0891 9.205 3.5702c.4256.1647.9025-.0488 1.065-.477.1624-.428-.051-.9089-.4754-1.074l-9.206-3.5686c-.4256-.1648-.9024.0487-1.0652.4756-.162.4294.051.909.4766 1.0738m-51.9065-.0124 9.205 3.5702c.4257.1648.9025-.0487 1.065-.4769.1624-.4281-.051-.909-.4754-1.074l-9.2059-3.5686c-.4256-.1648-.9025.0487-1.0652.4756-.1621.4294.0509.909.4765 1.0737"/>
    </g>
  </svg>
</template>
