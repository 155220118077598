import { defineStore } from 'pinia';

const copyFilters = <T extends Object>(filters: T): T => JSON.parse(JSON.stringify(filters));

export const createFilterStore = <T extends Object>(name: string, initialFilters: T) => defineStore(name, {
  state: () => ({
    initialValue: copyFilters(initialFilters),
    filters: copyFilters(initialFilters),
  }),
  getters: {
    isFilterApplied(state) {
      return JSON.stringify(state.filters) !== JSON.stringify(state.initialValue);
    },
  },
  actions: {
    applyFilters(newFilters: Partial<T>) {
      // @ts-expect-error: TODO: fix this
      Object.assign(this.filters, { ...this.filters, ...newFilters });
    },
    applyInitialFilters(newInitialFilters: Partial<T>) {
      // @ts-expect-error: TODO: fix this
      Object.assign(this.initialValue, { ...this.initialValue, ...newInitialFilters });
      this.applyFilters(newInitialFilters);
    },
    reset() {
      // @ts-expect-error: TODO: fix this
      Object.assign(this.filters, copyFilters(this.initialValue));
    },
  },
});
