<template>
  <section class="micro-sheet-work-during-sleep">
    <h2>Gewerkt tijdens slaapuren</h2>
    <dropdown v-model="value" :options="sleepOptions"
              name="work-during-sleep" />
  </section>
</template>
<script lang="ts" setup>

import { computed } from 'vue';
import Dropdown from '../../../components/Dropdown.vue';

const props = defineProps<{
  modelValue:number,
}>();
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get: () => props.modelValue,
  set: (to) => emit('update:modelValue', to),
});

const minutesToName = (minutes:number) => {
  const hours = Math.floor(minutes / 60);
  const parts = [`${hours} uur`];

  const rest = minutes - hours * 60;
  if (rest > 0) {
    parts.push(`${rest} minuten`);
  }
  return parts.join(', ');
};
const sleepOptions = computed(() => {
  const minMinutes = 1 * 60;
  const maxMinutes = 4 * 60;
  const options = [
    { value: 0, name: 'Niet gewerkt tijdens slaapuren' },
  ];
  for (let minutes = minMinutes; minutes <= maxMinutes; minutes += 15) {
    options.push({ value: minutes * 60, name: minutesToName(minutes) });
  }
  return options;
});

</script>
