export enum UserLoginState {
  LoggedOut = 'LoggedOut',
  LoggedInAdmin = 'LoggedInAdmin',
  LoggedInFreelancer = 'LoggedInFreelancer',
  LoggedInFreelancerUnpaid = 'LoggedInFreelancerUnpaid',
}

export enum UserType {
  FREELANCER = 'freelancer',
  AGENCY_WORKER = 'agency_worker',
  ADMIN = 'admin'
}