import * as atoms from './atoms';
import * as molecules from './molecules/index';
import * as organisms from './organisms/index';
import * as templates from './templates/index';

export default {
  ...atoms,
  ...molecules,
  ...organisms,
  ...templates
};
