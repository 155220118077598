import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';
import { languageOptions, skillOptions } from './form-options';

export const languageField = ref<FormField>({
  active: true,
  name: 'language_uuid',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.string({ required_error: 'Selecteer een taal' }),
  data: {
    id: 'subject',
    label: 'Taal',
    options: languageOptions([]),
    showDropdownPrepend: false,
  },
});

export const skillField = ref<FormField>({
  active: true,
  name: 'skill_level',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Selecteer een vaardigheid' }),
  data: {
    id: 'subject',
    label: 'Vaardigheid',
    options: skillOptions(),
    showDropdownPrepend: false,
  },
});
