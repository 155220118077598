import { RowType } from '@pidz/enums';
import { periodicalRateField, rateField } from './form-fields';


// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<StandardRateForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [rateField.value, periodicalRateField.value],
  },
];
