<template>
  <layout-desktop-wrapper>
    <travel-time-form ref="form" 
                      :form-data="formDataTravelTime"
                      is-edit/>
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { TravelTimeForm } from '@pidz/shared/components';
import { globalValidations, rows } from '@pidz/shared/forms/travel-time';
import { useUserStore } from '@pidz/stores';
import { secondsToMinutes } from '@pidz/utils';
import { computed, ref } from 'vue';

const form = ref<typeof TravelTimeForm>();
const userStore = useUserStore();

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)

const formDataTravelTime: PidzFormData<TravelTimeForm> = {
  globalValidations,
  values: {
    travelTime: secondsToMinutes(userStore.maxWorkRange!)
  },
  rows
}
const goBack = () => form.value?.onCancel()
defineExpose({ goBack })
</script>
