import {
  DateTime, Duration, DurationLikeObject
} from 'luxon';
export const convertLuxonDateTimeToJs = (dt: DateTime): Date => dt.toJSDate();
export const createDurationObject = (durationObject: DurationLikeObject): Duration => 
  Duration.fromObject(durationObject);

export const eventDateTimeToLuxon = ({date, time}:{date: string, time: string}) => {
  if (!time) return DateTime.fromISO(date);
  const [hour, minute, second] = time.split(':').map(Number);
  return DateTime.fromISO(date).set({ hour, minute, second });
}

export { DateTime };
