<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section class="travel-time-filter">
    <h3 class="my-4 font-bold">Maximale reistijd {{value}} minuten</h3>
    <range-slider v-model="value" :max="maxWorkRange / 60"/>
  </section>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import RangeSlider from '../RangeSlider.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: any,
  maxWorkRange:number,
}>();

const value = computed({
  get() {
    return props.modelValue / 60;
  },
  set(to) {
    emit('update:modelValue', to * 60);
  },
});

</script>
