<template>
  <section class="micro-sheet-pick-assignment">
    <h2>Werkzaam als</h2>
    <dropdown placeholder="Selecteer je functie" v-model="value"
              :options="options" name="functions" />
  </section>
</template>
<script lang="ts" setup>import { computed } from 'vue';
import Dropdown from '../../../components/Dropdown.vue';

const props = defineProps<{
  modelValue?:string,
  options:any[]
}>();
const emit = defineEmits(['update:modelValue']);

const value = computed({
  get: () => props.modelValue ?? null,
  set: (to) => emit('update:modelValue', to ?? undefined),
});
</script>
