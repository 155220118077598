<template>
  <p-card class="personal"
          :padding="padding"
          title="Persoonsgegevens"
          has-button
          icon-name="pencil"
          icon-size="large"
          :rounded="false"
          title-size="lg"
          @button-click="emit('edit-personal')">
    <template #content>
      <p-table class="personal__table"
               :columns="columns"
               :rows="rows"
               :omit-header="true"
               :colgroup="[{ width: '180px' }]"
               vertical-align="baseline"
               compact
               disable-horizontal-spacing>
        <template #row_type="row">
          <span class="personal__type--bold">{{ row.value.type }}</span>
        </template>
      </p-table>

      <section class="personal__files">
        <div v-for="(fileInfo, index) in fileRows"
             :key="index"
             class="personal__files--section">
          <span class="personal__files--title">{{ fileInfo.type }}</span>

          <div class="personal__files--filerow">
            <div v-if="fileInfo?.files?.length === 0 && 
              fileInfo.type === 'Betaalbewijs aansprakelijkheidsverzekering'">-</div>
            <p-status v-else-if="fileInfo?.files?.length === 0"
                      :variant="3"
                      text-override="Ontbreekt"/>
            <p-status-row v-else
                          :status="
                            isNearlyExpired(fileInfo?.files || []) ? 4 : fileInfo.value!.status
                          "
                          :date="fileInfo.value!.date"
                          :file-text="fileInfo.value!.fileText"
                          :date-text="fileInfo.value!.dateText"
                          :alignment="fileRow"
                          :reverse="true"
                          @open-files="(e: Event) => handleFileToggle(fileInfo?.files || [], e)"/>
          </div>
        </div>
      </section>

      <p-dropdown-list v-if="dropdownFiles && dropdownFiles.length > 1"
                       :open="showDropDown"
                       :position="position"
                       @close="() => (showDropDown = false)">
        <template #items>
          <div v-for="(file, index) in dropdownFiles" :key="index">
            <p-file-list-item :file-name="file.filename"
                              :show-download-button="true"
                              @download="downloadFile(file)"/>
          </div>
        </template>
      </p-dropdown-list>
    </template>
  </p-card>
</template>

<script lang="ts" setup>
import { DateTime } from '@pidz/date';
import { getMimeType, isNearlyExpired } from '@pidz/shared/utils';
import { useAssetStore, useUserStore } from '@pidz/stores';
import {
  convertToFileRowData,
  openFile,
  genders,
  countries,
  maritalStatus,
} from '@pidz/utils';
import { computed } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  data: ProfilePersonal;
  files: ProfileAssetsPersonal;
  padding: string;
  fileRow: string;
}>();

const assetStore = useAssetStore();
const userStore = useUserStore();

const emit = defineEmits(['edit-personal']);

const showDropDown = ref(false);
const position = ref<PidzDropdownListPosition>({ height: 0, left: 0 });

const columns = [
  { field: 'type', label: 'type' },
  { field: 'value', label: 'waarde' },
];

const rows = computed(() => [
  {
    type: 'Naam',
    value: props.data.fullName,
  },
  {
    type: 'Geboortedatum',
    value: DateTime.fromISO(props.data.birthdate).toFormat('dd-MM-yyyy'),
  },
  ...userStore.isUserAgencyWorker ? [{
    type: 'Geboorteplaats',
    value: props.data.birthPlace || '-',
  }] : [],
  ...userStore.isUserAgencyWorker ? [{
    type: 'Geboorteland',
    value: countries.get(props.data.birthCountry || '') || '-',
  }] : [],
  {
    type: 'Nationaliteit',
    value: countries.get(props.data.nationality),
  },
  ...userStore.isUserAgencyWorker ? [{
    type: 'Burgerlijke staat',
    value: maritalStatus.get(props.data.maritalStatus || '')|| '-',
  }] : [],
  {
    type: 'BSN',
    value: props.data.nationalIdentificationNumber,
  },
  {
    type: 'Geslacht',
    value: genders.get(props.data.gender),
  },
]);

const fileRows = computed(() => [
  ...userStore.isUserFreelancer ? [{
    type: 'Polis aansprakelijkheidsverzekering',
    value: convertToFileRowData(props.files.insuranceDocument || [], 'Polisblad'),
    files: props.files.insuranceDocument,
  }] : [],
  ...userStore.isUserFreelancer ? [{
    type: 'Betaalbewijs aansprakelijkheidsverzekering',
    value: convertToFileRowData(props.files.insuranceDocumentPaymentProof || [], 'Betaalbewijs'),
    files: props.files.insuranceDocumentPaymentProof,
  }] : [],
  {
    type: 'Verklaring omtrent gedrag',
    value: convertToFileRowData(props.files.vogDocument, 'VOG'),
    files: props.files.vogDocument,
  },
]);

const dropdownFiles = ref<Asset[] | undefined>();

const downloadFile = async (file: Asset) => {
  if ('id' in file && file.id) {
    const response = await assetStore.fetchAsset(file.id);
    const mimeType = getMimeType(file.filename);
    if (mimeType) openFile(response, mimeType);
  }
};

const handleFileToggle = (files: Asset[], e) => {
  e.stopPropagation();

  if (files.length === 1) {
    downloadFile(files[0]);
    return;
  }

  dropdownFiles.value = files;

  const target = e.currentTarget as HTMLInputElement;
  const leftOffset = document.body.clientWidth > 425 ? target.offsetLeft : 0;
  const left =
    target.offsetLeft + 400 > (target?.offsetParent?.clientWidth || 0)
      ? undefined
      : leftOffset + 25;
  const topOffset = target.offsetTop;
  const buttonHeight = target.clientHeight;
  const height = topOffset + buttonHeight;

  position.value = { height, left };
  showDropDown.value = !showDropDown.value;
};
</script>

<style scoped lang="scss">
.personal {
  &__table {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 16px;
  }

  &__type {
    &--bold {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__files {
    &--section {
      padding: 16px 0 0 0;
      border-top: 1px solid var(--pidz-grey-light);
    }

    &--title {
      font-weight: bold;
      font-size: 14px;
    }
    &--filerow {
      margin: 16px 0;
    }
  }
}
</style>
