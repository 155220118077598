<template>
  <teleport to="body">
    <div :class="['filter-overview w-full -right-full md:w-96 md:-right-96 z-[60]',
                  {'close' : closeFilter}]" v-bind="$attrs">
      <header class="filter-header">
        <top-bar>
          <template #left><div></div></template>
          <div>{{title}}</div>
          <template #right>
            <p-button class="right filter-close-button"
                      variant="ghost" 
                      @click="close">
              <p-icon icon-name="close"
                      size="large"
                      color="white"/>
            </p-button>
          </template>
        </top-bar>
      </header>

      <section class="filter-body">
        <slot />
      </section>

      <section class="filter-footer">
        <button class="filter-reset" @click="reset">Reset filters</button>
        <button @click="update">Filteren</button>
      </section>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import TopBar from './TopBar.vue';

defineProps<{
  title?: string
  class?: string
}>();
const closeFilter = ref<boolean>(false);
const emit = defineEmits(['update', 'reset', 'close']);

const close = () => {
  closeFilter.value = true;
  setTimeout(() => emit('close'), 300);
}

const reset = () => {
  closeFilter.value = true;
  setTimeout(() => emit('reset'), 300);
}

const update = () => {
  closeFilter.value = true;
  setTimeout(() => emit('update'), 300);
}

onMounted(() => {
  document.body.classList.add('filter-open');
});

onUnmounted(() => {
  closeFilter.value = true;
  document.body.classList.remove('filter-open');
});
</script>

<style lang="scss">
.filter-open {
  overflow: hidden;
  width: 100dvw;
}

@keyframes open {
  to {
    right: 0;
  }
}
@keyframes close {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}

.filter-overview {
  height: 100vh;
  top: 0;
  position: absolute;
  background-color: var(--pidz-blue);
  font-weight: 700;
  color: var(--pidz-white);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  transition: all ease-in-out .3s;
  animation: open .3s forwards ease-in;

  &.close {
    animation: close .3s forwards ease-in;
  }

  h3 {
    font-size: 14px;
  }

  .filter-header {
    width: 100%;
    .filter-close-button {
      color: inherit;
      background-color: transparent;
      border: none;
    }
  }

  .filter-body {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px 16px 16px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .filter-footer {
    position: sticky;
    padding: 16px;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;

    button {
      border: 0;
      font-family: inherit;
      color: var(--pidz-blue);
      display: block;
      text-decoration: none;
      padding: 12px;
      font-size: 16px;
      font-weight: 400;
      background-color: var(--pidz-white);
      text-align: center;

      &.filter-reset {
        color: var(--pidz-white);
        background-color: var(--pidz-blue);
        border: 2px solid var(--pidz-white);
      }
    }
  }
}
</style>
