<template>
  <svg width="48px" height="48px"
       viewBox="0 0 48 48" version="1.1"
       xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <g id="Group" transform="translate(1 1)">
      <path d="M23 46C35.7025 46 46 35.7025 46 23C46 10.2975 35.7025 0 23 0C10.2975 0 0 10.2975 0 23C0 35.7025 10.2975 46 23 46Z" id="Oval"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2" />
      <path d="M35 16L26 19M26 19L35 22" id="Combined-Shape"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2"
            stroke-linecap="round" />
      <path d="M11 16L20 19M20 19L11 22" id="Combined-Shape-Copy"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2"
            stroke-linecap="round" />
      <path d="M31.8462 37.1538C31.8462 32.2682 27.8856 28.3077 23 28.3077C18.1144 28.3077 14.1538 32.2682 14.1538 37.1538L31.8462 37.1538Z" id="Path-Copy-2"
            fill="none" fill-rule="evenodd"
            stroke="#152935" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
</template>
