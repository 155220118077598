<template>
  <svg class="GeneralChevronWhite pidz-icon" width="16px"
       height="16px" viewBox="0 0 16 16"
       version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/Chevron/Open/White</title>
    <g id="Icon/Chevron/Open/White" stroke="none"
       stroke-width="1" fill="none"
       fill-rule="evenodd">
      <g id="Icon/Chevron" transform="translate(3.000000, 4.000000)"
         fill="currentColor" fill-rule="nonzero">
        <g id="Group-5">
          <path d="M0.443881961,2.56130712 L6.69221671,2.83439448 C6.94862798,2.84560109 7.15491176,3.04912666 7.16956875,3.30536385 L7.23304443,4.41506146 C7.24881424,4.69075318 7.0381059,4.92702944 6.76241418,4.94279925 C6.74563531,4.94375901 6.72881872,4.9438722 6.71202845,4.94313837 L0.463693702,4.67005101 C0.207282432,4.6588444 0.000998647684,4.45531883 -0.0136583452,4.19908164 L-0.0771340247,3.08938403 C-0.0929038335,2.81369231 0.117804507,2.57741605 0.393496227,2.56164625 C0.410275099,2.56068648 0.427091691,2.56057329 0.443881961,2.56130712 Z" id="Rectangle"
                transform="translate(3.577955, 3.752223) rotate(-45.000000) translate(-3.577955, -3.752223) "></path>
          <path d="M4.30778069,2.83439194 L10.5561206,2.5613078 C10.8319996,2.54925049 11.0654181,2.76312024 11.0774754,3.03899925 C11.0782092,3.05578955 11.078096,3.07260617 11.0771363,3.08938507 L11.0136612,4.19905759 C10.999004,4.45529474 10.7927202,4.65882015 10.5363089,4.67002662 L4.28796903,4.94311076 C4.01209001,4.95516807 3.7786715,4.74129832 3.76661419,4.4654193 C3.76588037,4.448629 3.76599356,4.43181238 3.76695334,4.41503348 L3.83042838,3.30536096 C3.84508556,3.04912381 4.05136944,2.84559841 4.30778069,2.83439194 Z" id="Rectangle-Copy-16"
                transform="translate(7.422045, 3.752209) rotate(-315.000000) translate(-7.422045, -3.752209) "></path>
        </g>
      </g>
    </g>
  </svg>
</template>
