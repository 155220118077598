<template>
  <div class="bank">
    <div class="bank__fields" :class="{ 'bank__fields--web': isWeb }">
      <p-form ref="bankForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-buttons="true"/>

      <section v-if="mandateBankAccount" class="border-t py-4 border-neutral-300">
        <div class="font-bold">Let op!</div>
        <div>Je hebt een machtiging met een ander rekeningnummer ({{ mandateBankAccount }}) afgegeven dan hierboven 
          vermeld. Klik op de knop om een nieuwe machtiging te verstrekken  om het rekeningnummer te wijzigen.
        </div>
        <p-button class="mt-4"
                  @click="() => revokeAndRequestNewMollieMandate(webUrl ?? 'https://app.mijnpidz.nl')">Machtiging wijzigen</p-button>
      </section>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { MollieMandateModal } from '@pidz/shared/components';
import { useFormFunctions } from '@pidz/shared/forms';
import { automaticIncassoField } from '@pidz/shared/forms/profile/bank';
import { mollieMandate, revokeAndRequestNewMollieMandate, rerouteToOverview } from '@pidz/shared/utils';
import { useUserStore, useMollieStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { onBeforeMount, ref } from 'vue';
import {
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRouter,
} from 'vue-router';

interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<ProfileBank>;
  isDisabled?: boolean;
  mandateBankAccount?: string;
  webUrl: string; // need to have url based on environment, but can't access it here because it's a lib
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  disabled: false,
  mandateBankAccount: undefined,
});

const bankForm = ref<any>();

const router: Router = useRouter();
const userStore = useUserStore();
const mollieStore = useMollieStore();
const modal = useModalController();

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'profile_modal_edit_cancel' : 'profile_modal_add_cancel'

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  warnBeforeCancel,
} = useFormFunctions(
  bankForm,
  [],
  () => rerouteToOverview(router, 'profile'),
  () => Promise.resolve(),
  props.isEdit,
  'bank gegevens',
);
const onSubmit = async () => {
  const submit = bankForm?.value?.handleSubmit(
    async (formValues: ProfileBank) => {
      if (
        bankForm?.value?.isValidating ||
        !bankForm?.value?.meta.valid ||
        !userStore.freelancer
      )
        return;

      formSubmitted.value = true;

      let freelancer = userStore.freelancer;

      let incassoChanged = freelancer.incasso_organisation !== formValues.automaticIncasso;

       //TODO CID-16760 check when BE ticket is complete if this works
      if(userStore.isUserFreelancer){
        freelancer.bic_organisation = formValues.bic;
        freelancer.iban_organisation = formValues.iban;
        freelancer.name_of_organisation = formValues.name;
        freelancer.incasso_organisation = formValues.automaticIncasso || false
      }
      else{
        freelancer.bic_personal = formValues.bic;
        freelancer.iban_personal = formValues.iban;
        freelancer.name_bank_account = formValues.name;
      }

      if (incassoChanged) {
        await userStore.updateAutomaticPaymentChoice(formValues.automaticIncasso ? 'incasso' : 'ideal');
      }

      await userStore.updateFreelancerData(freelancer);

      if (incassoChanged && formValues.automaticIncasso && await mollieStore.isMollieMandateRequired()) {
        modal.open(MollieMandateModal, {
          mandateCB: async () => await mollieMandate(props.webUrl ?? 'https://app.mijnpidz.nl') });
      } 

      rerouteToOverview(router, 'profile');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

onBeforeMount(async () => {
  automaticIncassoField.value.active = userStore.isUserAgencyWorker ? false : true;
});

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit });
</script>
<style scoped lang="scss">
.bank {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-bottom: 8px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}
:deep(.input__checkbox label) {
  flex: 1;
}
</style>
