import { useAssetStore, useUserStore } from '@pidz/stores';
import { RouteLocationNormalized } from 'vue-router';

//@TODO add navigation guard to a shared-util lib

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function checkFreelancerData(_to: RouteLocationNormalized, _from: RouteLocationNormalized) {
  const userStore = useUserStore()
  const assetStore = useAssetStore()

  if(!userStore.freelancer || !assetStore.assets){
    await userStore.fetchFreelancerData(true)
    assetStore.fetchAllAssetData()
  }
}