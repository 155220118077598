<template>
  <div class="education">
    <div class="education__fields" :class="{ 'education__fields--web': isWeb }">
      <p-form ref="educationForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-form="isDisabled"
              :disable-buttons="true"/>
    </div>
    <div class="education__files"
         :class="[
           { 'education__files--web': isWeb },
           { 'education__files--disabled': isDisabled },
         ]">
      <p-expanded-picker :new-files="newFiles"
                         :existing-files="existingAssets"
                         :disabled="isDisabled"
                         :show-status="false"
                         @upload="onAddFile"
                         @delete="onDeleteFile"
                         @download="onDownloadFile"/>
      <p v-if="showFileError" class="file__error">
        Je moet voor deze opleiding een bestand toevoegen
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import {
  EDUCATION_ASSET_TYPE_ID,
  subjectField,
  educationOptions,
  toggleOtherSubjectsField,
  resetOtherEducationField,
} from '@pidz/shared/forms/cv/education';
import { rerouteToOverview, fileRemove, fileUpload } from '@pidz/shared/utils';
import { useCVStore, useAssetStore } from '@pidz/stores';
import { onBeforeUnmount, computed, onMounted, ref, watch } from 'vue';
import {
  useRouter,
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';

interface Props {
  educationItem?: Education;
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<EducationForm> | PidzFormData<NewEducationForm>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  isDisabled: false,
});

const educationForm = ref<any>();

const router: Router = useRouter();
const cvStore = useCVStore();
const assetStore = useAssetStore();

const showFileError = computed(() => {
  return (
    (newFiles.value.length === 0 &&
      existingAssets.value.length < 1 &&
      educationForm?.value?.submitCount > 0) ||
    (deletedFiles.value.length ===
      (props?.formData?.values?.assets || []).length &&
      !(newFiles.value.length > 0))
  );
});

const itemId = props.educationItem?.id ?? 0;

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  onDelete,
  newFiles,
  deletedFiles,
  existingAssets,
  onAddFile,
  onDownloadFile,
  onDeleteFile,
  warnBeforeCancel,
} = useFormFunctions(
  educationForm,
  props.educationItem?.assets,
  () => rerouteToOverview(router, 'cv'),
  async () => {
    props.educationItem?.assets.forEach(
      async (asset: Asset) => await assetStore.removeAsset(asset.id!),
    );
    await cvStore.removeEducation(itemId);
    succesToaster('Succesvol verwijderd');
  },
  props.isEdit,
  'opleiding',
);

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'cv_modal_edit_cancel' : 'cv_modal_add_cancel';
onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const onSubmit = async () => {
  const submit = educationForm?.value?.handleSubmit(
    async (formValues: EducationForm) => {
      if (
        educationForm?.value?.isValidating ||
        !educationForm?.value?.meta.valid ||
        showFileError.value
      ) {
        return;
      }
      const files = await fileUpload(newFiles.value, EDUCATION_ASSET_TYPE_ID);
      if (deletedFiles.value.length > 0 && props.isEdit)
        fileRemove(deletedFiles.value);

      const { subject, ...education } = formValues;

      let item: Omit<Education, 'id'> = {
        ...education,
        subject: {
          id: subject,
        },
        start_date: education.end_date, // TODO BE needs start_date remove here when fixed in BE
        assets: props.isEdit ? [...existingAssets.value, ...files] : files,
      };

      let editItem: Education = {
        ...item,
        id: itemId,
      };

      props.isEdit
        ? await cvStore.updateEducation(editItem)
        : await cvStore.addEducation(item);

      formSubmitted.value = true;
      rerouteToOverview(router, 'cv');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

onMounted(async () => {
  await cvStore.loadEducationSubjects();
  subjectField.value.data.options = educationOptions(
    cvStore.educationSubjects || [],
  );
});

watch(
  () => educationForm?.value?.values?.subject,
  (value) => {
    toggleOtherSubjectsField(
      value,
      educationForm,
      props.formData.values.other_subject || '',
    );
  },
);

onBeforeUnmount(() => resetOtherEducationField());

defineExpose({
  isSubmitDisabled,
  isSubmitting,
  onCancel,
  onSubmit,
  onDelete,
  onDownloadFile,
  showFileError,
});
</script>
<style scoped lang="scss">
.education {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
  &__files {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-top: 0;

    &--web {
      padding: 32px;
      padding-top: 0;
      box-shadow: none;
    }
    &--disabled {
      padding-bottom: 0;
    }
  }
}
.file {
  &__error {
    font-size: 14px;
    color: red;
  }
}
</style>
