<template>
  <select class="dropdown" v-model="singleValue"
          :name="name">
    <option v-if="placeholder" :value="null"
            disabled
            selected>{{placeholder}}</option>
    <option v-for="{name, value} in options"
            :value="value"
            :key="value"
            :disabled="value === ''">{{name}}</option>
  </select>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export interface Option {
  name:string;
  value:number|string;
}

const props = defineProps<{
  name?: string,
  placeholder?: string,
  modelValue: string | number | null,
  options: Option[],
}>();

const emit = defineEmits(['update:modelValue']);

const singleValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

</script>

<style lang="scss">

.dropdown {
  font: inherit;
  color: var(--pidz-body-text);
  min-height: 40px;
  width: 100%;
  border: 1px solid var(--pidz-black);
  padding: 0 12px;
  border-radius: 5px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // Chevron
  background-color: var(--pidz-white);
  background-size: 16px 16px;
  background-origin: content-box;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url('@app/icons/chevron.svg');
}
</style>
