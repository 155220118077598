<template>
  <table :class="['table', getBorderClasses(),
                  {'table--responsive': responsive, 
                   'table-layout-fixed': colgroup, 
                   compact, disableHorizontalSpacing}]">
    <colgroup v-if="colgroup">
      <template v-for="(col, _key) in colgroup" :key="_key">
        <col :span="col.span"
             :style="{width: col.width }" />
      </template>
    </colgroup>
    <thead v-if="!omitHeader">
      <tr>
        <th v-for="col in columns" :key="col.field">
          <template v-if="slots[`column_${col.field}`]">
            <slot :name="`column_${col.field}`" />
          </template>
          <template v-else>
            {{ col.label }}
          </template>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in rows" :key="row.id">
        <template v-for="col in columns" :key="col">
          <td>
            <div v-if="col.valueTemplate" v-html="col.valueTemplate(row)"></div>
            <template v-else-if="slots[`row_${col.field}`]">
              <slot :name="`row_${col.field}`" :value="row" />
            </template>
            <template v-else>
              {{ row[col.field] }}
            </template>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>
<script lang="ts" setup>
import { computed, useSlots } from 'vue';

const slots = useSlots();

const props = withDefaults(
  defineProps<{
    colgroup?: TableColgroupColumn[]
    rows: any[];
    columns: TableColumn[];
    omitHeader?: boolean;
    alignContent?: string;
    borders?:(keyof TableBorderTypes)[];
    responsive?: boolean;
    verticalAlign?: 'baseline'|'length'|'sub'|'super'|'top'|'text-top'|'middle'
    |'bottom'|'text-bottom'|'initial'|'inherit';
    compact?: boolean;
    disableHorizontalSpacing?: boolean;
  }>(),
  {
    rows: () => [],
    omitHeader: false,
    alignContent: 'left',
    borders: () => ['none'],
    asCard: false,
    verticalAlign: 'baseline',
    compact: false,
    disableHorizontalSpacing: false
  }
);

const getBorderClasses = () => props.borders.map((border: string) => `table--border-${border}`);
const gridColumnEnd = computed(() => props.columns.length);
</script>

<style lang="scss">
.table {
  width: 100%;
  border-collapse: collapse;
  z-index: 0;

  &.compact {
    td, th {
      padding: 8px;
    }
  }

  &.disableHorizontalSpacing {
    td, th {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: 480px){
    &--responsive {
      tr,
      td,
      th {
        display: grid;
      }

      tr {
        grid-template-columns: 1fr 1fr;
        border-bottom: none;

      }
      thead tr th:first-child {
        display: none;
      }
      tr td:first-child {
        grid-column-start: 1;
        grid-column-end: v-bind(gridColumnEnd);
        text-align: left;
        padding-top: 8px;
        padding-bottom: 0px;
        border-top: none;
      }
    }
  }
  &--border-outline {
    border: 1px solid var(--pidz-grey-light);
  }

  &--border-none {
    th,
    td {
      border: none;
    }
  }

  &--border-column {
    th,
    td {
      border-right: 1px solid var(--pidz-grey-light);
    }
  }

  &--border-row {
    thead {
      border-bottom: 1px solid var(--pidz-grey-light);}
    tr {
      border-bottom: 1px solid var(--pidz-grey-light);
    }
    tr:last-child {
      border-bottom: none;
    }
  }

  &--border-both {
    tr,
    td,
    th {
      border: 1px solid var(--pidz-grey-light);
    }
  }
}

th,
td {
  padding: 16px;
  text-align: v-bind(alignContent);
  vertical-align: v-bind(verticalAlign);
  overflow-wrap: break-word;
}

.table-layout-fixed {
  table-layout: fixed
}
</style>
