import { UserState } from '.';

export default (state:UserState) => {
  const mutable:UserState = state;

  const setImpersonation = async (freelancerUUID:string) => {
    mutable.assumeUser = freelancerUUID;
  };

  const impersonationConfigurator = () => (state.assumeUser ? {
    headers: {
      'x-pidz-rpc-assume-user': state.assumeUser,
    },
  } : {});

  return {
    setImpersonation,
    impersonationConfigurator,
  };
};
