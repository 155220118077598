<template>
  <div class="itemLink">
    <div class="itemLink__column--left">
      <p-icon :icon-name="item.status === 'linked' ? 'check' : 'close'"
                 boxed
                 size="small"
                 :background="item.status === 'linked' ? 'green' : 'grey'"/>
    </div>
    <div class="itemLink__column--middle">
      <div class="link_x_title">
        {{ item.organisation_name }}
      </div>
      <div class="itemLink_subtitle">Gekoppelde afdelingen: {{ item.linked_departments_count}}</div>
    </div>
    <div class="itemLink__column--right">
      <p-button variant="ghost"
                   @click="emit('edit-organisation', item.organisation_id)">
        <p-icon icon-name="pencil"
                   color="blue"/>
      </p-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  item: Organisation;
}>();

const emit = defineEmits(['edit-organisation']);
</script>
<style scoped lang="scss">
.itemLink {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  &__title{
    font-weight: bold;
    padding-bottom: 8px;
  }

  &__column{
    &--left{
      padding-right: 16px;
    }
    &--middle{
      flex-grow: 1;
    }
    &--right, &--left{
      display: flex;
      align-items: center;
    }
  }

  &__toggle{
    margin-right: 16px;
  }

}
</style>
