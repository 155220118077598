<template>
  <div class="bg-white sticky bottom-0 z-50 border-solid border-t border-neutral-200 md:hidden">
    <div class="mb-safe flex items-center justify-evenly 
     min-h-16">
    
      <component :is="item.component"
                 v-for="(item, i) in menuItems"
                 :key="i"
                 v-bind="isExternalLink(item) && {'href': item.to, 'target': '_blank'}"
                 :to="item.to"
                 class="inline-flex flex-col items-center text-center text-neutral p-2"
                 active-class="!text-blue font-bold [&_.activeIcon]:flex [&_.normalIcon]:hidden"
                 @click="$emit('closeMenu')">
        <span class="relative flex items-center justify-center">
          <p-badge v-if="item.openCount" 
                   :content="item.openCount"
                   :variant="(item.badgeVariant) ? item.badgeVariant : 'info'"
                   class="absolute -top-1 -right-0 translate-x-1/2" />
          <template v-if="item.icon">
            <span class="normalIcon flex">
              <p-icon :icon-name="item.icon" />
            </span>
            <span class="activeIcon hidden">
              <p-icon :icon-name="item.icon" color="blue" />
            </span>
          </template>
        </span>
        <span class="text-xxs">{{ item.name }}</span>
      </component>
  
      <span class="inline-flex flex-col items-center text-center p-2 cursor-pointer"
            :class="[{'!text-blue font-bold': menuOpen}]"
            @click="$emit('toggleMenu')">
        <p-icon icon-name="more" :color="menuOpen ? 'blue' : ''" />
        <span class="text-xxs">meer</span>
      </span>

    </div>
  </div>
</template>

<script setup lang="ts">
import { useDashboardStore, useUserStore } from '@pidz/stores';
import { computed } from 'vue';

const dashboardStore = useDashboardStore();
const userStore = useUserStore();

defineProps<{ menuOpen: boolean }>();
defineEmits(['closeMenu', 'toggleMenu']);

const isExternalLink = (item: MenuItem): boolean => item.component === 'a'

const menuItems = computed<MenuItem[]>(() => [
{
    name: 'Dashboard',
    icon: 'dashboard',
    to: { name: 'dashboard' },
    component: 'router-link'
  },
  {
    name: 'Uitnodigingen',
    icon: 'mail',
    to: { name: 'invitations' },
    openCount: dashboardStore.invitationCount,
    component: 'router-link'
  },
  {
    name: 'Opdrachten',
    icon: 'jobs',
    to: { name: 'shifts' },
    component: 'router-link',
  },
  {
    name: 'Uren',
    icon: 'time',
    to: userStore.isUserAgencyWorker ? 'https://helloflexpeople.helloflex.com/user/login' : { name: 'sheets' } , 
    component: userStore.isUserAgencyWorker ? 'a' : 'router-link'
  }
]);
</script>