<template>
  <p-banner v-if="bannerInfo" :text="bannerInfo.text"
               :type="bannerInfo.type"/>
  <layout-desktop-wrapper>
    <registration-form ref="form" 
                       :registration-item="registrationItem"
                       :form-data="formDataRegistration" 
                       is-edit
                       :is-disabled="isEditDisabled"/> 
    <form-delete-row name="Registratie" :on-delete="form?.onDelete"
                     class="border-t border-neutral-200"/>      
    <form-buttons-row v-if="!isEditDisabled"
                      :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled || form?.showFileError || isEditDisabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import FormDeleteRow from '@app/components/FormDeleteRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { CvSectionStatus } from '@pidz/enums';
import { RegistrationForm } from '@pidz/shared/components';
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/registration';
import { useCVStore } from '@pidz/stores';
import { getBannerInfo } from '@pidz/utils'
import { DateTime } from 'luxon'
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const form = ref<typeof RegistrationForm>();

const route = useRoute()
const cvStore = useCVStore();

const registrationItem = computed(() => cvStore.getRegistrationById(Number(route.params.id)));

const bannerInfo = computed(() => {
  const { status, deny_reason } = registrationItem.value as Registration;
  return getBannerInfo('registratie/keurmerk', status, deny_reason|| undefined);
})

const isEditDisabled = computed(() => 
  !!registrationItem?.value?.status && [CvSectionStatus.APPROVED, CvSectionStatus.EXPIRED]
  .includes(registrationItem.value.status));

const formatRegistration = (registration: Registration ): RegistrationForm => {
  registration.registration_number = registration.registration_number.toString()
  const { acquired_at, type, ...item} = registration
  return { 
    ...item, 
    type: type.id,
    acquired_at: formatYmd(DateTime.fromISO(acquired_at)),
  }
}

const formDataRegistration: PidzFormData<RegistrationForm> = {
  globalValidations,
  values: formatRegistration(registrationItem.value as Registration),
  rows
}

const loadStores = async () => {
  await cvStore.loadResume();
};

onMounted(async () => {
  await loadStores();
});
</script>
<style lang="scss" scoped>
.registration{
  width: 100%;
  
  &__delete-btn {
    background-color: var(--pidz-white);
    padding: 32px 16px;
  }
  &__delete-text {
    color: var(--pidz-black);
  }
}
</style>
