import {
  onUnmounted, Ref, ref, unref, watch,
} from 'vue';

const useIsElementInView = (element: Ref<HTMLElement | undefined>, threshold: number = 0) => {
  const isElementInView = ref(false);
  const observerRef = ref<IntersectionObserver>(new IntersectionObserver(
    (e) => { isElementInView.value = e[0].isIntersecting; },
    {
      threshold,
      rootMargin: '0px',
    },
  ));

  if (element.value !== null) {
    watch(element, (newElement, existingElement) => {
      if (existingElement !== null) observerRef.value.disconnect();
      const targetElement = unref(newElement!);
      observerRef.value.observe(targetElement);
    });

    onUnmounted(() => {
      if (observerRef.value) observerRef.value.disconnect();
    });
  }

  return isElementInView;
};

export default useIsElementInView;
