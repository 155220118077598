const contactOptions = new Map([
  [1, 'Een ZZP\'er'],
  [2, 'Een zorginstelling'],
  [3, 'Het PIDZ-kantoor'],
]);

export const contactDropdownOptions = (): FormDropDownOption[] => {
  const options: FormDropDownOption[] = [];
  contactOptions.forEach((type, key) => {
    options.push({
      id: key,
      value: key,
      label: type,
    });
  });
  return options;
};

const contactType = new Map([
  ['message', 'Ik wil een melding maken'],
  ['complaint', 'Ik heb een klacht']
])

export const radioGroupOptions = () => {
  const options: any[] = [];
  contactType.forEach((type, key) => {
    options.push({
      value: key,
      label: type,
    });
  });
  return options;
}