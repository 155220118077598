<template>
  <teleport to="body">
    <div class="modal-backdrop"
         data-prevent-pull
         v-on="!preventClose ? { click: closeModal } : {}">
      <div class="modal" v-bind="$attrs"
           @click.stop>
        <header v-if="!preventClose" class="modal-header">
          <nav>
            <button class="modal-close-btn" @click="closeModal">
              <general-close />
            </button>
          </nav>
        </header>
        <section class="modal-body">
          <slot />
        </section>
        <footer v-if="!customFooter || $slots.footer" class="modal-footer">
          <slot name="footer">
            <button @click="modalController.close">Sluit</button>
          </slot>
        </footer>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts" setup>
import GeneralClose from '@app/icons/GeneralClose.vue';
import { useModalController } from '@pidz/utils';
import { onMounted, onUnmounted } from 'vue';

const modalController = useModalController();

defineProps<{
  preventClose?: boolean;
  customFooter?: boolean;
}>();

const emit = defineEmits(['modalClosed']);

const closeModal = () => {
  emit('modalClosed');
  modalController.close();
};

onMounted(() => {
  document.body.classList.add('modal-open');
});

onUnmounted(() => {
  document.body.classList.remove('modal-open');
});
</script>
<style lang="scss">
.modal-backdrop {
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 40;
  position: absolute;
  display: flex;
  padding: 0 24px;
  align-items: center;
  justify-content: space-around;
  background-color: #011a3464;

  .modal {
    padding: 24px;
    width: 100%;
    max-width: 800px;
    background-color: var(--pidz-white);
    box-shadow: var(--shadow-dark);
    max-height: calc(var(--full-height) - 48px);

    button {
      font: inherit;
      color: inherit;
      background-color: transparent;
      border: none;
    }

    .modal-header {
      nav {
        display: flex;
        justify-content: flex-end;
      }
      .modal-close-btn {
        margin-right: -5px;
        padding: 0;
        svg {
          path {
            fill: var(--pidz-body-text);
          }
        }
      }
    }
    h1 {
      font-size: 28px;
      margin: 0;
      line-height: 2rem;
      color: var(--pidz-blue);
    }
    h2 {
      font-size: 28px;
      margin: 14px 0;
      color: var(--pidz-blue);
    }

    .modal-body p {
      color: var(--pidz-body-text);
    }

    .modal-footer {
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      column-gap: 16px;

      button {
        width: 100%;
        padding: 14px 0;
        color: var(--pidz-white);
        background-color: var(--pidz-blue);

        &.button-outline {
          color: var(--pidz-blue);
          background-color: var(--pidz-white);
          border: 2px solid var(--pidz-blue);

          &.inactive {
            color: var(--pidz-grey-dark);
          }
        }

        &.error {
          background-color: var(--pidz-red);
          color: var(--pidz-white);
        }
      }
    }
  }
}
</style>
