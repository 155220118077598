<template>
  <layout-desktop-wrapper>
    <organisation-departments ref="departments"
                              v-model:is-dirty="isDirty"
                              v-model:is-loading="isLoading"/>
    <form-buttons-row :is-loading="isLoading"
                      :on-cancel="departments?.onCancel" :on-submit="departments?.onSubmit"
                      :is-submit-disabled="!isDirty || isLoading"/>
  </layout-desktop-wrapper>
</template>

<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { OrganisationDepartments } from '@pidz/shared/components'
import { ref } from 'vue';

const isDirty = ref(false);
const isLoading = ref(false)
const departments = ref<typeof OrganisationDepartments>();
const goBack = () => departments.value?.onCancel()
defineExpose({ goBack })
</script>