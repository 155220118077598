import { DateTime, formatYmd, isAfter } from '@pidz/date';
import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';
import { educationOptions } from './form-options';

export const subjectField = ref<FormField>({
  active: true,
  name: 'subject',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een opleiding in' }),
  data: {
    id: 'subject',
    label: 'Opleiding',
    options: educationOptions([]),
    showDropdownPrepend: false,
  },
});

export const otherEducationField = ref<FormField>({
  active: false,
  name: 'other_subject',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een overige opleiding in' })
    .min(1, { message: 'Vul een overige opleiding in' })
    .max(128, { message: 'Overige opleiding mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'other_subject',
    label: 'Overige opleiding',
    inputType: 'text',
    placeholder: 'Vul je overige opleiding in',
  },
});

export const specializationField = ref<FormField>({
  active: true,
  name: 'specialization',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(128, { message: 'Naam mag maximaal 128 karakters zijn' })
    .optional(),
  width: 100,
  data: {
    id: 'specialization',
    label: 'Specialisatie/studierichting (optioneel)',
    inputType: 'text',
    placeholder: 'Bvb Algemeen',
  },
});

export const institutionNameField = ref<FormField>({
  active: true,
  name: 'institution_name',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een naam in' })
    .min(1, { message: 'Vul een naam in' })
    .max(128, { message: 'Naam mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'institution_name',
    label: 'Naam opleidingsinstituut',
    inputType: 'text',
    placeholder: 'Bvb ROC Tilburg',
  },
});

export const institutionPlaceField = ref<FormField>({
  active: true,
  name: 'institution_place',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een plaats in' })
    .min(1, { message: 'Vul een plaats in' })
    .max(128, { message: 'Plaats mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'institution_place',
    label: 'Plaats opleidingsinstituut',
    inputType: 'text',
    placeholder: 'Bvb Tilburg',
  },
});

export const endDateField = ref<FormField>({
  active: true,
  name: 'end_date',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .refine(
      (data): boolean =>
        !isAfter(
          DateTime.fromISO(data).startOf('day'),
          DateTime.now().startOf('day'),
        ),
      {
        message:
          'De datum waarop je je diploma hebt behaald mag niet in de toekomst liggen',
      },
    ),
  width: 100,
  data: {
    id: 'end_date',
    label: 'Datum behaald',
    inputType: 'date',
    preIcon: 'agenda',
    max: formatYmd(DateTime.now()),
  },
});
