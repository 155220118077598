<template>
  <p-modal class="cancel-modal" @modal-closed="modalController.close()">
    <h1 class="font-bold">Betalen via een automatische incasso</h1>
    <p>Je hebt ingesteld dat je de kosten voor mijnPIDZ wilt betalen via een automatische incasso. 
      Daarom dien je een machtiging te verstrekken zodat wij de kosten kunnen afschrijven. 
      Klik op de onderstaande knop om deze machtiging nu te verstrekken.</p>
    <template #footer>
      <p-button @click="modalController.close()">Annuleren</p-button>
      <p-button outlined
                @click="mandateCB()">
        Machtiging verstrekken
      </p-button>
    </template>
  </p-modal>
</template>
<script setup lang="ts">
import { useModalController } from '@pidz/utils';

const modalController = useModalController();

defineProps<{
  mandateCB: Function;
}>();
</script>
