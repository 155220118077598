<template>
  <div class="infinity-spinner">
    <svg viewBox="0 0 50 50">
      <circle class="circle" cx="25"
              cy="25" r="20" />
    </svg>
  </div>
</template>

<style lang="scss">
.infinity-spinner {
  width: 40px;
  height: 40px;

  & svg {
    animation: rotate 1.5s linear infinite;
    height: 100%;
    width: 100%;
    stroke: var(--pidz-white);

    & circle {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;

      stroke-linecap: round;
      fill: none;
      stroke-width: 4;
      animation: dash 1.5s ease-in-out infinite 0s;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: -124;
  }
}
</style>
