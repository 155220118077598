import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Auth from './authProxy';

const isAccessTokenExpired = (currentSession: CognitoUserSession) => {
  const expiration = currentSession.getAccessToken().getExpiration();
  const expiresAtInSeconds = expiration - Date.now() / 1000;

  return expiresAtInSeconds < 60;
};

const generateAuthHeader = (currentSession?: CognitoUserSession) => {
  if (!currentSession) {
    return {};
  }

  return {
    headers: {
      Authorization: `Bearer ${currentSession.getAccessToken().getJwtToken()}`,
    },
  };
};

let refreshPromise: Promise<CognitoUserSession | undefined> | undefined;

export const tokenConfigurator = async () => {
  let currentSession = await Auth.getCurrentSession();

  if (currentSession && isAccessTokenExpired(currentSession)) {
    if (!refreshPromise) {
      refreshPromise = Auth.refresh();
    }

    currentSession = await refreshPromise;
    refreshPromise = undefined;
  }

  return generateAuthHeader(currentSession);
};
