<template>
  <micro-sheet-row-card-view :date="formatDate(sheetRow.startTime, dayMonthOptions)"
                             :actual-time="actualTime"
                             :was-time="wasTime"
                             :break-time="breakTime"
                             :work-during-sleep-time="sheetRow.timeWorkedDuringSleep"
                             :total-time="totalTime"
                             :travel-distance="travelDistance"
                             :config="config"
                             :has-daylight-savings-split="hasDaylightSavingsSplit"
                             :overtime-remark="sheetRow.overtimeRemark"
                             @click="cardClick"/>
</template>
<script lang="ts" setup>
import { MicroSheetRowConfig } from '@app/pages/sheets/types';
import router from '@app/router';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import MicroSheetRowCardView from './MicroSheetRowCardView.vue';
const props = defineProps<{
  sheetId: any;
  sheetRow: any;
  config: MicroSheetRowConfig;
}>();


const dayMonthOptions = { day:'numeric', month:'long' };
const hourMinuteOptions = { hour:'2-digit', minute:'2-digit' };
const formatDate = (dateString: string, options:any) =>
DateTime.fromISO(dateString).setLocale('nl-nl').toLocaleString(options);

const dateToTime = (isoDate: string) => formatDate(isoDate, hourMinuteOptions);
const addSeconds = (isoDate:string, seconds:number) => {
  const result = new Date(isoDate);
  result.setUTCSeconds(result.getUTCSeconds() + seconds);
  return result.toISOString();
};

const startTime = computed(() => DateTime.fromISO(props.sheetRow.startTime).toFormat('HH:mm'));
const endTime = computed(() => {
  return DateTime.fromISO(props.sheetRow.startTime).plus({seconds: props.sheetRow.duration}).toFormat('HH:mm');
});

const actualTime = computed(() => {
  const { sheetRow } = props;
  let actualStartTime = addSeconds(sheetRow.startTime, sheetRow.startOffset)
  let actualEndTime = addSeconds(sheetRow.startTime, sheetRow.duration + sheetRow.endOffset)
  if (actualStartTime === actualEndTime) {
    actualStartTime = addSeconds(sheetRow.startTime, sheetRow.duration)
    actualEndTime = addSeconds(sheetRow.startTime, sheetRow.duration)
  }
  return [
    dateToTime(actualStartTime),
    dateToTime(actualEndTime),
  ].join(' - ');
});

const wasTime = computed(() => {
  const planTime = startTime.value + ' - '+endTime.value;
  return planTime != actualTime.value ? `(was ${planTime})` : undefined;
});

const breakTime = computed(() => props.sheetRow.breaks.reduce((a, v) => a + v.duration, 0));
const travelDistance = computed(() => props.sheetRow.commutes.reduce((a, v) => a + v.distance, 0));

const totalTime = computed(() => {
  const { duration, startOffset, endOffset } = props.sheetRow;
  return duration - startOffset + endOffset - breakTime.value;
});

const hasDaylightSavingsSplit = computed(() => {
  const { sheetRow } = props;
  const a = new Date(sheetRow.startTime);
  const b = new Date(sheetRow.startTime);
  b.setSeconds(b.getSeconds() + sheetRow.duration);
  return a.getTimezoneOffset() !== b.getTimezoneOffset();
});

const cardClick = () => {
  const { sheetId, sheetRow } = props;
  if (!props.config.allowEdit) {
    return;
  }
  router.push(`/sheets/${sheetId}/row/${sheetRow.uuid}`);
};
</script>
