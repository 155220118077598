<template>
  <div class="feedback-options">
    <template v-for="([, {component, rating, bgColor, borderColor}], i) in feedbackOptions" :key="i">
      <feedback-item :component="component" :value="rating"
                     :background-color="bgColor"
                     :border-color="borderColor"
                     :class="[modelValue === null || modelValue !== rating && 'deselected' || 'selected']"
                     @update="update"/>
    </template>
  </div>
</template>
<script lang="ts" setup>

import FeedbackHappy from '@app/illustrations/FeedbackHappy.vue';
import FeedbackSad from '@app/illustrations/FeedbackSad.vue';
import FeedbackVeryHappy from '@app/illustrations/FeedbackVeryHappy.vue';
import FeedbackVerySad from '@app/illustrations/FeedbackVerySad.vue';
import { FeedbackRating } from '@pidz/enums';
import FeedbackItem from './FeedbackItem.vue';

defineProps<{modelValue: number | undefined}>();
const emit = defineEmits(['update:modelValue']);

const feedbackOptions = new Map([
  ['verySad', {
    rating: FeedbackRating.VERY_SAD, component: FeedbackVerySad, bgColor: '#FCABA7', borderColor: '#F9574E',
  }],
  ['sad', {
    rating: FeedbackRating.SAD, component: FeedbackSad, bgColor: '#FCABA780', borderColor: '#F9574E80',
  }],
  ['happy', {
    rating: FeedbackRating.HAPPY, component: FeedbackHappy, bgColor: '#95D1A480', borderColor: '#28A24680',
  }],
  ['veryHappy', {
    rating: FeedbackRating.VERY_HAPPY, component: FeedbackVeryHappy, bgColor: '#95D1A4', borderColor: '#28A246',
  }],
]);

const update = (rating: number) => {
  emit('update:modelValue', rating);
};

</script>
<style lang="scss">
.feedback-options {
  display: flex;
  justify-content: space-around;
  flex-wrap:wrap
}

</style>
