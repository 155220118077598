<template>
  <button :disabled="props.disabled || disabledLoading"
          :class="['pidz-button',
                   props.variant,
                   {'outlined': props.outlined},
                   {'rounded': props.rounded},
                   {'shadowed': props.shadowed},
                   {'disabled': props.disabled || disabledLoading}]"
          @click="emit('onClick', 'emit value')">
    <slot v-if="!loading"/>
    <p-icon v-else class="pidz-button__spinner"
            icon-name="spinner" :size="loaderProps().size"
            :color="loaderProps().color" :boxed="loaderProps().boxed"/>
  </button>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';

const emit = defineEmits(['onClick']);

const props = withDefaults(defineProps<{
  variant?: 'primary' | 'secondary' | 'ghost',
  outlined?: boolean,
  rounded?: boolean,
  shadowed?: boolean,
  disabled?: boolean,
  width?: string,
  loading?: boolean
}>(), {
  variant: 'primary',
  outlined: false,
  rounded: false,
  shadowed: false,
  disabled: false,
  width: 'auto',
  loading: false
});

const disabledLoading = ref<boolean>(props.loading);

watch(
  () => props.loading,
  (value) => { disabledLoading.value = value; }
);

const spinner = ref({
  color: 'black',
  size: 'medium',
  boxed: false
});

const loaderProps = (): { color: string, size: string, boxed: boolean} => {
  if (props.variant && !props.outlined && !(props.variant === 'ghost')) {
    spinner.value.color = 'white';
  }
  if (props.outlined && props.variant) {
    if (props.variant === 'primary') {
      spinner.value.color = 'blue';
    }
    if (props.variant === 'secondary') {
      spinner.value.color = 'red';
    }
    if (props.variant === 'ghost') {
      spinner.value.color = 'black';
    }
  }
  if (props.shadowed && (props.variant === 'ghost')) {
    spinner.value.boxed = true;
    spinner.value.size = 'large';
    spinner.value.color = 'white';
  }
  return spinner.value;
};
</script>
<style lang="scss" scoped>
.pidz-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  border: none;
  width: v-bind(width);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  line-height: 1;

  &.primary {
    background: var(--pidz-blue);
    color: var(--pidz-white);
    height: 40px;
  }
  &.secondary {
    background: var(--pidz-red);
    color: var(--pidz-white);
    height: 40px;
  }
  &.rounded {
    border-radius: 4px;
  }
  &.outlined {
    background: var(--pidz-white);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &.primary {
      border: 2px solid var(--pidz-blue);
      color: var(--pidz-blue);
    }
    &.secondary {
      border: 2px solid var(--pidz-red);
      color: var(--pidz-red);
    }
  }
  &.ghost {
    padding: 0px;
    background: transparent;
  }
  &.shadowed :deep(div){
    border-radius: 50%;
    box-shadow: var(--shadow-light);
  }
  &.disabled {
    opacity: .5;
    cursor: default;
  }
  &__spinner {
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
</style>
