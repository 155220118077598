import { feedbackService } from '@pidz/api';
import { defineStore } from 'pinia';

export default defineStore('feedback', () => {

  const addReview = async (feedbackModel: Feedback): Promise<Feedback | null> => {
    try {
        return await feedbackService.addReview(feedbackModel);
    } catch (error) {
        console.error('Error adding review:', error);
        return null;
    }
  };

  const addAsset = async (assetParams: FeedbackAddAssetParams): Promise<void> => {
      await feedbackService.addAsset(assetParams);
  };

  return {
    // actions
    addReview,
    addAsset
  };
});