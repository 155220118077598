<template>
  <select v-model="value" class="selectbox"
          :disabled="disabled"
          name="functions">
    <option v-if="placeholder" :value="null"
            disabled
            selected>{{placeholder}}</option>
    <option v-for="{name, value} in options"
            :key="value"
            :value="value"
            :disabled="value === ''">{{name}}</option>
  </select>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export interface Option {
  name:string;
  value:number|string;
}

const props = defineProps<{
  placeholder?: string,
  modelValue: string | number | null,
  options: Option[],
  disabled?: boolean
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  }
});
</script>

<style scoped lang="scss">

.selectbox {
  font: inherit;
  color: black;
  height: 40px;
  width: 100%;
  border: 1px solid #000;
  padding: 0 12px;
  border-radius: 5px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // Chevron
  background-color: white;
  background-size: 16px 16px;
  background-origin: content-box;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url('/src/assets/icons/chevron.svg');

  &:disabled {
    border-color: var(--pidz-grey-dark);
    color: var(--pidz-grey-dark);
  }
}

</style>
