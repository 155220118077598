<template>
  <div class="App bg-blue-100 text-neutral overflow-hidden">
    <offline-page v-if="!interfaceStore.networkStatus" />
    <app-initialization v-else-if="!interfaceStore.initialized" />
    <maintenance v-else-if="healthStore.backEndStatus === 'MAINTENANCE'" />
    <template v-else>
      <router-view />
      <toaster-wrapper />
      <render-modal />
    </template>
  </div>
</template>
 
<script lang="ts" setup>
import AppInitialization from '@app/components/AppInitialization.vue';
import ToasterWrapper from '@app/components/ToasterWrapper.vue';
import OfflinePage from '@app/pages/offline/OfflinePage.vue';
import useInterface from '@app/stores/interface';
import { Maintenance } from '@pidz/shared/components';
import { useHealthStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { h } from 'vue';

const modal = useModalController();
const interfaceStore = useInterface();
const healthStore = useHealthStore();

// we steer away from render functions, but in this case it was justified:
// we need to be able to pass various props to custom modals such as :accept=""
const renderModal = () => {
  if (!modal.current.value) {
    return null;
  }
  return h(modal.current.value, modal.currentData.value);
};

</script>
<style lang="scss">
@import './scss/index.scss';

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato;
}

.modal-open {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
a {
  color: var(--pidz-blue);
}

.App {
  display: flex;
  scroll-behavior: contain;
  min-height: var(--full-height);
  max-height: var(--full-height);
  margin-top: env(safe-area-inset-top);
}

.toaster-wrapper {
  margin-top: env(safe-area-inset-top);
}
</style>

