import { defineStore } from 'pinia';

type Toaster = {
  type: ToasterType;
  title: string;
  message: string;
  duration: number;
}

export default defineStore('toaster', {
  state: (): { activeToasters: { [key: string]: Toaster }, toasterQueue: { [key: string]: Toaster } } => ({
    activeToasters: {},
    toasterQueue: {},
  }),
  actions: {
    async addToaster(toasterContent: Omit<Toaster, 'duration'>, duration: number = 5000) {
      const timestamp = Date.now() + Math.ceil((Math.random() * 100));

      if (Object.keys(this.activeToasters).length < 3) {
        this.activeToasters = { ...this.activeToasters, [timestamp]: { ...toasterContent, duration } };
      } else {
        this.toasterQueue = { ...this.toasterQueue, [timestamp]: { ...toasterContent, duration } };
      }
    },
    async removeToaster(timestamp: string) {
      delete this.activeToasters[timestamp];

      if (Object.keys(this.toasterQueue).length !== 0) {
        const nextInQueue = Object.keys(this.toasterQueue)[0];

        this.activeToasters = { ...this.activeToasters, [nextInQueue]: this.toasterQueue[nextInQueue] };
        delete this.toasterQueue[nextInQueue];
      }
    },
  },
});
