<template>
  <p-banner-list :banners="deniedReasonBanners"/>
  <layout-desktop-wrapper>
    <company-form ref="form" :form-data="formDataCompany"
                  is-edit/>
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { AssetStatus } from '@pidz/enums';
import { CompanyForm } from '@pidz/shared/components'
import {
  globalValidations,
  rows,
} from '@pidz/shared/forms/profile/company';
import { useAssetStore, useUserStore} from '@pidz/stores';
import { computed, ref } from 'vue';

const form = ref<typeof CompanyForm>();
const userStore = useUserStore();

const formDataCompany: PidzFormData<ProfileCompany> = {
  globalValidations,
  values: userStore.getCompanyDetails(),
  rows,
};

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)
const deniedReasonBanners = computed(() => {
  const assets = useAssetStore().getAssetsForProfile
  return [
    ...assets?.company?.cocDocument as Asset[],
    ...assets?.company?.complaintsDocument as Asset[],
    ]
    .filter((asset) => asset.status === AssetStatus.DISAPPROVED)
    .map((asset) => {
      return {
        type: 'warning',
        title: `Reden afkeuring (${asset.type?.name})`,
        text: asset.deny_reason
      }
    })
  })


const goBack = () => form.value?.onCancel()
defineExpose({ goBack })
</script>
