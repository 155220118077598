import { RowType } from '@pidz/enums';
import {
  automaticIncassoField,
  bicField,
  ibanField,
  nameField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<ProfileBank>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [bicField.value],
  },
  {
    type: RowType.ROW,
    items: [ibanField.value],
  },
  {
    type: RowType.ROW,
    items: [nameField.value],
  },
  {
    type: RowType.ROW,
    items: [automaticIncassoField.value],
  },
];
