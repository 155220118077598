<template>
  <layout-desktop-wrapper>
    <training-form ref="form" :form-data="formDataTraining"/>
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled || form?.showFileError"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { TrainingForm } from '@pidz/shared/components';
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/training';
import { DateTime } from 'luxon';
import { ref } from 'vue';

const form = ref<typeof TrainingForm>();

const values: NewTrainingForm = {
  subject: undefined,
  other_subject: undefined,
  method: undefined,
  institution_name: undefined,
  institution_place: undefined,
  acquired_at: formatYmd(DateTime.now()),
  assets: []
}

const formDataTraining: PidzFormData<NewTrainingForm> = {
  globalValidations,
  values,
  rows
}
</script>
