<template>
  <div class="calendar-month-dots">
    <span /><span /><span />
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss">
.calendar-month-dots {
  order: 1;
  margin-top: 3px;
  display: flex;
  align-self: flex-start;
  span {
    display: block;
    --size: 4px;
    border-radius: calc(var(--size)/2);
    margin-right: 2px;
    width: var(--size);
    height: var(--size);
    background: var(--pidz-grey-dark);
  }
}
</style>
