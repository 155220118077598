<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section class="functions-filter">
    <h3 class="my-4 font-bold">Functie</h3>
    <option-grid v-model="value" multi
                 :options="freelancerFunctions"
                 :columns="1"/>
  </section>
</template>
<script lang="ts" setup>
import { useUserStore } from '@pidz/stores';
import { computed, ref, onMounted } from 'vue';
import OptionGrid from '../OptionGrid.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: any,
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});
const freelancerFunctions = ref<any>([]);
const userStore = useUserStore();
onMounted(async () => {
  freelancerFunctions.value = (await userStore.getFreelancerFunctions())
  .map((freelancerfunction:any) => ({ name: freelancerfunction.name, value: freelancerfunction.id }));
});

</script>
