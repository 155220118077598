import { assetService } from '@pidz/api';
import { ProfileAssetTypes } from '@pidz/enums';
import { base64FromFile, errorCodes } from '@pidz/utils';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useToaster, useUserStore } from '..';

export default defineStore('asset', () => {
  const assets = ref<AssetModel[]>();
  const assetTypes = ref<AssetType[]>();
  
  const userStore = useUserStore()

  const fetchAllAssetData = (force = false) => {
    if(force || !assets.value) fetchAssets();
    if(force || !assetTypes.value) fetchAssetTypes();
  }
  
  const fetchAssets = async () => {
    assets.value = await assetService.getAssets();
  }

  const fetchAssetTypes = async () => {
    assetTypes.value = await assetService.getAssetTypes();
  }

  const getAssetByAssetType = (AssetType: number) => {
    if (!assets.value) return [];
    //Convert AssetModel(BE) to Asset(FE)
    const assetArray: Asset[] = assets.value.map( asset => {
      const { file_name, ...newAsset } = {...asset, filename: asset.file_name};
      return newAsset
    })
    return assetArray.filter(asset => asset?.type?.id === AssetType);
  }

  const fetchAsset = async (assetId: number) => await assetService.getAsset(assetId);
  const addAsset = async (asset: Asset): Promise<Asset | null> => {
    try {
      return await assetService.addAsset(asset);
    }
    catch(error: any) {
      const code = error?.response?.data?.error?.code;
        useToaster().addToaster({
          type: errorCodes[code].type,
          title: errorCodes[code].title,
          message: errorCodes[code].message,
        })
      return null;
    }
  };
  const removeAsset = async (assetId: number ) => await assetService.removeAsset(assetId);

  const getCompanyAssets = (): ProfileAssetsCompany => ({
    cocDocument: getAssetByAssetType(ProfileAssetTypes.COC_DOCUMENT),
    complaintsDocument: getAssetByAssetType(ProfileAssetTypes.COMPLAINTS_DOCUMENT),
  });

  const getPersonalAssets = (): ProfileAssetsPersonal => ({
    vogDocument: getAssetByAssetType(ProfileAssetTypes.VOG_DOCUMENT),
    ...userStore.isUserFreelancer && { 
      insuranceDocument: getAssetByAssetType(ProfileAssetTypes.INSURANCE_DOCUMENT) 
    },
    ...userStore.isUserFreelancer && { 
      insuranceDocumentPaymentProof: getAssetByAssetType(ProfileAssetTypes.INSURANCE_DOCUMENT_PAYMENT_PROOF) 
    },
  })
  

  const getAssetsForProfile = computed<ProfileAssets | undefined>(
    () => {
      if (!assets.value) return undefined;

      return {
        personal: getPersonalAssets(),
        company: getCompanyAssets(),
      };
    },
  );

  const fetchInvoiceTemplate = async () => {
    return await assetService.getInvoiceTemplate();
  }

  const updateInvoiceTemplate = async (file: File | null) => {
    let fileModel: FileModel | null = null
    if (file) {
      const base64 = await base64FromFile(file)
      fileModel = {
        id: null,
        name: file.name,
        data: base64,
      };
    }
    return await assetService.updateInvoiceTemplate(fileModel);
  }

  const $reset = () => {
    assets.value = undefined;
    assetTypes.value = undefined;
  }

  return {
    assets,
    fetchAllAssetData,
    fetchAsset,
    fetchAssets,
    addAsset,
    removeAsset,
    getAssetByAssetType,
    getAssetsForProfile,
    getPersonalAssets,
    getCompanyAssets,
    fetchInvoiceTemplate,
    updateInvoiceTemplate,
    $reset
  }
})
