<template>
  <p-banner v-if="bannerInfo" :title="bannerInfo.title" 
               :text="bannerInfo.text" :type="bannerInfo.type" />
  <layout-desktop-wrapper>
    <training-form ref="form"
                   :form-data="formDataTraining"
                   :training-item="trainingItem" is-edit
                   :is-disabled="isEditDisabled"/>
    <form-delete-row name="Aanvullende scholing" :on-delete="form?.onDelete"
                     class="border-t border-neutral-200"/>   
    <form-buttons-row v-if="!isEditDisabled"
                      :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled || form?.showFileError || isEditDisabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import FormDeleteRow from '@app/components/FormDeleteRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { CvSectionStatus } from '@pidz/enums';
import { TrainingForm } from '@pidz/shared/components';
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/training';
import { useCVStore } from '@pidz/stores';
import { getBannerInfo } from '@pidz/utils'
import { DateTime } from 'luxon';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const form = ref<typeof TrainingForm>();

const route = useRoute()
const cvStore = useCVStore();

const trainingItem = computed(() => cvStore.getTrainingById(Number(route.params.id)));

const bannerInfo = computed(() => {
  const { status, deny_reason } = trainingItem.value as Training;
  return getBannerInfo('aanvullende scholing', status, deny_reason);
})

const isEditDisabled = computed(() => 
  !!trainingItem.value?.status && [CvSectionStatus.APPROVED, CvSectionStatus.EXPIRED]
  .includes(trainingItem.value.status));

const formatDataTraining = (training: Training): TrainingForm => {
  const { other_subject, acquired_at, subject, ...item } = training;

  return {
    ...item,
    subject: subject!.id,
    other_subject: other_subject ?? '',
    acquired_at: formatYmd(DateTime.fromISO(acquired_at))
  }
}

const formDataTraining: PidzFormData<TrainingForm> = {
  globalValidations,
  values: formatDataTraining(trainingItem.value as Training),
  rows
}

const loadStores = async () => {
  await cvStore.loadResume();
};

onMounted(async () => {
  await loadStores();
});
</script>
