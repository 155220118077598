export const registrationTypesOptions = (registrationTypes: RegistrationType[]): FormDropDownOption[] => 
  registrationTypes.map(
    (registration, index): FormDropDownOption => ({
      id: index,
      value: registration.id,
      label:
        registration.name ||
        `Geen label beschikbaar voor id: ${registration.id}`,
    }),
  )
 