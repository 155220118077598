<template>
  <div class="labeled-slot" v-apply-for-name>
    <label :for="forName">{{label}}</label>
    <slot />
  </div>
</template>
<script lang="ts" setup>

import { computed } from 'vue';

const props = defineProps<{
  label:string,
  forName?:string,
}>();

const forName = computed(() => props.forName
  ?? props.label.toLowerCase());

const vApplyForName = {
  mounted: (el:any) => {
    const name = forName.value;
    const inputs = [...el.querySelectorAll('input,textarea,select')];
    if (inputs.length === 1) {
      inputs[0].setAttribute('id', name);
      inputs[0].setAttribute('name', name);
    }
  },
};

</script>
<style lang="scss">
.labeled-slot{
  &>label {
    color: var(--pidz-body-text);
    margin-bottom: 4px;
    min-width: auto;
    width: auto;
  }
}
</style>
