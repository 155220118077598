<template>
  <ol  class="timeline">
    <slot name="timeline-items"/>
  </ol>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">

    $topMargin: .35rem;
    $circleSize: .5rem;

    .timeline {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: calc(($circleSize / 2) - 1px);
            top: $topMargin;
            display: block;
            width: 2px;
            height: calc(100% - $topMargin);
            // TODO: Remove hardcoded color
            background: rgba(#004d9d, .25);
        }
        li {
            display: inline-block;
            position: relative;
            margin-left: calc($circleSize / 2);
            padding: 0 0 2rem 2.2rem;
            width: 100%;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: -#{calc($circleSize / 2)};
                top: .35rem;
                width: $circleSize;
                height: $circleSize;
                background: var(--pidz-blue);
                border-radius: 100%;
            }
        }
    }
</style>
