<template>
  <div class="upload-files">
    <label class="uploader">
      <docs-icon />
      <div>Bijlage toevoegen</div>
      <input type="file" multiple
             class="file-input"
             @change="filesChanged(($event.target as HTMLInputElement).files)"/>
    </label>

    <div class="error-container" v-if="invalidFiles && invalidFiles.length > 0">
      <h3 >{{ errorTitle }}</h3>
      <ul class="file-list error">
        <li class="file-item" v-for="(file, index) in invalidFiles"
            :key="index">{{ file.name }}</li>
      </ul>
    </div>

    <ul class="file-list" >
      <li class="file-item" v-for="(file, index) in uploadedFiles"
          :key="index">{{ (file as File).name }}<button @click="removeItem(index)"><bin-icon /></button></li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { DocsIcon, BinIcon } from '@pidz/icons';
import { ref, computed } from 'vue';

export interface Props {
  maxFileSizeInMb?: number
}

const props = withDefaults(defineProps<Props>(), {
  maxFileSizeInMb: 8,
});

const emit = defineEmits(['upload']);
const uploadedFiles = ref<File[]>();
const invalidFiles = ref<File[]>();
const maxSize = computed(() => (props.maxFileSizeInMb * 1024 * 1024));
const errorTitle = computed(() => {
  if (invalidFiles.value && invalidFiles.value.length > 1) {
    return `De volgende bestanden zijn te groot, de maximale grootte is ${props.maxFileSizeInMb}MB:`;
  }
  return `Het volgende bestand is te groot, de maximale grootte is ${props.maxFileSizeInMb}MB:`;
});

const filesChanged = (files: FileList | null) => {
  if (files) {
    invalidFiles.value = [];
    // TODO: add toaster with error message 'filesize too big'
    const invalidFileArray = Array.from(files).filter((file) => file.size > maxSize.value);
    const validFileArray = Array.from(files).filter((file) => file.size < maxSize.value);

    uploadedFiles.value = validFileArray;
    invalidFiles.value = invalidFileArray;
    emit('upload', uploadedFiles.value);
  }
};

const removeItem = (index: number) => {
  if (uploadedFiles.value && uploadedFiles.value[index]) {
    const newArray = uploadedFiles.value?.filter((item, key) => {
      if (key === index) {
        return false;
      }
      return true;
    });
    uploadedFiles.value = newArray;
    emit('upload', uploadedFiles.value);
  }
};

</script>

<style lang="scss">
.upload-files {
  flex: 1;
  .uploader {
    display: flex;
    align-items: center;
    text-decoration: underline;

    div {
      margin-left: 6px;
    }

    input[type="file"] {
      display: none;
    }
  }

  .file-list {
    margin-top: 8px;
    padding: 0;
    list-style: none;
    color:var(--pidz-black);

    li {
      display: flex;

      button {
        align-self: center;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        .pidz-icon {
          margin-left: 6px;
          width: 22px;
          height: 22px;
          color: var(--pidz-blue);
        }
      }
    }
  }
  .error-container {
    h3 {
      font-size: 1rem;
    }

    li {
      color: var(--pidz-red);

      &:before {
          content: "-";
          padding-right: 4px;
        }
    }
  }
}
</style>
