import { mollieService } from '@pidz/api';
import { defineStore } from 'pinia';

export default defineStore('useMollieStore', () => {
  const revokeAndRequestNewMollieMandate = async (redirectUrl: string) => {
    return await mollieService.revokeAndRequestNewMollieMandate(redirectUrl)
  }

  const isMollieMandateRequired = async () => {
    return await mollieService.isMollieMandateRequired()
  }

  const isMollieMandateValid = async () => {
    return await mollieService.isMollieMandateValid()
  }

  const generateMandatePaymentLink = async (redirectUrl: string) => {
    return await mollieService.mandatePaymentLink(redirectUrl);
  }

  const checkIfMandateWasSuccesful = async () => {
    return !await isMollieMandateRequired();
  }

  const $reset = () => {

  }

  return {
    revokeAndRequestNewMollieMandate,
    isMollieMandateRequired,
    isMollieMandateValid,

    generateMandatePaymentLink,
    checkIfMandateWasSuccesful,

    $reset
  }
});
