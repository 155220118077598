import { ToISODateOptions, ToISOTimeOptions, DateTime } from 'luxon';

export const formatISO = (
  dt: DateTime,
  opts?: ToISOTimeOptions,
): string | null => dt.toISO(opts);

export const formatISODate = (
  dt: DateTime,
  opts?: ToISODateOptions,
): string | null => dt.toISODate(opts);

export const getISODay = (dt: DateTime): number => dt.weekday;

//TODO: this function should not allow a date as input
// as fromJSDate gives us trouble with timezones
export const addHourToIsoDate = (isoDate: string | Date) => {
  const newDate: DateTime = (isoDate instanceof Date) 
    ? DateTime.fromJSDate(isoDate)
    : DateTime.fromISO(isoDate);
  return newDate.plus({hours: 1}).toISO({suppressMilliseconds: true});
};
