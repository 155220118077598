<template>
  <ul class="invoice-list">
    <li v-for="({ id, invoice_number }, index) in bundledInvoices"
        :key="index"
        class="downloadable-invoice"
        @click="downloadInvoice(id)">
      <span>Factuur</span>
      <span class="invoice-number">{{ invoice_number }}</span>
    </li>
  </ul>
</template>
<script lang="ts" setup>
import useEnvironment from '@app/stores/environment';
import { Browser } from '@capacitor/browser';
import { Auth } from '@pidz/api';
import { FilteredBundleInvoice } from '../types';

const environment = useEnvironment();
const props = defineProps<{
  bundledInvoices: FilteredBundleInvoice[];
  invoiceId: number;
}>();

const downloadInvoice = async (bundledInvoiceId: string) => {
  const currentSession = await Auth.getCurrentSession();
  const token = currentSession?.getAccessToken().getJwtToken();

  if (!environment.selected?.baseUrl) {
    return;
  }

  Browser.open({
    url: `${environment.selected.baseUrl}/invoices/${props.invoiceId}/download/${bundledInvoiceId}/ff?token=${token}`,
  });
};
</script>
<style lang="scss">
.invoice-list {
  display: block;
  position: absolute;
  background: var(--pidz-white);
  z-index: 1;
  box-shadow: var(--shadow-light);
  border-radius: 2px;
  right: 0;
  top: 0;
  padding: 12px 16px;
  color: var(--pidz-body-text);
  min-width: 120px;

  ul {
    left: 0;
    margin: 0;
    padding: 0;
  }

  .downloadable-invoice {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 2px;

    span.invoice-number {
      font-size: 12px;
      color: var(--pidz-grey-dark);
      margin-top: 2px;
    }
  }
}
</style>
