<template>
  <section class="maintenance-page">
    <h1>Onderhoud</h1>
    <p>
      Er wordt tijdelijk gewerkt aan het onderhoud van mijnPIDZ. Wij zorgen
      ervoor dat mijnPIDZ zo snel mogelijk weer toegankelijk is.
    </p>

    <!-- TODO: met UX over hebben om een leuke maintenance pagina op te zetten-->
  </section>
</template>
<script lang="ts" setup></script>
<style lang="scss">
.maintenance-page {
  width: 100%;
  height: 100vh;

  background-color: var(--pidz-blue);
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;
}
</style>
