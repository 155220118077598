<template>
  <div class="user user__wrapper">
    <figure :class="['user__figure', {'user--empty': !props.path}]">
      <img v-if="path"
           class="user__image"
           :src="path"
           alt="User image" />
      <div v-else class="user__placeholder">
        <pidz-silhouette />
      </div>
    </figure>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import PidzSilhouette from './asset/PidzSilhouette.vue';

interface Props {
  path?: string;
  size?: '40px' | '181px' | '250px'
}
const props = withDefaults(defineProps<Props>(), {
  path: '',
  size: '181px'
});

const imageSize = computed(() => props.size);

</script>
<style lang="scss" scoped>
.user {
  &__figure{
    width: v-bind(imageSize);
    min-width: v-bind(imageSize);
    height: v-bind(imageSize);
    min-height: v-bind(imageSize);
    border-radius: 50%;
    overflow: hidden;
    box-shadow: var(--shadow-light);
    cursor: pointer;
    margin: 0;
  }

  &--empty {
    border: 2px dashed var(--pidz-blue);
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
</style>
