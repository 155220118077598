import { FormFieldType } from '@pidz/enums';
import { checkVAT, countries } from 'jsvat-next';
import { ref } from 'vue';
import * as zod from 'zod';

export const cocField = ref<FormField>({
  active: true,
  name: 'cocNumber',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(8, { message: 'KVK nummer is minimaal 8 cijfers' })
    .refine(
      (value) => {
        const regex = /^\d{8,11}$/;
        return regex.test(value);
      },
      {
        message:
          'Vul een geldig KVK nummer in met minimaal 8 en maximaal 11 cijfers',
      },
    ),
  width: 100,
  data: {
    id: 'cocNumber',
    label: 'Kvk nr.',
    inputType: 'text',
    placeholder: '12345678',
  },
});

export const companyNameField = ref<FormField>({
  active: true,
  name: 'companyName',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, { message: 'Vul een bedrijfsnaam in' })
    .max(255, { message: 'Maximaal 255 karakters' }),
  width: 100,
  data: {
    id: 'companyName',
    label: 'Bedrijfsnaam',
    inputType: 'text',
    placeholder: 'Organisatie naam ',
  },
});

export const websiteField = ref<FormField>({
  active: true,
  name: 'website',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(255, { message: 'Maximaal 255 karakters' })
    .refine(
      (value) => {
        const regex =
          /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
        return regex.test(value);
      },
      { message: 'Vul een geldige website in' },
    )
    .or(zod.literal('')),
  width: 100,
  data: {
    id: 'website',
    label: 'Website (optioneel)',
    inputType: 'text',
    placeholder: 'https://organisatie.nl',
  },
});

export const vatField = ref<FormField>({
  active: true,
  name: 'vatNumber',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .min(1, { message: 'Vul een VAT nummer in' })
    .refine(
      (value) => {
        return checkVAT(value, countries).isValid;
      },
      {
        message: 'Ongeldig VAT nummer',
      },
    ),
  width: 100,
  data: {
    id: 'vatNumber',
    label: 'BTW nr.',
    inputType: 'text',
    placeholder: 'NL000099998B57',
  },
});

export const vatExemption = ref<FormField>({
  active: true,
  name: 'vatFree',
  formType: FormFieldType.TOGGLE,
  validation: zod.boolean(),
  width: 100,
  data: {
    id: 'vat_free',
    label: 'Ik kom in aanmerking voor BTW vrijstelling',
  },
});
