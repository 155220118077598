<template>
  <svg class="CheckCircleGreen" width="24px"
       height="24px" viewBox="0 0 24 24"
       version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon/19 x 19/check/circle/red</title>
    <g id="Error-messages" stroke="none"
       stroke-width="1" fill="none"
       fill-rule="evenodd">
      <g id="AgendaItem_New-Copy-49" transform="translate(-25.000000, -246.000000)">
        <g id="Group-3-Copy-5" transform="translate(9.000000, 226.000000)">
          <g id="icon/19-x-19//check/circle/red" transform="translate(16.000000, 20.000000)">
            <circle id="Witte-achtergrond" fill="#FFFFFF"
                    cx="12" cy="12"
                    r="9.47368421"></circle>
            <path fill="#28A246" class="circle"
                  d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M17.5673397,7.31759245 C17.3716483,7.13615464 17.0679618,7.14037445 16.8773872,7.32717954 L10.4583421,13.6190201 L7.25970293,10.6151088 C7.06483593,10.4321437 6.7605274,10.4348043 6.5688892,10.6211484 L5.00750363,12.1394003 L4.99156115,12.155624 C4.80254367,12.3569372 4.81251132,12.6733628 5.01382452,12.8623802 L10.1272764,17.6635149 C10.3221434,17.8464799 10.6264519,17.8438194 10.8180901,17.6574753 L10.7468969,17.7166903 C10.8140247,17.6931471 10.8771012,17.6548766 10.9310973,17.6019485 L19.1742428,9.52185051 C19.1799283,9.51627754 19.1854798,9.51056957 19.1908926,9.50473149 C19.3786408,9.30223397 19.366684,8.98587735 19.1641865,8.79812921 Z" id="Combined-Shape" ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
