<template>
  <layout-desktop-wrapper>
    <address-form ref="form" :form-data="formDataAddress"
                  is-edit />
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { AddressForm } from '@pidz/shared/components';
import {
  globalValidations,
  rows,
} from '@pidz/shared/forms/profile/address';
import { useUserStore } from '@pidz/stores';
import { ref, computed } from 'vue';

const form = ref<typeof AddressForm>();
const userStore = useUserStore();

const address = userStore.getAddress();
const businessAddress = userStore.getBusinessAddress();

const getAddressData = (): any => {
  return {
    street: address.street?? '',
    houseNumber: address.houseNumber ?? '',
    zipCode: address.zipCode ?? '',
    city: address.city ?? '',
    country: address.country ?? '',
    poBox: address.poBox ?? '',

    isSameAddress: !userStore.isBusinessAddressDifferent,
    
    businessAddress_street: businessAddress.street  ?? '',
    businessAddress_houseNumber: businessAddress.houseNumber  ?? '',
    businessAddress_zipCode: businessAddress.zipCode ?? '',
    businessAddress_city: businessAddress.city ?? '',
    businessAddress_country: businessAddress.country ?? '',
    businessAddress_poBox: businessAddress.poBox ?? ''
  }
};

const formDataAddress: PidzFormData<AddressForm> = {
  globalValidations,
  values: getAddressData(),
  rows,
};

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)

const goBack = () => form.value?.onCancel()
defineExpose({ goBack })
</script>
