<template>
  <div ref="main">
    <infinity-top-spinner v-if="topAnimationNormal > 0 || loading"
                          :animation-normal="topAnimationNormal"
                          :style="topSpinnerStyle"
                          :rotate="loading ?? false"/>
  </div>
</template>

<script lang="ts" setup>
import usePullToRefresh from '@app/utils/use-pull-to-refresh';
import { useReloadStore } from '@pidz/stores';
import { computed, ref, watch } from 'vue';
import InfinityTopSpinner from './InfinityTopSpinner.vue';

const props = defineProps<{
  reload: Function;
  loading?: boolean;
}>();

const reloadStore = useReloadStore();

const main = ref<HTMLDivElement>();

const { pullHeight, isPulling } = usePullToRefresh(main, props.reload);

const normalize = (val: number, min: number, max: number) =>
  Math.max(0, Math.min(1, (val - min) / (max - min)));

const topAnimationNormal = computed(() => props.loading ? 1 : normalize(pullHeight.value, 0, 100));
const topSpinnerStyle = computed(() => ({
  opacity: topAnimationNormal.value,
  transform: `translateY(${(1 - topAnimationNormal.value) * -50}px)`,
}));

watch(isPulling, () => reloadStore.setIsPulling(isPulling.value));

</script>
