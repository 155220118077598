<template>
  <div ref="calendar"
       class="calendar-month-content"
       :class="[{ loading: isLoading }, !isMobileApp ? 'web' : '']">
    <div class="selected-date">{{ selectedDateText }}</div>
    <div :class="[
      'grid',
      { condensed: isDrawerOpen },
      { mobile: props.isMobileApp },
    ]">
      <div v-for="day in days"
           :key="day.key!"
           :class="day.classes"
           @click="handleDayClick(day)">
        <div class="nr">{{ day.name }}</div>
        <p-calendar-event v-for="e in day.events.slice(0, 3)"
                          :key="e.id"
                          variant="empty"
                          class="bar"
                          :type="e.type"
                          @handle-click="handleDayClick(day)"/>
        <calendar-month-dots v-if="day.events.length > 1"
                             class="more-1" />
        <calendar-month-dots v-if="day.events.length > 3"
                             class="more-3" />
      </div>
    </div>
    <div class="floating-buttons">
      <add-event-button v-if="props.isMobileApp"
                        :is-drawer-open="isDrawerOpen" />
      <link-calendar-button v-if="props.isMobileApp"
                            :is-drawer-open="isDrawerOpen" />
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable max-lines */
import AddEventButton from '@app/pages/calendar/AddEventButton.vue';
import CalendarMonthDots from '@app/pages/calendar/CalendarMonthDots.vue';
import LinkCalendarButton from '@app/pages/calendar/LinkCalendarButton.vue';
import { computed } from 'vue';

interface Props {
  isLoading?: boolean;
  selectedDateText: string;
  isDrawerOpen?: boolean;
  days: CalendarDay[];
  isMobileApp?: boolean;
}

const emits = defineEmits<{(e: 'handleDayClick', day: CalendarDay): void }>();

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  selectedDateText: '',
  isDrawerOpen: false,
  isMobileApp: true,
});

const handleDayClick = (day: CalendarDay) => {
  emits('handleDayClick', day);
};

const calendarHeight = computed(() => (props.isMobileApp ? 'auto' : '100%'));
</script>

<style lang="scss" scoped>
@keyframes fadeMonthOpacity {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0.2;
  }
}

.calendar-month-content {
  &.loading .grid,
  &.loading .selected-date {
    animation: fadeMonthOpacity 1s infinite alternate-reverse;
    pointer-events: none;
    cursor: none;
  }

  .selected-date {
    padding: 8px 16px;
    color: var(--pidz-body-text);
  }

  .grid {
    height: v-bind(calendarHeight);
    padding: 12px 2px;
    background: var(--pidz-white);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    &.mobile {
      grid-template-rows: repeat(6, 62px);
    }
    transition: all 0.3s ease-in-out;

    .more-1 {
      display: none;
    }
    &.condensed {
      .more-1 {
        display: inherit;
      }
      .more-3 {
        display: none;
      }
      grid-template-rows: repeat(6, 36px);

      .more-1 ~ .bar,
      .bar ~ .bar {
        display: none;
      }
    }
  }
  .day {
    color: var(--pidz-body-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    padding: 8px 12px;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 13px;

    &.disabled {
      opacity: 0.5;
    }

    .nr {
      height: 16px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.today .nr {
      font-size: 11px;
      background: var(--pidz-blue);
      color: var(--pidz-white);
      font-weight: bold;
      border-radius: 50%;
    }

    &.selected {
      border: 1px solid var(--pidz-grey-light);
      border-radius: 4px;
    }

    .bar {
      width: 100%;
      height: 5px;
      padding: 2px;
      border-radius: 2.5px;
      margin-top: 2px;
      margin-bottom: 0px;
    }
  }

  .floating-buttons {
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    bottom: 16px;
    right: 16px;
  }
}

.web {
  .grid {
    padding: 16px 2px;
  }
  .day {
    padding: 12px;

    :deep(.calendar-event) {
      min-height: 10px;
    }
    .bar {
      max-height: 50px;
    }
    .nr {
      font-size: 13px;
      background: var(--pidz-white);
      color: black;
      font-weight: bold;
      border-radius: 50%;
      padding: 12px 6px;
      margin-bottom: 5px;
    }
    &.today {
      .nr {
        color: var(--pidz-white);
        background: var(--pidz-blue);
      }
    }
  }
}
</style>
