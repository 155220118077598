import { featureflagService } from '@pidz/api';
import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

const remoteFlags: string[] = [
  'ffe-cv',
  'ffe-preferences',
  'ffe-feedback',
];

export default defineStore('featureflags', () => {
  const flags: Ref<Record<string,boolean>> = ref({});

  const getStatus = (flag: string) => { return flags.value[flag];}

  const getFlagFromBackend = async (flag: string) => {
    const {result} = await featureflagService.getFeatureFlag(flag);
    return result;
  }

  const initRemoteFlags = () => {
    remoteFlags.forEach(async (flag) => {
      flags.value[flag] = false;

      const status = await getFlagFromBackend(flag);
      flags.value[flag] = status;
    })
  }

  const $reset = () => {
    // empty function to implement the reset function
  };

  return {
    // state
    flags,
    $reset,
    // getters
    getStatus,
    initRemoteFlags
  };
});
