<template>
  <p-content-section>
    <template #header>
      <div class="compentency-header">
        <p-badge v-if="isBadgeVisible"
                 class="compentency-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading icon="drop" class="font-bold">Risicovolle en voorbehouden handelingen</p-heading>
      </div>
    </template>
    <template v-if="groupedCompetencies && groupedCompetencies.length > 0"
              #content>
      <div class="competencies">
        <p class="competencies__par">
          Alle handelingen in deze sectie zijn toegevoegd op basis van jouw
          functie(s). Alleen de handelingen waarvoor bevoegdheid is aangetoond
          middels een diploma zijn geldig en zichtbaar. Bekwaamheid wordt
          maximaal 2 jaar lang onthouden, daarna moet deze worden herzien.
        </p>
        <p-table class="competencies__table table"
                 :columns="columns"
                 :rows="groupedCompetencies"
                 :borders="['row']"
                 omit-header
                 responsive>
          <template #row_name="{ value }">
            <div class="table__name">{{ value.name }}</div>
          </template>
          <template #row_is_allowed>
            <div class="table__allowed">
              <p-icon icon-name="check"
                      background="green"
                      boxed
                      size="small"/>
              Bevoegd
            </div>
          </template>
          <template #row_experience="row">
            <div v-if="row.value.competencies.length" class="table__experience">
              <div class="table__current-experience">
                <p-icon :icon-name="competencyIcons.get(row.value.experience)?.icon"
                        :background="competencyIcons.get(row.value.experience)?.color"
                        boxed
                        size="small"/>
                {{ row.value.experience }}
              </div>
            </div>
            <div v-else class="table__add-experience">
              <p-icon icon-name="add" size="large"
                      color="blue" />
              <span>Handeling invullen</span>
            </div>
          </template>
          <template #row_actions="row">
            <div v-if="row.value.competencies.length" class="flex-end">
              <p-icon icon-name="pencil"
                      color="blue"
                      :class="`table__icon table__icon-${row.value.id}`"
                      @click="emit('edit-competency', row.value.id)"/>
            </div>
          </template>
        </p-table>
      </div>
    </template>
    <!-- this component is not show if no competencies, so no empty state -->
  </p-content-section>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits(['edit-competency']);

defineProps<{
  groupedCompetencies?: CompetencyGroup[];
  isBadgeVisible: boolean;
}>();

const columns = computed(() => {
  return [
    {
      field: 'name',
      label: 'Naam',
    },
    {
      field: 'is_allowed',
      label: 'Bevoegdheid',
    },
    {
      field: 'experience',
      label: 'Bekwaamheid',
    },
    {
      field: 'actions',
      label: '',
    },
  ];
});

const competencyIcons = new Map<string, { icon: string; color: string }>([
  ['Deels bekwaam', { icon: 'exclamation', color: 'grey' }],
  ['Bekwaam', { icon: 'check', color: 'green' }],
  ['Niet bekwaam', { icon: 'close', color: 'grey' }],
]);
</script>
<style scoped lang="scss">
.compentency-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}


.competencies {
  @media screen and (min-width: 768px) {
    margin-right: 2rem;
  }
}
.par {
  color: var(--pidz-black);
}
.table {
  color: var(--pidz-black);
  &__allowed {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__experience {
    > div {
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        text-wrap: nowrap;
        color: var(--pidz-blue);
      }
    }
  }
  &__name {
    font-weight: bold;
  }
  &__icon {
    cursor: pointer;
  }
}
</style>
