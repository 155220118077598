<template>
  <layout-desktop-wrapper>
    <education-form ref="form" :form-data="formDataEducation"/>
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled || form?.showFileError"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { EducationForm } from '@pidz/shared/components';
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/education';
import { DateTime } from 'luxon'
import { ref } from 'vue';

const form = ref<typeof EducationForm>();

const values: NewEducationForm = {
  specialization: undefined,
  institution_name: undefined,
  institution_place: undefined,
  end_date: formatYmd(DateTime.now()),
  start_date: formatYmd(DateTime.now()),
  assets: [],
  subject: undefined,
}

const formDataEducation: PidzFormData<NewEducationForm> = {
  globalValidations,
  values,
  rows
}
</script>
