import { Device } from '@capacitor/device';

const formatOSVersion = (os: string, webViewVersion?: string) => {
  if (!webViewVersion) { return os; }
  return `${os} | WebViewVersion: ${webViewVersion}`;
};

export const lazyMakeFeedback = ():Feedback => {
  const result = {
    type: 'incidental',
    status: 'closed',
    body: '',
    screen_dimensions: `${window.innerWidth}x${window.innerHeight}`,
    contact_consent: false,
    date_created: new Date().toISOString(),
    date_completed: new Date().toISOString(),
  };
  Device.getInfo().then((info) => {
    Object.assign(result, {
      os_version: formatOSVersion(info.osVersion, info.webViewVersion),
      device_type: info.model,
    });
  });
  return result;
};
