<template>
  <input-border multi
                class="date-range"
                dark>
    <agenda-icon class="agenda-icon"/>
    <input id="start-date"
           v-model="value.start"
           type="date"
           placeholder="dd/mm/jjjj"/>
    <input id="end-date"
           v-model="value.end"
           type="date"
           placeholder="dd/mm/jjjj"/>
  </input-border>
</template>
<script setup lang="ts">
import { AgendaIcon } from '@pidz/icons';
import { computed } from 'vue';
import InputBorder from './InputBorder.vue';

const props = defineProps<{
  modelValue: {start: string, end: string}
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', {
      start: newValue.start,
      end: newValue.end,
    });
  },
});
</script>

<style lang="scss">
input {
  width: 100%;
  padding: 0px 12px;

  &[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}
</style>
