import { isToday, isYesterday } from '@pidz/date';
import { DateTime, DurationObjectUnits, DurationUnits } from 'luxon';

export const differenceBetweenDates = (
  dt1: DateTime,
  dt2: DateTime,
  unit?: DurationUnits,
): DurationObjectUnits => dt1.diff(dt2, unit).toObject();

export const isBefore = (dt1: DateTime, dt2: DateTime): boolean => {
  const { days } = differenceBetweenDates(dt1, dt2, 'days');
  return (days || 0) < 0;
};

export const isAfter = (dt1: DateTime, dt2: DateTime): boolean => {
  const { days } = differenceBetweenDates(dt2, dt1, 'days');
  return (days || 0) <= -1;
};

export const daysAgo = (date: DateTime) => {
  if (isToday(date)) {
    return 'vandaag';
  }
  if (isYesterday(date)) {
    return 'gisteren';
  }

  const { days } = differenceBetweenDates(
    DateTime.now().startOf('day'),
    date.startOf('day'),
    'days',
  );

  return `${days} dagen geleden`;
};

const toMinutes = (date: string) =>
  Math.round(DateTime.fromISO(date).toMillis() / 60000);

  export const deltaHoursMinutes = (from: string, to: string) => {
  const delta = toMinutes(to) - toMinutes(from);
  const hours = Math.floor(delta / 60);
  const minutes = delta - hours * 60;
  return {
    hours,
    minutes,
  };
};
