<template>
  <div ref="calendarWeekContent"
       class="calendar-week-content"
       data-prevent-pull-on-scrolloffset>
    <div v-for="day in events"
         :key="day.date.toString()"
         class="day">
      <h2 class="title">{{ formatDayTitle(day.date) }}</h2>
      <div class="events">
        <template v-if="day.events.length > 0">
          <calendar-event v-for="event in day.events"
                          :key="event.id"
                          :calendar-event="event"
                          :day-date="day.date"/>
        </template>
        <p v-else
           class="empty">Er staat niks in je agenda voor deze dag</p>
      </div>
    </div>
    <div class="floating-buttons">
      <add-event-button />
      <link-calendar-button />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { 
  addDuration, createDurationObject, localeString
} from '@pidz/date';
import { useCalendar } from '@pidz/stores';
import {
  collidesWithDay, capitalizeString
} from '@pidz/utils';
import { DateTime, Interval }from 'luxon'
import { computed, ref, watch } from 'vue';
import AddEventButton from './AddEventButton.vue';
import CalendarEvent from './CalendarEvent.vue';
import LinkCalendarButton from './LinkCalendarButton.vue';

interface IWeeklyEvents {
  date: DateTime;
  events: CalendarEvent[] | [];
}

const calendarStore = useCalendar();
const calendarWeekContent = ref<HTMLDivElement | null>();

// filter events if the start/end overlap with the day start/end
const filterWeekEvents = (events: CalendarEvent[], week: {start: DateTime, end: DateTime}) => { 
  const weekRange = Interval.fromDateTimes(
      week.start.startOf('day'),
      week.end.endOf('day'),
    );
  return events.filter((event: CalendarEvent) => {
    const eventRange = Interval.fromDateTimes(
    event.start.startOf('day'),
    event.end.endOf('day'),
    );
  return eventRange.overlaps(weekRange);
  })};

const formatDayTitle = (date: DateTime ) => {
  return capitalizeString(localeString(date, { weekday: 'long', month: 'long', day: 'numeric' }, { locale: 'nl'}))
};

const generateWeek = (start: DateTime) =>
  Array.from(
    { length: 7 },
    (_, i): IWeeklyEvents => ({
      date: addDuration(start, createDurationObject({ days: i })),
      events: [],
    }),
  );

const filterDailyEvents = (events: CalendarEvent[], date: DateTime) =>
  events.filter((event: CalendarEvent) => collidesWithDay(date, event));

const events = computed(() => {
  const start = calendarStore.date.startOf('week');
  const end = calendarStore.date.endOf('week');
  const weekDays = generateWeek(start);
  const weeklyEvents = filterWeekEvents(calendarStore.events, { start, end });
  const groupedEventsByDay = weekDays.reduce(
    (prev: { date: DateTime; events: CalendarEvent[] | [] }[], curr) => {
      const dailyEvents = filterDailyEvents(weeklyEvents, curr.date);
      return [
        ...prev,
        { ...curr, events: dailyEvents },
      ];
    },
    [],
  );
  return groupedEventsByDay;
});

watch(
  () => calendarStore.date,
  () => {
    calendarWeekContent.value?.scrollTo({ top: 0 });
  },
);
</script>
<style lang="scss">
.calendar-week-content {
  overflow-y: scroll;
  scrollbar-width: none;
  padding-bottom: 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  .week-content {
    display: flex;
    direction: column;
  }

  .day {
    padding-top: 14px;

    h2 {
      font-size: 13px;
      font-weight: 400;
      margin-left: 16px;
    }

    .events {
      background-color: var(--pidz-white);
      padding: 16px;
      box-shadow: var(--shadow-light);

      .empty {
        font-size: 13px;
        text-align: center;
        color: var(--pidz-grey-dark);
      }
    }
  }

  .floating-buttons {
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    bottom: 16px;
    right: 16px;
  }
}
</style>
