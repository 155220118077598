import useNotificationRouting from '@app/utils/use-notification-routing';
import { Device } from '@capacitor/device';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
  RegistrationError,
} from '@capacitor/push-notifications';
import { notificationService } from '@pidz/api';
import { useUserStore } from '@pidz/stores';
import { defineStore } from 'pinia';

const supportedPlatforms = ['android', 'ios'];

interface NotificationsStore {
  isDeviceRegistered: boolean
  isPermissionDenied: boolean
  token?: Token
  receivedNotifications: PushNotificationSchema[]
  performedActions: ActionPerformed[]
  registrationError?: RegistrationError
}

export default defineStore('notifications', {
  state: (): NotificationsStore => ({
    isDeviceRegistered: false,
    isPermissionDenied: false,
    receivedNotifications: [],
    performedActions: [],
  }),
  actions: {
    async registerFirebase() {
      const { platform } = await Device.getInfo();

      if (!supportedPlatforms.includes(platform)) return;
      if (this.token || this.isPermissionDenied) return;

      await this.addListeners();

      let permissionStatus = await PushNotifications.checkPermissions();
      if (permissionStatus.receive.includes('prompt')) {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      if (permissionStatus.receive === 'denied') {
        this.isPermissionDenied = true;
        return;
      }

      if (permissionStatus.receive !== 'granted') {
        throw new Error('Something went wrong registering push-notifications');
      }

      await PushNotifications.register();
    },
    async registerDevice(maxRetries = 1, retryCount = 0) {
      await useUserStore().fetchFreelancerData(true);
      if (this.isDeviceRegistered || !this.token || useUserStore().isUserAdmin) return;

      const { model, osVersion, platform } = await Device.getInfo();
      const { identifier } = await Device.getId();

      try {
        const result = await notificationService.registerDevice({
          deviceKey: identifier,
          deviceModel: model,
          firebaseRegistrationId: this.token.value,
          osVersion,
          platform: platform === 'android' ? 1 : 2,
        });
        this.isDeviceRegistered = result;

        if (!result) {
          throw new Error('not able to unregister');
        }
      } catch (e) {
        if (retryCount < maxRetries) {
          await this.registerDevice(maxRetries, retryCount + 1);
        }
      }
    },
    async addListeners() {
      await PushNotifications.removeAllListeners();
      await PushNotifications.addListener('registration', this.setRegistrationToken);
      await PushNotifications.addListener('registrationError', this.setRegistrationError);
      await PushNotifications.addListener('pushNotificationReceived', this.pushNotificationReceived);
      await PushNotifications.addListener('pushNotificationActionPerformed', this.pushNotificationActionPerformed);
    },
    async unregisterDevice(maxRetries = 1, retryCount = 0) {
      if (!this.token) return;

      try {
        const result = await notificationService.unRegisterDevice(this.token.value);
        this.isDeviceRegistered = !result;

        if (!result) {
          throw new Error('not able to unregister');
        }
      } catch (e) {
        if (retryCount < maxRetries) {
          await this.unregisterDevice(maxRetries, retryCount + 1);
        }
      }

      if (retryCount > 0) return;

      await PushNotifications.removeAllDeliveredNotifications();
      this.receivedNotifications = [];
      this.performedActions = [];
    },
    setRegistrationToken(token: Token) {
      this.token = token;
    },
    setRegistrationError(error: RegistrationError) {
      this.registrationError = error;
    },
    pushNotificationReceived(notification: PushNotificationSchema) {
      this.receivedNotifications = [...this.receivedNotifications, notification];
    },
    pushNotificationActionPerformed(action: ActionPerformed) {
      this.performedActions = [...this.performedActions, action];

      useNotificationRouting(action.notification);
    },
  },
});
