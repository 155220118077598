import { shiftService } from '@pidz/api';
import { defineStore } from 'pinia';

interface ShiftStore {
  shifts: {
    [shiftId: string]: Shift | undefined
  }
  shiftWorkers: {
    [shiftId: string]: ShiftWorker | undefined
  }
}

export default defineStore('shiftStore', {
  state: (): ShiftStore => ({
    shifts: {},
    shiftWorkers: {},
  }),
  actions: {
    async getShift(shiftId: number) {
      const shift = await shiftService.getShift(shiftId);
      this.shifts[shiftId] = shift;
      return shift;
    },
    async getShiftWorker(shiftId: number) {
      const shiftWorker = await shiftService.getShiftWorker(shiftId);
      this.shiftWorkers[shiftId] = shiftWorker;
      return shiftWorker;
    },
  },
});
