<template>
  <drawer>
    <drawer-details-split :left="left" :right="right" />

    <template v-if="displayInvoiceButton || isSendable" #buttons>
      <router-link v-if="displayInvoiceButton"
                   class="btn-link"
                   :to="{ name: 'freelancer invoice', params: { id: sheetId } }">
        Factuur versturen
      </router-link>
      <template v-if="isSendable">
        <button class="error" @click="$emit('closeSheet')">
          Afsluiten
        </button>
        <async-button v-if="displaySendSheetButton" class="light"
                      @click="(sendSheet as Function)">
          Versturen
        </async-button>
      </template>
    </template>
  </drawer>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Drawer from '@app/components/Drawer.vue';
import DrawerDetailsSplit from '@app/components/DrawerDetailsSplit.vue';
import { SheetStatus } from '@pidz/enums';
import { priceComponents } from '@pidz/utils';
import { computed } from 'vue';

const props = defineProps<{
  sendSheet: Function;
  sheetId: number;
  isSendable: boolean;
  sheetStatus?: number;
  earnings: number;
  totalWorkMinutes: number;
}>();

defineEmits(['sendSheet', 'closeSheet']);

const duration = computed(() => ({
  hours: Math.floor(props.totalWorkMinutes / 60),
  minutes: Math.round(props.totalWorkMinutes % 60),
}));

const left = computed(() => ({
  label: 'Totaal aantal gewerkte uren:',
  value: `${duration.value.hours} u `,
  suffix: `${duration.value.minutes} min.`,
}));

const right = computed(() => {
  const { euros, cents } = priceComponents(props.earnings);
  return {
    label: 'Verdiend:',
    value: `€${euros},`,
    suffix: `${cents}`,
  };
});

const displayInvoiceButton = computed(() => props.sheetStatus === SheetStatus.APPROVED_BY_CUSTOMER);
const displaySendSheetButton = computed(() => props.totalWorkMinutes > 0);

</script>
