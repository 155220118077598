<template>
  <div class="address">
    <div class="address__fields" :class="{ 'address__fields--web': isWeb }">
      <p-form ref="addressForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-buttons="true"/>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import {
  businessAddress_StreetField,
  businessAddress_HouseNumberField,
  businessAddress_ZipCodeField,
  businessAddress_CityField,
  businessAddress_PoBoxField,
  businessAddress_CountryField,
  sameAddressField
} from '@pidz/shared/forms/profile/address';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useUserStore } from '@pidz/stores';
import { onBeforeMount, ref, watch } from 'vue';
import {
  useRouter,
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';

interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<AddressForm>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  disabled: false,
});

const addressForm = ref<any>();
const router: Router = useRouter();
const userStore = useUserStore();

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'profile_modal_edit_cancel' : 'profile_modal_add_cancel'

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  warnBeforeCancel,
} = useFormFunctions(
  addressForm,
  [],
  () => rerouteToOverview(router, 'profile'),
  () => Promise.resolve(),
  props.isEdit,
  'contact gegevens',
);

const onSubmit = async () => {
  const submit = addressForm?.value?.handleSubmit(
    async (formValues: AddressForm) => {
      if (
        addressForm?.value?.isValidating ||
        !addressForm?.value?.meta.valid ||
        !userStore.freelancer
      )
        return;

      formSubmitted.value = true;
      let freelancer = userStore.freelancer;

      // freelancer fields
      if (userStore.isUserFreelancer) {
        freelancer.street_freelancer = formValues.street;
        freelancer.house_number_freelancer = formValues.houseNumber;
        freelancer.zip_code_freelancer = formValues.zipCode;
        freelancer.city_freelancer = formValues.city;
        freelancer.country_freelancer = formValues.country;

        freelancer.organisation.street = formValues.isSameAddress
          ? formValues.street
          : formValues.businessAddress_street;
        freelancer.organisation.house_number = formValues.isSameAddress
          ? formValues.houseNumber
          : formValues.businessAddress_houseNumber;
        freelancer.organisation.zip_code = formValues.isSameAddress
          ? formValues.zipCode
          : formValues.businessAddress_zipCode;
        freelancer.organisation.city = formValues.isSameAddress
          ? formValues.city
          : formValues.businessAddress_city;
        freelancer.organisation.country = formValues.isSameAddress
          ? formValues.country
          : formValues.businessAddress_country;
      }

      // agency worker fields
      if (userStore.isUserAgencyWorker) {
        freelancer.street = formValues.street;
        freelancer.house_number = formValues.houseNumber;
        freelancer.zip_code = formValues.zipCode;
        freelancer.city = formValues.city;
        freelancer.country = formValues.country;
      }

      if (formValues.poBox) freelancer.organisation.po_box = formValues.poBox;
      if (formValues.businessAddress_poBox)
        freelancer.organisation.po_box = formValues.businessAddress_poBox;

      await userStore.updateFreelancerData(freelancer);
      rerouteToOverview(router, 'profile');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit });

watch(
  () => addressForm?.value?.values?.isSameAddress,
  (value) => {
    if(userStore.isUserFreelancer){
      businessAddress_StreetField.value.active = !value;
      businessAddress_HouseNumberField.value.active = !value;
      businessAddress_ZipCodeField.value.active = !value;
      businessAddress_CityField.value.active = !value;
      businessAddress_PoBoxField.value.active = !value;
      businessAddress_CountryField.value.active = !value;
    }
  },
);

onBeforeMount(async () => {
  sameAddressField.value.active = userStore.isUserAgencyWorker ? false : true;
  businessAddress_StreetField.value.active = userStore.isUserAgencyWorker ? false : true;
  businessAddress_HouseNumberField.value.active = userStore.isUserAgencyWorker ? false : true;
  businessAddress_ZipCodeField.value.active = userStore.isUserAgencyWorker ? false : true;
  businessAddress_CityField.value.active = userStore.isUserAgencyWorker ? false : true;
  businessAddress_PoBoxField.value.active = userStore.isUserAgencyWorker ? false : true;
  businessAddress_CountryField.value.active = userStore.isUserAgencyWorker ? false : true;
});
</script>
<style scoped lang="scss">
.address {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-bottom: 8px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}

:deep(.input__checkbox label) {
  flex: 1;
}
</style>
