<template>
  <div class="w-full flex flex-col flex-grow" :data-dynamic-prevent-pull="preventPullToRefresh">
    <reload-page :reload="reload" :loading="forceReload"/>
    <top-bar>
      <template #left>&nbsp;</template>
      <template #default>Inloggen als...</template>
      <template #right>
        <p-button class="logout-btn"
                     variant="ghost"
                     @click="logoutClick">
          <logout-icon />
        </p-button>
      </template>
    </top-bar>
    <search-bar v-model="request.search" storage-key="freelancers"
                class="border-b border-neutral-300" />
              
    <p v-if="empty" class="text-center p-4">Er zijn geen resultaten gevonden</p>
    <div v-else class="flex-grow contain-strict">
      <virtual-scroller :items="impersonationOverviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        :scroll-position="0"
                        scroll-item-component="impersonation"
                        smooth
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"/>

    </div>
  </div>
</template>
<script lang="ts" setup>
import ReloadPage from '@app/components/ReloadPage.vue';
import SearchBar from '@app/components/SearchBar.vue';
import TopBar from '@app/components/TopBar.vue';
import VirtualScroller from '@app/components/virtualscroller/VirtualScroller.vue';
import { logout } from '@app/user-state';
import { LogoutIcon } from '@pidz/icons';
import { useImpersonationStore } from '@pidz/stores';
import { ref, reactive, watch, computed } from 'vue';

const impersonationStore = useImpersonationStore();

const endOfList = ref(false);
const forceReload = ref(false);
const loading = ref(false);
const preventPullToRefresh = ref(false);
const empty = ref(false);

const impersonationOverviewItems = computed(() => impersonationStore.impersonationOverviewItems);

const request = reactive({
  limit: 25,
  offset: impersonationOverviewItems.value.length,
  search: '',
});

const loadNext = async () => {
  if (loading.value) return;

  loading.value = true;

  const newFreelancers = await impersonationStore.getFreelancers({
    start: request.offset,
    limit: request.limit,
    search: request.search === '' ? undefined : request.search,
  });
  
  request.offset += request.limit;
  endOfList.value = newFreelancers.length !== request.offset;
  loading.value = false;

  empty.value = newFreelancers.length === 0;

};

const reload = async () => {
  await impersonationStore.clearImpersonation();

  request.offset = 0;
  
  await loadNext();
};

const logoutClick = async () => {
  logout();
};

watch(() => request.search, () => reload());
</script>
