import { formatYmd } from '@pidz/date';
import { DateTime } from 'luxon';
import { dateField, singleDateField } from './form-fields';

export const toggleDateFieldLogic = (workExperienceForm, value: boolean) => {
  dateField.value.active = !value;
  singleDateField.value.active = value;
  if (value && !workExperienceForm?.value?.values.start_date) {
    workExperienceForm?.value?.setFieldValue(
      'start_date',
      workExperienceForm?.value?.values.date.start_date,
    );
  }
  if (!value && workExperienceForm?.value?.values.start_date) {
    workExperienceForm?.value?.setFieldValue(
      'date.start_date',
      workExperienceForm?.value?.values.start_date,
    );
    workExperienceForm?.value?.setFieldValue(
      'date.end_date',
      formatYmd(DateTime.now()),
    );
  }
};

export const endDateMinimumValueLogic = (value: string) => {
  if (dateField.value.data.items) {
    dateField.value.data.items[1].data.min = value;
  }
};
