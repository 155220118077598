<template>
  <div class="toggle" :class="[{'toggle--disabled': disabled}]">
    <input :id="inputId"
           v-model="checked"
           type="checkbox"
           class="toggle__input"
           :disabled="disabled"
           :value="value"/>
    <div :class="['toggle__wrapper', {'toggle__wrapper--colored': colored}, ] ">
      <div class="toggle__slider">
        <p-icon v-if="checked"
                :icon-name="'check'"
                :color="disabled ? 'light-grey' : iconColor"
                size="small"
                class="toggle__icon"/>
        <p-icon v-else
                :icon-name="'close'"
                :color="disabled ? 'light-grey' : 'grey'"
                size="small"
                class="toggle__icon"/>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import PIcon from '../icon/Icon.vue';

const props = withDefaults(defineProps<{
  modelValue: boolean
  inputId: string
  disabled?: boolean
  value?: string | number
  colored?: boolean
}>(), {
  modelValue: false,
  disabled: false,
  colored: false
});

const iconColor = computed(() => (props.colored ? 'grey' : 'blue'));

const emit = defineEmits(['update:modelValue']);

const checked = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  }
});
</script>

<style scoped lang="scss">
.toggle {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  margin: 2px;
  &__input{
    opacity: 0;
    width: 54px;
    height: 28px;
    position: relative;
    z-index: 2;
    margin: 0;
    &:checked + .toggle__wrapper {
      background-color: var(--pidz-blue-light);
      border: 1px solid transparent;
      &--colored {
        background-color: var(--pidz-green-light);
      }
    }

    &:checked + .toggle__wrapper .toggle__slider{
      transform: translateX(1.5em);
    }

    &:disabled + .toggle__wrapper {
      background-color: rgba(var(--pidz-grey-light), .3);
      border: 1px solid transparent;
    }
  }

  &__wrapper{
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    background: var(--pidz-grey-light);
    transition: .4s;
    border-radius: 30px;
    border: 1px solid #ccc;
    overflow: hidden;
    &--colored {
      background: var(--pidz-red-light);
    }
  }

  &__slider{
    display: flex;
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    border-radius: 16px;
    left: 1.2px;
    top: 1px;
    bottom: 1px;
    background-color: white;
    box-shadow: 0 2px 5px #999999;
    transition: .4s;
    z-index: 1;
  }
  &__icon {
    margin: auto;
  }
}
</style>
