<template>
  <svg class="ExclamationCircle pidz-icon" width="25px"
       height="25px" viewBox="0 0 25 25"
       version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon/19 x 19/check/circle/blue</title>
    <g id="Error-messages" stroke="none"
       stroke-width="1" fill="none"
       fill-rule="evenodd">
      <g id="AgendaItem_New-Copy-49" transform="translate(-24.000000, -102.000000)">
        <g id="Group-3" transform="translate(8.000000, 82.000000)">
          <g id="icon/19-x-19//check/circle/red" transform="translate(16.000000, 20.000000)">
            <circle id="Oval" fill="#FFFFFF"
                    cx="12" cy="12"
                    r="10.7368421"></circle>
            <path fill="#004D9D" class="circle"
                  d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12.7631579,16.4210526 L11.2368421,16.4210526 C10.9606997,16.4210526 10.7368421,16.6449103 10.7368421,16.9210526 L10.7368421,18.4473684 C10.7368421,18.7235108 10.9606997,18.9473684 11.2368421,18.9473684 L12.7631579,18.9473684 C13.0393003,18.9473684 13.2631579,18.7235108 13.2631579,18.4473684 L13.2631579,16.9210526 C13.2631579,16.6449103 13.0393003,16.4210526 12.7631579,16.4210526 Z M12.7631579,5.05263158 L11.2368421,5.05263158 C10.9606997,5.05263158 10.7368421,5.2764892 10.7368421,5.55263158 L10.7368421,8.93305263 L11.3387891,14.7097158 C11.3653297,14.9644165 11.5800166,15.1578947 11.8360964,15.1578947 L12.1480611,15.1578947 C12.4036223,15.1578947 12.6180499,14.9651765 12.645226,14.7110643 L13.0568378,10.862262 C13.1942869,9.57703529 13.2631579,8.28539773 13.2631579,6.99284211 L13.2631579,5.55263158 C13.2631579,5.2764892 13.0393003,5.05263158 12.7631579,5.05263158 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
