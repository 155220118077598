<template>
  <textarea :id="inputId"
            v-model="value"
            class="textarea"
            :rows="rows"/>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: string | number | string[] | undefined;
    inputId: string;
    rows?: number;
  }>(),
  {
    rows: 3
  }
);

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  }
});

</script>
<style scoped lang="scss">
.textarea {
  width: 100%;
  border: 1px solid var(--pidz-black);
  border-radius: 5px;
  padding: 12px;
  font-family: Lato;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--pidz-body-text);
  resize: vertical;
}
</style>
