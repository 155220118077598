<template>
  <div class="work-experience">
    <div class="work-experience__fields"
         :class="{ 'work-experience__fields--web': isWeb }">
      <p-form ref="workExperienceForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-buttons="true"/>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import {
  endDateMinimumValueLogic,
  functionField,
  functionOptions,
  sectorField,
  sectorOptions,
  toggleDateFieldLogic,
} from '@pidz/shared/forms/cv/work';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useCVStore } from '@pidz/stores';
import { onMounted, ref, watch } from 'vue';
import {
  useRouter,
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';

interface Props {
  workExperienceItem?: WorkExperience;
  isWeb?: boolean;
  isEdit?: boolean;
  formData:
    | PidzFormData<WorkExperienceForm>
    | PidzFormData<NewWorkExperienceForm>;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
});

const workExperienceForm = ref<any>();

const router: Router = useRouter();
const cvStore = useCVStore();

const itemId = props.workExperienceItem?.id ?? 0;

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  onDelete,
  warnBeforeCancel,
} = useFormFunctions(
  workExperienceForm,
  [],
  () => rerouteToOverview(router, 'cv'),
  async () => {
    formSubmitted.value = true;
    await cvStore.removeWorkExperience(itemId);
    succesToaster('Succesvol verwijderd');
  },
  props.isEdit,
  'werkervaring',
);

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'cv_modal_edit_cancel' : 'cv_modal_add_cancel';

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const onSubmit = async () => {
  const submit = workExperienceForm?.value?.handleSubmit(
    async (formValues: WorkExperienceForm) => {
      if (
        workExperienceForm?.value?.isValidating ||
        !workExperienceForm?.value?.meta.valid
      ) {
        return;
      }

      const { date, function_name, ...workExperience } = formValues;

      let item: WorkExperience = {
        ...workExperience,
        start_date:
          workExperience.is_current && workExperience.start_date
            ? workExperience.start_date
            : date!.start_date,
        end_date: workExperience.is_current ? null : date?.end_date || null,
        function_name: cvStore.getFunctionNameById(function_name || 0),
      };

      let editItem: WorkExperience = {
        ...item,
        id: itemId,
      };

      //description is required in the backend so we set it to a empty string if its not set
      if (!props.isEdit && !item.description) {
        item.description = '';
      }

      props.isEdit
        ? await cvStore.updateWorkExperience(editItem)
        : await cvStore.addWorkExperience(item);

      formSubmitted.value = true;
      rerouteToOverview(router, 'cv');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

onMounted(async () => {
  await cvStore.loadFreelancerResumeFunctions();
  await cvStore.loadAllSectors();
  functionField.value.data.options = functionOptions(
    cvStore.freelancerResumeFunctions || [],
  );
  sectorField.value.data.options = sectorOptions(cvStore.allSectors || []);
});

watch(
  () => workExperienceForm?.value?.values?.is_current,
  (value) => toggleDateFieldLogic(workExperienceForm, value),
);

watch(
  () => workExperienceForm?.value?.values?.date?.start_date,
  (value) => endDateMinimumValueLogic(value),
);

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit, onDelete });
</script>
<style scoped lang="scss">
.work-experience {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}
</style>
