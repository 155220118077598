import { ContactFormReason, ContactFormType } from '@pidz/enums';
import { useApi } from '../../utils/use-api';
const api = useApi();

export const contactService = {
  sendHotlineMail: (params: { contactType: ContactFormType,
    about: ContactFormReason, message: string, anonymous: boolean}):
    Promise<{}> => {
    return api.jsonrpc({ 
      url: 'contact:sendHotLineMail',
      params
    });
  },
}