export enum CardType {
    REGISTRATION = 'registration',
}

export enum ResumeStatus {
  STATUS_NEW = 1,
  STATUS_APPROVED = 2,
  STATUS_DISAPPROVED = 3
}

export enum PidzStatusType {
  APPROVED = 'approved',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  WAITING = 'waiting',
}

export enum AssetStatus {
  NEW = 'new',
  EXPIRED = 'expired',
  APPROVED = 'approved',
  DISAPPROVED = 'disapproved',
  NEARING_EXPIRATION_DATE = 'nearing_expiration_date'
}

export enum SectionStatus {
  NEW = 0,
  APPROVED = 1,
  DECLINED = 2,
  EXPIRED = 3,
  NEARING_EXPIRATION_DATE = 4,
}

export enum FreelancerFunctionVatType {
  DEFAULT = 1,
  VATFREE = 2,
  VATLIABLE = 3
}

export enum CvSectionStatus {
  NEW = 0,
  APPROVED = 1,
  DECLINED = 2,
  EXPIRED = 3,
  NEARING_EXPIRATION_DATE = 4
}

export enum LanguageProficiencyLanguage {
  NL = 'nl',
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  TR = 'tr',
  AR = 'ar',
  PO = 'po'
}

export enum LanguageProficiencyLevel {
  NATIVE = 1,
  PROFESSIONAL = 2,
  BASIC = 3
}

export enum WorkExperienceAppointment {
  TEMPORARY = 0,
  PERMANENT = 1,
  INTERN = 2,
}

export enum AssetTypeRequiredType {
  NOT_REQUIRED = 0,
  REQUIRED = 1,
  SEMI_REQUIRED = 2
}

export enum RegistrationProofType {
  NUMBER = 1,
  ASSET = 2,
  BOTH = 3
}

export enum RouteType {
  ADD = 'add',
  EDIT = 'edit'
}

export enum CvSection {
  FUCNTIONS = 'functions',
  SECTORS = 'sectors',
  WORK = 'workExperience',
  EDUCATION = 'educations',
  TRAINING = 'trainings',
  REGISTRATION = 'registrations',
  LANGUAGE = 'languages',
}

export enum Registrations {
  BIG_REGISTRATION = 1, // BIG REGISTRATIE
  HKZ_REGISTRATION = 2, // HKZ REGISTRATIE ZZPERS
  SKJ_REGISTRATION = 4, // SKJ REGISTRATIE
  KIWA_REGISTRATION = 5, // KIWA KEURMERK REGISTRATIE
  QUALITY_V_AND_V_REGISTRATION = 6, // REGISTRATIE KWALITEITSREGISTER V&V
  VSR_REGISTRATION = 7, // REGISTRATIE VERPLEEGKUNDIG SPECIALISTEN
  HEALTHCARE_PORTAL_REGISTRATION = 8 // REGISTRATIE ZORGAANBIEDERSPORTAAL
}
