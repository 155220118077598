<template>
  <svg class="GeneralSleepShiftBlue pidz-icon" width="30"
       height="30" viewBox="0 0 30 30"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill="none" fill-rule="evenodd">
      <g stroke="currentColor" stroke-linecap="round"
         stroke-width="2">
        <path d="M20.127 6.876a8.53 8.53 0 0 0-5.979 2.826 8.511 8.511 0 0 0-2.164 5.907m5.169 7.582c1.688.72 3.62.902 5.53.39a8.493 8.493 0 0 0 5.905-5.646"/>
        <path d="M19.953 6.946a7.5 7.5 0 0 0 8.457 10.96"/>
      </g>
      <g stroke="currentColor" stroke-linecap="round"
         stroke-width="2">
        <path d="M13 17.972C12.577 16.269 10.948 15 9.003 15 7.048 15 5.413 16.283 5 18m8.925 6C15.623 24 17 22.657 17 21s-1.377-3-3.075-3c-.322 0-.633.048-.925.138"/>
        <path d="M4.935 18.125A3.115 3.115 0 0 0 4.059 18C2.369 18 1 19.343 1 21s1.37 3 3.059 3H14"/>
      </g>
      <g fill="currentColor">
        <path d="m26.996 8.987-.018 4.025a.992.992 0 0 1-.992.988.983.983 0 0 1-.982-.987l.018-4.025A.992.992 0 0 1 26.014 8a.983.983 0 0 1 .982.987Z"/>
        <path d="m28.013 10.004-4.025.018a.992.992 0 0 0-.988.992.983.983 0 0 0 .987.982l4.025-.018a.992.992 0 0 0 .988-.992.983.983 0 0 0-.987-.982Z"/>
      </g>
      <g fill="currentColor">
        <path d="m9.996 4.987-.018 4.025a.992.992 0 0 1-.992.988.983.983 0 0 1-.982-.987l.018-4.025A.992.992 0 0 1 9.014 4a.983.983 0 0 1 .982.987Z"/>
        <path d="m11.013 6.004-4.025.018A.992.992 0 0 0 6 7.014a.983.983 0 0 0 .987.982l4.025-.018A.992.992 0 0 0 12 6.986a.983.983 0 0 0-.987-.982Z"/>
      </g>
    </g>
  </svg>
</template>
