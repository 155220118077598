<template>
  <p-banner v-if="bannerInfo" :title="bannerInfo.title" 
               :text="bannerInfo.text" :type="bannerInfo.type" />
  <layout-desktop-wrapper>
    <education-form ref="form" 
                    :form-data="formDataEducation"
                    :education-item="educationItem"
                    is-edit
                    :is-disabled="isEditDisabled"/>
    <form-delete-row name="Opleiding" :on-delete="form?.onDelete"
                     class="border-t border-neutral-200"/>
    <form-buttons-row v-if="!isEditDisabled"
                      :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled || form?.showFileError || isEditDisabled"/>
  </layout-desktop-wrapper></template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import FormDeleteRow from '@app/components/FormDeleteRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { CvSectionStatus } from '@pidz/enums';
import { EducationForm } from '@pidz/shared/components';
import { 
    globalValidations, 
    rows,
} from '@pidz/shared/forms/cv/education';
import { useCVStore } from '@pidz/stores';
import { getBannerInfo } from '@pidz/utils'
import { DateTime } from 'luxon'
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const form = ref<typeof EducationForm>();

const route = useRoute();
const cvStore = useCVStore();

const educationItem = computed(() => cvStore.getEducationById(Number(route.params.id)));

const bannerInfo = computed(() => {
  const { status, deny_reason } = educationItem.value as Education;
  return getBannerInfo('opleiding', status, deny_reason);
})

const isEditDisabled = computed(() => !!educationItem.value?.status && [CvSectionStatus.APPROVED]
.includes(educationItem.value.status));

const formatEducation = (education: Education): EducationForm => {
  const { subject, end_date, ...item} = education
  return { 
    ...item, 
    subject: subject.id,
    end_date: formatYmd(DateTime.fromISO(end_date))
  }
}

const formDataEducation: PidzFormData<EducationForm> = {
  globalValidations,
  values: formatEducation(educationItem.value as Education),
  rows
}

const loadStores = async () => {
  await cvStore.loadResume();
};

onMounted(async () => {
  await loadStores();
});
</script>
<style lang="scss" scoped>
.education{    
  &__delete-btn{
    padding: 32px 16px;
    background-color: var(--pidz-white);
  }
  &__delete-txt {
      color: var(--pidz-black);
    }
}
</style>
