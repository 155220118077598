import { RowType } from '@pidz/enums';
import { anonymous, message, about, contactRadioGroupOptions } from './form-fields';
// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<ContactForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [contactRadioGroupOptions.value],
  },
  {
    type: RowType.ROW,
    items: [about.value],
  },
  {
    type: RowType.ROW,
    items: [message.value],
  },
  {
    type: RowType.ROW,
    items: [anonymous.value],
  },
];
