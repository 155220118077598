<template>
  <div :class="[
    'flex items-center justify-center min-h-4 min-w-4 px-1 rounded-full text-xxs text-white',
    {'border-solid border-1 border-white': bordered},
    classVariant,
  ]">
    <template v-if="typeof content === 'number'">
      {{content > 99 ? '99+' : content}}
    </template>
    <template v-else>
      {{ content }}
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';


interface Props {
  content: number | string,
  variant?: Variants
  bordered?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'danger',
  bordered: true
});

const classVariant = computed(() => 
  props.variant === 'success' ? 'bg-green' : 
  props.variant === 'info' ? 'bg-blue-600' : 
  props.variant === 'warn' ? 'bg-orange' : 
  'bg-red'
);
</script>
