<template>
  <p-content-section is-expandable
                     :has-add-button="true"
                     @add="emit('add-work-experience')">
    <template #header>
      <div class="work-experience-header">
        <p-badge v-if="isBadgeVisible"
                 class="work-experience-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading icon="jobs" class="font-bold">Werkervaring</p-heading>
      </div>
    </template>
    <template v-if="sortedExperiences && sortedExperiences.length" #content>
      <div class="work-experience">
        <p-time-line class="work-experience__timeline">
          <template #timeline-items>
            <li v-for="experience in sortedExperiences">
              <work-time-line-item :data="experience"
                                   @edit="(e: Event) => edit(e, experience.id)"/>
            </li>
          </template>
        </p-time-line>
      </div>
    </template>
    <template #empty>
      <p>
        <strong>Verplicht: Minimaal 2 jaar werkervaring na diplomering</strong><br />
        Klik hierboven op het plusje om je eerste werkervaring toe te voegen aan
        je CV
      </p>
    </template>
  </p-content-section>
</template>

<script lang="ts" setup>
import { useCVStore } from '@pidz/stores';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import WorkTimeLineItem from './WorkTimeLineItem.vue';

const cvStore = useCVStore();

const props = defineProps<{
  experiences: WorkExperience[] | undefined;
  isBadgeVisible: boolean;
}>();

const edit = (e: Event, id: number) => {
  e.stopPropagation();
  emit('edit-work-experience', id);
};

const emit = defineEmits(['add-work-experience', 'edit-work-experience']);

const sortedExperiences = computed(() => {
  if (!props.experiences) return;

  const timelineWorkItems = props.experiences.map((item) =>
    convertToWorkExperienceTimeline(item),
  );

  return timelineWorkItems.sort((a, b) => {
    if (!a.endDate) return -1;
    if (!b.endDate) return 1;
    return b.endDate.toMillis() - a.endDate.toMillis();
  });
});

const convertToWorkExperienceTimeline = (
  item: WorkExperience,
): TimelineItem & TimelineItemWork => {
  return {
    id: item.id,
    type: 'work',
    title: item.function_name,
    subtitle: `${item.institution_name} - ${cvStore.getSectorNameById(item.sector_id)} - ${appointmentType.get(item.appointment_type)}`,
    description: item.description,
    startDate: DateTime.fromISO(item.start_date),
    endDate: item.end_date ? DateTime.fromISO(item.end_date) : null,
  };
};

const appointmentType = new Map([
  [0, 'Tijdelijke aanstelling'],
  [1, 'Vaste aanstelling'],
  [2, 'Stagiair'],
]);
</script>

<style lang="scss" scoped>
.work-experience-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}
.work-experience {
  margin-left: 0.6rem;
  &__timeline {
    @media screen and (min-width: 768px) {
      margin-left: -2.4rem;
    }
  }
}
</style>
