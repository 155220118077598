import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { useMollieStore } from '@pidz/stores';

export const mollieMandate = async (webUrl: string) => {
  const mollieStore = useMollieStore();

  let url: string | undefined;
  const { platform } = await Device.getInfo();

  if (platform === 'web') {
    const baseUrl = webUrl ?? 'https://app.mijnpidz.nl';
    url = await mollieStore.generateMandatePaymentLink(baseUrl + '/#/settings/profile')
    
    window.location.replace(url);
  } else {
    url = await mollieStore.generateMandatePaymentLink('pidz-app://mollie')

    Browser.open({ url });
  }
};
