<template>
  <div class="overview-tab-bar">
    <slot />
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="scss">
.overview-tab-bar {
    width: 100%;
    top: 0;
    height: 56px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--pidz-blue);

    a {
      display: flex;
      font-weight: 600;
      width: 100%;
      height: 100%;
      justify-content: space-around;
      align-items: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;

      &.router-link-active {
        border-bottom: 2px solid var(--pidz-white);
        color: var(--pidz-white);
      }
    }
  }
</style>
