<template>
  <div class="file-list">
    <div v-if="showFileIcon" class="file-list__pre-icon file-list__column">
      <p-icon icon-name="file"
              size="medium"
              color="black" />
    </div>
    <div class="file-list__info file-info file-list__column">
      <div class="file-info__file">{{ fileName }}</div>
      <p-status v-if="status" :variant="status"></p-status>
      <div v-if="date" class="file-info__date">{{ `Geldig tot ${formatDate(date)}` }}</div>
    </div>
    <div class="file-list__icons file-list__column">
      <p-button v-if="showOpenButton"
                class="file-list__button"
                variant="ghost"
                @click="emit('open')">
        <p-icon icon-name="search"
                size="medium"
                color="blue" />
      </p-button>
      <p-button v-if="showDownloadButton"
                class="file-list__button"
                variant="ghost"
                @click="emit('download')">
        <p-icon icon-name="download"
                size="medium"
                color="blue" />
      </p-button>
      <p-button v-if="showDeleteButton"
                class="file-list__button"
                variant="ghost"
                @click="emit('delete')">
        <p-icon icon-name="bin"
                size="medium"
                color="blue" />
      </p-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { AssetStatus } from '@pidz/enums';
import { DateTime } from 'luxon';
import { Ref, computed } from 'vue';

const props = withDefaults(defineProps<{
  status?: AssetStatus,
  fileName: string
  date?: DateTime,
  showFileIcon?: boolean,
  showOpenButton?: boolean
  showDownloadButton?: boolean
  showDeleteButton?: boolean
  customDateFormat?: Intl.DateTimeFormatOptions
}>(), {
  showFileIcon: false,
  showOpenButton: false,
  showDownloadButton: false,
  showDeleteButton: false
});

const emit = defineEmits(['open', 'delete', 'download']);

const formatDate = (date: DateTime): string => date.toLocaleString(props.customDateFormat
  || { year: 'numeric', month: 'short', day: '2-digit' });

const offsetWidth: Ref<string> = computed(() => {
  let activeIcons: number = 0;

  if (props.showDeleteButton) {
    activeIcons += 1;
  }
  if (props.showOpenButton) {
    activeIcons += 1;
  }
  if (props.showDownloadButton) {
    activeIcons += 1;
  }

  let gap:number = 0;
  if (activeIcons > 1) {
    gap = (activeIcons - 1) * 16;
  }

  let preIconWidth: number = 0;
  if (props.showFileIcon) {
    preIconWidth = 40;
  }
  return `${activeIcons * 24 + gap + preIconWidth}px`;
});
</script>

<style lang="scss" scoped>
.file-list{
  display: flex;
  flex-direction: row;

  &__column{
    width: 100%;
  }

  &__pre-icon{
    max-width: 40px;
  }

  &__info{
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: calc(100% - v-bind(offsetWidth));
    justify-content: center;
  }

  &__icons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-start;
    gap: 16px;
    max-width: 104px;
  }

  &__button{
    max-width: 24px;
  }
}

.file-info{
  &__date{
    color: var(--pidz-grey-dark);
  }

  &__file{
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

}
</style>
