import { organisationService } from '@pidz/api';
import { defineStore } from 'pinia';

interface OrganisationState {
  contactDetails: ContactDetails | null;
}

export default defineStore('organisation', {
  state: ():OrganisationState => ({
    contactDetails: null,
  }),
  actions: {
    async getContactDetails() {
      if (!this.contactDetails) {
        this.contactDetails = await organisationService.getContactDetails();
      }

      return this.contactDetails;
    },
  },
});
