import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { createRouter, createWebHashHistory } from 'vue-router';
import {
  cv,
  settings,
  calendar,
  shifts,
  sheets,
  invitations,
  invoices,
  generalLoggedOut,
  generalLoggedIn,
  contact
} from './routes'
import { checkPermission } from './routes/navigation-guards/permission';
import { logCustomEvent } from './utils/firebase-analytics';

const routes = [
  {
    path: '',
    component: () => import('./components/layout/Layout.vue'),
    children: [
      ...generalLoggedIn,
      ...invoices,
      ...calendar,
      ...shifts,
      ...sheets,
      ...invitations,
      ...cv,
      ...contact,
      ...settings,
    ]
  },
  ...generalLoggedOut,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  FirebaseAnalytics.setScreenName({
    screenName: to.fullPath,
  });
  const { name } = to;
  logCustomEvent('page_view', { to: name ?? 'unnamed route' });
});

router.beforeEach(async (to, from) => {
  const hasPermission = await checkPermission(to, from);
  if(hasPermission) document.title = 'PIDZ' + (to.meta?.title ? ' | ' + to.meta.title : ''); 
  return hasPermission;
});

export default router;
