import { fetchAndActivate, getString } from '@app/FirebaseProxy';
import useEnvironment from '@app/stores/environment';
import { Preferences } from '@capacitor/preferences';
import config from './configure-firebase-app';

export const getEnvironments = async () => {
  await fetchAndActivate(config);
  return getString(config, 'environments_new');
};

const getActiveEnvironment = async () => {
  const { value: isEnvFixed } = await Preferences.get({ key: 'envProdFix'});
  const { value: activeEnvironment } = await Preferences.get({ key: 'environment' });

  if (!activeEnvironment || isEnvFixed !== 'fixed') {
    
    const hostMatch = window.location.host.match(/app.(local|test|acc).mijnpidz.nl/);
    if (hostMatch) {
      const environmentName = hostMatch[1] === 'acc' ? 'acceptance' : hostMatch[1];
      return JSON.parse(await getEnvironments())
        .find((env:Environment) => env.name === environmentName);
    }
    const defaultConfig = JSON.parse(config.defaultConfig);

    Preferences.set({key: 'envProdFix', value: 'fixed'})

    return defaultConfig.environments.find((environment: Environment) => environment.name === 'production');
  }
  return JSON.parse(activeEnvironment);
};

let isConfigInitialized: any = null;
export const configInitialized = new Promise((ok) => { isConfigInitialized = ok; });

const initEnvironment = async () => {
  const environmentStore = useEnvironment();
  environmentStore.setActiveEnvironment(await getActiveEnvironment());
  environmentStore.all = JSON.parse(config.defaultConfig).environments;
  isConfigInitialized();
};

export default initEnvironment;
