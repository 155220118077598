import { clientsService } from '@pidz/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('clients', () => {
  const organisations = ref<Organisation[]>();
  const fetchOrganisations: () => Promise<void> = async () => {
    if(!organisations.value) organisations.value = await clientsService.getLinkedOrganisations();
  }

  const departmentsByOrganisationId = ref<Record<number, Department[]>>({});
  const fetchDepartmentsByOrganisation = async (organisationId: number) => {
    const departments = await clientsService.getLinkedDepartmentsByOrganisation(organisationId);
    departmentsByOrganisationId.value[organisationId] = departments;
    return departments;
  }

  const getDepartmentsByOrganisationId = async (organisationId: number) => {
    if (departmentsByOrganisationId.value[organisationId]) return departmentsByOrganisationId.value[organisationId];

    return await fetchDepartmentsByOrganisation(organisationId);
  }

  const updateLinkedDepartment = async (department: Department) => {
    await clientsService.updateLinkedDepartment(department);
  }
  
  const updateLinkedDepartments = async (value: Department[], orgId: number) => {
    await Promise.all(value.map(async (dep) => {
      const storeDept = departmentsByOrganisationId.value[orgId]?.find((d) => d.department_id === dep.department_id);
      if (dep.status !== storeDept?.status) updateLinkedDepartment(dep);
    }))
    departmentsByOrganisationId.value[orgId] = [...value];
    recalculateOrgParams(orgId)
  };

  const updateOrganisationBlocked = async (orgId: number, blocked: boolean) => {
    await clientsService.updateOrganisationBlocked(orgId, blocked);
    const org = organisations.value?.find((o) => o.organisation_id === orgId);
    if (!org) return;
    org.status = blocked ? 'blocked' : 'linked';
    departmentsByOrganisationId.value[orgId] = departmentsByOrganisationId.value[orgId]?.map(dep => {
      return {...dep, status: blocked ? 'blocked' : 'linked'};
    });
    recalculateOrgParams(orgId)
  }

  const recalculateOrgParams = (orgId: number) => {
    const org = organisations.value?.find((o) => o.organisation_id === orgId);
    if (!org) return;
    org.linked_departments_count = departmentsByOrganisationId.value[orgId]?.filter((d) => d.status === 'linked').length || 0;
    if (departmentsByOrganisationId.value[orgId]?.some((d) => d.status === 'linked')) org.status = 'linked';
    else if (departmentsByOrganisationId.value[orgId]?.some((d) => d.status === 'blocked')) org.status = 'blocked';
  }

  const $reset = () => {
    organisations.value = undefined;
    departmentsByOrganisationId.value = {};
  };

  return {
    // state
    organisations,
    departmentsByOrganisationId,

    // getters
    getDepartmentsByOrganisationId,

    // actions
    fetchOrganisations,
    fetchDepartmentsByOrganisation,
    updateLinkedDepartment,
    updateLinkedDepartments,
    updateOrganisationBlocked,

    $reset,
  };
});
