<template>
  <div :class="['date-range-picker', errorMessage && errorMessage.trim().length ? '' : 'has-padding']">
    <div class="input-container">
      <p-label :input-id="inputId" :label="label" />
      <div class="inputs">
        <p-input-prepend>
          <p-icon icon-name="agenda"
                  color="white"
                  :border-radius="false" />
        </p-input-prepend>
        <p-input v-model="startDate"
                 :input-id="inputId"
                 input-type="date"
                 :has-border-radius-right="false"
                 :has-border-radius-left="false"
                 has-no-left-border/>
        <p-input v-model="endDate"
                 :input-id="inputId"
                 input-type="date"
                 :has-border-radius-left="false"
                 has-no-left-border/>
      </div>
    </div>
    <p-label v-if="errorMessage && errorMessage.trim().length" variant="error"
             :input-id="inputId"
             :label="errorMessage" />
  </div>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  startDate: DateTime,
  endDate: DateTime,
  inputId?: string,
  label?: string,
  errorMessage: string
}>(), {
  inputId: 'date-range',
  label: 'Datum'
});

const emit = defineEmits(['update:startDate', 'update:endDate']);
const startDate = computed({
  get() {
    return props.startDate;
  },
  set(newStartDate) {
    emit('update:startDate', newStartDate);
  }
});

const endDate = computed({
  get() {
    return props.endDate;
  },
  set(newEndDate) {
    emit('update:endDate', newEndDate);
  }
});

</script>

<style scoped lang="scss">
.date-range-picker {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .inputs {
      display: flex;
    }
  }

  &.has-padding {
    .input-container {
      padding-bottom: 24px;
    }
  }
}
</style>
