<template>
  <section v-for="(value, index) in modelValue" :key="index"
           class="pidz-accordion-item" :test-data="`accordion-item-${index}`">

    <div class="header" @click="accordionClicked(index)">
      <div class="title">{{ value.title }}</div>
      <p-icon :class="['chevron', {'active': value.open}]" icon-name="chevron"
              size="small"/>
    </div>

    <div :class="['body-wrapper', {'open': value.open}]">
      <div class="body" v-html="value.body"/>
    </div>
  </section>
</template>

<script lang="ts" setup>

const props = withDefaults(defineProps<{
  modelValue: Array<accordionItem>,
  multi?: boolean,
}>(), {
  multi: false
});

const emit = defineEmits(['update:modelValue']);

const accordionClicked = (index: number) => {
  const newValue = props.modelValue;

  if (props.multi) {
    newValue[index].open = !newValue[index].open;
  }

  if (!props.multi) {
    const oldIndex = newValue.findIndex((element: accordionItem) => element.open === true);

    newValue.forEach((item: accordionItem) => {
      // eslint-disable-next-line no-param-reassign
      item.open = false;
    });

    if (index !== oldIndex) {
      newValue[index].open = true;
    }
  }

  emit('update:modelValue', newValue);
};

</script>

<style scoped lang="scss">
$speed: 500ms;
.pidz-accordion-item {
  border-bottom: 1px solid var(--pidz-grey-light);

  &:last-child {
    border-bottom: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;

    cursor: pointer;

    .title {
      font-weight: bold;
    }

    .chevron {
      transition: transform $speed ease;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }

  .body-wrapper {
    font-size: 16px;

    display: grid;
    grid-template-rows: 0fr;
    transition: all $speed ease;

    &.open {
      grid-template-rows: 1fr;
      padding: 8px 0 16px 0;
    }

    .body {
      overflow: hidden;
    }
  }
}
</style>
