<template>
  <modal>
    <h2 class="font-bold">Uitnodiging geaccepteerd!</h2>
    <p>
      We wachten nog op goedkeuring van de opdrachtgever. Zodra
      {{ organisationName }} de opdracht heeft goedgekeurd krijg je hier bericht
      van.
    </p>
    <template #footer>
      <button @click="modalController.close">Sluit</button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal from '@app/components/Modal.vue';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();

defineProps<{
  organisationName: string;
}>();
</script>
