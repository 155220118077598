<template>
  <p-modal class="cancel-modal" @modal-closed="modalController.close()">
    <h1>Bewerking annuleren?</h1>
    <p>Weet je zeker dat je het bewerken wilt annuleren?</p>
    <p>Je wijzigingen worden niet opgeslagen.</p>
    <template #footer>
      <p-button outlined @on-click="onContinue">
        Verder met bewerken
      </p-button>
      <p-button @on-click="onCancel">Bewerking annuleren</p-button>
    </template>
  </p-modal>
</template>
<script setup lang="ts">
import { useModalController } from '@pidz/utils';

const modalController = useModalController();

const props = defineProps<{
  cancelEdit: Function;
  continueEdit: Function;
}>();

const onContinue = () => {
  modalController.close();
  props.continueEdit();
};

const onCancel = () => {
  modalController.close();
  props.cancelEdit();
};
</script>
<style lang="scss"></style>
