<template>
  <section ref="toasterElement" class="toaster">
    <component :is="toasterIcon[props.type]" class="toaster-icon" />

    <div class="content-wrapper">
      <div class="title">{{ props.title }}</div>
      <div class="message">{{ props.message }}</div>
    </div>

    <div class="close-toaster" @click="closeToaster">
      <close-icon />
    </div>

    <div class="progress-bar"></div>
  </section>
</template>

<script lang="ts" setup>
import CheckCircle from '@app/icons/CheckCircle.vue';
import ExclamationCircle from '@app/icons/ExclamationCircle.vue';
import { CloseIcon } from '@pidz/icons';
import { useToaster } from '@pidz/stores';
import { useHorizontalSwipe } from '@pidz/utils';
import {
  Component as ComponentType, computed, onMounted, ref,
} from 'vue';

const toaster = useToaster();
const toasterElement = ref<HTMLElement | undefined>();

const props = defineProps<{
  type: ToasterType;
  title: string;
  message: string;
  timestamp: string;
  duration: number;
}>();

const closeToaster = () => toaster.removeToaster(props.timestamp);
setTimeout(closeToaster, props.duration);

onMounted(() => {
  useHorizontalSwipe((direction) => {
    if (direction === -1) {
      closeToaster();
    }
  }, toasterElement.value ?? undefined);
});

const toasterIcon: { [key in ToasterType]: ComponentType } = {
  error: ExclamationCircle,
  success: CheckCircle,
  warning: ExclamationCircle,
  info: ExclamationCircle,
};

const toasterColors = {
  error: {
    background: '--pidz-red-light',
    progressBar: '--pidz-red',
  },
  success: {
    background: '--pidz-green-light',
    progressBar: '--pidz-green',
  },
  warning: {
    background: '--pidz-orange-light',
    progressBar: '--pidz-orange',
  },
  info: {
    background: '--pidz-blue-light',
    progressBar: '--pidz-blue',
  },
};

const backgroundColor = computed(
  () => `var(${toasterColors[props.type].background})`,
);
const progressBarColor = computed(
  () => `var(${toasterColors[props.type].progressBar})`,
);
const progressBarTimer = computed(
  () => `progress ${props.duration}ms linear forwards`,
);
</script>

<style lang="scss">
.toaster {
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 8px 0;
  border-radius: 5px;
  background: v-bind(backgroundColor);
  box-shadow: var(--shadow-light);

  .content-wrapper {
    color: var(--pidz-black);

    .title {
      font-size: 14px;
      font-weight: bold;
    }

    .message {
      font-size: 12px;
    }
  }

  .progress-bar {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    right: 0;
    background: v-bind(progressBarColor);

    animation: v-bind(progressBarTimer);
  }

  @keyframes progress {
    100% {
      right: 100%;
    }
  }

  .toaster-icon {
    margin: 0 16px 0 16px;
    min-width: 30px;
    .circle {
      fill: v-bind(progressBarColor);
    }
  }

  .close-toaster {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;

    .CloseIcon {
      color: var(--pidz-black);
      height: 14px;
      width: 14px;
    }
  }
}
</style>
