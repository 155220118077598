<template>
  <teleport to="body">
    <section class="toaster-wrapper">
      <TransitionGroup name="slide-right">
        <toaster v-for="(toaster, key) in toasterStore.activeToasters"
                 :key="key"
                 :type="toaster.type"
                 :title="toaster.title"
                 :message="toaster.message"
                 :duration="toaster.duration"
                 :timestamp="key.toString()"/>
      </TransitionGroup>
    </section>
  </teleport>
</template>

<script lang="ts" setup>
import { useToaster } from '@pidz/stores';
import Toaster from './Toaster.vue';

const toasterStore = useToaster();
</script>

<style lang="scss">
.toaster-wrapper {
  z-index: 101;
  max-width: 100%;
  // width: 100%;
  position: absolute;
  top: 56px;
  padding: 0 8px 0 8px;
  overflow: hidden;

  & > * {
    margin-top: 8px;
  }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: all 0.4s cubic-bezier(0.55, 1.5, 0.7, 1);
  }

  .slide-right-enter-from,
  .slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
