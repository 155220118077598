<template>
  <slider-root v-model="sliderValue"
               class="relative flex items-center select-none touch-none w-full"
               :min="min"
               :max="max"
               :step="step"
               :name="name">
    <slider-track class="relative grow rounded-full h-[8px] bg-blue-200" >
      <slider-range class="absolute rounded-full h-full bg-blue" />
    </slider-track>
    <slider-thumb class="flex justify-center w-5 h-5 bg-white border-[1px] border-blue
    rounded-[10px] focus:outline-none
    absolute"
                  aria-label="Volume">
      <div v-if="$slots.thumbcontent"
           class="text-white flex flex-col items-center justify-center mb-2
            absolute bottom-full
            whitespace-nowrap">
        <div class="content bg-blue p-2 rounded">
          <slot name="thumbcontent"/>
        </div>
        <div class="
          border-l-[8px] border-l-transparent
          border-t-[8px] border-t-blue
          border-r-[8px] border-r-transparent"></div>
      </div>
    </slider-thumb>
  </slider-root>
</template>

<script setup lang="ts">
import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'radix-vue'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  modelValue: number,
  min?: number,
  max?: number,
  step?: number
  name?: string
  }>(), {
  min: 0,
  max: 100,
  step: 1, 
  name: 'slider'
})

const emit = defineEmits(['update:modelValue'])

const sliderValue = computed<number[]>({
  get: () => [props.modelValue],
  set: (value: number[]) => {
    emit('update:modelValue', value[0])
    return value
  }
})
</script>