<template>
  <div class="date-time-range"
       :class="{ inverse }">
    <label>Datum van/tot</label>
    <input-border multi
                  :dark="inverse">
      <agenda-icon />
      <input v-model="dateStart"
             type="date"
             class="date-start"
             :disabled="disabled"/>
      <input v-model="dateEnd"
             type="date"
             class="date-end"
             :disabled="disabled"/>
    </input-border>
    <label>Start- en eindtijd</label>
    <input-border multi
                  :dark="inverse">
      <time-icon />
      <input v-model="timeStart"
             type="time"
             class="time-start"
             :disabled="disabled"/>
      <input v-model="timeEnd"
             type="time"
             class="time-end"
             :disabled="disabled"/>
    </input-border>
  </div>
</template>
<script lang="ts" setup>
import { eventDateTimeToLuxon } from '@pidz/date';
import { AgendaIcon, TimeIcon } from '@pidz/icons';
import { DateTime } from 'luxon';
import {
  onMounted, computed, ref, watch,
} from 'vue';
import InputBorder from '../../../components/InputBorder.vue';


const props = defineProps<{
  modelValue: DateTimeRange;
  inverse?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits(['update:modelValue']);
const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

const createWatchedDateAndTime = (prop: string) => {
  const date = ref();
  const time = ref();
  const updateDateTime = () => {
    const stringValue = date.value && time.value
      ? eventDateTimeToLuxon({date: date.value, time: time.value}).toISO({suppressMilliseconds: true})
      : null;
          value.value = {
      ...value.value,
      [prop]: stringValue,
    };
  };
  watch(date, updateDateTime);
  watch(time, updateDateTime);
  return [date, time];
};

const splitIsoDate = (isoDate?: string | Date) => {
  if (!isoDate) {
    return [null, null];
  }

  const newDate: DateTime = (isoDate instanceof Date) 
    ? DateTime.fromJSDate(isoDate)
    : DateTime.fromISO(isoDate);
    
  const iso = newDate.toFormat('yyyy-MM-dd HH:mm')
  return iso.split(' ');
};

const [dateStart, timeStart] = createWatchedDateAndTime('start');
const [dateEnd, timeEnd] = createWatchedDateAndTime('end');

onMounted(() => {
  [dateStart.value, timeStart.value] = splitIsoDate(props.modelValue.start);
  [dateEnd.value, timeEnd.value] = splitIsoDate(props.modelValue.end);
});
</script>
<style lang="scss">
.date-time-range {
  label {
    display: block;
    color: var(--pidz-black);
    margin-bottom: 4px;
  }
  input {
    width: 100%;

    &[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
  .input-border {
    margin-bottom: 12px;
  }
  &.inverse {
    color: var(--pidz-white);
    label {
      color: var(--pidz-white);
    }
  }
  input:disabled {
    background: var(--pidz-white);
    color: var(--pidz-grey-dark);
  }
}
</style>
