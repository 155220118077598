<template>
  <modal>
    <h2 class="font-bold">Urenregistratie afsluiten?</h2>
    <p v-if="hasSheetRows">
      Vanaf de eerste dag van de maand kun je deze urenregistratie ter
      goedkeuring naar de opdrachtgever sturen.
    </p>
    <p v-else>De urenregistratie wordt niet naar de opdrachtgever verstuurd.</p>
    <template #footer>
      <button @click="modalController.close">Annuleer</button>
      <async-button class="error" @click="closeSheet">Afsluiten</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();
const props = defineProps<{
  closeSheet: Function;
  hasSheetRows: boolean;
}>();

const closeSheet = async () => {
  await props.closeSheet();
};
</script>
