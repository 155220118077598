import { useUserStore, useToaster } from '@pidz/stores'
import { RouteLocationNormalized } from 'vue-router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function checkPermission(to: RouteLocationNormalized, _from: RouteLocationNormalized) {
  const toaster = useToaster()
  const userStore = useUserStore()
  
  const permission = userStore.getPagePermission(String(to.name))
    
  if(permission) return true
  toaster.addToaster({
    type: 'error',
    title: 'Oeps',
    message:
      `Je hebt geen toegang tot de pagina ${String(to.meta.title)}. 
      Neem contact op met de vestiging als je denkt dat dit een fout is`,
  });
  return false
}