export enum InvoiceStatus {
    NEW = 0,
    INVOICED = 1,
    REMINDER_1 = 2,
    REMINDER_2 = 3,
    PAID = 4,
    FINISHED = 5,
    CHECKED_IN = 6,
    REMINDER_3 = 7,
    // missing status 8
    CREDITED = 9,
    FORCED_CREDITED = 10,
    DOUBTFUL_DEBTOR = 11,
}

export enum InvoiceType {
    FREELANCER_INVOICE = 'freelancer',
    SOFTWARE_INVOICE = 'software'
}
