import { RowType } from '@pidz/enums';
import {
  institutionNameField,
  methodField,
  subjectField,
  institutionPlaceField,
  acquiredAtDate,
  otherSubjectField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<NewTrainingForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [subjectField.value],
  },
  {
    type: RowType.ROW,
    items: [otherSubjectField.value],
  },
  {
    type: RowType.ROW,
    items: [methodField.value],
  },
  {
    type: RowType.ROW,
    items: [institutionNameField.value],
  },
  {
    type: RowType.ROW,
    items: [institutionPlaceField.value],
  },
  {
    type: RowType.ROW,
    items: [acquiredAtDate.value],
  },
];
