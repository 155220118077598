<template>
  <span class="timeline-item__training">
    <h3>{{ data.title }}</h3>
    <p class="subtitle">{{ data.subtitle }}</p>
    <p-status-row :status="data.status"
                     date=""
                     :date-text="formatText(data.startDate, data.endDate)"
                     :file-text="data.fileText"
                     alignment="stacked"
                     @open-files="(e: Event) => handleFileToggle(e)"/>
    <p-dropdown-list v-if="data.assets && data.assets.length > 1"
                        :open="showDropDown"
                        :position="position"
                        @close="() => (showDropDown = false)">
      <template #items>
        <div v-for="(file, index) in data.assets" :key="index">
          <p-file-list-item :file-name="file.filename"
                               :show-download-button="true"
                               @download="downloadFile(file)"/>
        </div>
      </template>
    </p-dropdown-list>
    <p-button variant="ghost"
                 class="edit"
                 @click="(e: Event) => emit('edit', e)">
      <p-icon icon-name="pencil" color="blue" />
      Bewerken
    </p-button>
  </span>
</template>

<script lang="ts" setup>
import { getMimeType } from '@pidz/shared/utils'
import { useAssetStore } from '@pidz/stores';
import { openFile } from '@pidz/utils';
import { DateTime } from 'luxon';
import { ref } from 'vue';

const props = defineProps<{
  data: TimelineItem & TimelineItemEducation;
}>();

const showDropDown = ref<boolean>(false);
const assetStore = useAssetStore();
const position = ref<PidzDropdownListPosition>({ height: 0, left: 0 });

const emit = defineEmits(['edit']);

const downloadFile = async (file: Asset) => {
  if ('id' in file && file.id) {
    const response = await assetStore.fetchAsset(file.id);
    const mimeType = getMimeType(file.filename)
    if(mimeType) openFile(response, mimeType);
  }
};

const formatText = (startDate: DateTime, endDate?: DateTime) => {
  return endDate
    ? `Behaald op ${formatDate(startDate)}, Geldig tot ${formatDate(endDate)}`
    : `Behaald op ${formatDate(startDate)}`;
};

const handleFileToggle = (e: Event) => {
  e.stopPropagation();
  if (props.data.assets.length > 1) {
    const target = e.currentTarget as HTMLInputElement;
    const leftOffset = document.body.clientWidth > 425 ? target.offsetLeft : 0;
    const left =
      target.offsetLeft + 400 > (target?.offsetParent?.clientWidth || 0)
        ? undefined
        : leftOffset + 25;
    const topOffset = target.offsetTop;
    const buttonHeight = target.clientHeight;
    const height = topOffset + buttonHeight;

    position.value = { height, left };
    showDropDown.value = !showDropDown.value;
  } else {
    downloadFile(props.data.assets[0]);
  }
};

const formatDate = (date: DateTime): string => date.toFormat('dd-LL-yyyy');
</script>

<style lang="scss">
.timeline-item__training {
  h3,
  p,
  time {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
  }
  h3 {
    color: var(--pidz-blue);
    font-size: 16px;
    font-weight: bold;
  }
  p {
    &.subtitle {
      color: var(--pidz-body-text);
    }
  }

  .actionbar {
    display: flex;
    align-items: center;

    .item {
      padding-right: 2rem;
    }
  }

  time {
    font-size: 0.875rem;
  }

  .edit {
    margin-top: 0.5rem;
    color: var(--pidz-blue);
  }
}
</style>
