import { DateTime, formatYmd, isAfter } from '@pidz/date';
import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';
import { newMethodOptions, trainingOptions } from './form-options';

export const subjectField = ref<FormField>({
  active: true,
  name: 'subject',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een onderwerp in' }),
  data: {
    id: 'subject',
    label: 'Onderwerp',
    options: trainingOptions([]),
    showDropdownPrepend: false,
  },
});

export const otherSubjectField = ref<FormField>({
  active: false,
  name: 'other_subject',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een overig onderwerp in' })
    .min(1, { message: 'Vul een overig onderwerp in' })
    .max(128, { message: 'Overig onderwerp mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'other_subject',
    label: 'Overig onderwerp',
    inputType: 'text',
    placeholder: 'Vul je overig onderwerp in',
  },
});

export const methodField = ref<FormField>({
  active: true,
  name: 'method',
  formType: FormFieldType.DROPDOWN,
  width: 100,
  validation: zod.number({ required_error: 'Vul een type in' }),
  data: {
    id: 'method',
    label: 'Type',
    options: newMethodOptions(),
    showDropdownPrepend: false,
  },
});

export const institutionNameField = ref<FormField>({
  active: true,
  name: 'institution_name',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een naam in' })
    .min(1, { message: 'Vul een naam in' })
    .max(128, { message: 'Naam mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'institution_name',
    label: 'Naam opleidingsinstituut',
    inputType: 'text',
    placeholder: 'Bijv. ROC Tilburg',
  },
});

export const institutionPlaceField = ref<FormField>({
  active: true,
  name: 'institution_place',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een plaats in' })
    .min(1, { message: 'Vul een plaats in' })
    .max(128, { message: 'Plaats mag maximaal 128 karakters zijn' }),
  width: 100,
  data: {
    id: 'institution_place',
    label: 'Plaats opleidingsinstituut',
    inputType: 'text',
    placeholder: 'Bijv. Tilburg',
  },
});

export const acquiredAtDate = ref<FormField>({
  active: true,
  name: 'acquired_at',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .refine(
      (data): boolean => !isAfter(DateTime.fromISO(data), DateTime.now()),
      {
        message:
          'De datum waarop je je aanvullende scholing hebt behaald mag niet in de toekomst liggen',
      },
    ),
  width: 100,
  data: {
    id: 'acquired_at',
    label: 'Datum behaald',
    inputType: 'date',
    preIcon: 'agenda',
    max: formatYmd(DateTime.now()),
  },
});
