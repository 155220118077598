<template>
  <p-content-section :max-height="360" is-expandable>
    <template #header>
      <div class="functions-header">
        <p-badge v-if="isBadgeVisible"
                 class="functions-header__badge"
                 content="!"
                 variant="warn"/>
        <p-heading icon="profession" class="font-bold">Functies</p-heading>
      </div>
    </template>
    <template v-if="sortedFunctionsList?.length" #content>
      <div class="functions">
        <p-list :item-list="sortedFunctionsList" />
      </div>
    </template>

    <template #empty>
      <p>
        <strong>Er zijn geen functies beschikbaar</strong><br />
        PIDZ vestigingen vullen jouw functies in op basis van de gegevens die je
        invult op je CV
      </p>
    </template>
  </p-content-section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  functions: FreelancerFunction[] | undefined;
  isBadgeVisible: boolean;
}>();

const sortedFunctionsList = computed(() => {
  if (!props.functions) return;

  return props.functions.map((obj) => obj.name).sort();
});
</script>
<style scoped lang="scss">
.functions-header {
  position: relative;
  &__badge {
    position: absolute;
    top: -5px;
    left: 15px;
  }
}
</style>
