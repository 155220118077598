import { useDownMoveUp } from './use-down-move-up';

type HorizontalSwipeCallback = (direction:number) => void;

export const useHorizontalSwipe = (
  callback:HorizontalSwipeCallback,
  el?: HTMLElement,
) => {
  let downPos:DOMPoint|null = null;
  let movePos:DOMPoint|null = null;

  useDownMoveUp(
    {
      down: (_, pos) => {
        downPos = pos;
      },
      move: (_, pos) => {
        movePos = pos;
      },
      up: () => {
        if (downPos === null || movePos === null) { return; }

        const dx = downPos.x - movePos.x;
        if (Math.abs(dx) > 10) {
          const dy = downPos.y - movePos.y;
          if (Math.abs(dx) > Math.abs(dy)) {
            callback(dx < 0 ? -1 : 1);
          }
        }
        downPos = null;
        movePos = null;
      },
    },
    el ?? undefined,
  );
};
