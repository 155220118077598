<template>
  <different-rates-item-form-row v-for="(rate, index) in formRates" 
                                 :key="index"
                                 ref="differentRatesForm"
                                 :form-data="{
                                   globalValidations,
                                   values: rate.formValues,
                                   rows,
                                 }"
                                 :standard-rate="standardRates[index]"
                                 :organisation="organisation"
                                 :department="department"/>
</template>
<script lang="ts" setup>
import { globalValidations, rows } from '@pidz/shared/forms/settings/rates/standard';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useToaster } from '@pidz/stores';
import { formatPrice, errorCodes } from '@pidz/utils';
import { computed, ref } from 'vue';
import {
  Router,
  useRouter,
} from 'vue-router';
import DifferentRatesItemFormRow from './DifferentRatesItemFormRow.vue';

const props = defineProps<{
  standardRates: Rate[],
  organisation: ComboBoxOption,
  department: ComboBoxOption,
}>();

const router: Router = useRouter();

const formSubmitted = ref(false);
const isSubmitting = ref(false);
const differentRatesForm = ref<(typeof DifferentRatesItemFormRow)[]>([]);

const formRates = computed(() => props.standardRates?.map(rate => ({
  formValues: {
    ...rate,
    rate: formatPrice(rate.rate),
    periodical_rate: formatPrice(rate.periodical_rate),
  }
})));

const isValid = computed(() => !differentRatesForm.value.some((form) => !form.isValid));
const isSubmitDisabled = computed(() => 
  !isValid.value || !differentRatesForm.value.some((form) => form.isChangedAndValid));

const onSubmit = async () => {
  isSubmitting.value = true;

  const submittingForms = differentRatesForm.value
    .filter((form) => form.isChangedAndValid)
    .map((form) => form.onSubmit());

    try {
      await Promise.all(submittingForms);
    
      formSubmitted.value = true;
    
      rerouteToOverview(router, 'rates');
      differentRatesForm.value[0].succesToaster('Afwijkende tarieven opgeslagen');
    } catch (e: any) {
        const code = e?.response?.data?.error?.code;
        if (code === 1605){
            useToaster().addToaster({
            type: 'error',
            title: errorCodes[1605].title,
            message: errorCodes[1605].message,
          }, 5000);
        }
      }

  isSubmitting.value = false;

};

defineExpose({ isValid, isSubmitDisabled, isSubmitting, onSubmit, formSubmitted });
</script>
