<template>
  <div class="contact">
    <div class="contact__fields" :class="{ 'contact__fields--web': isWeb }">
      <p-form ref="contactDetailsForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-buttons="true"/>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import { emergencyContactField, emergencyPhoneField } from '@pidz/shared/forms/profile/contact';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useUserStore } from '@pidz/stores';
import { onBeforeMount, ref } from 'vue';
import {
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRouter,
} from 'vue-router';

interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<ProfileContact>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  disabled: false,
});

const contactDetailsForm = ref<any>();

const router: Router = useRouter();
const userStore = useUserStore();

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'profile_modal_edit_cancel' : 'profile_modal_add_cancel'

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  warnBeforeCancel,
} = useFormFunctions(
  contactDetailsForm,
  [],
  () => rerouteToOverview(router, 'profile'),
  () => Promise.resolve(),
  props.isEdit,
  'contact gegevens',
);

const onSubmit = async () => {
  const submit = contactDetailsForm?.value?.handleSubmit(
    async (formValues: ProfileContact) => {
      if (
        contactDetailsForm?.value?.isValidating ||
        !contactDetailsForm?.value?.meta.valid ||
        !userStore.freelancer
      )
        return;

      formSubmitted.value = true;

      let freelancer = userStore.freelancer;

      freelancer.telephone_number = formValues.phone;
      freelancer.mobile_phone_number = formValues.mobile;
      
      if (userStore.isUserFreelancer) {
        freelancer.email_freelancer = formValues.email;
      } 

      if(userStore.isUserAgencyWorker) {
        freelancer.email = formValues.email
        freelancer.emergency_contact = formValues.emergencyContact;
        freelancer.emergency_phone = formValues.emergencyPhone;
      }

      await userStore.updateFreelancerData(freelancer);
      rerouteToOverview(router, 'profile');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

onBeforeMount(async () => {
  emergencyContactField.value.active = userStore.isUserAgencyWorker ? true : false;
  emergencyPhoneField.value.active = userStore.isUserAgencyWorker ? true : false;
});

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit });
</script>
<style scoped lang="scss">
.contact {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-bottom: 8px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}
</style>
