
interface ShiftApprovalStatusData {
  textLong: string
  type: 'info' | 'success' | 'error' | 'warning'
  text: string
}
  
const enum ShiftApprovalStatus {
  FREELANCER_INVITED = 3,
  FREELANCER_DECLINED = 4,
  FREELANCER_ACCEPTED = 5,
  CUSTOMER_APPROVED = 6,
  CUSTOMER_DECLINED = 7,
  CUSTOMER_CANCELLED = 8,
}

export const shiftApprovalStatusMap = new Map<ShiftApprovalStatus, ShiftApprovalStatusData>([
  [
    ShiftApprovalStatus.FREELANCER_INVITED, 
    { textLong: 'Wachtend op reactie', type: 'info', text: 'Uitgenodigd' }
  ],
  [
    ShiftApprovalStatus.FREELANCER_DECLINED,
      { textLong: 'Opdracht afgewezen', type: 'error', text: 'Afgewezen' }
  ],
  [
    ShiftApprovalStatus.FREELANCER_ACCEPTED, 
    { textLong: 'Wachten op goedkeuring', type: 'info', text: 'Geaccepteerd' }
  ],
  [
    ShiftApprovalStatus.CUSTOMER_APPROVED, 
    { textLong: 'Opdracht goedgekeurd', type: 'success', text: 'Goedgekeurd' }
  ],
  [
    ShiftApprovalStatus.CUSTOMER_DECLINED, 
    { textLong: 'Opdracht afgekeurd', type: 'error', text: 'Afgewezen' }
  ],
  [
    ShiftApprovalStatus.CUSTOMER_CANCELLED, 
    { textLong: 'Opdracht geannuleerd', type: 'error', text: 'Geannuleerd' }
  ],
]);