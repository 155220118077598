<template>
  <div :class="['status', `status--${variant}`]">
    <p-icon :icon-name="statusMap.get(variant)?.icon"
            :boxed="true"
            :size="(variant === AssetStatus.NEW || variant === SectionStatus.NEW) ? 'medium' : 'small'"
            :background="statusMap.get(variant)?.bg"
            :color="(variant === AssetStatus.NEW || variant === SectionStatus.NEW) ? 'black' : 'white'"/>
    <div class="status__text">{{ textOverride ?? statusMap.get(variant)?.title }}</div>
  </div>

</template>
<script lang="ts" setup>
import { AssetStatus, SectionStatus } from '@pidz/enums'

withDefaults(defineProps<{
  variant: AssetStatus | SectionStatus,
  textOverride?: string
}>(), {
  variant: AssetStatus.APPROVED
});

const statusMap = new Map<AssetStatus | SectionStatus, PidzStatusData>([
  [SectionStatus.APPROVED, { title: 'Goedgekeurd', icon: 'check', bg: 'green' }],
  [SectionStatus.DECLINED, { title: 'Afgekeurd', icon: 'close', bg: 'orange' }],
  [SectionStatus.EXPIRED, { title: 'Verlopen', icon: 'exclamation', bg: 'orange' }],
  [SectionStatus.NEW, { title: 'Wacht op controle', icon: 'time', bg: 'white' }],
  [SectionStatus.NEARING_EXPIRATION_DATE, { title: 'Bijna verlopen', icon: 'exclamation', bg: 'orange' }],
  [AssetStatus.APPROVED, { title: 'Goedgekeurd', icon: 'check', bg: 'green' }],
  [AssetStatus.DISAPPROVED, { title: 'Afgekeurd', icon: 'close', bg: 'orange' }],
  [AssetStatus.EXPIRED, { title: 'Verlopen', icon: 'exclamation', bg: 'orange' }],
  [AssetStatus.NEW, { title: 'Wacht op controle', icon: 'time', bg: 'white' }],
  [AssetStatus.NEARING_EXPIRATION_DATE, { title: 'Bijna verlopen', icon: 'exclamation', bg: 'orange' }]
]);

</script>

<style lang="scss" scoped>
.status{
  display: flex;
  flex-direction: row;
  align-items: center;

  &__text{
    margin-left: 8px;
    color: var(--pidz-body-text);
    font-size: 14px;
  }

  &--waiting{
    :deep(.container.boxed.medium){
      width: 24px;
      height: 24px;
    }
  }
}
</style>
