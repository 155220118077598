<template>
  <layout-desktop-wrapper>
    <work-experience-form ref="form" :form-data="formDataWorkExperience"/>
    <form-buttons-row :on-cancel="form?.onCancel"
                      :on-submit="form?.onSubmit"
                      :is-loading="form?.isSubmitting"
                      :is-submit-disabled="form?.isSubmitDisabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { formatYmd } from '@pidz/date'
import { WorkExperienceForm } from '@pidz/shared/components';
import { 
  globalValidations, 
  rows,
} from '@pidz/shared/forms/cv/work';
import { DateTime } from 'luxon'
import { ref } from 'vue';

const form = ref<typeof WorkExperienceForm>();

const values: NewWorkExperienceForm = {
  sector_id: undefined, 
  function_name: undefined, 
  institution_name: undefined, 
  institution_place: undefined,
  date: {
      start_date: formatYmd(DateTime.now()),
      end_date: formatYmd(DateTime.now()), 
  },
  is_current: false, 
  appointment_type: undefined, 
  description: undefined, 
}

const formDataWorkExperience: PidzFormData<NewWorkExperienceForm> = {
  globalValidations,
  values,
  rows
}
</script>
