export enum SheetStatus {
    NEW_SHEET = 1,
    REQUEST_BY_FREELANCER = 2,
    APPROVED_BY_CUSTOMER = 3,
    DECLINED_BY_CUSTOMER = 4,
    INVOICED = 5,
    CLOSED = 6,
    CLOSED_WITHOUT_SHEETROW = 7,
}
  
export enum SheetBlockType {
    TYPE_OVERTIME = 1,
    TYPE_UNDERTIME = 2,
    TYPE_WORKING_HOURS = 3,
    TYPE_BREAK = 4,
    TYPE_WORK_DURING_SLEEP = 5,
}