<template>
  <sheet-block-list-section v-model="value"
                            title="Overuren"
                            add-title="Overuren toevoegen"
                            edit-title="Overuren bewerken"
                            :block-factory="blockFactory"
                            :validate="validate"/>
</template>
<script lang="ts" setup>
import { addHourToIsoDate } from '@pidz/date';
import { SheetBlockType } from '@pidz/enums';
import { computed } from 'vue';
import {
  validateNoOverlap,
  validateOverOutsideWorkHours,
  validateStartBeforeEnd,
  validateOverNoGap,
} from '../utils/block-validations';
import SheetBlockListSection from './SheetBlockListSection.vue';

const props = defineProps<{
  modelValue: any[];
  workingHoursBlock: any;
  shiftId: number;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

const validate = (block: any, otherBlocks: any[]) => {
  validateStartBeforeEnd(block);
  validateOverOutsideWorkHours(block, props.workingHoursBlock);
  validateOverNoGap(block, [props.workingHoursBlock, ...otherBlocks]);
  validateNoOverlap(block, otherBlocks, 'Overuren mogen niet overlappen.');
};

const blockFactory = () => ({
  start: props.workingHoursBlock.end,
  end: addHourToIsoDate(props.workingHoursBlock.end),
  type: SheetBlockType.TYPE_OVERTIME,
  shift_id: props.shiftId,
});
</script>
