<template>
  <p-modal class="nudging-modal md:!max-w-2xl !p-[16px]" @modal-closed="modalController.close()">
    <h1>Optimaliseer je incidentele tarief</h1>
    <p>Als je je tarief optimaliseert, is er kans dat je meer of minder uitnodigingen krijgt voor opdrachten.
      Test met de slider het effect van een hoger of lager tarief op het aantal uitnodigingen. </p>
    <p class="font-bold mt-2 mb-2">{{ rateItem.function_name }}</p>

    <div v-if="isLoading" class="w-full flex justify-center min-h-[205px] items-center">
      <p-spinner/>
    </div>

    <div v-if="!noData && !isLoading" ref="content">
      <div class="slider flex gap-2 mb-5 mt-14 items-center">
        <div class="whitespace-nowrap">
          € {{ formatPrice(sliderRange.minVal) }}
        </div>
        <p-slider v-model="testRate"
                  :min="sliderRange.minVal"
                  :max="sliderRange.maxVal">
          <template #thumbcontent>€ {{ formatPrice(testRate) }}</template>
        </p-slider>
        <div class="whitespace-nowrap">
          € {{ formatPrice(sliderRange.maxVal) }}
        </div> 
      </div>

      <p class="mb-2 font-bold">Uitnodigingen</p>
      
      <div class="flex flex-col">
        <div class="w-full flex flex-row mb-2 bg-blue-200 p-2">
          <div class="flex-1 min-w-28"></div>
          <div class="flex-1 text-right pr-5">Per maand</div>
          <div class="flex-1 text-right">Percentage</div>
        </div>
        
        <div class="w-full flex flex-row my-2">
          <div class="flex-1 font-bold min-w-28">Huidig tarief</div>
          <div class="flex-1 text-right pr-6">{{ currentNudge?.invites_count_cumulative}}</div>
          <div class="flex-1 text-right">{{ formatPercentage(currentNudge?.invites_percentage_cumulative)}}</div>
        </div>

        <p-ruler/>
        
        <div class="w-full flex flex-row my-2">
          <div class="flex-1 min-w-28">
            <span class="font-bold">Test tarief</span>
            <sup :class="['ml-1 whitespace-nowrap', rateDiff >= 0 ? 'text-green' : 'text-red']" >
              {{ rateDiff >= 0 ? `+ € ${rateDiff}` : `- € ${rateDiff * -1 }` }}
            </sup>
          </div>
          <div class="flex-1 text-right relative pr-5">
            <span>{{ selectedNudge?.invites_count_cumulative }}</span>
            <sup  :class="['ml-1 absolute top-1', inviteDiff >= 0 ? 'text-green' : 'text-red']">
              {{ inviteDiff >= 0 ? `+${inviteDiff}` : inviteDiff }}
            </sup>
          </div>
          <div  class="flex-1 text-right">{{ formatPercentage(selectedNudge?.invites_percentage_cumulative) }}</div>
        </div>
      </div>
    </div> 

    
    <div v-else class="flex gap-2">
      <p-icon icon-name="info"
              boxed
              size="small"
              background="blue"/>
      <p>Er is niet genoeg informatie om een goed inzicht te geven in deze functie. Probeer een andere functie.</p>
    </div>
    
    <template #footer>
      <p-button outlined @on-click="modalController.close()">
        Annuleren
      </p-button>
      <p-button :disabled="isLoading"
                class="whitespace-nowrap text-xs"
                @on-click="onSave(testRate)">
        Tarief overnemen
      </p-button>
    </template>
  </p-modal>
</template>
<script setup lang="ts">

import { useRatesStore } from '@pidz/stores';
import { formatPrice, useModalController } from '@pidz/utils';
import { computed, onMounted, ref } from 'vue';

const props = defineProps<{
  rateItem: Rate;
  onSave: Function;
}>();

const content = ref<HTMLElement | null>(null);
const modalController = useModalController();
const ratesStore = useRatesStore();
const isLoading = ref<boolean>(true);
const nudges = ref<Nudge[]>([]);
const currentRate = ref(Math.round(props.rateItem.rate))
const testRate = ref<number>(0);
const noData = ref<boolean>(false);

onMounted(async () => {
  try { 
    const result = await ratesStore.getNudgesByFunctionId(props.rateItem.function_id)

    // this is here because the BE returns a percentage of 0 for rates with a low number of invites
    nudges.value = result.map((nudge) => {
      if (nudge.invites_count_cumulative !== 0 && nudge.invites_percentage_cumulative === 0){
        nudge.invites_percentage_cumulative = 0.1
      }
      return nudge
    })
   }
  catch(e: any) { if (e.response.data.error.code === 2001) noData.value = true }
  finally {isLoading.value = false }

  const firstNudge = nudges.value[0];
  const lastNudge = nudges.value[nudges.value.length - 1];

  if (props.rateItem.rate < firstNudge.rate) testRate.value = firstNudge.rate;
  else if (props.rateItem.rate > lastNudge.rate) testRate.value = lastNudge.rate;
  else testRate.value = Math.round(props.rateItem.rate);
})

const sliderRange = computed(() => {
  if (!nudges.value.length) return { minVal: 0, maxVal: 0 };
  return {
    minVal: nudges.value[0].rate,
    maxVal: nudges.value[nudges.value.length - 1].rate
  } 
})

const createNudge = (rate: number) => {
  const invitesForRate = estimateInvites(rate)

  return {
    rate,
    invites_count_cumulative: invitesForRate,
    invites_percentage_cumulative: (invitesForRate / nudges.value[0].invites_count_cumulative ) * 100,
  } as NudgeShallow
}

const interpolatedNudges = computed(() => {
  const newNudges: NudgeShallow[] = []
  if (!nudges.value.length) return newNudges;

  for (let rate = nudges.value[0].rate; rate <= nudges.value[nudges.value.length - 1].rate; rate++){
    const existingNudge = nudges.value.find(nudge => nudge.rate === rate);
    if (existingNudge) newNudges.push(existingNudge);
    else newNudges.push(createNudge(rate))
  }
    return newNudges;
  }
)

// calculate for rate between nudges
const estimateInvites = (rate: number) => {
  if (!nudges.value.length) return 0;

  if (rate < nudges.value[0].rate) nudges.value[0].invites_count_cumulative;
  if (rate > nudges.value[nudges.value.length - 1].rate) return 0;

  const lastKnown = nudges.value.filter(nudge => nudge.rate < rate).reverse()[0] ?? nudges.value[0];
  const nextKnown = nudges.value.filter(nudge => nudge.rate > rate)[0] ?? nudges.value[nudges.value.length - 1];
  const stepsBetween = nextKnown.rate - lastKnown.rate;
  const invitesBetween = lastKnown.invites_count_cumulative - nextKnown.invites_count_cumulative;
  const invitesPerStep = invitesBetween / stepsBetween;
  const stepsToRate = rate - lastKnown.rate;
  return Math.round(lastKnown.invites_count_cumulative - (stepsToRate * invitesPerStep));
}

// find or create nudge for current rate, 
const currentNudge = computed<NudgeShallow>(() => {
  return interpolatedNudges.value.find(nudge => nudge.rate === currentRate.value)
    ?? createNudge(currentRate.value)
})
const selectedNudge = computed(() => interpolatedNudges.value.find(nudge => nudge.rate === testRate.value))

const inviteDiff = computed<number>(() => {
  const current = currentNudge.value?.invites_count_cumulative;
  const test = selectedNudge.value?.invites_count_cumulative;
  return (test || 0) - current;
})

const formatPercentage = (num?: number) => {
  if (!num) return '0%';
  if (num > 0 && num < 1) return '< 1%';
  return num.toFixed(0) + '%';
}

const rateDiff = computed(() => testRate.value - currentRate.value)
</script>
