<template>
  <layout-desktop-wrapper>
    <div class="competencies__content">
      <p class="competencies__description">
        Geef hier aan welke handelingen je bekwaam in bent. Je bent hiervoor ook
        verantwoordelijk in je werk en wordt hier mogelijk ook op geselecteerd
        door de werkgevers. Zorg dus dat je de handelingen waar je bekwaam of
        niet bekwaam in bent goed bijhoudt.
      </p>
      <p-heading class="competencies__heading font-bold" font-size="lg">{{
        group?.name
      }}</p-heading>
      <div class="competencies__list">
        <p-card v-for="comp in competencies"
                :key="comp.id"
                :title="comp.type.name">
          <template #content>
            <div class="competency">
              <span :class="{ 'competency--unexperienced': !comp.is_experienced }">Niet bekwaam</span>
              <p-toggle v-model="comp.is_experienced"
                        :input-id="comp.type.name ?? 'competency-toggle'"
                        class="competency__toggle"
                        colored/>
              <span :class="{ 'competency--experienced': comp.is_experienced }">Bekwaam</span>
            </div>
          </template>
        </p-card>
      </div>
      <p>
        Als je zeker weet dat jouw bekwaamheden goed zijn ingevuld en je wilt
        een indicatie maken van jouw bekwaamheid, klik dan hierboven op het
        vinkje. Een indicatie onthouden wij maximaal 2 jaar, daarna zullen we je
        vragen om opnieuw na te gaan of je nog wel bekwaam bent.
      </p>
    </div>
    <form-buttons-row :on-cancel="onCancel"
                      :on-submit="onSubmit"
                      :is-loading="isLoading"
                      :is-submit-disabled="!isDirty"/>
  </layout-desktop-wrapper>
</template>

<script setup lang="ts">
import FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { CancelModal } from '@pidz/shared/components';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useCVStore, useToaster } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter, Router } from 'vue-router';

const router: Router = useRouter();
const cvStore = useCVStore();
const id = computed(() => Number(router.currentRoute.value.params.id));
const group = computed(() =>
  cvStore.resume?.groupedCompetencies.find(
    (compGroup: CompetencyGroup) => compGroup.id === id.value,
  ),
);

// when the group changes, update the competencies
const competencies = ref<Array<Competency>>([]);
watch(
  () => group.value,
  async (newVal) => {
    if (!newVal) return;
    competencies.value = newVal.competencies;
  },
  { immediate: true },
);

const isDirty = computed(() =>
  competencies.value.some(
    (comp) => comp.is_experienced !== comp.is_experienced_initial,
  ),
);

const modalController = useModalController();
const onCancel = () => {
  if (isDirty.value) {
    modalController.open(CancelModal, {
      cancelEdit: () => {
        rerouteToOverview(router, 'cv');
        logCustomEvent('cv_modal_edit_cancel')
      },
      continueEdit: () => {
        logCustomEvent('cv_modal_edit_continue')
      }
    });
  } else {
      rerouteToOverview(router, 'cv');
  }
};

const toaster = useToaster();
const isLoading = ref(false);
const onSubmit = async () => {
  isLoading.value = true;
  await Promise.all(
    competencies.value.map(async (comp) => {
      if (comp.is_experienced !== comp.is_experienced_initial) {
        await cvStore.updateCompetency(comp);
      }
    }),
  );
  rerouteToOverview(router, 'cv');
  toaster.addToaster(
    {
      type: 'success',
      title: 'Gelukt!',
      message: 'Succesvol opgeslagen',
    },
    5000,
  );
  isLoading.value = false;
};

const loadStores = async () => {
  await cvStore.loadResume();
};

onMounted(async () => {
  await loadStores();
});
</script>

<style scoped lang="scss">
.competencies {
  &__heading {
    margin-bottom: 24px;
  }

  &__content {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 48px;
  }

  &__competency {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    border-bottom: 1px solid var(--pidz-grey-2);
  }

  &__unexperienced {
    color: var(--pidz-red);
    font-weight: bold;
  }
}

.competency {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--experienced {
    color: var(--pidz-green);
    text-shadow: 1px 0px 0px var(--pidz-green);
  }
  &--unexperienced {
    color: var(--pidz-red);
    text-shadow: 1px 0px 0px var(--pidz-red);
  }
}
</style>
