export const functionOptions = (freelancerResumeFunctions: FreelancerFunction[]): FormDropDownOption[] =>
  freelancerResumeFunctions.map(
    (resumeFunction, index): FormDropDownOption => ({
      id: index,
      value: resumeFunction.id,
      label: resumeFunction.name,
    }),
  )

export const sectorOptions = (allSectors: FreelancerSector[]): FormDropDownOption[] => 
  allSectors.map(
    (sector, index): FormDropDownOption => ({
      id: index,
      value: sector.id,
      label: sector.name,
    }),
  )

const appointmentType = new Map([
  [0, 'Tijdelijke aanstelling'],
  [1, 'Vaste aanstelling'],
  [2, 'Stagiair'],
]);

export const appointmentOptions = (): FormDropDownOption[] => {
  const opties: FormDropDownOption[] = [];
  appointmentType.forEach((appointment, key) => {
    opties.push({
      id: key,
      value: key,
      label: appointment,
    });
  });
  return opties;
};
