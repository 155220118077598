<template>
  <calendar-header calendar-type="Dag" 
                   :title="title"
                   weekday-indicator
                   @move-date="onMoveDate"
                   @weekday="onWeekday"/>
</template>

<script setup lang="ts">
import { isoWeekdayFromDate } from '@pidz/date';
import { useCalendar } from '@pidz/stores';
import { formatTitle } from '@pidz/utils';
import { computed } from 'vue';
import CalendarHeader from './CalendarHeader.vue';

const calendarStore = useCalendar();

const title = computed(() => {
  const { date, isDateThisYear } = calendarStore;
  const format = isDateThisYear ? 'long' : 'short'
  return `${date.day} ${formatTitle(date, isDateThisYear, format).toLowerCase()}`;
});

const onMoveDate = (dir: number) => {
  const newDate = calendarStore.date.plus({ day: dir });
  calendarStore.setDate(newDate);
};

const onWeekday = (day: IsoWeekday) => {
  const {date} = calendarStore;
  const current = isoWeekdayFromDate(date);
  const delta = day - current;
  calendarStore.setDate(date.plus({ days: delta }));
};
</script>
