<template>
  <div :class="['banner', `banner--${type}`, {'banner--centered': !props.title}]">
    <div  class="banner__icon">
      <p-icon boxed
              :icon-name="iconName"
              size="small"
              :background="iconBackground"/>
    </div>
    <div class="banner__content">
      <h1 v-if="title" class="banner__title">{{title}}</h1>
      <p class="banner__text">
        {{text}}
        <slot></slot>
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

interface BannerProps {
  type?: 'info' | 'warning' | 'error' | 'success';
  title?: string;
  text: string;
}

const props = withDefaults(defineProps<BannerProps>(), {
  type: 'info'
});

const banners = new Map([
  ['info', { icon: 'info', backgroundColor: 'blue' }],
  ['warning', { icon: 'exclamation', backgroundColor: 'orange' }],
  ['error', { icon: 'exclamation', backgroundColor: 'red' }],
  ['success', { icon: 'check', backgroundColor: 'green' }]
]);

const iconBackground = computed(() => banners.get(props.type)!.backgroundColor);
const iconName = computed(() => banners.get(props.type)!.icon);

</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  font-size: 12px;
  display: flex;
  padding: 12px 16px;
  box-shadow: var(--shadow-light);
  color: var(--pidz-body-text);

  @media screen and (min-width: 768px) {
    font-size: 14px
  }

  &--centered {
    align-items: center;
  }

  &--info {
    background-color: var(--pidz-blue-light);
  }

  &--warning {
    background-color: var(--pidz-orange-light);
  }

  &--error {
    background-color: var(--pidz-red-light);
  }

  &--success {
    background-color: var(--pidz-green-light);
  }

  &__content {
    margin-left: 16px;
  }
  &__title {
    display: block;
    font-size: 14px;
    margin: 0px;
    margin-bottom: 4px;
    font-weight: bold;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }

  &__text {
    margin: 0px;
    display: block;
  }
}
</style>
