<template>
  <section class="event-recurrence-type-section">
    <labeled-slot label="Herhalen">
      <input-border>
        <refresh-icon />
        <dropdown v-model="repeatType"
                  :options="recurrenceOptions"
                  name="repeatType" />
      </input-border>
    </labeled-slot>
    <calendar-weekly-recurrence v-if="repeatType === RecurrenceRepeatType.Weekly"
                                v-model="days"
                                :date="date" />
  </section>
  <section v-if="repeatType != RecurrenceRepeatType.Never"
           class="event-recurrence-period-section">
    <labeled-slot label="Herhaling eindigt">
      <input-border>
        <refresh-icon />
        <dropdown v-model="periodType"
                  :options="periodTypeOptions"
                  name="periodType" />
      </input-border>
    </labeled-slot>
    <form-field v-if="periodType === RecurrencePeriodType.SetAmount"
                :id="'occurrences-field'"
                v-model="occurrences"
                :name="'repeat.occurences'"
                :type="'number'"
                :inputmode="'numeric'"
                :text-before="formText.occurrences.before"
                :text-after="formText.occurrences.after"
                :class-name="'recurrence-end-occurrences'"
                :min=0
                :max=99></form-field>
    <bordered-form-field v-if="periodType === RecurrencePeriodType.OnDate"
                         :id="'end-date-field'"
                         v-model="end"
                         :name="'repeat.end'"
                         :type="'date'"
                         :class-name="'recurrence-end-date'">
      <agenda-icon />
    </bordered-form-field>
  </section>
</template>
<script lang="ts" setup>
import Dropdown from '@app/components/Dropdown.vue';
import InputBorder from '@app/components/InputBorder.vue';
import LabeledSlot from '@app/components/LabeledSlot.vue';
import { DateTime } from '@pidz/date';
import { RecurrencePeriodType, RecurrenceRepeatType } from '@pidz/enums';
import AgendaIcon from '@pidz/icons/src/AgendaIcon.vue';
import RefreshIcon from '@pidz/icons/src/RefreshIcon.vue';
import { computed } from 'vue';
import BorderedFormField from '../forms/BorderedFormField.vue';
import FormField from '../forms/FormField.vue';
import CalendarWeeklyRecurrence from './CalendarAddWeeklyRecurrence.vue';

const props = defineProps<{
  date: string,
  days: number[]
  periodType: number,
  repeatType: number,
  occurrences: number | null,
  end: DateTime | null,
}>();

const emit = defineEmits([
  'update:days', 'update:periodType', 'update:repeatType', 'update:occurrences', 'update:end']);

const recurrenceOptions = [
  { name: 'Nooit', value: RecurrenceRepeatType.Never },
  { name: 'Dagelijks', value: RecurrenceRepeatType.Daily },
  { name: 'Wekelijks', value: RecurrenceRepeatType.Weekly },
];

const periodTypeOptions = [
  { name: 'Na 1 jaar', value: RecurrencePeriodType.OneYear },
  { name: 'Na aantal keer', value: RecurrencePeriodType.SetAmount },
  { name: 'Op datum', value: RecurrencePeriodType.OnDate },
];

const formText = {
  occurrences: {
    before: 'Eindigt na',
    after: 'keer',
  },
};

const days = computed({
  get() { return props.days; },
  set(selectedDays) { emit('update:days', selectedDays); },
});

const periodType = computed({
  get() { return props.periodType; },
  set(updatedPeriodType) {
    if (updatedPeriodType === RecurrencePeriodType.OnDate) {
      emit('update:end', DateTime.fromISO(props.date).plus({months: 1}))
    }
    emit('update:periodType', updatedPeriodType);
  },
});

const repeatType = computed({
  get() { return props.repeatType; },
  set(updatedRepeatType) {
    emit('update:repeatType', updatedRepeatType);
  },
});

const occurrences = computed({
  get() { return props.occurrences; },
  set(updatedOccurrences) { emit('update:occurrences', updatedOccurrences); },
});

const end = computed({
  get() { return props.end ? props.end.toISODate() : null },
  set(updatedEnd) { emit('update:end', DateTime.fromISO(updatedEnd as string)); },
});

</script>
<style lang="scss">
.recurrence-end-occurrences {
  input {
    height: 40px;
    width: 40px;
    margin: 0 12px;
  }
}
</style>
