import { errorCodes } from './error-codes';

const getErrorMessage = (code: number|string) => errorCodes[code];

export const errorHandler = (errorCode: number|string) => {
  const errorMessage = getErrorMessage(errorCode);

  if (!errorMessage) { return getErrorMessage(600); }
  return errorMessage;
};
