export default [
  {
    name: 'update required',
    path: '/update-required',
    component: () => import('@app/pages/update/UpdateRequiredPage.vue'),
    meta: { title: 'Update', fullPage: true },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@app/pages/login/LoginPage.vue'),
    meta: { title: 'Login', fullPage: true },
  },
  {
    name: 'impersonation',
    path: '/impersonation',
    component: () => import('@app/pages/impersonation/ImpersonationPage.vue'),
    meta: { title: 'Impersonation' }
  },
  {
    name: 'forgot password',
    path: '/forgot',
    component: () => import('@app/pages/forgot-password/ForgotPasswordPage.vue'),
    meta: { title: 'Wachtwoord vergeten', fullPage: true },
  },
  {
    name: 'unpaid',
    path: '/unpaid',
    component: () => import('@app/pages/unpaid-invoice/UnpaidInvoicePage.vue'),
    meta: { title: 'Openstaande facturen', fullPage: true },
  },
]