import { DateTime } from 'luxon';

const createNewDate = (date:string | Date) => {
  return typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
}

const mapRepeatModelToLuxon = (
  repeatModel?: CalendarRepeatModel,
): CalendarRepeatModel | undefined => {
  if (!repeatModel) return undefined;
  const luxonRepeatModel: CalendarRepeatModel = {
    ...repeatModel,
    start: createNewDate(repeatModel.start),
    end: repeatModel.end ? createNewDate(repeatModel.end) : undefined,
    first_item: repeatModel.first_item
      ? {
          ...repeatModel.first_item,
          start: createNewDate(repeatModel.first_item.start),
          end: createNewDate(repeatModel.first_item.end),
        }
      : undefined,
  };

  return luxonRepeatModel;
};

export const mapCalendarEventsToLuxon = (response: CalendarEventResponse): CalendarEventReponse =>
  response.map((event: CalendarEvent) => {
    const luxonEvent: CalendarEvent = {
      ...event,
      start: createNewDate(event.start),
      end: event.end ? createNewDate(event.end) : undefined,
      repeat: mapRepeatModelToLuxon(event.repeat),
    };
    return luxonEvent;
  });
