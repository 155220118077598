import { App } from '@capacitor/app';
import { versionService } from '@pidz/api';
import { getVersion } from './get-version';

const checkForUpdate = async (router) => {
  try {
    const result = await versionService.getUpdateRequired(getVersion());
    if (result.updateRequired) {
      router.replace('/update-required');
    }
  } catch (e) {
    // in case we do not know, assume the version is good enough
  }
};

export const useUpdateChecker = async (router) => {
  checkForUpdate(router);
  await App.addListener('appStateChange', async ({ isActive }) => {
    if (isActive) {
      checkForUpdate(router);
    }
  });
};
