<template>
  <div
    class="weekly-recurrence">
    <option-grid
      v-model="selectedDays"
      multi
      class="day-grid"
      :disabled="isDisabled"
      :options="isoWeekdays"/>
  </div>
</template>
<script lang="ts" setup>
import OptionGrid from '@app/components/OptionGrid.vue';
import { getISODay, DateTime} from '@pidz/date';
import { computed } from 'vue';

const isoWeekdays = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map(
  (day, i) => ({ name: day, value: i }),
);

const props = defineProps<{
  date: string;
  modelValue: number[];
}>();
const emit = defineEmits(['update:modelValue']);

const isDisabled = computed((): number => getISODay(DateTime.fromISO(props.date)) - 1);

const selectedDays = computed({
  get() {
    return props.modelValue;
  },
  set(updatedDays) {
    emit(
      'update:modelValue',
      [...updatedDays].sort((a, b) => a - b),
    );
  },
});
</script>
<style lang="scss">
.weekly-recurrence {
  .day-grid {
    margin-top: 24px;
    width: 100%;
    color: var(--pidz-body-text);
    display: flex;
    justify-content: space-between;

    .option {
      width: 100%;
      max-width: 40px;
      max-height: 40px;
      border: 1px solid var(--pidz-body-text);

      &.active {
        color: var(--pidz-white);
        background-color: var(--pidz-blue);
      }
    }
  }
}
</style>
