import { RowType } from '@pidz/enums';
import { emailField, emergencyContactField, emergencyPhoneField, mobileField, phoneField } from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<ProfileContact>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [emailField.value],
  },
  {
    type: RowType.ROW,
    items: [mobileField.value],
  },
  {
    type: RowType.ROW,
    items: [phoneField.value],
  },
  {
    type: RowType.ROW,
    items: [emergencyContactField.value]
  },
  {
    type: RowType.ROW,
    items: [emergencyPhoneField.value]
  },
];
