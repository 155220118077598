import { useApi } from '@pidz/api';

const api = useApi();

export const cvService = {
  getResumeContent: (): Promise<Resume> => api.jsonrpc({
    url: 'resume:getResume',
  }),

  getTrainingSubjects: (): Promise<TrainingSubject[]> => api.jsonrpc({
    url: 'training_subject:getTrainingSubjects',
  }),

  getEducationSubjects: (): Promise<EducationSubject[]> => api.jsonrpc({
    url: 'education_subject:getEducationSubjects',
  }),

  getFreelancerSectors: (): Promise<FreelancerSector[]> => api.jsonrpc({
    url: 'sector:getAll',
  }),

  getFreelancerResumeFunctions: (): Promise<FreelancerResumeFunction[]> => api.jsonrpc({
    url: 'function:getAll',
  }),

  getRegistrationTypes: (): Promise<RegistrationType[]> => api.jsonrpc({
    url: 'registration_type:getRegistrationTypes',
  }),

  getLanguages: (): Promise<Language[]> => api.jsonrpc({
    url: 'language:getAll',
  }),

  addLanguageProficiency: (model: LanguageProficiencyParams): Promise<LanguageProficiency> => api.jsonrpc({
    url: 'language_skill:createLanguageSkill',
    params: { model }
  }),

  removeLanguageProficiency: (languageSkillId: number): Promise<{ result: boolean }> => api.jsonrpc({
    url: 'language_skill:removeLanguageSkill',
    params: { languageSkillId }
  }),

  updateLanguageProficiency: (model: LanguageProficiency): Promise<LanguageProficiency> => api.jsonrpc({
    url: 'language_skill:updateLanguageSkill',
    params: { model }
  }),

  addWorkExperience: (model: Omit<WorkExperience, 'id'>): Promise<WorkExperience> => api.jsonrpc({
    url: 'work_experience:createWorkExperience',
    params: { model }
  }),

  updateWorkExperience: (model: WorkExperience): Promise<WorkExperience> => api.jsonrpc({
    url: 'work_experience:updateWorkExperience',
    params: { model }
  }),

  removeWorkExperience: (workExperienceId: number): Promise<{ result: boolean }> => api.jsonrpc({
    url: 'work_experience:removeWorkExperience',
    params: { workExperienceId }
  }),

  addEducation: (model: EducationParams): Promise<Education> => api.jsonrpc({
    url: 'education:createEducation',
    params: { model }
  }),

  updateEducation: (model: Education): Promise<Education> => api.jsonrpc({
    url: 'education:updateEducation',
    params: { model }
  }),

  removeEducation: (educationId: number): Promise<{ result: boolean }> => api.jsonrpc({
    url: 'education:removeEducation',
    params: { educationId }
  }),

  addRegistration: (model: RegistrationParams): Promise<Registration> => api.jsonrpc({
    url: 'registration:createRegistration',
    params: { model }
  }),

  updateRegistration: (model: Registration): Promise<Registration> => api.jsonrpc({
    url: 'registration:updateRegistration',
    params: { model }
  }),

  removeRegistration: (registrationId: number): Promise<{ result: boolean }> => api.jsonrpc({
    url: 'registration:removeRegistration',
    params: { registrationId }
  }),

  addTraining: (model: AddTrainingParams): Promise<Training> => api.jsonrpc({
    url: 'training:createTraining',
    params: { model }
  }),

  updateTraining: (model: UpdateTrainingParams): Promise<Training> => api.jsonrpc({
    url: 'training:updateTraining',
    params: { model }
  }),

  removeTraining: (trainingId: number): Promise<{ result: boolean }> => api.jsonrpc({
    url: 'training:removeTraining',
    params: { trainingId }
  }),

  getResumeAsPDF: (): Promise<Base64File> => api.jsonrpc({
    url: 'resume:download',
  }),

  geBIGRegistrationAsPDF: (): Promise<Base64File> => api.jsonrpc({
    url: 'big_registration:download',
  }),

  updateCompetency: (model: Competency): Promise<Competency> => api.jsonrpc({
    url: 'competency:updateCompetency',
    params: { model }
  }),
}
