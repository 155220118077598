<template>
  <div :class="['date-picker', errorMessage && errorMessage.trim().length ? '' : 'has-padding']">
    <div class="input-container">
      <p-label v-if="label" :input-id="inputId"
               :label="label" />
      <div class="input">
        <p-input-prepend v-if="$slots.default">
          <slot />
        </p-input-prepend>
        <p-input v-model="selectedDate"
                 input-type="date"
                 :input-id="inputId"
                 :has-border-radius-left="hasBorderRadiusLeft"
                 :has-no-left-border="hasNoLeftBorder"
                 :min="min"
                 :max="max"/>
      </div>
    </div>
    <p-label v-if="errorMessage && errorMessage.trim().length"
             variant="error"
             :input-id="inputId"
             :label="errorMessage" />
  </div>
</template>
<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  selectedDate: DateTime
  label: string,
  inputId: string,
  errorMessage: string
  hasBorderRadiusLeft?: boolean
  hasNoLeftBorder?: boolean
  min?: DateTime
  max?: DateTime
}>(), {
  hasBorderRadiusLeft: true,
  hasNoLeftBorder: false
});

const emit = defineEmits(['update:selectedDate']);

const selectedDate = computed({
  get() {
    return props.selectedDate;
  },
  set(newDate) {
    emit('update:selectedDate', newDate);
  }
});

</script>

<style scoped lang="scss">
.date-picker {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .input {
      display: flex;
    }
  }

  &.has-padding {
    .input-container {
      padding-bottom: 22px;
    }
  }
}
</style>
