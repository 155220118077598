<template>
  <div class="tab-bar">
    <nav ref="nav">
      <a v-for="(title, index) in props.tabTitles" class="tab-link"
         :class="[index === activeTabIndex && 'active-tab']" :key="index"
         @click="scrollIntoView(index)">
        {{title}}
      </a>
    </nav>
    <div class="tab-indicator" :style="tabIndicatorStyles"></div>
  </div>
</template>

<script setup lang="ts">

import {
  reactive, ref,
  onMounted,
  onUnmounted,
} from 'vue';

const nav = ref();
const tabIndicatorStyles = reactive({
  '--tab-count': 1,
  '--indicator-offset': 0,
});

const props = defineProps < { pages: any, tabTitles: string[] }>();
const activeTabIndex = ref(Math.round(props.pages.scrollLeft / props.pages.clientWidth));

const animationFrame = ref<number>(0);
const scrollIntoView = (index: number) => {
  const { clientWidth } = props.pages;
  props.pages.scrollTo({ left: clientWidth * index });
};

const determineScrollPosition = () => {
  animationFrame.value = requestAnimationFrame(determineScrollPosition);
  const { scrollLeft, scrollWidth, clientWidth } = props.pages;
  tabIndicatorStyles['--indicator-offset'] = Number(((scrollLeft / scrollWidth) * 100).toFixed(2));
  activeTabIndex.value = Math.round(scrollLeft / clientWidth);
};

onMounted(() => {
  tabIndicatorStyles['--tab-count'] = props.pages.children.length;
  determineScrollPosition();
});

onUnmounted(() => {
  cancelAnimationFrame(animationFrame.value);
});

</script>
<style lang="scss">
.tab-bar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--pidz-blue);
  height: 52px;
  color: var(--pidz-white);

  nav {
    user-select: none;
    display: grid;
    grid-template-columns: autofill;
    grid-auto-flow: column;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    a {
      padding: 14px 0;
      color: rgba(255, 255, 255, 0.7);
    }

    a.active-tab {
      color: var(--pidz-white);
    }
  }

  .tab-indicator {
    width: calc(100% / var(--tab-count));
    height: 4px;
    background-color: var(--pidz-white);
    bottom: 0;
    position: absolute;
    margin-left: calc(var(--indicator-offset) * 1%);
  }
}

</style>
