<template>
  <p-modal class="edit-event-modal"
           @modal-closed="modalController.close()">
    <h2>Stel je PIDZ agenda koppeling in</h2>
    <p class="mb-4">
      Door jouw persoonlijke PIDZ agenda te koppelen zie je direct al je opdrachten in je persoonlijke agenda.
      Je voegt namelijk de PIDZ agenda toe aan je iCal of Google Calendar agenda.
      Wij zorgen er voor dat jouw PIDZ agenda automatisch synchroniseert.
    </p>
    <p v-if="platform !== 'android'" class="mb-4">
      Kopieer onderstaande persoonlijke link naar iCal of Google Calendar of klik op 'Koppelen'
      om jouw PIDZ agenda automatisch toe te voegen.
    </p>
    <p v-else class="mb-4">
      Kopieer onderstaande persoonlijke link naar iCal of Google Calendar om jouw PIDZ agenda
      automatisch toe te voegen.
    </p>
    <div class="calendar-link">
      <div class="calendar-link-header">
        <h4 class="font-bold">Persoonlijke link</h4>
        <p-icon class="copy-icon"
                icon-name="copy"
                color="blue"
                @click="copyToClipboard()" />
      </div>
      <span>{{ link }}</span>
    </div>
    <template #footer>
      <p-button class="outlined"
                   @click="cancelEvent">Annuleren</p-button>
      <p-button v-if="platform !== 'android'"
                   class="primary"
                   @click="linkCalendar">
        Koppelen
      </p-button>
      <p-button v-else
                   class="primary"
                   @click="copyToClipboard(true)">
        Kopieer link
      </p-button>
    </template>
  </p-modal>
</template>
<script lang="ts" setup>
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { AppLauncher } from '@capacitor/app-launcher';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();
const platform = Capacitor.getPlatform();

const props = defineProps<{
  link: string;
}>();

const linkCalendar = () => {
  logCustomEvent('agenda_link', { platform });
  modalController.close();
  AppLauncher.openUrl({ url: props.link });
};

const cancelEvent = () => {
  modalController.close();
};

const copyToClipboard = async (close?: boolean) => {
  logCustomEvent('agenda_copy_link', { platform });
  await Clipboard.write({
    string: props.link,
  });
  if (close) {
    modalController.close();
  }
};

</script>
<style lang="scss">
.calendar-link {
  color: var(--pidz-body-text);

  span {
    word-wrap: break-word;
  }

  .calendar-link-header {
    display: flex;
    gap: 4px;

    h4 {
      margin: 0;
    }
  }
}
</style>
