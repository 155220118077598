import { isSame } from '@pidz/date';
import { DateTime, Info } from 'luxon';
import { capitalizeString } from '../format';

export const getDateTimeValue = (dt: DateTime, value: keyof DateTime) => dt.get(value);

export const getMonthName = (month: number) => Info.months('long', { locale: 'nl-NL' })[month - 1];

export const getSelectedDateText = (date: DateTime, isDateThisYear: boolean) => {
  const format = isDateThisYear ? 'EEEE, d LLLL' : 'EEEE, d LLLL yyyy'
  const dateString = capitalizeString(date.setLocale('nl').toFormat(format))
  if (isSame(date, DateTime.now(), 'day')) return `${dateString} (vandaag)`
  return dateString
}

export const isoWeekdayFromDate = (date: DateTime) => date.weekday === 0 ? 6 : date.weekday - 1;
