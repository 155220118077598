export enum ShiftStatus {
    OPEN = 1,
    RESERVED = 2,
    FILLED = 3,
    CANCELLED = 4,
    DECLINED = 5,
    CHANGE_PENDING = 6,
    CHANGE_DENIED = 7,
    CHANGE_TIMED_OUT = 8,
    CHANGED = 9,
}

export enum ShiftType {
    TYPE_INCIDENTAL = 1,
    TYPE_PERIODICAL = 2,
}

export enum ShiftSubType {
    SUBTYPE_24_HOURS = 1,
    SUBTYPE_SLEEP = 2,
    SUBTYPE_FIXED = 3,
    SUBTYPE_NORMAL = 4
}
  
export enum ShiftApprovalStatus {
    FREELANCER_INVITED = 3,
    FREELANCER_DECLINED = 4,
    FREELANCER_ACCEPTED = 5,
    CUSTOMER_APPROVED = 6,
    CUSTOMER_DECLINED = 7,
    CUSTOMER_CANCELLED = 8,
}

export enum Prefinance {
    FORCE = 1,
    NONE = 2,
    CHOICE = 3,
}