<template>
  <text-row class="invoice-detail-travel-costs"
            left-label="Reiskosten"
            :left="`${formatPriceToEuroString(rate)} ex B.T.W.`"
            :right-label="`${distance} km`"
            :right="formatPriceToEuroString(total)"/>
</template>
<script lang="ts" setup>
import TextRow from '@app/components/TextRow.vue';
import { formatPriceToEuroString } from '@pidz/utils';
import { computed } from 'vue';

const props = defineProps<{
  rate: number;
  distance: number;
}>();

const total = computed(() => props.rate * props.distance);
</script>
<style lang="scss">
.invoice-detail-travel-costs {
}
</style>
