<template>
  <modal class="edit-event-modal">
    <h1 class="font-bold">Wat wil je bewerken?</h1>
    <p>
      Je hebt een agenda-item uit een reeks geselecteerd. Wil je één item of de
      gehele reeks bewerken?
    </p>
    <template #footer>
      <button class="button-outline"
              @click="linkToEditRecurrencePage">
        Gehele reeks
      </button>
      <button @click="linkToEditEventPage">Eén item</button>
    </template>
  </modal>
</template>
<script setup lang="ts">
import Modal from '@app/components/Modal.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useModalController } from '@pidz/utils';
import { useRouter } from 'vue-router';

const router = useRouter();
const modalController = useModalController();

const props = defineProps<{
  id: number;
}>();

const linkToEditRecurrencePage = async () => {
  logCustomEvent('agendaitem_edit', { type: 'series' });
  await router.push({
    name: 'calendar edit recurrence',
    params: { id: props.id },
  });
  modalController.close();
};

const linkToEditEventPage = async () => {
  logCustomEvent('agendaitem_edit', { type: 'single' });
  await router.push({
    name: 'calendar edit event',
    params: { id: props.id },
  });
  modalController.close();
};
</script>
<style lang="scss"></style>
