<template>
  <div class="update-required-page">
    <pidz-logo />
    <h1>Update benodigd</h1>
    <p>Deze mijnPIDZ app is helaas wat verouderd.</p>
    <p>Klik op onderstaande knop om in de {{storeName}} direct de nieuwste versie te installeren.</p>
    <form-section>
      <async-button class="light" @click="goAppStore">Update de app</async-button>
    </form-section>
  </div>
</template>
<script lang="ts" setup>
import { Capacitor } from '@capacitor/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { computed } from 'vue';
import AsyncButton from '../../components/AsyncButton.vue';
import FormSection from '../../components/FormSection.vue';
import PidzLogo from '../../components/PidzLogo.vue';

const storeName = computed(() => (Capacitor.getPlatform() === 'ios' ? 'App Store' : 'PlayStore'));

const goAppStore = () => {
  AppUpdate.openAppStore();
};

</script>
<style lang="scss">
.update-required-page{
  color: var(--pidz-white);
  background-color: var(--pidz-blue);
  padding: 48px 16px 16px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pidz-logo {
    align-self: center;
    height: 40px;
    margin-bottom: 20px;
  }
  p {
    max-width: 320px;
    text-align: center;
  }
}

</style>
