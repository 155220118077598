<template>
  <dynamicElement :class="['heading',
                           `heading--${variant}`,
                           {'heading--sized': !!props.fontSize}]">
    <p-icon v-if="props.icon"
            :icon-name="props.icon"
            :color="iconColor"
            size="large"
            class="heading__icon"/> <slot/>
  </dynamicElement>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?: 'primary' | 'secondary';
  fontSize?: 'xsm' | 'sm' | 'md' | 'lg' | 'xl';
  icon?: string
}

const props = withDefaults(defineProps<Props>(), {
  level: 'h1',
  variant: 'primary'
});

const headingSize = computed(() => {
  switch (props.fontSize) {
    case 'xsm':
      return '0.75rem';
    case 'sm':
      return '0.875rem';
    case 'md':
      return '1rem';
    case 'lg':
      return '1.25rem';
    case 'xl':
      return '2rem';
    default:
      return '1.5rem';
  }
});

const iconColor = computed(() => (props.variant === 'primary' ? 'blue' : 'black'));
const dynamicElement = computed(() => props.level);

</script>
<style lang="scss" scoped>
  .heading {
    display:flex;
    align-items: center;
    gap: .5rem;
    font-family: Lato;
    margin: 0;

     &--sized {
      font-size: v-bind(headingSize);
     }
      &--primary {
        color: var(--pidz-blue);
      }
      &--secondary {
        color: var(--pidz-black);
      }

      &__icon {
        margin-right: 12px;
      }
  }
</style>
