import { RowType } from '@pidz/enums';
import {
  endDateField,
  specializationField,
  subjectField,
  otherEducationField,
  institutionNameField,
  institutionPlaceField,
} from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<NewEducationForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [subjectField.value],
  },
  {
    type: RowType.ROW,
    items: [otherEducationField.value],
  },
  {
    type: RowType.ROW,
    items: [specializationField.value],
  },
  {
    type: RowType.ROW,
    items: [institutionNameField.value],
  },
  {
    type: RowType.ROW,
    items: [institutionPlaceField.value],
  },
  {
    type: RowType.ROW,
    items: [endDateField.value],
  },
];
