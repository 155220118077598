<template>
  <svg class="GeneralClose pidz-icon" width="14px"
       height="13px" viewBox="0 0 14 13"
       version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 56.3 (81716) - https://sketch.com -->
    <title>Icon/Close/Black</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none"
       stroke-width="1" fill="none"
       fill-rule="evenodd">
      <g id="Agenda_RemoveItem" transform="translate(-875.000000, -183.000000)"
         fill="currentColor">
        <g id="Group-11" transform="translate(423.000000, 117.000000)">
          <g id="Icon/Close/Black" transform="translate(451.000000, 64.000000)">
            <g id="Group-4" transform="translate(1.000000, 1.000000)">
              <path d="M-0.489401723,5.98786743 L14.3703765,6.28466224 C14.6186596,6.2896212 14.8256778,6.47605284 14.8565206,6.72246272 L14.9955149,7.83291742 C15.0298117,8.10692169 14.8354903,8.35684908 14.5614861,8.39114586 C14.5375802,8.39413813 14.5134893,8.39539864 14.4894017,8.39491753 L-0.370376504,8.09812273 C-0.618659638,8.09316376 -0.82567778,7.90673212 -0.856520605,7.66032225 L-0.995514872,6.54986755 C-1.02981165,6.27586328 -0.835490339,6.02593589 -0.561486065,5.99163911 C-0.537580201,5.98864684 -0.513489325,5.98738633 -0.489401723,5.98786743 Z" id="Rectangle"
                    fill-rule="nonzero" transform="translate(7.000000, 7.191392) rotate(-45.000000) translate(-7.000000, -7.191392) "></path>
              <path d="M-0.370408952,6.28466783 L14.4894336,5.98787647 C14.7655209,5.98236226 14.993804,6.2017051 14.9993182,6.47779241 C14.9997993,6.50188029 14.9985388,6.52597144 14.9955464,6.54987757 L14.8565531,7.66030716 C14.8257098,7.90671666 14.6186918,8.09314779 14.370409,8.09810667 L-0.489433565,8.39489802 C-0.765520878,8.40041223 -0.993804019,8.1810694 -0.999318232,7.90498208 C-0.999799332,7.88089421 -0.998538781,7.85680306 -0.995546432,7.83289692 L-0.85655309,6.72246734 C-0.82570982,6.47605783 -0.618691775,6.28962671 -0.370408952,6.28466783 Z" id="Rectangle-Copy-16"
                    fill-rule="nonzero" transform="translate(7.000000, 7.191387) rotate(-315.000000) translate(-7.000000, -7.191387) "></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
