<template>
  <div v-if="open" ref="dropdownList"
       class="dropdown-list">
    <div class="dropdown-list__close-icon">
      <p-button variant="ghost"
                @click="emit('close')">
        <p-icon icon-name="close"
                size="medium"/>
      </p-button>
    </div>
    <slot name="items"/>
  </div>
</template>
<script lang="ts" setup>
import {
  computed, ref, watch
} from 'vue';

const props = withDefaults(defineProps<{
  open: boolean,
  position?: {
    height?: number,
    left?: number
  }
}>(), {
  open: false
});

const dropdownList = ref<HTMLElement | null>(null);
const emit = defineEmits(['close']);

const outsideClickHandler = (event: Event) => {
  if (!dropdownList?.value?.contains(event.target as HTMLElement)) {
    emit('close');
  }
};

watch(
  () => props.open,
  () => {
    if (props.open) {
      document.addEventListener('click', outsideClickHandler);
    } else {
      document.removeEventListener('click', outsideClickHandler);
    }
  }
);

const heightOffset = computed(() => (props?.position?.height !== undefined ? `${(props?.position?.height)}px` : undefined));
const leftOffset = computed(() => (props?.position?.left !== undefined ? `${(props?.position?.left)}px` : undefined));

</script>

<style lang="scss" scoped>
.dropdown-list {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow:var(--shadow-dark);
  background: white;
  position: absolute;
  z-index: 999;
  top: v-bind(heightOffset);
  left: 0px;

  color: black;
  font-size: 16px;

  &__close-icon{
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: 1024px) {
    width: 400px;
    left: v-bind(leftOffset);
  }
}

</style>
