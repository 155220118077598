import { ShiftType } from '@pidz/enums';

export default [
  {
    name: 'invitations',
    path: '/invitations',
    redirect: { name: 'incidental invitations' },
    meta: { title: 'Uitnodigingen' },
    children: [
      {
        name: 'incidental invitations',
        path: '/invitations/incidental',
        component: () => import('@app/pages/invitations/InvitationOverviewPage.vue'),
        meta: {
          title: 'Uitnodigingen',
          type: ShiftType.TYPE_INCIDENTAL
        },
      },
      {
        name: 'periodical invitations',
        path: '/invitations/periodical',
        component: () => import('@app/pages/invitations/InvitationOverviewPage.vue'),
        meta: {
          title: 'Uitnodigingen',
          type: ShiftType.TYPE_PERIODICAL
        },
      },
      {
        name: 'invitation',
        path: '/invitation/:id',
        component: () => import('@app/pages/shifts/ShiftDetailPage.vue'),
        meta: {
          title: 'Uitnodiging',
          backButton: true,
        },
      },
    ],
  },
]