import router from '@app/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

export default async () => {
  if (Capacitor.getPlatform() === 'android') {
    await StatusBar.setBackgroundColor({ color: '#004d9d' });

    await App.addListener('backButton', ({ canGoBack }) => {
      const { name: routeName } = router.currentRoute.value;

      if (routeName === 'login' || routeName === 'dashboard') { App.minimizeApp(); return; }
      if (!canGoBack && routeName !== 'dashboard') { router.replace('/dashboard'); return; }
      if (canGoBack) { router.back(); return; }

      App.minimizeApp();
    });
  }
};
