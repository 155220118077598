import { sheetService } from '@pidz/api';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { sheetStatusInfo } from '../utils/overview-conversion';

interface SheetStore {
  singleSheet: { [seetId: string]: Sheet | undefined };
  sheets: Sheet[];
  sheetOverviewItems: newOverviewItem[],
  sheetOverviewScrollPosition: number,
  sheetSearchString: string,
}

const transformSheetToSheetOverviewItem = (sheet: Sheet): newOverviewItem => {
  const [year, month] = sheet.month.split('-').map(Number);

  return {
    firstOfType: false,
    lastOfType: false,
    groupTitle: `${DateTime.local(year, month).setLocale('nl-NL').monthLong!}
                  ${DateTime.now().year === year ? '' : `, ${year}`}`,
    to: { name: 'sheet', params: { id: sheet.sheet_id } },
    id: sheet.sheet_id,
    status: sheetStatusInfo.get(sheet.status)!.text,
    title: sheet.organisation_name,
    subtitle: sheet.department_name,
    class: sheetStatusInfo.get(sheet.status)!.className,
  };
};

// TODO: optimize this function
// it doesn't need to format all items
// it only needs to format the last 10 and 1 item before that
// isFormatted toevoegen en voordat je gaat formatten zet je de laatste op false
const formatAllOverviewItems = (sheets: newOverviewItem[]): newOverviewItem[] => {
  const formattedSheets = sheets;

  formattedSheets.forEach((sheet, index) => {
    const lastSheet = sheets[index - 1];
    const newSheet = sheet;

    if (!lastSheet || newSheet.groupTitle !== lastSheet.groupTitle) newSheet.firstOfType = true;

    if (lastSheet && newSheet.groupTitle !== lastSheet.groupTitle) {
      formattedSheets[index - 1].lastOfType = true;
    } else if (lastSheet) {
      formattedSheets[index - 1].lastOfType = false;
    }

    return newSheet;
  });

  formattedSheets[formattedSheets.length - 1].lastOfType = true;

  return formattedSheets;
};

export default defineStore('sheetStore', {
  state: (): SheetStore => ({
    singleSheet: {},
    sheets: [],
    sheetOverviewItems: [],
    sheetOverviewScrollPosition: 0,
    sheetSearchString: '',

  }),
  actions: {
    async clearSheets() {
      this.sheets = [];
      this.sheetOverviewItems = [];
      this.sheetOverviewScrollPosition = 0;
      this.sheetSearchString = '';
    },
    async getSheets(params: GetSheetsParams) {
      const result = await sheetService.getSheets(params);

      const newSheets = result.data;
      const previousSheets = this.sheets;

      if (!params.offset || !previousSheets?.length) {
        this.sheets = [...newSheets];
      } else {
        this.sheets = [...previousSheets, ...newSheets];
      }

      if (newSheets.length !== 0) {
        newSheets.forEach((sheet) => {
          this.sheetOverviewItems = [...this.sheetOverviewItems, transformSheetToSheetOverviewItem(sheet)];
        });

        this.sheetOverviewItems = formatAllOverviewItems(this.sheetOverviewItems);
      }

      return this.sheets;
    },

    async getSheetById(sheetId: number) {
      const sheetIdIndex = this.sheets.findIndex(element => element.sheet_id === sheetId);
      const sheet = await sheetService.getSheet(sheetId);
      sheetIdIndex !== -1 ? this.sheets[sheetIdIndex] = sheet : this.sheets.push(sheet);
      return sheet;
    },

    // Function only updates the sheet locally to the correct status
    async updateSheet(sheet: Sheet) {
      const { status, sheet_id } = sheet;

      const sheetIDIndex = this.sheets.findIndex(
        element => element.sheet_id === sheet_id,
      );

      this.sheets[sheetIDIndex].status = status;

      this.sheetOverviewItems[sheetIDIndex] = {
        ...this.sheetOverviewItems[sheetIDIndex],
        status: sheetStatusInfo.get(sheet.status)!.text,
        class: sheetStatusInfo.get(sheet.status)!.className,
      };
    },

    async addSheetBlock(sheetBlockModel: UnsavedSheetBlock): Promise<void> {
      await sheetService.addSheetBlock(sheetBlockModel);
    },

    async updateSheetBlock(edittedWorkingHours: SheetBlockModel): Promise<void> {
      await sheetService.updateSheetBlock(edittedWorkingHours);
    },

    async removeSheetBlock(sheetBlocks: number | number[]): Promise<void> {
      const sheetBlockIds = Array.isArray(sheetBlocks) ? sheetBlocks : [sheetBlocks];
      await sheetService.removeSheetBlock(sheetBlockIds);
    },

    async getRows(sheetUuid: string): Promise<MicroSheetRow[] | null> {
      try {
        return await sheetService.getRows(sheetUuid);
      } catch (error) {
        console.error('Error getting rows:', error);
        return null;
      }
    },

    async getRow(sheetUuid: string, rowUuid: string): Promise<MicroSheetRow | null> {
      try {
        const row = await sheetService.getRow(sheetUuid, rowUuid);
        return row;
      } catch (error) {
        console.error('Error getting rows:', error);
        return null;
      }
    },

    async createRow(rowValue: MicroSheetRow): Promise<void> {
      await sheetService.createRow(rowValue);
    },

    async updateRow(rowValue: MicroSheetRow): Promise<void> {
      await sheetService.updateRow(rowValue);
    },

    async removeRow(rowValue: MicroSheetRow): Promise<void> {
      await sheetService.removeRow(rowValue);
    },

    async addCommute(commute: any): Promise<void> {
      await sheetService.addCommute(commute);
    },

    async updateCommute(commute: any): Promise<void> {
      await sheetService.updateCommute(commute);
    },

    async removeCommute(commute: any): Promise<void> {
      await sheetService.removeCommute(commute);
    },

    async getCommutes(sheetUuid: string): Promise<Commute[] | null> {
      try {
        return await sheetService.getCommutes( sheetUuid );
      } catch (error) {
        console.error('Error getting commutes:', error);
        return null;
      }
    },

    async getSheetSummary(sheetId: number): Promise<SheetSummary | null> {
      try {
        return await sheetService.getSheetSummary(sheetId);
      } catch (error) {
        console.error('Error getting sheet summary:', error);
        return null;
      }
    },

    async closeSheetById(sheetId: number): Promise<void> {
      await sheetService.closeSheetById(sheetId);
    },

    async sendSheetForApprovalById(sheetId: number, comment: string): Promise<void> {
      await sheetService.sendSheetForApprovalById(sheetId, comment);
    },
  },
});
