import { useApi } from '../../utils/use-api';

interface ShiftWorkerParams {
  shiftWorkerId:number;
}
type ShiftWorkerResult = ShiftWorker;
const api = useApi();

export const shiftService = {
  getShifts: (data: ShiftGetShiftsParams): Promise<{data: OverviewShift[]}> => {
    return api.jsonrpc({ 
      url: 'shift:getShifts',
      params: data
    });
  },
  getShift: ( shiftId: number ): Promise<Shift> => {
    return api.jsonrpc({
      url: 'shift:getShift',
      params: {
        shiftId
      }
    })
  },
  getShiftWorker: ( shiftId?:number ): Promise<ShiftWorker> => {
    return api.jsonrpc({
      url: 'shift:getShiftWorker',
      params: {
        shiftId
      }
    })
  },
  acceptShift: (data: ShiftWorkerParams): Promise<ShiftWorkerResult> => {
    return api.jsonrpc({
      url: 'shift:acceptShift',
      params: data
    });
  },
  declineShift: (data: ShiftWorkerParams): Promise<ShiftWorkerResult> => {
    return api.jsonrpc({
      url: 'shift:declineShift',
      params: data
    });
  },
  acceptShiftChanges: (data: ShiftWorkerParams): Promise<ShiftWorkerResult> => {
    return api.jsonrpc({
      url: 'shift:acceptShiftChanges',
      params: data
    });
  },
  rejectShiftChanges: (data: ShiftWorkerParams): Promise<ShiftWorkerResult> => {
    return api.jsonrpc({
      url: 'shift:rejectShiftChanges',
      params: data
    });
  },
  downloadShiftContract: (id: number): Promise<Base64File> => {
    return api.jsonrpc({
      url: 'contract:downloadContract',
      params: { id }  
    });
  }
}