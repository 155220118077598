import { formatYmd, isAfter } from '@pidz/date';
import { FormFieldType } from '@pidz/enums';
import { genders, suggestedCountries, countries, maritalStatus } from '@pidz/utils';
import { DateTime } from 'luxon';
import { ref } from 'vue';
import * as zod from 'zod';

const countryList = (): FormDropDownOption[] =>
  [...suggestedCountries(), ...countries].map<FormDropDownOption>(
    ([key, value], index) => ({ id: index, value: key, label: value || '' }),
  );

const genderList = (): FormDropDownOption[] =>
  [...genders].map<FormDropDownOption>(([key, value], index) => ({
    id: index,
    value: key,
    label: value,
  }));

const maritalStatusList = (): FormDropDownOption[] =>
  [...maritalStatus].map<FormDropDownOption>(([key, value], index) => ({
    id: index,
    value: key,
    label: value,
  }));

export const firstNameField = ref<FormField>({
  active: true,
  name: 'firstName',
  formType: FormFieldType.INPUT,
  validation: zod.string().min(1, { message: 'Vul je voornaam in' }),
  width: 70,
  data: {
    id: 'firstName',
    label: 'Voornaam',
    inputType: 'text',
    placeholder: 'Voornaam',
  },
});

export const initialsField = ref<FormField>({
  active: true,
  name: 'initials',
  formType: FormFieldType.INPUT,
  validation: zod.string().min(1, { message: 'Vul je voorletters in' }),
  width: 30,
  data: {
    id: 'initials',
    label: 'Voorletters',
    inputType: 'text',
    placeholder: 'A.B.',
  },
});

export const insertionField = ref<FormField>({
  active: true,
  name: 'insertion',
  formType: FormFieldType.INPUT,
  validation: zod.string(),
  width: 30,
  data: {
    id: 'insertion',
    label: 'Tussenvoegsels',
    inputType: 'text',
    placeholder: 'van',
  },
});

export const lastNameField = ref<FormField>({
  active: true,
  name: 'lastName',
  formType: FormFieldType.INPUT,
  validation: zod.string().min(1, { message: 'Vul je achternaam in' }),
  width: 70,
  data: {
    id: 'lastName',
    label: 'Achternaam',
    inputType: 'text',
    placeholder: 'Achternaam',
  },
});

export const birthDateField = ref<FormField>({
  active: true,
  name: 'birthdate',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .refine(
      (data): boolean => !isAfter(DateTime.fromISO(data), DateTime.now()),
      {
        message: 'Je geboortedatum mag niet in de toekomst liggen',
      },
    )
    .refine((data) => data !== '', {
      message: 'Vul je geboortedatum in',
    }),
  data: {
    id: 'birthdate',
    label: 'Geboortedatum',
    inputType: 'date',
    preIcon: 'agenda',
    max: formatYmd(DateTime.now()),
  },
});

export const birthPlaceField = ref<FormField>({
  active: false,
  name: 'birthPlace',
  formType: FormFieldType.INPUT,
  validation: zod.string(
    { required_error: 'Vul je geboorteplaats in' }
  ).min(1, { message: 'Vul je geboorteplaats in' }),
  width: 100,
  data: {
    id: 'birthPlace',
    label: 'Geboorteplaats',
    inputType: 'text',
    placeholder: 'Geboorteplaats',
  },
});

export const birthCountryField = ref<FormField>({
  active: false,
  name: 'birthCountry',
  formType: FormFieldType.DROPDOWN,
  validation: zod.string({ required_error: 'Vul je geboorteland in' }),
  data: {
    id: 'birthCountry',
    label: 'Geboorteland',
    options: countryList(),
    showDropdownPrepend: false,
  },
});

export const nationalityField = ref<FormField>({
  active: true,
  name: 'nationality',
  formType: FormFieldType.DROPDOWN,
  validation: zod.string({ required_error: 'Vul je nationaliteit in' }),
  data: {
    id: 'nationality',
    label: 'Nationaliteit',
    options: countryList(),
    showDropdownPrepend: false,
  },
});

export const nationalServiceNumberField = ref<FormField>({
  active: true,
  name: 'nationalIdentificationNumber',
  formType: FormFieldType.INPUT,
  validation: zod.string().length(9, { message: 'Een BSN bevat 9 cijfers' }),
  data: {
    disabled: false,
    id: 'nationalIdentificationNumber',
    label: 'BSN',
    inputType: 'string',
    placeholder: '123456789',
  },
});

export const genderField = ref<FormField>({
  active: true,
  name: 'gender',
  formType: FormFieldType.DROPDOWN,
  validation: zod.string({ required_error: 'Vul een geslacht in' }),
  data: {
    id: 'gender',
    label: 'Geslacht',
    options: genderList(),
    showDropdownPrepend: false,
  },
});

export const maritalStatusField = ref<FormField>({
  active: false,
  name: 'maritalStatus',
  formType: FormFieldType.DROPDOWN,
  validation: zod.string({ required_error: 'Vul je burgerlijke staat in' }),
  data: {
    id: 'maritalStatus',
    label: 'Burgerlijke staat',
    options: maritalStatusList(),
    showDropdownPrepend: false,
  },
});
