import router from '@app/router';
import { updateFreelancerUnpaidInvoices } from '@app/user-state';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useInvoice, useToaster, useMollieStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';

const appLinkHandlers: {[namespace: string]: (...args: any[]) => void, } = {
  'freelancer.creditInvoice.payment': (id: string) => {
    const invoiceStore = useInvoice();
    invoiceStore.invalidateKey();
    router.push({ name: 'freelancer invoice', params: { id, payment: 'payment' } });
  },
  'freelancer.software.payment': async (id: string) => {
    const invoiceStore = useInvoice();
    invoiceStore.invalidateKey();
    try {
      router.push({ name: 'software invoice', params: { id, payment: 'payment' } });
    } catch (_) {
      // might be blocked by unpaid invoice page
    }
  },
  'freelancer.software.unpaid.payment': async () => {
    await updateFreelancerUnpaidInvoices();
  },
  'mollie': async () => {
    await checkIfMollieWasSuccesful();
  },
  'profile.revoke.mollie': async () => {
    await checkIfMollieWasSuccesful();
    router.push({ name: 'profile overview'});
  }
};

const checkIfMollieWasSuccesful = async () => {
  const mollie = useMollieStore();
  const modal = useModalController();

  if (await mollie.checkIfMandateWasSuccesful()) {
    showToaster('success')
    modal.close()
  } else {
    showToaster('error')
  }
}

const showToaster = (type: 'success' | 'error') => {
  const toaster = useToaster();

  if (type === 'success') {
    toaster.addToaster({
      type: 'success',
      title: 'Gelukt',
      message:
        `Machtiging succesvol!`,
    });
  }
  if (type === 'error') {
    toaster.addToaster({
      type: 'error',
      title: 'Oeps',
      message:
        `Machtiging is niet succesvol, probeer het (later) opnieuw of neem contact op met je vestiging`,
    });
  }
}

const initAppLinkListener = async () => await App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
  try {
    await Browser.close();
  } catch { /* empty */ } finally {
    const { url } = event;
    const [namespace, ...rest] = url.replace('pidz-app://', '').split('/');
    if (namespace in appLinkHandlers) {
      appLinkHandlers[namespace](...rest);
    } else {
      router.push({ name: 'dashboard' });
    }
  }
});

export default initAppLinkListener;
