<template>
  <svg class="infinity-top-spinner" :class="{ rotate }"
       viewBox="-10 -10 20 20" xmlns="http://www.w3.org/2000/svg" >
    <defs>
      <marker id="arrowhead" markerWidth="20"
              markerHeight="20"
              refX="2" refY="3"
              orient="auto">
        <path d="M1,5l2,-2 -2,-2" />
      </marker>
    </defs>
    <path :d="pathDefinition" :marker-end="rotate ? '' : 'url(#arrowhead)'" />
  </svg>
</template>
<script lang="ts" setup>

import { computed } from 'vue';

const props = defineProps<{
  rotate:boolean,
  animationNormal:number;
}>();

const pathDefinition = computed(() => {
  let result = 'M-5,0';
  const { animationNormal } = props;
  const angle = -Math.PI + animationNormal * (Math.PI + 2.5);
  const angleToPos = (a:number) => `${5 * Math.cos(a)},${5 * Math.sin(a)}`;

  const A = angle < 0 ? angleToPos(angle) : '5,0';
  result += `A5,5 0,0,1 ${A}`;
  if (angle > 0) {
    result += `A5,5 0,0,1 ${angleToPos(angle)}`;
  }
  return result;
});

</script>
<style lang="scss">

.infinity-top-spinner {
  position: fixed;
  z-index: 100;
  top: 50px;
  --size: 40px;
  left: calc(50% - var(--size)/2);
  width: var(--size);
  height: var(--size);
  background: var(--pidz-white);
  border-radius: 50%;
  filter: drop-shadow(0 0 12px rgba(0,0,0,0.35));

  path {
    fill: none;
    stroke: var(--pidz-blue);
  }

  &.rotate {
    animation:spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform:rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
}
</style>
