import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  onMounted, onBeforeUnmount, ref, Ref,
} from 'vue';

const iosUseScrollIntoView = (viewportRef:Ref) => {
  const mutateViewportRef = viewportRef;
  const keyboardHeight = ref(0);
  const noKeyboardBottom = ref(0);

  const setScrollBottomPadding = (val:number) => {
    mutateViewportRef.value.style.paddingBottom = `${val}px`;
  };

  let keyboardHeightKnown:any = null;
  const keyboardHeightPromise = new Promise((ok) => {
    keyboardHeightKnown = ok;
  });

  const onFocus = async (e:Event) => {
    const el = e.target as HTMLElement;
    if (!el) { return; }
    if (el.nodeName !== 'INPUT' && el.nodeName !== 'TEXTAREA') { return; }
    await keyboardHeightPromise;
    const rect = el.getBoundingClientRect();
    if (rect.bottom > noKeyboardBottom.value - keyboardHeight.value) {
      setScrollBottomPadding(keyboardHeight.value);
      setTimeout(() => {
        viewportRef.value.scrollTo({
          top: rect.top + 20,
          behavior: 'smooth',
        });
      }, 40);
    }
  };

  onMounted(async () => {
    document.body.addEventListener('focus', onFocus, true);
    await Keyboard.addListener('keyboardWillShow', (info) => {
      if (noKeyboardBottom.value === 0) {
        noKeyboardBottom.value = viewportRef.value.getBoundingClientRect().bottom;
      }
      keyboardHeight.value = info.keyboardHeight;
      keyboardHeightKnown();
    });
    await Keyboard.addListener('keyboardDidShow', () => {
      setScrollBottomPadding(0);
    });
  });
  onBeforeUnmount(() => {
    document.body.removeEventListener('focus', onFocus, true);
    Keyboard.removeAllListeners();
  });
};

const iosUseTapHideKeyboard = () => {
  const onTap = (e) => {
    const el = e.target as any;
    if (!el) { return; }
    if (document.activeElement && !['INPUT', 'SELECT', 'TEXTAREA'].includes(el.nodeName)) {
      (document.activeElement as HTMLElement).blur();
    }
  };
  onMounted(() => {
    document.body.addEventListener('click', onTap);
  });
  onBeforeUnmount(() => {
    document.body.removeEventListener('click', onTap);
  });
};

const androidUseResetScrollOnBlur = async (viewportRef:Ref) => {
  let shouldResetScroll:boolean|null = null;
  const onFocus = () => {
    if (shouldResetScroll === null) {
      const vp = viewportRef.value;
      shouldResetScroll = vp.scrollHeight <= vp.getBoundingClientRect().height;
    }
  };
  await Keyboard.addListener('keyboardDidHide', () => {
    if (shouldResetScroll) {
      viewportRef.value.scrollTo(0, 0);
    }
  });

  onMounted(() => {
    document.body.addEventListener('focus', onFocus, true);
  });
  onBeforeUnmount(() => {
    Keyboard.removeAllListeners();
    document.body.removeEventListener('focus', onFocus, true);
  });
};

const useLabelNoFocus = () => {
  const onClick = (e:Event) => {
    const el = e.target as any;
    if (!el) { return; }
    if (el.nodeName === 'LABEL' && !el.querySelector('[type=checkbox]')) {
      e.preventDefault();
    }
  };
  onMounted(() => {
    document.body.addEventListener('click', onClick);
  });
  onBeforeUnmount(() => {
    document.body.removeEventListener('click', onClick);
  });
};

export const useInputScrollFix = (viewportRef:Ref) => {
  const platform = Capacitor.getPlatform();
  if (platform === 'ios') {
    iosUseTapHideKeyboard();
    iosUseScrollIntoView(viewportRef);
  }
  if (platform === 'android') {
    androidUseResetScrollOnBlur(viewportRef);
  }
  useLabelNoFocus();
};
