<template>
  <div class="company">
    <div class="company__fields" :class="{ 'company__fields--web': isWeb }">
      <p-form ref="companyForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-buttons="true"/>
    </div>
    <div class="company__assets" :class="{ 'company__assets--web': isWeb }">
      <company-assets ref="companyAssets"
                      :template="formData.values.invoiceTemplate || ''"
                      :assets="assets"
                      :is-web="isWeb"
                      is-edit/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import {
  assetTypeMap,
  fileRemove,
  fileUpload,
  rerouteToOverview,
} from '@pidz/shared/utils';
import { useAssetStore, useUserStore } from '@pidz/stores';
import { computed, ref, watch } from 'vue';
import {
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRouter,
} from 'vue-router';
import CompanyAssets from './CompanyAssets.vue';

interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<ProfileCompany>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  disabled: false,
});

const companyForm = ref<any>();
const companyAssets = ref<typeof CompanyAssets>();

const router: Router = useRouter();
const userStore = useUserStore();
const assetStore = useAssetStore();

const assets = computed(() => assetStore.getCompanyAssets());

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'profile_modal_edit_cancel' : 'profile_modal_add_cancel'

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const { isSubmitting, succesToaster, onCancel, warnBeforeCancel } =
  useFormFunctions(
    companyForm,
    [],
    () => rerouteToOverview(router, 'profile'),
    () => Promise.resolve(),
    props.isEdit,
    'bedrijfs gegevens',
  );

const onSubmit = async () => {
  const submit = companyForm?.value?.handleSubmit(
    async (formValues: ProfileCompany) => {
      if (
        companyForm?.value?.isValidating ||
        !companyForm?.value?.meta.valid ||
        !userStore.freelancer
      )
        return;

      await Promise.all(
        Object.keys(assets.value).map((key) => {
          if (companyAssets.value!.newFiles[key].length > 0) {
            const assetsUpload = companyAssets.value!.newFiles[key];
            const assetKey = assetTypeMap.get(key) || 0;
            return fileUpload(
              assetsUpload,
              assetKey,
              companyAssets.value?.validUntil[key],
            );
          }
        }),
      );

      if (companyAssets.value?.deletedFiles.length > 0 && props.isEdit)
        fileRemove(companyAssets.value?.deletedFiles);

      if (companyAssets.value?.newInvoiceTemplate) {
        await assetStore.updateInvoiceTemplate(
          companyAssets.value?.newInvoiceTemplate,
        );
      }
      if (
        props.formData.values.invoiceTemplate &&
        !companyAssets.value?.existingInvoiceTemplate &&
        !companyAssets.value?.newInvoiceTemplate
      ) {
        await assetStore.updateInvoiceTemplate(null);
      }

      formSubmitted.value = true;

      let freelancer = userStore.freelancer;

      freelancer.coc_number = formValues.cocNumber;
      freelancer.website = formValues.website;
      freelancer.organisation.name = formValues.companyName;
      freelancer.vat_number = formValues.vatNumber;
      freelancer.vat_free = formValues.vatFree;

      await userStore.updateFreelancerData(freelancer);
      await assetStore.fetchAssets();

      rerouteToOverview(router, 'profile');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

const formRules = computed<boolean>(
  () => companyForm.value.isValidating || !companyForm.value.meta?.valid,
);

const requiredFilesRules = computed<boolean>(
  () =>
    (companyAssets.value?.newFiles.cocDocument.length === 0 &&
      companyAssets.value?.existingFiles.cocDocument.length === 0) ||
    (companyAssets.value?.newFiles.complaintsDocument.length === 0 &&
      companyAssets.value?.existingFiles.complaintsDocument.length === 0) ||
    (companyAssets.value?.newFiles.complaintsDocument.length !== 0 &&
      companyAssets.value?.validUntil.complaintsDocument === ''),
);

const dirtyRules = computed<boolean>(
  () =>
    companyForm.value.meta?.dirty ||
    companyAssets.value?.deletedFiles.length > 0 ||
    companyAssets.value?.newFiles.complaintsDocument.length > 0 ||
    companyAssets.value?.newFiles.cocDocument.length > 0 ||
    (!!props.formData.values.invoiceTemplate &&
      !companyAssets.value?.existingInvoiceTemplate) ||
    !!companyAssets?.value?.newInvoiceTemplate,
);

const isSubmitDisabled = computed(
  () => formRules.value || requiredFilesRules.value || !dirtyRules.value,
);

watch(
  () => companyAssets.value,
  () => {
    companyForm.value?.validate();
  },
);

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit });
</script>
<style scoped lang="scss">
.company {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
  &__assets {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-top: 0;

    &--web {
      padding: 32px;
      padding-top: 0;
      box-shadow: none;
    }
  }
}
</style>
