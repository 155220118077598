<template>
  <div class="languages">
    <div class="languages__fields" :class="{ 'languages__fields--web': isWeb }">
      <p-form ref="languageForm"
                 :form-data="formData"
                 :class-name="'form--web'"
                 :disable-buttons="true"/>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import {
  languageOptions,
  updateLanguageOptions,
} from '@pidz/shared/forms/cv/language';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useCVStore } from '@pidz/stores';
import { onMounted, ref } from 'vue';
import {
  useRouter,
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';

interface Props {
  languageItem?: LanguageProficiency;
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<LanguageForm> | PidzFormData<NewLanguagesForm>;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
});

const languageForm = ref<any>();

const router: Router = useRouter();
const cvStore = useCVStore();

const itemId = props.languageItem?.id ?? 0;

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  onDelete,
  warnBeforeCancel,
} = useFormFunctions(
  languageForm,
  [],
  () => rerouteToOverview(router, 'cv'),
  async () => {
    await cvStore.removeLanguageProficiency(itemId);
    succesToaster('Succesvol verwijderd');
  },
  props.isEdit,
  'taalvaardigheid',
);

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'cv_modal_edit_cancel' : 'cv_modal_add_cancel';

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const onSubmit = async () => {
  const submit = languageForm?.value?.handleSubmit(
    async (formValues: LanguageForm) => {
      if (
        languageForm?.value?.isValidating ||
        !languageForm?.value?.meta.valid
      ) {
        return;
      }

      const { language_uuid, skill_level } = formValues;

      const item: Omit<LanguageProficiency, 'id'> = {
        language_uuid: language_uuid,
        skill_level: skill_level,
      };

      let editItem: LanguageProficiency = {
        ...item,
        id: itemId,
      };

      props.isEdit
        ? await cvStore.updateLanguageProficiency(editItem)
        : await cvStore.addLanguageProficiency(item);

      formSubmitted.value = true;
      rerouteToOverview(router, 'cv');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

onMounted(async () => {
  await cvStore.loadLanguages();
  // Freelancers are not allowed to add two of the same LanguageProficiency, so existing languages are filtered
  const existingLanguages =
    cvStore?.resume?.language_skills?.map((lang) => lang.language_uuid) || [];
  // Filter out existing option
  const filteredLanguages = existingLanguages.filter(
    (language) => language !== props?.languageItem?.language_uuid || 0,
  );
  const options = languageOptions(cvStore.languages || []);
  updateLanguageOptions(options, filteredLanguages);
});

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit, onDelete });
</script>
<style scoped lang="scss">
.languages {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
}
</style>
