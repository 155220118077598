import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { useMollieStore } from '@pidz/stores';

export const revokeAndRequestNewMollieMandate = async (webUrl: string) => {
  const mollieStore = useMollieStore();
  
  const { platform } = await Device.getInfo();

  if (platform === 'web') {
    const baseUrl = webUrl ?? 'https://app.mijnpidz.nl';
    const url = await mollieStore.revokeAndRequestNewMollieMandate(baseUrl + '/#/settings/profile');
    window.location.replace(url);

  } else {
    const url = await mollieStore.revokeAndRequestNewMollieMandate('pidz-app://profile.revoke.mollie');

    Browser.open({ url });
  }
};
