<template>
  <div class="personal">
    <div class="personal__fields" :class="{ 'personal__fields--web': isWeb }">
      <p-form ref="personalForm"
              :form-data="formData"
              :class-name="'form--web'"
              :disable-buttons="true"/>
    </div>
    <div class="personal__assets" :class="{ 'personal__assets--web': isWeb }">
      <personal-assets ref="personalAssets"
                       :assets="assets"
                       :is-web="isWeb"
                       is-edit/>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import { 
  nationalServiceNumberField, 
  maritalStatusField,
  birthCountryField,
  birthPlaceField 
} from '@pidz/shared/forms/profile/personal';
import {
  assetTypeMap,
  fileRemove,
  fileUpload,
  rerouteToOverview,
} from '@pidz/shared/utils';
import { useUserStore, useAssetStore } from '@pidz/stores';
import { computed, onBeforeMount, ref, watch } from 'vue';
import {
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRouter,
} from 'vue-router';
import PersonalAssets from './PersonalAssets.vue';

interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<ProfilePersonal>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  disabled: false,
  permissions: undefined
});

const personalForm = ref<any>();
const personalAssets = ref<typeof PersonalAssets>();

const router: Router = useRouter();
const userStore = useUserStore();
const assetStore = useAssetStore();

const assets = computed(() => assetStore.getPersonalAssets());

const formSubmitted = ref(false);
const eventName = props.isEdit ? 'profile_modal_edit_cancel' : 'profile_modal_add_cancel'

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const { isSubmitting, succesToaster, onCancel, warnBeforeCancel } =
  useFormFunctions(
    personalForm,
    [],
    () => rerouteToOverview(router, 'profile'),
    () => Promise.resolve(),
    props.isEdit,
    'persoons gegevens',
  );

const onSubmit = async () => {
  const submit = personalForm?.value?.handleSubmit(
    async (formValues: ProfilePersonal) => {
      if (personalForm?.value?.isValidating || !personalForm?.value?.meta.valid || !userStore.freelancer) return;
      
      formSubmitted.value = true;

      await Promise.all(
        Object.keys(assets.value).map((key) => {
          if (personalAssets.value!.newFiles[key].length > 0) {
            const assetsUpload = personalAssets.value!.newFiles[key];
            const assetKey = assetTypeMap.get(key) || 0;
            return fileUpload(
              assetsUpload,
              assetKey,
              personalAssets.value?.validUntil[key],
            );
          }
        }),
      );

      if (personalAssets.value?.deletedFiles.length > 0 && props.isEdit)
        fileRemove(personalAssets.value?.deletedFiles);

      let freelancer = userStore.freelancer;

      freelancer.user.first_name = formValues.firstName;
      freelancer.user.last_name = formValues.lastName;
      freelancer.user.insertion = formValues.insertion;
      freelancer.initials = formValues.initials;
      freelancer.birth_date = formValues.birthdate;
      freelancer.nationality = formValues.nationality;
      freelancer.sex = formValues.gender;
      freelancer.birth_country = formValues.birthCountry;
      freelancer.birth_place = formValues.birthPlace
      freelancer.marital_status = formValues.maritalStatus

      if (!userStore.isUserAdmin) {
        freelancer.national_identification_number = formValues.nationalIdentificationNumber
      }

      await userStore.updateFreelancerData(freelancer);
      await assetStore.fetchAssets();

      rerouteToOverview(router, 'profile');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

const formRules = computed<boolean>(
  () => personalForm.value.isValidating || !personalForm.value.meta?.valid,
);

const requiredFilesRules = computed<boolean>(() => {
  const { newFiles, existingFiles, validUntil } = personalAssets.value!;

  const vogRule = 
    (newFiles.vogDocument.length === 0 && existingFiles.vogDocument.length === 0) ||
    (newFiles.vogDocument.length !== 0 && validUntil.vogDocument === '')

    const insuranceDocumentRule =
      (newFiles?.insuranceDocument?.length === 0 && existingFiles?.insuranceDocument?.length === 0) ||
      (newFiles?.insuranceDocument?.length !== 0 && validUntil?.insuranceDocument === '')
  

  return vogRule || (userStore.isUserFreelancer ? insuranceDocumentRule : false)
});

const dirtyRules = computed<boolean>(
  () =>
    personalForm.value.meta?.dirty ||
    personalAssets.value?.deletedFiles.length > 0 ||
    personalAssets.value?.newFiles.vogDocument.length > 0 ||
    personalAssets.value?.newFiles.insuranceDocument?.length > 0 ||
    personalAssets.value?.newFiles.insuranceDocumentPaymentProof?.length > 0
  );

const isSubmitDisabled = computed<boolean>(
  () => formRules.value || requiredFilesRules.value || !dirtyRules.value,
);

watch(
  () => personalAssets.value,
  () => personalForm.value?.validate(),
);

onBeforeMount(async () => {
  if (userStore.isUserAdmin) {
    nationalServiceNumberField.value.data.disabled = true;
  }
    
  maritalStatusField.value.active = userStore.isUserAgencyWorker ? true : false;
  birthCountryField.value.active = userStore.isUserAgencyWorker ? true : false;
  birthPlaceField.value.active = userStore.isUserAgencyWorker ? true : false;
  personalForm.value?.validate();
  
});

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit });
</script>
<style scoped lang="scss">
.personal {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
  &__assets {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;
    padding-top: 0;

    &--web {
      padding: 32px;
      padding-top: 0;
      box-shadow: none;
    }
  }
}
</style>
