<template>
  <div v-for="(type, index) in Object.keys(assets)" :key="index"
       class="asset">
    <p-ruler margin-bottom="24px" />
    <p-expanded-picker class="asset__picker"
                       :new-files="newFiles[type]"
                       :existing-files="existingFiles[type]"
                       :title="titleMap.get(type)"
                       :disabled="isDisabled"
                       :show-help-text="false"
                       @upload="(files: File[]) => onAddFile(files, type)"
                       @delete="(file: File | Asset) => onDeleteFile(file, type)"
                       @download="onDownloadFile"/>
    <p v-if="showError(type)" class="asset__error">
      {{ `Het is verplicht een ${titleMap.get(type)} toe te voegen` }}
    </p>
    <span v-if="type === 'complaintsDocument' && newFiles[type].length > 0"
          class="asset__valid-until date__container">
      <p-label :input-id="`valid-until__${type}`" :label="'Geldig tot'" />
      <span class="date__input">
        <p-input-prepend>
          <p-icon :icon-name="'agenda'"
                  color="white"
                  :border-radius="false"/>
        </p-input-prepend>
        <p-input v-model="validUntil[type]"
                 input-type="date"
                 :input-id="`valid-until__${type}`"
                 :has-border-radius-left="true"
                 :has-no-left-border="true"
                 :min="formatYmd(DateTime.now())"/>
      </span>
      <span v-if="validUntil[type] === ''" class="asset__error">Vul een geldige verloopdatum in</span>
    </span>
  </div>
  <div class="asset">
    <p-ruler margin-bottom="24px" />
    <p class="asset__title">{{ titleMap.get('invoiceTemplate') }}</p>
    <p-file-list-item v-if="newInvoiceTemplate || existingInvoiceTemplate"
                      class="asset__invoice-item"
                      :file-name="newInvoiceTemplate?.name || existingInvoiceTemplate"
                      show-file-icon
                      :show-download-button="!!existingInvoiceTemplate"
                      show-delete-button
                      @download="previewInvoiceTemplate"
                      @delete="deleteInvoiceTemplate"/>
    <p-file-picker class="expanded-picker__picker"
                   :allowed-file-types="['pdf']"
                   @upload="onUploadInvoiceTemplate">
      <p-icon icon-name="add" class="pointer"
              color="blue" />
      Bestand(en) toevoegen
    </p-file-picker>
  </div>
</template>
<script lang="ts" setup>
import { addDuration, createDurationObject, formatYmd } from '@pidz/date';
import { getMimeType } from '@pidz/shared/utils';
import { useAssetStore } from '@pidz/stores';
import { openFile } from '@pidz/utils';
import { DateTime } from 'luxon';
import { ref } from 'vue';

const props = defineProps<{
  assets: ProfileAssetsCompany;
  template: string;
  isWeb?: boolean;
  isEdit?: boolean;
  isDisabled?: boolean;
}>();

const assetStore = useAssetStore();

const existingFiles = ref<ProfileAssetsCompany>({
  cocDocument: props.assets.cocDocument,
  complaintsDocument: props.assets.complaintsDocument,
});
const newFiles = ref<ProfileAssetsCompany>({
  cocDocument: [],
  complaintsDocument: [],
});

const newInvoiceTemplate = ref<File>();
const existingInvoiceTemplate = ref<string | undefined>(props.template);

const deletedFiles = ref<Asset[]>([]);

const validUntil = ref<ValidDateCompanyAssets>({
  cocDocument: '',
  complaintsDocument:
    formatYmd(addDuration(DateTime.now(), createDurationObject({ year: 1 }))) ||
    '',
});

const titleMap = new Map<string, string>([
  ['cocDocument', 'KVK uitreksel'],
  ['complaintsDocument', 'Certificaat klachtenregeling'],
  ['invoiceTemplate', 'Factuurpapier(optioneel)'],
]);

const showError = (type: string): boolean => {
  return (
    newFiles.value[type].length === 0 && existingFiles.value[type].length < 1
  );
};

const onAddFile = (files: File[], type: string) => {
  newFiles.value[type] = [...newFiles.value[type], ...files];
};

const onDeleteFile = (file: File | Asset, type: string) => {
  if ('id' in file && file.id && props.isEdit) {
    existingFiles.value[type] = existingFiles.value[type].filter(
      (f: Asset) => f.id !== file.id,
    );
    deletedFiles.value = [...deletedFiles.value, file];
  } else {
    newFiles.value[type] = newFiles.value[type].filter((f: File) => f !== file);
  }
};

const onDownloadFile = async (file: File | Asset) => {
  if ('id' in file && file.id) {
    const response = await assetStore.fetchAsset(file.id);
    const mimeType = getMimeType(file.filename)
    if(mimeType) openFile(response, mimeType);
  }
};

const onUploadInvoiceTemplate = (files: FileList) =>
  (newInvoiceTemplate.value = files[0]);

const previewInvoiceTemplate = async () => {
  const invoiceTemplate = await assetStore.fetchInvoiceTemplate();
  openFile(invoiceTemplate);
};

const deleteInvoiceTemplate = async () => {
  if (existingInvoiceTemplate.value) {
    existingInvoiceTemplate.value = undefined;
  } else {
    newInvoiceTemplate.value = undefined;
  }
};

defineExpose({
  deletedFiles,
  newFiles,
  existingFiles,
  newInvoiceTemplate,
  existingInvoiceTemplate,
  validUntil,
});
</script>
<style scoped lang="scss">
.asset {
  font-size: 14px;
  &__picker,
  &__invoice-item,
  &__title,
  &__valid-until {
    margin-bottom: 24px;
  }
  &__error {
    color: red;
  }
}
.date {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__input {
    display: flex;
  }
}
</style>
