import { addDuration, createDurationObject, isAfter, isBefore, capitalizeString } from '@pidz/date';
import { CalendarEventType, RecurrencePeriodType } from '@pidz/enums';
import { DateTime, Info, Interval } from 'luxon';

export const formatTitle = (date: DateTime, isDateThisYear: boolean, format: 'short' | 'long' = 'short'): string => {
  const month = Info.monthsFormat(format, { locale: 'nl' })[date.month - 1];
  return capitalizeString(isDateThisYear ? month : `${month} ${date.year}`);
};

export const eventEndsAfterToday = (endDate: DateTime, currentDate: DateTime): boolean =>
  isAfter(endDate, currentDate);
export const eventStartsBeforeToday = (startDate: DateTime, currentDate: DateTime): boolean =>
  isBefore(startDate, currentDate);

export const formatLocation = (location: LocationDetails): string =>
  `${location.street} ${location.house_number}, ${location.city}`;

export const formatEventTime = (date: DateTime, currentDate?: DateTime): string => {
  if (!currentDate) return date.toFormat('HH:mm');
  const isNotOnDay = eventStartsBeforeToday(date, currentDate) || eventEndsAfterToday(date, currentDate)
  return isNotOnDay ? '00:00' : date.toFormat('HH:mm');
};

export const getEventDetails = (event: CalendarEvent) => {
  const eventMap = new Map([
    [
      CalendarEventType.FREELANCER_REQUEST,
      {
        title: event.organisation_name,
        subTitle: formatLocation({
          city: String(event.department_city),
          street: String(event.department_street),
          house_number: String(event.department_house_number),
        }),
        route: { name: 'shift', params: { id: event.shift_id } },
      },
    ],
    [
      CalendarEventType.SHIFT,
      {
        title: event.organisation_name,
        subTitle: formatLocation({
          city: String(event.department_city),
          street: String(event.department_street),
          house_number: String(event.department_house_number),
        }),
        route: { name: 'shift', params: { id: event.shift_id } },
      },
    ],
    [
      CalendarEventType.UNAVAILABLE,
      {
        title: 'Niet beschikbaar',
        route: { name: 'calendar edit event', params: { id: event.id } },
      },
    ],
  ]);
  return eventMap.get(event.type);
};

export const collidesWithDay = (
  date: DateTime,
  event: {start: DateTime, end: DateTime }, 
): boolean => {
  if (!event.end) return false;
  const eventDateTimeRange = Interval.fromDateTimes(
    event.start.startOf('day'),
    event.end.endOf('day'),
  );
  // this is here because a full day event should not be shown on the next day
  if (event.end.toISO() === date.startOf('day').toISO()) return false
  //
  return eventDateTimeRange.contains(date);
};

export const convertDayPartsToArray = (dayParts: OptionGrid[]): number[] =>
  dayParts.filter((item) => item.checked).map((item) => item.index);

export const formatRecurrenceEndDate = (
  startDate: DateTime,
  endDate: DateTime,
  type: RecurrencePeriodType,
) => {

  // TODO: this is a bug, the type is not a DateTime object, happens in web app
  const forcedDateTimeStartDate = DateTime.isDateTime(startDate) ? startDate : DateTime.fromISO(startDate);
  const forcedDateTimeEndDate = DateTime.isDateTime(endDate) ? endDate : DateTime.fromISO(endDate);

  if (type === RecurrencePeriodType.SetAmount) return undefined;
  return type === RecurrencePeriodType.OnDate
    ? forcedDateTimeEndDate.toISO()
    : addDuration(forcedDateTimeStartDate, createDurationObject({ years: 1 })).toISO();
};

export const eventTypeToName = (type: CalendarEventType): string =>
  ({
    2: 'shift',
    3: 'unavailable',
    4: 'freelancer-request',
  })[type];