<template>
  <div class="tooltip__wrapper" @mouseover="calculateToolTipOffset">
    <slot/>
    <span v-if="!disabled"
          :class="[
            `tooltip--${position}`,
            'tooltip__text'
          ]"
          role="tooltip"
          :id="spanId" inert>{{ text }}</span>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

const props = withDefaults(defineProps<{
  text: string,
  position: 'top' | 'bottom' | 'right' | 'left',
  spanId: string,
  color?: string
  disabled?: boolean
}>(), {
  text: 'tooltip',
  position: 'top',
  spanId: 'tooltip__text',
  color: '#BFD2E6',
  disabled: false
});

const offset = ref<string | number>(0);

const calculateToolTipOffset = () => {
  if (props.disabled) return;
  const tooltip = document.getElementById(`${props.spanId}`)?.getBoundingClientRect().height || 30;
  const element = document.getElementById(`${props.spanId}`)?.parentElement?.getBoundingClientRect().height || 30;
  offset.value = `${(element - tooltip) / 2}px`;
};
</script>
<style scoped lang="scss">
.tooltip__wrapper {
  position: relative;
  display: inline-block;
}

.tooltip__text {
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s;

  color: var(--pidz-body-text);
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  width: 130px;
  background: v-bind(color);
  word-wrap: break-word;
  position: absolute;
  z-index: 1;
}

.tooltip__wrapper:hover .tooltip__text{
  visibility: visible;
  opacity: 1;
}

.tooltip--top {
  inset-block-end: 110%;
  inset-inline-start: 50%;
  margin-inline-start: -65px;
}

.tooltip--bottom {
  inset-block-start: 110%;
  inset-inline-start: 50%;
  margin-inline-start: -65px;
}

.tooltip--left {
  inset-block-end: 0%;
  inset-inline-end: calc(100% + 10px);
  margin-block-end: v-bind(offset);
}

.tooltip--right {
  inset-block-end: 0%;
  inset-inline-start: calc(100% + 10px);
  margin-block-end: v-bind(offset);
}

.tooltip__text::after {
  content: " ";
  position: absolute;
  border-width: 5px;
  border-style: solid;
  border-color: v-bind(color) transparent transparent transparent;
}

.tooltip--left::after {
  margin-top: -5px;
  inset-block-start: 50%;
  inset-inline-start: 100%;
  border-color: transparent transparent transparent v-bind(color);
}

.tooltip--right::after {
  margin-top: -5px;
  inset-block-start: 50%;
  inset-inline-end: 100%;
  border-color: transparent v-bind(color) transparent transparent;
}

.tooltip--top::after {
  inset-block-start: 100%;
  inset-inline-start: 50%;
  margin-left: -5px;
  border-color: v-bind(color) transparent transparent  transparent;
}

.tooltip--bottom::after {
  margin-left: -5px;
  inset-block-end: 100%;
  inset-inline-start: 50%;
  border-color: transparent transparent v-bind(color) transparent;
}

</style>
