import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { event } from 'vue-gtag';

export const logGoogleAnalyticsEvent = async (
  _isWeb: boolean,
  eventName: string,
  params: object = {},
): Promise<void> => {
  if (Capacitor.getPlatform() == 'web') {
    event(eventName, params);
  } else {
    await FirebaseAnalytics.logEvent({
      name: eventName,
      params,
    });
  }
};
