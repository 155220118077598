/* eslint-disable max-len */
import { bannersService } from '@pidz/api';
import { mapMessageToBanner, mapBannerToText, findHighestRankedMessage } from '@pidz/utils';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export default defineStore('banners', () => {
// banners die andere overrulen. uitgeschreven, handmatig inactief, en inactief door onbetaalde facturen
const globalBanners = ['unsubscribed', 'manual_inactive', 'invoice']

const bannersFromAPI = ref<BannersAPI | undefined>();
const systemNotifications = ref<Banner[] | undefined>();

const fetchBanners = async () => {
  bannersFromAPI.value = await bannersService.fetchBanners();
  if (bannersFromAPI.value) mapBannersToRoutes(bannersFromAPI.value);
}

const bannersPerRoute = ref<BannersPerRoute>({
  global: [],
  dashboard: [],
  resume: [],
  profile: []
})

const mapBannersToRoutes = (banners: BannersAPI) => {
  resetBannersPerRoute();

  bannersPerRoute.value.global = bannersFromAPI.value?.inactiveReasons.filter(item => 
    globalBanners.includes(item)) || [];

  // If profile or resume has (nearly) inactive, add to dashboard, because this is a different message on a different page
  bannersPerRoute.value.dashboard = banners.inactiveReasons;
  if (banners.resume.includes('resume_items_expired') || banners.profile.includes('profile_files_expired')) {
    bannersPerRoute.value.dashboard = [...bannersPerRoute.value.dashboard, 'dashboard_expired'];
  }
  if (banners.resume.includes('resume_items_nearly_expired') || banners.profile.includes('profile_files_nearly_expired')) {
    bannersPerRoute.value.dashboard = [...bannersPerRoute.value.dashboard, 'dashboard_nearly_expired'];
  }

  bannersPerRoute.value.resume = banners.resume;
  bannersPerRoute.value.profile = banners.profile;

  // no_healthcare_provider_portal is a special case on which the user is inactive, but needs to take action on the resume page
  if (banners.inactiveReasons.includes('no_healthcare_provider_portal')) {
    bannersPerRoute.value.resume = [...banners.resume, 'no_healthcare_provider_portal'];
  }
}

const fetchSystemNotifications = async () => {
  const notifications = await bannersService.getSystemNotifications();
  const urgentNotifications = notifications.filter((item: SystemNotification) => item.type === 'urgent');
  systemNotifications.value = urgentNotifications.map(item => ({
    type: 'warning',
    title: item.title ?? '',
    text: item.text ?? ''
  }) as Banner);
}

const showTimeZoneBanner = computed(() => DateTime.now().setZone('local').zoneName !== DateTime.now().zoneName);

const getBannerPerRoute = (route: BannerRoutes): Banner[] | undefined => {
  if (bannersPerRoute.value.global.length) return findBannerText(bannersPerRoute.value.global);

  if (route === 'dashboard') {
    let banners = <Banner[]>[];
    if (showTimeZoneBanner.value) banners = [...banners, ...findBannerText(['timezone']) ?? []];
    if (systemNotifications.value && systemNotifications.value[0]) banners.push(systemNotifications.value[0]);
    if (!bannersPerRoute.value.global.length) banners = [...banners, ...findBannerText(bannersPerRoute.value.dashboard) ?? []];

    return banners;
  }

  if (route === 'resume') return findBannerText(bannersPerRoute.value.resume);
  if (route === 'profile') return findBannerText(bannersPerRoute.value.profile);

  return undefined;
}

const findBannerText = (messages: MessageName[]): Banner[] | undefined => {
  const mostImportantMessage = findHighestRankedMessage(messages);
  if (!mostImportantMessage) return;

  const bannerName = mapMessageToBanner(mostImportantMessage);
  if (!bannerName) return;

  const bannerText = mapBannerToText.get(bannerName);
  if (bannerText) return [bannerText];

  return;
};

const resetBannersPerRoute = () => {
  bannersPerRoute.value = {
    global: [],
    dashboard: [],
    resume: [],
    profile: []
  };
}

const $reset = () => {
  bannersFromAPI.value = undefined;
  systemNotifications.value = undefined;
  resetBannersPerRoute();
}

return {
  bannersFromAPI,
  fetchBanners,
  fetchSystemNotifications,
  systemNotifications,
  bannersPerRoute,
  getBannerPerRoute,
  $reset
};

});
