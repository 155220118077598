<template>
  <modal class="feedback-sent-modal">
    <h2 class="font-bold">Beoordeling verstuurd!</h2>
    <p>Je beoordeling houdt ons scherp, dank je wel!</p>
    <p v-if="suggestAppStoreRating && platform !== 'web'">
      Wil je de wereld laten weten wat je van MijnPIDZ vindt? Laat dan een
      review achter in de {{ appStoreMap.get(platform ?? 'ios')?.storeName }}.
    </p>
    <template #footer>
      <button class="button-outline" @click="closeModal">Sluit</button>
      <button v-if="suggestAppStoreRating && platform !== 'web'"
              @click="openAppStore">
        Naar {{ appStoreMap.get(platform ?? 'ios')?.storeName }}
      </button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal from '@app/components/Modal.vue';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { useModalController } from '@pidz/utils';
import { computed } from 'vue';

const props = defineProps<{ rating: number }>();
const modalController = useModalController();
const platform = Capacitor.getPlatform();

const suggestAppStoreRating = computed(() => props.rating <= 2);

const closeModal = () => modalController.close();

const appStoreMap = new Map([
  [
    'ios',
    {
      appLink:
        'https://apps.apple.com/nl/app/pidz-zzpers-in-de-zorg/id977584459',
      storeName: 'App Store',
    },
  ],
  [
    'android',
    {
      appLink:
        'https://play.google.com/store/apps/details?id=com.pidzsoftware.pidzmobileapp',
      storeName: 'Play Store',
    },
  ],
]);

const openAppStore = () => {
  closeModal();
  Browser.open({ url: appStoreMap.get(platform)?.appLink ?? '#' });
};
</script>
