<template>
  <div class="w-full flex flex-col flex-grow">
    <reload-page :reload="reload"
                 :loading="loading"/>
    <template v-if="invoiceData">
      <div class="flex w-full justify-between items-center bg-blue text-white px-4 pb-2 md:py-4">
        <div class="flex flex-col">
          <h1 class="font-bold text-base break-words">{{ invoiceData?.organisation_name }}</h1>
          <h2 class="text-sm break-words">{{ invoiceData?.department_name }}</h2>
        </div>
        <download-invoice-menu v-if="invoiceData" :invoice="invoiceData" />
      </div>
      <div class="invoice-detail-page flex flex-col flex-grow w-full">
        <p-banner v-if="isCreditInvoice"
                  type="error"
                  :text="`Deze factuur (${
                    invoiceData.invoice_number || creditInvoiceNumber
                  }) is gecrediteerd. Wijzig je urenregistratie en verstuur opnieuw.`" />
        <p-banner v-if="showSent"
                  type="info"
                  :text="`Deze factuur is ${daysAgo(DateTime.fromISO(invoiceData.sent_date))} verstuurd.`"/>
        <layout-desktop-wrapper no-padding-bottom>
          <div class="month">{{ month }}</div>
          <freelancer-invoice-detail-summary-section :proforma="invoiceData.proforma"/>
          <freelancer-invoice-detail-proforma-section :proforma="invoiceData.proforma"/>
          <freelancer-invoice-detail-total-section :proforma="invoiceData.proforma"/>
          <router-link class="link text-blue" :to="`/sheets/${route.params.id}`">Bekijk urenregistratie</router-link>
          <div class="flex-grow"></div>
          <freelancer-invoice-drawer v-model="invoiceNumber"
                                     :set-paid-status="setPaidStatus"
                                     :send-invoice-reminder="sendInvoiceReminder"
                                     :send-invoice="sendInvoice"
                                     :invoice-data="invoiceData"
                                     :is-credit-invoice="isCreditInvoice"
                                     :is-credit-invoice-paid="isCreditInvoicePaid"
                                     :is-factoring-company-unavailable="isFactoringCompanyUnavailable"
                                     :credit-invoice-number="creditInvoiceNumber"/>
        </layout-desktop-wrapper>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
/* eslint-disable max-lines */
// TODO: CID-11049 refactor freelance-invoice-drawer
import ExceptionModal from '@app/components/ExceptionModal.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import resetAllOverviewPages from '@app/utils/reset-all-overview-pages';
import { daysAgo } from '@pidz/date';
import { InvoiceStatus } from '@pidz/enums';
import { useInvoice } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import DownloadInvoiceMenu from './components/DownloadInvoiceMenu.vue';
import FreelancerInvoiceDetailProformaSection from './components/FreelancerInvoiceDetailProformaSection.vue';
import FreelancerInvoiceDetailSummarySection from './components/FreelancerInvoiceDetailSummarySection.vue';
import FreelancerInvoiceDetailTotalSection from './components/FreelancerInvoiceDetailTotalSection.vue';
import FreelancerInvoiceDrawer from './components/FreelancerInvoiceDrawer.vue';
import InvoicePaymentModal from './components/InvoicePaymentModal.vue';
import InvoicePrefinanceChoiceModal from './components/InvoicePrefinanceChoiceModal.vue';
import InvoiceSentSuccessModal from './components/InvoiceSentSuccessModal.vue';

const invoiceStore = useInvoice();
const modal = useModalController();
const route = useRoute();
const sheetId = computed(() => Number(route.params.id));
const invoiceData = computed(() => invoiceStore.invoices[sheetId.value]);
const month = computed(() => {
  return invoiceData.value?.month
    ? DateTime.fromISO(invoiceData.value?.month)
        .setLocale('nl-nl')
        .toLocaleString({
          month: 'long',
        })
    : undefined;
});

const loading = ref(false);

const hasInvoicePaymentStatusLoaded = ref(false);

const isCreditInvoicePaid = ref<boolean>(true);
const isFactoringCompanyUnavailable = ref(false);

const invoiceNumber = ref(invoiceData.value?.invoice_number ?? '');

const isCreditInvoice = computed(() =>
  invoiceData.value
    ? [InvoiceStatus.CREDITED, InvoiceStatus.FORCED_CREDITED].includes(
        invoiceData.value.status,
      )
    : false,
);

const creditInvoiceNumber = computed(() => {
  const creditedInvoices = invoiceData.value!.bundle_invoices;
  const creditedInvoicesWithInvoiceNumber = [...creditedInvoices].filter(
    ({ invoice_number }) => invoice_number,
  );

  const lastCreditedInvoiceWithInvoiceNumber =
    creditedInvoicesWithInvoiceNumber.pop();
  if (!lastCreditedInvoiceWithInvoiceNumber) {
    return 'Geen factuurnummer beschikbaar';
  }

  return lastCreditedInvoiceWithInvoiceNumber.invoice_number;
});

const showSent = computed(() =>
  invoiceData.value
    ? [
        InvoiceStatus.INVOICED,
        InvoiceStatus.REMINDER_1,
        InvoiceStatus.REMINDER_2,
        InvoiceStatus.REMINDER_3,
      ].includes(invoiceData.value.status)
    : false,
);

const fetchCreditInvoicePaidStatus = async () => {
  if (
    isCreditInvoice.value &&
    invoiceData.value?.prefinance_company === 'factoring_company'
  ) {
    try {
      isCreditInvoicePaid.value =
        await invoiceStore.factoringCompanyCreditIsPaid(
          invoiceData.value.invoice_id,
        );
      if (route.params.payment) {
        modal.open(InvoicePaymentModal, { success: isCreditInvoicePaid.value });
      }
    } catch {
      isCreditInvoicePaid.value = false;
      isFactoringCompanyUnavailable.value = true;
    }
  }
};

const reload = async () => {
  loading.value = true;
  const newInvoice = await invoiceStore.getInvoice(sheetId.value);

  if (!invoiceNumber.value) {
    invoiceNumber.value = newInvoice.invoice_number;
  }

  await fetchCreditInvoicePaidStatus();
  hasInvoicePaymentStatusLoaded.value = true;
  loading.value = false;
};

const setPaidStatus = async () => {
  const updatedPaymentStatus = !(
    invoiceData.value?.status === InvoiceStatus.PAID
  );

  try {
    const { invoice_id: invoiceId } = invoiceData?.value ?? {};
    if (!invoiceId) {
      throw new Error();
    }
    await invoiceStore.changeFreelancerInvoicePaidStatus(
      invoiceId,
      updatedPaymentStatus,
    );
    resetAllOverviewPages();
    await reload();
  } catch (e: any) {
    modal.open(ExceptionModal, {
      message:
        'Wij hebben de betaalstatus van de factuur helaas niet kunnen wijzigen.',
    });
  }
};

const sendInvoiceFinal = async (preFinance: boolean) => {
  try {
    await invoiceStore.sendInvoiceById(
      sheetId.value,
      preFinance,
      invoiceData.value?.automatic_invoice_number ? null : invoiceNumber.value, // auto invoice numbers
    );
    logCustomEvent('invoice_sent', { prefinance: preFinance });
    modal.open(InvoiceSentSuccessModal);
  } catch (e: any) {
    // handled by global error handler
  } finally {
    resetAllOverviewPages();
    await reload();
  }
};

const sendInvoice = async () => {
  if (invoiceData.value?.prefinance_qualification === 'prefinance_choice') {
    modal.open(InvoicePrefinanceChoiceModal, {
      sendInvoiceFinal,
      prefinanceCompany: invoiceData.value.prefinance_company,
    });
  } else {
    await sendInvoiceFinal(false);
  }
};

const sendInvoiceReminder = async () => {
  await invoiceStore.sendInvoiceReminder(invoiceData.value!.invoice_id);
  resetAllOverviewPages();
  await reload();
};

onMounted(() => {
  reload();
});
</script>

<style lang="scss">
.invoice-detail-page {
  .month {
    padding: 16px;
    padding-bottom: 6px;
    color: var(--pidz-body-text);
  }

    section {
      margin-bottom: 24px;
      box-shadow: var(--shadow-light);

      & > * {
        border-bottom: 1px solid var(--pidz-grey-light);
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  
  .bold {
    font-weight: bold;
  }
  .link {
    padding: 16px;
  }
}
</style>
