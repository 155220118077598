<template>
  <div class="standard-rate__form-row">
    
    <div class="standard-rate__form-row--title">
      <strong>{{ rateItem.function_name }}</strong>
    </div>
    <p-form :key="formData.values.rate"
            ref="standardRatesForm"
            :form-data="formData"
            :disable-form="isDisabled || isSubmitting"
            :disable-buttons="true"/>
    <span :class="['standard-rate__form-row--optimize', {disabled: standardRatesForm?.errors?.rate}]"
          @click="onOptimiseRate(rateItem)" >
      Optimaliseer je tarief
    </span>
  </div>
</template>
<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useRatesStore } from '@pidz/stores';
import { formatPrice, useModalController } from '@pidz/utils';
import { computed, ref } from 'vue';
import { Router, useRouter } from 'vue-router';
import NudgingModal from '../../../general/modals/NudgingModal.vue';


interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<StandardRateForm>;
  isDisabled?: boolean;
  rateItem: Rate;
}

const props = withDefaults(defineProps<Props>(), {
  isWeb: false,
  isEdit: false,
  isDisabled: false,
});

const modalController = useModalController();
const onOptimiseRate = (rateItem: Rate) => {
    if (standardRatesForm?.value.errors.rate) return
    modalController.open(NudgingModal, {
      rateItem: {...rateItem, rate: parseFloat(standardRatesForm.value.values.rate.replace(',', '.'))},
      onSave: (newRate: number) => {
        standardRatesForm.value.setFieldValue('rate', formatPrice(newRate).toString());
        modalController.close();
      }
    });
};

const router: Router = useRouter();

const standardRatesForm = ref<any>();

const isValid = computed(
  () =>
    standardRatesForm.value?.meta.valid &&
    !standardRatesForm.value?.isValidating,
);

const ratesStore = useRatesStore();

const {
  isSubmitDisabled,
  isSubmitting,
  onCancel,
  succesToaster,
  warnBeforeCancel,
} = useFormFunctions(
  standardRatesForm,
  [],
  () => rerouteToOverview(router, 'rates'),
  () => Promise.resolve(),
  props.isEdit,
  'standaard tarieven',
);

const formSubmitted = ref(false);

const onSubmit = async () => {
  const submit = standardRatesForm?.value?.handleSubmit(
    async (formValues: StandardRateForm) => {
      if (
        standardRatesForm.value?.isValidating ||
        !standardRatesForm.value?.meta.valid
      ) {
        return;
      }

      const item: Rate = {
        ...props.rateItem,
        rate: parseFloat(formValues.rate.replace(',', '.')),
        periodical_rate: parseFloat(
          formValues.periodical_rate.replace(',', '.'),
        ),
      };

      if( item.freelancer_function_rate_id ) {
        await ratesStore.updateFreelancerFunctionRate(item);
      }
      else {
        await ratesStore.addFreelancerFunctionRate(item);
      }

      formSubmitted.value = true;
    },
  );

  if (submit) {
    await submit();
  }
};

const isChanged = computed(() => standardRatesForm.value?.meta.dirty);
const isChangedAndValid = computed(() => !isSubmitDisabled.value);

defineExpose({
  isValid,
  isChanged,
  isChangedAndValid,
  isSubmitting,
  onCancel,
  onSubmit,
  succesToaster,
  warnBeforeCancel,
});
</script>
<style scoped lang="scss">
.standard-rate__form-row {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--pidz-grey-light);

  &:last-of-type {
    padding: 0;
    margin: 0;
    border: none;
  }

  &--title {
    margin-bottom: 8px;
  }

  &--optimize {
    color: var(--pidz-blue);
    font-size: 14px;
    display: block;
    margin-top: -8px;
    &.disabled {
      color: var(--pidz-grey-light);
    }
  }
}
</style>
