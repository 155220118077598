<template>
  <h2>Er geldt een afwijkende OvO</h2>
  <p>
    Deze zorginstelling hanteert een
    <in-app-browser-link :href="mondriaanOvOUrl" class="link">afwijkende OvO</in-app-browser-link>.
  </p>
</template>
<script lang="ts" setup>
import InAppBrowserLink from '@app/components/InAppBrowserLink.vue';

// eslint-disable-next-line vue/max-len
const mondriaanOvOUrl = 'https://mijnpidz.nl/build/files/Overeenkomst%20van%20opdracht%20Mondriaan_ZZP%20V2023-04-04.pdf';
</script>

<style lang="scss">
.link {
  text-decoration: underline;
}
</style>
