import { capitalizeString } from '@pidz/date';
import { DateTime, Info } from 'luxon';

export const daysMap = new Map<number, string>([
  [1, 'Maandag'],
  [2, 'Dinsdag'],
  [3, 'Woensdag'],
  [4, 'Donderdag'],
  [5, 'Vrijdag'],
  [6, 'Zaterdag'],
  [7, 'Zondag'],
]);

export const dayPartsMap = new Map<number, string>([
  [1, 'Ochtend'],
  [2, 'Middag'],
  [3, 'Avond'],
  [4, 'Nacht'],
]);

const generateMonths = (year: number) => Array.from({ length: 12 })
  .map((_, index) => ({
    name: new Date(year, index).toLocaleString('nl-NL', { month: 'short' }).split('.')[0],
    value: `${year}-${index + 1}`,
  }));

const generateYears = (start:number, offset:number) => (
  {
    year: start + offset,
    months: generateMonths(start + offset),
  });

export const generateMonthOptions = (from: number, to: number) => {
  const yearDifference = to - from;
  return Array.from({ length: yearDifference }, (_, i) => generateYears(from, i));
};

export const renderDays = (weekday: number, preSelectedEvents?: number[]) => 
Info.weekdays('short', { locale: 'nl-NL' }).map((day, index) => {
  const weekdayOfStartDate = weekday - 1;
  return ({
    index,
    value: capitalizeString(day),
    checked: weekdayOfStartDate === index || (!!preSelectedEvents && preSelectedEvents.includes(index)),
    disabled: weekdayOfStartDate === index,
  });
});

export const getWeeksinYear = (year: number) => {
  const dateObj = DateTime.local(year, { zone: 'Europe/Amsterdam' });
  return dateObj.weeksInWeekYear;
};

export const getWeeknumbers = (year: number) => {
  const totalWeeks = getWeeksinYear(year);
  return Array.from({ length: totalWeeks }).map((_, i) => (i + 1));
};
