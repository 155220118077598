import { invoiceService, dashboardService, feedbackService } from '@pidz/api';
import { useUserStore } from '@pidz/stores';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('dashboard', () => {
  const openInvoiceCount = ref<number|null>(null);
  const invitationCount = ref<number|null>(null);
  const upcomingShifts = ref<CalendarEvent[]|null>(null);
  const preventFeedbackModal = ref<boolean>(false);

  const filterPayableInvoices = (invoices: SoftwareInvoice[]) => invoices.filter((invoice) => invoice.payable === true);

  const getOpenInvoiceCount = async () => {
    const openInvoices = await invoiceService.getSoftwareInvoices({
      invoiceStatuses: [1, 2, 3, 7, 10],
      count: false,
    });
    return filterPayableInvoices(openInvoices.data).length;
  }

  const fetch = async () => {
    upcomingShifts.value = await dashboardService.getCalendarShifts();
    invitationCount.value = await dashboardService.getNotificationCount('shift_invitation')
  }
  
  const fetchUnpaidInvoices = async () => {
    openInvoiceCount.value = useUserStore().isUserAgencyWorker ? null : await getOpenInvoiceCount();
  }

  const getPeriodicalReview = async (): Promise<boolean | Feedback> => {
    return await feedbackService.getPeriodicalReview();
  }

  const updateReview = async (review: Feedback) => {
    feedbackService.updateReview(review);
  }

  const $reset = () => {
    openInvoiceCount.value = null;
    invitationCount.value = null;
    upcomingShifts.value = null;
    preventFeedbackModal.value = false;
  }

  return {
    openInvoiceCount,
    invitationCount,
    upcomingShifts,
    preventFeedbackModal,
    fetch,
    getPeriodicalReview,
    updateReview,
    fetchUnpaidInvoices,
    $reset
  }
});
