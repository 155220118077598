import { useUserStore } from '@pidz/stores'
import { useRoute } from 'vue-router'

export const checkPermissionSection = (permissionName: string): boolean => {
  const route = useRoute()
  const userPermission = useUserStore().permissions?.find(permission => permission.name === route.name)
  if(userPermission && userPermission?.sections) {
    const sectionPermission = userPermission.sections.find(permission => permission.name === permissionName)
    return sectionPermission ? sectionPermission.view : true
  }
  return true
}

export const checkPermissionPage = (pageName: string): boolean => {
  const userPermission = useUserStore().permissions?.find(permission => permission.name === pageName)
  if(userPermission) return userPermission.view
  return true
}