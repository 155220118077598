// this file shows the importance of banners (which should be shown first)
const messageTierlist: MessageName[] = [
  'unsubscribed',
  'manual_inactive',
  'timezone',
  // 'set_travel_time',
  // 'set_rates',
  'missing_assets',
  'vog_document',
  'coc_document',
  'insurance_document',
  'complaints_document',
  'invalid_cv',
  'upload_diploma',
  'add_workexperience',
  'big_registration',
  'add_languageskill_dutch',
  'competency_experience',
  'no_healthcare_provider_portal',
  'renew_assets',
  'profile_files_expired',
  'resume_items_expired',
  'dashboard_expired',
  'profile_files_nearly_expired',
  'resume_items_nearly_expired',
  'dashboard_nearly_expired',
  'set_automatic_payment_choice',
  // 'no_functions',
  // 'no_sectors',
  'wait_approve_assets'
];

export const findHighestRankedMessage = (messageArray: MessageName[]) => {
  let bestIndex: number | undefined;
  messageArray.forEach((message) => {
    if (messageTierlist.indexOf(message) === -1) return; // if it doesn't exist in the list, skip it

    if (bestIndex === undefined || bestIndex > messageTierlist.indexOf(message)) {
      bestIndex = messageTierlist.indexOf(message)
    };
  });

  if (bestIndex !== undefined) return messageTierlist[bestIndex];
  
  return undefined;
}
