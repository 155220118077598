import { useCVStore } from '@pidz/stores';
import { OTHER_SUBJECT_ID } from './const';
import { otherSubjectField } from './form-fields';

export const toggleOtherSubjectsField = (
  value: number,
  form,
  other_subject: string,
) => {
  if (value === OTHER_SUBJECT_ID) {
    otherSubjectField.value.active = true;
    form?.value?.setFieldValue('other_subject', other_subject);
  } else if (otherSubjectField.value.active) {
    form?.value?.setFieldValue('other_subject', undefined);
    otherSubjectField.value.active = false;
  }
};

export const resetOtherSubjectField = () =>
  (otherSubjectField.value.active = false);

export const setYearsValid = (value: number): number | null => {
  const validityMonths = useCVStore().trainingSubjects?.find(
    (training) => training.id === value,
  )?.validity_months;
  return validityMonths ? validityMonths / 12 : 2;
};
