<template>
  <section ref="section" class="content-section">
    <div v-if="slots.header" class="content-section__header">
      <slot name="header" />
      <p-button v-if="hasAddButton"
                class="content-section__add-button"
                variant="ghost"
                @click="emit('add')">
        <p-icon icon-name="add" size="large"
                color="blue"/>
        <span class="content-section__add-text">Toevoegen</span>
      </p-button>
    </div>
    <div class="content-section__content">
      <div v-if="!hasContentSlot" class="empty-holder">
        <slot name="empty" />
      </div>
      <slot v-else name="content" />
      <div v-if="isShowmoreVisible && isFadeOutVisible"
           class="content-section__fade-out"/>
    </div>
    <div v-if="slots.footer || isExpandable"
         class="content-section__footer">
      <slot name="footer" />
      <div v-if="isShowmoreVisible" ref="showMore"
           class="content-section__show-more"
           @click="isExpanded = !isExpanded">
        <p-button variant="ghost"
                  class="content-section__show-more-button">
          {{ isExpanded ? "Toon minder" : "Toon alles" }}
          <p-icon icon-name="chevron"
                  class="content-section__show-more-icon"
                  color="blue"/>
        </p-button>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { useSlots, computed, ref } from 'vue';

const emit = defineEmits(['add', 'expand', 'collapse']);
const slots = useSlots();
const props = withDefaults(
  defineProps<{
    hasAddButton?: boolean;
    isExpandable?: boolean;
    maxHeight?: number;
  }>(),
  {
    hasAddButton: false,
    isExpandable: false,
    maxHeight: 720
  }
);

const hasContentSlot = computed(() => {
  if (slots.content) return slots.content().length > 0;
  return false;
});

const isExpanded = ref(false);
const section = ref<HTMLElement>();
const isShowmoreVisible = computed(
  () => props.isExpandable && (section.value?.clientHeight || 0) > props.maxHeight
);
const isFadeOutVisible = computed(
  () => isShowmoreVisible.value && !isExpanded.value
);

const contentHeight = computed(() => (isShowmoreVisible.value && !isExpanded.value ? `${props.maxHeight}px` : 'auto'));
const iconRotation = computed(() => (isExpanded.value ? '180deg' : '0deg'));
const contentMargin = computed(() => (slots.footer ? '24px' : '0'));

const showMore = ref<HTMLElement>();
</script>

<style lang="scss" scoped>
.content-section {
  display: flex;
  flex-direction: column;
  container-type: inline-size;

  &__header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: (8px * 3);
  }
  &__content {
    padding: 0;
    max-height: v-bind(contentHeight);
    overflow-y: clip;
    position: relative;
    font-size: 14px;
    margin-bottom: v-bind(contentMargin);

    @media screen and (min-width: 1024px) {
      font-size: 16px;
    }
    @media screen and (min-width: #{768px}) {
      margin: 0px 0px 0px 3rem;
    }
  }
  &__fade-out {
    background: linear-gradient(transparent, white 100%);
    height: 120px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    box-sizing: border-box;
  }
  &__show-more-button {
    color: var(--pidz-blue);
    padding: 0px 16px !important;
    height: 44px;
    gap: 5px;
  }
  &__show-more-icon {
    rotate: v-bind(iconRotation);
    transition: 0.3s;
  }
  &__show-more {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--pidz-blue);
  }

  &__add-button {
    display: flex;
    gap: 10px;
  }

  &__add-text {
    color: var(--pidz-blue);
  }

  .empty-holder {
    text-align: center;
    color: var(--pidz-grey-dark);
  }

  @media screen and (max-width: 480px) {
    &__add-text {
      display: none;
    }
  }
}
</style>
