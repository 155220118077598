import { useApi } from '../../utils/use-api';

const api = useApi();

export const versionService = {
  getUpdateRequired: (version:string): Promise<{ updateRequired:boolean }> => api.rest({
    method: 'get',
    url: `/version/update-required/${version}`, 
    isMicro: true
  }),
}
