<template>
  <layout-desktop-wrapper class="cv-wrapper">
    <reload-page :reload="reload" :loading="loading" />
    <p-banner-list v-if="resumeDoneLoading"
                   :banners="cvBanners"
                   class="w-full"/>

    <section v-if="resumeDoneLoading" class="content w-full">
      <user-section :profile-picture="profilePicture"
                    :is-badge-visible="!profilePicture"
                    :name="userStore.getFullName()"
                    :experience="resume?.experience_years"/>

      <div class="relative">
        <p-button @click="(e: Event) => dropdown.openDropdown(e)">
          <p-icon icon-name="download"
                  size="large"
                  color="white"
                  class="mr-2"/>
          download
        </p-button>
        <download-cv-dropdown ref="dropdown"
                              :position="{ height: 52, left: 0 }"
                              class="max-w-96"/>
      </div>

      <functions-section :functions="freelancer?.functions"
                         :is-badge-visible="cvStore.visibleBadges.functions"/>

      <sectors-section :sectors="freelancer?.sectors"
                       :is-badge-visible="cvStore.visibleBadges.sectors"/>

      <work-experience-section :experiences="resume?.experiences"
                               :is-badge-visible="cvStore.visibleBadges.workExperience"
                               @add-work-experience="handleRoute('work-experience', RouteType.ADD)"
                               @edit-work-experience="
                                 (id: number) => handleRoute('work-experience', RouteType.EDIT, id)
                               "/>

      <education-section :educations="resume?.educations"
                         :is-badge-visible="cvStore.visibleBadges.educations"
                         @add-education="handleRoute('education', RouteType.ADD)"
                         @edit-education="
                           (id: number) => handleRoute('education', RouteType.EDIT, id)
                         "/>

      <training-section :trainings="resume?.trainings"
                        :is-badge-visible="cvStore.visibleBadges.trainings"
                        @add-training="handleRoute('training', RouteType.ADD)"
                        @edit-training="
                          (id: number) => handleRoute('training', RouteType.EDIT, id)
                        "/>

      <competencies-section v-if="cvStore.resume?.competencies.length"
                            :grouped-competencies="resume?.groupedCompetencies"
                            :is-badge-visible="cvStore.visibleBadges.compentency"
                            @edit-competency="
                              (id: number) => handleRoute('competencies', RouteType.EDIT, id)
                            "/>

      <registration-section :is-badge-visible="
                              cvStore.visibleBadges.registrations ||
                                bannersStore.bannersPerRoute['resume'].includes(
                                  'no_healthcare_provider_portal',
                                )
                            "
                            :registrations="resume?.registrations"
                            @add-registration="handleRoute('registration', RouteType.ADD)"
                            @edit-registration="
                              (id: number) => handleRoute('registration', RouteType.EDIT, id)
                            "/>

      <languages-section :is-badge-visible="cvStore.visibleBadges.languages"
                         class="languages-section"
                         :language_skills="resume?.language_skills"
                         @add-language="handleRoute('languages', RouteType.ADD)"/>
    </section>
  </layout-desktop-wrapper>
</template>
<script setup lang="ts">
import ReloadPage from '@app/components/ReloadPage.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { RouteType } from '@pidz/enums';
import {
  UserSection,
  FunctionsSection,
  SectorsSection,
  WorkExperienceSection,
  EducationSection,
  TrainingSection,
  LanguagesSection,
  CompetenciesSection,
  RegistrationSection,
  DownloadCvDropdown,
} from '@pidz/shared/components';
import { useBannersStore, useCVStore, useUserStore } from '@pidz/stores';
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const initialLoad = ref(true);
const loading = ref(false);
const dropdown = ref();

const cvStore = useCVStore();
const userStore = useUserStore();
const bannersStore = useBannersStore();
const router = useRouter();

const resume = computed(() => cvStore.resume);
const freelancer = computed(() => userStore.freelancer);
const profilePicture = computed(() => userStore.profilePicture);
const cvBanners = computed(() => bannersStore.getBannerPerRoute('resume'));
const resumeDoneLoading = computed(
  () => initialLoad.value && resume.value && freelancer.value,
);

const handleRoute = (section: string, routeType: RouteType, id?: number) => {
  router.push({
    name: `cv ${section} ${routeType}`,
    params: { id },
  });
};

const loadStores = async (reload = false) => {
  loading.value = true;
  await Promise.all([
    userStore.fetchFreelancerData(reload),
    cvStore.loadResume(reload),
    cvStore.loadLanguages(),
    bannersStore.fetchBanners(),
  ]);
};

const reload = async () => {
  loading.value = true;
  await loadStores(true);
  loading.value = false;
};

onMounted(async () => {
  await loadStores();
  loading.value = false;
  initialLoad.value = true;
});
</script>

<style lang="scss" scoped>
.cv-wrapper {
  position: relative;
  :deep(.dropdown-list) {
    left: 0px;
    width: calc(100% - 82px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  background-color: var(--pidz-white);
  padding: 25px 25px 75px 25px;

  .timeline {
    margin-left: -2.3rem;
  }
}

.cv {
  &__profile-row {
    position: relative;
  }
}

:deep(.content-section:first-of-type) {
  z-index: 1;
}
</style>
