<template>
  <div class="shift-diff-card">
    <header>
      <h3>{{ title }}</h3>
    </header>
    <main>
      <template v-if="changes">
        <span v-for="(val, name, i) in orderedChanges" :key="i"
              class="row">
          <strong>{{ formatChanges.get(name as string)?.label }}</strong>
          {{ val !== null ? formatChanges.get(name as string)?.format(val) : '-' }}
        </span>
      </template>
    </main>
  </div>
</template>
<script lang="ts" setup>
import { formatFullDate, daysMap, dayPartsMap } from '@pidz/date';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { ChangeSetValues, ShiftFunction } from '../types';

const props = defineProps<{
  title: string;
  changes: { [key: string]: ChangeSetValues };
}>();

// By default the changes are ordered in the way back-end returns them
// By doing this, force them in a certain order. Can be tweaked manually to include other fields
const orderedChanges = computed(() => ({
  start: props.changes.start,
  end: props.changes.end,
  ...props.changes
}))

const mapStatusToValue = (item: number, valueMap: Map<number, string>) => valueMap.get(item);
const formatDate = (isoString: string) => formatFullDate(DateTime.fromISO(isoString));
const formatFunctions = (functions: ShiftFunction[]) =>
  functions.map((func) => func.name).join(', ');
const formatBoolean = (condition: boolean, truthyVal: string, falsyVal: string) =>
  condition ? truthyVal : falsyVal;
const formatHours = (hours: number) => `${hours} ${hours > 1 ? 'uren' : 'uur'}`;
const formatStatusArray = (array: number[], valueMap: Map<number, string>) =>
  array.length > 0 ? array.map((status) => mapStatusToValue(status, valueMap)).join(', ') : null;

const formatChanges = new Map<string, { label: string; format: Function }>([
  [
    'start',
    {
      label: 'Van:',
      format: (isoDateString: string) => formatDate(isoDateString),
    },
  ],
  [
    'end',
    {
      label: 'Tot:',
      format: (isoDateString: string) => formatDate(isoDateString),
    },
  ],
  ['description', { label: 'Omschrijving:', format: (description: string) => description }],
  [
    'functions',
    {
      label: 'Functie:',
      format: (functions: ShiftFunction[]) => formatFunctions(functions),
    },
  ],
  [
    'virusShift',
    {
      label: 'COVID-19:',
      format: (virusShift: boolean) => formatBoolean(virusShift, 'Ja', 'Nee'),
    },
  ],
  [
    'subtype',
    {
      label: 'Slaapdienst:',
      format: (shiftType: number) => formatBoolean(shiftType === 2, 'Ja', 'Nee'),
    },
  ],
  [
    'requirements',
    {
      label: 'BHV Certificaat:',
      format: (reqs: { [key: string]: boolean }) =>
        formatBoolean(reqs?.['asset_type.bhv'], 'Ja', 'Nee'),
    },
  ],
  [
    'average_hours_per_shift',
    {
      label: 'Gemiddelde uren per opdracht:',
      format: (hrs: number) => formatHours(hrs),
    },
  ],
  ['hours_per_week', { label: 'Uren per week:', format: (hours: number) => formatHours(hours) }],
  [
    'week_days',
    {
      label: 'Dagen:',
      format: (days: number[]) => formatStatusArray(days, daysMap),
    },
  ],
  [
    'day_parts',
    {
      label: 'Dagdelen:',
      format: (dayParts: number[]) => formatStatusArray(dayParts, dayPartsMap),
    },
  ],
]);
</script>
<style lang="scss">
.shift-diff-card {
  border: 1px solid var(--pidz-grey-light);
  border-radius: 5px;
  color: var(--pidz-body-text);

  header {
    border-bottom: 1px solid var(--pidz-grey-light);
    padding: 16px 24px;

    h3 {
      padding: 0;
      margin: 0;
      font-size: 14px;
    }
  }

  main {
    padding: 16px 20px;

    .row {
      padding: 4px 0px;
      display: block;
    }

    span {
      padding: 8px 0px;
    }
  }
}
</style>
