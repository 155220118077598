/* eslint-disable max-len */
import { ratesService } from '@pidz/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('rates', () => {
  const freelancerRates = ref<Rate[]>();

  const fetchFreelancerFunctionRates = async () => {
    if(!freelancerRates.value) freelancerRates.value = await ratesService.getFreelancerFunctionRates();
  };

  const fetchFreelancerFunctionRatesById = async (params: {organisationId?: number | string, departmentId?: number | string}) => {
    return await ratesService.getFreelancerFunctionRates(params);
  };

  const addFreelancerFunctionRate = async (
    freelancerFunctionRateModel: Rate,
  ) => {
    const newRate = await ratesService.addFreelancerFunctionRate(
      freelancerFunctionRateModel,
    );
    if(newRate.organisation_id){
      freelancerRates.value = [...(freelancerRates.value ?? []), newRate];
    }
    else {
      const index = (freelancerRates.value || []).findIndex(
        (r) => r.function_id === newRate.function_id,
      );
      if (index !== -1) freelancerRates.value![index] = newRate;
    }
  };

  const updateFreelancerFunctionRate = async (
    freelancerFunctionRateModel: Rate,
  ) => {
    const rate = await ratesService.updateFreelancerFunctionRate(
      freelancerFunctionRateModel,
    );
    const index = freelancerRates.value!.findIndex(
      (r) => r.freelancer_function_rate_id === rate.freelancer_function_rate_id,
    );
    freelancerRates.value![index] = rate;
  };

  const removeFreelancerFunctionRate = async(freelancerFunctionRateId): Promise<void> => {
    await ratesService.removeFreelancerFunctionRate(freelancerFunctionRateId);

    if (!freelancerRates.value) return;

    const index = freelancerRates.value.findIndex(rate => rate.freelancer_function_rate_id === freelancerFunctionRateId);
    if (index !== -1) freelancerRates.value.splice(index, 1);
  }

  const getFreelancerStandardFunctionRates = () =>
    freelancerRates.value &&
    freelancerRates.value.filter((rate) => !rate.organisation_id);

  const getFreelancerDifferentFunctionRates = () =>
    freelancerRates.value &&
    freelancerRates.value.filter((rate) => rate.organisation_id);

  const getOrganisationsWithDifferentRates = () => {
    if (!freelancerRates.value) return;

    const differentRates = freelancerRates.value.filter((rate) => rate.organisation_id && !rate.department_id);

    const uniqueDepartmentIds: number[] = [];

    differentRates.forEach((rate) => {
      if (!rate.organisation_id) return;

      if (uniqueDepartmentIds.includes(rate.organisation_id)) return;
      uniqueDepartmentIds.push(rate.organisation_id)
    });

    return uniqueDepartmentIds;
  }

  const getDepartmentsWithDifferentRates = (organisationId: number) => {
    if (!freelancerRates.value) return;

    const differentRates = freelancerRates.value.filter((rate) => rate.organisation_id === organisationId);

    const uniqueDepartmentIds: number[] = [];

    differentRates.forEach((rate) => {
      if (!rate.department_id) return;

      if (uniqueDepartmentIds.includes(rate.department_id)) return;
      uniqueDepartmentIds.push(rate.department_id)
    });

    return uniqueDepartmentIds;
  }

  const getDifferentRatesById = (organisationId: number, departmentId?: number): Rate[] | undefined => {
    if (!freelancerRates.value) return;

    if (!departmentId) {
      return freelancerRates.value.filter((rate) => 
        rate.organisation_id === organisationId && !rate.department_id);
    }

    return freelancerRates.value.filter((rate) => 
      rate.organisation_id === organisationId && rate.department_id === departmentId);
  }

  // Nudge
  const nudgesByFunctionId = ref<Record<number, Nudge[]>>({});
  const fetchNudgesByFunctionId = async (functionId: number) => {
    const nudge = await ratesService.getNudgesForFunction(functionId);

    nudgesByFunctionId.value[functionId] = nudge;

    return nudge;
  };

  const getNudgesByFunctionId = async (functionId: number) => {
    if (nudgesByFunctionId.value[functionId])
      return nudgesByFunctionId.value[functionId];

    return await fetchNudgesByFunctionId(functionId);
  };

  const $reset = () => {
    freelancerRates.value = undefined;
  };

  return {
    // state
    freelancerRates,
    nudgesByFunctionId,

    // fetch
    fetchFreelancerFunctionRates,
    fetchFreelancerFunctionRatesById,
    fetchNudgesByFunctionId,

    // getters
    getNudgesByFunctionId,
    getFreelancerStandardFunctionRates,
    getFreelancerDifferentFunctionRates,
    getOrganisationsWithDifferentRates,
    getDepartmentsWithDifferentRates,
    getDifferentRatesById,

    // actions
    updateFreelancerFunctionRate,
    addFreelancerFunctionRate,
    removeFreelancerFunctionRate,

    $reset,
  };
});
