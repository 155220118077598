import useNotificationsStore from '@app/stores/notifications';
import { Preferences } from '@capacitor/preferences';
import { Auth } from '@pidz/api';
import { UserLoginState } from '@pidz/enums';
import { useUserStore } from '@pidz/stores';
import { getActivePinia } from 'pinia';
import { UserState } from '.';

const keep = ['environment', 'interface'];

const resetPersonalPiniaStores = () => {
  // eslint-disable-next-line no-underscore-dangle
  const stores = (getActivePinia() as any)?._s;
  if (!stores) { return; }
  [...stores.values()]
    .filter((s) => !keep.includes(s.$id))
    .forEach((s) => s.$reset());
};

export default (state:UserState, updateUser:Function) => {
  const mutableState:UserState = state;

  const removeImpersonationId = () => {
    window.impersonationId = undefined;
  }  

  const clearFreelancerInfo = async () => {
    resetPersonalPiniaStores();
    mutableState.assumeUser = null;
    removeImpersonationId();
  };

  const logoutToImpersonation = async () => {
    await clearFreelancerInfo();
    await updateUser();
  };

  const clearPreferences = async () => {
    const preferencesToRemove = (await Preferences.keys()).keys.filter((key) => !keep.includes(key));
    preferencesToRemove.forEach((key) => Preferences.remove({ key }));
  };

  const logoutCompletely = async () => {
    await useNotificationsStore().unregisterDevice();
    await Auth.signOut();
    await clearPreferences();
    await clearFreelancerInfo();

    mutableState.loginState = UserLoginState.LoggedOut;
  };

  const logout = async () => {
    if (useUserStore().isUserAdmin && state.loginState !== UserLoginState.LoggedInAdmin) {
      await logoutToImpersonation();
    } else {
      await logoutCompletely();
    }
  };

  return {
    logout,
  };
};
