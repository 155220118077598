<template>
  <div class="w-full flex flex-col relative"
       :class="[
         {
           'overflow-hidden': overflowStatus,
           'pointer-events-none': noPointerEvents,
         },
       ]">
    <div class="w-full flex flex-1">
      <sidebar :menu-open="sidebarMenuOpen" @close-menu="closeSidebarMenu" />
      <div ref="layoutContent"
           class="layoutContent w-full min-h-full flex flex-col items-center 
           bg-blue-100 relative contain-strict overflow-y-auto overflow-x-hidden overscroll-none">
        <topbar :menu-open="topbarMenuOpen"
                @close-menu="closeTopbarMenu"
                @toggle-menu="toggleTopbarMenu"/>
        <span class="absolute z-20 w-full bg-neutral opacity-0
        left-0 right-0 top-14 bottom-0 pointer-events-none transition-opacity duration-300"
              :class="[
                {
                  'pointer-events-auto !opacity-25':
                    sidebarMenuOpen || topbarMenuOpen,
                },
              ]"
              :style="{'height': backgroundMenuOpenHeight + 'px'}"
              @click="() => {
                closeSidebarMenu();
                closeTopbarMenu();
              }"></span>
        <div ref="content" class="w-full flex flex-col flex-1">
          <router-view />
        </div>
      </div>
    </div>
    <bottombar v-if="bottomBarVisible"
               :menu-open="sidebarMenuOpen"
               @close-menu="closeSidebarMenu"
               @toggle-menu="toggleSidebarMenu"/>
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useReloadStore } from '@pidz/stores';
import { computed, ref } from 'vue';
import { onMounted } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import Bottombar from './sections/Bottombar.vue';
import Sidebar from './sections/Sidebar.vue';
import Topbar from './sections/Topbar.vue';

const router = useRouter();
const reloadStore = useReloadStore();

const layoutContent = ref<HTMLElement>();
const content = ref<HTMLElement>();
router.afterEach(() => {
  if (layoutContent.value) layoutContent.value.scrollTop = 0;
});

const backgroundMenuOpenHeight = ref<number>(0); 

const overflowStatus = computed(() => reloadStore.isPulling);
const noPointerEvents = computed(() => reloadStore.isPulling);

const topbarMenuOpen = ref(false);
const toggleTopbarMenu: () => void = () => {
  sidebarMenuOpen.value = false
  topbarMenuOpen.value = !topbarMenuOpen.value
  setBackgroundHeight();
};
const closeTopbarMenu: () => void = () => (topbarMenuOpen.value = false, setBackgroundHeight());

const sidebarMenuOpen = ref(false);
const toggleSidebarMenu: () => void = () => {
  topbarMenuOpen.value = false
  sidebarMenuOpen.value = !sidebarMenuOpen.value
  setBackgroundHeight();
};
const closeSidebarMenu: () => void = () => (sidebarMenuOpen.value = false, setBackgroundHeight());

const setBackgroundHeight = () => {
  if(topbarMenuOpen.value || sidebarMenuOpen.value) {
    backgroundMenuOpenHeight.value = content.value?.clientHeight ?? 0;
  } else {
    setTimeout(() => backgroundMenuOpenHeight.value = 0, 300);
  }
}

const bottomBarVisible = ref(true);

onMounted(() => {
  if (Capacitor.isPluginAvailable('Keyboard')) {
    Keyboard.addListener('keyboardWillShow', () => bottomBarVisible.value = false);
    Keyboard.addListener('keyboardWillHide', () => bottomBarVisible.value = true);
  }
})
</script>
