<template>
  <section ref="pageWrapper" class="department-tab">
    <layout-desktop-wrapper>
      <div class="department-description wysiwyg-text" v-html="departmentInformation"></div>
      <div v-if="department.files.length !== 0" class="department-files">
        <shift-detail-row-icon v-for="(file, index) in department.files"
                               :key="index"
                               class="download-link"
                               :icon="DownloadIcon"
                               :text="file.name"
                               @click="downloadDepartmentDocument(file)"/>
      </div>
    </layout-desktop-wrapper>
  </section>
</template>
<script lang="ts" setup>
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { Department, DepartmentFile } from '@app/pages/shifts/types';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { DownloadIcon } from '@pidz/icons';
import { useShiftsStore } from '@pidz/stores';
import { base64ToBlob } from '@pidz/utils';
import DOMPurify from 'dompurify';
import { computed, ref, watch } from 'vue';
import useIsElementInView from '../use-is-element-is-in-view';
import ShiftDetailRowIcon from './ShiftDetailRowIcon.vue';

const shiftsStore = useShiftsStore()
const props = defineProps<{
  department: Department;
}>();

const pageWrapper = ref<HTMLElement>();
const elementIsInView = useIsElementInView(pageWrapper, 0.1);
const departmentTabEventSent = ref(false);
let timeout;

const emit = defineEmits(['departmentTabViewed']);

const emitDepartmentPageViewed = () => {
  departmentTabEventSent.value = true;
  emit('departmentTabViewed');
};

watch(elementIsInView, async (newValue) => {
  if (newValue && !departmentTabEventSent.value) {
    timeout = setTimeout(() => {
      emitDepartmentPageViewed();
    }, 5000);
  } else {
    clearTimeout(timeout);
  }
});

const downloadDepartmentDocumentWeb = async (document: {
  name: string;
  data: string;
} | null) => {
  if (document !== null) {
    const blob = base64ToBlob(document.data);
    const url = window.URL.createObjectURL(blob);
    await Browser.open({ url });
  }
};

const downloadDepartmentDocumentNative = async (document: {
  name: string;
  data: string;
} | null) => {
  if (document !== null) {
    const writeFileResult = await Filesystem.writeFile({
      path: document.name,
      data: document.data,
      directory: Directory.Documents,
    });
    await FileOpener.open({ filePath: writeFileResult.uri });
  }
};

const downloadDepartmentDocument = async (file: DepartmentFile) => {
  const document = await shiftsStore.downloadDocument(file.document_id);
  const deviceInfo = await Device.getInfo();

  if (deviceInfo.platform === 'web') {
    await downloadDepartmentDocumentWeb(document);
  } else {
    await downloadDepartmentDocumentNative(document);
  }
};

const departmentInformation = computed(() => DOMPurify.sanitize(props.department.description));
</script>
<style lang="scss">
.department-tab {
  .department-description {
    background-color: var(--pidz-white);
    padding: 12px;
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
  }

  .department-files {
    box-shadow: var(--shadow-light);
    margin-top: 24px;
  }
}
</style>
