<template>
  <div class="w-full flex flex-col flex-grow" :data-dynamic-prevent-pull="preventPullToRefresh">
    <reload-page :reload="reload" :loading="forceReload" />
    <search-bar  v-model="request.searchString"
                 storage-key="sheets"
                 filter-button
                 :has-filter="filterStore.isFilterApplied"
                 @open-filter="filterOpen = true"/>
    <layout-desktop-wrapper v-if="empty">
      <p class="text-center">Er zijn geen resultaten gevonden</p>
    </layout-desktop-wrapper>
    <div v-else class="flex-grow contain-strict">
      <virtual-scroller :items="sheetOverviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        smooth
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="setScrollPosition"/>

    </div>
    <sheet-filter v-if="filterOpen"
                  v-model="filterStore.filters"
                  @close="filterOpen = false"
                  @reset="onFilterReset"
                  @update="onFilterUpdate"/>
  </div>
</template>

<script setup lang="ts">
import ReloadPage from '@app/components/ReloadPage.vue';
import SearchBar from '@app/components/SearchBar.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import VirtualScroller from '@app/components/virtualscroller/VirtualScroller.vue';
import { useSheetFilterStore, useSheetStore } from '@pidz/stores';
import {
  computed, reactive, ref, watch, onBeforeUnmount,
} from 'vue';
import SheetFilter from './SheetFilter.vue';

const sheetStore = useSheetStore();

const filterOpen = ref(false);
const filterStore = useSheetFilterStore();

const sheetOverviewItems = computed(() => sheetStore.sheetOverviewItems);

const scrollPosition = ref(sheetStore.sheetOverviewScrollPosition);

const endOfList = ref(false);
const forceReload = ref(false);
const loading = ref(false);
const preventPullToRefresh = ref(false);
const empty = ref(false);

const request = reactive({
  limit: 10,
  offset: sheetOverviewItems.value.length,
  searchString: sheetStore.sheetSearchString,
});

const getSheetsParams = computed(() => {
  const { offset, limit, searchString } = request;
  const { statuses, searchMonths } = filterStore.filters;

  return {
    offset,
    limit,
    statuses,
    searchString: searchString !== '' ? searchString : undefined,
    searchMonths: searchMonths.length > 0 ? searchMonths : undefined,
  };
});

const loadNext = async () => {
  if (loading.value) return;
  
  loading.value = true;

  const params = getSheetsParams.value;
  const newSheets = await sheetStore.getSheets(params);

  request.offset += request.limit;
  endOfList.value = newSheets.length !== request.offset;
  loading.value = false;

  empty.value = newSheets.length === 0;
};

const reload = async (type: string = 'pull') => {
  await sheetStore.clearSheets();

  forceReload.value = type === 'pull';

  request.offset = 0;
  await loadNext();

  forceReload.value = false;
};

const onFilterUpdate = async () => {
  filterOpen.value = false;
  reload('normal');
};

const onFilterReset = () => {
  filterStore.reset();
  onFilterUpdate();
};

watch(
  () => request.searchString,
  () => reload('normal'),
);

const setScrollPosition = (emittedScrollPosition: number) => {
  sheetStore.sheetOverviewScrollPosition = emittedScrollPosition;
};

onBeforeUnmount(() => {
  sheetStore.sheetSearchString = request.searchString;
});
</script>
