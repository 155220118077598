<template>
  <div class="search-history">
    <ul>
      <li v-for="(searchTerm, index) in searchHistory" :key="index"
          @click.self="setSearchTerm(searchTerm)">
        <time-icon />{{searchTerm}}<button @click="removeSearchTerm(index)"><close-icon /></button>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { Preferences } from '@capacitor/preferences';
import { TimeIcon, CloseIcon } from '@pidz/icons';
import {
  onMounted, onUnmounted, ref, watch,
} from 'vue';

const props = defineProps<{searchTerm: string, storageKey: string}>();
const emit = defineEmits(['setSearchTerm', 'closeSearchHistory']);
const searchHistory = ref<string[]>([]);

const setSearchTerm = (searchTerm: string) => {
  emit('setSearchTerm', searchTerm);
};
const removeSearchTerm = (index: number) => searchHistory.value.splice(index, 1);

watch(
  () => props.searchTerm,
  () => {
    if (props.searchTerm === '') { emit('closeSearchHistory'); return; }
    const searchTermIndex = searchHistory.value.findIndex((term) => term === props.searchTerm);
    if (searchTermIndex > -1) { removeSearchTerm(searchTermIndex); }
    searchHistory.value.unshift(props.searchTerm);
    searchHistory.value.splice(5);
    emit('closeSearchHistory');
  },
);

const loadHistoryFromSharedPreferences = async () => {
  const { value } = await Preferences.get({ key: `searchHistory.${props.storageKey}` });
  if (value) { searchHistory.value = JSON.parse(value); }
};

const saveHistoryToSharedPreferences = async () => {
  await Preferences.set({
    key: `searchHistory.${props.storageKey}`,
    value: JSON.stringify(searchHistory.value),
  });
};

onMounted(async () => {
  await loadHistoryFromSharedPreferences();
});

onUnmounted(async () => {
  await saveHistoryToSharedPreferences();
});

</script>
<style lang="scss">
  .search-history {
    color: var(--pidz-body-text);
    background-color: var(--pidz-white);
    box-shadow: var(--shadow-light);
    border-radius: 4px;
    z-index: 1;
    position: absolute;
    width: calc(100% - 24px);
    left: 0;
    right: 0;
    top: calc(100% + 8px);
    margin-left: auto;
    margin-right: auto;

    ul {
      margin: 0;
      padding: 0px 0;
      list-style-type: none;

      li {
        padding: 14px 14px;
        display: flex;
        align-items: center;
      }

      .pidz-icon {
        margin-right: 14px;
        color: var(--pidz-grey-dark);
      }
    }

    button {
      background-color: var(--pidz-body-text);
      border: none;
      border-radius: 50%;
      padding: 4px;
      display: flex;
      align-items: center;
      margin-left: auto;

      .pidz-icon {
        height: 10px;
        width: 10px;
        color: var(--pidz-white);
        margin: 0;
      }
    }
  }

</style>
