<template>
  <div v-if="currentShift" class="shift-banner-wrapper">
    <p-banner v-if="isFreelancerInactive" type="warning"
                 :text="freelancerInactiveMessage" />
    <p-banner v-if="currentShiftWorker && shouldShowShiftApprovalStatus &&
                   shiftApprovalStatusMap.get(currentShiftWorker.status) !== undefined"
                 :text="shiftApprovalStatusMap.get(currentShiftWorker.status)?.textLong ?? ''"
                 :type="shiftApprovalStatusMap.get(currentShiftWorker.status)?.type ?? ''"/>
    <p-banner v-if="currentShift.contract_overrides?.cancellation_period"
                 type="warning" :text="cancellationPeriodMessage" />
    <p-banner v-if="currentShift.contract_overrides?.dispute_period"
                 type="warning" :text="disputePeriodMessage" />
  </div>
</template>

<script lang="ts" setup>
import { ShiftApprovalStatus } from '@pidz/enums';
import { shiftApprovalStatusMap } from '@pidz/utils';
import { computed } from 'vue';
import {
  Shift, ShiftWorker,
} from '../types';

const props = defineProps<{
  currentShift: Shift,
  currentShiftWorker: ShiftWorker,
  isFreelancerInactive?: boolean,
}>();

const shouldShowShiftApprovalStatus = computed(() => (
  props.currentShiftWorker
  && props.currentShiftWorker.status !== ShiftApprovalStatus.FREELANCER_INVITED
));

const freelancerInactiveMessage = 'Je bent inactief, daarom kun je helaas geen nieuwe opdrachten accepteren.';

const cancellationPeriodMessage = computed(() => `De annuleringstermijn in de overeenkomst is door de zorginstelling aangepast 
  naar ${props.currentShift.contract_overrides?.cancellation_period} ${props.currentShift.contract_overrides?.cancellation_period === 1 ? 'dag' : 'dagen'}.`);

const disputePeriodMessage = computed(() => `De betwistingstermijn in de overeenkomst is door de zorginstelling aangepast 
  naar ${props.currentShift.contract_overrides?.dispute_period} ${props.currentShift.contract_overrides?.dispute_period === 1 ? 'dag' : 'dagen'}.`);

</script>
