<template>
  <layout-desktop-wrapper>
    <section class="px-4 py-6 bg-white">
      <h1 class="text-xl font-bold">Afwijkende tarieven</h1>
      <p class="text-xs">{{taxText}}</p>
      <p class="text-sm pt-4">{{ helperTextPerStage[rateStage - 1] }}</p>

      <p-stepper v-model="rateStage" 
                 :stepper-options="stepperOptions"
                 show-edit
                 :linear="false"
                 class="py-4"/>

      <section v-if="rateStage === 1 && organisationsDropdown">
        <p-combobox v-model="selectedOrganisation" 
                    :options="organisationsDropdown"
                    prepend-icon="search"
                    placeholder-text="Selecteer of zoek een zorginstelling"
                    @value:selected="updateStage(2)"/>
      </section>

      <section v-else-if="rateStage === 2 && departmentsDropdown">
        <p-combobox v-model="selectedDepartment" 
                    :options="departmentsDropdown"
                    prepend-icon="search"
                    placeholder-text="Selecteer of zoek een afdeling"
                    @value:selected="updateStage(3)"/>
      </section>

      <section v-else-if="rateStage === 3 && standardRates">
        <different-rates-form ref="form" 
                              :standard-rates="standardRates"
                              :organisation="selectedOrganisation!"
                              :department="selectedDepartment!" />
      </section>

      <loader v-else />
      
    </section>

    <form-buttons-row :on-cancel="() => rerouteToOverview(router, 'rates')" 
                      :on-submit="form?.onSubmit"
                      :is-loading="loading"
                      :is-submit-disabled="disabled"/>

  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import Loader from '@app/components/Loader.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { DifferentRatesForm } from '@pidz/shared/components'
import { CancelModal } from '@pidz/shared/components';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useClientsStore, useRatesStore, useUserStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave, Router, useRouter } from 'vue-router';

const clientsStore = useClientsStore();
const userStore = useUserStore();
const ratesStore = useRatesStore();
const modalController = useModalController();
const router: Router = useRouter();

const form = ref<typeof DifferentRatesForm>();

const formChanged = ref(false);
const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled || rateStage.value !== 3)

const rateStage = ref<1 | 2 | 3>(1);

const taxText = computed(() => userStore.freelancer?.vat_free ? '(BTW vrijgesteld)' : '(Excl. BTW)')

const helperTextPerStage = [
  'Kies voor welke zorginstelling je de afwijkende tarieven wilt instellen',
  'Kies voor welke afdelingen je de afwijkende tarieven wilt instellen',
  'Stel voor elke functie je incidentele en/of periodieke tarief in'
]

const selectedOrganisation = ref<ComboBoxOption | null>(null);
const organisations = computed(() => clientsStore.organisations);
const organisationsDropdown = computed(() => {
  if (!organisations.value) return;

  return organisations.value.map(item => ({ value: item.organisation_id, label: item.organisation_name})) || [];
});


const fetchOrganisations = async () => await clientsStore.fetchOrganisations();

const selectedDepartment = ref<ComboBoxOption | null>(null);

const departmentsDropdown = computed(() => {
  if (!departments.value || !departmentsWithDifferentRates.value) return;
  const temp = departments.value?.filter((item) => !departmentsWithDifferentRates.value!.includes(item.department_id));

  const result = temp.map(item => ({ value: item.department_id, label: item.department_name})) || [];
  result.unshift({ value: -1, label: 'Alle afdelingen' });

  return result;
});

const departmentsWithDifferentRates = ref<number[] | undefined>();
const departments = ref<Department[] | null>(null);

const standardRates = computed(() =>
  ratesStore.getFreelancerStandardFunctionRates(),
);

const stepperOptions = computed(() => [{
  step: 1,
  title: 'Kies zorginstelling',
  description: selectedOrganisation.value?.label,
  disabled: false
}, {
  step: 2,
  title: 'Kies afdeling',
  description: selectedDepartment.value?.label,
  disabled: selectedOrganisation.value ? false : true
}, {
  step: 3,
  title: 'Stel afwijkende tarieven in',
  disabled: selectedDepartment.value ? false : true
}]);

watch(
  () => selectedOrganisation.value,
  () => selectedDepartment.value = null,
);

const updateStage = async (stage: number) => {
  if (stage === 2) {
    rateStage.value = 2

    formChanged.value = true;

    if (!selectedOrganisation.value?.value) return;
    departments.value = await clientsStore.getDepartmentsByOrganisationId(selectedOrganisation.value.value as number);
    departmentsWithDifferentRates.value = ratesStore.
      getDepartmentsWithDifferentRates(selectedOrganisation.value.value as number);
  }
  if (stage === 3) {
    rateStage.value = 3;
  }
}

onMounted(async () => {
  if (!clientsStore.organisations) await fetchOrganisations();
})

const cancel = (route: Function) => {
  if (formChanged.value && !form.value?.formSubmitted) { // show modal if form is filled, but not when submitting
    modalController.open(CancelModal, {
      cancelEdit: () => {
        route()
      },
      continueEdit: () => {
        modalController.close();
      }
    });
  } else {
    route();
  }
}

onBeforeRouteLeave((_to, _from, next) => cancel(next));
</script>
