import { initializeApp, getRemoteConfig } from '@app/FirebaseProxy';
import { generateAcceptanceEnvironment, generateProductionEnvironment } from './configure-cognito';

export const firebaseApp = initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
});

const config = getRemoteConfig(firebaseApp);

config.defaultConfig = JSON.stringify({
  environments:
    [
      generateAcceptanceEnvironment(),
      generateProductionEnvironment(),
    ],
});

if (import.meta.env.DEV) {
  config.settings.minimumFetchIntervalMillis = 1000;
}

export default config;
