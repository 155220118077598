<template>
  <teleport to="body">
    <div class="pidz-modal-backdrop"
         data-prevent-pull
         v-on="!preventClose ? { click: closeModal } : {}">
      <div class="pidz-modal"
           v-bind="$attrs"
           @click.stop>
        <header v-if="!preventClose" class="pidz-modal-header">
          <nav>
            <p-icon class="close-icon" icon-name="close"
                    @click="closeModal"/>
          </nav>
        </header>
        <section class="pidz-modal-body">
          <slot />
        </section>
        <footer v-if="!customFooter || $slots.footer" class="pidz-modal-footer">
          <slot name="footer">
            <p-button @click="modalController.close">Sluit</p-button>
          </slot>
        </footer>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';
import { useModalController } from './utils/modal-controller'

const modalController = useModalController();

defineProps<{
  preventClose?: boolean;
  customFooter?: boolean;
}>();

const emit = defineEmits(['modalClosed']);

const closeModal = () => {
  emit('modalClosed');
  modalController.close();
};

onMounted(() => {
  document.body.classList.add('modal-open');
});

onUnmounted(() => {
  document.body.classList.remove('modal-open');
});

</script>
  <style lang="scss">
  .pidz-modal-backdrop {
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    position: fixed;
    display: flex;
    padding: 0 24px;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.35);

    .pidz-modal {
      font-family: Lato;
      padding: 24px;
      width: 100%;
      background-color: var(--pidz-white);
      box-shadow: var(--shadow-dark);
      max-height: calc(100% - 32px);
      overflow-y: auto;

      h1 {
        margin-bottom: 16px;
      }

      @media screen and (min-width: 480px) {
        max-width: 436px;
        padding: 32px;
        h1, h2 {
          margin-bottom: 32px;
        }
        .pidz-modal-body {
          padding: 8px 16px 16px;
        }
        .pidz-modal-footer {
          margin: 16px 16px 0px 16px;
        }
      }

      .pidz-modal-header {
        nav {
          display: flex;
          justify-content: flex-end;
          color: var(--pidz-blue);
          .close-icon {
            cursor: pointer;
          }
        }
      }

      h1, h2 {
        color: var(--pidz-blue);
      }
      h1 {
        font-size: 28px;
        margin-top: 0px;
        line-height: 2rem;
        font-weight: 400;
      }
      h2 {
        font-size: 28px;
        margin: 14px 0;
      }

      .pidz-modal-body {
        p {
          color: var(--pidz-body-text);
        }

        > :last-child {
          margin-bottom: 0;
        }
      }

      .pidz-modal-footer {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        column-gap: 24px;
      }
    }
  }
  </style>
