<template>
  <h2>Er geldt een afwijkende OvO</h2>
  <p>
    Deze zorginstelling hanteert een
    <in-app-browser-link :href="koraalOvOUrl" class="link">afwijkende OvO</in-app-browser-link>.
    De belangrijkste wijzigingen betreffen artikel 1 (m.b.t. ORT) en de toevoeging van artikel 9.7.
  </p>
</template>
<script lang="ts" setup>
import InAppBrowserLink from '@app/components/InAppBrowserLink.vue';

const koraalOvOUrl = 'https://mijnpidz.nl/build/files/Overeenkomst%20van%20opdracht%20Koraal_ZZP%20V2020-07-21.pdf';
</script>

<style lang="scss">
.link {
  text-decoration: underline;
}
</style>
