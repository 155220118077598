<template>
  <div class="container"
       :class="[
         { boxed: props.boxed },
         props.boxed ? props.background: '',
         props.size
       ]">
    <component :is="selectedIcon" class="pidz-icon"
               :class="[
                 boxed && background !== 'white' ? 'white' : props.color,

               ]"
               :color="color"/>
  </div>
</template>
<script lang="ts" setup>
import {
  defineAsyncComponent, computed, watch,
  shallowRef
} from 'vue';

interface Props {
  iconName?: string
  size?: string
  boxed?: boolean
  color?: string
  background?: string
  borderRadius?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  iconName: 'activity',
  size: 'medium',
  boxed: false,
  color: 'black',
  background: 'blue',
  borderRadius: true
});

// using a watcher here because the computed method is not reactive
const selectedIcon = shallowRef(defineAsyncComponent(async () => import(`./icons/${props.size}/${props.iconName}/${props.iconName}.vue`)));
watch(() => props.iconName, () => {
  selectedIcon.value = defineAsyncComponent(async () => import(`./icons/${props.size}/${props.iconName}/${props.iconName}.vue`));
});

const getBorderRadius = computed(() => (props.boxed && props.borderRadius ? '30px' : '5px 0 0 5px'));
const iconSize = computed(() => {
  if (props.size === 'small') return props.boxed ? '24px' : '16px';
  if (props.size === 'medium') return props.boxed ? '30px' : '24px';
  return props.boxed ? '56px' : '30px';
});

</script>
<style scoped lang="scss">
.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: v-bind(getBorderRadius);
    overflow: hidden;
    min-width: v-bind(iconSize);
    min-height: v-bind(iconSize);
    max-width: v-bind(iconSize);
    max-height: v-bind(iconSize);
    &.boxed {
      &.blue {
        background: var(--pidz-blue);
      }
      &.red {
        background: var(--pidz-red);
      }
      &.green {
        background: var(--pidz-green);
      }
      &.orange {
        background: var(--pidz-orange);
      }
      &.white {
        background: var(--pidz-white);
      }
      &.grey {
        background: var(--pidz-grey-dark);
      }

      &.small {
        width: 24px;
        height: 24px;
      }
      &.medium {
        width: 42px;
        height: 42px;
      }
      &.large {
        width: 56px;
        height: 56px;
      }
    }

  .pidz-icon {
    flex-grow: 1;
    &.blue {
      fill: var(--pidz-blue) !important;
    }
    &.black {
      fill: var(--pidz-black) !important;
    }
    &.white {
      fill: var(--pidz-white) !important;
    }
    &.green {
      fill: var(--pidz-green) !important;
    }
    &.red {
      fill: var(--pidz-red) !important;
    }
    &.orange {
      fill: var(--pidz-orange) !important;
    }
    &.light-grey {
      fill: var(--pidz-grey-light) !important;
    }
    &.grey {
      fill: var(--pidz-grey-dark) !important;
    }
  }
}
</style>
