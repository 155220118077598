/* eslint-disable max-lines */
import { faqService } from '@pidz/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('faq', () => {
  /************/
  // FAQ
  /************/
  const faq = ref<Faq[]>();
  const loadFaq = async (reload?: boolean): Promise<void> => {
    if (reload || !faq.value) faq.value = await faqService.getFaq();
  };

  const $reset = () => {
    faq.value = undefined;
  };

  return {
    // state
    faq,
    
    // load
    loadFaq,

    $reset,
  };
});
