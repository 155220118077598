<template>
  <modal>
    <h1 class="font-bold">Uitzonderingen vervallen</h1>
    <p>
      Eventuele uitzonderingen die je eerder gemaakt hebt op de agenda-reeks
      komen te vervallen.
    </p>
    <template #footer>
      <button class="button-outline"
              @click="cancel">Annuleren</button>
      <async-button @click="update">Oké, bewaren</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();
const props = defineProps<{ update: Function }>();

const cancel = () => {
  logCustomEvent('agendaitem_edit_recurrence_confirmation_cancel');
  modalController.close();
};

const update = async () => {
  try {
    await props.update();
  } finally {
    logCustomEvent('agendaitem_edit_recurrence_confirmation_save');
    modalController.close();
  }
};
</script>
<style lang="scss"></style>
