<template>
  <div class="banner-list">
    <p-banner v-for="(banner, index) in visibleBanners"
              :key="`banner_${index}`"
              :type="banner.type"
              :title="banner.title"
              :text="banner.text" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  banners: Banner[];
  maxBanners?: number;
}>(), {
  banners: () => [],
  maxBanners: 3
});

const visibleBanners = computed(() => props.banners.slice(0, props.maxBanners));
</script>

<style lang="scss">
.banner-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
}
</style>
