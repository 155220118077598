<template>
  <div class="shift-details">
    <section class="basic-details">
      <shift-detail-row-icon v-if="shiftWorker.shift_virus_shift" :icon="WarningIcon"
                             multi-line>
        <div class="bold">{{ virusShiftText.title }}</div>
        <div>{{ virusShiftText.body }}</div>
      </shift-detail-row-icon>
      <shift-detail-row-icon v-if="shiftWorker.is_sleep_shift"
                             :icon="GeneralSleepShiftBlue"
                             :text="nightShiftText.body"
                             multi-line
                             no-border/>
      <shift-detail-row-icon :icon="TimeIcon">
        <div>{{ shiftStartDate }}</div>
        <div>{{ shiftEnd }}</div>
      </shift-detail-row-icon>
      <shift-detail-row-icon :icon="LocationIcon" :text="address"
                             @click="openMaps()" />
      <shift-detail-row-icon :icon="CallIcon"
                             :text="`${shift.department.phone_number ?? 'Niet beschikbaar'}`"
                             @click="callDepartment()"/>
      <shift-detail-row-icon :icon="CarIcon" :text="travelTime" />
      <shift-detail-row-icon-expandable :icon="CompanyIcon">
        <template #title>{{ shift.department.owning_office.name }}</template>
        <template #bottom-row>
          <a :href="`tel:${shift.department.owning_office.phone_number}`" class="underline cursor-pointer">
            {{ shift.department.owning_office.phone_number }}
          </a>
          <a :href="`mailto:${shift.department.owning_office.email}`" class="underline cursor-pointer">
            {{ shift.department.owning_office.email }}
          </a>
        </template>
      </shift-detail-row-icon-expandable>
    </section>

    <section class="function-details">
      <shift-detail-row-text name="Functie" :value="shift.function_name" />
      <shift-detail-row-text :name="userStore.isUserAgencyWorker ? 'Uurloon' : 'Uurtarief'" :value="rate" />
      <shift-detail-row-text name="Pauze" :value="`${shift.break} minuten`" />
      <shift-details-periodical v-if="shift.type === ShiftType.TYPE_PERIODICAL" :shift="shift" />
      <shift-detail-row-text v-if="userStore.isUserFreelancer" name="Voorfinanciering"
                             :value="preFinanceToString" />
      <shift-detail-row-text name="ORT" :value="shift.premium_pay.description"
                             big column />
      <shift-detail-row-text v-if="shift.specific_description"
                             name="Specifieke Opdrachtomschrijving"
                             :value="shift.specific_description"
                             big
                             column/>
      <shift-detail-row-text v-if="shift.description"
                             name="Bijzonderheden"
                             :value="shift.description"
                             big
                             column/>
    </section>

    <section v-if="!userStore.isUserAgencyWorker || !isInvite" class="contract-details">
      <a v-if="userStore.isUserAgencyWorker" href="https://helloflexpeople.helloflex.com/user/login"
         target="_blank">
        <shift-detail-row-icon class="download-link" :icon="DownloadIcon"
                               text="Contract" />
      </a>
      <a v-else-if="shiftWorker.contract_id" @click="openContract()">
        <shift-detail-row-icon class="download-link" :icon="DownloadIcon"
                               text="Contract" />
      </a>
      <in-app-browser-link v-else :href="openModelContract">
        <shift-detail-row-icon class="download-link" :icon="DownloadIcon"
                               text="Contract" />
      </in-app-browser-link>
    </section>
  </div>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import InAppBrowserLink from '@app/components/InAppBrowserLink.vue';
import GeneralSleepShiftBlue from '@app/icons/GeneralSleepShiftBlue.vue';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { shiftService } from '@pidz/api';
import { localeString, formatHour } from '@pidz/date';
import { ShiftType, Prefinance, ShiftApprovalStatus, ShiftStatus } from '@pidz/enums';
import {
  TimeIcon,
  LocationIcon,
  CallIcon,
  CarIcon,
  CompanyIcon,
  DownloadIcon,
  WarningIcon,
} from '@pidz/icons';
import { useUserStore } from '@pidz/stores';
import { openFile } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { Shift, ShiftWorker } from '../types';
import ShiftDetailRowIcon from './ShiftDetailRowIcon.vue';
import ShiftDetailRowIconExpandable from './ShiftDetailRowIconExpandable.vue';
import ShiftDetailRowText from './ShiftDetailRowText.vue';
import ShiftDetailsPeriodical from './ShiftDetailsPeriodical.vue';

const userStore = useUserStore();

const props = defineProps<{
  shift: Shift;
  shiftWorker: ShiftWorker;
}>();

const virusShiftText = {
  title: 'Let op!',
  body: `Op deze afdeling is COVID-19 geconstateerd bij een of meerdere zorgvragers.`,
};
const nightShiftText = {
  body: `Dit is een slaapopdracht en dat betekent dat er standaard 4 uur in de nacht niet wordt betaald.`,
};
const shiftStartDate = computed(() =>
  localeString(
    DateTime.fromISO(props.shift.start),
    {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    { locale: 'nl' },
  ),
);

const platform = Capacitor.getPlatform();

const shiftEnd = computed(() => {
  if (props.shift.type === ShiftType.TYPE_PERIODICAL) {
    return localeString(
      DateTime.fromISO(props.shift.end),
      {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      { locale: 'nl' },
    );
  }

  const start = formatHour(DateTime.fromISO(props.shift.start));

  const end = formatHour(DateTime.fromISO(props.shift.end));

  return `${start} - ${end}`;
});

const address = computed(() => {
  const { street, house_number, zip_code, city } = props.shift.department;
  return `${street} ${house_number}, ${zip_code} ${city}`;
});

const travelTime = computed(() => {
  const time = props.shift.department.travel_time;
  return time ? `${Math.ceil(time / 60)} minuten reistijd` : 'reistijd onbekend';
});

const rate = computed(() => {
  const formatAsEuro = Intl.NumberFormat('nl-NL', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(props.shiftWorker.rate);
  return `€ ${formatAsEuro} ${
    userStore.isUserAgencyWorker ? '(bruto)' : 
    props.shiftWorker.vat_free ? 'vrijgesteld' : 
    'excl. 21% BTW'
  }`;
});

const isInvite = computed(() =>
    props.shiftWorker.status === ShiftApprovalStatus.FREELANCER_INVITED &&
    props.shiftWorker.shift_status === ShiftStatus.OPEN,
);

const preFinanceToString = computed((): string => {
  const status = new Map([
    [Prefinance.FORCE, 'Voorfinanciering verplicht'],
    [Prefinance.NONE, 'Geen voorfinanciering mogelijk'],
    [Prefinance.CHOICE, 'Keuze per factuur'],
  ]);

  return status.get(props.shift.pre_finance) || 'Onbekend';
});

const openMaps = async () => {
  let url = `https://maps.google.com/?q=${encodeURI(address.value)}`;
  if (platform === 'ios') {
    url = `comgooglemaps://?q=${encodeURI(address.value)}`;
    if (!(await AppLauncher.canOpenUrl({ url })).value) {
      url = `maps://?q=${encodeURI(address.value)}`;
    }
  }
  await AppLauncher.openUrl({ url });
};

const callDepartment = () => {
  if (props.shift.department.phone_number) {
    window.open(`tel:${props.shift.department.phone_number}`);
  }
};

const modelLink = new Map([
    ['intramural', 'https://mijnpidz.nl/build/files/Overeenkomst_van_opdracht_intramuraal.pdf'],
    ['extramural', 'https://mijnpidz.nl/build/files/Modelovereenkomst_extramuraal.pdf'],
  ]);

const getContractLink = async (id: number) => await shiftService.downloadShiftContract(id)

const openContract = async () => {
    const contract = await getContractLink(props.shiftWorker.contract_id)
    openFile(contract)
}

const openModelContract = computed(() => modelLink.get(props.shift.department.sector_type))

</script>
<style lang="scss">
.shift-details {
  section {
    margin-bottom: 24px;
    box-shadow: var(--shadow-light);

    .shift-details {
      text-decoration: none;
    }

    .download-link {
      color: var(--pidz-blue);
      text-decoration: underline;

      &:visited {
        color: var(--pidz-blue);
      }
    }
  }
}
</style>
