<template>
  <div class="w-full bg-blue md:bg-white md:border-b-2 md:border-solid md:border-neutral-200 
  text-white md:text-neutral min-h-14 px-4 sticky top-0 z-30 flex items-center">
    <div class="flex items-center min-w-10 md:min-w-0">
      <environment-indicator class="absolute left-2 top-2 md:hidden" />
      <pidz-logo v-if="route.name === 'dashboard'" class="md:hidden" />
      <button v-else-if="route.meta.backButton"
              class="flex items-center"
              @click="goBack()">
        <span class="hidden md:inline-flex md:mr-4">
          <p-icon icon-name="arrow" size="large"
                  color="black" />
        </span>
        <span class="flex md:hidden">
          <p-icon icon-name="arrow" size="large"
                  color="white" />
        </span>
      </button>
    </div>
    <div class="flex flex-grow items-center justify-center md:justify-start
    text-center md:text-left font-bold text-base">
      {{ route.meta.title }}
    </div>
    <div class="flex items-center justify-end">
      <div class="relative cursor-pointer" @click="$emit('toggleMenu')">
        <profile-picture v-model="user.profilePicture"/>
        <p-badge v-if="showProfileBadge || showCVBadge"
                 variant="warn"
                 content="!"
                 :bordered="false"
                 class="absolute -right-1 top-0"/>
        <p-badge v-if="user.isUserFreelancer || user.isUserAgencyWorker"
                 :variant="user.isUserFreelancer ? 'info' : 'success'"
                 :content="user.isUserFreelancer ? 'ZZP' : 'UZK'"
                 class="absolute right-3/4 top-0"/>
      </div>

      <nav class="absolute right-4 bg-white rounded-lg p-2 
      transition-all duration-300 flex flex-col top-full translate-y-4"
           :class="[
             { 'translate-y-4 opacity-100': menuOpen },
             { 'translate-y-0 opacity-0 pointer-events-none': !menuOpen },
           ]">
        <component :is="item.component"
                   v-for="(item, i) in menuItems"
                   :key="i"
                   :to="item.to"
                   class="flex items-center w-full gap-4 px-4 py-2 
                     text-neutral hover:text-blue-900 transition relative"
                   active-class="!text-blue-900 font-bold"
                   @click="$emit('closeMenu')">
          <span class="text-xs">{{ item.name }}</span>
          <p-badge v-if="item.openCount"
                   :content="item.openCount"
                   :variant="item.badgeVariant ? item.badgeVariant : 'info'"
                   class="-translate-x-3 -translate-y-1"/>
        </component>
        <!-- Logout -->
        <div class="flex items-center w-full gap-4 px-4 py-2 text-neutral hover:text-blue-900 transition cursor-pointer"
             @click="logout">
          <p-icon icon-name="logout" />
          <span class="text-xs">Uitloggen</span>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import EnvironmentIndicator from '@app/components/EnvironmentIndicator.vue';
import LogoutModal from '@app/components/LogoutModal.vue';
import PidzLogo from '@app/components/PidzLogo.vue';
import ProfilePicture from '@app/pages/dashboard/components/ProfilePicture.vue';
import { checkPermissionPage } from '@pidz/shared/utils';
import {
  useBannersStore,
  useFeatureflagsStore,
  useUserStore,
} from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const flagStore = useFeatureflagsStore();
const bannersStore = useBannersStore();
const user = useUserStore();
const router = useRouter();
const route = useRoute();
const modal = useModalController();

defineProps<{ menuOpen: boolean }>();
defineEmits(['closeMenu', 'toggleMenu']);

// router.afterEach(() => (menuOpen.value = false));

const goBack = () => {
  window.history.length > 2 ? router.go(-1) : router.push('/');
};

const logout = () => modal.open(LogoutModal);

const showProfileBadge = computed(
  () => bannersStore.getBannerPerRoute('profile')?.length,
);
const showCVBadge = computed(
  () => bannersStore.getBannerPerRoute('resume')?.length,
);
const preferencesFeatureFlag = computed(() =>
  flagStore.getStatus('ffe-preferences'),
);

const menuItems = computed<MenuItem[]>(() => [
  {
    name: 'Profiel',
    to: { name: 'profile' },
    openCount: showProfileBadge.value ? '!' : '',
    badgeVariant: 'warn',
    component: 'router-link',
  },
  {
    name: 'CV',
    to: { name: 'cv' },
    openCount: showCVBadge.value ? '!' : '',
    badgeVariant: 'warn',
    component: 'router-link',
  },
  ...(preferencesFeatureFlag.value
    ? [
        {
          name: 'Notificaties',
          to: { name: 'notifications' },
          component: 'router-link',
        },
        ...(checkPermissionPage('rates')
          ? [
              {
                name: 'Tarieven',
                to: { name: 'rates' },
                component: 'router-link',
              },
            ]
          : []),
        {
          name: 'Reistijd & koppelingen',
          to: { name: 'travel-time-clients' },
          component: 'router-link',
        },
      ]
    : []),
]);
</script>
