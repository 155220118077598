export default [
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@app/pages/contact/ContactPage.vue'),
    meta: { title: 'Contact' },
  },
  {
    name: 'report',
    path: '/report',
    component: () => import('@app/pages/contact/ContactFormPage.vue'),
    meta: { title: 'Misstand melden' },
  },
  {
    name: 'faq',
    path: '/faq',
    component: () => import('@app/pages/support/faq/FAQPage.vue'),
    meta: { title: 'FAQ' },
  },
]