<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="pidz-illustration"
       viewBox="0 0 215 206">
    <path fill="#E1F4FD" fill-rule="evenodd"
          d="M106.5 173.7a65.4 65.4 0 1 0 9.1-130.4 65.4 65.4 0 0 0-9.1 130.4Z" />
    <path fill="none" fill-rule="evenodd"
          stroke="#3E444D" stroke-width="1.6"
          d="M91.6 161.3c11.7-25.6 23.4-50.2 35-75.8" />
    <path fill="#005093" fill-rule="evenodd"
          d="M162.7 55.1c-4.7 8.8-34.3 25.7-34.3 25.7S126 47 130.5 38.3c4.7-8.7 15.7-12
        24.6-7.4a17.8 17.8 0 0 1 7.6 24.2" />
    <path fill="#894D80" fill-rule="evenodd"
          d="m129 78.3-6.6 6 8.1 3-1.5-9Z" />
    <path fill="none" fill-rule="evenodd"
          stroke="#3E444D"
          stroke-width="1.6" d="m90.4 160 12.3-76" />
    <path fill="#894D80" fill-rule="evenodd"
          d="M102.5 78.1s15.6-26.7 15.3-35.8c-.3-9-8-16.2-17.2-16-9.2.3-16.3
        7.9-16 17 .3 9 17.9 34.8 17.9 34.8Z" />
    <path fill="#894D80" fill-rule="evenodd"
          d="m102 77.3 4 7.1-8 .3 4-7.4Z" />
    <path fill="none" fill-rule="evenodd"
          stroke="#3E444D" stroke-width="1.6"
          d="m91.2 160.4
      25.2-83.5-25.2 83.5Z" />
    <path fill="#E3665F" fill-rule="evenodd"
          d="M106 23.7c-2 10.7 11.9 46.7 11.9 46.7s25.6-26.3
      27.7-37c2-10.9-5.2-21.7-16.2-24.4-11-2.7-21.4 4-23.4 14.7" />
    <path fill="none" fill-rule="evenodd"
          stroke="#3E444D" stroke-width="1.5"
          d="M86.4 203.7c-2.7-5.1 4.8-12.4-2.8-19.5m-8 16.5c3.8-3.8-.4-12.2 8-16.4" />
    <path fill="none" fill-rule="evenodd"
          stroke="#3E444D" stroke-width="1.5"
          d="M69 200.7c5.5-3.3 1.8-14.6 13.2-16.8" />
    <path fill="#894D80" fill-rule="evenodd"
          d="m119.3 69 3.2 9.6-9.6-1.9 6.4-7.7Z" />
    <path fill="#89D1F4" fill-rule="evenodd"
          d="m68.5 154.4-7.9 24 40.9 15 9.8-24-42.8-15Z" />
    <path fill="#005093" fill-rule="evenodd"
          d="m68.4 154.7 43.3 15.7-27.1 7.5-16.2-23.2Z" />
    <path fill="#E3665F" fill-rule="evenodd"
          d="M82.4 181.7s-3.1-8.9-3-10.8c0-.4 0-.8.2-1.2.7-1.4 2.2-2.4 4.5-1.4 2.4 1.1
        2.3 3.6 2.2 4.7.7-.8 2.5-2.6 5-1.5 2.3 1 2.5 2.8 2 4.2-.3.4-.5.8-.8 1a72 72 0 0 1-10 5" />
    <path fill="none" fill-rule="evenodd"
          stroke="#3E444D" stroke-width="1.5"
          d="M90.1 160.4c-1.3-21.7-24-10.7.7-.2m1 .3c1.2-1.3 2.1-3 3.7-3.8 22.5-9.5 9.6 15.1-2.8 3.8" />
    <path fill="#89D1F4" fill-rule="evenodd"
          d="M198.8 155.5h-44.5c-6.9 0-12.5-5.8-12.5-13 0-7.3 5.6-13.1 12.5-13.1 1.6 0 3.2.3 4.6.9a18
        18 0 0 1 17.4-17c8.4 0 15.4 6.3 17.1 14.7 2-1 4.1-1.6 6.4-1.6 7.7 0 14 6.5 14
        14.5a14.3 14.3 0 0 1-15 14.6Zm-183-50.3h44.5c7 0 12.5-5.8 12.5-13s-5.6-13-12.5-13a12
        12 0 0 0-4.6.8 18 18 0 0 0-17.4-17 18 18 0 0 0-17.1 14.7c-2-1-4-1.6-6.4-1.6-7.7
        0-14 6.5-14 14.6a14.3 14.3 0 0 0 15 14.5Z" />
  </svg>
</template>
