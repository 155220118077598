<template>
  <p-card :padding="padding"
          class="company" 
          title="Bedrijfsgegevens"
          has-button
          icon-name="pencil"
          icon-size="large"
          :rounded="false"
          title-size="lg"
          @button-click="emit('edit-company')">
    <template #content>
      <p-table class="company__table"
               :columns="columns"
               :rows="rows"
               :omit-header="true"
               :colgroup="[{ span: 1, width: '180px' }]"
               compact
               disable-horizontal-spacing>
        <template #row_type="row">
          <span class="company__type--bold">{{ row.value.type }}</span>
        </template>
        <template #row_value="row">
          <span class="company__value">{{ row.value.value || '-' }}</span>
        </template>
      </p-table>

      <section class="company__files">
        <div v-for="(fileInfo, index) in fileRows"
             :key="index"
             class="company__files--section">
          <span class="company__files--title">{{ fileInfo.type }}</span>

          <div class="company__files--filerow">
            <p-status v-if="fileInfo.files.length === 0"
                      :variant="3"
                      text-override="Ontbreekt"/>
            <p-status-row v-else 
                          :status="(isNearlyExpired(fileInfo.files)) ? 4 : fileInfo.value?.status"
                          :date="fileInfo.value?.date"
                          :file-text="fileInfo.value?.fileText"
                          :date-text="fileInfo.value?.dateText"
                          :alignment="fileRow"
                          :reverse="true"
                          @open-files="(e: Event) => handleFileToggle(fileInfo.files, e)"/>
          </div>
        </div>

        <div class="company__files--section">
          <span class="company__files--title">Factuurpapier (optioneel)</span>
          <div v-if="data.invoiceTemplate" class="company__files--filerow">
            <p-button class="company__files__button"
                      variant="ghost"
                      @click="previewInvoiceTemplate">
              <p-icon icon-name="file" size="medium"
                      color="blue" />
              <div class="company__files__button-text">Factuurpapier</div>
            </p-button>
          </div>
          <div v-else class="company__files--filerow">-</div>
        </div>
      </section>

      <p-dropdown-list v-if="dropdownFiles && dropdownFiles.length > 1"
                       :open="showDropDown"
                       :position="position"
                       @close="() => (showDropDown = false)">
        <template #items>
          <div v-for="(file, index) in dropdownFiles" :key="index">
            <p-file-list-item :file-name="file.filename"
                              :show-download-button="true"
                              @download="downloadFile(file)"/>
          </div>
        </template>
      </p-dropdown-list>
    </template>
  </p-card>
</template>
<script lang="ts" setup>
import { MimeType } from '@pidz/enums';
import { getMimeType, isNearlyExpired } from '@pidz/shared/utils';
import { useAssetStore } from '@pidz/stores';
import { convertToFileRowData, openFile } from '@pidz/utils';
import { computed } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  data: ProfileCompany;
  files: ProfileAssetsCompany;
  padding: string;
  fileRow: string;
}>();

const emit = defineEmits(['edit-company']);

const assetStore = useAssetStore();

const showDropDown = ref(false);
const position = ref<PidzDropdownListPosition>({ height: 0, left: 0 });

const columns = [
  { field: 'type', label: 'type' },
  { field: 'value', label: 'waarde' },
];

const rows = computed(() => [
  {
    type: 'Kvk nr.',
    value: props.data.cocNumber,
  },
  {
    type: 'Bedrijfsnaam',
    value: props.data.companyName,
  },
  {
    type: 'Website',
    value: props.data.website,
  },
  {
    type: 'BTW nr.',
    value: props.data.vatNumber,
  },
  {
    type: 'BTW vrijstelling',
    value: props.data.vatFree ? 'Ja' : 'Nee',
  },
]);

const fileRows = computed(() => [
  {
    type: 'KvK uittreksel',
    value: convertToFileRowData(props.files.cocDocument, 'KVK Uittreksel'),
    files: props.files.cocDocument,
  },
  {
    type: 'Certificaat klachtenregeling',
    value: convertToFileRowData(props.files.complaintsDocument, 'Certificaat'),
    files: props.files.complaintsDocument,
  },
]);

const previewInvoiceTemplate = async () => {
  const invoiceTemplate = await assetStore.fetchInvoiceTemplate();
  openFile(invoiceTemplate, MimeType.PDF);
};

const dropdownFiles = ref<Asset[] | undefined>();

const downloadFile = async (file: Asset) => {
  if ('id' in file && file.id) {
    const response = await assetStore.fetchAsset(file.id);
    const mimeType = getMimeType(file.filename);
    if (mimeType) openFile(response, mimeType);
  }
};

const handleFileToggle = (files: Asset[], e: Event) => {
  e.stopPropagation();

  if (files.length === 1) {
    downloadFile(files[0]);
    return;
  }

  dropdownFiles.value = files;

  const target = e.currentTarget as HTMLInputElement;
  const leftOffset = document.body.clientWidth > 425 ? target.offsetLeft : 0;
  const left =
    target.offsetLeft + 400 > (target?.offsetParent?.clientWidth || 0)
      ? undefined
      : leftOffset + 25;
  const topOffset = target.offsetTop;
  const buttonHeight = target.clientHeight;
  const height = topOffset + buttonHeight;

  position.value = { height, left };
  showDropDown.value = !showDropDown.value;
};
</script>
<style lang="scss" >
.company {
  &__table {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 16px;
  }

  &__type {
    &--bold {
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__files {
    &--section {
      padding: 16px 0 0 0;
      border-top: 1px solid var(--pidz-grey-light);
    }

    &--title {
      font-weight: bold;
      font-size: 14px;
    }
    &--filerow {
      margin: 16px 0;
    }

    &__button {
      display: flex;
      align-items: center;
    }
    &__button-text {
      margin-left: 10px;
      color: var(--pidz-blue);
      font-size: 14px;
    }
  }
}
</style>
