<template>
  <modal v-if="review"
         class="periodical-feedback-modal"
         custom-footer
         @modal-closed="setPreventFeedbackModal">
    <h2 class="font-bold">
      {{ userStore.freelancer?.user.first_name }}, hoe beoordeel je mijnPIDZ?
    </h2>
    <feedback-select v-model="review.rating" />
    <form v-if="review.rating"
          class="feedback-form"
          @submit.prevent>
      <label for="feedback-field"> Opmerking </label>
      <textarea id="feedback-field"
                v-model="review.body"></textarea>
    </form>
    <template v-if="review.rating"
              #footer>
      <async-button @click="sendReview">Verstuur</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { lazyMakeFeedback } from '@pidz/factories';
import { useUserStore, useDashboardStore } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { DateTime } from 'luxon';
import { reactive } from 'vue';
import { Feedback } from '../types';
import FeedbackSelect from './FeedbackSelect.vue';
import PeriodicalFeedbackSentModal from './PeriodicalFeedbackSentModal.vue';

const props = defineProps<{ periodicalReview: Feedback }>();

const dashboardStore = useDashboardStore();
const userStore = useUserStore();
const modalController = useModalController();

const setPreventFeedbackModal = () => {
  dashboardStore.preventFeedbackModal = true;
};
const review = reactive<Feedback>(lazyMakeFeedback());

const IsoOptions = { suppressMilliseconds: true };
const sendReview = async () => {
  Object.assign(review, {
    type: props.periodicalReview.type,
    UUID: props.periodicalReview.UUID,
    user_UUID: props.periodicalReview.user_UUID,
    date_created: props.periodicalReview.date_created,
    date_completed: DateTime.now().toISO(IsoOptions),
  });
  try {
    await dashboardStore.updateReview(review)
  } finally {
    modalController.open(PeriodicalFeedbackSentModal, {
      name: userStore.freelancer?.user.first_name,
      rating: review.rating,
    });
  }
};
</script>
<style lang="scss">
.periodical-feedback-modal {
  .modal-body {
    .feedback-form {
      margin-top: 24px;
      width: 100%;
      color: var(--pidz-black);

      label {
        font-size: 12px;
      }

      textarea {
        font: inherit;
        width: 100%;
        padding: 14px 12px;
        background-color: transparent;
        color: inherit;
        border: 1px solid var(--pidz-black);
        resize: none;
        border-radius: 5px;
      }
    }
  }
}
</style>
