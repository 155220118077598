<template>
  <div class="sheet-sleep-hour-editor">
    <label>Geef aan hoeveel van de slaapuren je hebt gewerkt.</label>
    <input-border
      multi
      dark>
      <time-icon />
      <input
        v-model="value"
        type="time"
        name="time" />
    </input-border>
  </div>
</template>
<script lang="ts" setup>
import InputBorder from '@app/components/InputBorder.vue';
import { deltaHoursMinutes, eventDateTimeToLuxon } from '@pidz/date';
import { TimeIcon } from '@pidz/icons';
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
  modelValue: any;
}>();

const value = computed({
  get() {
    const { start, end } = props.modelValue;
    const { hours, minutes } = deltaHoursMinutes(start, end);
    return [hours, minutes]
      .map((nr) => nr.toString().padStart(2, '0'))
      .join(':');
  },
  set(time: string) {
    const date = props.modelValue.end.split('T')[0];
    emit('update:modelValue', {
      ...props.modelValue,
      start: eventDateTimeToLuxon({date, time: '00:00'}),
      end: eventDateTimeToLuxon({date, time}),
    });
  },
});
</script>
<style lang="scss">
.sheet-sleep-hour-editor {
  input {
    padding-left: 12px;
    grid-column: 2/-1;
  }
  label {
    display: block;
    margin-bottom: 8px;
  }
  .input-border {
    margin-bottom: 24px;
  }
}
</style>
