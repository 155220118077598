enum SheetStatus {
  NEW_SHEET = 1,
  REQUEST_BY_FREELANCER = 2,
  APPROVED_BY_CUSTOMER = 3,
  DECLINED_BY_CUSTOMER = 4,
  INVOICED = 5,
  CLOSED = 6,
  CLOSED_WITHOUT_SHEETROW = 7,
}

export const sheetStatusInfo = new Map<SheetStatus, 
  { className: 'info' | 'success' | 'error' | 'warning'; text: string }>([
  [SheetStatus.NEW_SHEET, { className: 'info', text: 'Openstaand' }],
  [SheetStatus.REQUEST_BY_FREELANCER, { className: 'info', text: 'Wachten op goedkeuring' }],
  [SheetStatus.APPROVED_BY_CUSTOMER, { className: 'success', text: 'Urenregistratie goedgekeurd' }],
  [SheetStatus.DECLINED_BY_CUSTOMER, { className: 'warning', text: 'Urenregistratie afgekeurd' }],
  [SheetStatus.INVOICED, { className: 'success', text: 'Factuur verstuurd' }],
  [SheetStatus.CLOSED, { className: 'warning', text: 'Gesloten' }],
]);

// TODO: optimize this function
// it doesn't need to format all items
// it only needs to format the last 10 and 1 item before that
// isFormatted toevoegen en voordat je gaat formatten zet je de laatste op false
export const formatAllOverviewItems = (data: newOverviewItem[]): newOverviewItem[] => {
  const formattedOverviewItems = data;

  formattedOverviewItems.forEach((item, index) => {
    const lastItem = data[index - 1];
    const newItem = item;

    if (!lastItem || newItem.groupTitle !== lastItem.groupTitle) newItem.firstOfType = true;

    if (lastItem && newItem.groupTitle !== lastItem.groupTitle) {
      formattedOverviewItems[index - 1].lastOfType = true;
    } else if (lastItem) {
      formattedOverviewItems[index - 1].lastOfType = false;
    }

    return newItem;
  });

  formattedOverviewItems[formattedOverviewItems.length - 1].lastOfType = true;

  return formattedOverviewItems;
};
