<template>
  <calendar-header calendar-type="Week" 
                   :title="title"
                   :title-detail="titleDetail"
                   @move-date="onMoveDate"
                   @weekday="scrollToWeekDay"/>
</template>

<script setup lang="ts">
/* eslint-disable */
import { DateTime } from 'luxon'
import { useCalendar } from '@pidz/stores';
import { computed } from 'vue';
import CalendarHeader from './CalendarHeader.vue';

const calendarStore = useCalendar();

const scrollToWeekDay = (e: IsoWeekday) => {
  const days = document.querySelectorAll('.day');
  days[e].scrollIntoView({ behavior: 'smooth' });
};

const week = computed((): number => calendarStore.date.weekNumber);

const title = computed((): string => `Week ${week.value}`);

const isYearBoundary = (start: DateTime, end: DateTime) =>
  start.year !== end.year;
const isOtherYear = (end: DateTime) => end.year !== DateTime.now().year;

const titleDetail = computed(() => {
  const start = calendarStore.date.startOf('week');
  const end = calendarStore.date.endOf('week');
  const [startMonth, endMonth] = [start, end].map((d) =>
    d.toLocaleString({ month: 'short' }).toLowerCase()
  );

  const result =
    startMonth === endMonth
      ? `${start.day} - ${end.day} ${startMonth}`
      : `${start.day} ${startMonth} - ${end.day} ${endMonth}`;

  if (isYearBoundary(start, end) || isOtherYear(end)) {
    return `${result} ${end.year}`;
  }
  return result;
});

const onMoveDate = (dir: number) => {
  let date = calendarStore.date;
  if (dir > 0) {date = date.plus({ weeks: 1 });}
  if (dir < 0) {date = date.minus({ weeks: 1 });}

  const today = DateTime.now();
  const start = date.startOf('week');
  const end = date.endOf('week');

  // prefer today
  if (start < today && today < end) calendarStore.setDate(today);
  // fall back to monday
  else calendarStore.setDate(date.startOf('week'));
    
};
</script>
