/* eslint-disable max-lines */
import { notificationService } from '@pidz/api';
import { NotificationItemType } from '@pidz/enums';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('notificationsInbox', () => {

  const NOTIFICATIONS_LIMIT = 25

  /************/
  // Notifications
  /************/
  const notifications = ref<NotificationResponse>();
  const loadNotifications = async (reload: boolean = false): Promise<void> => {
    if (reload || !notifications.value) {
      notifications.value = await notificationService.getNotifications({
        limit: NOTIFICATIONS_LIMIT,
        offset: 0,
        type: NotificationItemType.NOTIFICATIONS
      });
    }
  };

  /************/
  // Actions
  /************/
  const actions = ref<NotificationResponse>();
  const loadActions = async (reload: boolean = false): Promise<void> => {
    if (reload || !notifications.value) {
      actions.value = await notificationService.getNotifications({
        limit: NOTIFICATIONS_LIMIT,
        offset: 0,
        type: NotificationItemType.ACTIONS
      });
    }
  };

  const dismissNotification = async (params: DismissNotificationsParams, type: NotificationItemType): Promise<void> => {
    await notificationService.dismissNotification(params)
    if(type === NotificationItemType.ACTIONS && actions.value){
      const index = actions.value.data.findIndex(
        notification => notification.read_dismiss_parameters.notificationUUID === params.notificationUUID
      );
      if (index > -1) actions.value.data.splice(index, 1);
      if(actions.value.data.length === 0 && actions.value.total_count > NOTIFICATIONS_LIMIT){
        loadActions(true);
      } 
    }
    else if(type === NotificationItemType.NOTIFICATIONS && notifications.value){
      const index = notifications.value.data.findIndex(
        notification => notification.read_dismiss_parameters.notificationUUID === params.notificationUUID
      );
      if (index > -1) notifications.value.data.splice(index, 1);
      if(notifications.value.data.length === 0 && notifications.value.total_count > NOTIFICATIONS_LIMIT){
        loadNotifications(true);
    } 
    }
  }

  const $reset = () => {
    actions.value = undefined;
    notifications.value = undefined;
  };

  return {
    // state
    notifications,
    actions,
    
    //actions
    loadNotifications,
    loadActions,
    dismissNotification,

    $reset,
  };
});
