<template>
  <div class="w-full flex flex-col flex-grow" :data-dynamic-prevent-pull="preventPullToRefresh">
    <reload-page :reload="reload" :loading="forceReload"/>
    <overview-tab-bar>
      <router-link :to="{ name: 'incidental shifts' }">Incidenteel</router-link>
      <router-link :to="{ name: 'periodical shifts' }">Periodiek</router-link>
    </overview-tab-bar>
    <search-bar  v-model="request.searchString"
                 storage-key="shifts"
                 filter-button
                 :has-filter="filterStore.isFilterApplied"
                 @open-filter="filterOpen = true"/>
    <layout-desktop-wrapper v-if="empty[activeShiftType]">
      <p class="text-center">Er zijn geen resultaten gevonden</p>
    </layout-desktop-wrapper>
    <div v-else class="flex-grow contain-strict">
      <virtual-scroller v-if="activeShiftType === 1"
                        :items="overviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="(value) => setScrollPosition(value, 1)"/>
    
      <virtual-scroller v-if="activeShiftType === 2"
                        :items="overviewItems"
                        :load-next="loadNext"
                        :loading="loading"
                        :force-reload="forceReload"
                        :scroll-position="scrollPosition"
                        :prevent-pull-to-refresh="preventPullToRefresh"
                        :end-of-list="endOfList"
                        @update-prevent-pull-to-refresh="(value) => preventPullToRefresh = value"
                        @store-scroll-position="(value) => setScrollPosition(value, 2)"/>              
    </div>
    <shift-filter v-if="filterOpen"
                  v-model="filterStore.filters"
                  :max-work-range="maxWorkRange"
                  @close="filterOpen = false"
                  @reset="onFilterReset"
                  @update="onFilterUpdate"/>
  </div>
</template>

<script lang="ts" setup>

import OverviewTabBar from '@app/components/OverviewTabBar.vue';
import ReloadPage from '@app/components/ReloadPage.vue';
import SearchBar from '@app/components/SearchBar.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import VirtualScroller from '@app/components/virtualscroller/VirtualScroller.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useShiftFilterStore, useShiftsStore, useUserStore } from '@pidz/stores';
import { determineChangedInvitationFilters } from '@pidz/utils';
import { ref, computed, watch, reactive, onMounted } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import ShiftFilter from './ShiftFilter.vue';

const shiftsStore = useShiftsStore();
const userStore = useUserStore();
const filterOpen = ref(false);
const filterStore = useShiftFilterStore();
const route = useRoute();
const activeShiftType = computed(() => route.meta.type as number);

const maxWorkRange = ref(0);

const scrollPosition = computed(() => shiftsStore.shiftsOverviewScrollPosition[activeShiftType.value] ?? 0);
const overviewItems = computed(() => shiftsStore.shiftsOverviewItems[activeShiftType.value]);

const endOfList = ref(false);
const forceReload = ref(false);
const loading = ref(false);
const preventPullToRefresh = ref(false);
const empty = ref({1: false, 2: false});

const request = reactive({
  isLoading: false,
  limit: 10,
  offset: 0,
  searchString: '',
});

const filledArrayOrUndefined = (arr: any[]) => (arr.length > 0 ? arr : undefined);

const getShiftsParams = computed(() => {
  const {
    date,
    shiftStatuses,
    functions,
    dayParts,
    travelTime,
    ...filterRest
  } = filterStore.filters;

  const { start, end } = date;

  const { offset, limit, searchString } = request;

  return {
    offset,
    limit,
    type: 1,
    start,
    end: end !== '' ? end : undefined,
    searchString: searchString !== '' ? searchString : undefined,
    shiftType: activeShiftType.value,
    shiftStatuses: filledArrayOrUndefined(shiftStatuses),
    functions: filledArrayOrUndefined(functions),
    dayParts: filledArrayOrUndefined(dayParts),
    travelTime:
      travelTime > 0 && travelTime !== maxWorkRange.value
        ? travelTime
        : undefined,

    ...filterRest,
  };
});


const loadNext = async () => {
  if (loading.value) return;
  
  loading.value = true;

  const options: {} = {}; // TODO: remove from here and in the API
  const params = getShiftsParams.value;
  const type = activeShiftType.value;
  const newShifts = await shiftsStore.getShifts(type, { params, options });

  request.offset += request.limit;
  
  endOfList.value = newShifts.length !== request.offset;
  loading.value = false;

  empty.value[activeShiftType.value] = newShifts.length === 0;
};


const reload = async (type: string = 'pull') => {
  await shiftsStore.clearShifts();
  maxWorkRange.value = await userStore.getMaxWorkRange();

  forceReload.value = type === 'pull';

  request.offset = 0;
  preventPullToRefresh.value = false;
  empty.value = {1: false, 2: false};

  await loadNext();

  forceReload.value = false;
};

watch(
  () => activeShiftType.value,
  () => setOffsetAndEndoflist(),
);

watch(
  () => request.searchString,
  () => {
    logCustomEvent('shift_search', { search: request.searchString });
    reload('normal');
  },
);

const onFilterUpdate = () => {
  logCustomEvent('invitation_filter_apply', determineChangedInvitationFilters(filterStore.filters, maxWorkRange));
  filterOpen.value = false;
  reload('normal');
};

const onFilterReset = () => {
  filterStore.reset();
  filterOpen.value = false;
  reload('normal');
};

const setOffsetAndEndoflist = async () => {
  let overviewitemLength = 0;

  if (shiftsStore.shiftsOverviewItems[activeShiftType.value]) {
    overviewitemLength = shiftsStore.shiftsOverviewItems[activeShiftType.value].length;
  }

  endOfList.value = false;
  request.offset = overviewitemLength;  
}

const setScrollPosition = (emittedScrollPosition: number, type: number) => {
  shiftsStore.shiftsOverviewScrollPosition[type] = emittedScrollPosition;
};

onMounted(async () => {
  setOffsetAndEndoflist();

  maxWorkRange.value = await userStore.getMaxWorkRange()
  
  if (!filterStore.filters.travelTime) {
    filterStore.applyInitialFilters({ travelTime: maxWorkRange.value });
  }
});
</script>
