const hasBlockChanged = (obj: any, objToCompareTo: any) => JSON.stringify(obj) !== JSON.stringify(objToCompareTo);

interface GroupedModels<ModelType> {
  added: ModelType[]
  removed: ModelType[]
  editted: ModelType[]
}

const findBlockByKey = <T>(objToFind: T, objArray: T[], key: keyof T) => objArray
  .find((obj: T) => objToFind[key] === obj[key]);

const groupBlocksByEditStatus = <T> (sheetBlocks: T[], originalBlocks: T[], key: keyof T) => {
  const changedBlocks: GroupedModels<T> = { added: [], removed: [], editted: [] };

  originalBlocks.forEach((block) => {
    const result = findBlockByKey(block, sheetBlocks, key);
    if (!result) {
      changedBlocks.removed.push({ ...block });
    } else if (hasBlockChanged(block, result)) {
      changedBlocks.editted.push({ ...result });
    }
  });

  changedBlocks.added = [...sheetBlocks].filter((block) => (block[key] === null || !block[key]));
  return changedBlocks;
};
export {
  hasBlockChanged, groupBlocksByEditStatus,
};
