export default [
  {
    path: '/calendar',
    children: [
      {
        name: 'calendar',
        path: '/calendar',
        redirect: { name: 'calendar month' },
        component: () => import('@app/pages/calendar/CalendarPage.vue'),
        children: [
          {
            name: 'calendar day',
            path: '/calendar/day',
            component: () => import('@app/pages/calendar/CalendarDayContent.vue'),
            meta: { title: 'Agenda', type: 'day' },
          },
          {
            name: 'calendar week',
            path: '/calendar/week',
            component: () => import('@app/pages/calendar/CalendarWeekContent.vue'),
            meta: { title: 'Agenda', type: 'week' },
          },
          {
            name: 'calendar month',
            path: '/calendar/month',
            component: () => import('@app/pages/calendar/CalendarMonthContent.vue'),
            meta: { title: 'Agenda', type: 'month' },
          },
        ],
      },
      {
        name: 'calendar add event',
        path: '/calendar/add',
        component: () => import('@app/pages/calendar/CalendarAddEventPage.vue'),
        meta: {
          title: 'Nieuw agenda-item',
          backButton: true,
        }
      },
      {
        name: 'calendar edit event',
        path: '/calendar/edit/:id',
        component: () => import('@app/pages/calendar/CalendarEditEventPage.vue'),
        meta: {
          title: 'Agenda-item bewerken',
          backButton: true,
          editRecurrence: false
        },
      },
      {
        name: 'calendar edit recurrence',
        path: '/calendar/edit/:id/',
        component: () => import('@app/pages/calendar/CalendarEditEventPage.vue'),
        meta: {
          title: 'Agenda-item bewerken',
          backButton: true,
          editRecurrence: true
        },
      }
    ]
  }
]