<template>
  <section class="event-date-section">
    <labeled-slot
      class="input-date"
      label="Datum van/tot">
      <input-border multi>
        <agenda-icon />
        <input
          :value="start.date"
          type="date"
          @input="updateStartDate($event.target as HTMLInputElement)"/>
        <input
          :value="end.date"
          type="date"
          @input="updateEndDate($event.target as HTMLInputElement)"/>
      </input-border>
    </labeled-slot>
    <labeled-slot
      v-if="!allDay"
      class="input-time"
      label="Tijd van/tot">
      <input-border multi>
        <time-icon />
        <input
          v-model="startTime"
          type="time" />
        <input
          v-model="endTime"
          type="time" />
      </input-border>
      <p v-if="!isEventDateValid || !isEventTimeValid" class="error">
        {{ errorMessage }}
      </p>
    </labeled-slot>
    <checkbox-with-text
      v-model="allDay"
      class="all-day-checkbox">Hele dag</checkbox-with-text>
  </section>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import CheckboxWithText from '@app/components/CheckboxWithText.vue';
import InputBorder from '@app/components/InputBorder.vue';
import LabeledSlot from '@app/components/LabeledSlot.vue';
import {
  addDuration, createDurationObject,
  differenceBetweenDates, formatYmd, isBefore
} from '@pidz/date';
import { AgendaIcon, TimeIcon } from '@pidz/icons';
import { useCalendar } from '@pidz/stores';
import { DateTime } from 'luxon'
import { computed } from 'vue';
import { validateDatesOfEvent, validateTimeOfEvent } from '../utils';

const props = defineProps<{
  start: { date: string; time: string };
  end: { date: string; time: string };
  allDay: boolean;
}>();

const calendarStore = useCalendar();
const emit = defineEmits(['update:start', 'update:end', 'update:allDay']);
const isEventDateValid = computed(() => validateDatesOfEvent(props.start, props.end, props.allDay));
const isEventTimeValid = computed(() => validateTimeOfEvent(props.start, props.end, props.allDay));

const errorMessage = computed(() => {
  if (!isEventDateValid.value) {
    return 'Eindtijd kan niet voor de starttijd liggen.';
  } 
  if (!isEventTimeValid.value) {
    return 'Een agenda-item kan niet minder dan 15 minuten duren.';
  }
  return undefined;
});

// needs to be date string for the input type date
const startTime = computed({
  get() {
    return props.start.time;
  },
  set(updatedTime) {
    emit('update:start', { ...props.start, time: updatedTime });
  },
});

// needs to be date string for the input type date
const endTime = computed({
  get() {
    return props.end.time;
  },
  set(updatedTime) {
    emit('update:end', { ...props.end, time: updatedTime });
  },
});

const allDay = computed({
  get() {
    return props.allDay;
  },
  set(updatedValue) {
    emit('update:allDay', updatedValue);
  },
});

const updateStartDate = ({ value: newStartDate }) => {
  // on iOS a field can be "reset" which normally empties the field,
  // the following if statement defaults the date values back to the date saved in the store.
  if (newStartDate === '') {
    emit('update:start', {
      ...props.start,
      date: formatYmd(calendarStore.date),
    });
    emit('update:end', {
      ...props.start,
      date: formatYmd(calendarStore.date),
    });
    return;
  }

  const oldStartDate = DateTime.fromISO(props.start.date)
  const endDate = DateTime.fromISO(props.end.date)
  const { days } = differenceBetweenDates(DateTime.fromISO(newStartDate), oldStartDate, 'days');
  const dayDiff = days || 0;

emit('update:end', {
    ...props.end,
    date:
      dayDiff !== 0 ?  formatYmd(addDuration(endDate, createDurationObject({ days: dayDiff }))) : newStartDate });
  emit('update:start', { ...props.start, date: newStartDate });
};

const updateEndDate = ({ value: newEndDate }) => {
  // on iOS a date field can be "reset" which empties the field,
  // the following if statement restores the date values back to the date saved in the store.
  if (newEndDate === '') {
    emit('update:start', {
      ...props.start,
      date: formatYmd(calendarStore.date),
    });
    emit('update:end', {
      ...props.start,
      date: formatYmd(calendarStore.date),
    });
    return;
  }
  if (isBefore(DateTime.fromISO(newEndDate), DateTime.fromISO(props.start.date))) {
    emit('update:start', { ...props.start, date: newEndDate });
  }
  emit('update:end', { ...props.end, date: newEndDate });
};
</script>
<style lang="scss">
.event-date-section {
  input {
    font-size: 16px;
    width: 100%;

    background-origin: content-box;
    background-position: right;
    background-repeat: no-repeat;
    background-image: url('@app/icons/chevron.svg');

    &:focus::after {
      rotate: 180deg;
    }
  }

  input[type='date'],
  input[type='time'] {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  .input-time {
    margin-top: 24px;
  }

  .checkbox-with-text {
    margin-top: 12px;
    font-size: 13px;

    .checkmark {
      border-color: var(--pidz-black);
      border-width: 1px;
    }

    input:not(:checked) ~ .checkmark {
      background-color: var(--pidz-white);
    }
  }

  .error {
    font-size: 13px;
    color: var(--pidz-red);
  }
}
</style>
