<template>
  <div class="upcoming-shift w-10/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12">
    <div class="details">
      <span class="date">{{ date }}</span>
      <span class="time-slot">{{ timeSlot }}</span>
    </div>
    <div class="details">
      <span class="organisation">{{ organisation }}</span>
      <span class="address">{{ address }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { formatHour, formatDayMonth } from '@pidz/date';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = defineProps<{
  start: string;
  end: string;
  organisation: string;
  address: string;
}>();

const date = computed(() => formatDayMonth(DateTime.fromISO(props.start)));

const timeSlot = computed(() => {
  const start = formatHour(DateTime.fromISO(props.start));
  const end = formatHour(DateTime.fromISO(props.end));
  return `${start} - ${end}`;
});
</script>
<style lang="scss" scoped>
.upcoming-shift {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  scroll-snap-align: start;
  padding: 16px 8px;
  margin-right: 16px;
  border-radius: 5px;
  flex-shrink: 0;
  column-gap: 16px;
  color: var(--pidz-body-text);
  background-color: var(--pidz-white);
  box-shadow: var(--shadow-light);
  border: 1px solid var(--pidz-grey-light);

  .details {
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:last-of-type {
      justify-content: flex-start;
    }

    .date {
      font-size: 28px;
      vertical-align: top;
      letter-spacing: 0.3px;
      color: var(--pidz-blue);
      font-weight: 700;
    }

    .time-slot {
      font-size: 13px;
      color: var(--pidz-grey-dark);
    }

    .organisation {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .address {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
