<template>
  <modal>
    <h2 class="font-bold">Agenda-item verwijderen</h2>
    <p>Weet je zeker dat je dit agenda-item wil verwijderen?</p>
    <template #footer>
      <button class="button-outline"
              @click="cancelEvent">Annuleren</button>
      <async-button class="error"
                    @click="removeEvent">Verwijderen</async-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import AsyncButton from '@app/components/AsyncButton.vue';
import Modal from '@app/components/Modal.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { useModalController } from '@pidz/utils';

const modalController = useModalController();
const props = defineProps<{
  remove: Function;
}>();

const removeEvent = async () => {
  await props.remove();
  modalController.close();
};

const cancelEvent = () => {
  logCustomEvent('agendaitem_remove_cancel');
  modalController.close();
};
</script>
