<template>
  <modal>
    <h2 class="font-bold">{{ title }}</h2>
    <p>{{ message }}</p>
    <template #footer>
      <button @click="modalController.close">Sluit</button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import { useModalController } from '@pidz/utils';
import Modal from './Modal.vue';

const modalController = useModalController();

withDefaults(
  defineProps<{
    title?: string;
    message: string;
  }>(),
  {
    title: 'Helaas',
  },
);
</script>
<style lang="scss">
.exception-modal {
}
</style>
