<template>
  <div class="travel-time">
    <div class="travel-time__fields"
         :class="{ 'travel-time__fields--web': isWeb }">
      <p-form ref="travelTimeForm"
              :form-data="formData"
              :disable-form="isDisabled || isSubmitting"
              :disable-buttons="true"/>
      <div class="travel-time__departments">
        Gekoppelde afdelingen
        <div v-if="linkedDepartments || linkedDepartments === 0">
          {{ linkedDepartments }}
        </div>
        <div v-else class="spinner">
          <p-icon class="spinner__icon"
                  :icon-name="'spinner'"
                  size="large"
                  color="blue"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFormFunctions } from '@pidz/shared/forms';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useUserStore } from '@pidz/stores';
import { debounceFn, minutesToSeconds } from '@pidz/utils';
import { ref, watch } from 'vue';
import {
  Router,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRouter,
} from 'vue-router';

//@TODO FormPageProps interface naar types en overal toepassen
interface FormPageProps<T> {
  isWeb?: boolean;
  isEdit?: boolean;
  formData: PidzFormData<T>;
  isDisabled?: boolean;
}

const props = withDefaults(defineProps<FormPageProps<TravelTimeForm>>(), {
  isWeb: false,
  isEdit: false,
  isDisabled: false,
});

const router: Router = useRouter();
const userStore = useUserStore();

const travelTimeForm = ref<any>();
const linkedDepartments = ref<number>();
const formSubmitted = ref(false);

const eventName = 'travel-time_modal_edit_cancel';

onBeforeRouteLeave((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);
onBeforeRouteUpdate((_to, _from, next) =>
  warnBeforeCancel(next, formSubmitted.value, props.isWeb, eventName),
);

const {
  isSubmitting,
  isSubmitDisabled,
  succesToaster,
  onCancel,
  warnBeforeCancel,
} = useFormFunctions(
  travelTimeForm,
  [],
  () => rerouteToOverview(router, 'travel-time-clients'),
  () => Promise.resolve(),
  props.isEdit,
  'reistijd',
);

const onSubmit = async () => {
  const submit = travelTimeForm?.value?.handleSubmit(
    async (formValues: TravelTimeForm) => {
      formSubmitted.value = true;
      await userStore.updateMaxWorkRange(minutesToSeconds(formValues.travelTime));

      rerouteToOverview(router, 'travel-time-clients');
      succesToaster();
    },
  );

  if (submit) {
    await submit();
  }
};

const debounceLinkedDepartments = debounceFn(async (value: number) => {
  if (userStore.maxWorkRange && minutesToSeconds(value) === userStore.maxWorkRange)
    linkedDepartments.value = userStore.departmentCountWithinRange;
  else if(value) {
    const departments = await userStore.fetchDepartmentCountWithinRange(minutesToSeconds(value), true);
    linkedDepartments.value = departments ?? undefined;
  }
  else linkedDepartments.value = 0
}, 500);

watch(
  () => travelTimeForm?.value?.values?.travelTime,
  (value: number) => {
    linkedDepartments.value = undefined;
    debounceLinkedDepartments(value)
  }
);

defineExpose({ isSubmitDisabled, isSubmitting, onCancel, onSubmit });
</script>
<style scoped lang="scss">
.travel-time {
  &__fields {
    background-color: var(--pidz-white);
    color: var(--pidz-body-text);
    box-shadow: var(--shadow-light);
    padding: 24px 16px;

    &--web {
      padding: 32px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      box-shadow: none;
    }
  }
  &__departments {
    line-height: 30px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  &__icon {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
