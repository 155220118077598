<template>
  <layout-desktop-wrapper>
    <standard-rates-form ref="form" 
                         :rates="rates" 
                         is-edit/>
    <form-buttons-row :is-loading="loading"
                      :on-cancel="form?.onCancel" :on-submit="form?.onSubmit"
                      :is-submit-disabled="disabled"/>
  </layout-desktop-wrapper>
</template>
<script lang="ts" setup>
import  FormButtonsRow from '@app/components/FormButtonsRow.vue';
import LayoutDesktopWrapper from '@app/components/layout/LayoutDesktopWrapper.vue';
import { StandardRatesForm } from '@pidz/shared/components';
import { useRatesStore } from '@pidz/stores';
import { computed, ref } from 'vue';

const form = ref<typeof StandardRatesForm>();

const ratesStore = useRatesStore();

const loading = computed(() => form.value?.isSubmitting)
const disabled = computed(() => form.value?.isSubmitDisabled)
const rates = computed(() =>
 ratesStore.getFreelancerStandardFunctionRates(),
);

const goBack = () => form.value?.onCancel()
defineExpose({ goBack })
</script> 
