import useInterface from '@app/stores/interface';
import { Network, ConnectionStatus } from '@capacitor/network';

export const initNetworkStatus = async () => {
  const initialStatus = await Network.getStatus();
  useInterface().setNetworkStatus(initialStatus.connected);
  await Network.addListener('networkStatusChange', async (status: ConnectionStatus) => {
    await useInterface().setNetworkStatus(status.connected, true);
  });
};
