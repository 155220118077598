import { UnsavedBlock } from '../types';

export const getId = (block:UnsavedBlock) => block.sheet_block_id
    ?? block.uuid
    ?? block.temp_id;

export const sameId = (a: UnsavedBlock, b: UnsavedBlock) => {
  if (a.sheet_block_id !== b.sheet_block_id) { return false; }
  if (a.uuid !== b.uuid) { return false; }
  if (a.temp_id !== b.temp_id) { return false; }
  return true;
};
