<template>
  <p-card :padding="padding"
          class="bank"
          title="Bankgegevens"
          has-button
          icon-name="pencil"
          icon-size="large"
          :rounded="false"
          title-size="lg"
          @button-click="emit('edit-bank')">
    <template #header>
      <p-heading variant="secondary" class="font-bold"></p-heading>
    </template>

    <template #content>
      <p-table class="bank__table"
               :columns="columns"
               :rows="rows"
               :omit-header="true"
               :colgroup="[{ span: 1, width: '180px' }]"
               compact
               disable-horizontal-spacing>
        <template #row_type="row">
          <span class="bank__type--bold">{{ row.value.type }}</span>
        </template>
        <template #row_value="row">
          <span class="bank__value">{{ row.value.value || '-' }}</span>
        </template>
      </p-table>
    </template>
  </p-card>
</template>
<script lang="ts" setup>
import { useUserStore } from '@pidz/stores';
import { computed } from 'vue';

const props = defineProps<{
  data: FreelancerProfile['bank'];
  padding: string;
}>();

const emit = defineEmits(['edit-bank']);
const userStore = useUserStore();

const columns = [
  { field: 'type', label: 'type' },
  { field: 'value', label: 'waarde' },
];

const rows = computed(() => [
  {
    type: 'BIC Code',
    value: props.data.bic,
  },
  {
    type: 'IBAN',
    value: props.data.iban,
  },
  {
    type: 'Op naam van',
    value: props.data.name,
  },
  ...userStore.isUserFreelancer ? [{
    type: 'Automatische incasso',
    value: props.data.automaticIncasso ? 'Ja' : 'Nee',
  }] : [],
]);
</script>
<style scoped lang="scss">
.bank {
  &__table {
    margin-top: 24px;
    font-size: 16px;
  }
  &__type {
    &--bold {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>
