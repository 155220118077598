import { DateTime } from 'luxon';

export const getMonthRange = (date: DateTime, includingFullLastDay = false) => {
  const startDate = date.set({ day: 1 });

  // rewind back to monday before
  const subtract = startDate.weekday;

  // TODO: fix magic number -1
  const start = startDate.minus({ days: subtract - 1 });
  const end = start.plus({ days: includingFullLastDay ? 42 : 41 });

  return {
    start,
    end,
  };
};
