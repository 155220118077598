import { DateTime } from 'luxon';

export const determineChangedInvitationFilters = (
  filters: IInvitationFilterParams,
  maxWorkRange,
) => {
  return {
    dayParts: filters.dayParts.length > 0 ? true : undefined,
    alreadyWorkedAt: filters.alreadyWorkedAt !== 0 ? true : undefined,
    function: filters.functions.length > 0 ? true : undefined,
    travelTime: filters.travelTime !== maxWorkRange.value ? true : undefined,
    startDate:
      filters.date.start !== DateTime.now().toFormat('yyyy-MM-dd') ? true : undefined,
    endDate: filters.date.end ? true : undefined,
  }
};
