<template>
  <p-card title="Standaard tarieven"
          has-button
          icon-name="pencil"
          icon-size="large"
          :rounded="false"
          title-size="lg"
          :padding="padding"
          @button-click="emit('edit-standard-rates')">
    <template #content>
      <span class="subtitle">{{ taxText }}</span>
      <div v-if="rates" class="standard-rates">
        <div v-for="(rate, index) in rates" :key="index">
          <rate-list-item :rate="rate"/>
          <p-ruler v-if="index !== rates.length - 1" />
        </div>
      </div>
      <div v-else class="spinner">
        <p-icon class="spinner__icon"
                :icon-name="'spinner'"
                size="large"
                color="blue"/>
      </div>
    </template>
  </p-card>
</template>

<script lang="ts" setup>
import { useUserStore } from '@pidz/stores';
import { computed } from 'vue';
import RateListItem from './RateListItem.vue';

const userStore = useUserStore();

const taxText = computed(() => userStore.freelancer?.vat_free ? '(BTW vrijgesteld)' : '(Excl. BTW)')

defineProps<{
  padding: string;
  rates?: Rate[];
}>();

const emit = defineEmits(['edit-standard-rates']);

</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 14px;
}

.standard-rates {
  margin-top: 24px;
  font-size: 14px;
}

.no-results {
  padding: 16px;
  text-align: center;
  font-size: 14px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  &__icon {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
