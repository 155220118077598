import { useApi } from '@pidz/api';

const api = useApi();

export const bannersService = {
  fetchBanners: (): Promise<BannersAPI> => api.rest({
    method: 'get', 
    url: '/api/freelancer/freelancer-banners', 
  }),

  getSystemNotifications: (): Promise<SystemNotification[]> => api.jsonrpc({ 
    url: 'communication:getAllActive'
  }),
}
