import { invoiceService } from '@pidz/api';
import { InvoiceStatus } from '@pidz/enums';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export default defineStore('invoice', () => {
  const key = ref(Date.now());
  const unpaidInvoices = ref<SoftwareInvoice[] | null>(null);
  const invoices = ref({});
  const softwareInvoices = ref({});
  const molliePaymentId = ref<string|null>(null);

  const unpaidInvoiceCount = computed(() => unpaidInvoices.value?.length ?? 0);

  const invalidateKey = () => key.value = Date.now();
  const updateUnpaidInvoices = async () => {
    unpaidInvoices.value =
      (
        await invoiceService.getSoftwareInvoices({
          invoiceStatuses: [
            InvoiceStatus.DOUBTFUL_DEBTOR,
            InvoiceStatus.REMINDER_3,
          ],
          limit: 100,
          offset: 0,
          count: false,
        })
      )?.data ?? [];
  };

  const getInvoice = async (sheetId: number) => {
    const invoice = await invoiceService.getFreelancerInvoiceBySheet(sheetId);
    invoices.value[sheetId] = invoice;
    return invoice;
  };

  const getSoftwareInvoice = async (invoiceId: number) => {
    const softwareInvoice = await invoiceService.getSoftwareInvoice(invoiceId);
    softwareInvoices.value[invoiceId] = softwareInvoice;
    return softwareInvoice;
  };

  const changeFreelancerInvoicePaidStatus = async (
    invoiceId: number,
    updatedPaymentStatus: boolean,
  ) => {
    return await invoiceService.changeFreelancerInvoicePaidStatus(
      invoiceId,
      updatedPaymentStatus,
    );
  };

  const sendInvoiceById = async (
    sheetId: number,
    preFinance: boolean,
    invoiceNumber: string | null,
  ) => {
    return await invoiceService.sendInvoiceById(
      sheetId,
      preFinance,
      invoiceNumber,
    );
  };

  const sendInvoiceReminder = async (invoiceId: number) => {
    return await invoiceService.sendInvoiceReminder(
      invoiceId,
    );
  }

  const factoringCompanyCreditIsPaid = async (freelancerInvoiceId: number) => {
    return await invoiceService.factoringCompanyCreditIsPaid(
      freelancerInvoiceId,
    );
  }

  const factoringCompanyCreditPaymentLink = async (freelancerInvoiceId: number, redirectURL: string) => {
    return await invoiceService.factoringCompanyCreditPaymentLink(
      freelancerInvoiceId,
      redirectURL,
    );
  }

  const softwareInvoicePaymentLink = async (invoiceId: number, redirectUrl: string) => {
    const response = await invoiceService.softwareInvoicePaymentLink(
      invoiceId,
      redirectUrl,
    );
    molliePaymentId.value = response;
    return response;
  }

  const setSoftwareInvoiceToStatusPending = async () => {
    if (!molliePaymentId.value) {
      throw new Error('No mollie payment id set');
    }
    await invoiceService.setSoftwareInvoiceToStatusPending(molliePaymentId.value);
    molliePaymentId.value = null;
  }

  const setMolliePaymentId = (url: string) => {
    const paymentId = url.split('/').pop();
    if (paymentId) {
      molliePaymentId.value = `tr_${paymentId}`;
    }
  }

  const $reset = () => {
    key.value = Date.now();
    unpaidInvoices.value = null;
    invoices.value = {};
    softwareInvoices.value = {};
    molliePaymentId.value = null;
  }

  return {
    key,
    unpaidInvoices,
    invoices,
    softwareInvoices,
    molliePaymentId,
    unpaidInvoiceCount,
    invalidateKey,
    updateUnpaidInvoices,
    getInvoice,
    getSoftwareInvoice,
    changeFreelancerInvoicePaidStatus,
    sendInvoiceById,
    sendInvoiceReminder,
    factoringCompanyCreditIsPaid,
    factoringCompanyCreditPaymentLink,
    softwareInvoicePaymentLink,
    setSoftwareInvoiceToStatusPending,
    setMolliePaymentId,
    $reset
  };
});
