<template>
  <p-card class="travel-time"
             :padding="padding"
             title="Reistijd en koppelingen"
             has-button
             icon-name="pencil"
             :rounded="false"
             title-size="lg"
             icon-size="large"
             @button-click="emit('edit-travel-time')">
    <template #content>
      <p-table v-if="contentLoaded"
                  class="travel-time__table"
                  :columns="columns"
                  :rows="rows"
                  :omit-header="true"
                  :colgroup="[{ width: '180px' }]"
                  vertical-align="baseline"
                  compact
                  disable-horizontal-spacing>
        <template #row_type="row">
          <span class="travel-time__type--bold">{{ row.value.type }}</span>
        </template>
      </p-table>
      <div v-else class="spinner">
        <p-icon class="spinner__icon"
                   :icon-name="'spinner'"
                   size="large"
                   color="blue"/>
      </div>
    </template>
  </p-card>
</template>

<script lang="ts" setup>
import { secondsToMinutes } from '@pidz/utils';
import { computed } from 'vue';

const props = defineProps<{
  padding: string;
  travelData: TravelTimeData;
}>();

const contentLoaded = computed(
  () =>
    props.travelData.time &&
    props.travelData.linkedOrganisations &&
    props.travelData.linkedDepartments,
);

const emit = defineEmits(['edit-travel-time']);

const columns = [
  { field: 'type', label: 'type' },
  { field: 'value', label: 'waarde' },
];

const rows = computed(() => [
  {
    type: 'Max reistijd',
    value: `${secondsToMinutes(props.travelData.time || 0)} minuten` ,
  },
  {
    type: 'Zorginstellingen',
    value: props.travelData.linkedOrganisations,
  },
  {
    type: 'Afdelingen',
    value: props.travelData.linkedDepartments,
  },
]);
</script>
<style scoped lang="scss">
.travel-time {
  &__table {
    margin-top: 24px;
  }

  &__type {
    &--bold {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  &__icon {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
