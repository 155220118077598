<template>
  <drawer>
    <drawer-details-split v-if="isIncidental" :left="left"
                          :right="right" />

    <template v-if="isInvite || (sheetId && checkPermissionPage('sheets'))" #buttons>
      <template v-if="isInvite">
        <button class="error" :disabled="isFreelancerInactive"
                @click="cancelInvite">
          Weigeren
        </button>
        <button :disabled="isFreelancerInactive" @click="acceptInvite">Accepteren</button>
      </template>

      <router-link v-else-if="sheetId && checkPermissionPage('sheets')"
                   :disabled="isFreelancerInactive"
                   :to="{ name: 'sheet', params: { id: sheetId } }">
        Urenregistratie bekijken
      </router-link>
    </template>
  </drawer>
</template>
<script lang="ts" setup>
import Drawer from '@app/components/Drawer.vue';
import DrawerDetailsSplit from '@app/components/DrawerDetailsSplit.vue';
import { logCustomEvent } from '@app/utils/firebase-analytics';
import { differenceBetweenDates } from '@pidz/date';
import { ShiftType, ShiftStatus, ShiftSubType, ShiftApprovalStatus } from '@pidz/enums';
import { checkPermissionPage } from '@pidz/shared/utils';
import { useShiftsStore, useInvitationsStore } from '@pidz/stores';
import { useToaster } from '@pidz/stores';
import { useUserStore } from '@pidz/stores';
import { errorCodes, priceComponents, useModalController } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { ShiftWorker } from '../types';
import AcceptShiftModal from './AcceptShiftModal.vue';
import AgencyWorkerAcceptShiftModal from './AgencyWorkerAcceptShiftModal.vue';
import ShiftAcceptedModal from './ShiftAcceptedModal.vue';

const userStore = useUserStore();
const shiftsStore = useShiftsStore();
const invitationStore = useInvitationsStore();
const router = useRouter();

const props = defineProps<{
  shiftWorker: ShiftWorker;
  sheetId?: number;
  isFreelancerInactive?: boolean;
  break: number;
}>();

const emit = defineEmits(['acceptShift']);

const isIncidental = computed(() => props.shiftWorker.shift_type === ShiftType.TYPE_INCIDENTAL);
const isInvite = computed(() =>
    props.shiftWorker.status === ShiftApprovalStatus.FREELANCER_INVITED &&
    props.shiftWorker.shift_status === ShiftStatus.OPEN,
);

const modal = useModalController();
const acceptInvite = () => {
  if (userStore.isUserFreelancer) {
    modal.open(AcceptShiftModal, {
      accept: async () => acceptShift()
    });
  }

  if (userStore.isUserAgencyWorker) {
    modal.open(AgencyWorkerAcceptShiftModal, {
      accept: async () => acceptShift()
    });
  }
};

const acceptShift = async () => {
  try {
    await shiftsStore.acceptShift(props.shiftWorker.id);
    invitationStore.removeInvitation(props.shiftWorker.id, props.shiftWorker.shift_type);
    modal.open(ShiftAcceptedModal, {
      organisationName: props.shiftWorker.organisation_name,
    });
    logCustomEvent('invitation_accept');
    emit('acceptShift');
  } catch (e: any) {
    const code = e?.response?.data?.error?.code;
    if (code === 602 || code === 1002){
        useToaster().addToaster({
        type: 'error',
        title: errorCodes[1002].title,
        message: errorCodes[1002].message,
      }, 5000);
    }
  }
}

const cancelInvite = async () => {
  try {
    await shiftsStore.declineShift(props.shiftWorker.id);
    invitationStore.removeInvitation(props.shiftWorker.id, props.shiftWorker.shift_type);
    logCustomEvent('invitation_reject');
    await router.replace('/invitations');
  } catch (e: any) {
    // handled by global error handler
  }
};

const durationInMinutes = computed(() => {
  const { minutes } = differenceBetweenDates(
    DateTime.fromISO(props.shiftWorker.shift_end),
    DateTime.fromISO(props.shiftWorker.shift_start),
    'minute',
  );

  if (minutes) return minutes - props.break;

  return 0;
});

const duration = computed(() => ({
  hours: Math.floor(durationInMinutes.value / 60),
  minutes: durationInMinutes.value % 60,
}));

const earnings = computed(() => {
  const { shift_sub_type, rate } = props.shiftWorker;

  let durationWithoutSleep = durationInMinutes.value;
  if (shift_sub_type === ShiftSubType.SUBTYPE_SLEEP) {
    durationWithoutSleep -= 240;
  }
  return (durationWithoutSleep / 60) * rate;
});

const left = computed(() => ({
  label: 'Totaal aantal gewerkte uren:',
  value: `${duration.value.hours} u `,
  suffix: `${duration.value.minutes} min`,
}));

const right = computed(() => {
  const { euros, cents } = priceComponents(earnings.value);
  return {
    label: 'Te verdienen:',
    value: `€${euros},`,
    suffix: `${cents}`,
  };
});
</script>
