import { FormFieldType } from '@pidz/enums';
import { ref } from 'vue';
import * as zod from 'zod';

export const mobileField = ref<FormField>({
  active: true,
  name: 'mobile',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(14, { message: 'Vul maximaal 14 cijfers in' })
    .refine(
      (value) => {
        const regex = /[ `!@#$%^&*()_\-=[\]{};':"\\|,.<>/?~]+/;
        return !regex.test(value);
      },
      {
        message: 'Vul alleen cijfers in',
      },
    )
    .refine(
      (value) => {
        const regex = /^([+]|[0-9])\d{9,14}$/;
        return regex.test(value);
      },
      {
        message: 'Vul een geldig mobielnummer in met minimaal 9 cijfers',
      },
    )
    .optional(),
  width: 100,
  data: {
    id: 'mobile',
    label: 'Mobiel nr.',
    inputType: 'text',
    placeholder: '0612345678',
  },
});

export const phoneField = ref<FormField>({
  active: true,
  name: 'phone',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(14, { message: 'Vul maximaal 14 cijfers in' })
    .refine(
      (value) => {
        const regex = /[ `!@#$%^&*()_\-=[\]{};':"\\|,.<>/?~]+/;
        return !regex.test(value);
      },
      {
        message: 'Vul alleen cijfers in',
      },
    )
    .refine(
      (value) => {
        const regex = /^([+]|[0-9])\d{9,14}$/;
        return regex.test(value);
      },
      {
        message: 'Vul een geldig telefoonnummer in met minimaal 9 cijfers',
      },
    )
    .optional(),
  width: 100,
  data: {
    id: 'phone',
    label: 'Telefoon nr.',
    inputType: 'text',
    placeholder: '0101234567',
  },
});

export const emailField = ref<FormField>({
  active: true,
  name: 'email',
  formType: FormFieldType.INPUT,
  validation: zod
    .string({ required_error: 'Vul een e-mail adress in' })
    .min(1, { message: 'Vul een e-mail adress in' })
    .email({ message: 'Vul een geldig e-mail adress in' }),
  width: 100,
  data: {
    id: 'email',
    label: 'E-mail',
    inputType: 'text',
    placeholder: 'voorbeeld@voorbeeld.nl',
  },
});

export const emergencyContactField = ref<FormField>({
  active: false,
  name: 'emergencyContact',
  formType: FormFieldType.INPUT,
  validation: zod.string().min(1, { message: 'Vul de naam in' }).optional(),
  width: 100,
  data: {
    id: 'emergencyContact',
    label: 'Noodcontact',
    inputType: 'text',
    placeholder: 'Naam',
  },
});

export const emergencyPhoneField = ref<FormField>({
  active: false,
  name: 'emergencyPhone',
  formType: FormFieldType.INPUT,
  validation: zod
    .string()
    .max(14, { message: 'Vul maximaal 14 cijfers in' })
    .refine(
      (value) => {
        const regex = /[ `!@#$%^&*()_\-=[\]{};':"\\|,.<>/?~]+/;
        return !regex.test(value);
      },
      {
        message: 'Vul alleen cijfers in',
      },
    )
    .refine(
      (value) => {
        const regex = /^([+]|[0-9])\d{9,14}$/;
        return regex.test(value);
      },
      {
        message: 'Vul een geldig telefoonnummer in met minimaal 9 cijfers',
      },
    )
    .optional(),
  width: 100,
  data: {
    id: 'emergencyPhone',
    label: 'Noodcontact nr.',
    inputType: 'text',
    placeholder: '0101234567',
  },
});

