<template>
  <calendar-header calendar-type="Maand" 
                   :title="title"
                   @move-date="onMoveDate" />
</template>

<script setup lang="ts">
import {
  addDuration, createDurationObject, isSame
} from '@pidz/date';
import { useCalendar } from '@pidz/stores';
import { formatTitle } from '@pidz/utils';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import CalendarHeader from './CalendarHeader.vue';

const calendarStore = useCalendar();

const title = computed((): string => {
  const { date, isDateThisYear } = calendarStore;
  return formatTitle(date, isDateThisYear, 'long');
});

const onMoveDate = (dir: number) => {
  let date = addDuration(calendarStore.date, createDurationObject({ month: dir }));
  const now = DateTime.now();
  date = date.set({ day: isSame(date, now, 'month') ? now.day : 1})
  calendarStore.setDate(date);
};
</script>
