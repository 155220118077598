<template>
  <div class="options-grid">
    <div v-if="label" class="label">
      <p-label :input-id="inputId"
               :label="label" />
    </div>
    <div class="options">
      <p-checkbox v-for="(option, index) in modelValue" :key="index"
                  v-model="option.checked"
                  :input-id="inputId"
                  :text="option.value"
                  :value="option.value"
                  :disabled="(option.disabled) ? option.disabled : false"
                  @update:model-value="(event) => handleCheckboxClick(event, index)" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: Array<{
    value: string
    checked: boolean
    disabled?: boolean
  }>,
  inputId: string
  label?: string
}>();

const emit = defineEmits(['update:modelValue']);

const checkedOptions = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit(
      'update:modelValue',
      newValue
    );
  }
});

const handleCheckboxClick = (checked: boolean, index: number) => {
  const newData = [...props.modelValue];
  newData[index].checked = checked;
  checkedOptions.value = newData;
};

</script>

  <style scoped lang="scss">
  .options-grid {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .options {
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
