/* eslint-disable no-console */
import { documentService, notificationService, shiftService } from '@pidz/api';
import { isCurrentYear } from '@pidz/date';
import { shiftApprovalStatusMap } from '@pidz/utils';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { formatAllOverviewItems } from '../utils/overview-conversion';

interface ShiftsStore {
  shifts: {[type: number]: OverviewShift[]},
  shiftsOverviewItems: {[type: number]: newOverviewItem[]},
  shiftsOverviewScrollPosition: {[type: number]: number},
  shiftsSearchString: string,
}

interface GetShiftsParams {
  params: ShiftGetShiftsParams,
  options: {},
}

const getShiftGroupTitle = (date: DateTime, type: number): string => {
  let format = (type === 1) ? 'cccc d LLLL' : 'LLLL';
  
  if (!isCurrentYear(date)) format += ', y';

  return date.setLocale('nl-NL').toFormat(format);
}

const transformShiftToShiftOverviewItem = (shift: OverviewShift, type: number): newOverviewItem => {
  return {
    class:shiftApprovalStatusMap.get(shift.status)?.type ?? 'info',
    status:shiftApprovalStatusMap.get(shift.status)?.text ?? '',
    firstOfType: false,
    lastOfType: false,
    groupTitle: getShiftGroupTitle(DateTime.fromISO(shift.shift_start), type),
    // groupTitle: DateTime.fromISO(shift.shift_start).setLocale('nl-NL').toFormat(type === 1 ? 'ccc d LLLL' : 'LLLL'),
    to: { name: 'shift', params: { id: shift.shift_id } },
    id: shift.id,
    title: shift.organisation_name,
    subtitle: shift.department_name,
    description: shift.function,
    startTime: DateTime.fromISO(shift.shift_start).setLocale('nl-NL').toFormat(type === 1 ? 'HH:mm' : 'd LLL.'),
    endTime: DateTime.fromISO(shift.shift_end).setLocale('nl-NL').toFormat(type === 1 ? 'HH:mm' : 'd LLL.'),
    icon: shift.is_sleep_shift ? 'nightshift' : undefined,
  }
}; 

export default defineStore('shiftsStore', {
  state: ():ShiftsStore => ({
    shifts: {},
    shiftsOverviewItems: {},
    shiftsOverviewScrollPosition: {},
    shiftsSearchString: '',
  }),
  actions: {
    async getShifts(type: number, {
      params,
    }: GetShiftsParams) {
      const result = await shiftService.getShifts(params);

      const newShifts = result.data;
      const previousShifts = this.shifts[type] || [];

      this.shifts[type] = [...previousShifts, ...newShifts];

      if (newShifts.length !== 0) {
        if (!this.shiftsOverviewItems[type]) this.shiftsOverviewItems[type] = [];
        
        newShifts.forEach((shift) => {        
          this.shiftsOverviewItems[type] = [...this.shiftsOverviewItems[type], 
          transformShiftToShiftOverviewItem(shift, type)]
        });

        this.shiftsOverviewItems[type] = formatAllOverviewItems(this.shiftsOverviewItems[type]);
      }

      return this.shifts[type];
    },

    async clearShifts() {
      this.shifts = {};
      this.shiftsOverviewItems = {};
      this.shiftsOverviewScrollPosition = {};
      this.shiftsSearchString = '';
    },

    async downloadDocument(id: number): Promise<{ name: string; data: string } | null> {
      try {
        return await documentService.downloadDocument(id);
      } catch (error) {
        console.error('Error downloading document:', error);
        return null;
      }
    },

    async setInvitationRead(shiftWorkerId: number): Promise<boolean | null> {
      try {
        return await notificationService.setInvitationRead(shiftWorkerId);
      } catch (error) {
        console.error('Error setting the invitation on read:', error);
        return null;
      }
    },

    async acceptShift(shiftWorkerId: number): Promise<ShiftWorker | null> {
        return await shiftService.acceptShift({ shiftWorkerId });
    },
    
    async declineShift(shiftWorkerId: number): Promise<ShiftWorker | null> {
      try {
        return await shiftService.declineShift({ shiftWorkerId });
      } catch (error) {
        console.error('Error declining shift:', error);
        return null;
      }
    },
    async acceptShiftChanges(shiftWorkerId: number): Promise<ShiftWorker | null> {
      try {
        return await shiftService.acceptShiftChanges({ shiftWorkerId });
      } catch (error) {
        console.error('Error accepting shift changes:', error);
        return null;
      }
    },
    async rejectShiftChanges(shiftWorkerId: number) : Promise<ShiftWorker | null> {
      try {
        return await shiftService.rejectShiftChanges({ shiftWorkerId });
      } catch (error) {
        console.error('Error rejecting shift changes:', error);
        return null;
      }
    },
  },
});
