<template>
  <div class="standard-rates__form"
       :class="{ 'standard-rates__form--web': isWeb }">
    <standard-rate-item-form-row v-for="(rate, index) in formRates"
                                 :key="index"
                                 ref="standardRatesForms"
                                 :form-data="{
                                   globalValidations,
                                   values: rate.formValues,
                                   rows,
                                 }"
                                 :is-web="isWeb"
                                 :rate-item="rate.rate"
                                 :is-disabled="isDisabled || isSubmitting"/>
  </div>
</template>
<script lang="ts" setup>
import {
  globalValidations,
  rows,
} from '@pidz/shared/forms/settings/rates/standard';
import { rerouteToOverview } from '@pidz/shared/utils';
import { formatPrice } from '@pidz/utils';
import { computed, ref } from 'vue';
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  Router,
  useRouter,
} from 'vue-router';
import standardRateItemFormRow from './StandardRateItemFormRow.vue';

const router: Router = useRouter();

interface Props {
  isWeb?: boolean;
  isEdit?: boolean;
  isDisabled?: boolean; 
  rates?: Rate[];
}

const props = withDefaults(
  defineProps<Props>(),
  {
    isWeb: false,
    isEdit: false,
    isDisabled: false,
    rates: undefined,
  },
);

const formSubmitted = ref(false);
const isSubmitting = ref(false);
const standardRatesForms = ref<(typeof standardRateItemFormRow)[]>([]);

const formRates = computed(() =>
  props.rates?.map((rate) => ({
    rate: { ...rate },
    formValues: {
      ...rate,
      rate: formatPrice(rate.rate),
      periodical_rate: formatPrice(rate.periodical_rate),
    },
  })),
);

const isValid = computed(
  () => !standardRatesForms.value.some((form) => !form.isValid),
);
const isSubmitDisabled = computed(
  () =>
    !isValid.value ||
    !standardRatesForms.value.some((form) => form.isChangedAndValid),
);

const eventName = 'rates_modal_edit_cancel';

onBeforeRouteLeave((_to, _from, next) =>
  standardRatesForms.value[0].warnBeforeCancel(
    next,
    formSubmitted.value,
    props.isWeb,
    eventName,
  ),
);
onBeforeRouteUpdate((_to, _from, next) =>
  standardRatesForms.value[0].warnBeforeCancel(
    next,
    formSubmitted.value,
    props.isWeb,
    eventName,
  ),
);

const onSubmit = async () => {
  isSubmitting.value = true;

  const submittingForms = standardRatesForms.value
    .filter((form) => form.isChangedAndValid)
    .map((form) => form.onSubmit());

  await Promise.all(submittingForms);

  rerouteToOverview(router, 'rates');
  standardRatesForms.value[0].succesToaster('Standaard tarieven opgeslagen');
  formSubmitted.value = true;
  isSubmitting.value = false;
};

const onCancel = async () => {
  const index = standardRatesForms.value.findIndex(form => form.isChanged);
  if (index !== -1){
    standardRatesForms.value[index].warnBeforeCancel(
      () => rerouteToOverview(router, 'rates'),
      formSubmitted.value,
      props.isWeb,
      eventName,
    );
  } else {
    standardRatesForms.value[0].onCancel()
  }
};

defineExpose({ isValid, isSubmitDisabled, isSubmitting, onCancel, onSubmit });
</script>
<style scoped lang="scss">
.standard-rates__form {
  background-color: var(--pidz-white);
  color: var(--pidz-body-text);
  box-shadow: var(--shadow-light);
  padding: 24px 16px;

  &--web {
    padding: 32px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    box-shadow: none;
  }
}
</style>
