<template>
  <div class="calendar-header">
    <top-bar class="max-md:!h-auto !z-10">
      <template #left>
        <h1 class="topbar-title view-selector">
          <div v-if="!showViewMenu"
               class="flex items-center" @click="showViewMenu = true">
            {{ calendarType }}
            <general-chevron-white class="down" />
          </div>
        </h1>
        <div v-if="showViewMenu"
             v-click-out="() => (showViewMenu = false)"
             class="view-menu">
          <router-link v-for="(name, value) in viewMap"
                       :key="value"
                       :to="`/calendar/${value}`"
                       replace
                       @click="showViewMenu = false">
            {{ name }}
          </router-link>
        </div>
      </template>
      <span></span>
      <template #right>
        <div @click="calendarStore.setToday">Vandaag</div>
      </template>
    </top-bar>

    <div class="current-date">
      <div class="title">
        <div class="center">
          <span class="title-main">{{ title }}</span>
          <span v-if="titleDetail"
                class="title-detail">{{ titleDetail }}</span>
        </div>
      </div>
      <div class="prev"
           @click="$emit('moveDate', -1)">
        <general-chevron-white />
      </div>
      <div class="next"
           @click="$emit('moveDate', 1)">
        <general-chevron-white />
      </div>
    </div>
    <div class="weekdays">
      <div v-for="(day, i) in weekdays"
           :key="day"
           class="weekday"
           @click="$emit('weekday', i)">
        {{ day }}
        <div v-if="weekdayIndicator && isCurrentWeekday(i as IsoWeekday)"
             class="current"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
/* eslint-disable max-lines */
import TopBar from '@app/components/TopBar.vue';
import { isoWeekdayFromDate } from '@pidz/date';
import { useCalendar } from '@pidz/stores';
import { useHorizontalSwipe, useClickOutDirective } from '@pidz/utils';
import { ref } from 'vue';
import GeneralChevronWhite from '../../icons/GeneralChevronWhite.vue';

const calendarStore = useCalendar();

const { vClickOut } = useClickOutDirective();

defineProps<{
  calendarType: string;
  title: string;
  titleDetail?: string;
  weekdayIndicator?: boolean;
}>();

const emit = defineEmits(['moveDate', 'weekday']);

const viewMap = {
  day: 'Dag',
  week: 'Week',
  month: 'Maand',
};
const isCurrentWeekday = (day: IsoWeekday) => isoWeekdayFromDate(calendarStore.date) === day;

const showViewMenu = ref(false);
const weekdays = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'];

useHorizontalSwipe((direction) => emit('moveDate', direction));
</script>
<style lang="scss" scoped>
.calendar-header {
  box-shadow: var(--shadow-light);
  background-color: var(--pidz-blue);
  color: var(--pidz-white);
  .pidz-icon.down {
    transform: scaleY(-1);
  }

  .view-menu {
    position: absolute;
    z-index: 2;
    top: 20px;
    background: var(--pidz-white);
    border-radius: 2px;
    box-shadow: var(--shadow-dark);
    a {
      color: var(--pidz-body-text);
      text-decoration: none;
      padding: 0px 24px;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
  .current-date {
    display: grid;
    grid-template-columns: 5fr 36px 36px;
    padding-right: 4px;

    .title-main {
      margin-left: 16px;
      font-size: 24px;
      font-weight: bold;
      margin-right: 12px;
    }
    .title-detail {
      font-size: 12px;
    }

    & > * {
      display: flex;
      align-items: center;
    }

    .prev,
    .next {
      justify-content: center;
      cursor: pointer;
    }
    .prev .pidz-icon {
      transform: rotate(-90deg);
    }
    .next .pidz-icon {
      transform: rotate(90deg);
    }
  }
  .weekdays {
    display: grid;
    font-size: 14px;
    grid-template-columns: repeat(7, 1fr);
    user-select: none;
    .weekday {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      position: relative;
      .current {
        position: absolute;
        bottom: 0;
        background: var(--pidz-white);
        --pad: 2px;
        width: calc(100% - 2 * var(--pad));
        left: var(--pad);
        height: 4px;
      }
    }
  }
}
</style>
