<template>
  <div class="calendar-page w-full">
    <reload-page :reload="hardReload" :loading="loading" />
    <component :is="viewTypeHeader" />
    <router-view />
  </div>
</template>
<script lang="ts" setup>
import ReloadPage from '@app/components/ReloadPage.vue';
import { Preferences } from '@capacitor/preferences';
import { useCalendar } from '@pidz/stores';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CalendarDayHeader from './CalendarDayHeader.vue';
import CalendarMonthHeader from './CalendarMonthHeader.vue';
import CalendarWeekHeader from './CalendarWeekHeader.vue';

const loading = ref(false)

const calendarStore = useCalendar();
const route = useRoute();
const router = useRouter();

const viewType = computed(() => (route.meta.type as string) || null);

const viewTypeHeader = computed(
  () => ({
    month: CalendarMonthHeader,
    week: CalendarWeekHeader,
    day: CalendarDayHeader,
  }[viewType.value ?? 'month']),
);

const reload = async () => {
  loading.value = true;
  await calendarStore.loadEvents(calendarStore.dateRange(true));
  loading.value = false;
};

const hardReload = async () => {
  loading.value = true;
  calendarStore.clearCachedEventRange();
  await reload();
}

watch(
  () => calendarStore.date,
  () => reload(),
);

watch(
  viewType,
  (newValue) => {
    if (newValue) {
      Preferences.set({ key: 'calendarView', value: newValue });
    }
  },
  { immediate: true },
);

onMounted(async () => {
  calendarStore.clearCachedEventRange()
  reload();
  if (viewType.value === null) {
    const lastViewType = (await Preferences.get({ key: 'calendarView' })).value ?? 'month';
    router.replace(`/calendar/${lastViewType}`);
  }
});
</script>
<style lang="scss">
.calendar-page {  
  display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
    
  .bg-shift {
    background: var(--pidz-calendar-green);
  }
  .bg-unavailable {
    background: var(--pidz-calendar-grey);
  }
  .bg-freelancer-request {
    background: var(--pidz-calendar-blue);
  }
}
</style>
