<template>
  <section>
    <div class="flex justify-between items-center">
      <div class="text-sm font-bold">{{ standardRate.function_name }}</div>
      <p-toggle v-model="rowEnabled" :input-id="standardRate.function_id.toString()"/>
    </div>

    <p-form ref="differentRatesEditForm"
            :form-data="formData"
            :disable-form="!rowEnabled"
            :disable-buttons="true"/>

    <div class="text-xs grid grid-cols-2 gap-4">
      <div>Standaard tarief {{ formatPriceToEuroString(standardRate.rate) }}</div>
      <div>Standaard tarief {{ formatPriceToEuroString(standardRate.periodical_rate) }}</div>
    </div>

    <p-ruler class="my-4"/>
  </section>
</template>

<script setup lang="ts">
import { useFormFunctions } from '@pidz/shared/forms';
import { rerouteToOverview } from '@pidz/shared/utils';
import { useRatesStore } from '@pidz/stores';
import { formatPriceToEuroString } from '@pidz/utils';
import { computed, ref } from 'vue';
import { Router, useRouter } from 'vue-router';

const props = withDefaults(defineProps<{
  standardRate: Rate
  differentRate: editRate
  formData: PidzFormData<StandardRateForm>
  isEdit?: boolean,
  organisation: ComboBoxOption,
  department: ComboBoxOption 
}>(), {
  isEdit: false,
});

const router: Router = useRouter();

const ratesStore = useRatesStore();

const rowEnabled = ref(props.differentRate.enabled);

const differentRatesEditForm = ref<any>();

const {
  isSubmitDisabled,
  isSubmitting,
  succesToaster,
  warnBeforeCancel,
} = useFormFunctions(
  differentRatesEditForm,
  [],
  () => rerouteToOverview(router, 'rates'),
  () => Promise.resolve(),
  props.isEdit,
  'Afwijkende tarieven',
);

const isValid = computed(() => differentRatesEditForm.value?.meta.valid && !differentRatesEditForm.value?.isValidating);

const onSubmit = async () => {
  const submit = differentRatesEditForm?.value?.handleSubmit(
    async (formValues: StandardRateForm) => {

      if (differentRatesEditForm.value?.isValidating || !differentRatesEditForm.value?.meta.valid) return;

      if (shouldRowBeDeleted.value) {
        await ratesStore.removeFreelancerFunctionRate(props.differentRate.freelancer_function_rate_id);
      }

      if (props.differentRate.existing && rowEnabled.value) {
        const editItem: Rate = {
          ...props.differentRate,
          rate: parseFloat(formValues.rate.replace(',', '.')),
          periodical_rate: parseFloat(formValues.periodical_rate.replace(',', '.')),
        };

        await ratesStore.updateFreelancerFunctionRate(editItem)
      }

      if (!props.differentRate.existing && rowEnabled.value) {
        const editItem: Rate = {
          ...props.standardRate,
          rate: parseFloat(formValues.rate.replace(',', '.')),
          periodical_rate: parseFloat(formValues.periodical_rate.replace(',', '.')),
          organisation_id: props.organisation.value as number,
          department_id: props.department.value === -1 ? undefined : props.department.value as number,
        };
        
        await ratesStore.addFreelancerFunctionRate(editItem);
      }
    },
  );

  if (submit) {
    await submit();
  }
};

const shouldRowBeDeleted = computed(() => props.differentRate.existing && !rowEnabled.value);

const isChanged = computed(() => differentRatesEditForm.value?.meta.dirty || shouldRowBeDeleted.value);
const isChangedAndValid = computed(() => (!isSubmitDisabled.value && rowEnabled.value) || shouldRowBeDeleted.value);

defineExpose({
  isValid,
  isChanged,
  isChangedAndValid,
  isSubmitting,
  onSubmit,
  succesToaster,
  warnBeforeCancel,
});
</script>
