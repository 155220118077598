<template>
  <div class="forgot-password-page">
    <top-bar>
      <template #left>&nbsp;</template>
      <div class="relative">
        <environment-indicator class="absolute left-[50px] -top-1" />
        <pidz-logo />
      </div>
    </top-bar>
    <div v-if="!resetSuccessful">
      <h1>Nieuw wachtwoord aanvragen</h1>
      <p>
        We zullen je een e-mail sturen waarmee je een nieuw en veilig wachtwoord
        aanmaakt voor je PIDZ account.
      </p>
      <form @submit.prevent @keydown.enter.prevent>
        <form-section class="email-form">
          <label for="input-email">E-mail</label>
          <input v-model.trim="email"
                 type="email"
                 placeholder="jouw@emailadres.nl"/>
        </form-section>
        <form-section class="button-wrapper">
          <async-button class="error-button" @click="returnToLogin">Annuleer</async-button>

          <async-button type="submit"
                        class="light"
                        :disabled="!validEmail"
                        @click="resetPassword">Verstuur</async-button>
        </form-section>
      </form>
    </div>
    <div v-else>
      <h1>E-mail verstuurd</h1>
      <p>
        E-mail verstuurd naar {{ email }}! Klik op de link in de e-mail om een
        nieuw wachtwoord aan te maken.
      </p>
      <p>Geen e-mail ontvangen? Controleer je spam folder.</p>
      <router-link class="link" :to="{ name: 'login' }"
                   replace>Terug naar inlogscherm</router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import AsyncButton from '@app/components/AsyncButton.vue';
import EnvironmentIndicator from '@app/components/EnvironmentIndicator.vue';
import FormSection from '@app/components/FormSection.vue';
import PidzLogo from '@app/components/PidzLogo.vue';
import TopBar from '@app/components/TopBar.vue';
import router from '@app/router';
import { useUserStore } from '@pidz/stores';
import { useToaster } from '@pidz/stores';
import { ref, computed } from 'vue';

const email = ref<string>('');
const toaster = useToaster();
const resetSuccessful = ref(false);
const validEmail = computed(() => /.+@.+\..{2,}/.test(email.value));

const returnToLogin = () => {
  router.push({ name: 'login' });
};

const resetPassword = async () => {
  try {
    await useUserStore().resetPassword({
      email: email.value,
      redirectUri: import.meta.env.VITE_FFE_OLD_URL ?? 'https://zzp.mijnpidz.nl',
    });
    resetSuccessful.value = true;
  } catch (e) {
    toaster.addToaster(
      {
        type: 'error',
        title: 'Er is iets misgegaan',
        message:
          'Het resetten van je wachtwoord is helaas niet gelukt, probeer het later nog een keer',
      },
      10000,
    );
  }
};
</script>
<style lang="scss">
.forgot-password-page {
  color: var(--pidz-white);
  background-color: var(--pidz-blue);
  padding: 48px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
  overflow: auto;

  h1 {
    display: flex;
    align-items: center;
    margin: 48px 0 0 0;
    font-size: 28px;
    line-height: 1;
  }

  .email-form {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
  }

  .link {
    border: 0;
    font-family: inherit;
    color: var(--pidz-blue);
    display: block;
    text-decoration: none;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: var(--pidz-white);
    text-align: center;
    border-radius: 4px;
  }

  .button-wrapper {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    column-gap: 16px;

    .error-button {
      background-color: var(--pidz-red);
      color: var(--pidz-white);
    }
  }
}
</style>
