<template>
  <sheet-block-list-section
    v-model="value"
    title="Reiskosten"
    add-title="Reiskosten toevoegen"
    edit-title="Reiskosten bewerken"
    :block-factory="blockFactory"
    :editor="SheetCommuteEditor"
    :allow-save="allowSave">
    <template #item="{ block }">
      <div class="total">{{totalDistance(block)}}</div>
      <div class="description">{{fromToDescription(block)}}</div>
    </template>
  </sheet-block-list-section>
</template>
<script lang="ts" setup>

import { computed } from 'vue';
import { Commute } from '../types';
import SheetBlockListSection from './SheetBlockListSection.vue';
import SheetCommuteEditor from './SheetCommuteEditor.vue';

const props = defineProps<{
  modelValue: any[],
  context:Pick<Commute, 'sheetUuid'|'assignmentUuid'|'sheetRowUuid'|'workUuid'>
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(to) {
    emit('update:modelValue', to);
  },
});

const totalDistance = (block:any) => `${block.distance} km`;
const fromToDescription = (block:Commute) => `${block.fromAddress} - ${block.toAddress}`;

const allowSave = (block:Commute) => {
  if (block.fromAddress === '') { return false; }
  if (block.toAddress === '') { return false; }
  if (block.distance === 0) { return false; }
  return true;
};

const blockFactory = () => ({
  ...props.context,
  fromAddress: '',
  toAddress: '',
  distance: 0,
  description: '',
});

</script>
