<template>
  <div class="upload-files">
    <label class="uploader">
      <slot/>
      <input type="file"
             :multiple="multiple"
             :accept="allowedFileTypesString"
             class="file-input"
             @change="filesChanged(($event.target as HTMLInputElement).files)"
             @click="(e: Event) => (e.target as HTMLInputElement).value = ''"/>
    </label>
  </div>
</template>

<script setup lang="ts">
import { useToaster } from '@pidz/stores';
import { errorCodes } from '@pidz/utils';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    multiple?: boolean,
    allowedFileTypes?: Array<'pdf' | 'image'>,
  }>(),
  {
    multiple: false,
    allowedFileTypes: () => ['pdf', 'image']
  }
);
const allowedFileTypesString = computed(() => {
  let allowedTypes = '';

  if (props.allowedFileTypes.includes('image')) {
    allowedTypes = allowedTypes.concat('', 'image/jpg, image/jpeg, image/png, image/heic');
  }
 
  if (props.allowedFileTypes.includes('pdf')) {
    allowedTypes = allowedTypes.concat((allowedTypes ? ', ' : ''), 'application/pdf');
  }
  return allowedTypes;
});
const emit = defineEmits(['upload']);
const filesChanged = (files: FileList | null) => {
  if (files) {
    emit('upload', filterDisallowedFiles(files));
  }
};

const filterDisallowedFiles  = (files: FileList) => {
  const allowedExtensions = ['jpg', 'jpeg', 'pdf', 'png', 'heic'];
  Array.prototype.some.call(files, (file: File) => {
      const extension = file.name.split('.').pop();
      if (!allowedExtensions.includes(extension!)) {
        useToaster().addToaster({
          type: errorCodes[609].type as ToasterType,
          title: errorCodes[609].title,
          message: errorCodes[609].message
        })
      }
    });
  return Array.prototype.filter.call(files, (file: File) => {
    const extension = file.name.split('.').pop();
    return allowedExtensions.includes(extension!);
  });
}
</script>

<style lang="scss">
.upload-files {
  flex: 1;
  .uploader {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    div {
      margin-left: 6px;
      margin-right: 16px;
    }

    input[type="file"] {
      display: none;
    }
  }
}
</style>
