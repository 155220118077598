import { useApi } from '@pidz/api';

const api = useApi();

export const clientsService = {
  getLinkedOrganisations: (): Promise<Organisation[]> => api.jsonrpc({
    url: 'freelancer:getLinkedOrganisations'
  }),

  getLinkedDepartmentsByOrganisation: (organisationId: number): Promise<Department[]> => api.jsonrpc({
    url: 'freelancer:getLinkedDepartmentsByOrganisation',
    params: { organisationId }
  }),

  updateLinkedDepartment: (value: Department): Promise<boolean> => api.jsonrpc({
    url: 'freelancer:updateLinkedDepartment',
    params: { linkedDepartmentModel: value }
  }),

  updateOrganisationBlocked: (organisationId: number, blocked: boolean): Promise<boolean> => api.jsonrpc({
    url: 'freelancer:updateOrganisationBlocked',
    params: { organisationId, blocked }
  }),
}
