import { useApi } from '@pidz/api';

const api = useApi();

export const ratesService = {
  getFreelancerFunctionRates: (params?): Promise<Rate[]> => api.jsonrpc({
    url: 'freelancer_function_rate:getFreelancerFunctionRates',
    params
  }),

  updateFreelancerFunctionRate: (freelancerFunctionRateModel: Rate)
    : Promise<Rate> => api.jsonrpc({
      url: 'freelancer_function_rate:updateFreelancerFunctionRate',
      params: { freelancerFunctionRateModel }
    }),

  addFreelancerFunctionRate: (freelancerFunctionRateModel: Rate)
    : Promise<Rate> => api.jsonrpc({
      url: 'freelancer_function_rate:addFreelancerFunctionRate',
      params: { freelancerFunctionRateModel }
    }),

  getNudgesForFunction: (functionId: number): Promise<Nudge[]> => api.jsonrpc({
    url: 'freelancer_rate_nudge:getNudgesForFunction',
    params: { functionId }
  }),

  removeFreelancerFunctionRate: (freelancerFunctionRateId: number): Promise<void> => api.jsonrpc({
    url : 'freelancer_function_rate:removeFreelancerFunctionRate',
    params: { freelancerFunctionRateId }
  })

}
