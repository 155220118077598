import { Router } from 'vue-router';

export const rerouteToOverview = (
  router: Router,
  overviewType: string,
): void => {
  router.push({
    name: overviewType,
  });
};
