<template>
  <a class="link-calendar-button"
     @click="openCalendarLinkModal">
    <p-button variant="ghost"
                 shadowed>
      <p-icon class="link-icon"
                 icon-name="link"
                 boxed
                 :background="iconBackground"
                 size="large"
                 color="blue"/>
    </p-button>
  </a>
</template>
<script lang="ts" setup>
import { useCalendar } from '@pidz/stores';
import { useModalController } from '@pidz/utils';
import { computed } from 'vue';
import LinkCalendarModal from './components/LinkCalendarModal.vue';

const modalController = useModalController();
const calendarStore = useCalendar();

const props = defineProps<{
  isDrawerOpen?: boolean
}>();

const openCalendarLinkModal = async () => {
  const link = await calendarStore.getCalendarLink();
  modalController.open(LinkCalendarModal, { link });
};

const iconBackground = computed(() => (props.isDrawerOpen ? 'white' : 'blue'));
</script>
<style lang="scss" scoped>
.link-calendar-button {
  .link-icon {
    transition: all 0.3s ease-in-out;
    min-width: 44px;
    min-height: 44px;
    max-width: 44px;
    max-height: 44px;
  }
}
</style>
