/* eslint-disable max-lines */
import { cvService } from '@pidz/api';
import { CvSection } from '@pidz/enums';
import { getGroupedCompetencies, isBadgeVisible } from '@pidz/utils';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useBannersStore } from '..';
import useUserStore from './user';

export default defineStore('cv', () => {
  /************/
  // Resume
  /************/
  const resume = ref<Resume>();
  const loadResume = async (reload?: boolean): Promise<void> => {
    if (reload || !resume.value) {
      resume.value = await cvService.getResumeContent();
      groupCompetencies();
    }
  };

  /************/
  // Competency
  /************/
  const groupCompetencies = (): void => {
    if (!resume.value) return;
    resume.value.groupedCompetencies = getGroupedCompetencies(resume.value.competencies);
  };

  const updateCompetency = async (competencyItem: Competency): Promise<void> => {
    const response = await cvService.updateCompetency(competencyItem);
    if (!resume.value?.competencies) return;
    const index = resume.value.competencies.findIndex(competency => competency.id === competencyItem.id);
    if (index > -1) resume.value.competencies[index] = response;
    groupCompetencies();
  };

  /************/
  // Work experience
  /************/
  const getWorkExperienceById = (id: number): WorkExperience | undefined =>
    resume?.value?.experiences?.find((obj) => obj.id === id);

  const addWorkExperience = async (workExperienceItem: Omit<WorkExperience, 'id'>): Promise<void> => {
    const response = await cvService.addWorkExperience(workExperienceItem);
    resume.value?.experiences.push(response);
  };

  const updateWorkExperience = async (workExperienceItem: WorkExperience): Promise<void> => {
    const response = await cvService.updateWorkExperience(workExperienceItem);
    if (!resume.value?.experiences) return;
    const index = resume.value.experiences.findIndex(experience => experience.id === workExperienceItem.id);
    if (index > -1) resume.value.experiences[index] = response;
  };

  const removeWorkExperience = async (id: number): Promise<void> => {
    await cvService.removeWorkExperience(id);
    if (!resume.value) return;
    const index = resume.value.experiences.findIndex(experience => experience.id === id);
    if (index > -1) resume.value.experiences.splice(index, 1);
  };

  /************/
  // Education
  /************/
  const getEducationById = (id: number): Education | undefined => 
    resume.value?.educations.find((education) => education.id === id);

  const addEducation = async (education: Omit<Education, 'id'>): Promise<void> => {
    const response = await cvService.addEducation(education);
    resume.value?.educations.push(response);
  };

  const updateEducation = async (educationItem: Education): Promise<void> => {
    const response = await cvService.updateEducation(educationItem);
    if (!resume.value?.educations) return;
    const index = resume.value.educations.findIndex(education => education.id === educationItem.id);
    if (index > -1) resume.value.educations[index] = response;
  };

  const removeEducation = async (id: number): Promise<void> => {
    await cvService.removeEducation(id);
    if (!resume.value) return;
    const index = resume.value.educations.findIndex(education => education.id === id);
    if (index > -1) resume.value.educations.splice(index, 1);
  };

  /************/
  // Registration
  /************/
  const getRegistrationById = (id: number): Registration | undefined =>
    resume.value?.registrations.find((resistration) => resistration.id === id);

  const addRegistration = async (registration: Omit<Registration, 'id'>): Promise<void> => {
    const response = await cvService.addRegistration(registration);
    resume.value?.registrations.push(response);
  };

  const updateRegistration = async (registrationItem: Registration): Promise<void> => {
    const response = await cvService.updateRegistration(registrationItem);
    if (!resume.value?.registrations) return;
    const index = resume.value.registrations.findIndex(registration => registration.id === registrationItem.id);
    if (index > -1) resume.value.registrations[index] = response;
  };

  const removeRegistration = async (id: number): Promise<void> => {
    await cvService.removeRegistration(id);
    if (!resume.value) return;
    const index = resume.value.registrations.findIndex(registration => registration.id === id);
    if (index > -1) resume.value.registrations.splice(index, 1);
  };

  /************/
  // Training
  /************/
  const getTrainingById = (id: number): Training | undefined => 
    resume.value?.trainings.find((training) => training.id === id);

  const addTraining = async (training: AddTrainingParams): Promise<void> => {
    const response = await cvService.addTraining(training);
    resume.value?.trainings.push(response);
  };

  const updateTraining = async (trainingItem: UpdateTrainingParams): Promise<void> => {
    const response = await cvService.updateTraining(trainingItem);
    if (!resume.value?.trainings) return;
    const index = resume.value.trainings.findIndex(training => training.id === trainingItem.id);
    if (index > -1) resume.value.trainings[index] = response;
  };

  const removeTraining = async (id: number): Promise<void> => {
    await cvService.removeTraining(id);
    if (!resume.value) return;
    const index = resume.value.trainings.findIndex(training => training.id === id);
    if (index > -1) resume.value.trainings.splice(index, 1);
  };

  /************/
  // Languages
  /************/
  const languages = ref<Language[]>();
  const loadLanguages = async (reload?: boolean): Promise<void> => {
    if (reload || !languages.value) languages.value = await cvService.getLanguages();
  };

  const getLanguageByUuid = (uuid: string): Language | null => 
    languages.value?.find((lang) => lang.uuid === uuid) || null;
  const getLanguageProficiencyById = (id: number): LanguageProficiency | undefined => 
    resume.value?.language_skills.find((lang) => lang.id === id);

  const addLanguageProficiency = async (language: Omit<LanguageProficiency, 'id'>): Promise<void> => {
    const response = await cvService.addLanguageProficiency(language);
    resume.value?.language_skills.push(response);
  };

  const updateLanguageProficiency = async (languageSkillItem: LanguageProficiency): Promise<void> => {
    const response = await cvService.updateLanguageProficiency(languageSkillItem);
    if (!resume.value?.language_skills) return;
    const index = resume.value.language_skills.findIndex(languageSkill => languageSkill.id === languageSkillItem.id);
    if (index > -1) resume.value.language_skills[index] = response;
  };

  const removeLanguageProficiency = async (id: number): Promise<void> => {
    await cvService.removeLanguageProficiency(id);
    if (!resume.value) return;
    const index = resume.value.language_skills.findIndex(languageSkill => languageSkill.id === id);
    if (index > -1) resume.value.language_skills.splice(index, 1);
  };

  /************/
  // registration types
  /************/
  const registrationTypes = ref<RegistrationType[]>();
  const loadRegistrationTypes = async (): Promise<void> => {
    if (!registrationTypes.value) registrationTypes.value = await cvService.getRegistrationTypes();
  };

  const getRegistrationTypeById = (id: number): RegistrationType | undefined =>
    registrationTypes.value?.find((registrationType) => registrationType.id === id);

  /************/
  // Education subjects
  /************/
  const educationSubjects = ref<EducationSubject[]> ();
  const loadEducationSubjects = async (): Promise<void> => {
    if (!educationSubjects.value) educationSubjects.value = await cvService.getEducationSubjects();
  };

  /************/
  // Trainings subjects
  /************/
  const trainingSubjects = ref<TrainingSubject[]>();
  const loadTrainingSubjects = async (): Promise<void> => {
    if (!trainingSubjects.value) trainingSubjects.value = await cvService.getTrainingSubjects();
  };

  /************/
  // All sectors
  /************/
  const allSectors = ref<FreelancerSector[]>();
  const loadAllSectors = async (): Promise<void> => {
    if (!allSectors.value) allSectors.value = await cvService.getFreelancerSectors();
  };

  const getSectorNameById = (id: number): string =>
    allSectors.value?.find((sector) => sector.id === id)?.name ?? 'onbekende sector';

  const getFunctionIdByName = (name: string): number =>
    freelancerResumeFunctions.value?.find((resumeFunction) => resumeFunction.name === name)?.id ?? 0;

  /************/
  // Freelancer resume functions
  /************/
  const freelancerResumeFunctions = ref<FreelancerResumeFunction[]>();
  const loadFreelancerResumeFunctions = async (): Promise<void> => {
    if (!freelancerResumeFunctions.value) 
      freelancerResumeFunctions.value = await cvService.getFreelancerResumeFunctions();
  };

  const getFunctionNameById = (id: number): string =>
    freelancerResumeFunctions.value?.find((resumeFunction) => resumeFunction.id === id)?.name ?? 'onbekende functie';

  /************/
  // Badges
  /************/
  const userStore = useUserStore();
  const visibleBadges = computed<VisibleBadges>(() => {
    return {
      functions: isBadgeVisible(
        userStore.freelancer?.functions ?? [],
        CvSection.FUCNTIONS,
      ),
      sectors: isBadgeVisible(
        userStore.freelancer?.sectors ?? [],
        CvSection.SECTORS,
      ),
      workExperience: isBadgeVisible(
        resume.value?.experiences ?? [],
        CvSection.WORK,
      ),
      educations: isBadgeVisible(
        resume.value?.educations ?? [],
        CvSection.EDUCATION,
      ),
      trainings: isBadgeVisible(
        resume.value?.trainings ?? [],
        CvSection.TRAINING,
      ),
      registrations: isBadgeVisible(
        resume.value?.registrations ?? [],
        CvSection.REGISTRATION,
      ),
      languages: isBadgeVisible(
        resume.value?.language_skills ?? [],
        CvSection.LANGUAGE,
      ),
      compentency: useBannersStore().bannersPerRoute.resume.includes('competency_experience')
    };
  });

  const $reset = () => {
    resume.value = undefined;
    languages.value = undefined;
    registrationTypes.value = undefined;
    educationSubjects.value = undefined;
    trainingSubjects.value = undefined;
    allSectors.value = undefined;
    freelancerResumeFunctions.value = undefined;
  };

  return {
    // state
    resume,
    languages,
    registrationTypes,
    educationSubjects,
    trainingSubjects,
    allSectors,
    freelancerResumeFunctions,
    visibleBadges,
    
    // load
    loadResume,
    loadLanguages,

    loadRegistrationTypes,
    loadEducationSubjects,
    loadTrainingSubjects,
    loadAllSectors,
    loadFreelancerResumeFunctions,
        
    // getters
    getWorkExperienceById,
    getEducationById,
    getRegistrationById,
    getTrainingById,
    getLanguageByUuid,
    getLanguageProficiencyById,
    getRegistrationTypeById,
    getSectorNameById,
    getFunctionIdByName,
    getFunctionNameById,
    
    // actions
    updateCompetency,

    addWorkExperience,
    updateWorkExperience,
    removeWorkExperience,

    addEducation,
    updateEducation,
    removeEducation,

    addRegistration,
    updateRegistration,
    removeRegistration,

    addTraining,
    updateTraining,
    removeTraining,

    addLanguageProficiency,
    updateLanguageProficiency,
    removeLanguageProficiency,

    $reset,
  };
});
