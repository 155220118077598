import { OTHER_EDUCATION_ID } from './const';
import { otherEducationField } from './form-fields';

export const toggleOtherSubjectsField = (
  value: number,
  form,
  other_subject: string,
) => {
  if (value === OTHER_EDUCATION_ID) {
    otherEducationField.value.active = true;
    form?.value?.setFieldValue('other_subject', other_subject);
  } else if (otherEducationField.value.active) {
    form?.value?.setFieldValue('other_subject', undefined);
    otherEducationField.value.active = false;
  }
};

export const resetOtherEducationField = () =>
  (otherEducationField.value.active = false);
