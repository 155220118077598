import { Auth } from '@pidz/api';

const configUsingEnvPrefix = (name: string, prefix:string): Environment => ({
  name,
  auth: {
    region: import.meta.env[`${prefix}_COGNITO_REGION`] ?? 'eu-central-1',
    userPoolId: import.meta.env[`${prefix}_COGNITO_USER_POOL_ID`],
    userPoolWebClientId: import.meta.env[`${prefix}_COGNITO_USER_POOL_WEB_CLIENT_ID`],
    authenticationFlowType: import.meta.env[`${prefix}_COGNITO_AUTHENTICATION_FLOW_TYPE`],
  },
  baseUrl: import.meta.env[`${prefix}_BASE_URL`],
  freelancerBaseUrl: import.meta.env[`${prefix}_FREELANCER_BASE_URL`],
  microUrl: import.meta.env[`${prefix}_MICRO_URL`],
});

export const generateProductionEnvironment = () => configUsingEnvPrefix('production', 'VITE');
export const generateAcceptanceEnvironment = () => configUsingEnvPrefix('acceptance', 'VITE_ACC');

export const configCognito = (environment: Environment) => {
  Auth.configureEnvironmentAuth(environment.auth);
};
