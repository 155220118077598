import { AssetStatus } from '@pidz/enums';
import { useAssetStore } from '@pidz/stores';
import { base64FromFile } from '@pidz/utils';

export const fileUpload = async (
  assets: File[],
  assetId: number,
  valid_until: string = '',
): Promise<Array<Asset | null>> => {
  const assetStore = useAssetStore();

  return await Promise.all(
    assets.map(async (file): Promise<Asset | null> => {
      let asset: Asset = {
        id: null,
        type: { id: assetId },
        filename: file.name,
        status: AssetStatus.NEW,
        is_resume_item: true,
        file_model: {
          name: file.name,
          data: await base64FromFile(file),
        },
      };

      if (valid_until) asset = { ...asset, valid_until };
        const response = await assetStore.addAsset(asset);
        if (!response) return null;
        return {
          id: response.id,
          filename: response.filename,
          status: response.status,
          description: null,
        } as Asset;
    }),
  );
};
