<template>
  <modal prevent-close>
    <component :is="content.component"  v-bind="content?.props ? {...content?.props} : undefined"/>
    <template #footer>
      <button @click="showNext()">OK</button>
    </template>
  </modal>
</template>
<script lang="ts" setup>

import Modal from '@app/components/Modal.vue';
import { computed, Component as TComponent } from 'vue';

const props = defineProps<{content: Array<{component: TComponent, props: any }>, showNext: Function}>();
const content = computed(() => props.content[0]);

</script>
