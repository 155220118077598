/* eslint-disable no-param-reassign */
export const useClickOutDirective = () => {
  const vClickOut = {
    mounted: (el:any, binding:any) => {
      el.clickOutsideEvent = (e:any) => {
        if (!el.contains(e.target)) {
          binding.value();
        }
      };
      setTimeout(() => {
        window.addEventListener('click', el.clickOutsideEvent);
      }, 0);
    },
    unmounted(el:any) {
      window.removeEventListener('click', el.clickOutsideEvent);
    },
  };
  return {
    vClickOut,
  };
};
