import { formatISODate } from '@pidz/date';
import { DateTime } from 'luxon';
import { createFilterStore } from './filterFactory';

export default createFilterStore<IInvitationFilterParams>('invitationFilter', {
  date: {
    start: formatISODate(DateTime.now()) || '',
    end: '',
  },
  functions: [],
  dayParts: [],
  travelTime: 0,
  alreadyWorkedAt: 0,
});
