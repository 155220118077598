import {
  addDuration,
  createDurationObject,
  format,
  formatYmd,
} from '@pidz/date';
import { DateTime } from 'luxon';
import { ZORGAANBIEDERS_PORTAAL_ID } from './const';
import { acquiredAtField, registrationTypeField, registrationNumberField } from './form-fields';
import { defaultRegistrationValidation, validationMap } from './form-validation';

export const toggleAquiredtAtFieldLogic = (
  registrationForm,
  registrationType: RegistrationType,
) => {
  if (!registrationType.validity_months) {
    acquiredAtField.value.active = false;
  } else {
    const date = registrationForm.value.values.acquired_at
      ? registrationForm.value.values.acquired_at
      : formatYmd(DateTime.now());
    registrationForm?.value?.setFieldValue('acquired_at', date);
    acquiredAtField.value.active = true;
  }
  acquiredAtField.value.data.label = 
    `Datum geregistreerd (geldig tot: ${validDate(registrationForm.value.values.acquired_at, registrationType)})`;
};

export const changeAquiredtAtFieldLabelLogic = (
  registrationType: RegistrationType,
  value: string,
) => {
  if (registrationType?.validity_months) {
    acquiredAtField.value.data.label = `Datum geregistreerd (geldig tot: ${validDate(value, registrationType)})`;
  } else {
    acquiredAtField.value.data.label = `Datum geregistreerd`;
  }
};

export const changeRegistrationNumberLabelLogic = (value: number) => 
  registrationNumberField.value.data.label = value === ZORGAANBIEDERS_PORTAAL_ID ? 
  'Vestigingsnummer' :  
  `Registratienummer`

export const changeRegistrationNumberValidationLogic = (value: number) => {
  const validation = validationMap.get(value)
  registrationNumberField.value.validation = validation || defaultRegistrationValidation
 }



const validDate = (
  acquiredDate: string,
  registrationType: RegistrationType,
) => {
  return format(
    addDuration(
      DateTime.fromISO(acquiredDate),
      createDurationObject({ month: registrationType.validity_months || 0 }),
    ),
    'dd-MM-yyyy',
  );
};

export const updateRegistrationTypeOptions = (options: FormDropDownOption[], existingOptions: number[]) => {
  registrationTypeField.value.data.options = options.filter(
    (option) => !existingOptions.includes(Number(option.value)),
  );
};
