import { shiftService } from '@pidz/api';
import { isCurrentYear } from '@pidz/date';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { formatAllOverviewItems } from '../utils/overview-conversion';

interface InvitationsStore {
  invitations: {[type: number]: OverviewShift[]},
  invitationOverviewItems: {[type: number]: newOverviewItem[]},
  invitationOverviewScrollPosition: {[type: number]: number},
  invitationSearchString: string,
}

interface GetInvitationsParams {
  params: ShiftGetShiftsParams,
  options: {},
}


const getInvitationGroupTitle = (date: DateTime, type: number): string => {
  let format = (type === 1) ? 'cccc d LLLL' : 'LLLL';
  
  if (!isCurrentYear(date)) format += ', y';

  return date.setLocale('nl-NL').toFormat(format);
}

const transformInvitationToInvitationOverviewItem = (invitation: OverviewShift, type: number): newOverviewItem => {
  return {
    firstOfType: false,
    lastOfType: false,
    groupTitle: getInvitationGroupTitle(DateTime.fromISO(invitation.shift_start), type),
    to: { name: 'invitation', params: { id: invitation.shift_id } },
    id: invitation.id,
    title: invitation.organisation_name,
    subtitle: invitation.department_name,
    description: invitation.function,
    startTime: DateTime.fromISO(invitation.shift_start).setLocale('nl-NL').toFormat(type === 1 ? 'HH:mm' : 'd LLL.'),
    endTime: DateTime.fromISO(invitation.shift_end).setLocale('nl-NL').toFormat(type === 1 ? 'HH:mm' : 'd LLL.'),
    icon: invitation.is_sleep_shift ? 'nightshift' : undefined,
  }
}; 

export default defineStore('invitationsStore', {
  state: (): InvitationsStore => ({
    invitations: {},
    invitationOverviewItems: {},
    invitationOverviewScrollPosition: {},
    invitationSearchString: '',
  }),
  actions: {
    async clearInvitations() {
      this.invitations = {};
      this.invitationOverviewItems = {};
      this.invitationOverviewScrollPosition = {};
      this.invitationSearchString = '';
    },
    async getInvitations(type: number, {
      params,
    }: GetInvitationsParams) {
      const result = await shiftService.getShifts(params);
      const newInvitations = result.data;
      const previousInvitations = this.invitations[type];

      if (!params.offset || !previousInvitations?.length) {
        this.invitations[type] = [...newInvitations];
      } else {
        this.invitations[type] = [...previousInvitations, ...newInvitations];
      }

      if (newInvitations.length !== 0) {
        if (!this.invitationOverviewItems[type]) this.invitationOverviewItems[type] = [];
        
        newInvitations.forEach((invitation) => {        

        this.invitationOverviewItems[type] = [...this.invitationOverviewItems[type], 
          transformInvitationToInvitationOverviewItem(invitation, type)]
        });

        this.invitationOverviewItems[type] = formatAllOverviewItems(this.invitationOverviewItems[type]);
      }

      return this.invitations[type];
    },
    async removeInvitation(invitationId: number, shiftType: number) {
      const indexToRemove = this.invitationOverviewItems[shiftType].findIndex((element) => element.id === invitationId);

      this.invitationOverviewItems[shiftType].splice(indexToRemove, 1);
      this.invitations[shiftType].splice(indexToRemove, 1);

      this.invitationOverviewItems[shiftType] = formatAllOverviewItems(this.invitationOverviewItems[shiftType]);
    }
  },
});
