<template>
  <div v-if="getEventDetails(props.calendarEvent)"
       :class="['calendar-event', `bg-${eventTypeToName(calendarEvent.type)}`]"
       @click="linkToEventPage">
    <div class="time-details">
      <div v-if="isAllDay"
           class="all-day">Hele dag</div>
      <div v-else
           class="time-details">
        <time>{{ formatEventTime(calendarEvent.start, dayDate) }}</time>
        <span>&nbsp;-&nbsp;</span>
        <time>{{ formatEventTime(calendarEvent.end, dayDate) }}</time>
      </div>
    </div>
    <div class="event-details">
      <span class="event-title">
        {{ getEventDetails(calendarEvent)?.title }}
      </span>
      <span v-if="getEventDetails(calendarEvent)?.subTitle"
            class="event-location">
        {{ getEventDetails(calendarEvent)?.subTitle }}
      </span>
    </div>
    <general-sleep-shift-blue v-if="calendarEvent.is_sleep_shift"
                              class="sleep-shift"/>
  </div>
</template>
<script lang="ts" setup>
import GeneralSleepShiftBlue from '@app/icons/GeneralSleepShiftBlue.vue';
import { DateTime } from '@pidz/date'
import {
  getEventDetails,
  formatEventTime,
  useModalController,
  eventTypeToName
} from '@pidz/utils';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import CalendarEditEventModal from './components/CalendarEditEventModal.vue';

const modal = useModalController();

const props = defineProps<{ 
  calendarEvent: CalendarEvent
  dayDate?: DateTime
}>();
const router = useRouter();

const isAllDay = computed(() => props.calendarEvent.all_day);

const linkToEventPage = () => {
  if (props.calendarEvent.repeat) {
    modal.open(CalendarEditEventModal, { id: props.calendarEvent.id });
    return;
  }
  const obj = getEventDetails(props.calendarEvent);
  if (obj?.route) {
    router.push(obj.route);
  }
};
</script>
<style lang="scss">
.calendar-event {
  display: grid;
  grid-template-columns: min-content auto min-content;
  height: 60px;
  width: 100%;
  padding: 0 16px;
  background-color: var(--pidz-black);
  color: var(--pidz-white);
  border-radius: 5px;

  &.day-event {
    grid-template-columns: auto min-content;
    grid-template-rows: min-content;
    grid-template-areas: 'eventDetails sleepShift' 'timeDetails .';
    .event-details {
      grid-area: eventDetails;
      margin-left: 0px;
    }
    .time-details {
      grid-area: timeDetails;
      flex-direction: row;
      align-self: start;
      span {
        display: inline-block;
      }
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .all-day {
    font-size: 13px;
    text-align: center;
  }
  .time-details {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-self: center;

    span {
      display: none;
    }
  }

  .event-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 12px;
    align-self: center;

    span {
      margin: 0;
      padding: 0;

      &.event-title {
        font-size: 14px;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.event-location {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .sleep-shift {
    display: flex;
    margin-top: 2px;
  }
}
</style>
