import { RowType } from '@pidz/enums';
import { languageField, skillField } from './form-fields';

// FORM GLOBAL VALIDATIONS
export const globalValidations: GlobalValidation<NewLanguagesForm>[] = [];

export const rows: Row[] = [
  {
    type: RowType.ROW,
    items: [languageField.value],
  },
  {
    type: RowType.ROW,
    items: [skillField.value],
  },
];
