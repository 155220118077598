<template>
  <p-modal class="delete-modal" @modal-closed="modalController.close()">
    <h1>{{ capitalizeString(type) }} verwijderen</h1>
    <p v-if="customText">{{ customText }}</p>
    <p v-else>Weet je zeker dat je de {{ type }} wilt verwijderen?</p>
    <template #footer>
      <p-button outlined @on-click="onCancel"> Annuleren </p-button>
      <p-button variant="secondary" @on-click="onDelete">Verwijderen</p-button>
    </template>
  </p-modal>
</template>
<script setup lang="ts">
import { useModalController, capitalizeString } from '@pidz/utils';

const modalController = useModalController();

const props = defineProps<{
  delete: Function;
  cancel: Function;
  type: string;
  customText?: string;
}>();

const onDelete = () => {
  modalController.close();
  props.delete();
  props.cancel();
};

const onCancel = () => {
  modalController.close();
};
</script>
<style lang="scss" scoped></style>
