import { healthService } from '@pidz/api';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

let interval: number = 0;

export default defineStore('health', () => {
  const backEndStatus = ref<undefined | string>(undefined);

  const checkBackEndStatus = async () => {
    clearTimeout(interval);
    const result = await healthService.getHealth();
    backEndStatus.value = result.status;

    if (backEndStatus.value === 'MAINTENANCE') {
      interval = window.setTimeout(() => {
        checkBackEndStatus();
      }, 60000);
    }
  }

  const setBackendStatus = (value: string) => {
    backEndStatus.value = value;
  }

  watch(
    () => backEndStatus.value,
    () => checkBackEndStatus()
  );

  const $reset = () => {

  };

  return {
    $reset,
    backEndStatus,
    checkBackEndStatus,
    setBackendStatus
  };
});
